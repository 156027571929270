import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfigService } from '@app/config';
import { Observable } from 'rxjs';
import { TermsOfUseService } from './terms-of-use.service';

@Injectable({
  providedIn: 'root',
})
export class TermsOfUseGuard implements CanActivate {
  constructor(
    private readonly termsOfUseService: TermsOfUseService,
    private router: Router,
    private appConfigService: AppConfigService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.appConfigService.getConfig()?.enableTermsOfUse || this.termsOfUseService.agreedToTermsOfUse) {
      return true;
    } else {
      this.router.navigate(['terms-of-use'], { state: { data: { redirectUrl: state.url } } });

      return false;
    }
  }
}
