<ng-container *ngIf="queryService.getImTooltip() | async as tooltipData">
  <div class="im-tooltip">
    <p class="im-tooltip-title">{{ tooltipData.platform | titlecase | translate }}</p>
    <p class="im-attribute" *ngIf="tooltipData.name">{{ tooltipData.name }}</p>
    <p class="im-attribute" *ngIf="tooltipData.lastSeen">
      {{ 'Last seen' | translate }}: {{ tooltipData.lastSeen }}
    </p>
    <div class="row im-avatar" *ngIf="tooltipData?.images?.length">
      <img *ngFor="let image of tooltipData.images" appFallbackImg="/assets/static/images/linked-person.svg"
        class="avatar" [src]="image" alt="avatar">
    </div>
  </div>
</ng-container>
