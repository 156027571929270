import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Person } from 'datalayer/models';
import { PersonDTO } from 'src/app/modules/data-layer/services/person/person/person-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule
})

export class PersonApiService extends BaseApiService<Person, PersonDTO> {

  constructor(httpClient: HttpClient) {
    super(httpClient, PersonDTO);
  }

  public getModelName(): string {
    return Person.name;
  }

}
