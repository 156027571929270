import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionnaireService } from 'src/app/services/questionnaire/questionnaire.service';
import { UserFeaturesService } from 'src/app/services/user-features/user-features.service';
import { FeatureAttribute, FeatureId, UserFeatures } from '../../models/questionnaire.model';
import { BaseDialogComponent } from './../../classes/base-dialog.component';
import { InvestigationUserState } from './../../models/questionnaire.model';

export enum FeedbackStates {
  Feedback = 'Feedback',
  SendFeedback = 'SendFeedback',
  CloseFeedback = 'CloseFeedback'
}

@Component({
  selector: 'app-questionnaire-dialog',
  templateUrl: './questionnaire-dialog.component.html',
  styleUrls: ['./questionnaire-dialog.component.scss']
})
export class QuestionnaireDialogComponent extends BaseDialogComponent implements OnInit {
  feedbackStates = FeedbackStates;
  state: string;
  sendFeedbackForm: FormGroup;

  constructor(
    private userFeaturesService: UserFeaturesService,
    private questionnaireService: QuestionnaireService,
    public dialogRef: MatDialogRef<QuestionnaireDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { identity: string; state: FeedbackStates }
  ) {
    super(dialogRef);
    this.state = this.feedbackStates.Feedback;
  }

  ngOnInit(): void {
    if (this.data.state) {
      this.state = this.data.state;
    }
  }

  getQuestionnaireIcon(feedbackState: FeedbackStates): string {
    if (feedbackState === this.feedbackStates.Feedback) {
      return 'assets/static/images/questionnaire/questionnaire-elements.png';
    } else if (feedbackState === this.feedbackStates.CloseFeedback) {
      return 'assets/static/images/questionnaire/questionnaire-elements-red.png';
    }
  }

  closeFeedback(): void {
    if (this.dialog) {
      const queryParams = {
        featureId: FeatureId.INVESTIGATION,
        featureAttribute: FeatureAttribute.STATE,
        featureValue: InvestigationUserState.CLOSED
      };

      this.userFeaturesService.updateUserFeatures(queryParams).subscribe((response: UserFeatures[]) => {
        localStorage.setItem(`${this.data.identity}_questionnaireAccepted`, '0');
        this.questionnaireService.close();
        this.dialog.close();
      });
    }
  }

  goToSendFeedback(): void {
    this.state = this.feedbackStates.SendFeedback;
    this.initForm();
  }

  initForm(): void {
    this.sendFeedbackForm = new FormGroup({
      feedback: new FormControl('', [Validators.required, Validators.minLength(10), Validators.pattern('^[a-zA-Z0–9 _,.]*$')])
    });
  }

  onSubmitFeedback(): void {
    if (this.sendFeedbackForm.invalid) {
      return;
    }

    if (this.dialog) {
      const queryParams = {
        featureId: FeatureId.INVESTIGATION,
        featureAttribute: FeatureAttribute.STATE,
        featureValue: InvestigationUserState.RUNNING
      };

      this.userFeaturesService.updateUserFeatures(queryParams).subscribe((response: UserFeatures[]) => {
        localStorage.setItem(`${this.data.identity}_questionnaireAccepted`, '1');
        this.dialog.close();
      });
    }
  }
}
