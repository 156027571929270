export const es: { [key: string]: string } = {
  Scheduled: 'Programación',
  Scheduler: 'Programación',
  Emergency: 'Emergencia',
  'is on emergency': 'está en emergencia',
  'There are no investigations added. Please create a ':
    'No se ha agregado ninguna investigación. Por favor, agregue una ',
  'new investigation': 'nueva investigación',
  'See a demo investigation ': 'Vea un ejemplo de investigación ',
  Tasks: 'Tareas',
  'Live tasks': 'Tareas activas',
  Active: 'Activo',
  Past: 'Pasada',
  msisdn: 'MSISDN',
  MSISDN: 'MSISDN',
  imsi: 'IMSI',
  imei: 'IMEI',
  home_mcc: 'MCC',
  home_mnc: 'MNC',
  lac: 'LAC',
  cell_id: 'CeldaID',
  status: 'Estado',
  phone_model: 'Modelo',
  'USER EQUIPMENT': 'Equipo del Usuario',
  Cell: 'Celda',
  'Query Date': 'Fecha de consulta',
  'Query date': 'Fecha de consulta',
  'Create User': 'Crear Usuario',
  Username: 'Nombre de usuario',
  Password: 'Contraseña',
  'New Password': 'Nueva Contraseña',
  'New password': 'Nueva contraseña',
  'Confirm New Password': 'Confirmar Nueva Contraseña',
  'Confirm new password': 'Confirmar nueva contraseña',
  Phone: 'Teléfono',
  Email: 'Correo electrónico',
  Credits: 'Créditos',
  credits: 'créditos',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  'User Management': 'Administrar Usuarios',
  'Activity Log': 'Registro de actividades',
  'Location log': 'Registro de ubicación',
  Admin: 'Admin',
  Actions: 'Acciones',
  'Download CSV': 'Descargar CSV',
  'Create Place': 'Crear Lugar',
  'Add new place': 'Añadir Nuevo Lugar',
  'Name of the area': 'Nombre del Área',
  'Save area of Interest': 'Guardar Área de Interés',
  Draw: 'Trazar',
  Reset: 'Reiniciar',
  reset: 'reiniciar',
  'Enter a valid username': 'Ingrese un nombre de usuario válido',
  'Sign In': 'Iniciar Sesión',
  Manager: 'Administrar',
  'Reset Password': 'Reiniciar contraseña',
  Logout: 'Cerrar sesión',
  'Sign out': 'Cerrar sesión',
  'Mark all as read': 'Marcar todo como leído',
  Pending: 'Pendiente',
  Locating: 'Localizando',
  History: 'Historial',
  'No entries': 'No hay resultados',
  'My places': 'Mis lugares',
  'No places': 'Sin lugares',
  Name: 'Nombre',
  'Full Name': 'Nombre Completo',
  User: 'Usuario',
  Users: 'Usuarios',
  Photo: 'Foto',
  Color: 'Color',
  Delete: 'Borrar',
  'Pending request': 'Solicitud pendiente.',
  'Call log requested': 'Registro de llamadas solicitado',
  'Request call log': 'Solicitar registro de llamadas',
  'Pending request. Click here to cancel':
    'Solicitud pendiente. Haga clic aquí para cancelar.',
  'No targets': 'Sin objetivos',
  Search: 'Buscar',
  'Select user': 'Seleccionar usuario',
  Edit: 'Editar',
  'Add user': 'Agregar usuario',
  'Family Members': 'Miembros de la Familia',
  'Location found': 'Ubicación encontrada',
  'Query pending': 'Consulta Pendiente',
  'No location': 'Sin ubicación',
  Close: 'Cerrar',
  Locate: 'Localizar',
  'Edit family member': 'Editar miembro de la familia',
  'Add new Target': 'Agregar nuevo Objetivo',
  Fleet: 'Flotilla',
  Follow: 'Seguir',
  Unfollow: 'Dejar de seguir',
  'Add target': 'Agregar objetivo',
  'Edit Target': 'Editar objetivo',
  'Place created successfully!': 'Lugar creado con éxito!',
  'Place has not been created': 'El lugar no ha sido creado.',
  "Passwords don't match.": 'Las contraseñas no coinciden.',
  'Invalid values. Please try again.':
    'Valores inválidos. Por favor, intente de nuevo.',
  'Invalid values. Please fill in all fields.':
    'Valores no válidos. Por favor, complete todos los campos.',
  'User edited successfully!': '¡Usuario editado con éxito!',
  'User has not been edited': 'Usuario no ha sido editado',
  'New user #{username} created successfully!':
    '¡Nuevo usuario #{username} creado con éxito!',
  'Temporary password sent to: #{email}':
    'Contraseña temporal enviada a: #{email}',
  'The password will be sent to the email address #{email}. Is this address correct?':
    'La contraseña se enviará a la dirección de correo electrónico "#{email}" ¿Esta dirección de correo es correcta?',
  'User has not been created': 'El usuario no ha sido creado',
  Heatmap: 'Mapa de calor',
  'Place has been successfully deleted': 'El lugar ha sido eliminado con éxito',
  'Place has not been deleted': 'El lugar no ha sido eliminado',
  'Target edited successfully!': '¡Objetivo editado con éxito!',
  'Target has not been edited': 'El objetivo no ha sido editado.',
  'Target created successfully!': '¡Objetivo creado con éxito!',
  'Target has not been created': 'El objetivo no ha sido creado.',
  'Case created successfully!': 'Caso creado con éxito!',
  'Case has not been edited': 'El caso no ha sido actualizado',
  'Please fill in all fields': 'Por favor, complete todos los campos',
  'Maximum image size is 2MB. Please choose another image.':
    'El tamaño máximo de la imagen es de 2MB. Por favor, seleccione otra imagen.',
  'Your query has been queued': 'Su consulta ha sido puesta en fila',
  'Follow started for': 'Seguimiento iniciado para',
  'Follow stopped for': 'Seguimiento detenido para',
  'Target has been successfully deleted':
    'El objetivo ha sido eliminado con éxito',
  'Target has not been deleted': 'El objetivo no ha sido eliminado',
  Radius: 'Radio',
  'Total distance': 'Distancia total',
  'Enter a valid phone': 'Ingrese un teléfono válido',
  'Something went wrong with the photo. Please try again.':
    'Algo salió mal con la foto. Por favor, intente de nuevo.',
  'User has been successfully deleted':
    'El usuario ha sido eliminado con éxito',
  Map: 'Mapa',
  'Show query history': 'Mostrar historial de consultas',
  'Hide query history': 'Ocultar historial de consultas',
  Roles: 'Roles',
  'Place of interest': 'Lugar de interés',
  'Drag the map to precise your location':
    'Arrastre el mapa para precisar su ubicación',
  Family: 'Familia',
  Queries: 'Consultas',
  Notifications: 'Notificaciones',
  'Follow is already enabled.': 'Seguir ya está habilitado.',
  'Follow is already disabled.': 'Seguir ya está deshabilitado.',
  'User not found': 'Usuario no encontrado',
  'Invalid credentials provided': 'Credenciales inválidas',
  'Invalid OTP code provided': 'Codigo OTP inválido',
  Continue: 'Continuar',
  Places: 'Lugares',
  'Enter Password': 'Ingrese Contraseña',
  'Enter OTP': 'Ingrese OTP',
  'Saved!': '¡Guardado!',
  'Could not save!': '¡No se pudo guardar!',
  Log: 'Registro',
  Target: 'Objetivo',
  Targets: 'Objetivos',
  'Select dates': 'Seleccionar fechas',
  Filter: 'Filtrar',
  Geolocation: 'Geolocalización',
  Multiple: 'Múltiple',
  Date: 'Fecha',
  Type: 'Tipo',
  Export: 'Exportar',
  Exporting: 'Exportador',
  Refresh: 'Refrescar',
  phone: 'teléfono',
  'MOBILE NETWORK': 'Red móvil',
  location: 'localización',
  LOCATION: 'Localización',
  'Target details': 'Detalles del objetivo',
  Query: 'Consulta',
  'New Query': 'Nueva Consulta',
  'AoI Query': 'Consulta AoI',
  'Advanced Options': 'Opciones Avanzadas',
  Schedule: 'Programación',
  'Log on': 'Conexión',
  Proximity: 'Proximidad',
  'Insert phones': 'Ingresar números de teléfono',
  Submit: 'Enviar',
  'Query number': 'Numero de consulta',
  'Select frequency': 'Seleccionar la frecuencia',
  'Outside the circle': 'Fuera del círculo',
  Country: 'País',
  'Area of interest': 'Área de Interés',
  'No area is defined on the map.': 'No hay área definida en el mapa.',
  'Enter a valid number': 'Ingrese un número válido',
  'Enter a number': 'Ingrese un número',
  'Query created successfully!': '¡Consulta creada con éxito!',
  'Query successfully resubmitted!': '¡Consulta reenviada exitosamente!',
  'Resubmit Cancelled': 'Reenvío cancelado',
  'Query has not been created': 'No se ha creado la consulta.',
  Status: 'Estado',
  Subscription: 'Suscripción',
  'Insert IMSI': 'Insertar IMSI',
  'Insert MSISDN': 'Insertar MSISDN',
  'Create target': 'Crear objetivo',
  Geofencing: 'GeoCerca',
  'All caught up': 'No hay notificaciones nuevas',
  'Resubmit query': 'Reenviar consulta',
  'Imsi query': 'Consulta usando IMSI',
  'Inside the circle': 'Dentro del círculo',
  'Select country': 'Seleccionar país',
  'Save area': 'Guardar área',
  'Draw area': 'Dibujar área',
  'Are you sure you wish to resubmit?':
    '¿Estás seguro que desea volver a enviarlo?',
  'Delete Target': 'Borrar Objetivo',
  Clear: 'Eliminar',
  'Export to pdf': 'Exportar a PDF',
  'Download report': 'Descargar informe',
  located: 'localizado',
  'not located': 'no localizado',
  'Show history': 'Mostrar historia',
  'Total credits': 'Créditos totales',
  'Total queries': 'Total de consultas',
  'Total successful queries': 'Total de consultas exitosas',
  'Total credits deducted': 'Total de créditos descontados',
  Reason: 'Razón',
  Lock: 'Bloquear',
  Unlock: 'Desbloquear',
  Intelligence: 'Inteligencia',
  Photos: 'Fotos',
  Articles: 'Artículos',
  'See all photos': 'Ver todas las fotos',
  Group: 'Grupo',
  Groups: 'Grupos',
  'Add Group': 'Añadir Grupo',
  'Edit Group': 'Editar Grupo',
  'Associated with': 'Asociado con',
  Gender: 'Género',
  Location: 'Localización',
  'Delete photo': 'Borrar foto',
  'Upload photo': 'Subir foto',
  Details: 'Detalles',
  'Call log map': 'Mapa de registro de llamadas',
  Analysis: 'Análisis',
  'No credits': 'Sin créditos',
  'Date of query': 'Fecha de consulta',
  'Last seen online': 'Visto en línea por última vez',
  About: 'Acerca de',
  Go: 'Ir',
  'Date of birth': 'Fecha de nacimiento',
  'Intel search': 'Búsqueda de inteligencia',
  'Full Post': 'Publicación completa',
  'Post Information': 'Información de la Publicación',
  'PDF layout': 'Diseño del PDF',
  Disclaimer: 'Responsabilidad',
  'QR code': 'Código QR',
  Alias: 'Alias',
  Aliases: 'Alias',
  Settings: 'Ajustes',
  'User management': 'Administración de usuario',
  Dashboard: 'Tablero',
  'Event viewer': 'Visor de eventos',
  Ledger: 'Gestión de la base de datos',
  Title: 'Título',
  'Confidential content': 'Contenido confidencial',
  'Disclaimer content': 'Contenido de exclusión de responsabilidad',
  'Title content': 'Título del contenido',
  Confidential: 'Confidencial',
  'Company Logo': 'Logo de la Empresa',
  'Page numbering': 'Numeración de página',
  'Company Logo Url': 'URL del logotipo de la empresa',
  'Map circle': 'Círculo de mapa',
  'Simple details': 'Detalles simples',
  'PDF settings updated successfully!':
    '¡Configuración de PDF actualizada con éxito!',
  'Something went wrong. Please try again.':
    'Algo salió mal. Por favor, intente de nuevo.',
  'Phone Model': 'Modelo de teléfono',
  Provider: 'Proveedor',
  Lat: 'Latitud',
  Lon: 'Longitud',
  Latitude: 'Latitud',
  Longitude: 'Longitud',
  'Query Id': 'ID de consulta',
  'Date Of Query': 'Fecha de consulta',
  Network: 'Red',
  Radio: 'Conexión',
  'Cell Id': 'ID de celda',
  'Cell ENBID': 'ENBID de celda',
  'Cell LCID': 'LCID de celda',
  'Cell ECI': 'ECI de celda',
  LAC: 'LAC',
  'No date provided': 'Sin fecha',
  Level: 'Nivel',
  Command: 'Comando',
  Message: 'Mensaje',
  'LOG IN': 'INICIAR SESIÓN',
  PASSWORD: 'CONTRASEÑA',
  USERNAME: 'NOMBRE DE USUARIO',
  'Site properties': 'Propiedades del sitio',
  'Session Timeout': 'Hora de término de la sesión',
  'Map default countries': 'Mapa de países predeterminados',
  Archive: 'Archivo',
  'Blacklist number': 'Número de lista negra',
  All: 'Todo',
  'Success!': '¡Éxito!',
  'Failed!': '¡Falló!',
  'Query selected': 'Consulta seleccionada',
  'Please select a query': 'Por favor, seleccione una consulta',
  'Intelligence query': 'Consulta de inteligencia',
  Blacklist: 'Lista negra',
  'Show batch query': 'Mostrar consulta por lotes',
  Options: 'Opciones',
  Verified: 'Verificado',
  Following: 'Siguiendo',
  'Do you wish to perform an intelligence query?':
    '¿Desea realizar una consulta de inteligencia?',
  Keywords: 'Palabras clave',
  Keyword: 'Palabra clave',
  'No groups': 'No hay grupos',
  'Enter a valid IMSI': 'Ingrese un IMSI válido',
  'Enter a valid TMSI': 'Ingrese un TMSI válido',
  'Enter a valid IMEI': 'Ingrese un IMEI válido',
  'Save result': 'Guardar resultado',
  'Are you sure you wish to save this result?':
    '¿Estás seguro de que desea guardar este resultado?',
  Followings: 'Seguidos',
  Followers: 'Seguidores',
  'Show more': 'Mostrar más',
  'Results Found': 'Resultados Encontrados',
  Value: 'Valor',
  'Enter a valid value': 'Introduzca un valor valido',
  'Clear map': 'Limpiar Mapa',
  'Phone Numbers': 'Números de teléfono',
  'Group edited successfully!': '¡Grupo editado con éxito!',
  'Group has not been edited': 'El grupo no ha sido editado.',
  'Group created successfully!': '¡Grupo creado con éxito!',
  'Group has not been created': 'El grupo no ha sido creado',
  'Delete Group': 'Eliminar Grupo',
  'Group has been successfully deleted': 'El grupo ha sido eliminado con éxito',
  'Group has not been deleted': 'El grupo no ha sido eliminado',
  'Image Analysis': 'Análisis de Imagen',
  'Dark Web': 'Dark Web',
  Darkweb: 'Darkweb',
  Posts: 'Publicaciones',
  Friends: 'Amigos',
  'Select File': 'Seleccione archivo',
  FACES: 'CARAS',
  LABELS: 'ETIQUETAS',
  'WEB ENTITIES': 'ENTIDADES WEB',
  METADATA: 'METADATOS',
  'PARTIALLY MATCHING IMAGES': 'COINCIDENCIA PARCIAL DE IMÁGENES',
  'SIMILAR IMAGES': 'IMÁGENES SIMILARES',
  'PAGES WITH MATCHING IMAGES': 'PÁGINAS CON COINCIDENCIAS DE IMAGENES',
  'SAFE SEARCH': 'BÚSQUEDA SEGURA',
  'Screen name': 'Nombre de pantalla',
  Statistics: 'Estadísticas',
  'Connected numbers by device for': 'Números conectados por dispositivo',
  'Connected numbers by location for': 'Números conectados por ubicación',
  'Phone number': 'Número de teléfono',
  Distance: 'Distancia',
  Time: 'Hora',
  seconds: 'segundos',
  Severity: 'Severidad',
  Major: 'Mayor',
  Minor: 'Menor',
  Critical: 'Crítico',
  'Wrong Credentials': 'Credenciales incorrectas',
  Address: 'Dirección',
  'No queries': 'Sin consultas',
  Back: 'Atrás',
  'MSISDN is not linked with a whatsApp account':
    'MSISDN no está vinculado con una cuenta de whatsApp',
  'Remove filters': 'Limpiar Filtros',
  'Simple Query': 'Consulta Simple',
  'Export to CSV': 'Exportar a CSV',
  Today: 'Hoy',
  Yesterday: 'Ayer',
  'Login Now': 'Inicie Sesión Ahora',
  'End Date': 'Fecha de terminación',
  'End date': 'Fecha de terminación',
  Frequency: 'Frecuencia',
  Located: 'Localizados',
  'Not Located': 'No Localizados',
  'Distribution of keywords on platforms':
    'Distribución de palabras clave en las plataformas',
  'Show more cases': 'Mostrar más casos',
  'Date & time': 'Día y fecha',
  Session: 'Sesión',
  'Show less cases': 'Mostrar menos casos',
  'Task ID': 'ID de tarea',
  minutes: 'minutos',
  'Task has been successfully canceled': 'Tarea cancelada con éxito.',
  'Task has not been canceled': 'La tarea no ha sido cancelada',
  'Robot has not been canceled': 'El robot no ha sido cancelado.',
  'From Date': 'A partir de la fecha',
  MCC: 'MCC',
  MNC: 'MNC',
  IMSI: 'IMSI',
  'Query canceled': 'Consulta cancelada',
  'Query has not been canceled': 'La consulta no ha sido cancelada.',
  'Add hashtag for this query': 'Añadir hashtag para esta consulta',
  'Query hashtag': 'Consultar hashtag',
  'Hashtag added successfully!': '¡Hashtag añadido con éxito!',
  'Hashtag has not been added': 'Hashtag no ha sido añadido',
  'Data saved successfully!': '¡Datos guardados exitosamente!',
  'Something went wrong.': 'Algo salió mal.',
  'Save data to target': 'Guardar datos para objetivo',
  'Select target': 'Seleccione un objetivo',
  'was turned on': 'estaba encendido',
  'is Offline. Last Known Location found':
    'está desconectada. Última ubicación conocida encontrada',
  'was turned off': 'estaba apagado',
  'was found near': 'fue encontrado cerca',
  in: 'en',
  'was found': 'fue encontrado',
  Resubmit: 'Volver a enviar',
  'Possible location for': 'Posible ubicación para',
  inside: 'dentro',
  outside: 'fuera',
  is: 'está',
  'the geofenced area': 'del área con geocerca',
  entered: 'introducido',
  Disable: 'Desactivar',
  'Are you sure you want to disable the geofencing?':
    '¿Está seguro que desea desactivar la geocerca?',
  'Geofencing canceled!': '¡Geocerca cancelada!',
  left: 'dejado',
  'Task for': 'Tarea para',
  'with ID:': 'con ID:',
  'has finished': 'ha terminado',
  'scheduled task has finished': 'tarea programada ha terminado',
  Hashtag: 'Hashtag',
  Program: 'Programa',
  'Task Id': 'ID de tarea',
  'New query': 'Nueva consulta',
  Analytics: 'Analítica',
  'Outside of area': 'Fuera del área',
  'Location History Not Found': 'Historial de Ubicación No Encontrado',
  'Request from Support': 'Solicitud de Soporte',
  'Location History Found': 'Historial de Ubicación Encontrado',
  'entries were imported': 'las entradas fueron importadas',
  'Note: Please leave input box empty to remove current hashtag':
    'Nota: Por favor, deje el cuadro de entrada vacío para eliminar el hashtag actual',
  'Call log': 'Registro de llamadas',
  'Call Log Analysis': 'Análisis de registro de llamadas',
  'Past tasks': 'Tareas pasadas',
  'Start Date': 'Fecha de inicio',
  'Start date': 'Fecha de inicio',
  Created: 'Creado por',
  Language: 'Idioma',
  'User options': 'Opciones de usuario',
  'User manual': 'Manual de usuario',
  Sort: 'Ordenar',
  'Remove filters and restore queries':
    'Eliminar filtros y restaurar consultas',
  group: 'grupo',
  'Geo Country': 'Geo País',
  'Geo AoI': 'Geo AoI',
  type: 'tipo',
  GO: 'IR',
  'Do you wish to perform an intelligence query or view the existing intel?':
    '¿Desea realizar una consulta de inteligencia o ver alguna consulta de inteligencia existente?',
  'New intel query': 'Nueva consulta de inteligencia',
  'Show existing intel': 'Mostrar información existente',
  OTP: 'OTP',
  'Please add phone or email to enable OTP':
    'Por favor, agregue el teléfono o correo electrónico para habilitar OTP',
  'Enable OTP': 'Habilitar OTP',
  Roaming: 'Itinerancia',
  'Last hour': 'Última hora',
  Previous: 'Anterior',
  'Send SMS': 'Enviar SMS',
  Text: 'Texto',
  Send: 'Enviar',
  'Profile URL': 'URL del Perfil',
  'Open in website': 'Abrir en sitio web',
  'Location History Request': 'Solicitud de Historial de Ubicación',
  'Call Log Request': 'Solicitud de registro de llamadas',
  'Upload Call Log': 'Subir registro de llamadas',
  'Request has not been sent': 'La solicitud no ha sido enviada',
  'Request sent successfully!': '¡Solicitud enviada con éxito!',
  'Show call log details': 'Ver detalle de registro de llamadas',
  'Show call log map': 'Mostrar mapa de registro de llamadas',
  'Origin position': 'Posición de origen',
  Direction: 'Dirección',
  Duration: 'Duración',
  From: 'Desde',
  To: 'A',
  'Call detail records for': 'Registros de detalles de llamadas para',
  'Overall records': 'Registros generales',
  'Number most contacted': 'Número más contactado',
  'Number most received': 'Número más recibido',
  'Longest call': 'Llamada más larga',
  'Shortest call': 'Llamada más corta',
  'Select period': 'Seleccionar periodo',
  'last 30 days': 'Últimos 30 días',
  'last 60 days': 'Últimos 60 días',
  'last 90 days': 'Últimos 90 días',
  'File uploaded successfully!': '¡El archivo ha sido subido correctamente!',
  'Error while uploading file': 'Error al subir el archivo',
  or: 'o',
  'Browse files': 'Búsqueda de archivos',
  Downloading: 'Descargando',
  'Change password': 'Cambiar la contraseña',
  'Change Password': 'Cambiar la contraseña',
  English: 'Inglés',
  Spanish: 'Español',
  'Export call log': 'Exportar registro de llamadas',
  'Wrong template.': 'Plantilla incorrecta.',
  'Call log MSISDN is different from target MSISDN.':
    'El registro de llamadas MSISDN es diferente del MSISDN de destino.',
  'Invalid file format provided.':
    'Formato de archivo proporcionado no válido.',
  'If you edit or delete the MSISDN the Call Log for this target will be removed':
    'Si edita o elimina el MSISDN, se eliminará el registro de llamadas para este objetivo.',
  all: 'todos',
  monday: 'lunes',
  tuesday: 'martes',
  wednesday: 'miércoles',
  thursday: 'jueves',
  friday: 'viernes',
  saturday: 'sábado',
  sunday: 'domingo',
  weekdays: 'entre semana',
  weekends: 'fines de semana',
  'Call Log Request Pending': 'Registro llamadas solictud pendiente',
  'Session has expired!': '¡La sesión ha expirado!',
  'You will be redirected to login screen.':
    'Será redirigido a la pantalla de inicio de sesión.',
  'ALL QUERIES FOUND': 'TODAS LAS CONSULTAS ENCONTRADAS',
  'ALL UNREACHABLE QUERIES': 'TODAS LAS CONSULTAS NO ENCONTRADAS',
  'Unique entries': 'Entradas únicas',
  Found: 'Encontrado',
  Unreachable: 'No encontrado',
  'Last Hour': 'Ultima hora',
  'Last 24 hours': 'Últimas 24 horas',
  'Last week': 'Semana pasada',
  'No statistics available for the selected period.':
    'No hay estadísticas disponibles para el período seleccionado.',
  'License Plate': 'Placa',
  'Not enough information': 'No hay suficiente información',
  'Not enough geolocation data or call logs data to predict locations. Would you like to create a schedule?':
    'No hay suficientes datos de geolocalización o datos de registros de llamadas para predecir ubicaciones. ¿Le gustaría crear un horario?',
  'Would you like to import more data': 'Le gustaría importar más datos',
  'Would you like to a create a schedule': 'Le gustaría crear un horario',
  Yes: 'Sí',
  'Schedule has been created': 'El horario ha sido creado',
  'Request has been sent to support': 'La solicitud ha sido enviada a soporte',
  high: 'alto',
  medium: 'medio',
  low: 'bajo',
  'No. of calls': 'llamadas',
  Overall: 'en total',
  Count: 'Conteo',
  Hour: 'Hora',
  'Group ID': 'ID de grupo',
  DEBUG: 'Depurar',
  'Geo Query': 'Geo Consulta',
  'Intel Query': 'Intel Consulta',
  authentication: 'autenticación',
  ERROR: 'ERROR',
  INFO: 'INFO',
  'Preferred channel': 'Canal preferido',
  'OTP Channel': 'Canal de OTP',
  None: 'Ninguno',
  MNOs: 'Operador movil',
  Whitelist: 'Lista blanca',
  'Whitelist Number': 'Número de lista blanca',
  'Remove from whitelist': 'Eliminar de la lista blanca',
  Add: 'Añadir',
  'has been captured on': 'ha sido capturado en',
  'Inform Security Personel': 'Informar al Personal de Seguridad',
  'Toggle map': 'Mapa de Palanca',
  'Toggle photo': 'Alternar foto',
  'An error has occurred': 'Se ha producido un error',
  Imported: 'Importado',
  Tactical: 'Táctico',
  'Items per page': 'Elementos por página',
  'Next page': 'Siguiente página',
  'Previous page': 'Página anterior',
  'nearby towers found': 'torres cercanas encontradas',
  'Communication Channel': 'Canal de comunicación',
  'Please add phone to enable sms notifications':
    'Por favor, agregue un teléfono para habilitar las notificaciones de sms',
  'Please add email to enable email notifications':
    'Por favor, agregue un correo electrónico para habilitar las notificaciones de correo electrónico',
  'Disable sms notifications': 'Desactivar notificaciones sms',
  'Enable sms notifications': 'Habilitar notificaciones sms',
  'Enable email notifications':
    'Habilitar notificaciones de correo electrónico',
  'Disable email notifications':
    'Deshabilitar notificaciones de correo electrónico',
  'For authorized use only': 'Para uso autorizado únicamente.',
  'Notify me on Updates': 'Notificarme sobre Actualizaciones',
  'Inside of area': 'Dentro del área',
  'Pending... Click to cancel': 'Pendiente ... Haga clic para cancelar',
  'Whitelist Numbers': 'Números de la lista blanca',
  'Create a Geofencing Task (1 week / 15 minutes)':
    'Crear una geo-cerca (1 semana / 15 minutos)',
  'Create a Geofencing Task (6 hours / 15 minutes)':
    'Crear una geo-cerca (6 horas / 15 minutos)',
  'Stop Geofencing Task': 'Detener la tarea de geo-cerca',
  'Get the Neighbour Cells (1 credit)': 'Buscar Antenas Cercanas (1 crédito)',
  'Get the Neighbour Cells': 'Buscar Antenas Cercanas',
  'Create a Schedule Task (1 week / 15 minutes)':
    'Crear una tarea programada (1 semana / 15 minutos)',
  'Create a Schedule Task (6 hours / 15 minutes)':
    'Crear una tarea programada (6 horas / 15 minutos)',
  'Click for more options': 'Haga clic para más opciones',
  'Hide options': 'Ocultar opciones',
  Recipient: 'Recipiente',
  'Safety Cameras': 'Cámaras de seguridad',
  'Intelligence Query': 'Consulta de Inteligencia',
  'Call Log Cancel Request': 'Solicitud de cancelación de registro de llamadas',
  'Cancel Request sent successfully':
    'Cancelación de solicitud enviada con éxito',
  'Search by': 'Búsqueda por',
  'Contact Us': 'Contáctenos',
  'Maximum allowed size:': 'Tamaño máximo permitido:',
  'Support request': 'Solicitud de soporte',
  'Allowed file types: images': 'Tipos de archivo permitidos: imágenes',
  'Select file': 'Seleccione archivo',
  'Roaming Provider': 'Proveedor de itinerancia',
  LANGUAGE: 'IDIOMA',
  'Username must contain at least one letter':
    'El nombre de usuario debe contener al menos una letra',
  'View full profile': 'Ver todo el perfil',
  'View short profile': 'Ver perfil corto',
  'Social Media': 'Medios de comunicación social',
  Overview: 'Visión de conjunto',
  Feed: 'Actividad',
  'Phone links': 'Enlaces telefónicos',
  'Link analysis': 'Análisis de enlaces',
  'Link analysis v2': 'Análisis de enlaces v2',
  'Geo Follow': 'Geo Seguir',
  'Geolocation scheduler will start after radical speech detection!':
    '¡La tarea de geolocalización comenzará después de la detección radical del habla!',
  Monitoring: 'Vigilancia',
  'Radical speech monitoring': 'Monitoreo radical del habla',
  'Radical monitoring': 'Monitoreo radical',
  'results found': 'resultados encontrados',
  Start: 'Comienzo',
  Stop: 'Detener',
  Content: 'Contenido',
  Platform: 'Plataforma',
  'Target number': 'Número objetivo',
  'Analyzed content': 'Contenido analizado',
  'No social data for current targets!':
    'No hay datos disponibles para los objetivos actuales',
  'Permanently delete target': 'Eliminar permanentemente el objetivo',
  'Permanently delete case': 'Eliminar permanentemente el caso',
  'The files once deleted cannot be restored.':
    'Una vez eliminados, los archivos no se pueden restaurar.',
  'Once deleted, the cases cannot be restored.':
    'Una vez eliminados, los casos no se pueden restaurar.',
  'Are you sure you want to delete target permanently?':
    '¿Está seguro que desea eliminar el objetivo de forma permanente?',
  'Are you sure you want to delete case permanently?':
    '¿Está seguro que desea eliminar el caso de forma permanente?',
  'Create a target': 'Crea un objetivo',
  'Request Call Log': 'Solicitud de registro de llamadas',
  'Latest Location': 'Última Ubicación',
  'Location Heatmap': 'Mapa de calor de ubicación',
  'Location Timeline': 'Cronología de ubicación',
  'Location Probalility': 'Probabilidad de ubicación',
  'Loading call log data': 'Cargando datos del registro de llamadas',
  'Create new target': 'Crear nuevo objetivo',
  'Total number of sessions': 'Número total de sesiones',
  'Total monitor time': 'Tiempo total del monitor',
  'Create new keywords list': 'Crear nueva lista de palabras clave',
  'Configure keywords list': 'Configurar lista de palabras clave',
  'Create new list': 'Crear nueva lista',
  'Add new keywords list': 'Agregar nueva lista de palabras clave',
  'Session started on the': 'La sesión comenzó el',
  'and ended on the': 'y terminó el',
  'Keywords list': 'Lista de palabras clave',
  'No selected list': 'No hay lista seleccionada',
  'Maximum image size is 2MB.': 'El tamaño máximo de la imagen es de 2MB.',
  'Supported file types': 'Tipos de archivos soportados',
  'No call log found for this target. Please use the menu to request or upload a call log.':
    'No se encontraron registros de llamadas para este objetivo. Utilice el menú para solicitar o cargar un registro de llamadas.',
  'Export PDF': 'Exportar PDF',
  'Intelligence is not available yet': 'La inteligencia aún no está disponible',
  'Add seeds and click "Enchance target" to get "Most common faces"':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener los "Rostros más comunes"',
  'Social Media Profiles': 'Perfiles de redes sociales',
  Online: 'En línea',
  'Phone Info': 'Información del teléfono',
  'Location Info': 'Información de ubicación',
  'Show query details': 'Mostrar detalles de la consulta',
  more: 'más',
  'Select more targets': 'Selecciona más objetivos',
  'Finish adding targets': 'Terminar de agregar objetivos',
  'Add selected targets': 'Agregar objetivos seleccionados',
  'Date of creation': 'Fecha de creación',
  'Last modifications': 'Últimas modificaciones',
  'Finish editing': 'Finalizar edición',
  'Exporting call log': 'Exportar registro de llamadas',
  Inserted: 'Insertados',
  records: 'Registros',
  'Last Seen': 'Visto por última vez',
  Timeline: 'Cronograma',
  'Predicted locations': 'Predicción',
  'Places of interest': 'Lugares de interés',
  'Edit places': 'Editar lugares',
  'BASE LAYERS': 'CAPAS BASE',
  'ADDITIONAL LAYERS': 'CAPAS ADICIONALES',
  OPTIONS: 'OPCIONES',
  'Remove layer': 'Eliminar capa',
  'Enable layer': 'Habilitar capa',
  'Query MSISDN': 'Consultar MSISDN',
  'Invalid MSISDN': 'MSISDN no válido',
  'Loading target details': 'Cargando detalles del objetivo',
  'Enable traffic layer': 'Habilitar capa de tráfico',
  'Disable traffic layer': 'Deshabilitar capa de tráfico',
  'Enable target heatmap': 'Habilitar mapa de calor del objetivo',
  'Disable target heatmap': 'Deshabilitar mapa de calor del objetivo',
  'Enable target history timeline':
    'Habilitar la línea de tiempo del historial del objetivo',
  'Disable target history timeline':
    'Deshabilitar la línea de tiempo del historial del objetivo',
  'Available Data': 'Datos disponibles',
  'No Data Available': 'Datos no disponibles',
  'No data': 'Sin datos',
  'Resize map': 'Cambiar el tamaño del mapa',
  'Cases and targets': 'Casos y objetivos',
  'Show less': 'Muestra menos',
  'Create new case': 'Crear nuevo caso',
  Cases: 'Casos',
  'Call duration': 'Duración de llamada',
  'Always show descriptions': 'Mostrar siempre descripciones',
  'Hide descriptions': 'Ocultar descripciones',
  'Calls and SMS': 'Llamadas y SMS',
  Calls: 'Llamadas',
  'All week': 'Toda la semana',
  Weekdays: 'Entre semana',
  Weekends: 'Fines de semana',
  'Incoming and Outgoing': 'Entrantes y Salientes',
  Incoming: 'entrante',
  Outgoing: 'saliente',
  Cumulative: 'Acumulativas',
  Individual: 'Individuales',
  'No cases defined': 'No hay casos definidos',
  'No targets defined': 'No hay objetivos definidos',
  'No more than 255 characters long': 'No más de 255 caracteres de largo',
  'No more than 1024 characters long': 'No más de 1024 caracteres de largo',
  'You have more than 255 characters': 'Tiene más de 255 caracteres',
  'You have more than 1024 characters': 'Tiene más de 1024 caracteres',
  'Title is required': 'El título es requerido',
  'Area of Interest Query': 'Consulta de área de interés',
  Select: 'Seleccionar',
  'Redraw area': 'Redibujar área',
  'Select area': 'Seleccionar area',
  'Origin Position': 'Posición de origen',
  Data: 'Datos',
  Longest: 'Más larga',
  Shortest: 'Más corta',
  with: 'con',
  from: 'de',
  to: 'a',
  'data used at': 'datos utilizados en',
  Call: 'Llamada',
  at: 'a',
  'Live data': 'Datos en tiempo real',
  'Number of sessions': 'Número de sesiones',
  Busy: 'Ocupado',
  OFF: 'Apagado',
  ON: 'Encendido',
  'Last known location': 'Última ubicación conocida',
  Unknown: 'Desconocida',
  Wordcloud: 'Nube de Palabras',
  'Daily activity': 'Actividad diaria',
  'Weekly activity': 'Actividad semanal',
  MAPS: 'MAPAS',
  STATISTICS: 'ESTADÍSTICAS',
  'Related people': 'Personas relacionadas',
  'Sentiment analysis': 'Análisis de sentimiento',
  Entity: 'Entidad',
  Positive: 'Positivo',
  Neqative: 'Neqativo',
  Neutral: 'Neutral',
  Media: 'Media',
  'Most common faces': 'Caras más comunes',
  'Social profiles': 'Perfiles sociales',
  'Instant messaging': 'Mensajería instantánea',
  'Employment and education': 'Trabajo y educacion',
  'Add a new note': 'Agregar una nueva nota',
  Publish: 'Publicar',
  'Advanced Information Request': 'Solicitar Informacion Avanzada',
  'AT&T post-paid only': 'AT&T post pago únicamente',
  'This action will deduct 4 credits. This may take up to 30 min.':
    'Esta acción deducirá 4 créditos de su cuenta. Puede durar hasta 30 minutos.',
  Proceed: 'Proceder',
  'Upload image': 'Cargar imagen',
  'Draw marker': 'Dibujar marcador',
  'Note was added': 'Nota agregada',
  'Image attached': 'Imágen adjunta',
  'Add a note': 'Agregar una nota',
  'Added on': 'Añadido el',
  'View post details': 'Ver detalles de la publicación',
  'View geolocation details': 'Ver detalles de geolocalización',
  'Post was added': 'Publicación agregada',
  Post: 'Publicación',
  Notes: 'Notas',
  'Time of collection': 'Hora de recolección',
  'Geolocation details': 'Detalles de geolocalización',
  'Date created': 'Fecha de creación',
  'Last modified': 'Última modificación',
  'No phone links defined': 'No hay enlaces telefónicos definidos',
  'Name(s)': 'Nombre(s)',
  'Phone(s)': 'Telefono(s)',
  'MSISDN(s)': 'MSISDN(s)',
  Countries: 'Países',
  'Address(es)': 'Dirección',
  'Add new': 'Agregar nuevo',
  'Time Zone': 'Zona horaria',
  'Job(s)': 'Trabajos',
  'Date Of Birth': 'Fecha de nacimiento',
  'Place Of Birth': 'Lugar de nacimiento',
  'Marital Status': 'Estado civil',
  Nationalities: 'Nacionalidades',
  Race: 'Raza',
  'Username(s)': 'Nombre de usuario(s)',
  'License Plate(s)': 'Matrículas',
  'Work Place(s)': 'Lugar(es) de trabajo',
  'Eyes Color': 'Color de ojos',
  'Hair Color': 'Color de cabello',
  'Facial Hair': 'Cabello facial',
  'Social Insurance': 'Seguridad Social',
  Height: 'Altura',
  Weight: 'Peso',
  'Email(s)': 'Email(s)',
  'IMSI(s)': 'IMSI(s)',
  Remove: 'Eliminar',
  'Add new value': 'Agregar nuevo valor',
  'Enter phone numbers': 'Ingrese números de teléfono',
  'Type value here': 'Escriba el valor aquí',
  'Edit a target': 'Editar un objetivo',
  Create: 'Crear',
  Update: 'Actualizar',
  'Must be at least 3 characters long, special charactes and preceding or following spaces are not allowed':
    'La longitud deberá ser de al menos 3 carácteres, no están permitidos caracteres especiales ni seguido o precedido por espacios.',
  'Select timezone': 'Seleccionar zona horaria',
  Items: 'Artículos',
  'Once deleted the data cannot be restored':
    'Una vez eliminados, los datos no se pueden restaurar',
  'Alias exists. Please use another alias':
    'Este Alias ya existe. Por favor, utilice otro alias',
  'Title exists. Please use another title':
    'Este Título ya existe. Por favor, utilice otro título',
  'New note text': 'Nueva nota de texto',
  Others: 'Otros',
  'You can use the Apps button to navigate to the Cases & Targets page. By clicking done, you will permanently hide this message':
    'Puede utilizar el icono de aplicaciones para acceder “a” la página de Casos y Objetivos. Haciendo click “aquí” puede ocultar “este” mensaje de forma permanente.',
  'Back to "Cases and targets"': 'Volver a "Casos y objetivos"',
  'Note created successfully!': '¡Nota creada con éxito!',
  'Note has not been created': 'La nota no ha sido creada',
  'User equipment': 'Equipo de usuario',
  'Mobile network': 'Red móvil',
  Image: 'Imagen',
  Birthday: 'Cumpleaños',
  'Remove from case': 'Eliminar del caso',
  'Remove target from case': 'Eliminar objetivo del Caso',
  'Are you sure you want to remove target from this case?':
    '¿Está seguro que desea eliminar el objetivo de este caso?',
  'was extracted successfully. Information was also added in Target Call Log Analysis':
    'se extrajo correctamente. La información ha sido añadida al Análisis del Registro de Llamadas del Objetivo',
  'Geolocation query successfully submitted':
    'Consulta de geolocalización enviada correctamente',
  'number successfully located': 'número localizado con éxito',
  'number could not be located': 'número no pudo ser localizado',
  'was located during a call.': 'Se ha encontrado durante una llamada',
  'Do you want to extract the Peer Caller ID? This action will deduct 4 credits.':
    '¿Desea extraer el identificador de llamadas? Esta acción deducirá 4 créditos.',
  'Do you want to extract the Peer Caller ID?':
    '¿Desea extraer el identificador de llamadas?',
  Satellite: 'Satélite',
  Catcher: 'Receptor',
  'Current Call': 'Llamada actual',
  'Group By': 'Agrupar Por',
  'No conversations': 'Sin conversaciones',
  Hometown: 'Ciudad natal',
  Education: 'Educación',
  Work: 'Trabajo',
  No: 'No',
  items: 'artículos',
  item: 'artículo',
  Medium: 'Medio',
  'Add note': 'Agregar nota',
  'Select targets': 'Seleccionar objetivos',
  'Cancel request sent successfully!':
    '¡Peticion cancelada satisfactoriamente!',
  'with a duration of': 'con una duración',
  'Related numbers': 'Números relacionados',
  IMEI: 'IMEI',
  'Lat/Lon': 'Lat/Lon',
  'Duration (s)': 'Duración',
  TMSI: 'TMSI',
  CALLEE: 'Número Llamado',
  CALLER: 'Número Llamador',
  ID: 'ID',
  Redraw: 'Volver a dibujar',
  'Log on task': 'Registrar una tarea',
  'Create a case': 'Crear un caso',
  Description: 'Descripción',
  'View note details': 'Ver detalle de las notas',
  'Minimize map': 'Minimizar mapa',
  'Maximize map': 'Ampliar el mapa',
  Negative: 'Negativo',
  'Target Edit': 'Editar objetivo',
  Note: 'Nota',
  'items on': 'artículos en',
  'Please insert more than one phones to perform a proximity query':
    'Por favor agregue más de un número para poder realizar la consulta de proximidad',
  'Get the CallerID (4 credits)':
    'Obtener el número de la llamada (4 créditos)',
  'Get the CallerID': 'Obtener el número de la llamada',
  Video: 'Video',
  Vendor: 'Fabricante',
  Model: 'Modelo',
  Device: 'Dispositivo',
  Browser: 'Navegador',
  'No Data': 'Sin datos',
  Caller: 'Llamador',
  Callee: 'Llamante',
  'Query and schedule': 'Consulta y horario',
  'Keywords monitored': 'Palabras clave monitoreadas',
  'Clear selection': 'Selección clara',
  'Export selected': 'Exportar seleccionado',
  'Monitoring is': 'El monitoreo es',
  'Select list': 'Seleccionar lista',
  'Update the graph': 'Actualizar el gráfico',
  'Conversation Type': 'Tipo de conversación',
  'Copy all': 'Copiar todo',
  saved: 'guardado',
  'Save as a list from': 'Guardar como una lista de',
  'Timebar type': 'Tipo de barra de tiempo',
  histogram: 'histograma',
  area: 'zona',
  'Filter panel': 'Panel de filtro',
  Person: 'Persona',
  Organization: 'Organización',
  Place: 'Sitio',
  Topic: 'Tema',
  'items found': 'artículos encontrados',
  Nodes: 'Nodos',
  Relations: 'Relaciones',
  Links: 'Enlaces',
  Summary: 'Resumen',
  Locations: 'Lugares',
  'Social relation': 'Relación social',
  'Add seeds or Perform an OSINT query to retrieve more information':
    'Agregue semillas o realice una consulta OSINT para obtener más información',
  target: 'objetivo',
  'Select a target to update': 'Seleccione un objetivo para actualizar',
  'Clear all': 'Limpiar todo',
  'Target has not been update': 'El objetivo no ha sido actualizado',
  'Target updated successfully!': '¡Objetivo actualizado con éxito!',
  Analyse: 'Analizar',
  'Common relations': 'Relaciones comunes',
  'Update existing target': 'Actualizar destino existente',
  Labels: 'Etiquetas',
  'Incoming call': 'Llamada entrantes',
  'Outgoing call': 'Llamada salientes',
  'Incoming sms': 'SMS entrantes',
  'Outgoing sms': 'SMS salientes',
  'Unknown type': 'Tipo desconocido',
  'Live Session has been successfully obtained':
    'La sesión en vivo se ha obtenido con éxito',
  'Live session request created successfully!':
    '¡Solicitud de sesión en vivo creada con éxito!',
  'Copy to Clipboard': 'Copiar al portapapeles',
  'Following on Twitter': 'Siguiendo en Twitter',
  'Follower on Twitter': 'Seguidor/Seguidora en Twitter',
  'Following on Instagram': 'Siguiendo en Instagram',
  'Follower on Instagram': 'Seguidor/Seguidora en Instagram',
  'Facebook Friend': 'Amigo/Amiga de Facebook',
  'Family member on Facebook': 'Miembro de la familia en Facebook',
  'targets found': 'objetivos encontrados',
  'Link Analysis': 'Análisis de enlaces',
  Names: 'Nombres',
  Usernames: 'Nombres de usuario',
  Emails: 'Correos electrónicos',
  'Profile URLs': 'URL de perfil',
  Languages: 'Idiomas',
  Addresses: 'Direcciones',
  'Copied!': '¡Copiado!',
  Copy: 'Copiar',
  Profile: 'Perfil',
  queue: 'cola',
  'locate all': 'ubicar todo',
  'No items added': 'No se agregaron elementos',
  'create a new target': 'crear un nuevo objetivo',
  'Add to an existing target': 'Añadir a un objetivo existente',
  cancel: 'Cancelar',
  'Add and analyse': 'Agregar y analizar',
  filters: 'filtros',
  'filter by location': 'filtrar por ubicación',
  'Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok':
    'URL de perfil para Facebook, Twitter, Instagram, Linkedin, Tiktok',
  'search history': 'buscar historial',
  'Show search': 'Mostrar búsqueda',
  delete: 'eliminar',
  'end of history': 'fin de la historia',
  'Basic OSINT Search': 'Búsqueda básica de OSINT',
  'geo-fencing': 'geo-cerca',
  'cases and targets': 'casos y objetivos',
  'geo location': 'ubicación geográfica',
  'Search again': 'Busca de nuevo',
  addresses: 'direcciones',
  countries: 'países',
  educations: 'educaciones',
  emails: 'correos electrónicos',
  gender: 'género',
  imeis: 'imeis',
  jobs: 'trabajos',
  names: 'nombres',
  photos: 'fotos',
  telnos: 'números de teléfono',
  usernames: 'nombres de usuario',
  profileUrls: 'URL de perfil',
  MSISDNs: 'Números de teléfono',
  'Target summary': 'Resumen de objetivos',
  'Live Session': 'Sesión en vivo',
  'search results found for': 'resultados de búsqueda encontrados para',
  on: 'en',
  'select all': 'seleccionar todo',
  'items selected': 'artículos seleccionados',
  'Fetching facebook friends': 'Obteniendo amigos de Facebook',
  'Show nodes that interacted with the target by':
    'Mostrar nodos que interactuaron con el objetivo por',
  'times or more': 'veces o más',
  'Rest of the week': 'El resto de la semana',
  'Back to Dashboard': 'Volver al tablero',
  Case: 'Caso',
  'Social Profile': 'Perfil Social',
  'Confirmed infection': 'Infección Confirmada',
  'Potential infection': 'Infección Potencial',
  'Confirmed Infection': 'Infección Confirmada',
  'Potential Infection': 'Infección Potencial',
  'Target Infected': 'Objetivo Infectado',
  'Target received a call from': 'El Objetivo recibió una llamada de',
  'time(s)': 'veces',
  'Target called': 'El Objetivo fue llamado',
  'Target received a message from': 'El Objetivo recibió un mensaje de',
  'Target messaged': 'OEl Objetivo envió un mensaje a',
  'interacted with': 'interactuó con',
  'Is following': 'Está siguiendo',
  'Is followed by': 'Es seguido por',
  'has family relation with': 'tiene relación familiar con',
  'Connected with MSISDN': 'Conectada/o con MSISDN',
  'Is/was working as': 'Está / estaba trabajando como',
  'Possibly owns this social profile': 'Posiblemente posee este perfil social',
  'Target case': 'Caso objetivo',
  'Facebook friends': 'Amigo/Amiga de Facebook',
  'unknown type': 'tipo desconocido',
  'Discover persons': 'Descubrir personas',
  'Location history': 'Historial de ubicaciones',
  'Select/unselect location!': '¡Seleccionar/deseleccionar ubicación!',
  Discover: 'Descubrir',
  'show less': 'Mostrar menos',
  ' more': ' mostrar más',
  'No targets selected!': 'Ningun objetivo ha sido seleccionado',
  'The target changes have been saved. Click':
    'Los cambios al objetivo han sido guardados. Haga clic',
  here: 'aquí',
  'to view the target profile': 'para ver el perfil del objetivo',
  'The target changes have been saved and analysis is started. Click':
    'Los cambios al objetivo han sido guardados y inició el análisis. Haga clic',
  found: 'encontrado',
  'Show nodes that came in contact with the target by distance of':
    'Mostrar nodos que entraron en contacto con el objetivo por distancia de',
  'meters or less': 'metros o menos',
  'GEO query': 'Consulta GEO',
  'Discovered persons': 'Personas descubiertas',
  "Create a case with the target's alias":
    'Crear un caso con el alias del objetivo',
  'Company Employees': 'Empleados de la Empresa',
  'less than 50 meters': 'menos de 50 metros',
  'between 50-100 meters': 'entre 50-100 metros',
  'over 100 meters': 'más de 100 metros',
  'Please add at least two fields to create a note.':
    'Agregue al menos dos campos para crear una nota.',
  'Linkedin Employee': 'Empleado/a de Linkedin',
  'Target is assigned to this case': 'El objetivo se asigna a este caso',
  'Company employee': 'Empleado/a de la empresa',
  'Double click to expand/collapse node':
    'Haga doble clic para expandir / contraer el nodo',
  'IMEI(s)': 'IMEI(s)',
  'View more': 'Ver más',
  'View less': 'Ver menos',
  'Please complete all fields!': 'Por favor, ¡complete todos los campos',
  'Add new platform': 'Agregar nueva plataforma',
  Workplaces: 'Lugares de trabajo',
  'Landline(s)': 'Línea(s) Fija(s)',
  'Common relations on social networks': 'Relaciones comunes en redes sociales',
  'Found for': 'Encontrado para',
  'Go to target page': 'Ir a la página de destino',
  'Common faces on photos': 'Rostros comunes en fotos',
  'Common working places': 'Lugares de trabajo en común',
  'Common education places': 'Centros educativos en común',
  'Paste here the url for the platform': 'Pegue aquí la url de la plataforma',
  successful: 'exitosa',
  One: 'Una',
  intel: 'intel',
  query: 'consulta',
  call: 'llamada',
  info: 'informacion',
  emergency: 'emergencia',
  advanced: 'avanzada',
  geolocation: 'geolocalización',
  'Enter a address': 'Ingrese una dirección',
  'Enter emails': 'Ingrese correos electrónicos',
  'Collected Profile URL': 'URL de perfil recopilado',
  Apps: 'Aplicaciones',
  'Targets are now here': 'Los objetivos ya están aquí',
  'Please Enter Username': 'Por favor, introduzca el nombre de usuario',
  'Please Enter Password': 'Por favor, ingrese contraseña',
  'Case targets': 'Objetivos del caso',
  'This case has no targets': 'Este caso no tiene objetivos',
  ' Create a new one ': ' Cree uno nuevo ',
  'or ': 'o ',
  'Add an existing target': 'Agregar un objetivo existente',
  'Select case targets': 'Seleccionar objetivos del caso',
  'Add to case': 'Añadir al caso',
  'Filter targets': 'Filtrar objetivos',
  'Targets added successfully!': '¡Objetivos agregados con éxito!',
  'This case needs at least 2 targets to find common information':
    '¡Este caso necesita al menos 2 objetivos para encontrar información común!',
  'No data available': 'Datos no disponibles',
  'Add social profiles to the targets to view data':
    'Agregue perfiles sociales a los objetivos para ver datos',
  name: 'nombre',
  username: 'nombre de usuario',
  url: 'url',
  details: 'detalles',
  'Please fill in both fields for the date':
    'Por favor, complete ambos campos para la fecha',
  'show more': 'mostrar más',
  targets: 'objetivos',
  'Switch to detail view': 'Cambiar a vista detallada',
  'Switch to standard view': 'Cambiar a vista estándar',
  'Search by name': 'Buscar por nombre',
  Layout: 'Diseño',
  of: 'de',
  'This option will be available for': 'Esta opción estará disponible para',
  'Advanced geolocation': 'Geolocalización avanzada',
  'Would you like to obtain AGPS/GPS location for':
    '¿Le gustaría obtener la ubicación AGPS/GPS para',
  'This action will deduct 3 credits.': 'Esta accíon le deducirá 3 créditos.',
  'This action will deduct 2 credits.': 'Esta accíon le deducirá 2 créditos.',
  'Copy link': 'Copiar link',
  comments: 'comentarios',
  likes: 'gustos',
  'persons tagged': 'personas etiquetadas',
  'Get Photo Info': 'Obtener información de la foto',
  'Persons tagged': 'personas etiquetadas',
  'Result type': 'Tipo de Resultado',
  'Open Web': 'Web Abierta',
  'See more results': 'Ver más resultados',
  'See less results': 'Ver menos resultados',
  Maximize: 'Maximizar',
  Minimize: 'Minimizar',
  'View in fullscreen': 'Ver en pantalla completa',
  'Your browser is not capable to view in fullscreen. Please switch to a latest browser.':
    'Su navegador no soporta pantalla completa. Cambie a un navegador más reciente.',
  'Exit full screen': 'Salir de pantalla completa',
  'Full screen': 'Pantalla completa',
  'You can select multiple nodes by holding down Ctrl key and clicking on a node.':
    'Puede seleccionar varios nodos manteniendo presionada la tecla Ctrl y haciendo clic en un nodo.',
  'Then you have the ability to:': 'Entonces tiene la capacidad de:',
  'move the selected nodes by dragging using the mouse or with the keyboard arrows.':
    'mueva los nodos seleccionados arrastrándolos con el cursor o con las flechas del teclado.',
  'filter out the selected nodes by pressing the Delete key.':
    'filtrar los nodos seleccionados presionando la tecla Eliminar.',
  'Monitor changes': 'Monitorear cambios',
  'Please select a Social Profile!': '¡Por favor, seleccione un perfil social!',
  'Please select a date!': '¡Por favor seleccione una fecha!',
  'Select Social Profile': 'seleccione un perfil social',
  Feedback: 'Reacción',
  'Selected date must be in the past!':
    '¡La fecha seleccionada debe estar en el pasado!',
  'This post contains': 'Esta publicación contiene',
  images: 'imágenes',
  videos: 'videos',
  'click to expand.': 'haga clic para ampliar.',
  'Your browser does not support video': 'Su navegador no soporta video',
  'Show updates': 'Mostrar actualizaciones',
  'Facebook group member': 'Miembro del grupo de Facebook',
  'photo details': 'detalles de la foto',
  'Open in a new tab': 'Abrir en una pestaña nueva',
  'Copy URL': 'Copiar URL',
  'Would you like to obtain more accurate location for':
    '¿Desea obtener una ubicación más precisa para',
  'Removes all filters, keeps new nodes from analyse':
    'Elimina todos los filtros, evita que se analicen nuevos nodos',
  Reload: 'Recargar',
  'Removes everything (including nodes from analyse) and reloads all target data':
    'Elimina todo (incluidos los nodos del análisis) y vuelve a cargar todos los datos de destino',
  'Reload chart with latest target data':
    'Recargar gráfico con los últimos datos de destino',
  'Do you wish to reload the chart? Any data created from analysing nodes will be removed':
    '¿Desea recargar el gráfico? Se eliminarán todos los datos creados a partir de los nodos de análisis.',
  'Group Member on Facebook': 'Miembro del grupo en Facebook',
  Other: 'Otro',
  'Group Name': 'Nombre del grupo',
  Members: 'Miembros',
  discovery: 'Descubrimiento',
  Discovery: 'Descubrimiento',
  'Check-ins': 'Lugares',
  'License Plates': 'Placas Vehiculares',
  'Work Place': 'Lugar de Trabajo',
  'Voter Id': 'ID de Votación',
  Workplace: 'Lugar de trabajo',
  Present: 'Presente',
  'Case has not been created': 'El caso no ha sido creado',
  'Select date': 'Seleccione fecha',
  'Select days': 'Seleccionar días',
  'Facebook Group': 'Grupo de Facebook',
  'POI type': 'Tipo de POI',
  SMS: 'SMS',
  Sms: 'Sms',
  'Show Release Notes': 'Mostrar Información Adiciconal',
  'List name': 'Nombre de la lista',
  'Enter the keywords': 'Ingrese palabras clave',
  live: 'En vivo',
  'Live monitoring started at': 'El monitoreo en tiempo real empezó el',
  'View session details': 'Ver detalles de la sesión',
  'User unlocked successfully!': 'Usuario desbloqueado con éxito!',
  'User locked successfully!': 'Usuario bloqueado con éxito!',
  'Create a temporary user': 'Crear un usuario temporal',
  'This is a 24hrs temporary user': 'Este es un usuario temporal las 24 horas.',
  'Lock Temporary User': 'Bloquear usuario temporal',
  'Lock user': 'Bloquear usuario',
  'Time remaining': 'Tiempo restante',
  Login: 'Iniciar sesión',
  'Please provide your email address':
    'Por favor proporcione su dirección de correo electrónico',
  was: 'estaba',
  disabled: 'discapacitada',
  for: 'para',
  'for:': 'para:',
  enabled: 'habilitada',
  changed: 'cambiada',
  quota: 'creditos',
  empty: 'espacio',
  space: 'vacio',
  roles: 'roles',
  notifications: 'notificaciones',
  unlocked: 'desbloqueada',
  locked: 'bloqueada',
  'update-user': 'actualizar usuario',
  'create-user': 'crear usuario',
  Likers: 'Me gusta',
  'View all likers': 'Ver todos los me gusta',
  Commenters: 'Comentaristas',
  'View all commenters': 'Ver todos los comentarista',
  'reset-password': 'restablecer la contraseña',
  'otp-email': 'otp email',
  'otp-phone': 'teléfono otp',
  recipient: 'receptor',
  sender: 'remitente',
  'Proximity Analysis': 'Análisis de proximidad',
  'Common locations': 'Ubicaciones comunes',
  'Phone model': 'Modelo de teléfono',
  'Assign to target or add to existing':
    'Asignar a segmentar o agregar a existente',
  'Back to previous query': 'Volver a la consulta anterior',
  'Perform a location history query for the selected rows':
    'Realizar una consulta del historial de ubicaciones para las filas seleccionadas',
  'Perform a follow query for the selected rows':
    'Realizar una consulta de seguimiento para las filas seleccionadas',
  'Perform a proximity query for the selected row':
    'Realizar una consulta de proximidad para la fila seleccionada',
  'Active proximity items': 'Elementos de proximidad activos',
  'Existing target': 'Objetivo existente',
  'New target': 'Nuevo objetivo',
  'Search Google Maps': 'Buscar en Google Maps',
  'Your opinion is very important': 'Su opinión es muy importante',
  'Please take this survey to rate your experience with':
    'Por favor, conteste esta encuesta para calificar su experiencia con',
  'Not now': 'Ahora no',
  new: 'nuevo',
  user: 'usuario',
  created: 'creado',
  'created:': 'creado:',
  set: 'conjunto',
  'Invalid values. Please try again. Username must contain at least one letter.':
    'Valores inválidos. Inténtalo de nuevo. El nombre de usuario debe contener al menos una letra.',
  'Twitter hashtag': 'Twitter hashtag',
  'Show statistics': 'Mostrar estadísticas',
  'Hide statistics': 'Ocultar estadísticas',
  Coordinates: 'Coordinar',
  'Favorite count': 'Conteo favorito ',
  'Retweeted count': 'Conteo retwitteado',
  Account: 'Cuenta',
  'This action will create the Locate query that is associated with':
    'Esta acción creará la consulta de localización asociada con',
  'This action will fetch all the Basic OSINT that are associated with':
    'Esta acción buscará todos los OSINT básicos asociados con',
  'Basic OSINT will discover more seeds':
    'OSINT Básico descubrirá más semillas',
  'Discover more seeds': 'Descubrirá más semillas',
  'Perform any of the OSINT Stage to retrieve important intelligence information':
    'Realice alguna de las consultas OSINT para obtener información importante de inteligencia',
  'Add seeds and click "Enchance target" to retrieve social profiles':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener perfiles sociales',
  'Add seeds and click "Enchance target" to retrieve instant messaging apps':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener apps de mensajería instantánea',
  'Add seeds and click "Enchance target" to retrieve employment and education':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener información sobre empleador y educación',
  'Add seeds and click "Enchance target" to retrieve relationship information':
    'Agregar semillas y haga clic en "Mejorar perfil" para obtener información sobre relaciones',
  'Add seeds and click "Enchance target" to retrieve media':
    'Agregar semillas o haga clic en "Mejorar perfil" para obtener imágenes',
  'Add a Facebook account and click "Enchance target" to retrieve more information':
    'Agregue una cuenta de Facebook o haga clic en "Mejorar Perfil" para obtener más información',
  'No locations found for the target <br> Click "Locate" or "#{Request call log}" to retrieve locations':
    'No se encontraron ubicaciones para este objetivo <br> Haga clic en "Localizar" o en “#{Solicitar el Registro de Llamadas}” para obtener ubicaciones',
  'No locations found for the target <br> Click "#{Request call log}" to retrieve locations':
    'No se encontraron ubicaciones para este objetivo <br> Haga clic en “#{Solicitar el Registro de Llamadas}” para obtener ubicaciones',
  'No locations found for the target <br> Add seeds to retrieve more information':
    'No se encontraron ubicaciones para este objetivo <br> Agregue semillas para obtener más información',
  'Enter a value': 'Introduzca un valor',
  'Basic OSINT': 'OSINT Básico',
  'Simple OSINT': 'OSINT Simple',
  'Advanced Webint': 'WebInt avanzado',
  'Ad IDs': 'Ad IDs',
  'Something went wrong please try again later!':
    'Algo salió mal, por favor, intente de nuevo más tarde!',
  DATA: 'Datos',
  'Call in': 'Entrada',
  'Call out': 'Salida',
  'SMS in': 'SMS Entrada',
  'SMS out': 'SMS Salida',
  'Back to Case': 'Volver al caso',
  'Search history': 'Historial de búsqueda',
  'Analyze location': 'Analizar ubicación',
  'User Equipment': 'Dispositivo del usuario',
  'Mobile Network': 'Red móvil',
  'Target Details': 'Detalles del objetivo',
  darkweb: 'Darkweb',
  profiles: 'Perfiles',
  'This action will locate the': 'Esta acción localizará el',
  'AD ID': 'AD ID',
  'Last call log location': 'Ubicación del registro de la última llamada',
  'Target Profile': 'Perfil objetivo',
  'Active in': 'Activo en',
  cases: 'casos',
  'Open target profile': 'Abrir perfil de destino',
  'Select Dates': 'Seleccionar fechas',
  'We were not able to bring mutual facebook friends at the moment.':
    'No pudimos traer amigos mutuos de Facebook en este momento.',
  'We were not able to bring company employees at the moment.':
    'No pudimos traer empleados de la empresa en este momento.',
  'Current target has no MSISDN attached!':
    '¡El objetivo actual no tiene ningún MSISDN adjunto!',
  'Add a valid MSISDN': 'Agregar un MSISDN válido',
  Industry: 'Industria',
  'Company location': 'Ubicación de la compañía',
  'Current city': 'Ciudad actual',
  'Radical monitoring service is down':
    'El servicio de monitoreo radical está caído',
  'Start monitoring': 'Empezar a monitorear',
  'Stop monitoring': 'Parar de monitorear',
  "You don't have any monitoring created": 'No ha creado ninguna supervisión',
  'To start a monitor': 'Para iniciar un monitor',
  'enter keywords': 'ingrese las palabras clave',
  'to be monitored, or create a': 'a monitorear, o cree una',
  'keyword list': 'lista de palabras clave',
  'Keyword lists': 'Listas de palabras clave',
  'to reuse and select': 'para reutilizar, seleccione también las',
  'social platforms': 'plataformas sociales',
  'you want to be monitored': 'que desea monitorear',
  'No keyword lists': 'Sin listas de palabras clave',
  'All social platforms': 'Todas las plataformas sociales',
  'Total number of matches': 'Número total de coincidentes',
  'Average matches per session': 'Coincidentes promedio por sesión',
  'No keyword lists created': 'No se crearon listas de palabras clave',
  'Close session details': 'Cerrar los detalles de la sesión',
  'started on': 'comenzó en',
  'and ended on': 'y terminó en',
  'Scanned and added to feed': 'Escaneado y agregado a la alimentación',
  'No lists selected': 'No hay listas seleccionadas',
  'This monitor did not collect any data':
    'Este monitor no recopiló ningún dato',
  'The monitor is collecting data, it will be presented as soon as it is discovered':
    'El monitor está recopilando datos, se presentarán tan pronto como se descubra',
  'Live monitoring': 'El monitoreo en tiempo real',
  'started at': 'comenzó en',
  'No keywords': 'Sin palabras clave',
  'Radical monitoring matched a keyword for':
    'El monitoreo radical coincidió con una palabra clave para',
  Geolocate: 'Geolocalizar',
  'View item': 'Ver ítem',
  'platform selected': 'plataforma seleccionada',
  'platforms selected': 'plataformas seleccionadas',
  'list selected': 'lista seleccionada',
  'lists selected': 'listas seleccionadas',
  'Interested in': 'Interesado en',
  'Email to support': 'Enviar Email a Soporte',
  'Multiple cases': 'Múltiples casos',
  'Last query': 'Última consulta',
  'Not active': 'No activo',
  'Please contact your administrator': 'Por favor contacte a su administrador',
  'Analyze in progress': 'Analizar en progreso',
  'Advanced Webint query created successfully!':
    '¡Consulta de Webint avanzada creada con éxito!',
  'Basic Osint query created successfully!':
    'Consulta básica de Osint creada con éxito!',
  'Collecting and analysing intelligence for':
    'Recolectando y analizando inteligencia para',
  'Searching social profiles': 'Buscando perfiles sociales',
  'Searching instant message profiles':
    'Buscando perfiles de mensajería instantánea',
  'Searching dark web': 'Buscando en la Dark Web',
  'Searching open web': 'Buscando en la red abierta',
  'Searching closed databases': 'Buscando bases de datos cerradas',
  'In progress': 'En progreso',
  'The OSINT action for': 'La acción OSINT para',
  'is done': 'está hecho',
  'Would you like to see target profile':
    'Le gustaría ver el perfil de destino',
  has: 'tiene',
  'Please enter a valid TRG email!':
    'Por favor ingrese un correo electrónico TRG válido!',
  'User ID': 'ID de usuario',
  'Last Activity': 'Útima vez visto',
  'Private Profile': 'Perfil privado',
  'Private profile': 'Perfil privado',
  'Joined At': 'Se unió el',
  'OSINT completed successfully': 'OSINT completado con éxito',
  View: 'Ver',
  'Analysing data': 'Analizando datos',
  'Create group': 'Crea un grupo',
  'Add group name': 'Agregar nombre de grupo',
  Loading: 'Cargando',
  Correlator: 'Correlator',
  'Call log upload request for author':
    'Solicitud de carga del registro de llamadas para la autora',
  'with msisdn': 'con msisdn',
  login: 'Inicio de Sesión',
  logout: 'Cierre de Sesión',
  'User:': 'El Usuario:',
  'sucessfully logged in': 'inició sesión satisfactoriamente',
  'sucessfully logged out': 'cerró sesión satisfactoriamente',
  Unlimited: 'Ilimitado',
  'Request call log limit reached. Please contact support.':
    'Límite de solicitud de registro de llamadas alcanzado. Por favor, contacte a soporte',
  'Top associates': 'Asociados principales',
  'Show only profiles with': 'Mostrar sólo perfiles con',
  'All Types': 'Todos los tipos',
  Filters: 'Filtros',
  'All addresses': 'Todos los direcciones',
  'All educations': 'Toda la educación',
  Educations: 'Educación',
  Companies: 'Empresas',
  'All jobs': 'Todos los trabajos',
  'All names': 'Todos los nombres',
  'All urls': 'Todas las URLs',
  'Social URLs': 'URLs de redes sociales',
  'Phone numbers': 'Números de teléfono',
  'All phone numbers': 'Todos los números de teléfono',
  'All usernames': 'Todos los usuarios',
  'All emails': 'Todos los correos electrónicos',
  Profiler: 'Perfilador',
  Age: 'Edad',
  'More filters': 'Más filtros',
  'Select location': 'Seleccionar ubicación',
  'Enter a location': 'Ingresar ubicación',
  Apply: 'Aplicar',
  'Choose age range': 'Seleccione rango de edad',
  Connections: 'Conexiones',
  'For example': 'Por ejemplo',
  Jobs: 'Trabajos',
  'Free text': 'Texto libre',
  'Show all': 'Mostrar todos',
  'Show only starred': 'Mostrar sólo seleccionado',
  'Search type': 'Tipo de búsqueda',
  'Search query': 'Término de búsqueda',
  Results: 'Resultados',
  'Created at': 'Creado el',
  'Clear all history': 'Limpiar todo el historial',
  Anytime: 'En cualquier momento',
  'Last 7 days': 'Últimos 7 días',
  'Last 30 days': 'Últimos 30 días',
  'Last 90 days': 'Últimos 90 días',
  'Last page': 'Última página',
  'First page': 'Primera página',
  'Recent searches': 'Búsquedas recientes',
  'See search history': 'Ver historial de búsqueda',
  results: 'resultados',
  'We found': 'Encontramos',
  'Advanced Geo Query': 'Consulta geográfica avanzada',
  image: 'imagen',
  'Start typing phone, name, url, email or username or upload photo':
    'Comience a escribir teléfono, nombre, url, correo electrónico o nombre de usuario o cargue una foto',
  And: 'y',
  'My target': 'Mi objetivo',
  'All users': 'Todas las usuarias',
  'See more': 'Ver más',
  'See less': 'Ver menos',
  'seeds selected': 'semillas seleccionadas',
  'Choose profiles or seeds to build your target':
    'Elija perfiles o semillas para construir su objetivo',
  'Add to target': 'Agregar al objetivo',
  Added: 'Agregada',
  Source: 'Fuente',
  selected: 'seleccionada',
  'Clear All': 'Limpiar todo',
  Telnos: 'Números de teléfono',
  Profileurls: 'URLs de redes sociales',
  'Create Target': 'Crear objetivo',
  'Create a new target': 'Crea un nuevo objetivo',
  seeds: 'semillas',
  'Create new target or search for existing one':
    'Cree un nuevo objetivo o busque uno existente',
  'Start typing job description':
    'Empiece a escribir la descripción del trabajo',
  'Type any term you would like to add':
    'Escriba cualquier término que le gustaría agregar',
  'Profile photos': 'Fotos de perfil',
  'Text Analysis': 'Análisis de texto',
  Miscellaneous: 'Varios',
  'Miscellaneous ': 'Varios',
  Cardinal: 'Cardinal',
  Numerical: 'Números',
  Product: 'Producto',
  Ordinal: 'Ordinal',
  'Work of art': 'Obra de arte',
  Percent: 'Porcentaje',
  Event: 'Evento',
  Quantity: 'Cantidad',
  Broadcaster: 'Locutor',
  Money: 'Dinero',
  Organizations: 'Organizaciones',
  People: 'Personas',
  'Social groups': 'Grupos sociales',
  'Geo Political Entities': 'Entidades Geopolíticas',
  'Infrastructure and buildings': 'Infraestructura y Construcción',
  'Non Geo Political Entities': 'Entidades no Geopolíticas',
  'Distribution of topics': 'Distribución de temas',
  Org: 'Org',
  Url: 'Url',
  'Item matched': 'Elemento coincidente',
  'Open URL': 'Abrir URL',
  'Activity patterns': 'Patrones de actividad',
  'overall activity': 'actividad general',
  'data activity': 'actividad de datos',
  'voice activity': 'actividad de voz',
  'SMS activity': 'actividad de SMS',
  'Events by type': 'Eventos por tipo',
  Voice: 'Voz',
  'Call analysis': 'Análisis de llamadas',
  Week: 'Semana',
  Weekend: 'Fin de semana',
  'Top target locations': 'Ubicaciones principales del objetivo',
  'Count analysis': 'Análisis de recuento',
  Amount: 'Cantidad',
  'Total duration': 'Duración total',
  'duration & frequency': 'duración & frecuencia',
  'incoming & outgoing': 'entrante & saliente',
  'Low activity': 'Bajo actividad',
  'Average activity': 'Medio actividad',
  'High activity': 'Alto actividad',
  'No activity': 'Ninguna actividad',
  'SMS & calls': 'SMS y llamadas',
  'Total calls': 'Llamadas totales',
  'Map View': 'Vista del mapa',
  'Split View': 'Vista dividida',
  'List View': 'Vista de la lista',
  'Choose file to upload': 'Seleccione un archivo para subir',
  'Call logs should be uploaded as a single .csv file':
    'Los registros de llamadas deben cargarse como un solo archivo .csv',
  'Drag file here': 'Arrastre aquí el archivo',
  'Checking Data Availability...':
    'Comprobando la disponibilidad de los datos ...',
  'Search by phone number': 'Buscar por número',
  'Results of upload': 'Resultados de la carga',
  'Total Parsed': 'Total analizado',
  'Successfully inserted': 'Insertado satisfactoriamente',
  Duplicated: 'Duplicados',
  Errors: 'Errores',
  'Processing the data': 'Procesando los datos',
  'Processing the data, please wait': 'Procesando los datos, por favor espere',
  Destination: 'Destino',
  'Upload existing call log': 'Suba un registro de llamadas existente',
  'in order to see call log analysis':
    'para poder ver el análisis del registro de llamadas',
  'Analysis includes': 'El análisis incluye',
  "insights about your target's top associates":
    'estadísticas sobre los asociados principales de su objetivo',
  "a playback of the target's timeline":
    'una lista de la línea de tiempo del objetivo',
  "target's predicted locations": 'predicción de ubicaciones del objetivo',
  "target's common used routes": 'las rutas frecuentes usadas por el objetivo',
  'And more': 'y más',
  'Most recent': 'Más reciente',
  'Most relevant': 'Lo más relevante',
  Tenant: 'Inquilino',
  'Total credits reserved': 'Créditos totales reservados',
  'Lines parsed': 'Líneas analizadas',
  'No available data': 'Sin datos disponibles',
  'Please select a 3 day range maximum.':
    'Seleccione un rango máximo de 3 días.',
  'Select a range of max 3 days': 'Seleccione un rango de 3 días como máximo',
  Balance: 'Equilibrar',
  'No transactions were made in the selected dates':
    'No se realizaron transacciones en las fechas seleccionadas',
  'No transactions found': 'No se encontraron transacciones',
  'out of': 'fuera de',
  queries: 'consultas',
  'Social Urls': 'URL sociales',
  'Cross Intel Search': 'Búsqueda cruzada de Intel',
  Contains: 'Contiene',
  'Social URL': 'URL social',
  'View map': 'Ver el mapa',
  Certainty: 'Certeza',
  'From - To': 'De - A',
  'Add name and image url': 'Agregar nombre y URL de imagen',
  'Search by face and name': 'Buscar por rostro y nombre',
  and: 'y',
  'We couldn’t find any results for':
    'No pudimos encontrar ningún resultado para',
  'Try another search': 'Prueba otra búsqueda',
  'Search & geolocate phone': 'Buscar y geolocalizar teléfono',
  'Geolocate phone': 'Geolocalizar teléfono',
  'Search tips': 'Consejos de búsqueda',
  'Try different or less filters': 'Intente diferente o menos filtros',
  'Try fewer keywords': 'Pruebe con menos palabras clave',
  'Check for spelling errors': 'Verificar errores ortográficos',
  'Add Profile to My Target': 'Agregar perfil a Mi Objetivo',
  'Parsing errors': 'Análisis de errores',
  'Parsing error lines': 'Analizando líneas de error',
  'Billing error': 'Error de facturación',
  'File error': 'Error de archivo',
  Confirmation: 'Confirmación',
  'Are you sure want to clear the history ?':
    '¿Seguro que quieres borrar el historial?',
  'Are you sure want to clear all the history ?':
    '¿Seguro que quieres borrar todo el historial?',
  'No Results': 'No hay resultados',
  'Type a name': 'Escriba un nombre',
  'Look for a country, city or address': 'Busque un país, ciudad o dirección',
  'View in Link Analysis': 'Ver en análisis de enlaces',
  'Completed successfully': 'Completado con exito',
  'Expiring date': 'Fecha de expiración',
  'Could not verify that you are human, please contact your administrator':
    'No se pudo verificar que eres humano, comunícate con tu administrador',
  'Name Surname': 'Nombre Apellido',
  'Name Middle Surname': 'Nombre Segundo Apellido',
  'Saving data': 'Guardar datos',
  'Save changes': 'Guardar cambios',
  'Would you like to save the latest changes you made in link analysis?':
    '¿Le gustaría guardar los últimos cambios que realizó en el análisis de enlaces?',
  Degrees: 'Grados',
  'Changes saved successfully!': '¡Los cambios se guardaron exitosamente!',
  'No seeds were found. The ExtractusD will fetch information related to Target MSISDN(s)':
    'No se encontraron semillas. ExtractusD buscará la información relacionada al (los) MSISDN del objetivo',
  'Succesfull ExtractusD Operation': 'Operación de ExtractusD satisfactoria',
  Features: 'Funcionalidad',
  'ExtractusD Response': 'Respuesta de Extractus',
  'ExtractusD request': 'Solicitud ExtractusD',
  'ExtractusD requested by user': 'Solicitado ExtractusD por la usuaria',
  'ExtractusD request by user': 'Solicitud de ExtractusD por el usuario',
  'para el objetivo': 'for target',
  'was Succesfull': 'fue satisfactoria',
  Landlines: 'Líneas fijas',
  'All landlines': 'Todas las líneas fijas',
  'All MSISDNs': 'Todos los números de teléfono',
  'All types': 'Todos los tipos',
  Ascending: 'Ascendente',
  Descending: 'Descendiendo',
  'Sort by': 'Ordenar por',
  'Add to "My target"': 'Agregar a "Mi objetivo"',
  URL: 'URL',
  'Truecaller profile location': 'Ubicación de Perfil de Truecaller',
  'Clear filters': 'Filtros claros',
  Topics: 'Temas',
  'Click to see details': 'Haga clic para ver los detalles',
  Category: 'categoría',
  'Seed copied': 'Semilla copiada',
  'This action will request Call Log for':
    'Esta acción solicitará el registro de llamadas para',
  'Add at least 1 MSISDN or IMEI or IMSI':
    'Agregue al menos 1 MSISDN o IMEI o IMSI',
  'to the target to be able to use this feature':
    'al objetivo para poder usar esta funcionalidad',
  'Please enter 3 or more characters': 'Ingrese 3 o más caracteres',
  'Special characters and trailing spaces are not allowed':
    'No se permiten caracteres especiales ni espacios finales',
  Required: 'Requerido',
  'Confirmation required': 'Confirmación requerida',
  'Insert a single or multiple MSISDNs or IMSIs separated by comma':
    'Inserte uno o varios MSISDN o IMSI separados por comas',
  'Use comma to separate the MSISDNs.': 'Use coma para separar las MSISDN.',
  Block: 'Cuadra',
  Manage: 'Administrar',
  'Call Log Pending': 'Registro de llamadas pendiente',
  'Call log pending': 'Registro de llamadas pendiente',
  'Activate OTP': 'Activar OTP',
  'Facebook Significant Other': 'Otro significativo de Facebook',
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Instagram: 'Instagram',
  Linkedin: 'Linkedin',
  Skype: 'Skype',
  Whatsapp: 'Whatsapp',
  Tiktok: 'Tiktok',
  Telegram: 'Telegram',
  Friend: 'Amiga',
  Follower: 'Seguidora',
  Activate: 'Activar',
  'Deactivate OTP': 'Desactivar OTP',
  Deactivate: 'Desactivar',
  'Activate 2-step verification': 'Activar la verificación en dos pasos',
  'Deactivate 2-step verification': 'Desactivar la verificación en dos pasos',
  'Enter password': 'Introducir la contraseña',
  'Scan QR with your device to generate OTP codes':
    'Escanee QR con su dispositivo para generar códigos OTP',
  'Enter the OTP code from your device':
    'Ingrese el código OTP de su dispositivo',
  'OTP configuration completed': 'Configuración de OTP completada',
  'Are you sure you want to reset the user password for':
    '¿Está seguro de que desea restablecer la contraseña de usuario para',
  "A second step after entering your password verifies it's you signing in.":
    'Un segundo paso después de ingresar su contraseña verifica que es usted quien inicia sesión.',
  'Change your password': 'Cambia tu contraseña',
  'Current password': 'Contraseña actual',
  'Password strength': 'Seguridad de la contraseña',
  'Use at least 8 characters. Don’t use a password from another site, or something too obvious':
    'Utilice al menos 8 caracteres. No use una contraseña de otro sitio o algo demasiado obvio',
  'The password was changed!': '¡La contraseña fue cambiada!',
  'Password must be at least 8 characters.':
    'La contraseña debe tener al menos 8 caracteres.',
  'Password does not contain upper case letters.':
    'La contraseña no contiene letras mayúsculas.',
  'Password does not contain lower case letters.':
    'La contraseña no contiene letras minúsculas.',
  'Password does not contain any digits.': 'La contraseña no contiene dígitos.',
  'Please use upper case letters, lower case letters and numbers':
    'Utilice letras mayúsculas, minúsculas y números',
  'Please enter the same new password again':
    'Ingrese la misma contraseña nueva nuevamente',
  '2-Step Verification': 'Verificación en dos pasos',
  Change: 'Cambio',
  'OTP code': 'Código OTP',
  'Back to login': 'Atrás para iniciar sesión',
  'An email with the QR was sent to':
    'Se envió un correo electrónico con el QR a',
  'Email sent.': 'Email enviado.',
  'The password was reset!': '¡Se restableció la contraseña!',
  'Could not reset password': 'No se pudo restablecer la contraseña',
  'Please add an email address to the user. A new password will be sent to that email':
    'Agregue una dirección de correo electrónico al usuario. Se enviará una nueva contraseña a ese correo electrónico',
  'If you lost your device please reset the 2-Step Verification by pressing the reset button. An email will be sent to guide you to add a new device.':
    'Si perdió su dispositivo, reinicie la verificación en dos pasos presionando el botón de reinicio. Se enviará un correo electrónico para guiarlo a agregar un nuevo dispositivo.',
  'Please fill out this field': 'Por favor rellene este campo',
  'Wrong password': 'Contraseña Incorrecta',
  'Something went wrong. Please try OTP reset again.':
    'Algo salió mal. Intente restablecer OTP nuevamente.',
  'Analyzing and merging the results for':
    'Analizar y fusionar los resultados para',
  'Analyzing and unmerging the results for':
    'Analizando y deshaciendo los resultados para',
  'Locate all': 'Ubicar todo',
  See: 'Mostrar',
  'Quick filters': 'Filtros rápidos',
  'Tagged in': 'Etiquetado en',
  'photo(s) together': 'foto(s) juntas',
  'Attended same institution': 'Asistió a la misma institución',
  'Same location': 'Misma ubicación',
  'Facebook family': 'Familia de Facebook',
  Liked: 'Le gusta',
  'target photo(s)': 'foto(s) objetivo',
  'target post(s)': 'publicación(s) de objetivo',
  'Commented on a photo of the target': 'Comentó una foto del objetivo.',
  'Commented on a post of the target': 'Comentó una publicación del objetivo.',
  shares: 'compartidos',
  views: 'vistas',
  Hashtags: 'Hashtags',
  Muted: 'Silenciados',
  Feature: 'Funcionalidad',
  Videos: 'Videos',
  Hearts: 'Corazones',
  Shared: 'Compartido',
  'No platforms were found. Please select the platform from the list bellow.':
    'No se encontraron plataformas. Por favor, seleccione la plataforma de la lista a continuación.',
  'Once the infrastructure is up, it will take our BOTs up to':
    'Una vez que la infraestructura esté funcionando, a nuestros BOTs les tomará hasta',
  'hours to respond with success status.':
    'horas para responder con un estado satisfactorio.',
  'Your request for SOMEDUS on': 'Su solicitud de SOMEDUS para',
  'account has been submitted.': 'ha sido enviada.',
  'If the status is N/A, yet you will have the option to enable the BOT to continue attempts.':
    'Si el estado es N/A, aún tendrá la opción de habilitar el BOT para continuar intentando.',
  'If you dont associated the correct MSISDN with platform account, the request could take up to 144 hours.':
    'Si no asocia el MSISDN correcto con la cuenta de la plataforma, la solicitud podría tomar hasta 144 horas.',
  Download: 'Descargar',
  'Select platform': 'Seleccionar plataforma',
  'Select action': 'Seleccione la acción',
  'Select MSISDN': 'Seleccione MSISDN',
  'Somedus retrived information': 'Somedus obtuvo información',
  'Action started by': 'Acción iniciada por',
  'is here': 'está aquí',
  'Associated MSISDN with account': 'MSISDN asociado con la cuenta',
  hours: 'horas',
  'Searching open databases': 'Buscando bases de datos abiertas',
  'Add new item': 'Agregar ítem nuevo',
  'Select the start and end interval for tracking the Ad IDs':
    'Seleccione el intervalo de inicio y fin a rastrear Ad IDs',
  'Adint history': 'Historia de un poco',
  'Enter Ad ID(s)': 'Ingrese Ad ID(s)',
  'Select targets from case': 'Seleccione objetivos de un caso',
  'View results': 'Ver resultados',
  'Remove from history': 'Eliminar del historial',
  'Edit name': 'Editar nombre',
  Repeat: 'Repetir',
  'Pin this search': 'Fijar esta búsqueda',
  'Predicted Location': 'Ubicación prevista',
  'CDR Statistics': 'Estadísticas de CDR',
  'Unpin this search': 'Dejar de fijar búsqueda',
  'Show unique IDs': 'Mostrar ID únicos',
  'Show common IDs': 'Mostrar ID en común',
  'Column settings': 'Ajustes de columna',
  Expand: 'Expandir',
  Collapse: 'Colapsar',
  'Device ID': 'ID de dispositivo',
  'Phone brand': 'Marca del teléfono',
  'IP/Carrier': 'IP/Proveedor',
  APPs: 'Apps',
  Area: 'Área',
  Confidence: 'Confianza',
  'Follow with Geofence': 'Seguir con geocerca',
  'is required': 'es requerido',
  Trace: 'Trazar',
  'De Anon': 'De anonimizar',
  'Cdr Statistics': 'Estadísticas de CDR',
  'Cdr Export': 'Exportar CDR',
  'Target LastSeen': 'Ultima vez visto de objetivo',
  'Top Device Attributes': 'Atributos de dispositivos principales',
  'Nearest Neighbors': 'Vecinos cercanos',
  'Common Ips': 'IPs en común',
  'Case Cdr Statistics': 'Estadísticas de CDR del caso',
  'Common Associates': 'Personas en común',
  'Common Locations': 'Ubicaciones en común',
  'Case Top Associates': 'Personas principales del caso',
  'Case Top Locations': 'Ubicaciones principales del caso',
  'Case Target Activity': 'Actividad del objetivo del caso',
  'Case Event By Type': 'Evento por tipo del caso',
  'Case Call Analysis': 'Análisis de llamadas del caso',
  'Case Count Analysis': 'Conteo de llamadas del caso',
  'Target Activity': 'Actividad del objetivo',
  'Top connection': 'Conexión importante',
  'Call log top relation': 'Relación importante del registro de llamadas',
  'Select node to view details': 'Seleccione el nodo para ver los detalles',
  'Every MSISDN should start with + .': 'Cada MSISDN debe empezar con + .',
  'is not a valid MSISDN .': 'no es un MSISDN válido .',
  'Events Type': 'Tipo de Evento',
  'Top locations': 'Ubicaciones principales',
  'Export call log report': 'Exportar reporte de registro de llamadas',
  'Select report type': 'Seleccione tipo de reporte',
  'Full report': 'Reporte completo',
  'Custom report': 'Reporte personalizado',
  'Following MSISDN - Platform combination is already selected':
    'La combinación de MSISDN a seguir y plataforma asociada ya está seleccionada',
  'In order to strengthen account security, Two Factor Authentication (2FA) is mandatory for all users.':
    'Para fortalecer la seguridad de la cuenta, la autenticación de dos factores (2FA) es obligatoria para todos los usuarios.',
  'Your password will expire in #{days} days.':
    'Su contraseña caducará en #{days} días.',
  'Your password has expired #{days} ago.':
    'Tu contraseña ha caducado hace #{days} días.',
  'Your password expired yesterday.': 'Tu contraseña expiró ayer.',
  'Your password expires today.': 'Tu contraseña caduca hoy.',
  'Your password expires tomorrow.': 'Tu contraseña vence mañana.',
  'Session will expire in': 'La sesión caducará en',
  'Your session is about to expire click "Continue" to keep the session active.':
    'Su sesión está a punto de caducar, haga clic en "Continuar" para mantener la sesión activa.',
  Okay: 'Okey',
  'Activate Now!': '¡Activar ahora!',
  'Request expired for': 'La solicitud expiró para',
  Abandon: 'Abandonar',
  'is not a valid MSISDN.': 'no es un MSISDN válido.',
  'Deep Webint query created successfully!':
    '¡Consulta de WebInt profundo creada con éxito!',
  'Failed to retrieve': 'La solicitud falló',
  'Somedus Request Submitted': 'Somedus solicitud enviada',
  'Request Cancelled': 'Solicitud cancelada',
  'You have reached': 'Ha alcanzado',
  'of your queries quota': 'de su cuota de consultas',
  'Only lowercase letters, numbers, dot (.) and underscore (_).':
    'Solo letras minúsculas, números, punto (.) Y guión bajo (_).',
  'Not enough credits for this request':
    'No cuenta con los créditos suficientes para esta solicitud',
  'Most Common Relations': 'Relaciones en Común',
  'Top Relations': 'Relaciones Principales',
  'Copy location': 'Copiar ubicación',
  'Use the search filters': 'Utilice los filtros de búsqueda',
  'When using the search filters, the results will be more accurate':
    'Al utilizar los filtros de búsqueda, los resultados serán más precisos',
  Quotes: 'Notas',
  'Incoming SMS': 'SMS entrantes',
  'Outgoing SMS': 'SMS salientes',
  'All locations': 'Todas las ubicaciones',
  'Target locations': 'Ubicaciones del objetivo',
  'Peer(s) locations': 'Ubicaciones de contactos',
  'In call': 'En llamada',
  'Out call': 'Llamada de salida',
  'Out SMS': 'SMS de salida',
  'In SMS': 'En SMS',
  'Most common associates': 'Asociados más comunes',
  'Got it': 'Entendido',
  'Target peer interactions': 'Interacciones',
  'Location belongs to the source': 'La ubicación se refiere a la fuente',
  'Location belongs to the destination': 'La ubicación se refiere al destino',
  'No, remove address from account': 'No, remover dirección de la cuenta',
  Confirm: 'Confirmar',
  'Temporary password for #{username}':
    'Contraseña temporal para el #{username}',
  'Password provided was already used':
    'La contraseña proporcionada ya está en uso',
  'No image available': 'Imagen no disponible',
  sub: 'sub',
  add: 'Nuevo',
  'The quota is changed by #{currentUser}':
    'La cuota ha sido cambiada por #{currentUser}',
  'The quota is changed by #{currentUser} - #{quotaType}':
    'La cuota ha sido cambiada por #{currentUser} - #{quotaType}',
  intel_search: 'Búsqueda de inteligencia',
  query_location: 'Consulta de ubicación',
  import_cdr: 'Importar Registro de llamadas',
  'Maximum amount of robot queries is':
    'La cuota máxima de consultas del robot es de',
  'Please choose another daterange.':
    'Por favor, seleccione otro rango de fechas.',
  'Gathering call logs report for':
    'Obteniendo reportes de registros de llamada para',
  'Gathering data for target details':
    'Obteniendo datos para los detalles del perfil',
  'Gathering images': 'Obteniendo imágenes',
  'Gathering call log statistics':
    'Obteniendo estadísticas de reportes de llamada',
  'Preparing PDF preview': 'Preparando vista previa del PDF',
  'Add entity': 'Agregar entidad',
  'Select type': 'Seleccionar tipo',
  'name surname': 'nombre apellido',
  telno: 'número de teléfono',
  'name middle surname': 'nombre segundo apellido',
  'full name': 'nombre completo',
  photo: 'foto',
  email: 'correo electrónico',
  'Add profile to target': 'Agregar perfil a objetivo',
  'Enhance profile': 'Mejorar perfil',
  'Remove Profile': 'Quitar perfil',
  'Alias is too short': 'El Alias es demasiado corto',
  'Special characters are not allowed':
    'Los caracteres especiales no están permitidos',
  'Deep search will add second degree relations, likers, commenters, reactors and more':
    'La búsqueda extensa agregará relaciones de segundo grado, personas que han dado me gusta, comentado, reaccionado y más.',
  Deep: 'Extenso',
  'Advanced search will add media, relations, insights and more from social profiles':
    'La búsqueda avanzada agregará imagenes y videos, relaciones, estadísticas y más de redes sociales.',
  Advanced: 'Avanzado',
  'Gather more intel from MSISDN, email & social profiles':
    'Obtenga más información sobre MSISDN, correo electrónico y redes sociales.',
  Basic: 'Básico',
  'Enhance profile in order to automatically create a target, discover more intelligence information and retrieve important insights':
    'Mejore el perfil para crear automáticamente un objetivo, descubrir más información de inteligencia y recuperar datos importantes.',
  'Enhance profile in order to discover more intelligence information and retrieve important insights':
    'Mejore el perfil para descubrir más información de inteligencia y recuperar datos importantes.',
  'People nearby': 'Gente cercana',
  'Visit Target': 'Visite objetivo',
  'The request call log is not currently supported for this country':
    'Por el momento la solicitud de registro de llamadas no está soportada para este país',
  'Analyze locations': 'Analizar ubicaciónes',
  'Find commons': 'Encontrar similares',
  'You have entered some invalid ad ids.':
    'Ha introducido algunos AD-ID’s inválidos',
  IP: 'IP',
  Operator: 'Operador',
  'Date and time': 'Fecha y hora:',
  'Are you sure you want to delete this search?':
    '¿Está seguro de que desea eliminar esta búsqueda?',
  Applications: 'Aplicaciones',
  IPs: 'IPs',
  'Phone models': 'Modelos de teléfono',
  Carrier: 'Proveedor',
  'Select from Existing Targets': 'Seleccionar de objetivos existentes',
  'Update target': 'Actualizar objetivo',
  OK: 'OK',
  'Maximum number of targets has been reached':
    'Ha alcanzado el número máximo de objetivos',
  Male: 'Hombre',
  Female: 'Mujer',
  'Location Sources': 'Fuentes de Ubicación',
  'All sources': 'Todas las fuentes',
  Geolocations: 'Geolocalizaciones',
  'Call logs': 'Registro de llamadas',
  'True Caller': 'True-caller',
  'Social networks': 'Redes sociales',
  'Other sources': 'Otras fuentes',
  Company: 'Empresa',
  'Disable drawing': 'Deshabilitar trazo',
  'Meeting Points': 'Puntos de encuentro',
  nmr: 'NMR',
  Impersonation: 'Suplantación',
  Done: 'Listo',
  'Target is expired': 'El objetivo ha caducado',
  Expired: 'Caducado',
  'Renew with the credits': 'Renovar con los créditos',
  'Click on the button below to renew for #{days} days':
    'Haga clic en el botón de abajo para renovar por #{days} días',
  'Meeting points': 'Puntos de encuentro',
  'Paste image URL': 'Copiar URL de imagen',
  'Drag image here': 'Arrastre una imagen hasta aquí',
  'Choose image to upload': 'Seleccione cargar imagen',
  Unauthorized: 'No autorizado',
  hour: 'hora',
  'Upload existing call': 'Subir un registro de llamadas existente',
  'for at least 2 targets from the case in order to see call log analysis':
    'para al menos 2 objetivos del caso y poder ver el análisis de registro de llamadas.',
  'Insights about most common and top associates':
    'Estadísticas sobre contactos principales y más comunes',
  Analyze: 'Analizar',
  'Tagged posts owners': 'Personas etiquetadas en post',
  'Tagged photos owners': 'Personas etiquetadas en fotos',
  'Must be at least 3 characters long and maximum characters allowed are 20, special charactes and preceding or following spaces are not allowed':
    'La longitud deberá ser de al menos 3 caracteres y un máximo de 20, no están permitidos caracteres especiales ni seguido o precedido por espacios',
  'Call logs should be uploaded as a single .csv or .xlsx file':
    'Los registros de llamadas deben cargarse como un solo archivo .csv o .xlsx',
  'Close all': 'Cierra todo',
  'Cdr Type': 'Tipo de CDR',
  'Case renewed successfully!': 'Caso renovado con éxito!',
  'Case has not been renewed': 'El caso no ha sido renovado',
  'Target renewed successfully!': 'Objetivo renovado con éxito!',
  'Target has not been renewed': 'El objetivo no se ha renovado',
  'Create a new target and Enhance profile':
    'Crear un nuevo objetivo y Mejorar perfil',
  'First month is free': 'El primer mes es gratis',
  'Target is Expired': 'El objetivo expiró',
  'You dont have permissions to use this feature':
    'No cuenta con los permisos para utilizar esta funcionalidad',
  'Call Log': 'Registro de llamadas',
  'Access to the target will be free for #{days} days':
    'El acceso al objetivo será gratuito por #{days} días',
  'Could not verify that you are human':
    'No pudimos verificar que es un humano',
  Bio: 'Biografía',
  'No valid MSISDN': 'MSISDN no válido',
  'Password expired': 'La contraseña expiró',
  Discard: 'Descartar',
  'Target export call log report': 'Exportar reporte de llamadas del objetivo',
  'Enable geofencing': 'Habilitar geocerca',
  'Upload call log': 'Subir registro de llamadas',
  Dates: 'Fecha',
  'Select all': 'Seleccionar todo',
  'Case call logs report': 'Reporte de registro de llamadas del caso',
  'Predicted location': 'Ubicación prevista',
  'Last seen': 'Visto por última vez',
  overall: 'en total',
  incoming: 'entrante',
  outgoing: 'saliente',
  frequency: 'frecuencia',
  duration: 'duración',
  weekend: 'fines de semana',
  week: 'semana',
  authored: 'autor',
  published: 'publicado',
  'Virtual ID creation wizard': 'Configurador de creación de virtual ID',
  'Virtual ID information': 'Información del virtual ID',
  'Select age': 'Seleccione edad',
  'Select gender': 'Seleccione género',
  Non: 'Ninguno',
  Next: 'Siguiente',
  'Select a virtual ID or proceed to the next step to create a new one':
    'Seleccione un virtual ID o preceda al siguiente paso para crear uno nuevo',
  'Virtual ID selection': 'Selección de virtual ID',
  'Last name': 'Apellido',
  'Middle name': 'Segundo nombre',
  'City name': 'Ciudad',
  'Autocomplete all': 'Autocompletar todo',
  'Select social network': 'Seleccionar redes sociales',
  Step: 'Paso',
  'Generating virtual ID': 'Generando ID virtuales',
  'Virtual ID  management': 'Administración de ID virtuales',
  'New virtual ID ': 'Nuevo ID virtuales',
  'Virtual ID was created': 'El ID virtuales fue creado',
  'Search virtual ID ': 'Buscar ID virtuales',
  'Profile picture': 'Foto de perfil',
  'Birth date': 'Fecha de nacimiento',
  'Remote view': 'Vista remota',
  'Open remote view': 'Abrir Vista Remota',
  'Last activity': 'Última actividad',
  'No date available': 'Fecha no disponible',
  'Status ': 'Estado',
  active: 'activo',
  idle: 'inactivo',
  errors: 'errores',
  'View Log': 'Ver historial',
  'No virtual ID match your criteria':
    'Ningún ID virtuales coincidió con sus criterios',
  'There are no virtual IDs assigned to your user':
    'No tiene ningún IDs virtuales  asignado a su usuario',
  Reserving: 'Reservando',
  Releasing: 'Liberando',
  'your browser': 'su navegador',
  'remote browser': 'navegador remoto...',
  Avatars: 'Avatares',
  'United States': 'Estados Unidos',
  'Time left to expiration:': 'Fecha de Expiración:',
  'This action will fetch call log information for':
    'Esta acción recolectará la información del registro de llamada para',
  'Case is expired': 'Caso ha caducado',
  Sim: 'Sim',
  'Enter coordinates: lat, lon': 'Ingrese las coordenadas: lat, lon',
  'Enter phone': 'Ingrese el teléfono',
  'Paste profile URL': 'Pegar la URL del perfil',
  'Enter IMEI': 'Ingrese IMEI',
  'Invalid IMEI provided': 'IMEI inválido',
  'Enter valid coordinates': 'Ingrese coordenadas válidas',
  'Invalid URL. Social network not supported':
    'URL invalida. Red social no soportada',
  Creating: 'Creando',
  'Fetching data': 'Recuperando datos',
  'Drag to link with another node': 'Arrastre para vincular con otro nodo',
  'Fetch call logs': 'Obtener registros de llamadas',
  'Fetch social profiles': 'Obtener perfiles sociales',
  'Fetch instant messaging profiles':
    'Obtener perfiles de mensajería instantánea',
  'Get sim': 'Obtener sim',
  'Invalid relationship type': 'Tipo de relación no válido',
  'Located at': 'Ubicado en',
  post: 'publicación',
  Called: 'Llamado',
  'Used by': 'Usado por',
  'MSISDN is connected with this instant messaging profile':
    'El MSISDN está conectado con este perfil de mensajería instantánea',
  'Please select at least 2 targets for call log analysis':
    'Por favor, seleccione al menos 2 objetivos para analizar el registro de llamadas',
  Reserved: 'Reservado',
  'Opening browser': 'Abriendo navegador',
  'Pre-registering': 'Pre-registrando',
  'Filling photos': 'Completando fotos',
  'Filling personal info': 'Completando información personal',
  'Registering FB acc.': 'Registrando cta. FB',
  'Getting OTP': 'Obteniendo OTP',
  'Filling OTP': 'Completando OTP',
  'Logging in': 'Inicio de sesión',
  'Scrapping photos': 'Desechando fotos',
  'Configuring acc.': 'Configurando cuentas',
  'Liking posts': 'Dándo Me Gusta a publicaciones',
  'Accepting requests': 'Aceptando solicitudes',
  'Joining groups': 'Uniéndose a grupos',
  'Following pages': 'Siguiendo páginas',
  'Configuring Gmail': 'Configurando Gmail',
  Activity: 'Actividad',
  Social: 'Social',
  'Started at': 'Empezó el',
  'Completed at': 'Completado el',
  'is expired': 'ha caducado',
  'days remaining until expiration': 'días para la fecha de expiración',
  Renew: 'Renovar',
  'Reserve browser failed. Please retry':
    'Falló la reservación de navegador. Por favor, intente de nuevo',
  'No available browsers. Please retry':
    'No hay navegadores disponibles. Por favor, intente de nuevo',
  'Management for a new target is free of charge for #{days} days':
    'La gestión de un nuevo objetivo es gratuita durante #{days} días.',
  'Management for a new case is free of charge for #{days} days':
    'La gestión de un caso nuevo es gratuita durante #{days} días.',
  'Create a new target and Request call log':
    'Crear un nuevo objetivo y solicitar registro de llamadas',
  'This action will create a new target and request call log for':
    'Esta acción creará un nuevo objetivo y solicitará el registro de llamadas para',
  'Renew target and Request call log':
    'Renovar destino y solicitar registro de llamadas',
  Copied: 'Copiado',
  away: 'de distancia',
  'people nearby': 'personas cercanas',
  'Show nearby Telegram users': 'Mostrar usuarios de Telegram cercanos',
  'Hide nearby Telegram users': 'Ocultar usuarios de Telegram cercanos',
  'Intelligence search': 'Búsqueda de inteligencia',
  'Current call': 'Llamada actual',
  'Other social profiles': 'Otros perfiles sociales',
  'Could not find a person with exact input':
    'No se pudo encontrar a la persona con la entrada exacta',
  'Owner id': 'Id Propietario',
  'Avatar remote view ended': 'La vista remota del Avatar finalizó',
  Current: 'Actual',
  Previously: 'Previamente',
  'Activity log': 'Historial de actividad',
  'Call log analysis': 'Análisis de registro de llamadas',
  'Text analysis': 'Análisis de texto',
  'Advanced search will gather more intel from MSISDN, email & social profiles and analyse their content such as media, relations, posts and more':
    'La búsqueda avanzada obtendrá más información de inteligencia sobre el MSISDN, correo electrónico y perfiles sociales y analizará su contenido como imágenes, relaciones publicaciones y más',
  'upload existing call log': 'suba un registro de llamadas existente',
  Position: 'Puesto de trabajo',
  Link: 'Enlace',
  'Location sources': 'Fuente de ubicaciones',
  'No results found': 'No se han encontrado resultados',
  Reactions: 'Reacciones',
  reactions: 'Reacciones',
  'Reactions count': 'Las reacciones cuentan',
  'No data. Make sure you have seeds and click "Enhance profile"':
    'Sin información. Asegurate de tener semillas y haz clic "Mejorar perfil"',
  'nearby profiles found': 'perfiles cercanos encontrados',
  'Deep Webint': 'WebInt profundo',
  'Must be at least 1 character long and maximum characters allowed are 255, special charactes and preceding or following spaces are not allowed':
    'La longitud deberá ser de al menos 1 caractere y un máximo de 255, no están permitidos caracteres especiales ni seguido o precedido por espacios',
  'Recovery data': 'Recuperación de cuenta',
  'Authenticating to system': 'Autenticarse en el sistema',
  'Social profile details': 'Info. de perfiles sociales',
  'Group details': 'Info. de Grupos',
  'Checkin details': 'Info. de Checkins',
  'Time of the query': 'Hora de la consulta',
  'Gathering call log entry':
    'Recopilación de la entrada del registro de llamadas',
  'Generating map': 'Generando mapa',
  Unspecified: 'Desconocida',
  Truecaller: 'Truecaller',
  'No matches found': 'No se encontraron resultados',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo',
  'Name must contain at least 3 characters':
    'El nombre debe contener al menos 3 caracteres',
  'Name is required': 'Se requiere el nombre',
  'Name cannot be just whitespaces':
    'El nombre no puede ser solo espacios en blanco',
  'Receiver number': 'Recipiente',
  'screenWidth must not be less than 640':
    'el ancho de la pantalla no debe ser menor a 640',
  'Please provide proper platform link!':
    'Proporcione el enlace de plataforma adecuado!',
  'Place name': 'Lugar',
  Own: 'Propio',
  'Tagged by others': 'Etiquetado por otros',
  'New entity': 'Nueva entidad',
  register: 'Registrar',
  'Add social network': 'Agregar red social',
  'Not enough credits': 'Sin créditos suficientes',
  'Posted on': 'Publicado en',
  'View original post': 'Ver publicación original',
  Any: 'Cualquiera',
  Picture: 'Foto',
  'Avatar id': 'Id Avatar',
  'Last Modified': 'Última modificación',
  'Created On': 'Creado en',
  'You do not have access rights or the url is invalid':
    'No cuenta con los permisos necesarios o la URL es inválida',
  'Update link analysis': 'Actualizar análisis de enlaces',
  'Getting cookies': 'Obteniendo cookies',
  'Pre registration warmup for #{socialType} started':
    'Se ha empezado el preregistro para #{socialType}',
  'The whole Facebook registration flow completed':
    'Se finalizó el flujo de registro completo de Facebook',
  'Photos uploaded into facebook account. Filling account details':
    'Fotografías cargdas a la cuenta de Facebook. Completando los detalles de la cuenta',
  'Facebook account confirmed. Uploading photos into account':
    'Cuenta de Facebook confirmada. Cargando fotos en la cuenta',
  'Otp code obtained. Confirming facebook account registration':
    'Código Otp obtenido. Confirmando el registro de la cuenta de Facebook',
  '#{socialType} registration succeed. Trying to get email otp code':
    'Registro de #{socialType} satisfactorio. Intentando obtener el email con código otp',
  '#{socialType} registration started': 'Registro de  #{socialType} iniciado',
  'Imported successfully': 'Importado correctamente',
  'Importing historical data': 'Importación de datos históricos',
  Owner: 'Propietario',
  'Case updated successfully!': '¡Caso actualizado con éxito!',
  'Remove target': 'Eliminar objetivo',
  'Add targets': 'Agregar objetivos',
  'Unselect all': 'Deselecciona todo',
  'Delete target': 'Borrar objetivo',
  'Delete case': 'Borrar caso',
  'Add case': 'Agregar caso',
  'Next step': 'Próximo paso',
  'screenHeight must not be less than 480':
    'El tamaño de la pantalla no debe ser menor a 480',
  'Both screenWidth and screenHeight must be specified':
    'Tanto el ancho como la altura de la pantalla deben ser especificados.',
  'is not a valid': 'no es válida',
  'Deleted!': '¡Eliminado',
  'Permanently remove target from case':
    'Remover el objetivo del caso permanentemente',
  'Are you sure you want to remove target from case permanently?':
    '¿Está seguro que desea remover el objetivo del caso permanentemente?',
  'Once removed the data cannot be restored':
    'Una vez removido, la información no puede ser restaurada',
  'There are no avatars created. Please add a new avatar':
    'No se han creado Avatares. Por favor, agregue un nuevo avatar.',
  'Summary report': 'Informe resumido',
  'Profiler report': 'Informe del generador de perfiles',
  Import: 'Importación',
  Selected: 'Seleccionado',
  'No locations were found for': 'No se encontraron ubicaciones para',
  'We found available historical data':
    'Se encontraron datos históricos disponibles',
  starting: 'incipiente',
  'To import, please select one or multiple days':
    'Para importar, seleccione uno o varios días',
  'Select all available': 'Seleccione todos los disponibles',
  day: 'día',
  days: 'días',
  'Get historical data': 'Obtener historial',
  'We found available historical data for':
    'Se encontraron datos históricos disponibles para',
  'Create or add to target': 'Crear o agregar al objetivo',
  'Recovery email': 'Correo de recuperación',
  'Recovery phone': 'Teléfono de recuperación',
  'passwords found': 'contraseñas encontradas',
  Choose: 'Seleccionar',
  'Interacted with': 'Interactuó con',
  'Show on map': 'Mostrar en el mapa',
  'Gathering target summary report for':
    'Obteniendo el reporte del resumen de objetivo para',
  'Gathering family relation': 'Obteniendo relación familiar',
  'Gathering social word details': 'Obteniendo detalles de palabras sociales',
  'Gathering social network top associates':
    'Obteniendo los principales asociados de la red social',
  'Gathering call log top associates statistics':
    'Obteniendo estadísticas de los principales asociados del registro de llamadas',
  'Gathering locations': 'Obteniendo de ubicaciones',
  'Gathering activity feed details':
    'Obteniendo detalles del historial de actividad',
  'There are no cases created. Please add a':
    'No hay casos creados. Por favor, añada un',
  'new case': 'nuevo caso',
  'New case': 'Nuevo caso',
  'Invalid location': 'Ubicación inválida',
  'Rows per page': 'Filas por página',
  'There are no targets created. Please add a':
    'No hay objetivos creados. Por favor, añada un',
  'new target': 'nuevo objetivo',
  OSINT: 'OSINT',
  'View Call Log analysis': 'Ver análisis de registro de llamadas',
  'Copy password': 'Copiar contraseña',
  'Breached results for': 'Fuga de datos para',
  Origin: 'Origen',
  'Target summary report': 'Reporte de resumen de objetivo',
  'Gathering social network details':
    'Obteniendo información de redes sociales',
  'Events found': 'Eventos encontrados',
  'Telegram nearby': 'Telegram cercanos',
  'This action will show the accurate position of the Telegram nearby people':
    'Esta acción mostrará la posición exacta de las personas con Telegram cercanas',
  source: 'fuente',
  destination: 'destino',
  'Could not update target profile, user id already exists!':
    'No se pudo actualizar el perfil de destino, ¡la identificación de usuario ya existe!',
  'Social word cloud': 'Nube de palabras sociales',
  'Social network top associates': 'Principales asociados de redes sociales',
  'Call log top associates': 'Registro de llamadas principales asociados',
  'There are no MSISDNs added. Please add a':
    'No se han agregado MSISDN. Por favor, agregue un',
  'New MSISDN': 'Nuevo MSISDN',
  'Clear all filters': 'Limpiar filtros',
  'Target is already assigned to the case!':
    '¡Target ya está asignado al caso!',
  'Target Expired': 'El objetivo caducado',
  'Call Logs uploaded successfully!':
    'Registros de llamadas cargados correctamente.',
  'Telegram users cannot be located now!':
    '¡Las usuarios de telegram no pueden ser localizadas ahora!',
  'No location found for Telegram users!':
    '¡No se encontró ninguna ubicación para los usuarios de Telegram!',
  'Telegram users located successfully!':
    '¡Usuarios de Telegram localizados con éxito!',
  'Knowledge base': 'Fuente de conocimiento',
  'Call Log MSISDN': 'Registro de llamadas MSISDN',
  'Active Locate': 'Localizar Activo',
  'Active locate': 'Localizar activo',
  'This action might leave anonymous traces on target’s phone.':
    'Esta acción puede dejar rastros anónimos en el teléfono del objetivo.',
  'The Cell ID cannot be extracted for':
    'El ID de celda no se puede extraer para',
  'Do you want to perform an Active Locate query? This action might leave anonymous traces on target’s phone.':
    '¿Desea realizar una consulta de localización activa? Esta acción puede dejar rastros anónimos en el teléfono del objetivo.',
  'Active locate query submitted successfully!':
    '¡Consulta de localización activa enviada con éxito!',
  'Avatar in error state, please contact administrators':
    'El avatar tiene un estado de error, por favor, contacte a su administrador.',
  'Locate a single MSISDN or IMSI': 'Localice un único MSISDN o IMSI',
  'Added Seed': 'Semilla agregada',
  'Added Details': 'Detalles agregados',
  'Added Note': 'Notas agregadas',
  'Added Photo': 'Foto agregada',
  'Expired targets will be renewed and added to case':
    'Los objetivos que expiraron serán renovados y agregados al caso',
  'Back to Call Logs': 'Regresar al Registro de Llamadas',
  'Assigned targets changed': 'Cambiaron los objetivos asignados',
  'Targets assigned': 'Objetivos asignados',
  'Targets unassigned': 'Objetivos no asignados',
  'Note changed': 'Cambiaron las notas',
  'Case name changed': 'Cambió el nombre del caso',
  'Case colour changed': 'Cambió el color del caso',
  'Call log successfully processed!':
    '¡Registro de llamadas procesado correctamente!',
  'Could not gather data for this target':
    'No se pudieron recopilar datos para este objetivo',
  'Rows per page:': 'Filas por página:',
  'Enter alias': 'Ingrese un alias',
  'Create new case or search for existing one':
    'Cree un nuevo caso o busque uno existente',
  'Album name': 'Nombre del álbum',
  'Album url': 'URL del álbum',
  'Create case': 'Crear caso',
  'Distance from target': 'Distancia del objetivo',
  'Social profile': 'Perfiles sociales',
  'Search graph': 'Buscar en gráfico',
  'Social accounts': 'Cuentas sociales',
  'Calls number': 'Número de llamadas',
  'Calls duration': 'Duración de las llamadas',
  'Data transferred': 'Datos transferidos',
  'Geoloc Knowledge Base': 'Fuente de Conocimiento Geoloc',
  'Knowledge Base': 'Fuente de Conocimiento',
  'Intellectus Knowledge Base': 'Fuente de Conocimiento Intellectus',
  'Expired cases will be renewed': 'Los casos caducados se renovarán',
  'Target’s subscription is active': 'La suscripción del objetivo está activa',
  'Target’s subscription is not activated':
    'La suscripción del objetivo no está activa',
  'Target is connected to the network': 'El Objetivo está conectado a la red',
  'Target’s subscription is active but currently not connected to the network':
    'La suscripción del objetivo está activa pero actualmente no está conectado a la red',
  'Target is currently on a call': 'El objetivo está en una llamada',
  'Target’s subscription is blocked':
    'La suscripción del objetivo está bloqueada',
  'Could not determine the target’s status':
    'No se pudo obtener el estado del objetivo',
  ACTIVE: 'ACTIVO',
  DEACTIVATED: 'DESACTIVADO',
  ONLINE: 'EN LÍNEA',
  OFFLINE: 'FUERA DE LÍNEA',
  BUSY: 'OCUPADO',
  UNKNOWN: 'DESCONOCIDO',
  BLOCKED: 'BLOQUEADO',
  'No targets added.': 'No se agregaron objetivos.',
  'Facebook groups': 'Grupos de facebook',
  'new MSISDN': 'nuevo MSISDN',
  Presets: 'Plantillas',
  'Show predicted': 'Mostrar predicción',
  'Sleeping time': 'Horario de dormir',
  'Working time': 'Horario de trabajo',
  'Group results': 'Agrupar resultados',
  'Unmerge results': 'Desagrupar resultados',
  'Call Log has been requested': 'El registro de llamadas ha sido solicitado',
  'Pending Request': 'Solicitud pendiente',
  'Requesting call logs has been disabled':
    'La solicitud de registros de llamada ha sido deshabilitada',
  'profile location': 'ubicación de perfil',
  'View in link analysis': 'Ver en análisis de enlaces',
  'No Location found for trilateration query!':
    'No se encontró ubicación para la consulta de triangulación!',
  'Trilateration query completed!': 'Consulta de triangulación completada',
  'Please enable location access':
    'Por favor, habilite el acceso a la ubicación',
  Completed: 'Completado',
  'Could not gather data for this report':
    'No se pudo obtener información para este reporte',
  Job: 'Trabajo',
  male: 'hombre',
  female: 'mujer',
  'Enter value': 'Ingresar información',
  'Export as CSV': 'Exportar como CSV',
  'has terminated': 'ha terminado',
  'is deactivated': 'está desactivado',
  'Always obtain accurate geolocation information when available':
    'Siempre obtener información precisa de geolocalización cuando esté disponible',
  'Platform settings': 'Configuración de la plataforma',
  'You can turn off': 'Puedes apagar',
  'from Platform settings.': 'desde la configuración de la plataforma.',
  'Target has not been renewed, Not enough credits':
    'El objetivo no se ha renovado, Sin créditos suficientes',
  'Analyzing Results': 'Analizar resultados',
  'Enable support requests': 'Habilitar solicitudes de soporte',
  Powerusers: 'Powerusers',
  'Select a value': 'Seleccione un valor',
  'Results found': 'Resultados encontrados',
  'Searched sources': 'Fuentes buscadas',
  'Save and Locate': 'Guardar y localizar',
  Adint: 'Adint',
  'Virtual ID': 'ID Virtuales',
  'Virtual ID management': 'Administración de ID Virtuales',
  Webint: 'Webint',
  'Call Log for': 'Registro de llamadas para',
  'was successfully delivered': 'fue entregado con éxito',
  'Start scheduler': 'Comienzo programación',
  'Start Scheduler': 'Comienzo Programación',
  'Choose duration and frequency': 'Seleccionar duración y frecuencia',
  'Choose Duration': 'Seleccionar Duración',
  'Choose Frequency': 'Seleccionar Frecuencia',
  '1 day': '1 día',
  '2 days': '2 días',
  '1 week': '1 semana',
  '15 minutes': '15 minutos',
  '30 minutes': '30 minutos',
  '1 hour': '1 hora',
  Logon: 'Conexión',
  'Draw circle': 'Trazar círculo',
  'Stop following': 'Dejar de seguir',
  'Fenced Area': 'Area Delimitada',
  'Group members': 'Miembro del grupo',
  'Enable Call log analysis': 'Habilitar Análisis de registro de llamadas',
  'This action will enable Call log analysis':
    'Esta acción permitirá el análisis del registro de llamadas',
  More: 'Más',
  'Cannot upload file': 'El archivo no puedo ser cargado',
  Neuter: 'Neutro',
  'Something went wrong': 'Algo salió mal',
  'Gathering activity patterns': 'Obteniendo patrones de actividad',
  'Gathering call analysis': 'Obteniendo análisis de llamadas',
  'Gathering top locations': 'Obteniendo ubicaciones principales',
  'Gathering count analysis': 'Obteniendo count analysis',
  Maps: 'Mapas',
  'Full name': 'Nombre completo',
  'Mostly active': 'Más activo',
  'Mostly inactive': 'Más inactivo',
  'Country and region': 'País y región',
  Region: 'Región',
  'Periods analyzed': 'Periodos analizados',
  Period: 'Periodo',
  File: 'Archivo',
  Starting: 'Empezando el',
  'Gathering details for targets': 'Obteniendo información para los objetivos',
  'Gathering call log common associates statistics':
    'Obteniendo estadísticas de contactos coincidentes en registros de llamada',
  'Activity Patterns': 'Patrones de actividad',
  'Call Analysis': 'Análisis de llamadas',
  'Call Logs': 'Registro de llamadas',
  'Top Associates': 'Asociados principales',
  'Top Locations': 'Ubicaciones principales',
  'Recovery accounts': 'Recuperando cuentas',
  'Target cannot be renewed. Not enough Credits':
    'El objetivo no puede ser renovado.  No cuenta con los créditos suficientes',
  'Intel Search Results': 'Resultados de la Búsqueda de Inteligencia',
  'View the target': 'Ver objetivo',
  'View the results': 'Ver los resultados',
  'We have successfully merged all the collected data to a':
    'Hemos fusionado con éxito todos los datos recolectados con',
  'single unique profile': 'un sólo perfil único',
  'Missing CDRs for this target': 'Faltan CDRs para este objetivo',
  'Waiting for CSV to be downloaded': 'Esperando la descarga del archivo CSV',
  'Exporting CSV': 'Exportar CSV',
  'We found that': 'Encontramos eso',
  'Intelligence suggestions': 'Sugerencias de inteligencia',
  'has used by the same device': 'ha utilizado por el mismo dispositivo',
  'Call log recommandation archived.':
    'Recomendación de registro de llamadas archivado',
  'Call log recommandation unarchived.':
    'Recomendación de registro de llamadas desarchivado',
  'Something went wrong, please try again.':
    'algo salió mal, por favor, intente de nuevo',
  'No archived recommandations available':
    'No hay recomendaciones archivadas disponibles',
  'No recommandations available': 'No hay recomendaciones disponibles',
  'Exporting file': 'Exportando archivo',
  'Fathers name': 'Apellido Paterno',
  'Mothers name': 'Apellido Materno',
  'Social insurance number': 'Número de seguro social',
  'Voter ID': 'ID de votante',
  'Call log record': 'Registro de historial de llamadas',
  'Do you want to extract details about the Call Peer':
    'Desea extraer información sobre el participante de la llamada',
  'Get information': 'Obtener información',
  'Get information & geolocate': 'Objetner información y geolocalizar',
  'No location data': 'Sin datos ubicación',
  'was located during a call': 'se ha encontrado durante una llamada',
  'Always obtain the peer details when available':
    'Obtener información sobre el participante de la llamada siempre que esté disponible',
  'Always obtain the peer details and geolocate when available':
    'Obtener información sobre el participante de la llamada y geolocalizar siempre que esté disponible',
  'Purchase call log': 'Comprar registro de llamada',
  'Show archived': 'Mostrar archivados',
  'Hide archived': 'Ocultar archivados',
  'This action will purchase the call logs for':
    'Esta acción comprará el registro de llamada para',
  'Frequently had consecutive communications with #{receiverMsisdn}':
    'Tuvo constante comunicación con #{receiverMsisdn}',
  'Frequently had consecutive communications with #{receiverMsisdn} within 1-hour time intervals,':
    'Tuvo constante comunicación con #{receiverMsisdn} dentro de intervalos de tiempo de 1 hora',
  'Frequently communicated with #{receiverMsisdn}':
    'Mantuvo comunicación constante con #{receiverMsisdn}',
  'located at #({latitude},{longitude})':
    'ubicado en #({latitude},{longitude})',
  'between hours #{hour.start}:00 and #{hour.end}:00':
    'entre las #{hour.start}:00 y las #{hour.end}:00',
  'between the #{day.start}th and #{day.end}th day of a month':
    'entre el día #{day.start}th y el día #{day.end}th del mes',
  'between the #{day.start}st and #{day.end}th day of a month':
    'entre el día #{day.start}st y el día #{day.end}th del mes',
  'on #{dayName}s': 'los #{dayName}s',
  'at year #{year}': 'del año #{year}',
  'on #{monthName}': 'el #{monthName}',
  January: 'enero',
  February: 'febrero',
  March: 'marzo',
  April: 'abril',
  May: 'mayo',
  June: 'junio',
  July: 'julio',
  August: 'agosto',
  September: 'septiembre',
  October: 'octubre',
  November: 'noviembre',
  December: 'diciembre',
  'This associate frequently interacts with the target.':
    'Este contacto interactúa frecuentemente con el objetivo.',
  'No new suggestions available': 'No hay nuevas sugerencias disponibles',
  'No suggestions available': 'No hay sugerencias disponibles',
  'This action will purchase and import call log for':
    'Esta acción comprará e importará el Registro de Llamadas para',
  'Importing data': 'Importando datos',
  'View call log analysis': 'Ver el análisis de Registro de Llamadas',
  "We found an anomaly! This person used the target's mobile device.":
    '¡Encontramos una anomalía! Esta persona uso el dispositivo móvil del objetivo',
  'This associate possibly met with the target.':
    'Este contacto posiblemente se reunió con el objetivo.',
  'Purchase call logs failed!': '¡Fallo la Compra de Registro de Llamadas!',
  'Purchase call logs initiated!': '¡Compra de Registro de Llamadas iniciada!',
  'Select at least one period file':
    'Seleccione al menos un periodo del archivo',
  'Select one or multiple periods': 'Seleccione uno o varios periodos',
  'Select atleast one IMEI/Phone model':
    'Seleccione un IMEI/Modelo de teléfono',
  'Failed to find intel suggestions!':
    '¡Error al encontrar sugerencias de inteligencia!',
  'What type of investigation are you working on?':
    '¿En qué tipo de investigación está trabajando?',
  'Opt-out': 'Desuscribirse',
  Homicide: 'Homicidio',
  Violence: 'Violencia',
  Robbery: 'Robo',
  Kidnapping: 'Secuestro',
  'Financial crime': 'Delitos Financieros',
  Fraud: 'Fraude',
  'Drugs and illegal substances': 'Drogas o sustancias ilegales',
  'Are you still in the same investigation?':
    '¿Sigue trabajando en la misma investigación?',
  'Your feedback is valuable!': '¡Su opinión es muy importante!',
  Dear: 'Hola',
  'We are always looking for the ways to improve our product for it to be even more useful in your daily work.':
    'Siempre estamos buscando la manera de mejorar nuestro producto para que sea aún más útil en su trabajo diario.',
  'To achieve this we are asking you to participate in the user feedback generation program where every now-and-then we would ask you simple questions about how you use Intellectus.':
    'Para lograr esto, le pedimos que participe en el programa de comentarios y sugerencias de usuarios, en el que de vez en cuando le haremos preguntas sencillas sobre cómo utiliza Intellectus.',
  'All the data collected will be completely anonymous.':
    'Toda la información recolectada es completamente anónima.',
  'Would you like to help us become even better?':
    '¿Le gustaría ayudarnos a mejorar?',
  'No, thank you': 'No, gracias',
  'Yes, I want to participate': 'Sí, quiero participar',
  'Send us your feedback!': '¡Enviar sus comentarios!',
  'Please provide a short description of your work.':
    'Por favor, describa brevemente su trabajo.',
  'All the answers are anonymous.': 'Todas las respuestas son anónimas.',
  "E.g., 'I work with drug cases in Panama' or 'I work with kidnapping investigations in Mexico'.":
    "E.g., 'Trabajo con casos sobre drogas en Panama' or 'Trabajo en investigaciones de sucestro en Mexico'.",
  'Are you sure you want to leave the program?':
    '¿Está seguro qque desea dejar el programa?',
  'Your feedback is very valuable and will help us to improve Intellectus. We would appreciate if you stayed!':
    'Su opinión es muy importante para nosotros y nos ayudaría a mejorar Intellectus. Le agradeceríamos si se quedara.',
  'Yes, I want to cancel': 'Sí, quiero salir',
  'No, I want to continue': 'No, deseo continuar',
  'Feedback is required': 'Sus comentarios son requeridos',
  'Automatic interactions': 'Interacciones automáticas',
  'Random actions': 'Acciones aleatorias',
  'Specific actions': 'Acciones específicas',
  'Send friend request': 'Enviar solicitud de amistad',
  'Like posts': 'Dar “Me gusta”',
  'Unlike posts': 'Quitar “Me gusta”',
  'Comment on posts': 'Comentar en publicaciones',
  'Like group posts': 'Dar “Me gusta” a publicaciones de grupos',
  'Unlike group posts': 'Quitar “Me gusta” a publicaciones de grupos',
  'Send friend requests to group members':
    'Enviar solicitud de amistad a miembros de grupo',
  'Share post': 'Compartir publicación',
  'Join random or specific communities on the selected social network.':
    'Unirse a comunidades específicas o aleatorias en la red social seleccionada.',
  'Please enter the URL for the specific community you want to be joined. &#10;One URL per line.':
    'Por favor, ingrese la URL de la comunidad en específico a la cual desea unirse. &#10;Una URL por línea.',
  'Enter the text': 'Ingrese el texto',
  'Add media content': 'Agregar contenido multimedia',
  'Create post': 'Crear publicación',
  'Drag media here': 'Arrastre aquí el contenido multimedia',
  'Choose media for upload': 'Seleccione los medios a subir',
  'Delete file': 'Elimine archivo',
  'Field is required': 'El campo es requerido',
  Run: 'Ejecutar',
  'Stop method was successfully stopped':
    'El método de detención se detuvo con éxito',
  'Run method was successfully started':
    'El método de ejecución se inició con éxito',
  'Create post was successfully created':
    'Crear publicación fue creada con éxito',
  'Choose an interval': 'Seleccione un intervalo',
  'Repeat interval - hours': 'Repetir intervalo - horas',
  'Virtual IDs': 'IDs Virtuales',
  Activities: 'Actividades',
  'Virtual id': 'ID Virtual',
  Idle: 'Inactivo',
  Stopped: 'Detenido',
  Id: 'Id',
  'Time stamp': 'Registro',
  'New virtual ID': 'Nuevo ID virtual',
  'Send friend requests': 'Enviar solicitudes de amistad',
  'Social network': 'Red social',
  Avatar: 'Avatar',
  'No activity log for this avatar':
    'No hay registro de actividades para este avatar',
  'User settings': 'Menú de Usuario',
  'My details': 'Mi información',
  'Invalid type - allowed only png, jpg, jpeg':
    'Tipo de archivo inválido - sólo se permite png, jpg, jpeg',
  'Action has been successfully created':
    'Esta acción ha sido creada con éxito',
  'Action is stopped': 'Acción detenida',
  'Action is started': 'Acción iniciada',
  'Must be at least 3 characters long and maximum characters allowed are 15, special charactes are not allowed':
    'Debe contener un mínimo de 3 caracteres de largo y un máximo de 15, los caracteres especiales no están permitidos',
  Running: 'En ejecución',
  'item selected': 'artículos seleccionados',
  'Post is created': 'Publicación creada',
  'Post is shared': 'Publicación compartida',
  'Post is liked': 'Se ha dado Me gusta a publicaciones',
  'Post is disliked': 'Se ha dado No Me gusta a publicaciones',
  'An international #{type} to or from #{msisdn} coming from #{country} has happened at least #{frequency} time(s).':
    'Un #{type} internacional hacia o desde #{msisdn} procedente de #{country} ha ocurrido al menos #{frequency} hora(s).',
  'Dislike posts': 'Dar "No me gusta',
  'Join group': 'Unirse a grupo',
  Specific: 'Específico',
  Random: 'Aleatorio',
  'Enter URL': 'Ingrese URL',
  'Joined group': 'Unió a un grupo',
  'Please enter the URL for the specific community you want to be joined':
    'Por favor, ingrese la URL de la comunidad en específico a la cual desea unirse',
  'OTP - One Time Password': 'OTP - Contraseña de una sola vez',
  'Please select the type of OTP you want to use.':
    'Por favor, seleccione el tipo de OTP que desea usar.',
  'Email address': 'Correo electrónico',
  Unavailable: 'No disponible',
  'Add phone number': 'Agregar número telefónico',
  'We sent a verification code to the email address:':
    'Enviamos el código de verificación al correo electrónico:',
  'We sent a verification code to the phone number:':
    'Enviamos el código de verificación al número telefónico:',
  'Enter the code received via email':
    'Ingrese el código recibído vía correo electrónico',
  'Enter the code received via SMS': 'Ingrese el código recibído vía SMS',
  'Valid phone number entered.': 'El número telefónico ingresado es válido.',
  'Disabled Feature': 'Funcionalidad Desactivada',
  'Webint features are disabled. Please contact your customer success representative.':
    'La funcionalidad Webint está desactivada. Por favor, contacte a su representante de éxito del cliente.',
  'Contact Customer Success': 'Contacte a Éxito del Cliente',
  'Thank you for your message. Your customer success representative will contact you shortly':
    'Gracias por su mensaje. Su representante de éxito del cliente le contactará en breve',
  'Must be at least 10 characters long and special characters are not allowed':
    'Debe contener al menos 10 caracteres de largo y no se permiten caracteres especiales',
  'GiO - GeoLocation Services - For exclusive usage by government law enforcement agency: Government of the State of Nuevo Leon - Backed up by EUC - 2017':
    'GiO - Servicio de Geolocalización - Para uso exclusivo de agencias policiacas de gobierno: Gobierno del Estado de Nuevo León - Respaldado por EUC - 2017',
  'Passwords not provided': 'Contraseña no proporcionada',
  'Permission denied': 'Permiso denegado',
  'Phone Number': 'Número telefónico',
  'Current Password': 'Contraseña actual',
  Activated: 'Activado',
  Method: 'Método',
  Application: 'Aplicación',
  'Change OTP method': 'Cambiar método OTP',
  'You selected to change OTP method from email to the following phone number':
    'Ha seleccionado cambiar su método OTP de correo electrónico a el siguiente número telefónico',
  'You selected to change OTP method from email to application':
    'Ha seleccionado cambiar su método OTP de correo electrónico a la aplicación',
  'You selected to change OTP method from phone number to the following email address':
    'Ha seleccionado cambiar su método OTP de número telefónico a la siguiente dirección de correo electrónico',
  'You selected to change OTP method from phone number to application':
    'Ha seleccionado cambiar su método OTP de número telefónico a la aplicación',
  'You selected to change OTP method from application to the following phone number':
    'Ha seleccionado cambiar su método OTP de la aplicación al el siguiente número telefónico',
  'You selected to change OTP method from application to the following email address':
    'Ha seleccionado cambiar su método OTP de la aplicación a la siguiente dirección de correo electrónico',
  "#{msisdn} has been used on target's phone #{imei}":
    '#{msisdn} se ha utilizado en el teléfono objetivo #{imei}',
  'Call log report in PDF': 'Reporte de Registro de Llamadas en PDF',
  'Export table': 'Exportar tabla',
  'Exporting table in CSV': 'Exportar tabla en CSV',
  'Call log request is already in pending':
    'Su solicitud de registro de llamadas está pendiente',
  'Export detailed geolocation report':
    'Exportar reporte detallado de geolocalización',
  'Unveil movement patterns and understand your target’s habits':
    "Revelar patrones de movimiento y entender hábitos de su objetivo':entender los hábitos de su objetivo",
  'Unveil movement patterns and understand your target’s habits and associates':
    'Revelar patrones de movimiento y entender hábitos y contactos de su objetivo',
  'Locate now': 'Ubicar ahora',
  'Get current location of this MSISDN':
    'Obtener ubicación actual de este MSISDN',
  'Investigate lead': 'Pista de investigación',
  'Get historical locations': 'Obtener historial de ubicaciones',
  'Uncover usernames, open web mentions, email and social media accounts':
    'Descubrir nombres de usuario, menciones en open web, email y cuentas de redes sociales',
  'Find possible associates, visited places, connections on social media, groups and more.':
    'Encontrar posibles asociados, lugares visitados, conexiones en redes sociales, grupos y más',
  'Credits will be deducted only if accurate information is found.':
    'Los créditos serán deducidos sólo en caso de encontrar información.',
  'Uncover usernames, open web mentions, email and social media accounts that are associated with':
    'Descubrir usuarios, menciones en open web, email y cuentas de redes sociales asociadas con',
  'Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.':
    'Encontrar posibles contactos, lugares visitados, conexiones en redes sociales, grupos y más. Los créditos serán deducidos sólo en caso de encontrar información.',
  'Create a new target and find possible associates, visited places, connections on social media, groups and more.':
    'Crear un nuevo objetivo y encontrar posibles contactos, lugares visitados, conexiones en redes sociales, grupos y más.',
  'For generating OTP codes, please download the Authenticator App':
    'Para generar códigos OTP, por favor, descargue la app Authenticator',
  'Add email': 'Agregar email',
  'Valid email entered': 'Dirección de email válida',
  '2-Step Verification is OFF': 'La verificación de dos pasos está DESACTIVADA',
  'Turn On': 'Activar',
  'Turn Off': 'Desactivar',
  CURP: 'CURP',
  IFE: 'IFE',
  RFC: 'RFC',
  'Start typing phone, name, CURP, url, email or username or upload photo':
    'Comience a escribir teléfono, nombre, CURP, url, correo electrónico o nombre de usuario o cargue una foto',
  'data points': 'puntos de datos',
  'Perform Location History': 'Realizar historial de ubicaciones',
  'We now support search by CURP ID': 'Ahora soportamos la búsqueda por CURP',
  'List of all targets': 'Lista de todos los objetivos',
  'List of all cases': 'Lista de todos los casos',
  'List of all imported call detail records':
    'Lista de todos los registros de detalle de llamada importados',
  Investigation: 'Investigación',
  'Tool to analyze different types of intelligence':
    'Herramienta de análisis de diferentes tipos de inteligencia',
  'Invalid email address': 'Dirección de correo electrónico invalida',
  Invalid: 'Inválido',
  'Pending call log request!': '¡Solicitud de registro de llamadas pendiente!',
  'Pending call log': 'Solicitud de registro de llamadas',
  'Request or Upload Call Log by selecting one of the following options':
    'Solicite o Cargue un Registro de Llamadas seleccionando una de las siguientes opciones',
  'New call log': 'Nuevo registro de llamadas',
  Investigations: 'Investigaciones',
  investigation: 'investigación',
  nodes: 'nodos',
  links: 'enlaces',
  'New investigation': 'Nueva investigación',
  'Create case investigation': 'Crear caso de la investigación',
  'Enter investigation name': 'Ingrese el nombre de la investigación',
  'Enter a short description of your investigation':
    'Ingrese una breve descripción de su investigación',
  'Please select at least 1 entity to create a new investigation':
    'Por favor, seleccione al menos 1 entidad para crear una nueva investigación',
  'Selected queue': 'Cuota seleccionada',
  Graph: 'Gráfica',
  'Loading data': 'Cargando información',
  'Would you like to save the changes you made in this investigation?':
    '¿Desea guardar los cambios realizados a esta investigación?',
  'Go to call logs page': 'Ir a la página de registros de llamada',
  'Select entity': 'Seleccionar entidad',
  'Details panel': 'Panel de información',
  'View target': 'Ver objetivo',
  'View profile': 'Ver perfil',
  'Enter MSISDN': 'Ingrese MSISDN',
  "Add targets by searching for target's Alias.":
    'Agregue objetivos buscando el alias del objetivo.',
  'closed databases': 'Bases de datos cerradas',
  'Back to "Core"': 'Regresar a "Core"',
  Core: 'Core',
  'Webint search history': 'Historial de búsqueda Webint',
  'Upload another file': 'Subir otro archivo',
  'Request Call Log by selecting one of the following options':
    'Solicite un Registro de Llamadas seleccionando una de las siguientes opciones',
  'Upload Call Log by selecting one of the following options':
    'Cargue un Registro de Llamadas seleccionando una de las siguientes opciones',
  Fixed: 'Fijo',
  'Credit usage': 'Uso de créditos',
  Quota: 'Cuota',
  WEBINT: 'WEBINT',
  'Maximum quota': 'Cuota máxima',
  'Tenant balance': 'Balance del tenant',
  'credits assigned': 'créditos asignados',
  'credits available': 'créditos disponibles',
  Somedus: 'Somedus',
  credit: 'Créditos',
  'Daily credits assigned': 'Créditos diarios asignados',
  'Daily credits available': 'Créditos diarios disponibles',
  'Monthly credits assigned': 'Créditos mensuales asignados',
  'Monthly credits available': 'Créditos mensuales disponibles',
  'Fixed credits assigned': 'Créditos fijos asignados',
  'Fixed credits available': 'Créditos fijos disponibles',
  'Maximum credits assigned': 'Créditos máximos asignados',
  'Maximum credits available': 'Créditos máximos disponibles',
  'Raw data': 'Datos en crudo',
  'Fixed quota': 'Cuota fija',
  'You have': 'Cuenta con',
  'available of your queries quota': 'disponible de su cuota de consultas',
  'Start Investigation': 'Empezar investigación',
  'Cases and Targets': 'Casos y Objetivos',
  'Load a target': 'Cargar objetivo',
  'Add MSISDN': 'Agregar MSISDN',
  'Load a case': 'Cargar caso',
  'Graph settings': 'Configuración de gráfica',
  'Select analysis': 'Seleccionar análisis',
  'Select timeline analysis': 'Seleccionar análisis de fechas',
  'Select layout': 'Seleccionar formato',
  Restore: 'Restaurar',
  'Highlight nodes based on number and type of direct connections':
    'Resaltar los nodos basados en el número y tipo de conexiones directas',
  'Highlight nodes that act as "bridges" between the connected network':
    'Resaltar nodos que actúen como "conexiones" entre la red',
  'Calculates the extended connections of a node. Can identify nodes with influence over the whole network, and not just those directly connected to it.':
    'Calcula las conexiones extendidas de un nodo. Puede calcular los nodos con influencia en la red. y no solamente los conectados a esta.',
  Betweenness: 'Relación',
  'Eigen centrality': 'Eigen centrality',
  'Hour of day': 'Hora del día',
  'Day of week': 'Día de la semana',
  'Day of month': 'Día del mes',
  'Month of year': 'Mes del año',
  Standard: 'Estándar',
  Organic: 'Orgánico',
  Structural: 'Estructural',
  Lens: 'Lente',
  Tweak: 'Tweak',
  Sequential: 'Secuencial',
  Sources: 'Fuentes',
  'Filter by source type': 'Filtrar por tipo de fuente',
  'Are you sure you want to delete investigation permanently?':
    '¿Está seguro que desea eliminar esta investigación de forma permanente?',
  'Permanently delete investigation': 'Eliminar investigación permanentemente',
  'File name must match #{type} number':
    'El nombre del archivo debe ser igual al número #{type}',
  'Upload call log for: #{value}': 'Cargar registro de llamadas para: #{value}',
  'File name': 'Nombre del archivo',
  'File size': ' Tamaño del archivo',
  'Vehicle year': 'Año de vehículo',
  'Vehicle model': 'Modelo de vehículo',
  'quota reset to initial balance': 'Reinicio de cuota a balance inicial',
  call_log_upload_imei: 'Carga de CDR exitosa IMEI',
  'Credit change for request_cdr for IMEI':
    'Cargo de crédito por solicitud de CDR del IMEI',
  'Vehicle ID (VIN)': 'ID de vehículo',
  'Property type(s)': 'Tipo de propiedad(es)',
  'Property last sale date(s)': 'Última venta de la propiedad',
  Industries: 'Industrias',
  'Vehicle make': 'Marca de Vehículo',
  Volunteering: 'Voluntariado',
  Skills: 'Habilidades',
  Courses: 'Cursos',
  'Associated domains': 'Dominios asociados',
  'Home last sale date': 'Ultima fecha de venta de la casa',
  'Property type': 'Tipo de propiedad',
  'Fan of': 'Fan de',
  'Other info': 'Otra información',
  'All groups': 'Todos los grupos',
  'All fans': 'Todos los fans',
  'All skills': 'Todas las habilidades',
  'All courses': 'Todos los cursos',
  'All industries': 'Todas las industrias',
  'All vehicle models': 'Todos los modelos de vehículos',
  'All vehicle make': 'Todas las marcas de vehículos',
  'All vehicle ID (VIN)': 'Todos los ID de vehículos',
  'All volunteering actions': 'Todas las acciones de voluntarios',
  'All associated domains': 'Todos los dominios asociados',
  'All other info': 'Toda la demás información',
  'Family relations': 'Relaciones familiares',
  'Work relations': 'Relaciones laborales',
  'Other relations': 'Otras relaciones',
  Relation: 'Relación',
  'Vehicle models': 'Modelos de vehículos',
  'Volunteering actions': 'acciones de voluntarios',
  'Only xlsx or csv files are supported':
    'Sólo están soportados archivos xlsx o csv',
  'Daily quota assigned': 'Cuota diaria asignada',
  'Monthly quota': 'Cuota mensual',
  Maximum: 'Máxima',
  'Available Credits': 'Créditos Disponibles',
  'Define quota': 'Definir cuota',
  'Monthly quota assigned': 'Cuota mensual asignada',
  'Maximum quota assigned': 'Cuota máxima asignada',
  'Quota unused': 'Cuota no utilizada',
  'Quota assigned': 'Cuota asignada',
  'Available to assign': 'Disponible para asignar',
  'Daily quota': 'Cuota diaria',
  'Other recovery accounts': 'Otras cuentas de recuperación',
  'User created with': 'Usuario creado con',
  'credits by': 'creditos por',
  inactive: 'inactivo',
  'Timeline pattern': 'Patrón de actividad',
  'Most active period': 'Período más activo',
  'Most inactive period': 'Periodo menos activo',
  Law: 'Ley',
  'No credits assigned': 'Sin créditos asignados',
  'List of periods': 'Lista de periodos',
  'Targets renewed successfully!': '¡Objetivo renovado satisfactoriamente!',
  'Recovery Accounts': 'Cuentas de Recuperación',
  'All friend relations': 'Todas las relaciones de amigos',
  'All family relations': 'Todas las relaciones familiares',
  'All work relations': 'Todas las relaciones laborales',
  'All other relations': 'Todas las demás relaciones',
  'Friend relations': 'Relaciones de amigos',
  Fans: 'Fans',
  'List of msisdns': 'Lista de MSISDN',
  'List of imeis': 'Lista de IMEI',
  'List of imsis': 'Lista de IMSI',
  'Phone models / IMEIs': 'Modelo de teléfono / IMEI',
  'Select one or multiple imsis': 'Seleccione uno o múltiples IMSI',
  'Select one of multiple msisdns': 'Seleccione uno o múltiples MSISDN',
  'Select one or multiple models': 'Seleccione uno o múltiples modelos',
  'Select one or multiple msisdns': 'Seleccione uno o múltiples MSISDN',
  IMSIs: 'IMSI',
  target_management: 'Administración de objetivos',
  advanced_osint: 'OSINT Avanzado',
  'One successful Advanced OSINT': 'Una exitosa OSINT Avanzado',
  'One successful Intel Search': 'Una exitosa Búsqueda de inteligencia',
  'Advanced osint': 'OSINT Avanzado',
  call_info: 'Información de Llamada',
  case_management: 'Administración de caso',
  neighbour_cell_towers: 'Torres cercanas',
  'Neighbour cell towers with params': 'Torres cercanas con parámetros',
  trilateration_nearby_people: 'Trilaterización de personas cercanas',
  query_foreign_location: 'Consulta de Ubicación en Extranjero',
  'Place of birth': 'Lugar de nacimiento',
  'Favorite results': 'Resultados favoritos',
  'Work place': 'Lugar de Trabajo',
  'Vehicle ID': 'ID de Vehículo',
  'Dates of birth': 'Fecha de nacimiento',
  'Switch to dark theme': 'Cambiar a modo oscuro',
  Hierarchy: 'Jerárquico',
  Sequencial: 'Secuencial',
  'Current And Previous Workplaces (Target)':
    'Lugar de trabajo actual y anterior (Objetivo)',
  'Education (Target)': 'Educación (Objetivo)',
  'Switch to light theme': 'Cambiar a modo claro',
  'Export current investigation view':
    'Exportar la vista actual de la investigación',
  'Enter report title': 'Ingrese el Título del Reporte',
  'Enter notes': 'Ingresar notas',
  'Generate pdf': 'Generar PDF',
  'Export CSV': 'Exportar CSV',
  renewed: 'renovado',
  'Top Facebook Connections': 'Principales Conexiones de Facebook',
  'Mutual Facebook Friends': 'Amigos Comunes en Facebook',
  'Top Commenteers In Facebook Photos':
    'Principales Comentaristas de Fotos en Facebook',
  'Top Likers In Facebook Posts':
    'Principales Likers de Publicaciones en Facebook',
  Studied: 'Estudió',
  'Connected with this location': 'En conexión con esta ubicación',
  'Profile is associated with this photo': 'Perfil asociado con esta foto',
  'Profile is associated with this post':
    'El perfil está asociado con esta publicación',
  'View post': 'Ver publicación',
  'View photo': 'Ver foto',
  'Tagged in posts of': 'Etiquetado en publicaciones de',
  'Tagged in photos of': 'Etiquetado en fotos de',
  Home: 'Casa',
  'current and previous workplaces (target)':
    'Lugar de trabajo actual y anterior (Objetivo)',
  'education (target)': 'educación (objetivo)',
  'Location probability': 'Probabilidad de ubicación',
  'Search name, MSISDNs or cases': 'Buscar nombre, MSISDN o casos',
  'Search MSISDNs': 'Buscar MSISDN',
  'Call logs interaction depth':
    'Interacción de registros de llamada a detalle',
  'Export graph': 'Exportar gráfica',
  'Drag and drop to load a target to the investigation':
    'Arrastre y suelte para agregar un objetivo a la investigación',
  'Drag and drop to add an MSISDN to the investigation':
    'Arrastre y suelte para agregar un MSISDN a la investigación',
  'Drag and drop to load a case to the investigation':
    'Arrastre y suelte para agregar un caso a la investigación',
  'Search entities': 'Buscar entidades',
  'IP addresses': 'Dirección IP',
  'All IP addresses': 'Todas las direcciones IP',
  call_log_upload_msisdn: 'Carga de CDR MSISDN',
  case: 'caso',
  time: 'vez',
  times: 'veces',
  'View call log': 'Ver registro de llamadas',
  'Add at least 1 MSISDN or IMEI': 'Agregue al menos 1 MSISDN o IMEI',
  'The inputted #{clParameterType} is partially invalid. Please update your request with the following #{clParameterType}:':
    'El #{clParameterType} introducido es parcialmente inválido. Por favor, actualice su solicitud con el siguiente #{clParameterType}:',
  '#{clParameterType} entered is not valid. Please try another one':
    'El #{clParameterType} introducido no es válido. Por favor, pruebe con otro',
  call_log_purchase: 'Compra de CDR',
  logon_query_location_offline:
    'Consulta de ubicación por conexión fuera de línea',
  import_historical_locations: 'Importe de historial de ubicaciones',
  'Import Historical Locations': 'Importe de historial de ubicaciones',
  'request cdr': 'Solicitud de CDR',
  'Top Commenteers In Facebook Posts':
    'Top comentadores de publicaciones en Facebook',
  'Tagged In Same Photo': 'Etiquetados en las mismas fotos',
  'Top Facebook Post Shares': 'Top compartidores de publicaciones en Facebook',
  'Top Facebook Photo Shares': 'Top comentadores de fotos en Facebook',
  'Top Likers In Facebook Photos': 'Principales Likers de Fotos en Facebook',
  query_location_offline: 'Consulta de Ubicación Fuera de Línea',
  'Purchase and import call log data for': 'Compra e importación de CDR para',
  'Gathering common locations': 'Obteniendo ubicaciones en común',
  Peer: 'Llamado(a)',
  call_log_request_msisdn: 'Solicitud de CDR',
  'Advanced webint is already in progress.':
    'Webint Avanzado ya está en progreso',
  months: 'meses',
  'Please select the time period': 'Por favor, seleccione el periodo',
  'Note: Time periods are not available for Upload call logs ':
    'Nota: Los periodos de tiempo no están disponible para la carga de Registros de Llamada',
  'You have to save the investigation before uploading a file':
    'Tiene que guardar la investigación antes de cargar un archivo',
  'Credits are expired': 'Los créditos están vencidos',
  'Call log request #{type} for #{duration} months':
    'Solicitud de CDR por #{type} de #{duration} meses',
  'One successful call log request for #{type} #{value} for the period of #{duration} months':
    'Solicitud de CDR satisfactoria para el #{type} #{value} para el periodo de #{duration} meses',
  'Call log upload for #{type}': 'Una existosa Carga de #{type}',
  'One successful import cdr for #{type} #{value}':
    'Una exitosa Carga de CDR #{type} por #{type} #{value}',
  'Select case': 'Seleccione un caso',
  'Change language': 'Cambiar idioma',
  'Age range': 'rango de edad',
  'Get Facebook Posts': 'Obtener publicaciones de Facebook',
  'Get Cell Level Location': 'Obtener ubicación a nivel torre',
  'Do you want to get cell level location?':
    '¿Desea obtener ubicación a nivel torre?',
  'Query successfully submitted': 'Consulta enviada satisfactoriamente',
  'IP Location': 'Ubicación de IP',
  'Get Facebook Relations': 'Obtener relaciones en Facebook',
  'Software Information': 'Información del programa',
  'Covid vaccine': 'Vacunación COVID',
  Pregnant: 'Embarazo',
  'House number': 'Número de Casa ',
  'Wanted for': 'Buscado por',
  'Skin tone': 'Tono de Piel',
  'Medical condition': 'Condición Médica',
  'Card number': 'Número de Tarjeta',
  Cv: 'CV',
  'Birth location': 'Lugar de Nacimiento',
  Scars: 'Cicatrices',
  'Covid vaccination status': 'Estado de Vacunación COVID',
  'Registration date': 'Fecha de Registro',
  'Affiliated person': 'Persona Afiliada',
  'Act number': 'Número de Acta',
  'Is dead': 'Fallecido',
  'Identification card number': 'Número de Tarjeta de Identificación ',
  Institution: 'Institución',
  'Instagram handle': 'Instagram',
  Cve: 'CV',
  'Bank details': 'Información bancaria',
  Colony: 'Colonia',
  Consec: 'Consec',
  'Photo update time': 'Fecha de actualización de foto',
  'To search for a phone number, enter the complete number, including country code. Example: #{example}':
    'Para buscar un número de teléfono, introduzca el número completo, incluido el prefijo del país. Ejemplo: #{example}',
  'Enter the complete phone number including country code. Example: #{example}':
    'Introduzca el número de teléfono completo, incluido el prefijo del país. Ejemplo: #{example}',
  'View place': 'Ver lugar',
  'is in': 'está en',
  'View Dashboard': 'Ver Tablero',
  'Credits Usage Statistics': 'Estadísticas de Uso de Créditos',
  'New Credits Usage Statistics Dashboard':
    'Nuevo Tablero de Estadísticas de Uso de Créditos',
  'check in': 'lugares en',
  'Username already exists. Please try again.':
    'El nombre de usuario ya existe. Por favor, intente de nuevo.',
  'Peer interactions': 'Interacciones',
  'Accept friends': 'Aceptar amigos',
  'Error occured while attempting to reserve':
    'Ocurrió un error al intentar reservar',
  'Postal code': 'Código postal',
  'Facebook id': 'Facebook id',
  Callerid: 'Callerid',
  Foreign: 'Extranjero',
  successfully: 'con éxito',
  'Cancelled request': 'Solicitud cancelada',
  'This Virtual ID will expire on': 'Esta ID virtual expira el',
  'Expires On': 'Expira el',
  'Expiration date': 'Fecha de Expiración',
  Generate: 'Generar',
  'Virtual ID #{fullName} expired on #{formatedDate}. Click on the button below to renew for #{numberOfDays} days.':
    'La ID Virtual #{fullName} expiró el #{formatedDate}. Haga clic en el botón a continuación para renovarla por #{numberOfDays} días.',
  'Virtual ID could not be renewed. Please try again.':
    'La ID Virtual no pudo ser renovada. Por favor, intente de nuevo.',
  'Virtual ID renewed successfully.': 'ID Virtual renovada satisfactoriamente.',
  'Virtual IDs will expire in 24 hours':
    'Algunas ID Virtuales expirarán en 24 horas',
  'View the list': 'Ver la lista',
  'will expire soon': 'expirará pronto',
  'Virtual IDs have expired': 'Las ID Virtuales han expirado',
  'Drag and drop to upload file for Text analysis':
    'Arrastre y suelte archivo para  Análisis de Texto',
  'the extention supported are:': 'Las extensión soportadas son',
  Browse: 'Buscar',
  'Add URL': 'Agregar URL',
  'Please write the URL bellow and press enter if valid':
    'Por favor, agregue la URL a continuación y presione entrar si es válida',
  'Cancel all uploads': 'Cancelar todas las cargas',
  'Download file': 'Descargar archivo',
  'Remove node': 'Remover nodo',
  'Text analysis the file': 'Análisis de Texto del archivo',
  'Open in new page': 'Abrir en una nueva página',
  'Uploaded on': 'Cargado el',
  Entities: 'Entidades',
  'Suspicious Words': 'Palabras sospechosas',
  mentions: 'Menciones',
  'Something went wrong with File Text Analysis':
    'Algo salió mal con el Análisis de Texto del Archivo',
  'Extracted entity from file': 'Entidad extraída del archivo',
  'text analysis': 'Análisis de texto',
  Failed: 'Falló',
  'Word Cloud': 'Nube de palabras',
  Popularity: 'Popularidad',
  Sentiment: 'Sentimento',
  'Entity Type': 'Tipo de Entidad',
  Suspicious: 'Sospechoso',
  Who: 'quién',
  Where: 'dónde',
  When: 'cuándo',
  Action: 'acción',
  Object: 'objeto',
  'There are no suspicious words': 'No hay palabras sospechosas',
  'Show #{count} more': 'Mostrar #{count} más',
  'Please write the URL bellow and press enter if is valid':
    'Por favor, agregue la URL a continuación y presione entrar si es válida',
  'Loading the file could not be completed.':
    'La carga del archivo no pudo ser completada',
  'Please review the local file and upload again.':
    'Por favor, revise el archivo local e intente de nuevo',
  'Extracted event from file.': 'Evento extraído desde archivo.',
  'Non expiring': 'Sin expiración',
  'Show only articles with': 'Mostrar sólo artículos con',
  'All companies': 'Todas las empresas',
  'Site name': 'Nombre del Sitio',
  'All site names': 'Todos los sitios',
  'Missing file to upload': 'Falta archivo para cargar',
  'Results for': 'Resultados para',
  'Copy value': 'Copiar',
  'Area of interest is limited to 500 meters': 'Area of interest is limited to 500 meters',
};
