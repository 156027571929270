<div class="profiler-target-note-form">
  <div class="row end-xs">
    <mat-icon
      class="target-note-form-close-btn clickable"
      matTooltip="{{ 'Close' | translate }}"
      (click)="onClose()"
      >close
    </mat-icon>
  </div>
  <img src="assets/static/images/profiler/edit.svg" height="25" width="25" />
  <p class="target-note-form-header">{{ title | translate }}</p>
  <div mat-dialog-content>
    <form [formGroup]="targetNoteForm" class="target-note-form">
      <input
        formControlName="title"
        maxlength="20"
        class="global-input note-input"
        placeholder="{{ 'Title' | translate }}"
      />
      <textarea
        formControlName="description"
        class="global-textarea note-input"
        placeholder="{{ 'Description' | translate }}"
      ></textarea>
    </form>
  </div>
  <div class="row center-xs target-note-form-actions">
    <div class="col-12">
      <button mat-raised-button class="cancel" (click)="onClose()" type="button">
        {{ 'Cancel' | translate }}
      </button>
      <button
        mat-raised-button
        class="ok"
        color="accent"
        type="submit"
        [disabled]="targetNoteForm.invalid"
        (click)="onSubmit()"
      >
        {{ 'Save' | translate }}
      </button>
    </div>
  </div>
</div>
