<div class="extract-peer-popup" *ngIf="popupIsOpen">
  <div class="popup-title">
    <span class="title">{{ 'Call log record' | translate }}</span>
    <button class="close-extract-popup" (click)="togglePopUp()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="popup-body">
    <div class="popup-description" *ngIf="sectionMap.userEquipment.hasData">
      <p class="description-header">&ndash; {{ 'User equipment' | translate }}</p>
      <div class="row" *ngIf="sectionMap.userEquipment.fields.msisdn">
        <div class="col-6 col-title">{{ 'MSISDN' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.queryArgs?.telno }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.userEquipment.fields.status">
        <div class="col-6 col-title">{{ 'Status' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.device?.state }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.userEquipment.fields.phoneModel">
        <div class="col-6 col-title">{{ 'Phone model' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.device?.phoneModel }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.userEquipment.fields.imei">
        <div class="col-6 col-title">{{ 'IMEI' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.device?.imei }}</div>
      </div>
    </div>

    <div class="popup-description" *ngIf="sectionMap.mobileNetwork.hasData">
      <p class="description-header">&ndash; {{ 'Mobile Network' | translate }}</p>
      <div class="row" *ngIf="sectionMap.mobileNetwork.fields.provider">
        <div class="col-6 col-title">{{ 'Provider' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.provider?.name }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.mobileNetwork.fields.lac">
        <div class="col-6 col-title">{{ 'LAC' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.cell?.lac }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.mobileNetwork.fields.cellEnbid">
        <div class="col-6 col-title">{{ 'Cell ENBID' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.cell?.enbid }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.mobileNetwork.fields.cellLcid">
        <div class="col-6 col-title">{{ 'Cell LCID' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.cell?.lcid }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.mobileNetwork.fields.cellId">
        <div class="col-6 col-title">{{ 'Cell Id' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.cell?.cellId }}</div>
      </div>
      <div class="row" *ngIf="sectionMap.mobileNetwork.fields.radio">
        <div class="col-6 col-title">{{ 'Radio' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.cell?.cellType }}G</div>
      </div>
    </div>

    <div class="popup-description" *ngIf="sectionMap.location.hasData">
      <p class="description-header">&ndash; {{ 'Location' | translate }}</p>
      <div class="row" *ngIf="sectionMap.location.fields.latLon">
        <div class="col-6 col-title">Lat / Lon:</div>
        <div class="col-6 col-value">
          {{ query?.location?.coordinates[1] }} /
          {{ query?.location?.coordinates[0] }}
        </div>
      </div>
      <div class="row" *ngIf="sectionMap.location.fields.radius">
        <div class="col-6 col-title">{{ 'Radius' | translate }}:</div>
        <div class="col-6 col-value">{{ query?.accuracyMeters }}m</div>
      </div>
      <div class="row" *ngIf="sectionMap.location.fields.date">
        <div class="col-6 col-title">{{ 'Date' | translate }}:</div>
        <div class="col-6 col-value">
          {{ query.locationReceivedAt | date: 'EEE dd MMM yyyy H:mm' }}
        </div>
      </div>
      <div class="row" *ngIf="sectionMap.location.fields.aol">
        <div class="col-6 col-title">AoL:</div>
        <div class="col-6 col-value">{{ query?.ageOfLocationInMinutes }} min</div>
      </div>
    </div>
  </div>
</div>

<div class="marker-template" (click)="togglePopUp()">
  <img class="marker-image" [src]="markerPath" alt="" />
</div>
