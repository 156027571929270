<div *ngIf="target" [class.clickable]="!isCurrentTarget && !updateExistingTargetView"
  [ngStyle]="{ border: '2px solid ' + color }" [class.current-target-item]="isCurrentTarget"
  [class.case-view-target-item]="caseView" [class.active]="targetActiveQuarantine" class="profiler-target-item text-center"
  [class.geo-details-view]="geoDetailsView" (click)="navigateToTarget()">
  <div class="top-row">
    <div *ngIf="target.countryCode" class="country-info">
      <span class="iti__flag iti__{{ target.countryCode | lowercase }}"></span>
      {{ target.countryCode | uppercase }}
    </div>
    <img *ngIf="target.assignedCases && target.assignedCases.length > 0" class="linked-cases"
      src="assets/static/images/profiler/linked_cases.svg" height="20" width="20" [matMenuTriggerFor]="cases"
      (click)="openLinkedCases($event)" />
    <img *ngIf="!updateExistingTargetView" class="options" src="assets/static/images/options.svg" height="20" width="20"
      [matMenuTriggerFor]="more" (click)="openMenu($event)" [class.hidden]="geoDetailsView" />
  </div>
  <div class="center-xs">
    <div class="target-info">
      <ngx-avatar *ngIf="nameInitials" value="{{ nameInitials }}"></ngx-avatar>
      <span class="img-data" *ngIf="!nameInitials">
        <img class="target-photo" [src]="targetImage || imagePlaceholder" loading="lazy"
          (error)="handleAvatarError()" />
      </span>
      <div *ngIf="!geoDetailsView" class="target-name global-text-overflow-wrap" [matTooltip]="target.alias">
        <span *ngIf="aliasEdit" [class.increase-width]="target.alias.length > 16" class="text-ellipsis"
          [matTooltip]="target.alias">{{ target.alias }}</span>

        <!-- Alias Edit -->
        <ng-container *ngIf="targetId && !caseView">
          <input *ngIf="!aliasEdit" class="aliasEditInput" type="text" name="alias" [formControl]="aliasInputCtrl"
            #aliasInput [class.readonly]="loader" />
          <mat-icon *ngIf="aliasEdit" [class.not-editable]="loader" (click)="onEditAlias(target.alias)">edit</mat-icon>
          <div *ngIf="!aliasEdit && !loader" class="alias-action flex-wrp">
            <mat-icon *ngIf="!errorMsg && !aliasInputCtrl.invalid" class="done"
              (click)="updateTargetAlias(aliasInputCtrl.value)">done</mat-icon>
            <mat-icon class="close" (click)="(aliasEdit = true) && (errorMsg = false)">close</mat-icon>
          </div>
          <img *ngIf="loader" src="assets/static/images/profiler/loader.svg" alt="loading" width="30" />

          <!-- Alias Edit Related Errors -->
          <div class="alias-errors" *ngIf="!aliasEdit">
            <mat-error class="error" *ngIf="errorMsg">{{
              'Alias exists. Please use another alias' | translate
            }}</mat-error>

            <mat-error class="error" *ngIf="aliasInputCtrl.invalid">
              {{
                'Must be at least 3 characters long and maximum characters allowed are 20, special charactes and preceding or following spaces are not allowed'
                  | translate
              }}
            </mat-error>
          </div>
        </ng-container>
      </div>
      <div *ngIf="allTargetTelnos.length > 0" class="target-telno" [class.hidden]="caseView || geoDetailsView">
        {{ allTargetTelnos[0] }}
        <span *ngIf="allTargetTelnos.length > 1">
          ({{ allTargetTelnos.length - 1 }} {{ 'more' | translate }})
        </span>
      </div>
      <div *ngIf="targetItemInfo" class="target-telno">
        <span *ngIf="targetItemInfo.age">{{ 'Age' | translate }} {{ targetItemInfo.age }}</span>
        <span *ngIf="targetItemInfo.age && (targetItemInfo.gender || targetItemInfo.country)"> | </span>

        <span *ngIf="targetItemInfo.gender">{{ targetItemInfo.gender | titlecase | translate }}</span>
        <span *ngIf="targetItemInfo.gender && targetItemInfo.country"> | </span>

        <span *ngIf="targetItemInfo.country">{{ targetItemInfo.country | uppercase }}</span>
      </div>
    </div>

    <!-- expired label -->
    <div class="expired-wrp error" *ngIf="targetCreditsChargesEnabled && target.expired">
      <p class="expired-label">{{ 'Expired' | translate }}</p>
    </div>
    <div class="time-expiry error" *ngIf="targetCreditsChargesEnabled && showTargetExpiration && !caseView">
      <p>
        <img src="./assets/static/images/pulse_alert.svg" alt="expiry" />
        {{ expirationDaysLeft }} {{ 'days remaining until expiration' | translate }}
      </p>
    </div>
    <div class="case-target-checkbox la-update-target-checkbox" *ngIf="updateExistingTargetView">
      <mat-checkbox [(ngModel)]="isTargetChecked" (change)="addToCheckedTargets($event)"></mat-checkbox>
    </div>
  </div>

  <mat-menu #more="matMenu">
    <button mat-menu-item *ngIf="!caseView && !(targetCreditsChargesEnabled && target.expired)" (click)="editTarget()">
      <span>{{ 'Edit' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="!isCurrentTarget && enableDeleteTarget" (click)="deleteTarget()">
      <span>{{ 'Delete' | translate }}</span>
    </button>
    <button *ngIf="caseView" mat-menu-item (click)="removeFromCase()">
      <span>{{ 'Remove from case' | translate }}</span>
    </button>
  </mat-menu>

  <mat-menu #cases="matMenu">
    <span *ngIf="targetCases && targetCases.length > 0">
      <button mat-menu-item *ngFor="let case of targetCases" (click)="goToCaseDashboard(case)">
        <span *ngIf="case">{{ case.caseName }}</span>
      </button>
    </span>
  </mat-menu>
</div>
