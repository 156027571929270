import { Injectable } from '@angular/core';
import { DayEnum, GeoJsonFeatureCollection } from '@trg-commons/data-models-ts';
import { PredictedLocationDto } from '@trg-commons/gio-data-models-ts';
import { DataSource } from 'datalayer/models/platform-models';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IconMarker } from 'src/app/modules/mapV2/models/map.models';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PredictedLocationsTimeFilters } from 'src/app/shared/models/predicted-locations-time-filters.interface';
import { CallLogsApiService } from 'src/app/shared/modules/call-logs-shared/services/call-logs-api.service';
import { enumToArray } from 'src/app/shared/util/helper';
import { ProfilerIconMarker } from '../../../shared/models/profiler-icon-marker';
import { MarkerCollectionOptions } from '../models/marker-collection-options.enum';
import { MarkerIcon } from '../models/marker-icons.enum';

@Injectable({
  providedIn: 'root'
})
export class ProfilerProbabilityService {
  probabilities$ = new BehaviorSubject<{ type: MarkerCollectionOptions; markers: IconMarker[] }>({
    type: null,
    markers: []
  });

  constructor(private callLogsApiService: CallLogsApiService, private analyticsService: AnalyticsService) {}

  setTimelineProbabilities(
    probabilities: Partial<{ latitude: number; longitude: number; confidence: number }>[]
  ): void {
    if (!probabilities?.length) {
      return;
    }

    const markers: IconMarker[] = probabilities.map(item => {
      const confidence = Math.round(item.confidence * 100);

      return new IconMarker({
        id: `probability${item.latitude}+${item.longitude}+${confidence}`,
        lat: item.latitude,
        lng: item.longitude,
        popupHTML: `${confidence}%`,
        isPopupWindowOpen: true,
        iconUrl: MarkerIcon.Default
      });
    });

    this.probabilities$.next({ type: MarkerCollectionOptions.TIMELINECDRPROBABILITIES, markers: markers });
  }

  loadCallLogPredictedLocations(telnos: string[], timeFilters?: PredictedLocationsTimeFilters) {
    const time = timeFilters || {
      days: enumToArray(DayEnum),
      hours: {
        from: 0,
        to: 23
      }
    };

    const request = new PredictedLocationDto({
      msisdns: telnos,
      days: time.days as DayEnum[],
      hours: time.hours
    });

    return this.callLogsApiService.createPredictedLocationsRequest(request).pipe(
      tap(result => {
        const locations = result?.body?.targetLocations;

        if (!Array.isArray(locations)) {
          this.probabilities$.next({ type: MarkerCollectionOptions.CDRPROBABILITIES, markers: [] });
          return;
        }

        const markers = locations.map(location => {
          const geosjon = location.geojson as GeoJsonFeatureCollection;
          const [lng, lat] = geosjon.features[0].geometry.coordinates;

          const confidence = Math.round(location.confidence * 100);

          return new ProfilerIconMarker({
            id: `probability${lat}+${lng}+${confidence}`,
            lat,
            lng,
            popupHTML: `${confidence}%`,
            isPopupWindowOpen: true,
            iconUrl: MarkerIcon.Default,
            source: DataSource.CallLog
          });
        });
        this.probabilities$.next({ type: MarkerCollectionOptions.CDRPROBABILITIES, markers: markers });
      })
    );
  }

  loadGeolocationPredictedLocations(telnos: string[]) {
    const observables = telnos.map(telno => {
      return this.analyticsService.getHomeAndWorkLocationProbability(telno).pipe(
        tap(data => {
          if (data.home?.length) {
            const homeMarkers = data.home.map(item => {
              const confidence = Math.round(item.confidence * 100);

              return new ProfilerIconMarker({
                id: `home-address${item.latitude}+${item.latitude}`,
                lat: item.latitude,
                lng: item.longitude,
                popupHTML: `${confidence}%`,
                isPopupWindowOpen: true,
                source: DataSource.GeoLocation,
                iconUrl: MarkerIcon.Home
              });
            });

            this.probabilities$.next({ type: MarkerCollectionOptions.GEOLOCATIONPROBABILITIES, markers: homeMarkers });
          }

          if (data.work?.length) {
            const workMarkers = data.work.map(item => {
              const confidence = Math.round(item.confidence * 100);

              return new ProfilerIconMarker({
                id: `work-probability${item.latitude}+${item.longitude}+${confidence}`,
                lat: item.latitude,
                lng: item.longitude,
                popupHTML: `${confidence}%`,
                isPopupWindowOpen: true,
                source: DataSource.GeoLocation,
                iconUrl: MarkerIcon.Work
              });
            });
            this.probabilities$.next({ type: MarkerCollectionOptions.GEOLOCATIONPROBABILITIES, markers: workMarkers });
          }
        })
      );
    });

    return forkJoin(observables);
  }
}
