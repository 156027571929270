import { Place as PlatformPlace, EntityRelationType, Media } from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';
import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';

export class Place extends PlatformPlace implements BaseTargetEntity {
    targetId: string;
    platform: Platforms;
    profileId?: string;
    relationType?: EntityRelationType;
    image?: Partial<Media>;
    imageUrl?: string;

    constructor(model?: Partial<Place>) {
        super();

        if (model) {
            Object.assign(this, model);
        }
    }
}
