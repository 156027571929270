import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LocationQueryService } from 'src/app/services/query/location-query.service';

@Component({
  selector: 'app-aoi-query-table',
  templateUrl: './aoi-query-table.component.html',
  styleUrls: ['./aoi-query-table.component.scss']
})
export class AoiQueryTableComponent extends BaseComponent implements OnInit {
  displayedColumns: string[] = ['mac', 'imsi', 'mno', 'date'];
  dataSource = new MatTableDataSource([]);
  loader: boolean;
  selectedRow;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() emittedLocationQuery = new EventEmitter<any>();

  constructor(
    public snackBar: MatSnackBar,
    protected locationQueryService: LocationQueryService) {
    super();
  }

  ngOnInit() {
    this.loader = false;
    const getLocationQueryData = this.locationQueryService.locationQueryData.subscribe(
      (entries: []) => {
        if (!entries.length) { return; }
        this.dataSource = new MatTableDataSource(entries);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );

    const getLocationQueryLoader = this.locationQueryService.locationQueryLoader.subscribe(
      (loader) => {
        this.loader = loader;
      }
    );

    const getSelectedRow = this.locationQueryService.selectedRow.subscribe(
      (entry) => {
        this.selectedRow = entry;
      }
    );

    this.subscriptions.push(getLocationQueryData, getLocationQueryLoader, getSelectedRow);
  }

  showLocation(entry) {
    this.emittedLocationQuery.emit(entry);
    this.locationQueryService.selectedRow.next(entry);
  }

}
