<div [formGroup]="form">
  <div class="group" *ngFor="let group of transformedData; let groupIndex = index">
    <div class="group-ctrl flex-vert-center" formArrayName="groups">
      <mat-checkbox [formControlName]="groupIndex" (change)="onGroupCheck($event, group)">
        <ng-container
          [ngTemplateOutlet]="groupTpl ? groupTpl : defaultGroupTpl"
          [ngTemplateOutletContext]="{ $implicit: group }"
        ></ng-container>
      </mat-checkbox>
      <mat-icon
        class="cursor-pointer"
        *ngIf="!group.fakeChildren"
        (click)="group.showChildren = !group.showChildren"
      >
        {{ group.showChildren ? 'arrow_drop_up' : 'arrow_drop_down' }}
      </mat-icon>
    </div>
    <div class="group-children-outer" [class.hidden]="!group.showChildren">
      <div
        class="group-children-ctrls"
        [class.hidden]="group.fakeChildren"
        *ngFor="let item of group.children; let i = index"
        [formArrayName]="'list'"
      >
        <mat-checkbox [formControlName]="item.index">
          <ng-container
            [ngTemplateOutlet]="childrenTpl ? childrenTpl : defaultChildrenTpl"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-container>
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultChildrenTpl let-item>
  {{ item.viewValue | json }}
</ng-template>

<ng-template #defaultGroupTpl let-group>
  {{ group.group | translate }}
</ng-template>
