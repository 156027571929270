import { Entity } from 'datalayer/models/platform-models';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BaseApiService } from 'src/app/modules/data-layer/services/base/base-api.service';
import { BaseDTO } from 'src/app/modules/data-layer/services/base/base-dto';
import { CacheService } from 'src/app/modules/data-layer/services/base/cache.service';
import { DataChangeNotification, DataChangeType } from 'src/app/modules/data-layer/services/base/data-change-notification';
import { EmptyCacheService } from 'src/app/modules/data-layer/services/base/empty-cache.service';
import { ModelStore } from 'src/app/modules/data-layer/services/base/model-store';
import { RequestOptions } from 'src/app/modules/data-layer/services/base/request-options.interface';

export abstract class BaseService<
  TYPE_MODEL extends Entity,
  TYPE_DTO extends BaseDTO<TYPE_MODEL, TYPE_DTO>,
  TYPE_API extends BaseApiService<TYPE_MODEL, TYPE_DTO, TYPE_CHANGE_TYPE>,
  TYPE_CACHE extends CacheService<TYPE_MODEL> = CacheService<TYPE_MODEL>,
  TYPE_CHANGE_TYPE = DataChangeType> {
  protected cache: TYPE_CACHE;
  protected api: TYPE_API;


  protected externalDataChangeNotification = new ReplaySubject<DataChangeNotification<TYPE_MODEL, TYPE_CHANGE_TYPE>>(1);

  constructor(api: TYPE_API, cache?: TYPE_CACHE) {
    if (undefined !== cache) {
      this.cache = cache;
    } else {
      this.cache = <TYPE_CACHE>new EmptyCacheService<TYPE_MODEL>();
    }
    this.api = api;

    // subscripe to api changes, and emit on observer property
    this.api.resourceChanged.subscribe(res => {
      this.onResourceChanged(res);
    });
  }

  public create(model: TYPE_MODEL): Observable<TYPE_MODEL> {
    return this.api.create(model).pipe(tap(result => {
      this.cache.put([result]);
    }));
  }

  public get(guid: string): Observable<TYPE_MODEL> {
    const model = this.cache.get(guid);
    if (null === model) {
      // get from api
      return this.api.get(guid).pipe(tap(newModel => {
        this.cache.put([newModel]);
      }));
    }

    // was found in cache
    return of(model);
  }

  public getAll(options?: RequestOptions): Observable<ModelStore<TYPE_MODEL>> {
    const results = this.cache.getAll(options);
    if (results === undefined || results === null ||
      Object.keys(results).length === 0 || !this.cache.getStorageComplete()
    ) {
      // cache was never populated with any entry or is cache complete flag is set to
      // false or request has filtering options, fetch all from api
      return this.api.getAll(options).pipe(
        switchMap((models: TYPE_MODEL[]) => this.cache.put(models)),
        map((models: TYPE_MODEL[]) => {
          if (options === undefined) {
            this.cache.setStorageComplete(true);
          }

          const store: ModelStore<TYPE_MODEL> = {};
          models.forEach((model: TYPE_MODEL) => {
            store[model.id] = model;
          });

          return store;
        }));
    }
    // was found in cache
    return of(results);
  }

  public delete(model: TYPE_MODEL): Observable<ModelStore<TYPE_MODEL>> {
    return this.api.delete(model).pipe(map(() => {
      this.cache.delete([model]);
      const store: ModelStore<TYPE_MODEL> = {};
      store[model.id] = model;
      return store;
    }));
  }

  public update(model: TYPE_MODEL): Observable<ModelStore<TYPE_MODEL>> {
    return this.api.update(model).pipe(map(() => {
      this.cache.put([model]);
      const store: ModelStore<TYPE_MODEL> = {};
      store[model.id] = model;
      return store;
    }));
  }

  public resourceChanged(): Observable<DataChangeNotification<TYPE_MODEL, TYPE_CHANGE_TYPE>> {
    return this.externalDataChangeNotification;
  }

  protected onResourceChanged(res: DataChangeNotification<TYPE_MODEL, TYPE_CHANGE_TYPE>): void {
    const models: TYPE_MODEL[] = [];
    const type: DataChangeType = res.type as unknown as DataChangeType
    switch (type) {
      case DataChangeType.Create:
      case DataChangeType.Update: {
        this.cache.put(res.models);
        res.models.forEach(model => {
          models.push(this.cache.get(model.id) || model);
        });
        break;
      }
      case DataChangeType.Delete: {
        res.models.forEach(model => {
          models.push(this.cache.get(model.id) || model);
        });
        this.cache.delete(res.models);
        break;
      }
      default: {
        console.log('no action for resource changed action');
        break;
      }
    }
    this.externalDataChangeNotification.next({ type: res.type, models: models, message: res.message });
  }
}
