import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QueryService } from 'src/app/services/query/query.service';
import { QueryType } from 'src/app/shared/models/query-item.model';
import { Circle } from 'src/app/modules/mapV2/models/map.models';

@Component({
  selector: 'app-advanced-queries',
  templateUrl: './advanced-queries.component.html',
  styleUrls: ['./advanced-queries.component.scss']
})
export class AdvancedQueriesComponent implements OnInit, OnChanges {
  advanced = new FormControl();
  @Output() selectedCountry = new EventEmitter<string>();
  @Output() showSearchTool = new EventEmitter<boolean>();
  @Output() showDrawingTools = new EventEmitter<boolean>();
  @Output() clearQueryMap = new EventEmitter<boolean>();
  @Output() drawAreaOfInterestFromComponent = new EventEmitter<Circle>();
  @Input() areaOfInterestDrawn: Circle;
  areaOfInterest: Circle;

  constructor(
    private queryService: QueryService,
  ) { }

  ngOnInit() {
    // by default query type is simple
    this.queryService.isAdvancedQuery.next(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.areaOfInterest = changes.areaOfInterestDrawn.currentValue;
  }

  toggleChange(event) {
    if (event.checked) {
      this.queryService.advancedQueryType.next(QueryType.SCHEDULED);
    }
    this.queryService.isAdvancedQuery.next(event.checked);
  }

  advancedType(index) {
    this.showSearchTool.emit(false);
    this.showDrawingTools.emit(false);
    let advancedType = QueryType.SCHEDULED;
    if (index === 1) {
      advancedType = QueryType.LOGON;
    } else if (index === 2) {
      advancedType = QueryType.FENCED_COUNTRY;
    } else if (index === 3) {
      advancedType = QueryType.PROXIMITY;
    }
    this.queryService.advancedQueryType.next(advancedType);
  }

  onSelectedCountry(country: string) {
    this.selectedCountry.emit(country);
  }

  toggleSearchTool(flag: boolean) {
    this.showSearchTool.emit(flag);
  }

  toggleDrawingTools(flag: boolean) {
    this.showDrawingTools.emit(flag);
  }

  onClearQueryMap(flag: boolean) {
    this.clearQueryMap.emit(flag);
  }

  onDrawnAreaOfInterestFromComponent(circle: Circle) {
    this.drawAreaOfInterestFromComponent.emit(circle);
  }
}
