import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ButtonSize } from "./button-size.enum";
import { faQuestion, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'intellectus-icon-text-button',
    templateUrl: './icon-text-button.component.html',
    styleUrls: ['./icon-text-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconTextButtonComponent {
    @Input() buttonText = '';
    @Input() buttonIcon = faQuestion;
    @Input() isLoading = false;
    @Input() buttonSize: ButtonSize = ButtonSize.Default;
    @Input() isDisabled = false;
    @Input() rotate = false;
    @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

    protected readonly faSpinner = faSpinner;

    onClick(): void {
        if (!this.isDisabled && !this.isLoading) {
            this.buttonClick.emit();
        }
    }
}
