export enum NotificationType {
  SYSTEM = 'system',
  RADICAL_MONITORING = 'radicalmonitoring:notifyOnDetect',
  MARKETING_BANNER = 'marketing-banner',
  TARGET_CASE_EXPIRATION = 'expires:entity',
  SOMEDUS_STATUS = 'somedus:status',
  AVATAR_EXPIRED = 'avatar:expired',
}

export enum ActionType {
  push = 'push',
  pop = 'pop',
  clear = 'clear',
  markAsRead = 'markAsRead',
  clearRadicalMonitoring = 'clearRadicalMonitoringSource',
  system = 'system',
  remove = 'remove',
}