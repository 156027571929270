import { BaseComponent } from 'src/app/shared/classes/base.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-base-map',
  templateUrl: './base-map.component.html',
  styleUrls: ['./base-map.component.scss']
})
export class BaseMapComponent extends BaseComponent implements OnInit {
  @ViewChild('mapContainer', { static: true }) gmap: ElementRef;

  mapOptions: google.maps.MapOptions = {
    center: new google.maps.LatLng(37.774546, -122.433523),
    zoom: 12,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      // style: google.maps.Map.ZoomControlStyle.SMALL, # property removed
      position: google.maps.ControlPosition.LEFT_TOP
    },
  };

  map: google.maps.Map;

  constructor() {
    super();
  }

  ngOnInit() { }

  protected setupMap() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

    navigator.geolocation.getCurrentPosition(
      this.showPosition.bind(this), () => { });
  }


  private showPosition(position) {
    this.map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
    return;
  }
}
