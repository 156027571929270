
export const DEFAULT_COUNTRIES = [
  {
    value: 'mexico',
    viewValue: 'Mexico',
    lat: 19.451054,
    lon: -99.125519
  },
  {
    value: 'argentina',
    viewValue: 'Argentina',
    lat: -38.4193,
    lon: -63.5989
  },
  {
    value: 'panama',
    viewValue: 'Panama',
    lat: 8.3788373,
    lon: -81.2266042
  },
  {
    value: 'none',
    viewValue: 'None'
  }
];
