import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { Notification } from 'src/app/components/notifications-list/notification.model';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';

@Component({
  selector: 'app-marketing-banner',
  templateUrl: './marketing-banner.component.html',
  styleUrls: ['./marketing-banner.component.scss']
})
export class MarketingBannerComponent extends BaseComponent implements OnInit {
  marketingBannerImage = false;
  notification: Notification | undefined = undefined;
  constructor(
    public dialogRef: MatDialogRef<MarketingBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Notification,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService
  ) {
    super();
    this.dialogRef.afterClosed().subscribe(() => {
      sessionStorage.setItem(`${this.localStorageService.getCurrentUser().identity}_marketingBannerClicked`, 'true');
    });
  }

  ngOnInit() {
    this.subscriptions.push(this.notificationService.get(this.data.id).subscribe(notification => {
      this.notification = notification;
      if (notification && notification.content.marketingBanner.text.indexOf('img') > -1) {
        this.marketingBannerImage = true;
      }
    }));
  }

  storeInteraction() {
    this.dialogRef.close();
  }

}
