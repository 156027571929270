import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Action } from '../classes/action.class';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  private action = new Subject<Action>();

  constructor() { }

  /**
   *
   * @param actionKey string
   */
  onAction(actionKey?: string): Observable<Action> {
    if (actionKey) {
      return this.action.asObservable()
        .pipe(
          filter(action => action.key === actionKey)
        );
    }
    return this.action.asObservable();
  }

  publishAction(action: Action) {
    this.action.next(action);
  }
}
