import { Group as PlatformGroup, EntityRelationType, Media } from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';

export class Group extends PlatformGroup implements BaseTargetEntity {
  targetId: string;
  profileId?: string;
  relationType?: EntityRelationType;
  image?: Partial<Media>;
  imageUrl?: string;

    constructor(model?: Partial<Group>) {
        super();

        if (model) {
            Object.assign(this, model);
        }
    }
}
