import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { FormArray, FormBuilder, NgControl } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { groupBy } from 'lodash-es';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface ChildrenCheckbox {
  viewValue: string;
  group?: string;
  index?: number;
}

export interface GroupWithCheckboxes {
  group: string;
  children: ChildrenCheckbox[];
  showChildren?: boolean;
  fakeChildren?: boolean;
  disabled?: boolean;
}

interface CheckedCollection {
  [group: string]: ChildrenCheckbox[];
}

@Component({
  selector: 'app-grouped-checkboxes',
  templateUrl: './grouped-checkboxes.component.html',
  styleUrls: ['./grouped-checkboxes.component.scss']
})
export class GroupedCheckboxesComponent implements OnInit, AfterViewInit {
  constructor(private fb: FormBuilder) {}

  @Input() data: Array<GroupWithCheckboxes>;
  @Input() childrenTpl: TemplateRef<any>;
  @Input() groupTpl: TemplateRef<any>;
  @Output() change = new EventEmitter<CheckedCollection>();
  @ViewChildren(NgControl) controls: QueryList<NgControl>;

  public transformedData: Array<GroupWithCheckboxes>;
  public form = this.fb.group({
    list: this.fb.array([]),
    groups: this.fb.array([])
  });
  private value: CheckedCollection = {};

  get list() {
    return this.form.get('list') as FormArray;
  }

  get groups() {
    return this.form.get('groups') as FormArray;
  }

  ngOnInit() {
    this.prepareData();
  }

  ngAfterViewInit() {
    this.list.valueChanges
      .pipe(
        map(values => groupBy(this.transformBooleansToValues(values), 'group')),
        tap(values => {
          this.checkIfGroupWasChecked(values);
        })
      )
      .subscribe(data => {
        this.value = data;
        this.change.emit(data);
      });
  }

  public getValue() {
    return this.value;
  }

  public resetValues() {
    this.controls.forEach(({ control }) => control.patchValue(false));
  }

  private prepareData() {
    let index = 0;
    this.transformedData = this.data.map(group => {
      this.groups.push(this.fb.control(false));

      const children = group.children?.length ? group.children : [{ viewValue: group.group } as ChildrenCheckbox];

      return {
        ...group,
        fakeChildren: !group.children?.length,
        showChildren: true,
        children: children.map(children => {
          this.list.push(this.fb.control(false));

          return {
            ...children,
            index: index++,
            group: group.group
          };
        })
      };
    });
  }

  private transformBooleansToValues(values: Array<boolean>): ChildrenCheckbox[] {
    return values.map((v, i) => (v ? this.getChildByIndex(i) : null)).filter(v => v);
  }

  private getChildByIndex(index: number): ChildrenCheckbox {
    let foundChild = null;

    this.transformedData.forEach(group => {
      if (!group.children) {
        return;
      }
      group.children.forEach(child => {
        if (child.index === index) {
          foundChild = child;
        }
      });
    });

    return foundChild;
  }

  onGroupCheck({ checked }: MatCheckboxChange, group: GroupWithCheckboxes): void {
    group.children.forEach(({ index }) => {
      this.list.at(index).patchValue(checked);
    });
  }

  private checkIfGroupWasChecked(values: CheckedCollection): void {
    const keys = Object.keys(values);

    if (!keys.length) {
      this.groups.controls.forEach(c => c.patchValue(false));
      return;
    }

    keys.forEach(key => {
      const boundGroup = this.transformedData.find(group => group.group === key);
      const allGroupChecked = boundGroup.children.length === values[key].length;

      this.groups.at(this.transformedData.indexOf(boundGroup)).patchValue(allGroupChecked);
    });
  }
}
