import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { BillingActions, BillingActionType, BillingPlan } from 'src/app/shared/models/billing-action.model';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';

@Component({
  selector: 'app-custom-toolbox-marker',
  templateUrl: './custom-toolbox-marker.component.html',
  styleUrls: ['./custom-toolbox-marker.component.scss'],
})
export class CustomToolboxMarkerComponent extends BaseComponent implements OnInit {
  imagesPath = 'assets/static/images/';
  defaultMarkerSrc = '';
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };
  @Input() showGeoMarkerToolbox: boolean;
  @Input() geofencingIsEnabled: boolean;
  @Input() cachedAntennas: boolean;
  @Input() followIsEnabled: boolean;
  @Input() pendingSchedulerAction: boolean = false;

  @Output() toggleGeoMarkerToolboxClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleGeofencingTaskClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() showBTSClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleScheduleTaskClick: EventEmitter<void> = new EventEmitter<void>();

  geofencingTitle = 'Create a Geofencing Task (1 week / 15 minutes)';
  schedulerTitle = 'Start scheduler';
  cellsTitle: string;

  constructor(
    private applicationStateService: ApplicationStateService,
    private appConfigService: AppConfigService,
    private billingService: BillingService,
    private translationService:TranslationService
  ) {
    super();
    this.defaultMarkerSrc = this.applicationStateService.getDefaultMarker();
  }

  ngOnInit() {
    if (this.appConfigService.getConfigVariable('forceLowFrequencySchedules')) {
      this.geofencingTitle = 'Create a Geofencing Task (6 hours / 15 minutes)';
    }
    this.subscribeToBillingPlan();
  }

  toggleGeoMarkerToolbox() {
    this.toggleGeoMarkerToolboxClick.emit(void 0);
  }

  toggleGeofencingTask() {
    this.toggleGeofencingTaskClick.emit(void 0);
  }

  showBTS() {
    this.showBTSClick.emit(void 0);
  }

  toggleScheduleTask() {
    if (!this.pendingSchedulerAction) {
      this.toggleScheduleTaskClick.emit(void 0);
    }
  }

  private subscribeToBillingPlan() {
    this.subscriptions.push(
      this.billingService
        .getBillingPlan()
        .asObservable()
        .pipe(filter((billingPlan) => !!billingPlan && BillingActions.NEIGHBOUR_CELL_TOWERS in billingPlan))
        .subscribe((billingPlan: BillingPlan<BillingActions, BillingActionType>) => {
          this.cellsTitle =
            String(billingPlan[BillingActions.NEIGHBOUR_CELL_TOWERS].cost) === 'unlimited'
              ? this.translationService.translate('Get the Neighbour Cells')
              : `${this.translationService.translate('Get the Neighbour Cells')} (${billingPlan[BillingActions.NEIGHBOUR_CELL_TOWERS].cost} ${this.translationService.translate('credit')})`;
        })
    );
  }
}
