import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActiveToast } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { OsintGroupNotificationsService } from 'src/app/shared/components/osint-group-notifications/osint-group-notifications.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { RecommendationImportToastrComponent } from 'src/app/shared/modules/recommendations/recommendation-import-toastr/recommendation-import-toastr.component';
import { ServerPyWsService } from 'src/app/services/websocket/server-py-websocket.service';
import { ImportRecommendationRequest, ImportRecommendationResponse, RecommendationRequest, RecommendationResponse } from './models/socket-responses.interface';

@Injectable({
  providedIn: 'root'
})
export class RecommendationImportService {
  constructor(
    private serverPyWsService: ServerPyWsService,
    private osintGroupNotificationsService: OsintGroupNotificationsService,
  ) {}

  doImportAndShowToastr(
    telno: string,
    dates: string[]
  ): Observable<[ImportRecommendationResponse, ActiveToast<RecommendationImportToastrComponent>]> {
    const comp = this.osintGroupNotificationsService.showCustomNotification(RecommendationImportToastrComponent, {
      telno
    });
    return this.importRequest(telno, dates).pipe(map(resp => [transformSnakeToCamel(resp), comp]));
  }

  checkOnRecommendations(msisdn: string): Observable<RecommendationResponse> {
    return this.serverPyWsService
      .call<RecommendationRequest, RecommendationResponse>({
        subject: MessageSubject.Recommendations,
        body: {
          msisdn
        }
      })
      .pipe(map(resp => transformSnakeToCamel(resp)));
  }

  private importRequest(msisdn: string, dates: string[]): Observable<ImportRecommendationResponse> {
    return this.serverPyWsService.call<ImportRecommendationRequest, ImportRecommendationResponse>({
      subject: MessageSubject.importRecommendations,
      body: {
        msisdn,
        dates
      }
    });
  }
}
