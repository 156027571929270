import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CQRSBaseEvent } from '@trg-commons/gio-data-models-ts';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { DefaultTabs, IntelResults } from '../models/intel-results-view.model';
import { SearchFilters, SearchIntelModel } from '../models/search-intel.model';
import { IntelResultsStore } from './intel-results.store';
import { SearchIntelService } from './search-intel.service';

@Injectable({
  providedIn: 'root'
})
export class IntelResultsInvestigationService extends BaseService {
  public listeners: Map<string, Subject<any>> = new Map();
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private wsManager: WebsocketManagerService,
    private intelResultsStore: IntelResultsStore,
    private searchIntelService: SearchIntelService,
    private translationService: TranslationService
  ) {
    super(router, snackBar);
    this.initializeListeners();
  }

  getSmartfiltersByPersonIds(personKeys: string[][]): Observable<any> {
    const bodyRequest = {
      mergedPersonsKeys: personKeys
    };
    return this.httpClient.post(`${this.fastAPIurl}/indonesia/smart-filters`, bodyRequest).pipe(map(result => result));
  }

  createSearchIntel(searchArgs, queryFilters?: SearchFilters): Observable<any> {
    const bodyRequest = {
      query_args: searchArgs
    };

    if (queryFilters && Object.keys(queryFilters).length) {
      bodyRequest['query_filters'] = queryFilters;
    }
    this.intelResultsStore.setUpdating(true);
    return this.httpClient.post(`${this.fastAPIurl}/indonesia/intel-search`, bodyRequest).pipe(
      map((data: { result: SearchIntelModel }) => {
        const intelSearch = transformSnakeToCamel(data.result);
        intelSearch.queryArgsDisplay = [];
        intelSearch.queryArgs.forEach(queryArgs => {
          if (queryArgs && typeof queryArgs['argValue'] === 'object') {
            const values = Object.values(queryArgs['argValue']);
            intelSearch.queryArgsDisplay.push(values.join(` <b>${this.translationService.translate('and')}</b> `));
          } else {
            intelSearch.queryArgsDisplay.push(queryArgs.argValue);
          }
        });
        return intelSearch;
      }),
      tap((searchResult: SearchIntelModel) => {
        this.searchIntelService.currentSearchIntel.next({
          search: searchResult,
          noResultSearch: false
        });
      })
    );
  }

  createListenerForSearchResult(searchResult: SearchIntelModel): void {
    this.createListener(searchResult.id)
      .pipe(tap(event => this.listenSearchResultsNotifications(event)))
      .subscribe();
  }

  private listenSearchResultsNotifications(event: CQRSBaseEvent<any>) {
    let intelResult: IntelResults = {};
    const body = transformSnakeToCamel(event.body.body);
    switch (event.body.subject) {
      case MessageSubject.searchingTrgDataLake:
        if (Array.isArray(body) && this.intelResultsStore.head(body).status === 'complete') {
          // TODO:Refactor when BE sends proper message
          this.intelResultsStore.setUpdating(false);
          this.checkMultipleSearchResults();
          const listener = this.listeners.get(event.correlationId);
          this.waitForOutOfOrderMessagesAndCleanup(event.correlationId, listener);
        }
        if (body.persons) {
          intelResult.persons = body.persons;
        }
        if (body.filters) {
          intelResult.filters = body.filters;
          intelResult.filters.id = event.correlationId;
        }
        if (body.queryArgs) {
          intelResult.queryArgs = body.queryArgs;
        }
        intelResult.searchId = event.correlationId;
        this.intelResultsStore.updateBySearchId(intelResult, intelResult.searchId);
        break;
      default:
        break;
    }
  }

  private createListener(correlationId: string): Observable<CQRSBaseEvent<any>> {
    const subject = new Subject<CQRSBaseEvent<any>>();
    this.listeners.set(correlationId, subject);
    return subject.asObservable();
  }

  private initializeListeners() {
    this.wsManager.getServerTsConnection().subscribe(ws => {
      ws.on('message', (data: CQRSBaseEvent<any>) => {
        const listener = this.listeners.get(data.correlationId);

        if (!listener) {
          return;
        }

        listener.next(data);
      });
    });
  }

  private waitForOutOfOrderMessagesAndCleanup(corId: string, listener: Subject<any>) {
    this.listeners.delete(corId);
    listener.complete();
  }

  private checkMultipleSearchResults() {
    const intelResults = this.intelResultsStore.getValue();
    if (intelResults.length > 1) {
      const personKeys = Array.prototype.concat.apply(
        [],
        intelResults.map(i => i.persons.map(p => p.mergedPersonKeys)).map(i => Array.prototype.concat.apply(i))
      );
      let intelResult: IntelResults = {};
      this.getSmartfiltersByPersonIds(personKeys).subscribe(result => {
        intelResult.filters = result;
        intelResult.filters.id = DefaultTabs.ALL;
        intelResult.searchId = DefaultTabs.ALL;
        const existingResults = this.intelResultsStore.getValue().filter(i => i.searchId !== DefaultTabs.ALL);
        const persons = Array.prototype.concat.apply(
          [],
          existingResults.map(i => Array.prototype.concat.apply([], i.persons))
        );
        intelResult.persons = persons;

        this.searchIntelService.currentSearchIntel.next({
          search: {
            id: DefaultTabs.ALL,
            queryArgs: [
              {
                argValue: 'All',
                argType: 'All'
              }
            ],
            queryArgsDisplay: ['All'],
            createdAt: new Date().toString()
          },
          noResultSearch: false
        });
        this.intelResultsStore.updateBySearchId(intelResult, intelResult.searchId);
      });
    }
  }
}
