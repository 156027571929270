import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BaseCacheService } from './base-cache.service';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class FinishedHttpCacheService extends BaseCacheService<HttpResponse<unknown>> {
  constructor(configService: AppConfigService) {
    super();

    this.initialize(configService.getConfigVariable('httpResponseCacheLifetimeSeconds'));
  }
}
