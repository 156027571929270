import { DataSource, EntityType, Media } from 'datalayer/models/platform-models';
import { Education } from 'datalayer/models/social/education';
import { BaseDTO } from 'datalayer/services/base';

export class EducationDTO extends BaseDTO<Education, EducationDTO> {

  private id: any;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private source_entity?: {
    id: string,
    created_at: Date,
    parent_id: string;
  };
  private target_id?: string;
  private name: string;
  private degree?: string;
  private start_date?: string;
  private end_date?: string;
  private url?: string;
  private logo?: string;
  private image?: Partial<Media>;

  constructor(model?: Partial<EducationDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Education): EducationDTO {
    this.id = model.id;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.target_id = model.targetId;
    this.name = model.name;
    this.degree = model.degree;
    this.start_date = model.startDate;
    this.end_date = model.endDate;
    this.url = model.url;
    this.logo = model.logo;
    this.image = model.image;
    return this;
  }

  toModel(): Education {
    const education: Education = new Education({
      id: this.id,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      name: this.name,
      degree: this.degree,
      startDate: this.start_date,
      endDate: this.end_date,
      url: this.url,
      logo: this.logo,
      image: this.image,
    });

    return education;
  }

}
