import { Component, OnInit } from '@angular/core';
import { AudioService } from 'src/app/services/audio/audio.service';
import { Notification } from 'src/app/components/notifications-list/notification.model';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { MatDialog } from '@angular/material/dialog';
import { TasksComponent } from 'src/app/components/tasks/tasks.component';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { NotificationType } from 'src/app/services/notification/types';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
  isMobileResolution: boolean;
  notificationIds = [];
  skin;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  theme: string;
  notificationsCount = 0;
  hasRegularNotifications = false;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories
  };

  constructor(
    public dialog: MatDialog,
    public notificationService: NotificationService,
    private audioService: AudioService,
    private applicationStateService: ApplicationStateService,
    private appConfigService: AppConfigService
  ) {
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.skin = this.applicationStateService.getSkin();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    this.isGeolocTheme = this.theme === 'GEOLOC';
    this.isWhiteTheme = this.theme === 'WHITE';

    this.notificationService.messages$.subscribe((notifications: Notification[]) => {
      const excludeFromRegular: string[] = [NotificationType.SYSTEM, NotificationType.MARKETING_BANNER];
      const systemNotifications = notifications.filter(n => n.notificationType === NotificationType.SYSTEM);
      const regularNotifications = notifications.filter(
        n => !n.isRead && !excludeFromRegular.includes(n.notificationType)
      );

      const totalNotificationsCount = regularNotifications.length + systemNotifications.length;

      this.hasRegularNotifications = regularNotifications.length > 0;

      this.notificationIds = regularNotifications.map(n => n.id);

      if (totalNotificationsCount > this.notificationsCount && !this.notificationService.hasNotificationToneAlreadyRang) {
        // play sound only when a new notification is added
        this.audioService.playNotification();
        this.notificationService.hasNotificationToneAlreadyRang = true;
      }
      this.notificationsCount = totalNotificationsCount;
    });
  }

  clearNotifications() {
    if (this.notificationIds.length) {
      this.notificationService.clearNotifications();
    }
  }

  markAllRead() {
    if (this.notificationIds.length) {
      this.notificationService.markNotificationsAsRead(this.notificationIds);
    }
  }

  openDialog(flag): void {
    this.dialog.open(TasksComponent, {
      width: 'auto',
      height: '85vh',
      data: flag, // canceled task = true OR false
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : ''
    });
  }
}
