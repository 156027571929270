<ul class="day-selector">
  <li *ngFor="let option of options; let idx = index">
    <button class="day-selector-btn"
            type="button"
            (click)="onSelect(idx)"
            [class.active]="isSelected(idx)">
      {{ option.label | translate }}
    </button>
  </li>
</ul>
