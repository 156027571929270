import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreditDetailsService } from '../../services/credit-details.service';
@Component({
  selector: 'app-credit-usage-bar',
  templateUrl: './credit-usage-bar.component.html',
  styleUrls: ['./credit-usage-bar.component.scss'],
})
export class CreditUsageBarComponent implements OnInit {
  public barElem: ElementRef<HTMLDivElement>;
  @ViewChild('bar') set content(content: ElementRef<HTMLDivElement>) {
     if(content) {
         this.barElem = content;
     }
  }

  constructor(private creditDetailsService: CreditDetailsService) {}
  private readonly TOOLTIP_X_ADJUSTMENT: number = 13;
  private readonly TOOLTIP_Y_ADJUSTMENT: number = 35;

  public showTooltip$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  public creditUsage$: Observable<{ endValW: string; startValW: string; total: number; title: string }>;

  public onMouseOver(_): void {
    const positionX = `${this.barElem.nativeElement.getBoundingClientRect().left - this.TOOLTIP_X_ADJUSTMENT}px`;
    const positionY = `${this.barElem.nativeElement.getBoundingClientRect().top + this.TOOLTIP_Y_ADJUSTMENT}px`;
    this.creditDetailsService.setCreditBarToolTipPosition(positionX, positionY);
    this.showTooltip$.next(true);
  }

  public onMouseLeave(_): void {
    this.showTooltip$.next(false);
  }

  ngOnInit(): void {
    this.creditUsage$ = this.creditDetailsService.getCreditUsageBar().pipe(map(this.calcProportion));
  }

  private calcProportion(creditUsage: CreditUsageBar): {
    endValW: string;
    startValW: string;
    total: number;
    title: string;
  } {
    return {
      title: creditUsage.title,
      total: creditUsage.totalVal,
      endValW: creditUsage.endVal > 0 ? `${(creditUsage.endVal * 100) / creditUsage.lengthBar}%` : `0%`,
      startValW: creditUsage.startVal > 0 ? `${(creditUsage.startVal * 100) / creditUsage.lengthBar}%` : `0%`,
    };
  }
}

export interface CreditUsageBar{
  title: string;
  endVal: number;
  startVal: number;
  totalVal: number;
  lengthBar: number;
}
