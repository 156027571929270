import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService, IMPORT_DATA_THRESHOLD_PER } from 'src/app/services/analytics/analytics.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseComponent } from 'src/app/base/base.component';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { QueryType } from 'src/app/shared/models/query-item.model';
import { User } from 'src/app/services/user/user.model';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';

@Component({
  selector: 'app-import-data-request-dialog',
  templateUrl: './import-data-request-dialog.component.html',
  styleUrls: ['./import-data-request-dialog.component.scss']
})
export class ImportDataRequestDialogComponent extends BaseComponent implements OnInit {
  dataAvailability = {
    tenantPercentage: 0,
    overallPercentage: 0
  };
  currentCredits: number = 0;
  isProfilerView = false;

  constructor(
    public dialogRef: MatDialogRef<ImportDataRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public target: TargetItem,
    private analyticsService: AnalyticsService,
    private queryService: QueryService,
    private translate: TranslationService,
    private ledgerService: LedgerService,
    private applicationStateService: ApplicationStateService,
    private userBillingService: UserBillingService,
  ) {
    super();
    this.isProfilerView = this.applicationStateService.isProfilerTarget();
  }

  ngOnInit() {
    this.dataAvailability = this.analyticsService.probabilityDataAvailability;
    this.subscriptions.push(
      this.ledgerService
        .getCurrentUserLedgerItem()
        .subscribe((user: User) => (this.currentCredits = this.userBillingService.getUserCurrentBalance(user)))
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  canRequestImport(): boolean {
    return this.dataAvailability.overallPercentage >= IMPORT_DATA_THRESHOLD_PER;
  }

  createSchedule() {
    if (this.currentCredits) {
      if (this.isProfilerView) {

        if (!this.target.telnos || !this.target.telnos.length) {
          this.showMessage(this.translate.translate('An error has occured'));
          console.error('Mising target MSISDNS in order to perform query');
        } else {
          this.queryService.numbersToBeQueried.next([{
              telno: this.target.telnos[0],
              imsi: ''
            }]
          );
        }
      }
      const currentDate = new Date();
      this.queryService.createScheduledQuery({
        startAt: new Date(),
        endAt: new Date(currentDate.setDate(currentDate.getDate() + 14)),
        frequency: 15,
        queryType: QueryType.SCHEDULED
      }).subscribe();
      this.confirmAction(
        this.translate.translate('Schedule has been created')
      );
    } else {
      this.showMessage(this.translate.translate('No credits'));
      return;
    }
  }

  requestImport() {

    let telno: string | null = null;

    if (this.isProfilerView) {
      if (!this.target.telnos || !this.target.telnos.length) {
        this.translate.translate('An error has occured');
        console.error('Mising target MSISDNS in order to perform query');
        return;
      }
      telno = this.target.telnos[0];
    } else {
      if (!this.queryService.onQuerySelection.value.queryArgs.telno) {
        this.translate.translate('An error has occured');
        console.error('Mising query args telno');
        return;
      }
      telno = this.queryService.onQuerySelection.value.queryArgs.telno;
    }

    if (null === telno) {
      this.translate.translate('An error has occured');
      console.error('Should not reach this point, we have no telno to query');
      return;
    }

    this.analyticsService.importDataRequest(
      telno
    ).subscribe(
      () => {
        return;
      },
      () => {
        this.translate.translate('An error has occured');
      }
    );
  }

  confirmAction(message: string, action = this.translate.translate('Close')) {
    this.showMessage(message);
  }

  onImportOrSchedule() {
    this.dialogRef.close();
    if (this.canRequestImport()) {
      this.requestImport();
      this.confirmAction(
        this.translate.translate('Request has been sent to support')
      );
    } else {
      this.createSchedule();
    }
  }
}
