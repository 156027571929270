import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationPopupModel } from 'src/app/modules/search-intel/models/search-intel.model';

@Component({
  selector: 'app-confirm-email-recipient-dialog',
  templateUrl: './confirm-email-recipient-dialog.component.html',
  styleUrls: ['./confirm-email-recipient-dialog.component.scss']
})
export class ConfirmEmailRecipientDialogComponent implements OnInit {
  onConfirm: EventEmitter<any> = new EventEmitter<any>();
  onRemoveEmail: EventEmitter<any> = new EventEmitter<any>();
  constructor(public dialogRef: MatDialogRef<ConfirmEmailRecipientDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: ConfirmationPopupModel) {}

  ngOnInit(): void {}
}
