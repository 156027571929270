<div
  class="body-height body-background-color"
  [class.geoloc-theme]="isGeolocTheme"
  [class.white-theme]="isWhiteTheme"
  [class.investigation-view]="isInvestigationPage"
  [class.intelshield-theme]="isIntelshieldTheme"
>
  <app-navbar
    (emitHeigth)="getHeadHeight($event)"
    *ngIf="showNavbar && !isMobileResolution && !isInvestigationPage"
  ></app-navbar>
  <main [style.top.px]="headerTop" [class.topShift]="topVal">
    <div
      id="appContainer"
      class="containerFixed"
      [style.width]="cssOverwritesService.mainContainerWidth$ | async"
      [style.maxWidth]="cssOverwritesService.mainContainerMaxWidth$ | async"
      [class.containerFlow]="widthVal"
      [class.remove-overflow-style]="removeOverflowStyle"
    >
      <div class="wrapper">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>
  </main>
</div>
<ng-template #questionnaireContainer> <ng-template></ng-template></ng-template>

<intellectus-drawer hidden></intellectus-drawer>