import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OsintGroupNotificationsComponent } from 'src/app/shared/components/osint-group-notifications/osint-group-notifications.component';
import { OsintNotificationsStore } from 'src/app/shared/components/osint-group-notifications/osint-notifications.store';
import { OsintSearchNotificationResponse } from 'datalayer/models/background-jobs/osint-notifications';
import { ComponentType } from '@angular/cdk/overlay';
import { GroupNotification } from '../../models/group-notifications.model';
import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OsintGroupNotificationsService extends GroupNotification {
  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private toastStore: OsintNotificationsStore
  ) {
    super();
    this.authService.isAuthenticated.subscribe(isLogged => {
      if (!isLogged) {
        this.cleanAllToasts();
      }
    });
  }

  /**
   * Will add new Toast only when id wasn't added before,
   * otherwise will just update existed Toast with the new notifications
   * @param message OsintSearchNotificationResponse
   */
  processNotification(message: OsintSearchNotificationResponse) {
    if (!this.toastStore.hasNotificationWithId(message.target_id)) {
      this.addToast(message);
    }

    // clean an old notifications for group if OSINT was ran again
    if (this.toastStore.hasDoneNotification(message.target_id)) {
      this.toastStore.cleanNotificationsById(message.target_id);
    }

    this.toastStore.addNotification(message);
  }

  public showCustomNotification<Comp>(component: ComponentType<Comp>, data: any): ActiveToast<Comp> {
    const toast = this.toastr.success(null, null, {
      toastComponent: component,
      positionClass: 'toast-bottom-right'
    });

    toast.toastRef.componentInstance.data = data;
    toast.toastRef.componentInstance.toast = toast;

    return toast;
  }

  protected cleanAllToasts() {
    this.toastr.toasts.forEach(t => {
      t.toastRef.close();
      this.toastStore.cleanNotificationsById(t.toastRef.componentInstance.id);
    });
  }

  public cleanAndCloseToastById(id: string) {
    this.toastStore.cleanNotificationsById(id);
    this.toastr.toasts.forEach(t => {
      if (t.toastRef.componentInstance.id === id) {
        t.toastRef.close();
      }
    });
  }

  /**
   *
   * @param message OsintSearchNotificationResponse
   */
  protected addToast(message: OsintSearchNotificationResponse) {
    const toast = this.toastr.success(null, null, {
      toastComponent: OsintGroupNotificationsComponent,
      positionClass: 'toast-bottom-right'
    });

    toast.toastRef.componentInstance.id = message.target_id;
    toast.toastRef.componentInstance.toast = toast;
  }
}
