<div
  class="target-delete-dialog"
  *ngIf="
    data.type === 'delete-target' || data.type === 'delete-case' || data.type === 'remove-target' || data.type ==='delete-investigation'
  "
>
  <img
    class="clickable profiler-target-delete-close"
    matTooltip="{{ 'Close' | translate }}"
    (click)="onClose()"
    src="assets/static/images/close-grey.svg"
    height="16"
  />
  <div class="profiler-target-delete-header">
    <ng-container *ngIf="data.type === 'delete-target'">
      {{ 'Permanently delete target' | translate }}
    </ng-container>
    <ng-container *ngIf="data.type === 'delete-case'">
      {{ 'Permanently delete case' | translate }}
    </ng-container>
    <ng-container *ngIf="data.type === 'remove-target'">
      {{ 'Permanently remove target from case' | translate }}
    </ng-container>
    <ng-container *ngIf="data.type === 'delete-investigation'">
      {{ 'Permanently delete investigation' | translate }}
    </ng-container>
  </div>

  <div class="profiler-target-delete-separation"></div>

  <div class="profiler-target-delete-first-row">
    <ng-container *ngIf="data.type === 'delete-target'">
      {{ 'Are you sure you want to delete target permanently?' | translate }}
    </ng-container>
    <ng-container *ngIf="data.type === 'delete-case'">
      {{ 'Are you sure you want to delete case permanently?' | translate }}
    </ng-container>
    <ng-container *ngIf="data.type === 'remove-target'">
      {{ 'Are you sure you want to remove target from case permanently?' | translate }}
    </ng-container>
    <ng-container *ngIf="data.type === 'delete-investigation'">
      {{ 'Are you sure you want to delete investigation permanently?' | translate }}
    </ng-container>
  </div>
  <div class="profiler-target-delete-second-row" *ngIf="data.type !== 'remove-target'">
    {{ 'Once deleted the data cannot be restored' | translate }}
  </div>
  <div class="profiler-target-delete-second-row" *ngIf="data.type === 'remove-target'">
    {{ 'Once removed the data cannot be restored' | translate }}
  </div>

  <div class="row">
    <div class="col-12 end-xs btns-wrapper">
      <button mat-raised-button class="profiler-target-delete-cancel" (click)="onClose()">
        {{ 'Cancel' | translate }}
      </button>
      <button
        mat-raised-button
        class="profiler-target-delete"
        (click)="delete()"
        *ngIf="data.type !== 'remove-target'"
      >
        {{ 'Delete' | translate }}
      </button>
      <button
        mat-raised-button
        class="profiler-target-delete"
        (click)="delete()"
        *ngIf="data.type === 'remove-target'"
      >
        {{ 'Remove' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="target-delete-dialog" *ngIf="data.type === 'removeCase'">
  <img
    class="clickable profiler-target-delete-close"
    matTooltip="{{ 'Close' | translate }}"
    (click)="onClose()"
    src="assets/static/images/close-grey.svg"
    height="16"
  />
  <div class="profiler-target-delete-header">{{ 'Remove target from case' | translate }}</div>
  <div class="profiler-target-delete-separation"></div>

  <div class="profiler-target-delete-first-row">
    {{ 'Are you sure you want to remove target from this case?' | translate }}
  </div>

  <div class="row">
    <div class="col-12 end-xs btns-wrapper">
      <button mat-raised-button class="profiler-target-delete-cancel" (click)="onClose()">
        {{ 'Cancel' | translate }}
      </button>
      <button mat-raised-button class="profiler-target-delete" (click)="delete()">
        {{ 'Remove' | translate }}
      </button>
    </div>
  </div>
</div>
