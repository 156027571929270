<div class="loader-position" *ngIf="showLoader">
  <mat-spinner class="spinner-loader" color="accent"></mat-spinner>
</div>
<form
  class="example-form search-input-form"
  [class.shadow]="searchDrop"
  [class.search-navbar]="searchType === 'OSINT'"
  [ngClass]="{ 'empty-state-form': !searchFields.length && !searchDrop }"
>
  <mat-form-field class="example-chip-list intel-search" appearance="outline" #matFormField>
    <div
      class="empty-state-search-icon"
      *ngIf="!searchFields.length && !searchDrop && navBarIdentifier === availableNavBarIdentifier.MAINNAVBAR"
    >
      <mat-icon>search</mat-icon>
    </div>
    <div
      class="type-effect-wrapper"
      (click)="hideTypingEffect()"
      *ngIf="typingEffect && enableCovid19MX && searchType === IntelSearchType.OSINT"
    >
      <div class="typing-effect {{ appLanguage | async }}">{{ 'We now support search by CURP ID' | translate }}</div>
    </div>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let field of searchFields; let i = index"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(i)"
      >
        <div class="search-item-chip">
          <div class="item-value row">
            <div *ngIf="field.label === searchLabels.PHONE && field.countryCode" [ngClass]="field.countryCode + ' flag'"></div>
            <ng-container *ngIf="field.label === 'Image'">
              <span *ngIf="field.displayName"> {{ field.displayName ? field.displayName : field.value }}</span>
              <span *ngIf="!field.displayName"> {{ field.filename ? field.filename : field.value }}</span>
            </ng-container>
            <ng-container *ngIf="field.label !== 'Image'">
              <span> {{ field.value }}</span>
            </ng-container>
          </div>
          <div class="item-desc assistant-font">
            <span>{{ field.label | translate }}</span>
          </div>
        </div>
      </mat-chip>

      <input
        class="intel-navbar-search-text"
        [class.placeholder-color]="!typingEffect"
        matInput
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
        type="text"
        autocomplete="off"
        placeholder="{{ searchFields?.length > 0 ? '' : (queryPlaceholder | translate) + '...' }}"
        (click)="onSearchDrop()"
        #searchFieldInput
        [formControl]="searchText"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-list>
    <div class="btn-wrp" *ngIf="searchType === 'OSINT'">
      <button
        mat-button
        class="image-btn"
        (click)="addImageDialog()"
        matTooltip="{{ 'Search by face and name' | translate }}"
      >
        <img src="assets/static/images/search-intel/icons-24-search.svg" alt="credits" />
      </button>
    </div>
  </mat-form-field>
</form>
<span *ngIf="theme === 'UNLIMITED' && searchType === 'OSINT'" class="demo-sign">DEMO</span>

<!-- Search Drop Down | Suggestions-->
<div
  class="search-drop"
  [hidden]="!(searchDrop && searchType === 'OSINT')"
  (clickOutside)="clickedOutside()"
  [@openClose]="searchDrop ? 'open' : 'closed'"
>
  <ul class="labels-list">
    <li *ngIf="searchText.value" class="example-details">
      <mat-selection-list #searchList (selectionChange)="onClickSearchItem(searchList.selectedOptions.selected)">
        <ng-container *ngFor="let inputType of inputTypes" >
          <mat-list-option *ngIf="inputTypeValueLabel[inputType].value !== searchLabels.POSSIBLE_TELNO" value="{{ inputTypeValueLabel[inputType].value }}">
            <div mat-line class="search-list-value">{{ searchText.value }}</div>
            <div mat-line class="search-list-label">
              {{ inputTypeValueLabel[inputType].label | translate }}
            </div>
          </mat-list-option>
          <div class="info-message" *ngIf="inputTypeValueLabel[inputType].value === searchLabels.POSSIBLE_TELNO">
            <mat-icon class="icon">info</mat-icon><span class="search-list-label">{{infoMessage}}</span>
          </div>
        </ng-container>
      </mat-selection-list>
    </li>
  </ul>



  <!-- Search Filters -->
  <div class="intel-filters" *ngIf="showFilter">
    <app-intel-filters
      (emitFilters)="importFilters = $event"
      [closeAllFilters]="searchDrop"
      (click)="$event.stopPropagation()"
    ></app-intel-filters>
    <div class="btn-wrp" *ngIf="billingPlan$ | async as billingPlan">
      <button
        mat-button
        class="cp-btn-primary cp-search-btn"
        [class.active]="btnActive && billingPlan?.intelSearch?.cost >= 0"
      >
        <span (click)="search($event)">
          <span class="btn-txt">{{ 'Search' | translate }}</span>
          <ng-container
            *ngIf="(searchText.value || searchFields.length) && billingPlan?.intelSearch?.cost >= 0"
          >
            <span>|</span>
            <span class="credits">{{ billingPlan?.intelSearch?.cost}}</span>
          </ng-container>
        </span>
        <mat-icon [matMenuTriggerFor]="creditsMenu" *ngIf="!isLocatingDisabled && inputTypes.includes('telno')">arrow_drop_down</mat-icon>
      </button>
      <mat-menu class="credits-menu" #creditsMenu="matMenu">
        <button mat-menu-item class="row align-items-center justify-content-between" (click)="searchAndLocate($event)">
          Search & geolocate phone
          <span *ngIf="billingPlan?.intelSearch?.cost && billingPlan?.queryLocation?.cost"  class="credits">{{
            billingPlan?.intelSearch?.cost + billingPlan?.queryLocation?.cost
          }}</span>
        </button>
        <button mat-menu-item class="row align-items-center justify-content-between" (click)="locate()">
          Geolocate phone
          <span *ngIf="billingPlan?.queryLocation?.cost" class="credits">{{ billingPlan?.queryLocation?.cost }}</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <ul class="history-list assistant-font">
    <li>
      <div class="recent-search">{{ 'Recent searches' | translate }}</div>
      <ng-container *ngFor="let search of searchHistory; let i = index">
        <div
          class="value-wrp flex-wrp clickable-content"
          angulartics2On="click"
          [angularticsAction]="matomo.actions.recentSearchItem"
          [angularticsCategory]="matomo.categories.landingPage"
          *ngIf="i < 5"
          [@openClose]="searchDrop ? 'open' : 'closed'"
        >
          <div class="justify-center">
            <img
              (click)="makeFavorite(true, search.id)"
              *ngIf="!search.favorite"
              src="assets/static/images/search-intel/star-empty.svg"
              alt="favorites"
            />
            <img
              (click)="makeFavorite(false, search.id)"
              *ngIf="search.favorite"
              src="assets/static/images/search-intel/star-full.svg"
              alt="favorites"
            />
            <span class="history-item" (click)="showPreviousSearch(search)">
              <span [innerHTML]="search.queryArgsDisplay"></span>
            </span>
          </div>
          <span class="found">{{ search.profilesCount + search.articlesCount }} {{ 'results' | translate }}</span>
        </div>
      </ng-container>
    </li>
    <li
      class="history-list clickable"
      angulartics2On="click"
      [angularticsAction]="matomo.actions.viewSearchHistory"
      [angularticsCategory]="matomo.categories.landingPage"
      [@openClose]="searchDrop ? 'open' : 'closed'"
    >
      <a (click)="routeToHistory()">{{ 'See search history' | translate }}</a>
    </li>
  </ul>
</div>
