import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { head } from 'lodash-es';
import { Action } from '../../classes/action.class';
import { BaseDialogComponent } from '../../classes/base-dialog.component';
import { BillingActions } from '../../models/billing-action.model';
import { CostInfoDialogType } from '../../models/cost-info-dialog-type.model';
import { Themes } from '../../models/skins.model';

class RequestClMsisdn {
  msisdn: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-request-cl-dialog',
  templateUrl: './request-cl-dialog.component.html',
  styleUrls: ['./request-cl-dialog.component.scss']
})
export class RequestClDialogComponent extends BaseDialogComponent implements OnInit {
  targetMsisdns: RequestClMsisdn[];
  message: string;
  title: string;
  action: BillingActions;
  theme: Themes;
  cost: number;
  selectedMsisdn: string;
  constructor(
    public dialogRef: MatDialogRef<RequestClDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      telnos: RequestClMsisdn[];
      type: CostInfoDialogType;
      message: string;
      action: BillingActions;
      title?: string;
      cost?: number;
      actionBtnLabel?: string;
      footerText?: string;
    },
    router: Router,
    private appConfigService: AppConfigService
  ) {
    super(dialogRef, router);
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    this.targetMsisdns = this.data.telnos;
    this.message = this.data.message;
    this.action = this.data.action;
    this.title = this.data.title;
    this.cost = this.data.cost;

    if (this.targetMsisdns.filter(x => !x.disabled).length === 1) {
      this.selectedMsisdn = head(this.targetMsisdns.filter(x => !x.disabled))?.msisdn;
    }
  }

  onSuccess(action: Action = new Action({ key: 'success' })) {
    if (this.dialog) {
      action.data = this.selectedMsisdn;
      this.dialog.close(action);
    }
  }
}
