import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormControlStatus, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { PasswordValidationResult, PasswordValidationType } from './../../../shared/models/login.model';
import { InputStatus } from './../../user-settings/user-settings.models';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.scss'],
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {
  @Input() currentPassword = '';
  @Input() isPartOfOtpForm?: boolean = false;
  @Output() onPasswordChange = new EventEmitter<string>();
  @Output() passwordFormValid? = new EventEmitter<InputStatus>();

  changePasswordForm: FormGroup;
  strengthBar: number;
  visibility = false;
  errorMsg: string;
  isValid = false;
  errorMessage: string;

  constructor(
    protected authService: AuthDataService,
    private translationService: TranslationService,
    private userService: UserService,
    protected router: Router,
    @Optional() public dialogRef: MatDialogRef<ChangePasswordDialogComponent>
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.onKeyupCheckPassword();

    if (this.isPartOfOtpForm) {
      this.subscriptions.push(
        this.changePasswordForm.statusChanges.subscribe((changePasswordFormStatus: FormControlStatus) => {
          this.passwordFormValid.emit({
            value: this.changePasswordForm.controls['newPassword'].value,
            valid: this.isValid && this.validate.match.exact,
          });
        })
      );
    }
  }

  get validate() {
    const newPassword = this.changePasswordForm.controls['newPassword'].value;
    const confirmPassword = this.changePasswordForm.controls['confirmPassword'].value;

    return {
      match: {
        start: newPassword.indexOf(confirmPassword) === 0,
        exact: newPassword === confirmPassword,
      },
    };
  }

  initForm() {
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl(''),
      newPassword: new FormControl(''),
      confirmPassword: new FormControl(''),
    });
  }

  onKeyupCheckPassword() {
    this.subscription = this.changePasswordForm
      .get('newPassword')
      .valueChanges.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((newPassword: string) => {
        if (newPassword.length) {
          this.checkPasswordPolicy(newPassword);
        }
      });
  }

  private checkPasswordPolicy(newPassword: string) {
    this.authService.checkPasswordPolicy(newPassword).subscribe((password: PasswordValidationResult) => {
      this.isValid = password.valid;
      this.calculatePasswordStrength(password.validated_policies);
      this.passwordValidation(password.validated_policies);
    });
  }

  calculatePasswordStrength(validationType: PasswordValidationType) {
    const validatePoliciesList = Object.values(validationType);
    this.strengthBar = validatePoliciesList.reduce((score, item) => {
      return score + (item ? 100 / validatePoliciesList.length : 0);
    }, 0);
  }

  passwordValidation(validationType: PasswordValidationType) {
    if (!validationType.length) {
      this.errorMsg = 'Password must be at least 8 characters.';
    } else if (!validationType.uppercase) {
      this.errorMsg = 'Password does not contain upper case letters.';
    } else if (!validationType.lowercase) {
      this.errorMsg = 'Password does not contain lower case letters.';
    } else if (!validationType.digits) {
      this.errorMsg = 'Password does not contain any digits.';
    } else {
      this.errorMsg = '';
    }
  }

  onChangePassword() {
    const currentPassword = this.currentPassword.length
      ? this.currentPassword
      : this.changePasswordForm.controls['currentPassword'].value;
    const newPassword = this.changePasswordForm.controls['newPassword'].value;
    if (this.changePasswordForm.valid) {
      if (this.validate.match.exact) {
        this.userService.resetPassword(currentPassword, newPassword).subscribe(
          () => {
            this.onPasswordChange.emit(newPassword);
            this.showMessage(this.translationService.translate('The password was changed!'));
            if (this.dialogRef) {
              this.dialogRef.close();
            }
          },
          (error) => {
            this.showMessage(this.translationService.translate(error.message));
          }
        );
      } else {
        this.showMessage(this.translationService.translate(`Passwords don't match.`));
      }
    } else {
      this.showMessage(this.translationService.translate('Invalid values. Please fill in all fields.'));
    }
  }
}
