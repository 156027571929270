export function getTranslations() {
  return {
    'es': {
      languange: 'es',
      values: {
        'Afghanistan': 'Afganistán',
        'Albania': 'Albania',
        'Algeria': 'Argelia',
        'Andorra': 'Andorra',
        'Angola': 'Angola',
        'Anguilla': 'Anguila',
        'Antigua and Barbuda': 'Antigua y Barbuda',
        'Argentina': 'Argentina',
        'Armenia': 'Armenia',
        'Australia': 'Australia',
        'Austria': 'Austria',
        'Azerbaijan': 'Azerbaiyán',
        'Bahrain': 'Baréin',
        'Bangladesh': 'Bangladés',
        'Belarus': 'Bielorrusia',
        'Belgium': 'Bélgica',
        'Belize': 'Belice',
        'Benin': 'Benín',
        'Bhutan': 'Bután',
        'Bolivia': 'Bolivia',
        'Bosnia and Herzegovina': 'Bosnia y Herzegovina',
        'Botswana': 'Botsuana',
        'Brazil': 'Brasil',
        'British Indian Ocean Territory': 'Territorio Británico del Océano Índico',
        'Brunei': 'Brunéi',
        'Bulgaria': 'Bulgaria',
        'Burkina Faso': 'Burkina Faso',
        'Cambodia': 'Camboya',
        'Cameroon': 'Camerún',
        'Canada': 'Canadá',
        'Cayman Islands': 'Islas Caimán',
        'Central African Republic': 'República Centroafricana',
        'Chad': 'Chad',
        'Chile': 'Chile',
        'China': 'República Popular China',
        'Colombia': 'Colombia',
        'Comoros': 'Comoras',
        'Congo-Brazzaville': 'República del Congo',
        'Cook Islands': 'Islas Cook',
        'Costa Rica': 'Costa Rica',
        "Côte d'Ivoire": "Costa de Marfil",
        'Croatia': 'Croacia',
        'Cuba': 'Cuba',
        'Cyprus': 'Chipre',
        'Czechia': 'Chequia',
        'Democratic Republic of the Congo': 'República Democrática del Congo',
        'Denmark': 'Dinamarca',
        'Djibouti': 'Yibuti',
        'Dominican Republic': 'República Dominicana',
        'Ecuador': 'Ecuador',
        'Egypt': 'Egipto',
        'El Salvador': 'República de El Salvador',
        'Equatorial Guinea': 'Guinea Ecuatorial',
        'Eritrea': 'Eritrea',
        'Estonia': 'Estonia',
        'Ethiopia': 'Etiopía',
        'Falkland Islands': 'Islas Malvinas',
        'Faroe Islands': 'Islas Feroe',
        'Fiji': 'Fiyi',
        'Finland': 'Finlandia',
        'France': 'Francia',
        'Gabon': 'Gabón',
        'Gambia': 'Gambia',
        'Georgia': 'Georgia',
        'Germany': 'Alemania',
        'Ghana': 'Ghana',
        'Gibraltar': 'Gibraltar',
        'Greece': 'Grecia',
        'Greenland': 'Groenlandia',
        'Guatemala': 'Guatemala',
        'Guernsey': 'Guernsey',
        'Guinea': 'Guinea',
        'Guinea-Bissau': 'Guinea-Bisáu',
        'Guyana': 'Guyana',
        'Haiti': 'Haití',
        'Honduras': 'Honduras',
        'Hungary': 'Hungría',
        'Iceland': 'Islandia',
        'India': 'India',
        'Indonesia': 'Indonesia',
        'Iran': 'Irán',
        'Iraq': 'Irak',
        'Ireland': 'Irlanda',
        'Isle of Man': 'Isla de Man',
        'Israel': 'Israel',
        'Italy': 'Italia',
        'Japan': 'Japón',
        'Jersey': 'Jersey',
        'Jordan': 'Jordania',
        'Kazakhstan': 'Kazajistán',
        'Kenya': 'Kenia',
        'Kuwait': 'Kuwait',
        'Kyrgyzstan': 'Kirguistán',
        'Laos': 'Laos',
        'Latvia': 'Letonia',
        'Lebanon': 'Líbano',
        'Lesotho': 'Lesoto',
        'Liberia': 'Liberia',
        'Libya': 'Libia',
        'Lithuania': 'Lituania',
        'Luxembourg': 'Luxemburgo',
        'Macedonia': 'República de Macedonia',
        'Madagascar': 'Madagascar',
        'Malawi': 'Malaui',
        'Malaysia': 'Malasia',
        'Maldives': 'Maldivas',
        'Mali': 'Malí',
        'Malta': 'Malta',
        'Marshall Islands': 'Islas Marshall',
        'Mauritania': 'Mauritania',
        'Mauritius': 'Mauricio',
        'Mexico': 'México',
        'Moldova': 'Moldavia',
        'Monaco': 'Mónaco',
        'Mongolia': 'Mongolia',
        'Montenegro': 'Montenegro',
        'Montserrat': 'Montserrat',
        'Morocco': 'Marruecos',
        'Mozambique': 'Mozambique',
        'Myanmar': 'Myanmar',
        'Namibia': 'Namibia',
        'Nepal': 'Nepal',
        'New Zealand': 'Nueva Zelanda',
        'Nicaragua': 'Nicaragua',
        'Niger': 'Níger',
        'Nigeria': 'Nigeria',
        'Niue': 'Niue',
        'North Korea': 'Corea del Norte',
        'Norway': 'Noruega',
        'Oman': 'Omán',
        'Pakistan': 'Pakistán',
        'Palestine': 'Palestina',
        'Panama': 'Panamá',
        'Papua New Guinea': 'Papua-Nueva Guinea',
        'Paraguay': 'Paraguay',
        'Peru': 'Perú',
        'Philippines': 'Filipinas',
        'Poland': 'Polonia',
        'Portugal': 'Portugal',
        'Qatar': 'Qatar',
        'Romania': 'Rumanía',
        'Russia': 'Rusia',
        'Rwanda': 'Ruanda',
        'Sahrawi Arab Democratic Republic': 'República Árabe Saharaui Democrática',
        'Saint Lucia': 'Santa Lucía',
        'Samoa': 'Samoa',
        'Saudi Arabia': 'Arabia Saudita',
        'Senegal': 'Senegal',
        'Serbia': 'Serbia',
        'Seychelles': 'Seychelles',
        'Sierra Leone': 'Sierra Leona',
        'Singapore': 'Singapur',
        'Slovakia': 'Eslovaquia',
        'Slovenia': 'Eslovenia',
        'Solomon Islands': 'Islas Salomón',
        'Somalia': 'Somalia',
        'South Africa': 'Sudáfrica',
        'South Georgia and the South Sandwich Islands': 'Islas Georgias del Sur y Sandwich del Sur',
        'South Korea': 'Corea del Sur',
        'South Sudan': 'Sudán del Sur',
        'Spain': 'España',
        'Sri Lanka': 'Sri Lanka',
        'Sudan': 'Sudán',
        'Suriname': 'Surinam',
        'Swaziland': 'Suazilandia',
        'Sweden': 'Suecia',
        'Switzerland': 'Suiza',
        'Syria': 'Siria',
        'Taiwan': 'Taiwán',
        'Tajikistan': 'Tayikistán',
        'Tanzania': 'Tanzania',
        'Thailand': 'Tailandia',
        'The Bahamas': 'Las Bahamas',
        'The Netherlands': 'Países Bajos',
        'Togo': 'Togo',
        'Tokelau': 'Tokelau',
        'Tonga': 'Tonga',
        'Trinidad and Tobago': 'Trinidad y Tobago',
        'Tunisia': 'Túnez',
        'Turkey': 'Turquía',
        'Turkmenistan': 'Turkmenistán',
        'Tuvalu': 'Tuvalu',
        'Uganda': 'Uganda',
        'Ukraine': 'Ucrania',
        'United Arab Emirates': 'Emiratos Árabes Unidos',
        'United Kingdom': 'Reino Unido',
        'United States of America': 'Estados Unidos de América',
      }
    },
    'en': {
      languange: 'en',
      values: {
        'Afghanistan': 'Afghanistan',
        'Albania': 'Albania',
        'Algeria': 'Algeria',
        'Andorra': 'Andorra',
        'Angola': 'Angola',
        'Anguilla': 'Anguilla',
        'Antigua and Barbuda': 'Antigua and Barbuda',
        'Argentina': 'Argentina',
        'Armenia': 'Armenia',
        'Australia': 'Australia',
        'Austria': 'Austria',
        'Azerbaijan': 'Azerbaijan',
        'Bahrain': 'Bahrain',
        'Bangladesh': 'Bangladesh',
        'Barbados': 'Barbados',
        'Belarus': 'Belarus',
        'Belgium': 'Belgium',
        'Belize': 'Belize',
        'Benin': 'Benin',
        'Bermuda': 'Bermuda',
        'Bhutan': 'Bhutan',
        'Bolivia': 'Bolivia',
        'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
        'Botswana': 'Botswana',
        'Brazil': 'Brazil',
        'British Indian Ocean Territory': 'British Indian Ocean Territory',
        'British Virgin Islands': 'British Virgin Islands',
        'Brunei': 'Brunei',
        'Bulgaria': 'Bulgaria',
        'Burkina Faso': 'Burkina Faso',
        'Burundi': 'Burundi',
        'Cambodia': 'Cambodia',
        'Cameroon': 'Cameroon',
        'Canada': 'Canada',
        'Cape Verde': 'Cape Verde',
        'Cayman Islands': 'Cayman Islands',
        'Central African Republic': 'Central African Republic',
        'Chad': 'Chad',
        'Chile': 'Chile',
        'China': 'China',
        'Colombia': 'Colombia',
        'Comoros': 'Comoros',
        'Congo-Brazzaville': 'Congo-Brazzaville',
        'Cook Islands': 'Cook Islands',
        'Costa Rica': 'Costa Rica',
        "Côte d'Ivoire": "Côte d'Ivoire",
        'Croatia': 'Croatia',
        'Cuba': 'Cuba',
        'Cyprus': 'Cyprus',
        'Czechia': 'Czechia',
        'Democratic Republic of the Congo': 'Democratic Republic of the Congo',
        'Denmark': 'Denmark',
        'Djibouti': 'Djibouti',
        'Dominica': 'Dominica',
        'Dominican Republic': 'Dominican Republic',
        'East Timor': 'East Timor',
        'Ecuador': 'Ecuador',
        'Egypt': 'Egypt',
        'El Salvador': 'El Salvador',
        'Equatorial Guinea': 'Equatorial Guinea',
        'Eritrea': 'Eritrea',
        'Estonia': 'Estonia',
        'Ethiopia': 'Ethiopia',
        'Falkland Islands': 'Falkland Islands',
        'Faroe Islands': 'Faroe Islands',
        'Federated States of Micronesia': 'Federated States of Micronesia',
        'Fiji': 'Fiji',
        'Finland': 'Finland',
        'France': 'France',
        'Gabon': 'Gabon',
        'Gambia': 'Gambia',
        'Georgia': 'Georgia',
        'Germany': 'Germany',
        'Ghana': 'Ghana',
        'Gibraltar': 'Gibraltar',
        'Greece': 'Greece',
        'Greenland': 'Greenland',
        'Grenada': 'Grenada',
        'Guatemala': 'Guatemala',
        'Guernsey': 'Guernsey',
        'Guinea': 'Guinea',
        'Guinea-Bissau': 'Guinea-Bissau',
        'Guyana': 'Guyana',
        'Haiti': 'Haiti',
        'Honduras': 'Honduras',
        'Hungary': 'Hungary',
        'Iceland': 'Iceland',
        'India': 'India',
        'Indonesia': 'Indonesia',
        'Iran': 'Iran',
        'Iraq': 'Iraq',
        'Ireland': 'Ireland',
        'Isle of Man': 'Isle of Man',
        'Israel': 'Israel',
        'Italy': 'Italy',
        'Jamaica': 'Jamaica',
        'Japan': 'Japan',
        'Jersey': 'Jersey',
        'Jordan': 'Jordan',
        'Kazakhstan': 'Kazakhstan',
        'Kenya': 'Kenya',
        'Kiribati': 'Kiribati',
        'Kosovo': 'Kosovo',
        'Kuwait': 'Kuwait',
        'Kyrgyzstan': 'Kyrgyzstan',
        'Laos': 'Laos',
        'Latvia': 'Latvia',
        'Lebanon': 'Lebanon',
        'Lesotho': 'Lesotho',
        'Liberia': 'Liberia',
        'Libya': 'Libya',
        'Liechtenstein': 'Liechtenstein',
        'Lithuania': 'Lithuania',
        'Luxembourg': 'Luxembourg',
        'Macedonia': 'Macedonia',
        'Madagascar': 'Madagascar',
        'Malawi': 'Malawi',
        'Malaysia': 'Malaysia',
        'Maldives': 'Maldives',
        'Mali': 'Mali',
        'Malta': 'Malta',
        'Marshall Islands': 'Marshall Islands',
        'Mauritania': 'Mauritania',
        'Mauritius': 'Mauritius',
        'Mexico': 'Mexico',
        'Moldova': 'Moldova',
        'Monaco': 'Monaco',
        'Mongolia': 'Mongolia',
        'Montenegro': 'Montenegro',
        'Montserrat': 'Montserrat',
        'Morocco': 'Morocco',
        'Mozambique': 'Mozambique',
        'Myanmar': 'Myanmar',
        'Namibia': 'Namibia',
        'Nauru': 'Nauru',
        'Nepal': 'Nepal',
        'New Zealand': 'New Zealand',
        'Nicaragua': 'Nicaragua',
        'Niger': 'Niger',
        'Nigeria': 'Nigeria',
        'Niue': 'Niue',
        'North Korea': 'North Korea',
        'Norway': 'Norway',
        'Oman': 'Oman',
        'Pakistan': 'Pakistan',
        'Palau': 'Palau',
        'Palestine': 'Palestine',
        'Panama': 'Panama',
        'Papua New Guinea': 'Papua New Guinea',
        'Paraguay': 'Paraguay',
        'Peru': 'Peru',
        'Philippines': 'Philippines',
        'Pitcairn Islands': 'Pitcairn Islands',
        'Poland': 'Poland',
        'Portugal': 'Portugal',
        'Qatar': 'Qatar',
        'Romania': 'Romania',
        'Russia': 'Russia',
        'Rwanda': 'Rwanda',
        'Sahrawi Arab Democratic Republic': 'Sahrawi Arab Democratic Republic',
        'Saint Helena, Ascension and Tristan da Cunha': 'Saint Helena, Ascension and Tristan da Cunha',
        'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
        'Saint Lucia': 'Saint Lucia',
        'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
        'Samoa': 'Samoa',
        'San Marino': 'San Marino',
        'São Tomé and Príncipe': 'São Tomé and Príncipe',
        'Saudi Arabia': 'Saudi Arabia',
        'Senegal': 'Senegal',
        'Serbia': 'Serbia',
        'Seychelles': 'Seychelles',
        'Sierra Leone': 'Sierra Leone',
        'Singapore': 'Singapore',
        'Slovakia': 'Slovakia',
        'Slovenia': 'Slovenia',
        'Solomon Islands': 'Solomon Islands',
        'Somalia': 'Somalia',
        'South Africa': 'South Africa',
        'South Georgia and the South Sandwich Islands': 'South Georgia and the South Sandwich Islands',
        'South Korea': 'South Korea',
        'South Sudan': 'South Sudan',
        'Spain': 'Spain',
        'Sri Lanka': 'Sri Lanka',
        'Sudan': 'Sudan',
        'Suriname': 'Suriname',
        'Swaziland': 'Swaziland',
        'Sweden': 'Sweden',
        'Switzerland': 'Switzerland',
        'Syria': 'Syria',
        'Taiwan': 'Taiwan',
        'Tajikistan': 'Tajikistan',
        'Tanzania': 'Tanzania',
        'Thailand': 'Thailand',
        'The Bahamas': 'The Bahamas',
        'The Netherlands': 'The Netherlands',
        'Togo': 'Togo',
        'Tokelau': 'Tokelau',
        'Tonga': 'Tonga',
        'Trinidad and Tobago': 'Trinidad and Tobago',
        'Tunisia': 'Tunisia',
        'Turkey': 'Turkey',
        'Turkmenistan': 'Turkmenistan',
        'Turks and Caicos Islands': 'Turks and Caicos Islands',
        'Tuvalu': 'Tuvalu',
        'Uganda': 'Uganda',
        'Ukraine': 'Ukraine',
        'United Arab Emirates': 'United Arab Emirates',
        'United Kingdom': 'United Kingdom',
        'United States of America': 'United States of America',
      }
    }
  };
}
