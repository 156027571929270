import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/config';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';
import { RequestClParameters } from 'src/app/modules/analysis/shared/models/request-cl-parameters.model';
import { RequestClValidatorService } from 'src/app/modules/analysis/shared/services/request-cl-validator.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { RequestCl } from 'src/app/services/call-logs/request-cl.model';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Animations } from '../../animations/animations';
import { Action } from '../../classes/action.class';
import { BaseDialogComponent } from '../../classes/base-dialog.component';
import { BillingActions } from '../../models/billing-action.model';
import { ClParameterDataToBillingActions, ClParameterDataTypeMapper, RequestClFormValuesAndState } from '../../models/call-log-request.model';
import { isValidImeiIgnoreChecksum, isValidIMSI, isValidMSISDN } from '../../util/helper';
import { ClRequestType, RequestClDialogModel } from './request-cl-dialog.model';

@Component({
  selector: 'app-new-request-cl-dialog',
  templateUrl: './new-request-cl-dialog.component.html',
  styleUrls: ['./new-request-cl-dialog.component.scss'],
  animations: [Animations.slideInOut],
})
export class NewRequestClDialogComponent extends BaseDialogComponent implements OnInit {
  public availableClOptions: RequestCl;
  public requestAction: BillingActions;
  public uploadAction: BillingActions;
  public hasUploadCapabilities: boolean;
  public hasOnlyUploadCapabilities: boolean = false;
  public editable: boolean;
  private clInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public clInProgress: Observable<boolean> = this.clInProgress$.asObservable();
  private allowRequest$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public allowRequest: Observable<boolean> = this.allowRequest$.asObservable();
  public disablePeriodsSelection$ = combineLatest([this.clInProgress, this.allowRequest], (progress, allow) => progress || !allow);
  public isFormScreen = true;
  public formValueAndState: RequestClFormValuesAndState;
  public clDialogTitle: string;
  public clDialogSubtitle: string;
  public enabledNewCallLogsRequest: boolean;
  public enableIMSICallLogs: boolean;

  constructor(
    public dialogRef: MatDialogRef<NewRequestClDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: RequestClDialogModel,
    router: Router,
    private requestClValidatorService: RequestClValidatorService,
    private translationService: TranslationService,
    private userBillingService: UserBillingService,
    private appConfigService:AppConfigService,
  ) {
    super(dialogRef, router);
    this.enabledNewCallLogsRequest = this.appConfigService.getConfigVariable('enabledNewCallLogsRequest');
    this.enableIMSICallLogs = this.appConfigService.getConfigVariable('enableIMSICallLogs');
  }

  ngOnInit() {
    this.availableClOptions = this.data.clParameters;
    this.hasUploadCapabilities = this.data.hasUploadCapabilities;
    this.hasOnlyUploadCapabilities = this.data.hasOnlyUploadCapabilities;
    this.editable = this.data.editable;
    this.removeInvalidEntries()
    this.buildClDialogTitles();
  }

  requestCallLog(action: Action = new Action({ key: 'success' })) {
    if (this.userBillingService.userHasEnoughCredits([this.requestAction])) {
      const typeAndValue = this.sanitizeSelectedParameter(this.formValueAndState.selectedParameter);
      action.data = { typeAndValue, period: this.formValueAndState.period, actionType: ClRequestType.REQUEST_CALL_LOG };
      return this.dialog.close(action);
    }
  }

  private checkForPendingClRequests(requestCl: RequestClParameters): Observable<number> {
    return this.requestClValidatorService.checkForPendingClRequests(requestCl);
  }

  private removeInvalidEntries(){
    if(this.availableClOptions.imeis)
      this.availableClOptions.imeis = this.availableClOptions.imeis.filter((elem)=> isValidImeiIgnoreChecksum(parseInt(elem.value)));
    if(this.availableClOptions.imsis)
      this.availableClOptions.imsis = this.availableClOptions.imsis.filter((elem)=> isValidIMSI(elem.value));
    if(this.availableClOptions.msisdns)
      this.availableClOptions.msisdns = this.availableClOptions.msisdns.filter((elem)=> isValidMSISDN(elem.value));
  }

  public toggleScreens(): void {
    this.isFormScreen = !this.isFormScreen;
    this.buildClDialogTitles();
  }

  public onFormValueAndStateChange(formData: RequestClFormValuesAndState): void {
    this.formValueAndState = formData;
    this.clInProgress$.next(false);
    this.allowRequest$.next(this.allowRequestValidator());
    this.updateBillingInfo()
    this.updateRequestInfo();
  }

  private sanitizeSelectedParameter(param: Record<string, string>): Record<string, string> {
    const selectedParameterKey = Object.keys(param)[0];
    const selectedParameterValue = param[selectedParameterKey];

    if (!selectedParameterValue) return param;

    return { [selectedParameterKey]: selectedParameterValue.trim() };
  }

  private updateRequestInfo() {
    if (this.formValueAndState.isFormValid) {
      const selectedParameterKey = Object.keys(this.formValueAndState.selectedParameter)[0];
      const requestClParameters =  { [ClParameterDataTypeMapper[selectedParameterKey]]: [this.formValueAndState.selectedParameter[selectedParameterKey]] };
      this.checkForPendingClRequests(requestClParameters).pipe(
        map((result) => result > 0),
        tap((result) => {
          this.clInProgress$.next(result);
          this.allowRequest$.next(this.allowRequestValidator());
        })
      ).subscribe();
    }
  }

  private updateBillingInfo(): void {
    const availableBillingActions = ClParameterDataToBillingActions[Object.keys(this.formValueAndState.selectedParameter)[0]];
    this.requestAction = availableBillingActions.requestActions[this.formValueAndState.period];
    this.uploadAction = availableBillingActions.uploadAction;
  }

  public onUploadFinished(action: Action): void {
    switch(action.key) {
      case 'success': {
        this.close(action);
        break;
      }
      case 'failed': {
        this.showMessage(`${this.translationService.translate('Cannot upload file')}: ${this.translationService.translate(action.data?.message)}`);
        break;
      }
      default: {
        console.warn('OnUploadFinished no action taken for action: ', action);
        break;
      }
    }
  }

  private buildClDialogTitles(): void {
    if (this.isFormScreen) {
      const title = this.hasUploadCapabilities ? 'New call log' : 'Request call log';
      this.clDialogTitle = this.translationService.translate(title);
    } else {
      this.clDialogTitle = this.translationService.interpolate('Upload call log for: #{value}', {value: Object.values(this.formValueAndState.selectedParameter)[0]});
    }

    if (this.hasOnlyUploadCapabilities) {
      this.clDialogTitle = this.translationService.translate('Upload call log');
      this.clDialogSubtitle = this.translationService.translate('Upload Call Log by selecting one of the following options');
    } else {
      const subTitle = this.hasUploadCapabilities ? 'Request or Upload Call Log by selecting one of the following options' : 'Request Call Log by selecting one of the following options';
      this.clDialogSubtitle = this.translationService.translate(subTitle);
    }
  }

  private allowRequestValidator(): boolean {
    const simpleValidation = this.formValueAndState.isFormValid && !this.clInProgress$.getValue();
    if (this.formValueAndState.selectedParameter?.msisdn) {
      return (
        simpleValidation &&
        this.requestClValidatorService.isMsisdnAllowedForRequestCallLogs(
          this.formValueAndState.selectedParameter.msisdn
        )
      );
    } else if (this.formValueAndState.selectedParameter?.imsi) {
      return (
        simpleValidation &&
        this.requestClValidatorService.isIMSIAllowedForRequestCallLogs(
          this.formValueAndState.selectedParameter.imsi
        )
      );
    }
    return simpleValidation;
  }
}
