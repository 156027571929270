<div
  class="conversation-item"
  [class.selected-tactical-item]="selectedConversation && conversation.id === selectedConversation.id"
  (click)="selectConversation(conversation)"
  [class.conversation-item-even]="even"
  [class.conversation-item-odd]="!even">
    <div class="column checkbox-column">
      <mat-checkbox [checked]="checked" class="checkbox-tactical" (change)="multiselect($event, conversation)" (click)="$event.stopPropagation()"></mat-checkbox>
    </div>
    <div class="column">
      {{ conversation.satellite || '' }}
    </div>
    <div class="column">
      {{ conversation.conversationId || '' }}
    </div>
    <div class="column">
      <span *ngIf="conversation.conversationType === 'Voice'">
        <span [class.clickable]="conversation.file" (click)="conversation.file ? audioPlayer(conversation) : ''">
          {{ conversation.conversationType }}
          <img
            class="type-icon"
            *ngIf="conversation.file"
            [src]="selectedConversation && conversation.id === selectedConversation.id ? 'assets/static/images/speaker_inverted.svg' : 'assets/static/images/speaker.svg'"
            height="15"
            width="15">
        </span>
      </span>
      <span *ngIf="conversation.conversationType === 'SMS'">
        <span [class.clickable]="conversation.smsText" (click)="conversation.smsText ? fileReader(conversation.smsText) : ''">
          {{ conversation.conversationType }}
          <img
            class="type-icon"
            *ngIf="conversation.smsText"
            [src]="selectedConversation && conversation.id === selectedConversation.id ? 'assets/static/images/contact_inverted.svg' : 'assets/static/images/contact.svg'"
            height="15"
            width="15">
        </span>
      </span>
    </div>
    <div class="column">
      {{ conversation.direction || '' }}
    </div>
    <div class="column">
      {{ conversation.startTime ? (conversation.startTime | date: 'dd.MM.yyyy HH:mm') : '' }}
    </div>
    <div class="column">
      {{ conversation.durationSeconds || '' }}
    </div>
    <div class="column">
      {{ conversation.caller || '' }}
    </div>
    <div class="column">
      {{ conversation.callee || '' }}
    </div>
    <div class="column">
      {{ conversation.imsi || '' }}
    </div>
    <div class="column">
      {{ conversation.tmsi || '' }}
    </div>
    <div class="column coords-column">
      {{ conversation.location ? conversation.location.coordinates[1].toFixed(4) : '' }} / {{ conversation.location ? conversation.location.coordinates[0].toFixed(4) : '' }}
    </div>
    <div class="column">
      {{ conversation.target || '' }}
      <img
        [src]="selectedConversation && conversation.id === selectedConversation.id ? 'assets/static/images/add_target_inverted.svg' : 'assets/static/images/add_target.svg'"
        height="20"
        *ngIf="!conversation.target"
        class="clickable"
        matTooltip="{{'Add new Target' | translate}}"
        (click)="addTargetDialog(conversation)">
    </div>
</div>
