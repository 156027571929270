import { IconProp } from "@fortawesome/fontawesome-svg-core"

export enum DrawerState {
  MINIMIZED,
  DEFAULT,
}

export interface DrawerOptions  {
    title: string,
    onClose(): void,
    width: string
    positionClass?: string,
    headerIcon?: IconProp
}