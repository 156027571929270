import { Post as PlatformPost, EntityRelationType } from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';

export class Post extends PlatformPost implements BaseTargetEntity {
  targetId: string;
  profileId?: string;
  relationType?: EntityRelationType;

    constructor(model?: Partial<Post>) {
        super();

        if (model) {
            Object.assign(this, model);
        }
    }
}
