import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {
  transform<T>(array: Array<T>, itemToCheck: T): boolean {
    if (!Array.isArray(array)) {
      return false;
    }

    return array.includes(itemToCheck);
  }
}
