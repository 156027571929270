import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { QueryService } from 'src/app/services/query/query.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS } from '@busacca/ng-pick-datetime';
import { AppConfigService } from 'src/app/providers/app-config.service';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  fullPickerInput: 'DD.MM.YYYY HH:mm',
};

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ]

})
export class DatetimepickerComponent extends BaseComponent implements OnInit {
  @Output() dateRange = new EventEmitter<Date[]>();
  public dateTimeRange: Date[];
  isProfilerView = false;
  isAdminView = false;
  @Input() placeholderText: string;
  frequency: number;

  constructor(
    private queryService: QueryService,
    private translationService: TranslationService,
    private applicationStateService: ApplicationStateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    public snackBar: MatSnackBar,
    private router: Router,
    private appConfigService: AppConfigService
  ) {
    super();
    dateTimeAdapter.setLocale(this.appConfigService.getLanguage());
    this.isAdminView = this.router.url.includes('admin');
  }

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Output() dates = new EventEmitter<any>();

  ngOnInit() {
    this.isProfilerView = this.applicationStateService.isProfilerTarget();

    const clearDatesSubscription = this.queryService.clearDates.subscribe(
      (flag) => {
        if (flag) {
          this.dateTimeRange = [];
          this.queryService.dateRange.next([]);
        }
      });

      if (this.appConfigService.getConfigVariable('forceLowFrequencySchedules')) {
        this.subscriptions.push(this.queryService.frequency.subscribe((frequency: number) => {
          this.frequency = frequency;
        }))
      }

    this.subscriptions.push(clearDatesSubscription);
  }

  getRange(date) {
    // tenant ledger
    if (this.isAdminView && !this.handleRangeRestriction(date, 3)) {
      return;
    }
    const datesChecked: boolean = this.datesChecker(date);
    if (datesChecked) {
      if (this.isProfilerView) {
        this.dateRange.emit([date[0].toDate(), date[1].toDate()]);
        return;
      }
      if (this.appConfigService.getConfigVariable('forceLowFrequencySchedules') && this.frequency) {
        this.validateDateRange(date)
      }
      this.queryService.dateRange.next([date[0].toDate(), date[1].toDate()]);
      this.queryService.clearDates.next(false);
    }
  }

  datesChecker(date): boolean {
    if (date[0] && date[1]) {
      return true;
    } else {
      this.showMessage(this.translationService.translate('Please fill in both fields for the date'));
    }
    return false;
  }

  private handleRangeRestriction(dates: any, maxDaysRange: number): boolean {
    const difference = new Date(dates[1]).getTime() - new Date(dates[0]).getTime();
    if (difference < 1000 * 3600 * 24 * maxDaysRange) {
      this.dateRange.emit([dates[0].toDate(), dates[1].toDate()]);
      return true;
    }
    this.showMessage(this.translationService.translate('Please select a 3 day range maximum.'));
  }

  private validateDateRange(dates: string[]) {
    const limit = this.appConfigService.getConfigVariable('themeQueriesLimit');
    const from = new Date(dates[0]);
    const to = new Date(dates[1]);
    const diff = to.getTime() - from.getTime();
    const hours = diff / (1000 * 3600);
    if (hours / (this.frequency / 60) > limit) {
      this.showMessage(
        `${this.translationService.translate('Maximum amount of robot queries is')} ${limit}.
        ${this.translationService.translate('Please choose another daterange.')}`
      )
    }
  }
}
