import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { omit } from 'lodash-es';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { FeatureNotificationResponse } from 'datalayer/models/background-jobs/feature-notification';
import { Notifications } from 'datalayer/models/background-jobs/notification-response';

@Injectable({
  providedIn: 'root'
})
export class FeatureNotificationsStore {
  private obs$ = new BehaviorSubject<Notifications>({});
  public notifications$ = this.obs$.asObservable();

  private get value() {
    return this.obs$.getValue();
  }

  private set value(value: Notifications) {
    this.obs$.next(value);
  }

  public addNotification(newNotification: FeatureNotificationResponse) {
    let curNotificationsById: FeatureNotificationResponse[] = this.value[newNotification.target_id] || [];
    this.value = {
      ...this.value,
      [newNotification.target_id]: this.processIncomingNotification(curNotificationsById, newNotification),
    };
  }

  private processIncomingNotification(
    curNotificationsById: FeatureNotificationResponse[],
    newNotification: FeatureNotificationResponse
  ): FeatureNotificationResponse[] {
    return curNotificationsById.reduce((acc, notification) => {
      const index: number = acc.findIndex(newNot => newNot.request_id === notification.request_id);
      if (index < 0) acc.push(notification);
      return acc;
    }, [newNotification]);
  }

  cleanNotificationsById(id: string) {
    this.value = omit(this.value, id);
  }

  hasNotificationWithId(id: string) {
    return this.value.hasOwnProperty(id);
  }

  hasDoneNotification(id: string) {
    const curNotificationsById = this.value[id];

    return curNotificationsById ? curNotificationsById.some(n => n.status === JobStatus.DONE) : false;
  }

  deleteNotification(targetId: string, requestId: string) {
    const curNotificationsById: FeatureNotificationResponse[] = this.value[targetId];
    this.value = {
      ...this.value,
      [targetId]: curNotificationsById.filter((notification: FeatureNotificationResponse) => notification.request_id !== requestId),
    }
  }
}
