import { User } from 'src/app/services/user/user.model';

export enum UserFormState {
  All = 'All',
  Passwords = 'Passwords',
  Phone = 'Phone',
  Email = 'Email'
}

export enum CamelCaseUserFieldsHelper {
  EMAIL_NOTIFICATIONS = 'emailNotifications',
  SMS_NOTIFICATIONS = 'smsNotifications',
}

export interface MyDetailsParams {
  email?: string;
  otpPhone?: string;
  password?: string;
  newPassword?: string;
  emailNotifications?: boolean;
  smsNotifications?: boolean;
}

export interface InputStatus {
  valid: boolean;
  value: string;
}

export enum OTPDialogState {
  PasswordScreen = 'PasswordScreen',
  PasswordScreenChangeMethod = 'PasswordScreenChangeMethod',
  OTPSelectionScreen = 'OTPSelectionScreen',
  ActivateCode = 'ActivateCode',
  ActivateQR = 'ActivateQR',
}

export enum OTPChannels {
  Email = 'email',
  OtpPhone = 'sms',
  App = 'app',
  None = '',
}

export interface OTPModalsData {
  state: OTPDialogState;
  user: User;
  channel?: OTPChannels;
  isActivated?: boolean;
  isPasswordChange?: boolean;
}

export interface OTPCodeRequestHandlePasswordValidity {
  password: string;
}

export interface OTPCodeRequestDeactivate extends OTPCodeRequestHandlePasswordValidity {
  otp_status: boolean;
}

export interface OTPCodeRequestChangeState extends OTPCodeRequestHandlePasswordValidity {
  otp_channel: OTPChannels;
}

export interface OTPCodeRequestActivateCode extends OTPCodeRequestHandlePasswordValidity {
  otp_channel: OTPChannels;
  otp_code?: string;
  otp_status?: boolean;
  otp_phone?: string;
  otp_email?: string;
}

export type OTPCodeRequest =
  | OTPCodeRequestHandlePasswordValidity
  | OTPCodeRequestDeactivate
  | OTPCodeRequestChangeState
  | OTPCodeRequestActivateCode;

export const otpChannelLabel: Record<OTPChannels, string> = {
    [OTPChannels.Email]: 'email address',
    [OTPChannels.OtpPhone]: 'phone number',
    [OTPChannels.App]: 'application',
    [OTPChannels.None]: ''
}
