import { EntityRelationType } from '../enums';
import { Entity } from './entity';

/*
* Will be used as payload on the AvailableData event body
*
*/
export class EntityRelationShort extends Entity {
  public relationType: EntityRelationType;
  public fromEntity: Entity;
  public toEntity: Entity;
}
