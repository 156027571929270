import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { flow, map, toPairs, zipObject, groupBy } from 'lodash-es';
import { BaseComponent } from 'src/app/base/base.component';
import { TacticalService } from 'src/app/services/tactical/tactical.service';
import { LawEnforcementItem } from 'src/app/shared/models/law-enforcement-item.model';
import { MatDialog } from '@angular/material/dialog';
import { LawEnforcementDialogComponent } from 'src/app/components/tactical/law-enforcement-dialog/law-enforcement-dialog.component';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Component({
  selector: 'app-li-log-table',
  templateUrl: './li-log-table.component.html',
  styleUrls: ['./li-log-table.component.scss']
})
export class LiLogTableComponent extends BaseComponent implements OnInit, OnChanges {
  lawEnforcementData: LawEnforcementItem[];
  filteredLawEnforcementData: LawEnforcementItem[] = [];
  paginatedLawEnforcementData: LawEnforcementItem[] = [];
  checked;
  groupBy;
  groupByView = false;
  groupedData = [];
  loader: boolean;
  theme: string;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;

  paginator = {
    pageSize: 6,
    currentPage: 0,
    totalSize: 0
  };

  sorterSettings = {
    field: undefined,
    sortOrder: 1
  };

  groupByFields = [
    {
      label: 'MSISDN',
      value: 'msisdn'
    },
    {
      label: 'Type',
      value: 'leType'
    },
  ];

  @ViewChild('searchInput',{ static: true }) searchInput: ElementRef<HTMLInputElement>;
  @Input() fullScreen;

  @Output() emittedSelectedLawEnforcement = new EventEmitter<LawEnforcementItem>();
  @Output() emittedMultiselectLawEnforcement = new EventEmitter<{ lawEnforcement: LawEnforcementItem, new: boolean }>();
  @Output() refreshMap = new EventEmitter<boolean>();

  constructor(
    private tacticalService: TacticalService,
    public dialog: MatDialog,
    private appConfigService: AppConfigService,
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    this.loader = false;
    const lawEnforcementDataSubscription = this.tacticalService.lawEnforcementData.subscribe(
      (data: LawEnforcementItem[]) => {
        this.lawEnforcementData = data;
        this.paginator.currentPage = 0;
        this._iteratePaginator();
      }
    );

    const getLocationQueryLoader = this.tacticalService.tacticalLoader.subscribe(
      (loader) => {
        this.loader = loader;
      }
    );
    this.subscriptions.push(lawEnforcementDataSubscription, getLocationQueryLoader);
    this.tacticalService.clearSelectedLawEnforcement();
  }

  ngOnChanges() {
    if (this.fullScreen) {
      this.paginator.pageSize = 13;
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    } else {
      this.paginator.pageSize = 6;
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    }
  }

  onSelectedLawEnforcement(le: LawEnforcementItem) {
    this.emittedSelectedLawEnforcement.emit(le);
  }

  onMultiselectLawEnforcement(data: { le: LawEnforcementItem, checked: boolean }) {
    this.emittedMultiselectLawEnforcement.emit({ lawEnforcement: data.le, new: data.checked ? true : false });
  }

  refreshLawEnforcementData() {
    this.groupByView = false;
    this.groupBy = null;
    this.groupedData = [];
    this.refreshMap.emit(true);
  }

  onPaginatePageChange(event) {
    this.paginator.currentPage = event.pageIndex;
    this._iteratePaginator();
  }

  public _iteratePaginator() {
    if (this.lawEnforcementData) {
      const lawEnforcementData = this.searchInput.nativeElement.value ? this.filteredLawEnforcementData : this.lawEnforcementData;
      this.paginator.totalSize = lawEnforcementData.length;
      this.paginatedLawEnforcementData = lawEnforcementData.slice(
        this.paginator.currentPage * this.paginator.pageSize,
        (this.paginator.currentPage + 1) * this.paginator.pageSize
      );
    }
  }

  onKeyUp(event) {
    if (this.lawEnforcementData) {
      this.filteredLawEnforcementData.length = 0;
      this.paginatedLawEnforcementData.length = 0;
      const filterValue = this.searchInput.nativeElement.value.toLowerCase();
      for (const lawEnforcement of this.lawEnforcementData) {
        for (const key of Object.keys(lawEnforcement)) {
          if (lawEnforcement[key] && lawEnforcement[key].toString().toLowerCase().includes(filterValue)) {
            this.filteredLawEnforcementData.push(lawEnforcement);
            break;
          }
        }
      }
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    }
  }

  sort(field) {
    this.sorterSettings.sortOrder = field === this.sorterSettings.field ? this.sorterSettings.sortOrder *= -1 : 1;
    this.sorterSettings.field = field;
    this.lawEnforcementData.sort(this.arraySort(field, this.sorterSettings.sortOrder));
    this.filteredLawEnforcementData.sort(this.arraySort(field, this.sorterSettings.sortOrder));
    this._iteratePaginator();
  }

  arraySort(property, sortOrder) {
    return function (a, b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  // multiselect all the entries. Currently not used. If we want to add this functionality for the future
  // we need to reimplemented to work with the module map.
  // multiselect(event) {
  //   this.checked = event.checked;
  //   if (event.checked) {
  //     for (const lawEnforcement of this.lawEnforcementData) {
  //       // constract the object that the map function requires.
  //       // TODO: fix the map function to accept a general type object
  //       const markerData = {
  //         location: lawEnforcement.location,
  //         queryArgs: {
  //           telno: lawEnforcement.msisdn
  //         },
  //         id: lawEnforcement.id
  //       };
  //       this.mapService.addToMultipins(markerData);
  //     }
  //   } else {
  //     this.mapService.refreshMap();
  //   }
  // }

  filterView() {
    this.groupByView = true;
    this.groupedData = this.groupByFilter(this.lawEnforcementData, this.groupBy.toString(), 'groupName', 'items');
  }

  groupByFilter(dataToGroupOn, fieldNameToGroupOn, fieldNameForGroupName, fieldNameForChildren) {
    return flow(
      (value) => groupBy(value, fieldNameToGroupOn),
      toPairs,
      (value) => map(value, curVal => zipObject([fieldNameForGroupName, fieldNameForChildren], curVal))
    )(dataToGroupOn);
  }

  getGroupHeight() {
    return this.fullScreen ? '50vh' : '18vh';
  }

  openDialog(): void {
    this.dialog.open(LawEnforcementDialogComponent, {
      width: '35vw',
      height: '35vh',
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : (this.isWhiteTheme ? 'white-theme' : ''),
    });
  }

}
