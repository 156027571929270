import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Angulartics2Matomo } from 'angulartics2';
import { FeatureNotificationResponse } from 'datalayer/models/background-jobs/feature-notification';
import { OsintSearchNotificationResponse } from 'datalayer/models/background-jobs/osint-notifications';
import { PurchaseNotificationType } from 'datalayer/models/background-jobs/purchase-notifications';
import { RefreshLocalCacheService } from 'datalayer/services/base/refresh-data.service';
import { distinctUntilChanged, filter, skipWhile, take, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { JobStatus } from 'src/app/modules/data-layer/models/background-jobs/background-job-status';
import { JobType } from 'src/app/modules/data-layer/models/background-jobs/background-job-type';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { TitleService } from 'src/app/services/title.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { Message } from 'src/app/services/websocket/websocket.class';
import { OsintGroupNotificationsService } from 'src/app/shared/components/osint-group-notifications/osint-group-notifications.service';
import { Skins, Themes } from 'src/app/shared/models/skins.model';
import { customIconList } from 'src/app/shared/util/custom-icons';
import { environment } from 'src/environments/environment';
import { ProxyWsService } from './modules/ad-ids/shared/proxy-ws.service';
import { PurchaseNotificationsService } from './modules/call-logs/components/cl-main/components/cl-intel-suggestions/purchase-notification/purchase-notification.service';
import { SomedusRequestObj } from './modules/profiler/shared/models/profiler-dashboard-sections.model';
import { IntelResultsNotificationsService } from './modules/search-intel/components/intel-results-notification/intel-results-notification.service';
import { CssOverwritesService } from './services/application/css-overwrites.service';
import { AuthState } from './services/authentication/auth-state.enum';
import { AuthService } from './services/authentication/auth.service';
import { FeaturesService } from './services/features/features.service';
import { NearbyLocationsStore } from './services/nearby-locations.store';
import { QuestionnaireService, QuestionnaireState } from './services/questionnaire/questionnaire.service';
import { TranslationService } from './services/translation/translation.service';
import { DataLayerWsManagerService } from './services/websocket/data-layer-ws-manager.service';
import { ServerPyWsService } from './services/websocket/server-py-websocket.service';
import { Animations } from './shared/animations/animations';
import { FeatureGroupNotificationsService } from './shared/components/feature-group-notifications/feature-group-notifications.service';
import { InvestigationFeedbackPopupComponent } from './shared/components/investigation-feedback-popup/investigation-feedback-popup.component';
import { ApplicationMainPageUrls } from './shared/models/application-main-page-urls.enum';
import { NearbyLocationsBodyMsg } from './shared/models/nearby-locations.model';
import { transformSnakeToCamel } from './shared/util/helper';

const themesToImage: { [key in Themes]: string } = {
  [Themes.GEO]: 'intellectus.png',
  [Themes.GEOLOC]: 'geoloc_logo_64.png',
  [Themes.WHITE]: 'white-logo.png',
  [Themes.FRESHVALE]: 'freshvale-favicon-logo.png',
  [Themes.UNLIMITED]: '',
  [Themes.INTELSHIELD]: 'intellshield.png',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [Animations.slideInAnimation, Animations.fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponent implements OnInit {
  title = 'geo2-fe';
  showNavbar: boolean = false;
  isSmallDeviceResolution = false;
  isMobileResolution = false;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  isIntelshieldTheme = false;
  basicImageSrc = './assets/static/images/';
  theme: Themes;
  @ViewChild('targetHeight', { static: true }) targetElement: any;
  headerTop: number = 0;
  widthVal = false;
  topVal = false;
  flavor: string;
  removeOverflowStyle = false;

  enableQuestionnaires = this.appConfigService.getConfigVariable('enableQuestionnaires');
  @ViewChild('questionnaireContainer', { read: ViewContainerRef }) questionnaireContainer;
  questionnaireRef: ComponentRef<InvestigationFeedbackPopupComponent>;
  public isInvestigationPage = false;
  constructor(
    private authService: AuthService,
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private appConfigService: AppConfigService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private refreshLocalCacheService: RefreshLocalCacheService,
    private osintGroupNotificationsService: OsintGroupNotificationsService,
    private featureGroupNotificationsService: FeatureGroupNotificationsService,
    private angulartics2: Angulartics2Matomo,
    private titleService: TitleService,
    private nearbyLocationsStore: NearbyLocationsStore,
    private dataLayerWsManager: DataLayerWsManagerService,
    private featuresService: FeaturesService,
    public purchaseNotificationsService: PurchaseNotificationsService,
    private serverPyWsService: ServerPyWsService,
    private translationService: TranslationService,
    private questionnaireService: QuestionnaireService,
    private resolver: ComponentFactoryResolver,
    private intelResultsNotificationsService: IntelResultsNotificationsService,
    private proxyWsService: ProxyWsService,
    public cssOverwritesService: CssOverwritesService,
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.flavor = this.appConfigService.getConfigVariable('flavor');
    const isUAEnabled = this.appConfigService.getConfigVariable('enabledUA');

    // For Custom Icons
    customIconList.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon.iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.iconUrl));
    });
    if (isUAEnabled) {
      this.angulartics2.startTracking();
    }
    this.titleService.startListening();
  }

  setupRouteRelatedUIFlags(url: string) {
    const outterRoutes = ['/login', '/terms-of-use'];
    const routes = ['/discovery', '/dashboard-new', '/adint', '/targets/cases-link-analysis'];
    this.widthVal = routes.includes(url) || outterRoutes.includes(url);
    if(this.widthVal) {
      this.cssOverwritesService.changeMainContainerWidth('100%')
    } else {
      this.cssOverwritesService.resetMainContainerWidth()
    }
    this.topVal = outterRoutes.includes(url);
    this.removeOverflowStyle =
      url.includes(`/${ApplicationMainPageUrls.WEBINT}/results`) ||
      url.includes('/overview') ||
      url.includes('/feed') ||
      url.includes('/case-call-log-analysis');
    this.showNavbar = !outterRoutes.includes(url);
    this.isInvestigationPage = this.applicationStateService.isPage(ApplicationMainPageUrls.INVESTIGATION);
  }

  ngOnInit() {
    const routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
      next: (event: Event) => this.setupRouteRelatedUIFlags((event as NavigationEnd).url),
    });

    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    
    if (environment.useMockData) {
      this.showNavbar = true;
    }
    const authSubscription = this.authService.authState$
    .pipe(
        tap((state) => {
          if (state !== AuthState.authenticated) {
            return;
          }
          
          this.listenPurchaseClNotifications();
          this.dataLayerWsManager.connect();
          this.listenOsintNotification();
          this.createPendingSomedusNotifications();
          this.listenSearchResultsNotifications();
        })
      )
      .subscribe((state) => {
        this.showNavbar = state === AuthState.authenticated;
      });

    const configSubscription = this.appConfigService.authenticatedConfigLoaded$.pipe(
      skipWhile((v) => !v),
      take(1))
      .subscribe((config)=> {
        this.theme = this.appConfigService.getConfigVariable('theme');
        this.isIntelshieldTheme = this.theme === 'INTELSHIELD';
        this.setFavicon();
      })

    this.subscriptions.push(
      this.questionnaireService.showTypeOfInvestigation
        .pipe(
          distinctUntilChanged((prev, curr) => {
            return prev.shouldShow === curr.shouldShow && prev.state === curr.state;
          })
        )
        .subscribe((state: QuestionnaireState) => {
          if (state && state.state && state.shouldShow) {
            this.questionnaireContainer.clear();
            const factory: ComponentFactory<InvestigationFeedbackPopupComponent> =
              this.resolver.resolveComponentFactory(InvestigationFeedbackPopupComponent);

            this.questionnaireRef = this.questionnaireContainer.createComponent(factory);
          } else {
            if (this.questionnaireRef) {
              this.questionnaireRef.destroy();
            }
          }
        })
    );

    this.subscriptions.push(authSubscription, routerSubscription, configSubscription);
    this.isSmallDeviceResolution = this.applicationStateService.getIsSmallDeviceResolution();
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
  }

  public setFavicon() {
    let favicon = document.querySelector('link[rel="shortcut icon"]');
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.setAttribute('rel', 'shortcut icon');
      const head = document.querySelector('head');

      if (head) {
        head.appendChild(favicon);
      }
    }
    favicon.setAttribute('type', 'image/png');
    if (this.flavor === Skins.CVTP) {
      favicon.setAttribute('href', `${this.basicImageSrc}trg-logo.png`);
    } else if (environment.flavor === Skins.GEO4) {
      const image = themesToImage[this.theme];
      favicon.setAttribute('href', `${this.basicImageSrc}${image}`);
    }

    if (this.theme === 'INTELSHIELD') {
      const image = themesToImage[this.theme];
      favicon.setAttribute('href', `${this.basicImageSrc}${image}`);
    }
  }

  getHeadHeight(heightVal: number) {
    this.headerTop = heightVal;
  }

  private createPendingSomedusNotifications(): void {
    this.subscriptions.push(
      this.featuresService.getPendingSomedusFeatures().subscribe((result: SomedusRequestObj[]) => {
        const notifications: FeatureNotificationResponse[] = [];
        if (result?.length) {
          result.forEach((somedesRequest) => {
            notifications.push({
              target_id: somedesRequest.targetId,
              platform: somedesRequest.platform,
              status: somedesRequest.status,
              request_id: somedesRequest.requestId,
            });
          });
        }
        notifications.forEach((notification) =>
          this.featureGroupNotificationsService.processNotification(notification)
        );
      })
    );
  }

  private listenOsintNotification() {
    this.serverPyWsService.getMessage().subscribe((msg: Message) => {
      switch (msg.subject) {
        case MessageSubject.NearbyLocations:
          const response: NearbyLocationsBodyMsg = msg.body;
          this.nearbyLocationsStore.saveNearbyLocations({
            queryId: response.geo_query_id,
            queryDate: response.query_date,
            profiles: transformSnakeToCamel(response.profiles),
            queryArgTelno: response.telno,
          });
          break;
        case MessageSubject.TrilaterationNearbyLocations:
          this.nearbyLocationsStore.setTrilaterationNearbyPeopleResponse(msg.body as NearbyLocationsBodyMsg);
          break;
        case MessageSubject.OSINTSearch:
          if (msg.body?.osint_type === JobType.DEEP_OSINT && msg.body.status === JobStatus.DONE) {
            // We need this timeout to make sure the Data Platform processed all of the new data before we request to
            // view them in the FE
            setTimeout(() => {
              this.osintGroupNotificationsService.processNotification(msg.body as OsintSearchNotificationResponse);
              this.refreshLocalCacheService.refreshSource(msg);
            }, 1000 * 60 * 3); // 3 min
          } else {
            this.osintGroupNotificationsService.processNotification(msg.body as OsintSearchNotificationResponse);
            this.refreshLocalCacheService.refreshSource(msg);
          }
          break;

        case MessageSubject.SomedusQueryRequest:
        case MessageSubject.SomedusQueryResponse:
          this.featureGroupNotificationsService.processNotification(msg.body as FeatureNotificationResponse);
          break;
        default:
          break;
      }
    });
  }

  listenPurchaseClNotifications() {
    this.proxyWsService.getMessage().subscribe((msg) => {
      switch (msg?.body?.notificationType) {
        case PurchaseNotificationType.PurchaseCompleted:
          this.purchaseNotificationsService.processNotification(msg.body);
          break;
        case PurchaseNotificationType.PurchaseFailed:
          this.showMessage(this.translationService.translate('Purchase call logs failed!'));
          break;
        default:
          break;
      }
    });
  }

  listenSearchResultsNotifications() {
    this.proxyWsService.getMessage().subscribe((msg: Message) => {
      if (msg?.body?.subject === MessageSubject.searchingTrgDataLake) {
        this.intelResultsNotificationsService.processNotification(msg);
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
