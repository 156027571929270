import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, Observable } from 'rxjs';
import { RequestCl } from 'src/app/services/call-logs/request-cl.model';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseComponent } from '../../classes/base.component';
import { ClAvailablePeriodsSelector, ClParameterDataTypeMapper, ClParameterType, RequestClFormValuesAndState } from '../../models/call-log-request.model';
import { calculateValidImei, isValidIMEIValidateChecksum, isValidIMSI, isValidMSISDN, validateFormControlBy } from '../../util/helper';

@Component({
  selector: 'app-request-cl-form',
  templateUrl: './request-cl-form.component.html',
  styleUrls: ['./request-cl-form.component.scss'],
})
export class RequestClFormComponent extends BaseComponent implements OnInit, OnChanges {
  public requestClForm: FormGroup;
  public selectedParameter: { [key in ClParameterType]?: string } = {};
  public formatValidation = true;
  public clParameterType = Object.values(ClParameterType);
  public clParameterDataTypeMapper = ClParameterDataTypeMapper;
  public forbiddenNumer: boolean;
  public formHintValue: string;
  public formErrorMessage: string;
  public availablePeriods = ClAvailablePeriodsSelector;
  @Input() enableImsiUploads: boolean;
  @Input() editable: boolean;
  @Input() availableClOptions: RequestCl;
  @Input() hidePeriodsSelection: boolean = false;
  @Input() disablePeriodsSelection: Observable<boolean>;
  @Input() hidePeriodsNote: boolean = false;
  @Output() formValueAndStateChange: EventEmitter<RequestClFormValuesAndState> = new EventEmitter();

  constructor(
    private translationService: TranslationService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enableImsiUploads.firstChange && !this.enableImsiUploads) {
      this.clParameterType = this.clParameterType.filter(type => type !== ClParameterType.IMSI);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.formValueAndStateChange.emit({selectedParameter: this.selectedParameter, period: this.requestClForm.controls['period'].value, isFormValid: this.requestClForm.valid});
    this.subscriptions.push(this.disablePeriodsSelection.subscribe((result) => {
      switch(true) {
        case result:
          this.requestClForm.controls['period'].disable({emitEvent: false});
          break;
        default: {
          this.requestClForm.controls['period'].enable({emitEvent: false});
          break;
        }
      }
    }));
  }

  private initForm() {
    const clParameter = this.getFirstParameterWichHasValues();
    this.selectedParameter = this.getFirstValueOfSelectedParameter(clParameter);
    this.requestClForm = new FormGroup({
      clParameterType: new FormControl(clParameter, [Validators.required]),
      clParameter: new FormControl(this.selectedParameter[clParameter] || '', [Validators.required]),
      period: new FormControl(this.availablePeriods[0].value, [Validators.required]),
    });

    this.setCustomValidators(clParameter);
    this.setCustomValidatorHints(this.selectedParameter[clParameter], clParameter);

    this.subscriptions.push(
      this.requestClForm.controls['clParameterType'].valueChanges.subscribe((value) => {
        this.setCustomValidators(value);
        this.selectedParameter = this.getFirstValueOfSelectedParameter(value);
        this.requestClForm.controls['clParameter'].patchValue(this.selectedParameter[value], { emitEvent: false });
        this.setCustomValidatorHints(this.selectedParameter[value], value);
        this.formValueAndStateChange.emit({selectedParameter: this.selectedParameter, period: this.requestClForm.controls['period'].value, isFormValid: this.requestClForm.valid});
      })
    );

    this.subscriptions.push(
      this.requestClForm.controls['clParameter'].valueChanges.pipe(debounceTime(400)).subscribe((value) => {
        const clParameterType = this.requestClForm.controls['clParameterType'].value;
        this.selectedParameter = { [clParameterType]: value };
        this.setCustomValidatorHints(value, clParameterType);
        this.formValueAndStateChange.emit({selectedParameter: this.selectedParameter, period: this.requestClForm.controls['period'].value, isFormValid: this.requestClForm.valid})
      })
    );

    this.subscriptions.push(
      this.requestClForm.controls['period'].valueChanges.subscribe((value) => {
        this.formValueAndStateChange.emit({selectedParameter: this.selectedParameter, period: value, isFormValid: this.requestClForm.valid})
      })
    );
  }

  private getFirstValueOfSelectedParameter(requestClType: ClParameterType): { [key in ClParameterType]?: string } {
    switch (requestClType) {
      case ClParameterType.MSISDN:
        return {
          [ClParameterType.MSISDN]: this.availableClOptions.msisdns?.filter((msisdn) => !msisdn.disabled)[0]?.value,
        };
      case ClParameterType.IMSI:
        return { [ClParameterType.IMSI]: this.availableClOptions.imsis?.filter((imsi) => !imsi.disabled)[0]?.value };
      case ClParameterType.IMEI:
        return { [ClParameterType.IMEI]: this.availableClOptions.imeis?.filter((imei) => !imei.disabled)[0]?.value };
    }
  }

  private getFirstParameterWichHasValues(): ClParameterType {
    switch(true) {
      default:
      case this.availableClOptions.msisdns?.length > 0:
        return ClParameterType.MSISDN;
      case this.availableClOptions.imeis?.length > 0:
        return ClParameterType.IMEI;
      case this.availableClOptions.imsis?.length > 0:
        return ClParameterType.IMSI;
    }
  }

  private setCustomValidators(key?: ClParameterType): void {
    const formValidator = {
      [ClParameterType.MSISDN]: isValidMSISDN,
      [ClParameterType.IMSI]: isValidIMSI,
      [ClParameterType.IMEI]: isValidIMEIValidateChecksum
     }
    this.requestClForm.controls['clParameter'].setValidators([Validators.required, validateFormControlBy(formValidator[key] || isValidMSISDN)]);
    this.requestClForm.controls['clParameter'].updateValueAndValidity({ emitEvent: false });
  }

  private setCustomValidatorHints(hintForValue: string, key: ClParameterType): void {
    if(this.requestClForm.valid || !hintForValue || key !== ClParameterType.IMEI) {
      this.formHintValue = undefined;
      this.formErrorMessage = undefined;
      return;
    }
    const formHints = {
      [ClParameterType.IMEI]: calculateValidImei(hintForValue)
    };
    this.formHintValue = formHints[key];
    const message = this.formHintValue ? 'The inputted #{clParameterType} is partially invalid. Please update your request with the following #{clParameterType}:' : '#{clParameterType} entered is not valid. Please try another one';
    this.formErrorMessage = this.translationService.interpolate(message, {
      'clParameterType': this.requestClForm.controls['clParameterType'].value.toUpperCase(),
    });
  }

  public patchFormWithValue(value: string, formControlName: string) {
    if(!this.requestClForm.controls[formControlName]) {
      return;
    }
    this.requestClForm.controls[formControlName].patchValue(value);
  }
}
