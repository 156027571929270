import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '@app/config';
import { Angulartics2 } from 'angulartics2';
import { Observable, of } from 'rxjs';
import { switchMap, tap, filter } from 'rxjs/operators';
import { RequestClParameters } from 'src/app/modules/analysis/shared/models/request-cl-parameters.model';
import { AnalysisService } from 'src/app/modules/analysis/shared/services/analysis.service';
import { RequestClValidatorService } from 'src/app/modules/analysis/shared/services/request-cl-validator.service';
import { CallLogRequestsService } from 'src/app/modules/call-logs/services/call-log-requests.service';
import { Action } from 'src/app/shared/classes/action.class';
import { NewRequestClDialogComponent } from 'src/app/shared/components/new-request-cl-dialog/new-request-cl-dialog.component';
import { ClRequestType, RequestClDialogModel } from 'src/app/shared/components/new-request-cl-dialog/request-cl-dialog.model';
import { ClParameterType, RequestClPayload } from 'src/app/shared/models/call-log-request.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';
import { EmailTemplateService } from '../email-template.service';
import { CallLogRequestsMessagesService, ClRequestStatus } from './call-log-requests-messages.service';
import { RequestCallLogDialogServiceRequest, RequestCl } from './request-cl.model';

@Injectable({
  providedIn: 'root',
})
export class CallLogRequestDialogService {
  private requestCallLogEnabled: boolean;
  constructor(
    private analysisService: AnalysisService,
    private callLogRequestsService: CallLogRequestsService,
    private requestClValidatorService: RequestClValidatorService,
    public dialog: MatDialog,
    private appConfigService: AppConfigService,
    private callLogRequestsMessagesService: CallLogRequestsMessagesService,
    private emailTemplateService: EmailTemplateService,
    private angulartics2: Angulartics2,
  ) {
    this.requestCallLogEnabled = this.appConfigService.getConfigVariable('enabledNewCallLogs');
  }

  openRequestCallLogDialog(request: RequestCallLogDialogServiceRequest): Observable<ClRequestStatus> {
    const requestCl = request.requestCl;
    if (!this.requestCallLogEnabled) {
      return of(ClRequestStatus.NOT_ENABLED);
    }
    requestCl.msisdns = requestCl.msisdns.filter(
      (msisdn) => this.requestClValidatorService.isMsisdnAllowedForRequestCallLogs(msisdn) || request.allowOnlyUpload
    );
    requestCl.imsis = requestCl.imsis.filter(
      (imsi) => this.requestClValidatorService.isIMSIAllowedForRequestCallLogs(imsi) || request.allowOnlyUpload
    );
    return this.validateAndRequestCallLog(request).pipe(
      tap((clRequestStatus: ClRequestStatus) => {
        this.callLogRequestsMessagesService.showClResponseMessage(clRequestStatus);
      })
    );
  }

  private validateAndRequestCallLog(request: RequestCallLogDialogServiceRequest): Observable<ClRequestStatus> {
    const requestCl = request.requestCl;
    return new Observable<ClRequestStatus>((observable) => {
      this.requestClValidatorService.getCallLogsList(requestCl).subscribe(
        (clRequestedForIdentifier) => {
          const requestClData: RequestCl = this.prepareRequestCl(
            clRequestedForIdentifier,
            requestCl,
            request.allowOnlyUpload
          );
          this.openDialog(requestClData, request).subscribe((clReqResponse) => {
            observable.next(clReqResponse);
            observable.complete();
          });
        },
        () => {
          observable.next(ClRequestStatus.FAILED_TO_REQUEST);
          observable.complete();
        }
      );
    });
  }

  private openDialog(requestCl: RequestCl, request: RequestCallLogDialogServiceRequest): Observable<ClRequestStatus> {
    return this.dialog
      .open<NewRequestClDialogComponent, RequestClDialogModel>(NewRequestClDialogComponent, {
        data: {
          clParameters: requestCl,
          ...(request.allowClUpload && { hasUploadCapabilities: request.allowClUpload }),
          ...(request.allowOnlyUpload && { hasOnlyUploadCapabilities: request.allowOnlyUpload }),
          uploadClArgs: {
            ...(request.target && { targetId: request.target.id }),
            ...(request.callLog && { requestCallLogId: request.callLog.id }),
          },
        },
        panelClass: 'renew-credit',
      })
      .afterClosed()
      .pipe(
        filter((action) => action?.key && action?.data),
        switchMap((action: Action) => {
          return this.handleModalAction(action, request.target);
        })
      );
  }

  private sendEmail(
    requestCl: RequestClPayload,
    appUrl: string, target?: TargetItem
  ): Observable<any> {
    return this.callLogRequestsService.sendCallLogRequestToSupport(
      this.emailTemplateService.callLogsRequestEmailFormat(requestCl, { appUrl: appUrl, alias: target?.alias}),
      requestCl.typeAndValue.msisdn
    );
  }

  private sendRequestForCallLog(
    requestCl: RequestClPayload,
    target?: TargetItem
  ): Observable<ClRequestStatus> {
    return new Observable<ClRequestStatus>((observable) => {
      this.analysisService
        .requestCallLog({...requestCl.typeAndValue, period: requestCl.period})
        .pipe(switchMap((clRequest) => this.sendEmail(requestCl, this.partialAppUrl(clRequest.id, target?.id), target)))
        .subscribe(
          () => {
            observable.next(ClRequestStatus.REQUESTED);
            observable.complete();
          },
          (error) => {
            let clRequestStatus = ClRequestStatus.FAILED_TO_REQUEST;
            if (error.error === 'Forbidden') {
              clRequestStatus = ClRequestStatus.FORBIDDEN;
            }
            observable.next(clRequestStatus);
            observable.complete();
          }
        );
    });
  }

  private prepareRequestCl(
    clRequestedForIdentifier: { [key in ClParameterType]?: boolean },
    requestCl: RequestClParameters,
    allowOnlyUpload: boolean
  ): RequestCl {
    let prepareRequestCl: RequestCl = {};

    prepareRequestCl.msisdns = requestCl.msisdns?.map((value) => {
      return { value, disabled: allowOnlyUpload ? false : clRequestedForIdentifier[value] };
    });
    prepareRequestCl.imsis = requestCl.imsis?.map((value) => {
      return { value, disabled: allowOnlyUpload ? false : clRequestedForIdentifier[value] };
    });
    prepareRequestCl.imeis = requestCl.imeis?.map((value) => {
      return { value, disabled: allowOnlyUpload ? false : clRequestedForIdentifier[value] };
    });

    return prepareRequestCl;
  }

  private handleModalAction(action: Action, target: TargetItem): Observable<ClRequestStatus> {
    let clRequestStatus: Observable<ClRequestStatus>;
    switch (action.data.actionType) {
      case ClRequestType.REQUEST_CALL_LOG: {
        this.angulartics2.eventTrack.next({
          action: matomoActions.requestCallLog,
          properties: { category: matomoCategories.callLogAnalysis }
        });
        clRequestStatus = this.sendRequestForCallLog(action.data, target);
        break;
      }
      case ClRequestType.FULFILL_CALL_LOG: {
        clRequestStatus = of(ClRequestStatus.UPLOAD_SUCCESSFUL);
        break;
      }
      default: {
        clRequestStatus = of(ClRequestStatus.CANCELLED);
        break;
      }
    }
    return clRequestStatus;
  }

  private partialAppUrl(requestId: string, targetId?: string): string {
    return targetId ? `${targetId}/new-call-log-analysis` : `call-log-analysis/${requestId}/`;
  }
}
