<div class="navbar-menu" [class.intelshield-theme]="actualTheme === themes.INTELSHIELD">
    <ul class="primary-menu">
        <ng-container *ngFor="let menu of activeMenuItems|slice:0:5">
            <ng-container *ngIf="menu.children && menu.children.length > 0">
                <li
                 [matMenuTriggerFor]="submenu.childMenu"
                 #subMenuTrigger="matMenuTrigger"
                 (mouseover)="subMenuTrigger.openMenu()"
                >
                    <ng-container [ngTemplateOutlet]="menuLink" [ngTemplateOutletContext]="{menu:menu}"></ng-container>
                </li>
                <app-nav-menu-sub-item #submenu [items]="menu.children" [menuTrigger]="subMenuTrigger"></app-nav-menu-sub-item>
            </ng-container>

            <ng-container *ngIf="!menu.children || menu.children.length === 0">
                <li (mouseover)="onMenuItemMouseOver()">
                    <ng-container [ngTemplateOutlet]="menuLink" [ngTemplateOutletContext]="{menu:menu}"></ng-container>
                </li>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="activeMenuItems && activeMenuItems.length >= 6">
            <li [matMenuTriggerFor]="submenu.childMenu" #subMenuTrigger="matMenuTrigger">
                <div class="more-item-menu">
                    <mat-icon class="navbar-more">
                        arrow_drop_down_circle
                    </mat-icon>
                </div>
                <app-nav-menu-sub-item #submenu [items]="activeMenuItems|slice:5" [menuTrigger]="subMenuTrigger"></app-nav-menu-sub-item>
            </li>
        </ng-container>
    </ul>
</div>


<ng-template #menuLink let-menu='menu'>
    <a (click)="handleClick(menu)" [ngClass]="{'router-link-active': menu.routeActive}" *ngIf="menu.visible" [routerLink]="menu.link" routerLinkActive="router-link-active"
        [angulartics2On]="menu.angulartics2On" [angularticsAction]="menu.angularticsAction"
        [angularticsCategory]=" menu.angularticsCategory">
        <mat-icon class="svg-icon" [class]="menu.svgIcon" *ngIf="menu.svgIcon" [svgIcon]="menu.svgIcon"></mat-icon>
        <p class="name">{{ menu.name | translate }}</p>
        <span *ngIf="menu.demoSign && actualTheme === themes.UNLIMITED" class="demo-sign">DEMO</span>
        <span [ngClass]="menu.betaSign && menu.demoSign ? 'both-sign' : '' " *ngIf="menu.betaSign"
            class="beta-sign-label">BETA</span>
    </a>
</ng-template>