import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreditBarTooltip } from '../components/credit-bar-tooltip/credit-bar-tooltip.component';
import { CreditUsageBullet } from '../components/credit-usage-bullets/credit-usage-bullets.component';
import { CreditUsageBar } from '../components/credit-usage-bar/credit-usage-bar.component';
import { CreditDetails } from '../models/credit-details.model';

@Injectable({
  providedIn: 'root',
})
export class CreditDetailsService {
  constructor() {}

  private creditBarTooltip$: BehaviorSubject<CreditBarTooltip> = new BehaviorSubject(null);
  private creditUsageBullets$: BehaviorSubject<CreditUsageBullet[]> = new BehaviorSubject<CreditUsageBullet[]>(null);
  private creditUsageBar$: BehaviorSubject<CreditUsageBar> = new BehaviorSubject(<CreditUsageBar>{
    title: null,
    endVal: 20,
    startVal: 50,
    totalVal: 100,
    lengthBar: 100,
  });
  private creditDetails$: BehaviorSubject<CreditDetails> = new BehaviorSubject<CreditDetails>(null);

  public getCreditBarTooltip(): Observable<CreditBarTooltip> {
    return this.creditBarTooltip$.asObservable();
  }

  public setCreditBarTooltip(creditBarTooltip: CreditBarTooltip): void {
    this.creditBarTooltip$.next(creditBarTooltip);
  }

  public setCreditBarToolTipPosition(positionX: string, positionY: string) {
    this.creditBarTooltip$.next({
      ...this.creditBarTooltip$.getValue(),
      positionX,
      positionY,
    });
  }

  public getCreditUsageBullets(): Observable<CreditUsageBullet[]> {
    return this.creditUsageBullets$.asObservable();
  }

  public setCreditUsageBullets(creditUsageBullets: CreditUsageBullet[]): void {
    this.creditUsageBullets$.next(creditUsageBullets);
  }

  public getCreditUsageBar(): Observable<CreditUsageBar> {
    return this.creditUsageBar$.asObservable().pipe(filter((data: CreditUsageBar) => !!data.title));
  }

  public setCreditUsageBar(creditUsageBar: CreditUsageBar): void {
    this.creditUsageBar$.next(creditUsageBar);
  }

  public getCreditDetails(): Observable<CreditDetails> {
    return this.creditDetails$.asObservable();
  }

  public setCreditDetails(creditDetails: CreditDetails): void {
    this.creditDetails$.next(creditDetails);
  }
}
