import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@trg-ui/link-analysis';
import { AppModule } from './app/app.module';
import { DependencyInjectionService } from './app/services/dependency-injection.service';
import { environment } from 'environment/environment';

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // Set out custom dependency injector to the app module
  .then((moduleRef) => DependencyInjectionService.setInjector(moduleRef.injector))
  .catch((err) => console.error(err));


