import { Pipe, PipeTransform } from '@angular/core';
import { humanize } from '../util/helper';

@Pipe({
  name: 'humanize'
})
export class HumanizePipe implements PipeTransform {

  transform(value: string) {
    return humanize(value);
  }
}
