import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meterToKilometer'
})
export class MeterToKilometerPipe implements PipeTransform {
  transform(meter: number): number {
    return Number((meter / 1000).toFixed(1));
  }
}
