import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  DEFAULT_SITE_SETTINGS,
  Language,
  LanguageFlagsMap,
  LanguageOptions,
  SiteSettings,
} from '../shared/models/site-settings.model';
import { camelToSnakeCase, transformSnakeToCamel } from '../shared/util/helper';
import { AuthService } from './authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService {
  private readonly BASE_URL: string = environment.serverAPIUri;
  private store$: BehaviorSubject<SiteSettings> = new BehaviorSubject<SiteSettings>(DEFAULT_SITE_SETTINGS);

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.authService.isAuthenticated.subscribe(() => this.getSiteSettings().subscribe());
  }

  public getSiteSettings(): Observable<SiteSettings> {
    return this.httpClient.get<{ result: SiteSettings }>(`${this.BASE_URL}/site-settings`).pipe(
      map(({ result }) => transformSnakeToCamel(result)),
      catchError(() => of(DEFAULT_SITE_SETTINGS)),
      map((result: SiteSettings) => (Object.keys(result).length ? result : DEFAULT_SITE_SETTINGS)),
      map((result: SiteSettings) => {
        if (!result.languages?.length) {
          result.languages = DEFAULT_SITE_SETTINGS.languages;
        }
        return result;
      }),
      tap((settings: SiteSettings) => this.setSiteSettings(settings))
    );
  }

  public updateSiteSettings(siteSettings: Partial<SiteSettings>) {
    const requestParam: object = {};
    Object.keys(siteSettings).forEach((key) => (requestParam[camelToSnakeCase(key)] = siteSettings[key]));
    return this.httpClient.put<{ result: SiteSettings }>(`${this.BASE_URL}/site-settings`, requestParam).pipe(
      map(({ result }) => transformSnakeToCamel(result)),
      catchError(() => of(DEFAULT_SITE_SETTINGS)),
      map((result: SiteSettings) => (Object.keys(result).length ? result : DEFAULT_SITE_SETTINGS)),
      tap((settings: SiteSettings) => this.setSiteSettings(settings))
    );
  }

  public setSiteSettings(settings: SiteSettings): void {
    settings.languages = this.addLanguageImage(settings.languages);
    this.store$.next(settings);
  }

  public getStoreSiteSettings(): Observable<SiteSettings> {
    return this.store$.asObservable();
  }

  public updateSiteLanguage(languageOption: LanguageOptions): void {
    const storeValue: SiteSettings = this.store$.getValue();
    const updatedSiteSettingsValue = {
      ...storeValue,
      languages: storeValue.languages.map(({value, display}) => ({
        value,
        display,
        checked: languageOption === value,
      })),
    };
    this.updateSiteSettings(updatedSiteSettingsValue).subscribe();
  }

  private addLanguageImage(languages: Language[]): Language[] {
    return languages.map((language) => ({ ...language, image: LanguageFlagsMap[language.value] }));
  }
}
