export enum EntityRelationType {
  // Social Profiles: Relations Types related to Social Profiles
  Avatar = 'Avatar',
  Friend = 'Friend',
  Follower = 'Follower',
  Following = 'Following',
  Family = 'Family', // includes SignificantOther
  Liked = 'Liked',
  Shared = 'Shared',
  Commented = 'Commented',
  Reply = 'Replied',
  Quote = 'Quoted',
  Broadcast = 'Broadcasted',
  Author = 'Authored',
  Mutual = 'Mutual',
  Joined = 'Joined',
  Mentioned = 'Mentioned',
  Tagged = 'Tagged',
  Uses = 'Uses',
  Published = 'Published',
  Attended = 'Attended',
  WorkedAt = 'WorkedAt',
  Generated = 'Generated',
  // Geolocation: Relations Types related to Geolocation
  LocatedAt = 'LocatedAt',
  // Telco: Relations Types related to Telecommunications
  Roaming = 'Roaming',
  // Common: Neutral Relations Types
  Associated = 'Associated',
  Contains = 'Contains',
  Plain = 'Plain',
  NearDuplicate = 'NearDuplicate',
  SimilarFace = 'SimilarFace',
  // Web Domains
  Registrant = 'Registrant',
  Administer = 'Administer',
  Technical = 'Technical',
}
