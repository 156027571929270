<div *ngIf="skin === 'GEO4' || skin === 'CVTP'" class="bottom-sheet-open">
  <img class="line" src="assets/static/images/open_sheet.svg" height="50" width="100" (click)="closeBottomSheet($event)" />
  <div class="row top-row" [class.hidden-gauge]="!showGaugeOnMobile">
    <div [ngClass]="showGaugeOnMobile? 'col-9': 'col-11'">
      <app-quick-query></app-quick-query>
    </div>
    <div [ngClass]="{'col-1':!showGaugeOnMobile}">
      <app-notifications-list></app-notifications-list>
    </div>
    <div class="col-2" *ngIf="showGaugeOnMobile">
      <app-credits-gauge></app-credits-gauge>
    </div>
  </div>
  <app-query-list (emittedSelectedQuery)="onSelectedQuery($event)"></app-query-list>
</div>
