import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from '../../classes/base.component';
import { AnimationStates, InvestigationStates, InvestigationTypes } from '../../models/investigations.model';
import { FeedbackStates, QuestionnaireDialogComponent } from '../questionnaire-dialog/questionnaire-dialog.component';
import { QuestionnaireService } from './../../../services/questionnaire/questionnaire.service';
@Component({
  selector: 'app-investigation-feedback-popup',
  templateUrl: './investigation-feedback-popup.component.html',
  styleUrls: ['./investigation-feedback-popup.component.scss'],
  animations: [
    trigger('animatePopup', [
      state(
        AnimationStates.Hide,
        style({
          opacity: '0',

          display: 'none'
        })
      ),
      state(
        AnimationStates.Show,
        style({
          opacity: '1',
          display: 'block'
        })
      ),
      transition('* => *', animate(150))
    ])
  ]
})
export class InvestigationFeedbackPopupComponent extends BaseComponent implements OnInit {
  investigationTypes: { name: string; icon: string }[] = [
    { name: InvestigationTypes.Homicide, icon: 'assets/static/images/investigation-types-icons/icon-homicide.png' },
    { name: InvestigationTypes.Violence, icon: 'assets/static/images/investigation-types-icons/icon-violence.png' },
    { name: InvestigationTypes.Robbery, icon: 'assets/static/images/investigation-types-icons/icon-robbery.png' },
    { name: InvestigationTypes.Kidnapping, icon: 'assets/static/images/investigation-types-icons/icon-kidnapping.png' },
    {
      name: InvestigationTypes.FinancialCrime,
      icon: 'assets/static/images/investigation-types-icons/icon-financial.png'
    },
    { name: InvestigationTypes.Fraud, icon: 'assets/static/images/investigation-types-icons/icon-fraud.png' },
    {
      name: InvestigationTypes.DrugsAndIllegalSubstances,
      icon: 'assets/static/images/investigation-types-icons/icon-drugs.png'
    },
    { name: InvestigationTypes.Other, icon: '' }
  ];

  animationStates = AnimationStates;
  animationState: string = this.animationStates.Show;

  investigationStates = InvestigationStates;
  currentInvestigationState: InvestigationStates;

  selectedInvestigationType: string = '';

  currentUser: string;
  tenant: string;

  theme: string;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;

  constructor(
    private questionnaireService: QuestionnaireService,
    private userService: UserService,
    private authService: AuthService,
    protected appConfigService: AppConfigService,
    public dialog: MatDialog
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit(): void {
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    this.subscriptions.push(
      this.userService.getCurrentUser().subscribe((data: User) => {
        this.currentUser = data.username;
        this.tenant = data.roles[0];
      })
    );

    this.subscriptions.push(
      this.questionnaireService.showTypeOfInvestigation.subscribe(
        (type: { shouldShow: boolean; state: InvestigationStates }) => {
          this.currentInvestigationState = type.state;
        }
      )
    );

    this.subscriptions.push(
      this.authService.isAuthenticated.subscribe(isAuth => {
        if (!isAuth) {
          this.questionnaireService.close();
        }
      })
    );
  }

  optOut(): void {
    this.dialog.open(QuestionnaireDialogComponent, {
      data: { identity: this.currentUser, state: FeedbackStates.CloseFeedback },
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : ''
    });
  }

  hidePopup(): void {
    this.animationState == this.animationStates.Hide
      ? (this.animationState = this.animationStates.Show)
      : (this.animationState = this.animationStates.Hide);
  }

  submitInvestigationType(): void {
    if (this.selectedInvestigationType) {
      this.questionnaireService.submitInvestigation(this.currentUser, this.selectedInvestigationType);
      this.questionnaireService.close();
    }
  }

  sameInvestigationNo(): void {
    this.questionnaireService.notTheSameInvestigation();
  }

  sameInvestigationYes(): void {
    this.questionnaireService.handleSameInvestigation();
    this.questionnaireService.close();
  }
}
