import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApplicationMainPageUrls } from '../../models/application-main-page-urls.enum';

@Component({
  selector: 'app-redirect-snack-bar',
  templateUrl: 'redirect-snack-bar.component.html',
  styleUrls: ['redirect-snack-bar.component.scss'],
})

export class RedirectSnackBarComponent {
  targetId: string;
  htmlTemplate: { start: string, end: string };
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { id: string, html: { start: string, end: string } },
    private router: Router,
  ) {
    this.targetId = data.id;
    this.htmlTemplate = data.html;
  }

  goToTargetOverview() {
    this.router
      .navigateByUrl(`/${ApplicationMainPageUrls.CORE}`, { skipLocationChange: true })
      .then(() => this.router.navigate([ApplicationMainPageUrls.CORE, this.targetId, 'overview']));
  }
}
