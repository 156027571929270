import { Case } from 'src/app/shared/models/case.model';
import { TargetItem } from '../../../../shared/models/target-item.model';
export class TargetsListRequest {
  colsFilters: FeatureColumnFilter[] = [];
  beforeSortBy: string;
  beforeSortByDir: number;
  afterSortBy: string;
  afterSortByDir: string;
  excludeFields: string[] = [];
}
export class CallLogsListRequestModel {
  disableCache?: boolean;
  targetIds?: string[];
  msisdns?: string[];
  id?: string;
  createdBy?: string[];
  updatedBy?: string;
  statuses?: string[];
  billingInfoId?: string;
  hasBillingInfo?: boolean;
  fields?: string[];
  sortBy?: { [key: string]: -1 | 1 };
  search?: string;
  limit?: number;
  offset?: number;
  targets?: TargetItem[];
  isFavorite?: boolean;
  targetAlias?: string[];
  caseNames?: string[];
  imsis?: string[];
  imeis?: string[];
  requestId: string;
}

export class CallLogsListResponseModel {
  createdBy?: string;
  targetId?: string;
  msisdns?: string[];
  uploadedBy?: string;
  uploadedAt?: Date;
  status?: string;
  createdAt?: Date;
  favorites?: any[];
  id?: string;
  isFavorite?: boolean;
  targets?: TargetItem[];
  cases?: Case[];
  imsis?: string[];
  imeis?: string[];
}

export class CLPaginatedResponse {
  paginatedResults: CallLogsListResponseModel[];
  totalCount: { count: number }[];
}

class FeatureColumnFilter {
  col: string;
  value?: string | string[];
  sortDir: number;
  search?: string;
}

export const TargetColumnsKeys: { [key: string]: string } = {
  alias: 'alias',
  telnos: 'telnos',
  countries: 'countries',
  addresses: 'addresses',
  cases: 'assigned_cases',
  status: 'expired',
  user: 'created_by',
  lastModified: 'updated_at',
  createdOn: 'created_at',
  caseName: 'case_name',
  curp: 'curp',
  names: 'names',
  emails: 'emails',
  personName: 'person.name', 
  personEmail: 'person.email',
  personCountry: 'person.country',
  personCurp: 'person.curp'
};

export const CasesColumnsKeys: { [key: string]: string } = {
  caseName: 'case_name',
  caseDescription: 'case_description',
  assignedTargets: 'assigned_targets',
  status: 'expired',
  user: 'created_by',
  lastModified: 'updated_at',
  createdOn: 'created_at',
  targetsAlias: 'targets_alias'
};

export const CallLogsColumnsKeys: { [key: string]: string } = {
  telnos: 'msisdn',
  status: 'status',
  user: 'created_by',
  lastActivity: 'updated_at',
  cases: 'cases.case_name',
  alias: 'targets.alias'
};
