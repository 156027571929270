<div class="row end-xs">
  <mat-icon class="clickable" matTooltip="{{'Close' | translate}}" (click)="onClose()">close</mat-icon>
</div>
<div class="row center-xs request-location-history-imsi-dialog">
  <div class="location-imsi-title">{{ 'Location History Request' | translate}}</div>
  <div class="col-12">
    <div>
      <div class="label">{{'Target' | translate}}:</div>
      <div class="value">{{requestData.queryArgs.telno || requestData.queryArgs.imsi}}</div>
    </div>
  </div>
</div>
<div class="row center-xs request-location-history-imsi-dialog-actions">
  <button class="cancel" type="button" (click)="onClose()" mat-raised-button>{{'Cancel' | translate}}</button>
  <button class="ok" type="button" (click)="requestReport()" mat-raised-button>{{'Send' | translate}}</button>
</div>
