<app-dialog>
  <div header>
    <span class="title">
      {{ title | translate }}
    </span>
  </div>

  <div body>
    <p>{{ message }}</p>
  </div>

  <div footer>
    <div class="btn-wrp">
      <button mat-button class="cp-btn-ghost" (click)="close()">
        {{ 'Cancel' | translate }}
      </button>

      <app-credit-cost-button
        *ngIf="!cost"
        [action]="action"
        [buttonText]="data.actionBtnLabel"
        [active]="true"
        [stylingClasses]="'cp-btn-primary cp-search-btn active'"
        (clicked)="onSuccess()"
      ></app-credit-cost-button>

      <!-- For custom actions that are not part of billing like geo locate + intel -->
      <button *ngIf="cost" mat-button class="cp-btn-primary cp-search-btn active">
        <span (click)="onSuccess()">
          <span class="btn-txt">{{ data.actionBtnLabel | translate }}</span>
          <ng-container *ngIf="cost">
            <span>|</span>
            <span class="credits">{{ cost }}</span>
          </ng-container>
        </span>
      </button>
    </div>
    <div *ngIf="data?.footerText">
      <p class="ubuntu-font note-txt">{{ data.footerText }}</p>
    </div>
  </div>
</app-dialog>
