import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { pick } from 'lodash-es';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { BehaviorSubject, interval, Subject, throwError } from 'rxjs';
import { catchError, filter, scan, take, takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Skins, Themes } from 'src/app/shared/models/skins.model';
import Swal from 'sweetalert2';
import { ApplicationStateService } from '../application/application-state.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { TargetService } from '../target/target.service';
import { TranslationService } from '../translation/translation.service';
import { AuthDataService } from './auth-data.service';
import { AuthState } from './auth-state.enum';
import { AuthResponse, ErrorData } from './dto';
import { Token } from './token';
import { AuthPayload, RedirectionRoutes } from './types';
import { formatTime } from './utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authState$: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>(AuthState.unknown);
  public isAuthenticated = this.authState$.pipe(distinctUntilChanged(), filter((state) => state === AuthState.authenticated));

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private targetService: TargetService,
    private applicationStateService: ApplicationStateService,
    private appConfigService: AppConfigService,
    private authDataService: AuthDataService,
    private translationService: TranslationService,
    protected reCaptchaV3Service: ReCaptchaV3Service,
    public dialog: MatDialog
  ) {
    if(this.token?.valid) {
      this.reload().subscribe();
    } else {
      this.authState$.next(AuthState.unauthenticated);
    }
  }

  login(authPayload: AuthPayload) {
    return this.authDataService.login(authPayload).pipe(tap((result: AuthResponse) => this.handleAuthResponse(result)));
  }

  logout(reload: boolean = false) {
    this.authDataService.logout();
    this.dialog.closeAll();
    Swal.close();
    this.localStorageService.clear();
    this.appConfigService.clearConfigValue();
    this.authState$.next(AuthState.unauthenticated);
    this.targetService.allTargetQueries.next([]);
    this.router.navigate([RedirectionRoutes.LOGIN]).then(() => {
      if (reload) {
        window.location.reload();
      }
    });
  }

  refresh() {
    return this.authDataService.refreshSession().pipe(tap((result: AuthResponse) => this.handleAuthResponse(result)));
  }

  reload() {
    return this.authDataService.getSessionData().pipe(
      tap((result: AuthResponse) => this.handleAuthResponse(result)),
      catchError((error) => {
        this.authState$.next(AuthState.unauthenticated);
        return throwError(() => error);
      })
    );
  }
  private handleAuthResponse(response: AuthResponse) {
    this.localStorageService.setSessionData(response);

    this.user.set();

    if (this.errors.count) {
      if (this.errors?.request_password_change) {
        this.authState$.next(AuthState.unauthenticated);
      }
      throw this.errors;
    }

    this.authState$.next(AuthState.authenticated);

    return this.user.current;
  }

  public getDefaultRedirectRoute() {
    if (
      this.applicationStateService.getIsMobileResolution() ||
      this.appConfigService.getConfigVariable('theme') === Themes.UNLIMITED
    ) {
      return RedirectionRoutes.DASHBOARD;
    }
    if (this.applicationStateService.getSkin() === Skins.CVTP) {
      return RedirectionRoutes.TARGETS;
    }
    return RedirectionRoutes.WEBINT;
  }

  get recaptcha() {
    const recaptchaKey = this.appConfigService.getConfigVariable('recaptchaV3SiteKey');
    return {
      isEnabled: this.appConfigService.getConfigVariable('enabledRecaptcha'),
      token: async (action: string) => {
        if (!this.recaptcha.isEnabled) {
          return;
        }

        return new Promise<string>((resolve) =>
          this.reCaptchaV3Service.execute(recaptchaKey, action, (token) => resolve(token))
        );
      },
    };
  }

  public get token() {
    return new Token(this.localStorageService.getSessionData()?.token);
  }

  public get user() {
    const data = this.localStorageService.getSessionData();
    return {
      current: data.current_user,
      set: (token: string | Token = this.token) => {
        token = new Token(token.toString());
        if (!token.valid) {
          return;
        }
        this.localStorageService.setSessionData({
          ...data,
          current_user: token.decoded,
          token: token.toString(),
        });
      },
    };
  }

  public get errors() {
    const { message, ...data } = this.localStorageService.getSessionData() ?? {};
    const errors = pick<ErrorData>(data, Object.keys(new ErrorData()));
    return {
      count: Object.keys(errors).length,
      ...errors,
      message,
    };
  }

  public showSessionExpiredAlert() {
    return Swal.fire({
      title: this.translationService.translate('Session has expired!'),
      text: this.translationService.translate('You will be redirected to login screen.'),
      icon: 'error',
      confirmButtonText: 'OK',
      timer: 6000,
      didClose: () => this.logout(),
    });
  }

  public showSessionWillExpireAlert(stop: Subject<void>, expireIn: number = 30) {
    return Swal.fire<boolean>({
      title: `${this.translationService.translate('Session will expire in')}&thinsp;<b>${formatTime(expireIn)}</b>`,
      text: this.translationService.translate(
        'Your session is about to expire click "Continue" to keep the session active.'
      ),
      confirmButtonText: this.translationService.translate('Continue'),
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      didOpen: () => {
        interval(1000)
          .pipe(
            scan((accumulator, _current) => accumulator - 1, expireIn + 1),
            take(expireIn + 1),
            takeUntil(stop)
          )
          .subscribe({
            next: (seconds: number) => {
              if (seconds <= 0) {
                return this.showSessionExpiredAlert();
              }
              const header = Swal.getTitle();
              if (header !== null) {
                const counter = header.querySelector('b');
                if (counter !== null) {
                  counter.innerText = formatTime(seconds);
                }
              }
            },
          });
      },
    });
  }
}
