import { Entity } from '../base';

export class Group extends Entity {
  public url?: string;
  public name?: string;

  // extra fields
  public username?: string;
  public owner?: string;
  public description?: string;
  public membersCount?: number;
  public groupId?: string;
}
