import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { flow, map, toPairs, zipObject, groupBy } from 'lodash-es';
import { BaseComponent } from 'src/app/base/base.component';
import { TacticalService } from 'src/app/services/tactical/tactical.service';
import { ImsiCatcherItem } from 'src/app/shared/models/imsi-catcher-item.model';

@Component({
  selector: 'app-ic-log-table',
  templateUrl: './ic-log-table.component.html',
  styleUrls: ['./ic-log-table.component.scss']
})
export class IcLogTableComponent extends BaseComponent implements OnInit, OnChanges {
  imsiCatcherData: ImsiCatcherItem[];
  filteredImsiCatcherData: ImsiCatcherItem[] = [];
  paginatedImsiCatcherData: ImsiCatcherItem[] = [];
  checked;
  groupBy;
  groupByView = false;
  groupedData = [];
  loader: boolean;

  paginator = {
    pageSize: 6,
    currentPage: 0,
    totalSize: 0
  };

  sorterSettings = {
    field: undefined,
    sortOrder: 1
  };

  groupByFields = [
    {
      label: 'MAC',
      value: 'mac'
    },
    {
      label: 'IMSI',
      value: 'imsi'
    },
    {
      label: 'Vendor',
      value: 'vendor'
    },
  ];

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  @Input() fullScreen;

  @Output() emittedSelectedImsiCatcher = new EventEmitter<ImsiCatcherItem>();
  @Output() emittedMultiselectImsiCatcher = new EventEmitter<{ imsiCatcher: ImsiCatcherItem, new: boolean }>();
  @Output() refreshMap = new EventEmitter <boolean>();

  constructor(
    private tacticalService: TacticalService,
  ) {
    super();
  }

  ngOnInit() {
    this.loader = false;
    const imsiCatcherDataSubscription = this.tacticalService.imsiCatcherData.subscribe(
      (data: ImsiCatcherItem[]) => {
        this.imsiCatcherData = data;
        this.paginator.currentPage = 0;
        this._iteratePaginator();
      }
    );

    const getLocationQueryLoader = this.tacticalService.tacticalLoader.subscribe(
      (loader) => {
        this.loader = loader;
      }
    );
    this.subscriptions.push(imsiCatcherDataSubscription, getLocationQueryLoader);
    this.tacticalService.getAllImsiCatcherData().subscribe();
    this.tacticalService.clearSelectedImsiCatcher();
  }

  ngOnChanges() {
    if (this.fullScreen) {
      this.paginator.pageSize = 13;
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    } else {
      this.paginator.pageSize = 6;
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    }
  }

  onSelectedImsiCatcher(imsiCatcher: ImsiCatcherItem) {
    this.emittedSelectedImsiCatcher.emit(imsiCatcher);
  }

  onMultiselectConversation(data: { imsiCatcher: ImsiCatcherItem, checked: boolean }) {
    this.emittedMultiselectImsiCatcher.emit({ imsiCatcher: data.imsiCatcher, new: data.checked ? true : false});
  }


  refreshImsiCatcherData() {
    this.tacticalService.getAllImsiCatcherData().subscribe();
    this.tacticalService.clearSelectedImsiCatcher();
    this.groupByView = false;
    this.groupBy = null;
    this.refreshMap.emit(true);
  }

  onPaginatePageChange(event) {
    this.paginator.currentPage = event.pageIndex;
    this._iteratePaginator();
  }

  public _iteratePaginator() {
    if (this.imsiCatcherData) {
      const imsiCatcherData = this.searchInput.nativeElement.value ? this.filteredImsiCatcherData : this.imsiCatcherData;
      this.paginator.totalSize = imsiCatcherData.length;
      this.paginatedImsiCatcherData = imsiCatcherData.slice(
        this.paginator.currentPage * this.paginator.pageSize,
        (this.paginator.currentPage + 1) * this.paginator.pageSize
      );
    }
  }

  onKeyUp(event) {
    if (this.imsiCatcherData) {
      this.filteredImsiCatcherData.length = 0;
      this.paginatedImsiCatcherData.length = 0;
      const filterValue = this.searchInput.nativeElement.value.toLowerCase();
      for (const imsiCatcher of this.imsiCatcherData) {
        for (const key of Object.keys(imsiCatcher)) {
          if (imsiCatcher[key] && imsiCatcher[key].toString().toLowerCase().includes(filterValue)) {
            this.filteredImsiCatcherData.push(imsiCatcher);
            break;
          }
        }
      }
      this.paginator.currentPage = 0;
      this._iteratePaginator();
    }
  }

  sort(field) {
    this.sorterSettings.sortOrder = field === this.sorterSettings.field ? this.sorterSettings.sortOrder *= -1 : 1;
    this.sorterSettings.field = field;
    this.imsiCatcherData.sort(this.arraySort(field, this.sorterSettings.sortOrder));
    this.filteredImsiCatcherData.sort(this.arraySort(field, this.sorterSettings.sortOrder));
    this._iteratePaginator();
  }

  arraySort(property, sortOrder) {
    return function (a, b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  // multiselect all the entries. Currently not used. If we want to add this functionality for the future
  // we need to reimplemented it to work with the module map.
  // multiselect(event) {
  //   this.checked = event.checked;
  //   if (event.checked) {
  //     for (const imsiCatcher of this.imsiCatcherData) {
  //       // constract the object that the map function requires.
  //       // TODO: fix the map function to accept a general type object
  //       const markerData = {
  //         location: [imsiCatcher.location.coordinates.longitude, imsiCatcher.location.coordinates.latitude],
  //         queryArgs: {
  //           imsi: imsiCatcher.imsi
  //         },
  //         id: imsiCatcher.id
  //       };
  //       this.mapService.addToMultipins(markerData);
  //     }
  //   } else {
  //     this.mapService.refreshMap();
  //   }
  // }

  filterView() {
    this.groupByView = true;
    this.groupedData = this.groupByFilter(this.imsiCatcherData, this.groupBy.toString(), 'groupName', 'items');
  }

  groupByFilter(dataToGroupOn, fieldNameToGroupOn, fieldNameForGroupName, fieldNameForChildren) {
    return flow(
      (value) => groupBy(value, fieldNameToGroupOn),
      toPairs,
      (value) => map(value, curVal => zipObject([fieldNameForGroupName, fieldNameForChildren], curVal))
    )(dataToGroupOn);
  }

  getGroupHeight() {
    return this.fullScreen ? '50vh' : '18vh';
  }

}

