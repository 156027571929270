import { Injectable } from '@angular/core';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { BalanceType } from 'src/app/shared/models/billing-action.model';
import { AvailablePool, CreditPools, PoolFeaturesLabelsMap } from 'src/app/shared/models/credit-pools.model';
import { CreditBarTooltip } from '../components/credit-bar-tooltip/credit-bar-tooltip.component';
import { CreditUsageBar } from '../components/credit-usage-bar/credit-usage-bar.component';
import { BulletColor, CreditUsageBullet } from '../components/credit-usage-bullets/credit-usage-bullets.component';
import { CreditDetails, CreditDetailTitles, CreditUsage, InitDetailsResult } from '../models/credit-details.model';

@Injectable({
  providedIn: 'root',
})
export class InitDetailsService {
  constructor(private userBillingService: UserBillingService) {}

  public buildUserInfoByBalanceType(creditDetails: CreditDetails): InitDetailsResult {
    if (BalanceType.DISTRIBUTED === creditDetails.balanceType) {
      return this.buildUserDistributedInfo(creditDetails);
    }
    if (BalanceType.SINGLE === creditDetails.balanceType) {
      return this.buildUserSingleInfo(creditDetails);
    }
  }

  private buildUserSingleInfo(creditDetails: CreditDetails): InitDetailsResult {
    const from = Math.max(creditDetails.unassignCredits as number, creditDetails.currentCredits as number);
    const used = creditDetails.currentCredits as number;
    const usage = from - used;

    const bullets: CreditUsageBullet[] = [
      {
        title: !!creditDetails.limitType ? `${creditDetails.limitType} quota assigned` : 'Quota assigned',
        value: creditDetails.unassignCredits as number,
        color: BulletColor.BLUE,
      },
    ];

    const creditsUsage: CreditUsage[] = [
      {
        poolName: null,
        usage,
        from,
        limitType: creditDetails.limitType,
      },
    ];

    return {
      bulletsInfo: bullets,
      tooltipInfo: {
        title: CreditDetailTitles.USER,
        creditsUsage,
        positionX: null,
        positionY: null,
      } as CreditBarTooltip,
      barInfo: {
        title: CreditDetailTitles.USER,
        endVal: 0,
        startVal: usage,
        totalVal: usage,
        lengthBar: creditDetails.unassignCredits as number,
      } as CreditUsageBar,
    };
  }

  private buildUserDistributedInfo(creditDetails: CreditDetails): InitDetailsResult {
    const unassignCredits = creditDetails.unassignCredits as CreditPools;
    const bullets: CreditUsageBullet[] = [
      {
        title: creditDetails?.limitType ? `${creditDetails.limitType} quota assigned` : 'Quota assigned',
        value: unassignCredits.total,
        color: BulletColor.BLUE,
      },
    ];

    const creditsUsage: CreditUsage[] = this.userBillingService
      .getAvailablePools()
      .map((availablePool: AvailablePool) => availablePool.value)
      .filter((pool) => creditDetails.unassignCredits[pool] > 0)
      .map((pool) => {
        const from = Math.max(creditDetails.unassignCredits[pool], creditDetails.currentCredits[pool]);
        const used = creditDetails.currentCredits[pool] as number;

        const usage = from - used;
        return  {
          poolName: PoolFeaturesLabelsMap[pool],
          usage,
          from,
          limitType: creditDetails.limitType,
      }}
    );

    const totalCreditUsage: number = creditsUsage.reduce((acc, curr: CreditUsage) => acc + curr.usage, 0);

    return {
      bulletsInfo: bullets,
      tooltipInfo: {
        title: CreditDetailTitles.USER,
        creditsUsage,
        positionX: null,
        positionY: null,
      } as CreditBarTooltip,
      barInfo: {
        title: CreditDetailTitles.USER,
        endVal: 0,
        startVal: totalCreditUsage,
        totalVal: totalCreditUsage,
        lengthBar: creditsUsage.reduce((acc, curr: CreditUsage) => acc + curr.from, 0),
      } as CreditUsageBar,
    };
  }

  public buildTenantInfoByBalanceType(creditDetails: CreditDetails): InitDetailsResult {
    let creditsUsage: CreditUsage[] = [];
    let creditsAssigned: number,
      creditsAvailableToAssign: number,
      lengthBar: number,
      startVal: number,
      totalVal: number = 0;

    if (BalanceType.DISTRIBUTED === creditDetails.balanceType) {
      ({ creditsUsage, creditsAssigned, creditsAvailableToAssign, startVal, totalVal, lengthBar } =
        this.buildTenantDistributedInfo(creditDetails));
    }

    if (BalanceType.SINGLE === creditDetails.balanceType) {
      ({ creditsUsage, creditsAssigned, creditsAvailableToAssign, startVal, totalVal, lengthBar } =
        this.buildTenantSingleInfo(creditDetails));
    }

    const bullets: CreditUsageBullet[] = [
      {
        title: 'Quota assigned',
        value: creditsAssigned,
        color: BulletColor.BLUE,
      },
      {
        title: 'Available to assign',
        value: creditsAvailableToAssign,
        color: BulletColor.GRAY,
      },
    ];

    return {
      bulletsInfo: bullets,
      tooltipInfo: {
        title: CreditDetailTitles.TENANT,
        creditsUsage,
        positionX: null,
        positionY: null,
      } as CreditBarTooltip,
      barInfo: {
        title: CreditDetailTitles.TENANT,
        endVal: 0,
        startVal,
        totalVal,
        lengthBar,
      } as CreditUsageBar,
    };
  }

  private buildTenantDistributedInfo(creditDetails: CreditDetails) {
    const unassignCredits = creditDetails.unassignCredits as CreditPools;
    const currentCredits = creditDetails.currentCredits as CreditPools;
    const creditsUsage = this.userBillingService
      .getAvailablePools()
      .map((availablePool: AvailablePool) => availablePool.value)
      .map((pool) => ({
        poolName: PoolFeaturesLabelsMap[pool],
        usage: currentCredits[pool] - unassignCredits[pool],
        from: currentCredits[pool],
        limitType: creditDetails.limitType,
      }));

    return {
      creditsUsage,
      creditsAssigned: currentCredits.total - unassignCredits.total,
      creditsAvailableToAssign: unassignCredits.total,
      startVal: creditsUsage.reduce((acc, curr: CreditUsage) => acc + curr.usage, 0),
      totalVal: currentCredits.total,
      lengthBar: currentCredits.total,
    };
  }

  private buildTenantSingleInfo(creditDetails: CreditDetails) {
    const unassignCredits = creditDetails.unassignCredits as number;
    const currentCredits = creditDetails.currentCredits as number;
    const creditsUsage = [
      {
        poolName: null,
        usage: currentCredits - unassignCredits,
        from: currentCredits,
        limitType: creditDetails.limitType,
      },
    ];

    return {
      creditsUsage,
      creditsAssigned: currentCredits - unassignCredits,
      creditsAvailableToAssign: unassignCredits,
      startVal: currentCredits - unassignCredits,
      totalVal: currentCredits,
      lengthBar: currentCredits,
    };
  }
}
