export enum FeatureId {
  INVESTIGATION = 'investigation'
}

export enum FeatureAttribute {
  STATE = 'state'
}

export enum InvestigationUserState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  CLOSED = 'CLOSED'
}

export interface UserFeatures {
  createdBy: string;
  featureAttribute: string;
  featureId: string;
  featureValue: string;
  updatedAt: string;
}
