import { Pipe, PipeTransform } from '@angular/core';
import { InvestigationDataSourceType } from 'src/app/modules/analysis/shared/models/case-investigations.model';

@Pipe({
  name: 'visualTimelineEventType'
})
export class VisualTimelineEventTypePipe implements PipeTransform {
  textTransform = {
    [InvestigationDataSourceType.data]: 'Data',
    [InvestigationDataSourceType.sms]: 'Sms',
    [InvestigationDataSourceType.voice]: 'Voice call',
    [InvestigationDataSourceType.checkin]: 'Checkin',
    [InvestigationDataSourceType.photo]: 'Photo',
    [InvestigationDataSourceType.post]: 'Post',
    [InvestigationDataSourceType.geo_query]: 'Geolocation'
  };

  transform(value: InvestigationDataSourceType): unknown {
    return this.textTransform[value] || value;
  }
}
