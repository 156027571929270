import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconTextButtonComponent } from "./icon-text-button/icon-text-button.component";
import { StateIndicatorComponent } from "./state-indicator/state-indicator.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionHeaderComponent } from './accordion/accordion-header/accordion-header.component';
import { AccordionBodyComponent } from './accordion/accordion-content/accordion-body.component';
import { DrawerComponent } from './drawer/drawer.component';
import { DrawerService } from './drawer/drawer.service';

@NgModule({
  declarations: [
    IconTextButtonComponent,
    StateIndicatorComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    DrawerComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    IconTextButtonComponent,
    StateIndicatorComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    DrawerComponent
  ],
  providers: [
    DrawerService
  ]
})
export class SharedUiIntellectusModule {}
