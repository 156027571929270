<div class="target-form-dialog">
  <img
    class="clickable profiler-target-form-close"
    matTooltip="{{ 'Close' | translate }}"
    (click)="onClose()"
    src="assets/static/images/close-grey.svg"
    height="16"
  />
  <div class="profiler-target-form-header">{{ title | translate }}</div>
  <div class="profiler-target-form-up-separation"></div>
  <div mat-dialog-content>
    <form [formGroup]="createTargetForm" class="target-form">
      <div class="row center-xs">
        <div class="upload-img">
          <span class="edit-badge">
            <img src="assets/static/images/draw.svg" (click)="fileUpload.click()" />
          </span>
          <img [src]="avatarUrl" height="100" width="100" />
          <input type="file" (change)="onAvatarUpload($event)" #fileUpload />
        </div>
      </div>

      <div *ngIf="!editMode" class="row start-xs create-case-container">
        <mat-checkbox
          class="create-case-checkbox"
          [ngModel]="createCase"
          (change)="createCaseState($event)"
          [ngModelOptions]="{ standalone: true }"
        >
          {{ "Create a case with the target's alias" | translate }}.
        </mat-checkbox>
      </div>

      <div class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">
          Alias
          <span
            matTooltip="{{
              'Must be at least 3 characters long, special charactes and preceding or following spaces are not allowed'
                | translate
            }}"
            class="warn-text"
            >*</span
          >
        </div>
        <input
          class="global-input profiler-target-form-input"
          formControlName="alias"
          maxlength="20"
          minlength="3"
          matInput
          required
        />
        <div
          *ngIf="createTargetForm.get('alias').touched && createTargetForm.get('alias').invalid"
          class="error-message"
        >
          <p *ngIf="createTargetForm.get('alias').errors.minlength">
            ** {{ 'Please enter 3 or more characters' | translate }}
          </p>
          <p *ngIf="createTargetForm.get('alias').errors.pattern">
            ** {{ 'Special characters and trailing spaces are not allowed' | translate }}
          </p>
        </div>
      </div>

      <div class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">{{ 'Name' | translate }}</div>
        <input
          class="global-input profiler-target-form-input"
          formControlName="names"
          maxlength="255"
          matInput
        />
      </div>

      <div class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">{{ 'MSISDN' | translate }}</div>
        <div class="profiler-target-form-input values-chip-list">
          <mat-chip-list #telnosList>
            <mat-chip
              class="my-list-chip"
              [class.invalid-value]="!editValidationPhone(telno) || !isValueUnique('telnos', telno)"
              *ngFor="let telno of currentTarget.telnos"
              removable
              (removed)="onRemoveTelno(telno)"
            >
              <span [class.deleted-value]="wasDeleted(telno, 'telnos')">{{ telno }}</span>
              <mat-icon *ngIf="!wasDeleted(telno, 'telnos')" matChipRemove>cancel</mat-icon>
              <mat-icon *ngIf="wasDeleted(telno, 'telnos')" matChipRemove>add_circle</mat-icon>
            </mat-chip>
            <input
              class="chip-list-input"
              [matChipInputAddOnBlur]="true"
              [matChipInputFor]="telnosList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addTelno($event)"
              (keypress)="preventUserFromAddingCharacters($event, allowedCharacters['telno'])"
            />
          </mat-chip-list>
        </div>
      </div>

      <div *ngIf="enableAdInt" class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">{{ 'Ad IDs' | translate }}</div>
        <div class="profiler-target-form-input values-chip-list">
          <mat-chip-list #adidList>
            <mat-chip
              class="my-list-chip"
              [class.invalid-value]="!editValidationAdId(adid)"
              *ngFor="let adid of currentTarget.ifas"
              removable
              (removed)="onRemoveAdId(adid)"
            >
              {{ adid }}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              class="chip-list-input"
              [matChipInputAddOnBlur]="true"
              [matChipInputFor]="adidList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addAdId($event)"
            />
          </mat-chip-list>
        </div>
      </div>

      <div class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">{{ 'ID' | translate }}</div>
        <input
          class="global-input profiler-target-form-input"
          formControlName="nationalId"
          maxlength="50"
          minlength="3"
          matInput
        />
      </div>

      <div class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">IMSI</div>
        <div class="profiler-target-form-input values-chip-list">
          <mat-chip-list #imsiList>
            <mat-chip
              class="my-list-chip"
              [class.invalid-value]="!editValidationImsi(imsi)"
              *ngFor="let imsi of currentTarget.imsis"
              removable
              (removed)="onRemoveImsi(imsi)"
            >
              {{ imsi }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              (wheel)="$event.preventDefault()"
              (keypress)="preventUserFromAddingCharacters($event, allowedCharacters['number'])"
              class="chip-list-input"
              [matChipInputAddOnBlur]="true"
              [matChipInputFor]="imsiList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addImsi($event)"
            />
          </mat-chip-list>
        </div>
      </div>

      <div class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">IMEI</div>
        <input
          (wheel)="$event.preventDefault()"
          (keypress)="preventUserFromAddingCharacters($event, allowedCharacters['number'])"
          class="global-input profiler-target-form-input"
          formControlName="imeis"
          matInput
          autocomplete="off"
        />
        <mat-error class="errorMsg" *ngIf="errorMsgForImei">{{
          'Enter a valid IMEI' | translate
        }}</mat-error>
      </div>

      <div class="profiler-target-form-input-group">
        <div class="inline">
          <div class="profiler-target-form-label">{{ 'Select country' | translate }}</div>

          <div class="profiler-target-form-input values-chip-list countries-chip-list">
            <mat-chip-list #countryList>
              <mat-chip
                class="my-list-chip"
                *ngFor="let country of currentTarget.countries"
                removable
                (removed)="onRemoveCountry(country)"
              >
                {{ country }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                class="chip-list-input"
                #countryInput
                [matAutocomplete]="autoCountries"
                [matChipInputAddOnBlur]="true"
                [matChipInputFor]="countryList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-list>
            <div
              class="inline-flex flag-country align-items-center"
              *ngIf="currentTarget.countries && currentTarget.countries.length === 1"
            >
              <span class="iti__flag iti__{{ countryCode | lowercase }}"></span>
            </div>
          </div>

          <mat-autocomplete
            #autoCountries="matAutocomplete"
            (optionSelected)="countryValidation($event)"
          >
            <mat-option
              *ngFor="let country of filteredCountries | async"
              [value]="country.countryName"
            >
              <span class="iti__flag iti__{{ country.countryCode | lowercase }}"></span>
              <span>{{ country.countryName }}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <div class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">{{ 'Select timezone' | translate }}</div>
        <mat-select class="global-select profiler-target-form-select" formControlName="timeZone">
          <mat-option *ngFor="let zone of timezones" [value]="zone.label">
            {{ zone.label }}
          </mat-option>
        </mat-select>
      </div>

      <div *ngIf="isAdmin || isSupport || isPower" class="profiler-target-form-input-group">
        <div class="profiler-target-form-label">
          {{ 'Select user' | translate }}
          <span class="warn-text">*</span>
        </div>
        <mat-select class="global-select profiler-target-form-select" formControlName="user">
          <mat-option *ngFor="let username of usernames" [value]="username">
            {{ username }}
          </mat-option>
        </mat-select>
      </div>

      <mat-error class="row center-xs errorMsg" *ngIf="errorMsgForImage">
        {{ 'Maximum image size is 2MB.' | translate }}<br />
        {{ 'Supported file types' | translate }}: jpeg, jpg, png, svg, gif
      </mat-error>
    </form>
  </div>

  <div class="profiler-target-form-down-separation"></div>

  <div class="row">
    <div class="col-12 end-xs">
      <button
        mat-raised-button
        class="profiler-target-form-cancel"
        (click)="onClose()"
        type="button"
      >
        {{ 'Cancel' | translate }}
      </button>
      <button
        mat-raised-button
        class="profiler-target-form-save"
        [disabled]="createTargetForm.invalid"
        type="submit"
        (click)="onSubmit()"
        angulartics2On="click"
        [angularticsAction]="matomo.actions.createNewTarget"
        [angularticsCategory]="matomo.categories.caseAndTargets"
      >
        {{ submitButtonText | translate }}
      </button>
    </div>
  </div>
</div>
