import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Link, Node } from '@trg-ui/link-analysis';
import { BackgroundJob } from 'datalayer/models/background-jobs/background-job.model';
import { Profile } from 'datalayer/models/social/profile';
import { ProfileService } from 'datalayer/services/social/profile/profile.service';
import { map } from 'rxjs/operators';
import { DataSource, EntityRelationType, EntityType, Gender } from 'src/app/modules/data-layer/models/platform-models';
import { Person } from 'src/app/modules/data-layer/models/platform-models/person/person';
import { RequestOptions } from 'src/app/modules/data-layer/services/base';
import { OsintService } from 'src/app/modules/data-layer/services/osint/osint.service';
import { PersonService } from 'src/app/modules/data-layer/services/person/person/person.service';
import { ProfileService as SocialProfilesService } from 'src/app/modules/data-layer/services/social/profile/profile.service';
import { LinkAnalysisDataService } from 'src/app/modules/link-analysis/services/link-analysis-data.service';
import { LinkAnalysisService } from 'src/app/modules/link-analysis/services/link-analysis.service';
import {
  LabelSize,
  LIGHTER_LINK_COLOR,
  linkTypes,
  nodeSize,
  nodeTypes,
  nodeTypesColors,
  relationTypes
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
import { TargetDialogComponent } from 'src/app/modules/search-intel/components/target-dialog/target-dialog.component';
import { TargetDialogResult } from 'src/app/modules/search-intel/models/target-dialog-result.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { ImageService } from 'src/app/services/image/image.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { CostInfoActionDialogComponent } from 'src/app/shared/components/cost-info-action-dialog/cost-info-action-dialog.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { CostInfoActionDialogModel } from 'src/app/shared/models/cost-info-action-dialog.model';
import { Themes } from 'src/app/shared/models/skins.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { environment } from 'src/environments/environment';
import { WebintDisabledService } from './../../../../shared/components/webint-disabled-modal/webint-disabled.service';

@Component({
  selector: 'app-node-details',
  templateUrl: './node-details.component.html',
  styleUrls: ['./node-details.component.scss']
})
export class NodeDetailsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() node: Node;
  @Input() target: TargetItem;
  @Input() caseView: boolean;
  @Output() newGraphItems = new EventEmitter<(Node | Link)[]>();
  @Output() updateNodeData = new EventEmitter<{ node: Node; socialResult: Person }>();
  @Input() playgroundView: boolean;
  @Output() showAnimation = new EventEmitter<boolean>();
  @Output() queryPerformed = new EventEmitter<string>();
  @Output() updateNodeParent = new EventEmitter<{ nodeId: string; updateParentTo: string | null }>();
  @Input() selectedNodeOsintCompleted: boolean;

  platformTypes = relationTypes;
  socialSearchResults: Person[];
  loader = false;
  noSocialResultsMessage = false;
  facebookId: string;
  mutualFriends = [];
  theme: Themes;
  showOsintButton = false;
  hideAdvancedOsintButton: boolean = true;

  constructor(
    public dialog: MatDialog,
    private linkAnalysisService: LinkAnalysisService,
    private linkAnalysisDataService: LinkAnalysisDataService,
    private translationService: TranslationService,
    private osintService: OsintService,
    private socialProfilesService: SocialProfilesService,
    private personService: PersonService,
    private imageService: ImageService,
    private router: Router,
    private profileService: ProfileService,
    private appConfigService: AppConfigService,
    private targetService: TargetService,
    private webintDisabledService: WebintDisabledService,
    private userBillingService: UserBillingService
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    if (!this.caseView && !this.playgroundView) {
      if (this.node.d.type === nodeTypes.SOCIAL_PROFILE && this.node.d.relation === relationTypes.FACEBOOK) {
        this.getFacebookId();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.node && changes.node.currentValue) {
      this.fetchBackgroundJobs();
      this.socialSearchResults = [];
      this.loader = false;
      this.noSocialResultsMessage = false;
      if (this.node.d.socialSearchResult) {
        this.socialSearchResults[0] = this.node.d.socialSearchResult;
      }
      // targetNodes ex: target msisdns nodes, target person node
      // we will show all the data of the target for these nodes
      if (this.node.d.isTargetNode && !this.caseView) {
        this.socialSearchResults[0] = this.getTargetSocialSearchData(this.target);
      }
      this.showOsintButton = this.showAnalyseButton();
      if (this.node.d?.alias && this.node.d?.targetId) {
        this.getTargetData(this.node.d.targetId);
      }
    }
    if (changes.selectedNodeOsintCompleted?.currentValue) {
      this.getOsintData();
    }
  }

  public openTargetDialog(): void {
    const target = this.getNewTargetData();
    this.dialog
      .open(TargetDialogComponent, {
        width: '530px',
        data: { seedName: target.names, seedCount: Object.keys(target).length, newTarget: target },
        autoFocus: false
      })
      .afterClosed()
      .subscribe((dialogResult: TargetDialogResult) => {
        dialogResult?.targets.forEach((targetItem: TargetItem) => {
          if (targetItem.id) {
            this.moveOsintDataToNewTarget(targetItem.id);
          }
        });
      });
  }

  private moveOsintDataToNewTarget(targetId: string) {
    this.subscriptions.push(
      this.linkAnalysisDataService
        .moveOsintDataToNewTarget(`${this.target.id}___${this.node.id}`, targetId.toString())
        .subscribe(() => {
          this.router.navigate(['targets', targetId, 'overview']);
        })
    );
  }

  private getNewTargetData(): TargetItem {
    const target: TargetItem = {};
    if (this.node.d.image) {
      target.photos = [this.node.d.image];
    }
    if (this.socialSearchResults[0]) {
      target.names = this.socialSearchResults[0].names;
      target.telnos = this.socialSearchResults[0].telnos;
      target.gender = this.socialSearchResults[0].gender;
      target.emails = this.socialSearchResults[0].emails;
      target.usernames = this.socialSearchResults[0].usernames;
    }
    if (this.node.d.type === nodeTypes.SOCIAL_PROFILE) {
      target.names = target.names && target.names.length ? target.names : this.node.d.label ? [this.node.d.label] : [];
      target.socialProfiles = [
        {
          platform: this.node.d.relation,
          link: this.node.d.url,
          userId: this.node.id
        }
      ];
    } else if (this.node.d.type === nodeTypes.MSISDN) {
      target.telnos = target.telnos && target.telnos.length ? target.telnos : [this.node.id];
    }
    return target;
  }

  advancedOsint() {
    if (!this.userBillingService.userHasEnoughCredits([BillingActions.ADVANCED_OSINT])) {
      return;
    }

    this.queryPerformed.emit(this.node.id);
    this.loader = true;
    const searchArgs = [];

    if (this.node.d.type === nodeTypes.MSISDN) {
      searchArgs.push({ arg_type: 'telno', arg_value: this.node.id });
    }

    if (this.node.d.type === nodeTypes.SOCIAL_PROFILE) {
      switch (this.node.d.relation) {
        case relationTypes.TWITTER:
          searchArgs.push({ arg_type: 'userId', arg_value: this.node.d.username });
          break;
        case relationTypes.LINKEDIN:
          searchArgs.push({ arg_type: 'username', arg_value: this.node.d.username });
          searchArgs.push({ arg_type: 'alias', arg_value: this.node.d.username });
          searchArgs.push({ arg_type: 'url', arg_value: this.node.d.url });
          break;
        case relationTypes.FACEBOOK:
        case relationTypes.FAMILY:
        case relationTypes.INSTAGRAM:
          searchArgs.push({ arg_type: 'userId', arg_value: this.node.id });
          break;
        default:
          break;
      }
    }

    const args = {
      query_args: searchArgs,
      target_id: `${this.target.id}___${this.node.id}`
    };

    if (this.node.parentId) {
      this.updateNodeParent.emit({ nodeId: this.node.id, updateParentTo: null });
    }

    this.osintService.advancedOsint(args).subscribe(
      () => {},
      (error: Error) => {
        this.showMessage(this.translationService.translate(error.message));
      }
    );
  }

  public openAdvancedOsintDialog() {
    if(!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }

    const osintDialog = this.dialog.open(CostInfoActionDialogComponent, {
      data: <CostInfoActionDialogModel>{
        title: 'Advanced Webint',
        message: `${this.translationService.translate(
          'Create a new target and find possible associates, visited places, connections on social media, groups and more.'
        )} ${this.translationService.translate('Credits will be deducted only if accurate information is found.')}`,
        action: BillingActions.ADVANCED_OSINT,
        actionBtnLabel: 'Advanced Webint',
        footerText: ''
      },
      panelClass: 'renew-credit'
    });

    this.subscriptions.push(
      osintDialog.afterClosed().subscribe(action => {
        if (action?.key === 'success') {
          this.advancedOsint();
        }
      })
    );
  }

  public getMutualFriends() {
    this.loader = true;
    this.showAnimation.emit(true);
    if (this.node.parentId) {
      this.updateNodeParent.emit({ nodeId: this.node.id, updateParentTo: null });
    }
    this.linkAnalysisDataService.getMutualFriends(this.facebookId, this.node.id).subscribe(data => {
      if (!data || !data.length) {
        this.loader = false;
        this.showAnimation.emit(false);
        this.showMessage(
          this.translationService.translate('We were not able to bring mutual facebook friends at the moment.')
        );
        return;
      }
      const friends = data[0].children[0].children;
      const friendGraphItems = this.getFacebookFriendGraphItems(friends);
      this.newGraphItems.emit(friendGraphItems);
      this.loader = false;
      this.showAnimation.emit(false);
    });
  }

  public redirectToTargetProfile(id: string) {
    this.router.navigate(['targets', id, 'overview']);
  }

  private getFacebookFriendGraphItems(
    friends: {
      type: 'facebook-friend';
      children: any[];
      payload: { id: string; platform: string; url: string; userId: string };
    }[]
  ): (Node | Link)[] {
    const friendGraphItems: (Node | Link)[] = [];
    friends.forEach(rawFriend => {
      const moreData = rawFriend.children.filter(c => c.type === 'image');
      const node = this.linkAnalysisService.createNewNode(
        `${rawFriend.payload.userId}@facebook`,
        nodeTypesColors.SOCIAL_PROFILE,
        moreData[0].payload.author.name,
        {
          type: nodeTypes.SOCIAL_PROFILE,
          image: environment.serverAPIUri + moreData[0].payload.url,
          parent: this.node.id,
          relation: relationTypes.FACEBOOK,
          label: moreData[0].payload.author.name,
          username: rawFriend.payload.userId,
          url: rawFriend.payload.url
        },
        nodeSize.XSMALL,
        [this.linkAnalysisService.getGlyph(`link-analysis/facebook.svg`, 'e')],
        LabelSize.MEDIUM
      );
      const link: Link = this.linkAnalysisService.getLink(
        this.node.id,
        node.id,
        linkTypes.FACEBOOK_FRIEND,
        true,
        LIGHTER_LINK_COLOR,
        moreData[0].payload.author.name
      );
      friendGraphItems.push(node, link);
    });
    return friendGraphItems;
  }

  private getFacebookId() {
    const filters: RequestOptions = {
      filters: {
        source: [DataSource.Facebook],
        targetId: this.target.id,
        type: EntityType.Profile,
        relationType: [EntityRelationType.Plain]
      }
    };
    this.subscriptions.push(
      this.socialProfilesService
        .getAll(filters)
        .pipe(map(result => Object.values(result)))
        .subscribe((profiles: Profile[]) => {
          if (profiles && profiles.length) {
            this.facebookId = profiles[0].profileId;
          }
        })
    );
  }

  public copyToClipboard(value: string): void {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', value);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.showMessage(this.translationService.translate('Copied!'));
  }

  private getTargetSocialSearchData(target: TargetItem): Person {
    const { addresses, countries, emails, gender, names, telnos, userIds, usernames, socialProfiles } = target;
    const parsedAddresses = [];
    const parsedProfileUrls = [];
    const parsedGender: Gender = gender
      ? gender.toLocaleLowerCase() === 'female'
        ? Gender.Female
        : Gender.Male
      : null;
    if (addresses.length) {
      addresses.forEach(address => {
        parsedAddresses.push({ display: address });
      });
    }
    if (socialProfiles.length) {
      socialProfiles.forEach(profile => {
        parsedProfileUrls.push(profile.link);
      });
    }
    return {
      addresses: parsedAddresses,
      originCountries: countries || [],
      emails: emails || [],
      gender: parsedGender,
      names: names || [],
      telnos: telnos || [],
      userIds: userIds || [],
      usernames: usernames || [],
      urls: parsedProfileUrls || [],
      type: EntityType.Person,
      source: DataSource.SocialSearch,
      createdAt: new Date() // no use for this: just adding because is required in the model
    };
  }

  public getLinkedinCompanyEmployees() {
    this.showAnimation.emit(true);
    this.loader = true;
    if (this.node.parentId) {
      this.updateNodeParent.emit({ nodeId: this.node.id, updateParentTo: null });
    }
    this.linkAnalysisDataService.getLinkedinCompanyEmployees(this.node.d.companyId).subscribe(data => {
      if (data.length) {
        const employeesNodes: (Node | Link)[] = [];
        data[0].children.forEach(person => {
          const profile = person.children.filter(item => item.type === 'image');
          if (profile.length) {
            const { name, url } = profile[0].payload.author;
            const index = url.indexOf('in/');
            const id = url.substr(index + 3, url.length).replace('/', '');
            const node = this.linkAnalysisService.createNewNode(
              `${id}@linkedin`,
              nodeTypesColors.SOCIAL_PROFILE,
              name,
              {
                type: nodeTypes.SOCIAL_PROFILE,
                image: this.imageService.getPhotoUrl(profile[0].payload.thumbnailUrl) as string,
                parent: this.node.id,
                label: name,
                url: url,
                username: id,
                relation: relationTypes.LINKEDIN
              },
              nodeSize.XSMALL,
              [this.linkAnalysisService.getGlyph(`link-analysis/linkedin.svg`, 'e')],
              LabelSize.MEDIUM
            );
            node.parentId = this.node.id;
            const link: Link = this.linkAnalysisService.getLink(
              this.node.id,
              node.id,
              linkTypes.LINKEDIN_EMPLOYEE,
              false,
              LIGHTER_LINK_COLOR
            );
            employeesNodes.push(node, link);
          }
        });

        this.newGraphItems.emit(employeesNodes);
        this.loader = false;
        this.showAnimation.emit(false);
      } else {
        this.loader = false;
        this.showAnimation.emit(false);
        this.showMessage(
          this.translationService.translate('We were not able to bring company employees at the moment.')
        );
      }
    });
  }

  public showMutualFriendsButton(): boolean {
    return (
      this.node.d.relation === 'facebook' &&
      this.facebookId &&
      !this.node.d.isTargetNode &&
      this.node.d.type !== nodeTypes.FACEBOOK_GROUP
    );
  }

  private createSocialSearchNodes(person: Person) {
    const graphItems: (Node | Link)[] = [];
    const personName = person.names && person.names.length ? person.names[0] : null;
    if (person.telnos.length) {
      this.socialSearchResults[0].telnos.forEach(telno => {
        const parsedTelno = telno.trim()[0] === '+' ? telno.trim() : `+${telno.trim()}`;
        if (this.node.id.includes(telno)) {
          return;
        }
        const node = this.linkAnalysisService.createNewNode(
          parsedTelno,
          nodeTypesColors.MSISDN,
          parsedTelno,
          {
            type: nodeTypes.MSISDN,
            parent: personName || this.node.id,
            label: parsedTelno
          },
          nodeSize.XSMALL,
          [],
          LabelSize.MEDIUM
        );
        const link: Link = this.linkAnalysisService.getLink(personName || this.node.id, node.id, linkTypes.SIM, false, LIGHTER_LINK_COLOR);
        graphItems.push(node, link);
      });
    }
    if (personName) {
      const node = this.linkAnalysisService.createNewNode(
        person.names[0],
        nodeTypesColors.PERSON,
        person.names[0],
        {
          type: nodeTypes.PERSON,
          image:
            person.image && Object.keys(person.image).length
              ? (this.imageService.getPhotoUrl(person.image.url, true) as string)
              : '',
          label: person.names[0],
          socialSearchResult: person
        },
        nodeSize.SMALL,
        [],
        LabelSize.MEDIUM,
        person.image && Object.keys(person.image).length
          ? (this.imageService.getPhotoUrl(person.image.url, true) as string)
          : ''
      );
      const linkType: linkTypes = this.node.d.type === nodeTypes.MSISDN ? linkTypes.SIM : linkTypes.SOCIAL_PROFILE;
      const link: Link = this.linkAnalysisService.getLink(node.id, this.node.id, linkType, false, LIGHTER_LINK_COLOR);
      graphItems.push(node, link);
    }
    this.newGraphItems.emit(graphItems);
  }

  private getOsintData() {
    let id = this.node.id;
    if (this.node.d.type === nodeTypes.MSISDN) {
      id = id.substr(1, id.length);
    }
    const personFilters: RequestOptions = {
      filters: {
        source: [DataSource.SocialSearch],
        targetId: `${this.target.id}___${id}`,
        type: EntityType.Person,
        relationType: EntityRelationType.Plain
      }
    };
    this.subscriptions.push(
      this.personService
        .getAll(personFilters)
        .pipe(map(result => Object.values(result)))
        .subscribe((data: Person[]) => {
          if (data && data.length) {
            this.socialSearchResults = data;
            this.updateNodeData.emit({ node: this.node, socialResult: data[0] });
            this.createSocialSearchNodes(data[0]);
          } else {
            this.noSocialResultsMessage = true;
            this.loader = false;
          }
          const parentId = data && data.length && data[0].names.length ? data[0].names[0] : this.node.id;
          this.getSocialProfiles(parentId);
        })
    );
  }

  private getSocialProfiles(connectTo: string) {
    let id = this.node.id;
    if (this.node.d.type === nodeTypes.MSISDN) {
      id = id.substr(1, id.length);
    }
    const socialProfilesfilters: RequestOptions = {
      filters: {
        source: [DataSource.Twitter, DataSource.Facebook, DataSource.Instagram, DataSource.LinkedIn],
        targetId: `${this.target.id}___${id}`,
        type: EntityType.Profile,
        relationType: [EntityRelationType.Plain],
        limit: 10
      }
    };
    this.subscriptions.push(
      this.socialProfilesService
        .getAll(socialProfilesfilters)
        .pipe(map(result => Object.values(result)))
        .subscribe((profiles: Profile[]) => {
          if (profiles && profiles.length) {
            const graphItems: (Node | Link)[] = [];
            profiles.forEach(profile => {
              // the profile returned is the same as the selected one
              if (profile.profileId === this.node.id) {
                return;
              }
              const { node, link } = this.createSocialConnection(profile, false, connectTo);
              graphItems.push(node, link);
            });
            if (graphItems.length) {
              this.newGraphItems.emit(graphItems);
            }
          }
          this.getSocialConnections();
        })
    );
  }

  private getSocialConnections() {
    let id = this.node.id;
    if (this.node.d.type === nodeTypes.MSISDN) {
      id = id.substr(1, id.length);
    }
    const socialConnectionsfilters: RequestOptions = {
      filters: {
        source: [DataSource.Twitter, DataSource.Facebook, DataSource.Instagram],
        targetId: `${this.target.id}___${id}`,
        type: EntityType.Profile,
        relationType: [
          EntityRelationType.Friend,
          EntityRelationType.Follower,
          EntityRelationType.Following,
          EntityRelationType.Family
        ],
        limit: 5000
      }
    };
    this.subscriptions.push(
      this.socialProfilesService
        .getAll(socialConnectionsfilters)
        .pipe(map(result => Object.values(result)))
        .subscribe((profiles: Profile[]) => {
          if (profiles && profiles.length) {
            const graphItems: (Node | Link)[] = [];
            profiles.forEach(profile => {
              if (profile.profileId === this.node.id) {
                return;
              }
              const { node, link } = this.createSocialConnection(profile, true);
              graphItems.push(node, link);
            });
            if (graphItems.length) {
              this.newGraphItems.emit(graphItems);
            }
          }
        })
    );
  }

  private createSocialConnection(profile: Profile, addInCombo: boolean, parentId?: string): { node: Node; link: Link } {
    let connectionType: linkTypes = this.linkAnalysisService.getConnectionType(profile.source, profile.relationType);
    const node = this.linkAnalysisService.getSocialConnectionNode(profile);
    if (addInCombo) {
      node.parentId = profile.sourceEntity.parentId.toLowerCase() || this.node.id;
    }
    let id1: string;
    let id2: string;
    if (profile.relationType === EntityRelationType.Joined) {
      id1 = profile.profileId.toLowerCase();
      id2 = this.node.id;
      connectionType = linkTypes.FACEBOOK_GROUP_MEMBER;
    } else {
      id1 =
        connectionType === linkTypes.INSTAGRAM_FOLLOWER || connectionType === linkTypes.TWITTER_FOLLOWER
          ? profile.profileId.toLowerCase()
          : parentId || profile.sourceEntity.parentId.toLowerCase();
      id2 =
        connectionType === linkTypes.INSTAGRAM_FOLLOWER || connectionType === linkTypes.TWITTER_FOLLOWER
          ? parentId || profile.sourceEntity.parentId.toLowerCase()
          : profile.profileId.toLowerCase();
    }
    const link: Link = this.linkAnalysisService.getLink(
      id1,
      id2,
      connectionType,
      connectionType === linkTypes.FACEBOOK_FRIEND || connectionType === linkTypes.FACEBOK_FAMILY ? true : false,
      LIGHTER_LINK_COLOR,
      profile.name
    );
    return { node, link };
  }

  public getGenderLabel(gender: string) {
    switch (gender.toLowerCase()) {
      case 'female':
      case 'f':
        return 'Female';
      case 'male':
      case 'm':
        return 'Male';
      default:
        break;
    }
  }

  public showAnalyseButton(): boolean {
    const hideTypes = [
      nodeTypes.ORGANIZATION,
      nodeTypes.FACEBOOK_GROUP,
      nodeTypes.PLACE,
      nodeTypes.PERSON,
      nodeTypes.PHONE
    ];
    return (
      !this.node.d.isTargetNode && !(this.caseView || this.playgroundView) && !hideTypes.includes(this.node.d.type)
    );
  }

  public getFacebookGroupMembers(): void {
    this.showAnimation.emit(true);
    this.loader = true;
    const groupMembersFilters: RequestOptions = {
      filters: {
        source: [DataSource.Facebook],
        targetId: this.target.id, // TODO: for friends groups, this should not be target id always
        type: EntityType.Profile,
        relationType: [EntityRelationType.Joined],
        profileId: this.node.d.sourceEntityId,
        limit: 100
      }
    };
    this.subscriptions.push(
      this.profileService
        .getAll(groupMembersFilters)
        .pipe(map(result => Object.values(result)))
        .subscribe((profiles: Profile[]) => {
          if (profiles && profiles.length) {
            const graphItems: (Node | Link)[] = [];
            profiles.forEach(profile => {
              const { node, link } = this.createSocialConnection(profile, false);
              graphItems.push(node, link);
            });
            this.newGraphItems.emit(graphItems);
          }
        })
    );
  }

  public getPhoto(photo: string): string {
    return this.imageService.getPhotoUrl(photo, true) as string;
  }

  private getTargetData(targetId: string) {
    this.subscriptions.push(
      this.targetService.getTarget(targetId).subscribe((targetItem: TargetItem) => {
        this.socialSearchResults[0] = this.getTargetSocialSearchData(targetItem);
      })
    );
  }

  private fetchBackgroundJobs(): void {
    if (!this.target?.id) {
      return;
    }
    let id = this.node.id;
    if (this.node.d.type === nodeTypes.MSISDN) {
      id = id.substr(1, id.length);
    }
    this.subscriptions.push(
      this.osintService
        .fetchBackgroundJobs(`${this.target.id}___${id}`)
        .subscribe((backgroundJobs: BackgroundJob[]) => {
          if (backgroundJobs && backgroundJobs.length) {
            this.loader = true;
          }
        })
    );
  }
}
