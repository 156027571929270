import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faAngleDown, IconDefinition } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'intellectus-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionHeaderComponent {
  @Input()
  public state = false;

  @Output()
  public readonly stateToggled: EventEmitter<void> = new EventEmitter<void>();

  public readonly icon: IconDefinition = faAngleDown;
}
