export enum JobStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
  EXPIRED = 'EXPIRED',
  ABANDONED = 'ABANDONED',
  REISSUED = 'REISSUED',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  NEVERSTARTED = 'NEVERSTARTED'
}
