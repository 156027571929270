<form [formGroup]="scheduleForm" (ngSubmit)="onSubmit()">
  <div class="row schedule-row">
    <div class="col-12" [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'">
      <div>
        <app-datetimepicker [minDate]="todayDate"></app-datetimepicker>
      </div>
    </div>
    <div class="col-12" [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'">
      <div>
        <app-frequency></app-frequency>
      </div>
    </div>
    <!-- hide until BE is implemented -->
    <div class="col-12 remove-padding-from-left-col checkbox hidden" [class.col-md-4]="skin === 'GEO4' || skin === 'CVTP'">
      <div>
        <mat-checkbox [formControl]="notify">{{'Notify me on Updates' | translate}}</mat-checkbox>
      </div>
    </div>
  </div>
  <div *ngIf="showError" class="mat-error">
    {{'Please fill in all fields' | translate}}
  </div>
</form>
