import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import camelCase from 'lodash-es/camelCase';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import {
  BillingActions,
  BillingActionType,
  BillingPlan,
  TenantBillingDetails,
} from 'src/app/shared/models/billing-action.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { AuthService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseService {
  private readonly TEN_MINUTES = 1000 * 60 * 10;
  private billingPlan$ = new BehaviorSubject<BillingPlan<BillingActions, BillingActionType> | null>(null);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    protected override router: Router,
    protected override snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.authService.isAuthenticated.pipe(switchMap(() => this.fetchBillingPlan())).subscribe();
  }

  public fetchBillingPlan() {
    return timer(0, this.TEN_MINUTES).pipe(
      switchMap((_) => this.httpClient.get(`${this.fastAPIurl}/billing-plan`)),
      map((billingPlan: BillingPlan<BillingActions, BillingActionType>) => {
        Object.keys(billingPlan).forEach((action: BillingActions) => {
          billingPlan[action].type = camelCase(billingPlan[action].type.toLocaleLowerCase()) as BillingActionType;
          billingPlan[action].cost = Number(billingPlan[action].cost);
        });
        this.billingPlan$.next(transformSnakeToCamel(billingPlan));
      }),
      catchError((error) => this.handleError(error))
    );
  }

  public getBillingPlan(): BehaviorSubject<BillingPlan<BillingActions, BillingActionType>> {
    return this.billingPlan$;
  }

  public getTenantBillingDetails(): Observable<TenantBillingDetails> {
    return this.httpClient.get(`${this.fastAPIurl}/billing/tenant-details`).pipe(
      catchError(() => of({ result: <TenantBillingDetails>{} })),
      map((data: { result: TenantBillingDetails }) => transformSnakeToCamel(data.result))
    );
  }
}
