import { Injectable } from '@angular/core';
import { CdrTarget, GeoJsonFeatureCollection } from '@trg-commons/data-models-ts';
import first from 'lodash-es/first';
import { Point, Polygon } from 'src/app/modules/mapV2/models/map.models';
import { QueryService } from 'src/app/services/query/query.service';
import { GeoPointTypes } from 'src/app/shared/models/query-item.model';
import { CallLogsMapStore } from './calls-logs-map.store';

@Injectable({
  providedIn: 'root'
})
export class ClMapService {

  private azimuthPolygonCache: { [key: string]: Polygon; } = {};

  constructor(private queryService: QueryService, private mapStore: CallLogsMapStore) {}

  public createAzimuthPolygons(cdrs: CdrTarget[], requestTelnos: string[]): Polygon[] {
    return cdrs
      .filter(cdr => cdr.azimuth)
      .map(cdr => {
        const location = this.mapStore.getLocationForMsisdnFromCdr(cdr);
        if (!location) {
          return;
        }
        const point = first((location.geo.geojson as GeoJsonFeatureCollection).features);
        const [lng, lat] = point.geometry.coordinates;

        const azimuthHash: string = this.calculateAzimuthHash(lat, lng, cdr.azimuth);

        if (!this.azimuthPolygonCache[azimuthHash]) {
          this.azimuthPolygonCache[azimuthHash] = new Polygon({
            id: cdr.sourceEntity.id,
            strokeColor: 'white',
            strokeOpacity: 1,
            fillColor: '#0C97FF',
            points: this.queryService.calculateCircularSector(
              { type: GeoPointTypes.POINT, coordinates: [lng, lat] },
              {
                combasBearing: cdr.azimuth,
                sectorSize: 20
              },
              500,
              true
            ),
            extendMapBounds: false,
            fillOpacity: 0.3,
            isPopupWindowOpen: true,
            show: false
          });
        }

        return this.azimuthPolygonCache[azimuthHash];
      });
  }

  private calculateAzimuthHash(lat: number, lng: number, azimuth: number): string {
    return `${lat}|${lng}|${azimuth}`;
  }

  public getPolygonForPeerInteractionSection(
    lat: number,
    lng: number,
    azimuth: number
  ): Point[] | undefined {
    return this.queryService.calculateCircularSector(
      { type: GeoPointTypes.POINT, coordinates: [lng, lat] },
      {
        combasBearing: azimuth,
        sectorSize: 20
      },
      500,
      false
    );
  }
}
