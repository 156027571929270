import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QueryService } from 'src/app/services/query/query.service';
import { QueryType } from 'src/app/shared/models/query-item.model';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';
import { Angulartics2 } from 'angulartics2';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, OnDestroy {
  date: string[];
  public todayDate = new Date();
  scheduleForm: FormGroup;
  showError = false;
  numbersToBeQueried;
  skin;
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  subscriptions: Subscription[] = [];
  notify = new FormControl();

  constructor(
    private queryService: QueryService,
    private translationService: TranslationService,
    private dashboardService: DashboardService,
    private applicationStateService: ApplicationStateService,
    private angulartics2: Angulartics2,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initForm();
    const dateSubscription = this.queryService.dateRange.subscribe(dateRange => {
      this.date = dateRange;
      this.scheduleForm.controls['startAt'].setValue(this.date[0]);
      this.scheduleForm.controls['endAt'].setValue(this.date[1]);
    });
    const frequencySubscription = this.queryService.frequency.subscribe(frequency => {
      this.scheduleForm.controls['frequency'].setValue(frequency);
    });
    const formSubmissionSubscription = this.queryService.advancedDataFlag.subscribe(flag => {
      if (flag && this.queryService.advancedQueryType.value === QueryType.SCHEDULED) {
        this.onSubmit();
      }
    });
    const skinSubscription = this.applicationStateService.skin.subscribe((skin: string) => {
      this.skin = skin;
    });

    this.subscriptions.push(dateSubscription, frequencySubscription, formSubmissionSubscription, skinSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  initForm() {
    this.scheduleForm = new FormGroup({
      queryType: new FormControl(QueryType.SCHEDULED),
      startAt: new FormControl('', Validators.required),
      endAt: new FormControl('', Validators.required),
      frequency: new FormControl('', Validators.required),
      notify: new FormControl()
    });
  }

  onSubmit() {
    const queriesSubmission = this.queryService.numbersToBeQueried.subscribe(numbers => {
      if (numbers) {
        this.numbersToBeQueried = numbers;
      }
    });
    this.subscriptions.push(queriesSubmission);

    if (this.scheduleForm.valid && this.numbersToBeQueried.length) {
      const scheduleQuerySubscription = this.queryService.createScheduledQuery(this.scheduleForm.value).subscribe(
        () => {
          this.showMessage(this.translationService.translate('Query created successfully!'));
          this.changeView('logView');
        },
        () => {
          this.showMessage(this.translationService.translate('Query has not been created'));
        },
        () => {
          this.angulartics2.eventTrack.next({
            action: matomoActions.submitScheduleQuery,
            properties: {
              category: matomoCategories.gioAdvancedQuery
            }
          });
        }
      );
      this.subscriptions.push(scheduleQuerySubscription);
      // clear form
      this.queryService.advancedDataFlag.next(false);
    } else {
      this.showError = true;
      this.queryService.queriesFlag.next(false);
    }
  }

  changeView(view) {
    this.dashboardService.componentsView.next(view);
  }

  protected showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar']
    });
  }
}
