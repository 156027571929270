import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Injectable({
  providedIn: 'root'
})

export class AdintGuard implements CanActivate {
  adintFlavourEnabled = false;

  constructor(private router: Router, private appConfigService: AppConfigService) {
    this.adintFlavourEnabled = this.appConfigService.getConfigVariable('enableAdintFlavour');
  }
  // This guards should be changed into module-based permissions
  canActivate(): boolean {
    if (this.adintFlavourEnabled) {
      this.router.navigate(['/adint']);
      return false;
    }
    return true;
  }
}
