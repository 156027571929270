<app-core-toastr [class.open]="!isHidden">
  <app-core-toastr-header (close)="close()">
    <div class="target-toastr__title font-medium flex-wrp">
      <span>{{'Deep Webint' | translate}}</span>
      <span>{{target?.alias}}</span>
    </div>
    <mat-icon class="arrow" (click)="isHidden = !isHidden">play_arrow</mat-icon>
  </app-core-toastr-header>

  <app-core-toastr-body [class.hidden]="isHidden">
    <div class="target-toastr__osint-done flex-vert-center">
      <mat-icon class="target-toastr__done-icon">check_circle</mat-icon>
      <span>
        {{'Completed successfully' | translate}}
      </span>
      <a class="target-toastr__overview-link cursor-pointer" (click)="visitLinkAnalysisTab()">
        {{'View' | translate}}
      </a>
    </div>
  </app-core-toastr-body>
</app-core-toastr>
