import { Injectable } from '@angular/core';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { concat, head } from 'lodash-es';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NearbyLocationWindowState } from '../shared/models/nearby-locations-window-state.interface';
import {
  NearbyLocationsBodyMsg,
  NearbyLocationsProfile,
  NearbyLocationStore,
  NearbyLocationStoreValues
} from '../shared/models/nearby-locations.model';

@Injectable({
  providedIn: 'root'
})
export class NearbyLocationsStore {
  private store$ = new BehaviorSubject<NearbyLocationStore>({});
  private storeStateNearbyRequests$ = new BehaviorSubject<{ [key: string]: JobStatus }>({});
  private storeStateTrilaterationRequests$ = new BehaviorSubject<{ [key: string]: JobStatus }>({});
  private currentTrilaterationState$ = new BehaviorSubject<JobStatus>(JobStatus.NEVERSTARTED);
  private currentNearbyLocationWindowState: BehaviorSubject<JobStatus> = new BehaviorSubject<JobStatus>(
    JobStatus.PENDING
  );

  private trilaterationNearbyPeopleResponse$: Subject<NearbyLocationsBodyMsg> = new Subject<NearbyLocationsBodyMsg>();
  private filteredStore$: BehaviorSubject<NearbyLocationStoreValues[]> = new BehaviorSubject<
    NearbyLocationStoreValues[]
  >([]);
  private closeNearbyLocationWindows$: Subject<NearbyLocationWindowState> = new Subject();
  private applyTrilateration$: Subject<NearbyLocationsProfile[]> = new Subject<NearbyLocationsProfile[]>();
  private showNearbyPeopleToggle$: Subject<{ queryIds: string[]; show: boolean }> = new Subject<{
    queryIds: string[];
    show: boolean;
  }>();
  private nearbyLocationSeed$: Subject<{ flag: boolean; seed: string }> = new Subject<{
    flag: boolean;
    seed: string;
  }>();

  private selectedProfileIdFromMap$: Subject<string> = new Subject<string>();

  public storeUpdated$: Observable<boolean> = this.store$.asObservable().pipe(switchMap(() => of(true)));
  public nearbyLocations$: Observable<NearbyLocationStoreValues[]> = this.filteredStore$.asObservable();

  public setTrilaterationNearbyPeopleResponse(responseMsg: NearbyLocationsBodyMsg): void {
    this.trilaterationNearbyPeopleResponse$.next(responseMsg);
  }

  public getTrilaterationNearbyPeopleResponse(): Observable<NearbyLocationsBodyMsg> {
    return this.trilaterationNearbyPeopleResponse$.asObservable();
  }

  public setSelectedProfileIdFromMap(profileId: string) {
    this.selectedProfileIdFromMap$.next(profileId);
  }

  public getSelectedProfileIdFromMap(): Observable<string> {
    return this.selectedProfileIdFromMap$.asObservable();
  }

  public emitNearbyPeopleToggle(value: { queryIds: string[]; show: boolean }): void {
    this.showNearbyPeopleToggle$.next(value);
  }

  public getNearbyPeopleToggle(): Observable<{ queryIds: string[]; show: boolean }> {
    return this.showNearbyPeopleToggle$.asObservable();
  }

  public emitProfilesForTrilateration(profiles: NearbyLocationsProfile[]): void {
    this.applyTrilateration$.next(profiles);
  }

  public getProfilesForTrilateration(): Observable<NearbyLocationsProfile[]> {
    return this.applyTrilateration$.asObservable();
  }

  public emitNearbyLocationIntelSearchSeed(data: { flag: boolean; seed: string }): void {
    this.nearbyLocationSeed$.next(data);
  }

  public getNearbyLocationIntelSearchSeed(): Observable<{ flag: boolean; seed: string }> {
    return this.nearbyLocationSeed$.asObservable();
  }

  public closeNearbyLocationWindow(state: NearbyLocationWindowState): void {
    this.closeNearbyLocationWindows$.next(state);
  }

  public getCloseNearbyLocationWindow(): Observable<NearbyLocationWindowState> {
    return this.closeNearbyLocationWindows$.asObservable();
  }

  public updateNearbyLocationStore(profile: NearbyLocationsProfile) {
    const nearbyLocations: NearbyLocationStore = this.store$.getValue();
    if (nearbyLocations[profile.geoQueryId]) {
      nearbyLocations[profile.geoQueryId].profiles = nearbyLocations[profile.geoQueryId].profiles.map(profileInStore =>
        profileInStore.userId === profile.userId ? profile : profileInStore
      );
      this.store$.next(nearbyLocations);
    }
  }

  public areAlreadyFetchedProfiles(queryId: string): boolean {
    const nearbyLocations: NearbyLocationStore = this.store$.getValue();
    return !!nearbyLocations[queryId];
  }

  public fetchAlreadySavedProfiles(selectedQueryIds: string[]): NearbyLocationsProfile[] {
    return concat([], ...this.selectNearbyProfilesByQueryIds(selectedQueryIds).map(value => value.profiles));
  }

  public setNearbyLocationRequestState(queryId: string, status: JobStatus): void {
    const stateRequests: { [key: string]: JobStatus } = this.storeStateNearbyRequests$.getValue();
    stateRequests[queryId] = status;
    this.currentNearbyLocationWindowState.next(status);
    this.storeStateNearbyRequests$.next(stateRequests);
  }

  public getNearbyLocationRequestState(queryId: string): JobStatus {
    const stateStore = this.storeStateNearbyRequests$.getValue();
    const status: JobStatus = stateStore[queryId] || JobStatus.DONE;
    this.currentNearbyLocationWindowState.next(status);
    return status;
  }

  public getCurrentNearbyLocationWindowState(): Observable<JobStatus> {
    return this.currentNearbyLocationWindowState.asObservable();
  }

  public getCurrentTrilaterationState(): Observable<JobStatus> {
    return this.currentTrilaterationState$.asObservable();
  }

  public setStateTrilaterationRequests(queryIds: string[], status: JobStatus = undefined) {
    const stateRequests: { [key: string]: JobStatus } = this.storeStateTrilaterationRequests$.getValue();

    if (status) {
      queryIds.forEach(id => (stateRequests[id] = status));
    } else {
      status = stateRequests[head(queryIds)] || JobStatus.NEVERSTARTED;
    }
    this.currentTrilaterationState$.next(status);
    this.storeStateTrilaterationRequests$.next(stateRequests);
  }

  public saveNearbyLocations({
    queryId,
    queryDate,
    profiles,
    queryArgTelno
  }: {
    queryId: string;
    queryDate?: string;
    queryArgTelno: string;
    profiles: NearbyLocationsProfile[];
  }) {
    const nearbyLocations: NearbyLocationStore = this.store$.getValue();
    if (!nearbyLocations[queryId]) {
      profiles.forEach(profile => (profile.geoQueryId = queryId));
      const values: NearbyLocationStoreValues = {
        profiles,
        queryDate: queryDate ? new Date(queryDate) : new Date(),
        queryArgTelno
      };
      nearbyLocations[queryId] = values;
      this.store$.next(nearbyLocations);
    }
    this.setNearbyLocationRequestState(queryId, JobStatus.DONE);
  }

  public filterNearbyLocations(selectedQueryIds: string[]): void {
    this.filteredStore$.next(this.selectNearbyProfilesByQueryIds(selectedQueryIds));
  }

  private selectNearbyProfilesByQueryIds(selectedQueryIds: string[]): NearbyLocationStoreValues[] {
    const nearbyLocations: NearbyLocationStore = this.store$.getValue();
    return Object.keys(nearbyLocations).reduce(
      (acc, key) => (selectedQueryIds.indexOf(key) > -1 ? (acc = acc.concat(nearbyLocations[key])) : acc),
      []
    );
  }
}
