<div class="day-time-selector-container"
     *ngIf="show">
  <div class="loader"
       *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"
                      [color]="'accent'"></mat-progress-bar>
  </div>
  <app-day-selector (dayChange)="onDayChange($event)"></app-day-selector>
  <div class="time-slider">
    <nouislider [config]="sliderConfig"
                (change)="onHourChange($event)"></nouislider>
  </div>
</div>
