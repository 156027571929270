<mat-tab-group mat-align-tabs="start" class="query-tabs" (selectedTabChange)="mapDrawTools($event.index)">
  <mat-tab label="{{'New Query' | translate}}">
    <mat-card class="new-query-card">
      <mat-card-content>
        <app-simple-query (validInput)="getValidState($event)"></app-simple-query>
        <app-advanced-queries
          (selectedCountry)="onSelectedCountry($event)"
          (showSearchTool)="toggleSearchTool($event)"
          (showDrawingTools)="toggleDrawingTools($event)"
          (clearQueryMap)="onClearQueryMap($event)"
          (drawAreaOfInterestFromComponent)="onDrawnAreaOfInterestFromComponent($event)"
          [areaOfInterestDrawn]="areaOfInterest">
        </app-advanced-queries>
        <div class="row justify-content-center">
          <div class="center btn-wrp">
            <button mat-button class="btn-cancel" (click)="resetFields()">{{'Reset' | translate}}</button>
            <button appDebounceClick mat-button class="btn-green" [disabled]="!simpleQueryFlag" (debounceClick)="submitQuery()" [debounceTime]="5000">
              {{'Submit' | translate}}
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab class="queries-tab" *ngIf="enableAoIQuery" label="{{'AoI Query' | translate}}">
    <mat-card>
      <mat-card-content>
        <app-location-query [areaOfInterestDrawn]="areaOfInterest"></app-location-query>
      </mat-card-content>
    </mat-card>
    <app-aoi-query-table (emittedLocationQuery)="onEmittedLocationQuery($event)"></app-aoi-query-table>
  </mat-tab>
</mat-tab-group>
