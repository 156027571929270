<div [@animatePopup]="animationState" class="investigation-popup-wrapper">
  <ng-container *ngIf="currentInvestigationState === investigationStates.ChooseInvestigation">
    <div class="investigation-popup-header">
      <div class="title" mat-dialog-title>{{ 'Your feedback is valuable!' | translate }}</div>
    </div>
    <div class="investigation-popup-body">
      <div class="investigation-popup-body-content">
        {{ 'What type of investigation are you working on?' | translate }}
      </div>
      <mat-radio-group
        aria-labelledby="investigation-elements-group"
        [(ngModel)]="selectedInvestigationType"
      >
        <mat-radio-button
          *ngFor="let type of investigationTypes"
          class="investigation-popup-list-element"
          [value]="type.name"
          ><div class="investigation-popup-element">
            <span class="investigation-popup-element-name">{{ type.name | translate }}</span
            ><span>
              <ng-container *ngIf="type.icon"
                ><img src="{{ type.icon }}" alt="{{ type.name }}"
              /></ng-container>
            </span></div
        ></mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="footer">
      <a class="investigation-popup-opt-out-link" (click)="optOut()">{{ 'Opt-out' | translate }}</a>
      <button
        class="cp-btn-primary"
        [disabled]="!selectedInvestigationType"
        mat-button
        (click)="submitInvestigationType()"
      >
        {{ 'Submit' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="currentInvestigationState === investigationStates.SendInvestigation">
    <div class="investigation-popup-body">
      <div class="investigation-popup-body-content">
        {{ 'Are you still in the same investigation?' | translate }}
      </div>
      <div class="investigation-popup-send-investigation">
        <div class="investigation-popup-send-yes" (click)="sameInvestigationYes()">
          <img src="assets/static/images/investigation-types-icons/icon-thumb_up.svg" alt="yes" />
          {{ 'Yes' | translate }}
        </div>
        <div class="investigation-popup-send-no" (click)="sameInvestigationNo()">
          <img src="assets/static/images/investigation-types-icons/icon-thumb_down.svg" alt="no" />
          {{ 'No' | translate }}
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="investigation-resize-wrapper">
  <span class="investigation-resize" (click)="hidePopup()">
    <mat-icon *ngIf="animationState === animationStates.Hide" class="primary-icon"
      >keyboard_arrow_up</mat-icon
    >
    <mat-icon *ngIf="animationState === animationStates.Show" class="primary-icon"
      >keyboard_arrow_down</mat-icon
    ></span
  >
</div>
