import { Component, Input, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { NavbarMenuItem } from '../nav-bar.model';

@Component({
  selector: 'app-nav-menu-sub-item',
  templateUrl: './nav-menu-sub-item.component.html',
  styleUrls: ['./nav-menu-sub-item.component.scss'],
})

export class NavMenuSubItemComponent {
  @ViewChild(MatMenu, { static: true }) childMenu: MatMenu;
  @Input() items: NavbarMenuItem[] | undefined;
  @Input() menuTrigger: MatMenuTrigger | undefined;
  constructor() { }

  handleClick(item: NavbarMenuItem): void {
    if(item.onClick) {
      item.onClick();
    }
  }

}
