import { BaseService, EmptyCacheService } from 'datalayer/services/base';
import { Album } from 'datalayer/models/social/album';
import { Injectable } from '@angular/core';
import { AlbumDTO } from 'src/app/modules/data-layer/services/social/album/album-dto';
import { AlbumApiService } from 'src/app/modules/data-layer/services/social/album/album-api-service';

@Injectable({
  providedIn: 'root',
})
export class AlbumService extends BaseService<Album, AlbumDTO, AlbumApiService, EmptyCacheService<Album>> {
  constructor(apiService: AlbumApiService, localCacheService: EmptyCacheService<Album>) {
    super(apiService, localCacheService);
  }
}
