import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProperty'
})
export class FilterByPropertyPipe implements PipeTransform {
  transform(array: { [k: string]: unknown }[], key: string, toCompare: unknown): unknown {
    if (!Array.isArray(array) || !key) return array;

    return array.filter(item => {
      if (!item && !item[key]) {
        return false;
      }

      return item[key] === toCompare;
    });
  }
}
