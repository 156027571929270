import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Action } from '../../classes/action.class';
import { BaseDialogComponent } from '../../classes/base-dialog.component';
import { QuerySchedulerDurationHours, QuerySchedulerFrequencyMinutes } from '../../models/query-item.model';
import { Themes } from '../../models/skins.model';

@Component({
  selector: 'app-schedule-activation-dialog',
  templateUrl: './schedule-activation-dialog.component.html',
  styleUrls: ['./schedule-activation-dialog.component.scss']
})
export class ScheduleActivationDialogComponent extends BaseDialogComponent {
  public theme: Themes;

  private lowFrequencyDurationOptions: { displayValue: string; value: QuerySchedulerDurationHours }[] = [
    { displayValue: `6 ${this.translationService.translate('hours')}`, value: QuerySchedulerDurationHours.SIX_HOURS },
    { displayValue: `12 ${this.translationService.translate('hours')}`, value: QuerySchedulerDurationHours.TWELVE_HOURS },
    { displayValue: `24 ${this.translationService.translate('hours')}`, value: QuerySchedulerDurationHours.ONE_DAY }
  ];

  private defaultDurationOptions: { displayValue: string; value: QuerySchedulerDurationHours }[] = [
    { displayValue: this.translationService.translate('1 day'), value: QuerySchedulerDurationHours.ONE_DAY },
    { displayValue: this.translationService.translate('2 days'), value: QuerySchedulerDurationHours.TWO_DAYS },
    { displayValue: this.translationService.translate('1 week'), value: QuerySchedulerDurationHours.ONE_WEEK },
  ];

  public durationOptions: { displayValue: string; value: QuerySchedulerDurationHours }[];

  private frequencyOptions: { displayValue: string; value: QuerySchedulerFrequencyMinutes }[] = [
    { displayValue: '15 minutes', value: QuerySchedulerFrequencyMinutes.FIFTEEN_MINUTES },
    { displayValue: '30 minutes', value: QuerySchedulerFrequencyMinutes.THIRTY_MINUTES },
    { displayValue: '1 hour', value: QuerySchedulerFrequencyMinutes.ONE_HOUR },
  ];

  public filteredFrequencyOptions: { displayValue: string; value: QuerySchedulerFrequencyMinutes }[] = [];

  selectedDuration: QuerySchedulerDurationHours;
  selectedFrequency: QuerySchedulerFrequencyMinutes;

  private forceLowFrequencySchedules = false;
  constructor(
    public dialogRef: MatDialogRef<ScheduleActivationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; bodyDescription: string },
    router: Router,
    private appConfigService: AppConfigService,
    private translationService: TranslationService,
  ) {
    super(dialogRef, router);
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.forceLowFrequencySchedules = this.appConfigService.getConfigVariable('forceLowFrequencySchedules');
    this.durationOptions = this.forceLowFrequencySchedules ? this.lowFrequencyDurationOptions : this.defaultDurationOptions;
    this.selectedDuration = this.durationOptions[0].value;
    this.filteredFrequencyOptions = this.filterFrequencyOptions();
    this.selectedFrequency = this.filteredFrequencyOptions[0].value;
  }

  onSuccess(
    action: Action = new Action({
      key: 'success',
      data: {
        durationHours: this.selectedDuration,
        frequencyMinutes: this.selectedFrequency
      }
    })
  ) {
    this.dialog.close(action);
  }

  private filterFrequencyOptions(): { displayValue: string; value: QuerySchedulerFrequencyMinutes }[] {
    return this.frequencyOptions.filter(option => this.filterFrequencyPredicate(option.value));
  }

  private filterFrequencyPredicate(value: number): boolean {
    const selectedDurationInMinutes = this.selectedDuration * 60;
    return this.forceLowFrequencySchedules ? selectedDurationInMinutes / value <= 24 : true;
  }

  onDurationChange(): void {
    this.filteredFrequencyOptions = this.filterFrequencyOptions();
    if(!this.filterFrequencyPredicate(this.selectedFrequency)) {
      this.selectedFrequency = this.filteredFrequencyOptions[0]?.value;
    }
  }
}
