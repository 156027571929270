import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { PasswordValidationResult, PasswordValidationType } from '../models/login.model';

export function passwordValidator(authService: AuthDataService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return of(control.value).pipe(
      delay(500),
      filter((controlValue: string) => {
        return !!controlValue;
      }),
      switchMap((controlValue: string) =>
        authService.checkPasswordPolicy(controlValue).pipe(
          map((passwordResult: PasswordValidationResult) => {
            const validationType = passwordResult.validated_policies as PasswordValidationType;
            switch (true) {
              case !validationType.length:
                return { passwordAtLeast8Chars: true };
              case !validationType.uppercase:
                return { passwortAtLeastUppercase: true };
              case !validationType.lowercase:
                return { passwordAtLeastLowercase: true };
              case !validationType.digits:
                return { passwordAtLeastDigits: true };
              default:
                return null;
            }
          })
        )
      )
    );
  };
}
