import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Point } from 'src/app/modules/mapV2/models/map.models';
import { BaseService } from 'src/app/services/base.service';
import { environment } from 'src/environments/environment';
import { OTPCodeRequest } from '../../components/user-settings/user-settings.models';
import { OTPConfiguration, PasswordValidationResult } from '../../shared/models/login.model';
import { AuthResponse } from './dto';
import { AuthPayload } from './types';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService extends BaseService {
  private _url: string = environment.serverAPIUri;
  userLocation = new BehaviorSubject<Point>({ lat: null, lng: null });

  constructor(protected router: Router, private httpClient: HttpClient, protected snackBar: MatSnackBar) {
    super(router, snackBar);
  }

  login(authPayload: AuthPayload): Observable<AuthResponse> {
    return this.httpClient
      .post<any>(`${this._url}/auth`, {
        ...authPayload,
        longitude: this.userLocation.getValue().lng,
        latitude: this.userLocation.getValue().lat
      })
      .pipe(
        map(({ result }) => result),
        catchError(({ error }: HttpErrorResponse) => throwError(error.error))
      );
  }

  logout() {
    this.httpClient
      .get<any>(`${this._url}/logout`)
      .pipe(catchError(error => this.handleError(error)))
      .subscribe();
  }

  checkPasswordPolicy(password: string): Observable<PasswordValidationResult> {
    const params = {
      pwd: password
    };
    return this.httpClient.post<PasswordValidationResult>(`${this._url}/security/password/format/validate`, params);
  }

  GenerateOTPAuthenticatorQRCode(): Observable<string> {
    return this.httpClient.get(`${this._url}/security/otp/generate/qr?${Date.now()}`, { responseType: 'blob' }).pipe(
      map((response) => URL.createObjectURL(response)),
      catchError((error) => this.handleError(error))
    );
  }

  sendCode(sendCodeRequest: OTPCodeRequest): Observable<{ status: string } & Partial<AuthResponse>> {
    return this.httpClient.post(`${this._url}/security/otp/code`, sendCodeRequest).pipe(
      map((response: { result: { status: string } & Partial<AuthResponse> }) => response.result),
      catchError((error) => this.handleError(error))
    );
  }

  setUserOTPStatus(OTPStatus: OTPConfiguration): Observable<boolean> {
    return this.httpClient.post<any>(`${this._url}/security/otp/status`, OTPStatus).pipe(
      map(response => response.result.status),
      catchError(error => this.handleError(error))
    );
  }

  requestEmailAuthenticatorQrCode(userCredentials: { username: string; password: string }): Observable<string> {
    return this.httpClient.post<any>(`${this._url}/security/otp/email/qr`, userCredentials).pipe(
      map(response => response.message.email),
      catchError(error => this.handleError(error))
    );
  }

  public getSessionData(): Observable<AuthResponse> {
    return this.httpClient.get<any>(`${this._url}/security/session/data`).pipe(
      map(({ result }) => result),
      catchError(error => this.handleError(error))
    );
  }

  public refreshSession(): Observable<AuthResponse> {
    return this.httpClient.get<any>(`${this._url}/security/session/refresh`).pipe(
      map(({ result }) => result),
      catchError(error => this.handleError(error))
    );
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.status >= 500) {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      // return an observable with a user-facing error message
      return throwError(() => 'Something bad happened; please try again later.');
    }
    if (error.error) {
      return throwError(() => error.error.error); // for login call
    }
    return throwError(() => error.message); // OH My dog. this is inception
  }
}
