export enum TargetSummaryReportProgressBarElements {
    targetProfile = 'Gathering data for target details',
    socialNetworkGraph = 'Gathering social network details',
    socialWorldCloud = 'Gathering social word details',
    socialNetworkTopAssociates = 'Gathering social network top associates',
    callLogTopAssociates = 'Gathering call log top associates statistics',
    locations = 'Gathering locations',
    activityPattern = 'Gathering activity feed details',
    pdfPreview = 'Preparing PDF preview',
  }
