import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanizeTitleCase'
})
export class HumanizeTitleCasePipe implements PipeTransform {
  transform(value: string): string {
    value = value.split(/(?=[A-Z])/).join(' ');
    value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    return value;
  }
}
