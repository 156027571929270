
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileSidenavComponent } from 'src/app/components/mobile-sidenav/mobile-sidenav.component';
import { TrgCommonUiModule } from 'src/app/modules/trg-common-ui/trg-common-ui.module';
import { TrgCommonModule } from 'src/app/modules/trg-common/trg-common.module';


@NgModule({
  imports: [
    CommonModule,
    TrgCommonUiModule,
    TrgCommonModule,
  ],
  declarations: [
    MobileSidenavComponent
  ],
  exports: [
    MobileSidenavComponent
  ]
})
export class MobileSidenavModule { }
