
// here is the default text string
import { Injectable } from "@angular/core";
import { OwlDateTimeIntl } from '@busacca/ng-pick-datetime';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  language = "en";

  constructor(language: string) {
    super()
    this.language = language;

    /** A label for the cancel button */
    this.cancelBtnLabel = this.language === 'en' ? 'Cancel' : 'Cancelar';
  
    /** A label for the set button */
    this.setBtnLabel = this.language === 'en' ? 'Set' : 'Seleccionar';
  
    /** A label for the range 'from' in picker info */
    this.rangeFromLabel = this.language === 'en' ? 'From' : 'De';
  
    /** A label for the range 'to' in picker info */
    this.rangeToLabel = this.language === 'en' ? 'To' : 'A';
  }

}
