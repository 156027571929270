import { Injectable } from '@angular/core';
import { AvatarApiService } from 'datalayer/services/avatar/avatar-api.service';
import { AvatarDataChangeType } from 'datalayer/services/avatar/avatar-data-change-motification';
import { AvatarDTO } from 'datalayer/services/avatar/avatar-dto';
import { MessageSubject } from './message-subject.model';
import { ServerPyWsService } from './server-py-websocket.service';
import { Message } from './websocket.class';

@Injectable({
  providedIn: 'root'
})
export class DataLayerWsManagerService {
  constructor(private serverPyWsService: ServerPyWsService, private avatarApiService: AvatarApiService) { }

  public connect(): void {
    this.serverPyWsService.getMessage().subscribe((message: Message) => {
      const messageSubjectsToHandle = [
        MessageSubject.AvatarUpdate,
        MessageSubject.AvatarRemoteViewEnded,
        MessageSubject.AvatarRemoteViewStarted
      ];
      if (messageSubjectsToHandle.includes(message.subject)) {
        this.dispatchMessage(message.subject, message.body);
      }
    });
  }

  private dispatchMessage(
    subject,
    body: { correlationId: string; payload: { avatar: AvatarDTO; msg?: string } }
  ): void {
    switch (subject) {
      case MessageSubject.AvatarUpdate: {
        this.avatarApiService.onResourceChanged({
          type: AvatarDataChangeType.Update,
          dto: body.payload.avatar,
          message: body.payload.msg
        });
        break;
      }
      case MessageSubject.AvatarRemoteViewEnded: {
        this.avatarApiService.onResourceChanged({
          type: AvatarDataChangeType.RemoteViewEnded,
          dto: body.payload.avatar
        });
        break;
      }
      case MessageSubject.AvatarRemoteViewStarted: {
        this.avatarApiService.onResourceChanged({
          type: AvatarDataChangeType.RemoteViewStarted,
          dto: body.payload.avatar
        });
        break;
      }
    }
  }
}
