import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Themes } from 'src/app/shared/models/skins.model';

@Injectable({
  providedIn: 'root'
})
export class UserOptionsGuard implements CanActivate {

  constructor(private appConfigService: AppConfigService, private router: Router) { }

  canActivate(): boolean {
    if (this.appConfigService.getConfigVariable('theme') === Themes.UNLIMITED) {
      this.router.navigate(['/discovery']);
      return false;
    }
    return true;
  }
}
