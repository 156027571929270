import { Component, Input, ViewRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { TemplateActivityCell } from '../../activity-pattern.component';

@Component({
  selector: 'app-template-activity-cell',
  templateUrl: './template-activity-cell.component.html',
  styleUrls: ['./template-activity-cell.component.scss']
})
export class TemplateActivityCellComponent implements OnChanges, OnDestroy {
  @Input() activityCell: TemplateActivityCell | undefined;
  viewRef: ViewRef | undefined;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activityCell && changes.activityCell.currentValue.value) {
      this.viewRef = changes.activityCell.currentValue.templateGenerator();
    } else {
      this.viewRef = undefined;
    }
  }

  ngOnDestroy(): void {
    this.viewRef = null;
    this.activityCell = null;
  }

}
