import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { JobStatus } from 'src/app/modules/data-layer/models/background-jobs/background-job-status';
import { Case } from 'src/app/shared/models/case.model';
import { transformSnakeToCamel } from '../util/helper';
import { TargetLink } from './social-media.model';
import { RecoveryAccount } from './recovery-account.model';
import { getCountryCodeByCountryName } from '../util/country_dial';
import { Platform } from '../schemas/common/platforms';
import { Observable } from 'rxjs';

export interface TargetItem {
  alias?: string;
  names?: Array<string>;
  telnos?: Array<string>;
  imsis?: Array<string>;
  tmsis?: Array<string>;
  imeis?: Array<string>;
  user?: string;
  assignedCases?: Array<string>;
  fullCasesList?: Array<Case>;
  assignedUsers?: Array<string>;
  assignedGroups?: Array<string>;
  id?: string;
  requestedCdr?: boolean;
  notifyOnCapture?: boolean;
  gender?: string;
  emails?: Array<string>;
  about?: Array<string>;
  userIds?: Array<string>;
  usernames?: Array<string>;
  licensePlates?: Array<string>;
  jobs?: Array<string>;
  workPlaces?: Array<string>;
  photos?: Array<string>;
  countryCode?: string;
  checked?: boolean;
  addresses?: Array<string>;
  dateOfBirth?: string;
  placeOfBirth?: Array<string>;
  maritalStatus?: string;
  nationalities?: Array<string>;
  race?: string;
  countries?: Array<string>;
  socialProfiles?: Array<TargetLink>;
  eyesColor?: string;
  hairColor?: string;
  facialHair?: string;
  socialInsurance?: string;
  height?: string;
  weight?: string;
  timeZone?: string;
  provider?: string;
  voterId?: string;
  nationalId?: string;
  landTelnos?: string[];
  color?: string;
  ifas?: string[];
  deepOsintStatus?: JobStatus;
  instantMessagePhotos?: ImProfile[];
  expired?: boolean;
  expiresAt?: Date;
  callLogsEnabled?: boolean;
  recoveryAccounts?: RecoveryAccount[];
  motherNames?: string[];
  fatherNames?: string[];
  ife?: string[];
  curp?: string[];
  rfc?: string[];
  groups?: string[];
  skills?: string[];
  courses?: string[];
  fanOf?: string[];
  industry?: string[];
  volunteering?: string[];
  associatedDomain?: string[];
  otherTags?: string[];
  vehicleMake?: string[];
  vehicleModel?: string[];
  vehicleIdNumber?: string[];
  vehicleYear?: string[];
  propertyType?: string[];
  homeLastSaleDate?: string[];
  ipAddresses?: string[];
  familyRelations?: Relation[];
  workRelations?: Relation[];
  friendRelations?: Relation[];
  otherRelations?: Relation[];
  createdBy?: string;
  isInAnalyzeState?: Observable<boolean>;
}

export interface Relation {
  platform?: Platform;
  subValue?: string;
  value?: string;
}

export class TargetItemMapper {
  static map(data: Partial<TargetItem>): TargetItem {
    data = transformSnakeToCamel(data);
    return {
      alias: data?.alias,
      names: data?.names?.filter(item => !!item) || [],
      telnos: data?.telnos?.filter(item => !!item) || [],
      imsis: data?.imsis?.filter(item => !!item) || [],
      tmsis: data?.tmsis?.filter(item => !!item) || [],
      imeis: data?.imeis?.filter(item => !!item) || [],
      user: data?.user,
      assignedCases: data?.assignedCases?.filter(item => !!item) || [],
      fullCasesList: data?.fullCasesList?.filter(item => !!item) || [],
      assignedUsers: data?.assignedUsers?.filter(item => !!item) || [],
      assignedGroups: data?.assignedGroups?.filter(item => !!item) || [],
      id: data?.id,
      requestedCdr: data?.requestedCdr,
      notifyOnCapture: data?.notifyOnCapture,
      gender: data?.gender,
      emails: data?.emails?.filter(item => !!item) || [],
      about: data?.about?.filter(item => !!item) || [],
      userIds: data?.userIds?.filter(item => !!item) || [],
      usernames: data?.usernames?.filter(item => !!item) || [],
      licensePlates: data?.licensePlates?.filter(item => !!item) || [],
      jobs: data?.jobs?.filter(item => !!item) || [],
      workPlaces: data?.workPlaces?.filter(item => !!item) || [],
      photos: data?.photos?.filter(item => !!item) || [],
      countryCode: data?.countryCode || getCountryCodeByCountryName(data?.countries[0])?.code,
      checked: data?.checked,
      addresses: data?.addresses?.filter(item => !!item) || [],
      dateOfBirth: data?.dateOfBirth,
      placeOfBirth: data?.placeOfBirth,
      maritalStatus: data?.maritalStatus,
      nationalities: data?.nationalities?.filter(item => !!item) || [],
      race: data?.race,
      countries: data?.countries?.filter(item => !!item) || [],
      socialProfiles: data?.socialProfiles?.filter(item => !!item) || [],
      eyesColor: data?.eyesColor,
      hairColor: data?.hairColor,
      facialHair: data?.facialHair,
      socialInsurance: data?.socialInsurance,
      height: data?.height,
      weight: data?.weight,
      timeZone: data?.timeZone,
      provider: data?.provider,
      voterId: data?.voterId,
      nationalId: data?.nationalId,
      landTelnos: data?.landTelnos?.filter(item => !!item) || [],
      color: data?.color,
      ifas: data?.ifas?.filter(item => !!item) || [],
      deepOsintStatus: data?.deepOsintStatus,
      instantMessagePhotos: data?.instantMessagePhotos?.filter(item => !!item) || [],
      expired: data?.expired,
      expiresAt: data?.expiresAt,
      callLogsEnabled: data?.callLogsEnabled,
      recoveryAccounts: data?.recoveryAccounts?.filter(item => !!item) || [],
      motherNames: data.motherNames?.filter(item => !!item) || [],
      fatherNames: data.fatherNames?.filter(item => !!item) || [],
      ife: data.ife?.filter(item => !!item) || [],
      curp: data.curp?.filter(item => !!item) || [],
      rfc: data.rfc?.filter(item => !!item) || [],
      groups: data.groups?.filter(item => !!item) || [],
      skills: data.skills?.filter(item => !!item) || [],
      courses: data.courses?.filter(item => !!item) || [],
      industry: data.industry?.filter(item => !!item) || [],
      fanOf: data.fanOf?.filter(item => !!item) || [],
      otherTags: data.otherTags?.filter(item => !!item) || [],
      associatedDomain: data.associatedDomain?.filter(item => !!item) || [],
      volunteering: data.volunteering?.filter(item => !!item) || [],
      vehicleIdNumber: data.vehicleIdNumber?.filter(item => !!item) || [],
      vehicleMake: data.vehicleMake?.filter(item => !!item) || [],
      vehicleModel: data.vehicleModel?.filter(item => !!item) || [],
      vehicleYear: data.vehicleYear?.filter(item => !!item) || [],
      propertyType: data.propertyType?.filter(item => !!item) || [],
      homeLastSaleDate: data.homeLastSaleDate?.filter(item => !!item) || [],
      ipAddresses: data.ipAddresses?.filter(item => !!item) || [],
    };
  }
}
