import { BaseMapComponent } from 'src/app/shared/components/base-map/base-map.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BaseMapComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class BaseMapModule { }
