export enum DataSource {
  OpenSearch = 'OS',
  SocialSearch = 'SS',
  Facebook = 'FB',
  Instagram = 'IG',
  Twitter = 'TW',
  LinkedIn = 'IN',
  WhatsApp = 'WA',
  Telegram = 'TG',
  Skype = 'SK',
  Truecaller = 'TC',
  CallerID = 'CI',
  Viber = 'VB',
  Wechat = 'WC',
  Interpol = 'INTERPOL',
  Dea = 'DEA',
  FBI = 'FBI',
  INE = 'INE',
  Tinder = 'TIN',
  Youtube = 'YT',
  Tiktok = 'TT',
  DBSearch = 'DBS',
  CallLog = 'CDR',
  GeoLocation = 'GEOLOCATION',
  Other = 'OTHER',
  AdInt = 'ADINT',
  Google = 'GG',
}

export function getDataSourceName(source: DataSource | string): string {
  const sourceEl = Object.entries(DataSource).find(([_name, value]) => value === source);

  return sourceEl ? sourceEl[0] : source;
}
