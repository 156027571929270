import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { findIndex, omit, pickBy, remove } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { transformCamelToSnakeRecursive, transformSnakeToCamel } from 'src/app/shared/util/helper';
import { environment } from 'src/environments/environment';
import { UserBillingService } from '../billing/user-billing.service';
import { UsersTableHeaders } from './../../components/admin/users/users-table-headers.enum';
import { PaginatedUsers } from './paginated-users.model';
import { User } from './user.model';
@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  allUsers: User[];
  currentUser$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
  usersChanged = new Subject<User[]>();
  handlingAccurateLocation = new BehaviorSubject<boolean>(false);

  private _url: string = environment.serverAPIUri;
  constructor(private httpClient: HttpClient, protected router: Router, protected snackBar: MatSnackBar, private userBillingService: UserBillingService) {
    super(router, snackBar);
  }

  getAllUsers(): Observable<User[]> {
    const params = {};
    params['limit'] = 1000;
    return this.httpClient
      .get<any>(`${this._url}/users`, { params })
      .pipe(
        map((data) => {
          this.allUsers = transformSnakeToCamel(data.result);
          return this.allUsers;
        }),
        catchError(error => this.handleError(error))
      );
  }

  getAllPaginatedUsers({
    limit = 8,
    page = 1,
    sortKey = UsersTableHeaders.Username,
    sortOrder = 1,
    filterArg = ''
  }): Observable<PaginatedUsers> {
    const params = {};
    params['limit'] = limit;
    params['page'] = page;
    params['sortOrder'] = sortOrder;
    params['sortKey'] = sortKey
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    params['filterArg'] = filterArg;
    return this.httpClient
      .get<any>(`${this._url}/users`, { params })
      .pipe(
        map((result) => transformSnakeToCamel(result)),
        catchError(error => this.handleError(error))
      );
  }

  getCurrentUser(): Observable<User> {
    return this.httpClient.get<{ result: User }>(`${this._url}/user`).pipe(
      map(({ result }) => transformSnakeToCamel(result)),
      map((result) => {
        this.currentUser$.next(result);
        return result;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  getAllUsernames(): Observable<string[]> {
    return this.httpClient.get<{result: string[]}>(`${this._url}/usernames`).pipe(
      map(({result}) => result),
      catchError(error => this.handleError(error))
    );
  }

  createUser(user: Partial<User>, paginated: boolean = false): Observable<User> {
    return this.httpClient.post<{result: User}>(`${this._url}/users`, transformCamelToSnakeRecursive(user)).pipe(
      map(({ result }) => transformSnakeToCamel(result)),
      map((result) => {
        if(!paginated) {
          const { temporary_password, ...user } = result;
          this.allUsers.push(user);
          this.usersChanged.next(this.allUsers.slice());
        }

        return result;
      }),
      catchError(error => this.handleError(error))
    );
  }

  editUser(currentUser: Partial<User>, newData: Partial<User>, paginated: boolean = false): Observable<User> {
    let updatedUser = pickBy({ ...currentUser, ...newData }, (v) => v !== undefined && v !== null);
    const isTenantExpired: boolean = this.userBillingService.isTenantExpiredValue();

    if(isTenantExpired) {
      updatedUser = omit(updatedUser, ['currentBalance', 'initialBalance']);
    }

    return this.httpClient.put<{ result: User }>(`${this._url}/users/${currentUser.username}`, transformCamelToSnakeRecursive(updatedUser)).pipe(
      map(({ result }) => transformSnakeToCamel(result)),
      map((result) => {
        this.currentUser$.next(result);
        if (!paginated) {
          this.allUsers[findIndex(this.allUsers, [UsersTableHeaders.Username, currentUser.username])] = result;
          this.usersChanged.next(this.allUsers.slice());
        }
        return result;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  removeUser(currentUser: Partial<User>): Observable<void> {
    return this.httpClient.delete(`${this._url}/users/${currentUser.username}`).pipe(
      map(() => {
        remove(this.allUsers, [UsersTableHeaders.Username, currentUser.username]);
        this.usersChanged.next(this.allUsers.slice());
      }),
      catchError(error => this.handleError(error))
    );
  }

  resetPassword(oldPassword: string, newPassword: string): Observable<User> {
    return this.httpClient.put<{ result: User }>(`${this._url}/user/reset-password`, { oldPassword, newPassword }).pipe(
      map(({ result }) => transformSnakeToCamel(result)),
      catchError(this.handleUnauthorizedError)
    );
  }

  resetUserPassword(username: string): Observable<User> {
    return this.httpClient.put<{result: User}>(`${this._url}/user/reset-password/${username}`, undefined).pipe(
      map(({ result }) => transformSnakeToCamel(result)),
      catchError(this.handleUnauthorizedError)
    );
  }

  private handleUnauthorizedError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      return throwError(error.error.error);
    }
  }

  changeAccurateLocation(flag: boolean, username: string) {
    return this.httpClient.put<{result: {accurate_location: boolean}}>(`${this._url}/user/platform-setting/${username}`, { accurate_location: flag })
      .pipe(
        map(({ result }) => result.accurate_location),
        catchError(this.handleUnauthorizedError)
      );
  }

  getCurrentAccurateLocation(username: string){
    return this.httpClient.get<{result: {accurate_location: boolean}}>(`${this._url}/user/platform-setting/${username}`)
    .pipe(
      map(({ result }) => result.accurate_location),
      catchError(this.handleUnauthorizedError)
    );
  }
}
