import { ActivityType } from './../../../../../trg-common/components/activity-pattern/enum/activity-type.enum';
import { Component, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FeedService } from 'src/app/modules/profiler/services/feed.service';
import { TargetFeedActivityModel } from 'src/app/modules/profiler/shared/target-feed-calendar/target-feed-calendar.component';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { CallLogsApiService } from 'src/app/shared/modules/call-logs-shared/services/call-logs-api.service';
import { CdrStatisticsDto } from '@trg-commons/gio-data-models-ts';
import { map, take, } from 'rxjs/operators';
import { DayEnum, EnumDictionary, EveryTwoHoursEnum, QueryCommand, TargetAcitvityModel, TargetActivity } from '@trg-commons/data-models-ts';
import { ActivityPatternModelTransformerService } from '../../service/activity-pattern-model-transformer.service';
import { ActivityPattern } from 'src/app/modules/trg-common/components/activity-pattern/activity-pattern.component';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-target-activity-pattern',
  templateUrl: './target-activity-pattern.component.html',
  styleUrls: ['./target-activity-pattern.component.scss']
})
export class TargetActivityPatternComponent implements AfterViewInit {
  @Output() activityPatternComplete = new EventEmitter<void>();
  @Input() target: TargetItem;
  activities: ActivityPattern = null;
  activityLists: {name: string}[] = [];
  constructor(
    private apiService: CallLogsApiService,
    private feedService: FeedService,
    private activityPatternModelTransformerService: ActivityPatternModelTransformerService) { }

  ngAfterViewInit(): void {
    if (!this.target) {
      console.warn('Target is missing');
      return;
    }
    this.generateTargetTotalActivity();
  }

  generateTargetTotalActivity(): void {
    forkJoin({
      callLogActivity: this.generateCallLogActivity(this.target),
      socialAccountActivity : this.generateSocialAccountsActivity(this.target),
    }).subscribe((data) => {
      const totalActivityPattern: ActivityPattern = {};
      if (!isEmpty(data.callLogActivity)) {
        this.activityLists.push({name: ActivityType.callLog});
        this.activityPatternModelTransformerService.addActivityToActivityPattern(totalActivityPattern, data.callLogActivity);
      }
      if (!isEmpty(data.socialAccountActivity)) {
        this.activityLists.push({name: ActivityType.socialAccounts});
       this.activityPatternModelTransformerService.addActivityToActivityPattern(totalActivityPattern, data.socialAccountActivity);
      }
      this.activities = totalActivityPattern;
    });
  }

  generateCallLogActivity(target: TargetItem): Observable<EnumDictionary<DayEnum, EnumDictionary<EveryTwoHoursEnum, TargetAcitvityModel>>> {
    const request = new CdrStatisticsDto({
      msisdns: target.telnos,
      command: QueryCommand.TargetActivity
    });
    return this.apiService.createStatisticsRequest(request).pipe(
      map(reponse => reponse.body as TargetActivity),
      map((response) => response.overallActivity),
      take(1)
    );
  }

  generateSocialAccountsActivity(target: TargetItem):
  Observable<EnumDictionary<DayEnum, EnumDictionary<EveryTwoHoursEnum, TargetFeedActivityModel>>> {
   return this.feedService
    .getFeedActivity({
      targetId: target.id,
    }).pipe(take(1));
  }
}
