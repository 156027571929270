import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AvatarService } from 'datalayer/services/avatar/avatar.service';
import { get } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/base/base.component';
import { RoundNumberPipe } from 'src/app/components/location-log/round-number-pipe';
import { MarketingBannerComponent } from 'src/app/components/marketing-banner/marketing-banner.component';
import getNotificationText from 'src/app/components/notification-item/get-notification-text';
import { Notification } from 'src/app/components/notifications-list/notification.model';
import { RequestLocationHistoryDialogComponent } from 'src/app/components/request-location-history-dialog/request-location-history-dialog.component';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { ImageService } from 'src/app/services/image/image.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { NotificationType } from 'src/app/services/notification/types';
import { QueryService } from 'src/app/services/query/query.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { QueryArgs } from 'src/app/shared/models/query-item.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  providers: [RoundNumberPipe],
})
export class NotificationItemComponent extends BaseComponent implements OnChanges, OnInit {

  isMobileResolution: boolean;
  skin;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;

  roundPipe = new RoundNumberPipe();
  public notificationIcon: string;
  public readStatus: string;
  public notificationText: string;
  public template: string;
  public context: any = {};
  theme: string;
  private userReadableGeofencingType: { [key: string]: string } = {
    'fenced_area': 'fenced area',
    'fenced_country': 'fenced country',
    'fenced_quarantine': 'fenced quarantine'
  }

  _notification = new BehaviorSubject<Notification>(null);

  @Input() set notification(notification: Notification) {
    this._notification.next(notification);
  }

  constructor(
    private applicationStateService: ApplicationStateService,
    public notificationService: NotificationService,
    private queryService: QueryService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    private appConfigService: AppConfigService,
    private imageService: ImageService,
    private localStorageService: LocalStorageService,
    private profilerService: ProfilerService,
    private router: Router,
    private userBillingService: UserBillingService,
    private avatarService: AvatarService,
  ) {
    super();
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.skin = this.applicationStateService.getSkin();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  getImage(image: string, thumbnail = false) {
    return <string>this.imageService.getPhotoUrl(image, thumbnail);
  }

  getVideo(video: string) {
    return `${environment.serverAPIUri}${video}`;
  }

  highlightText(value: any, keywords: string[]) {
    if (!keywords || !Array.isArray(keywords) || !keywords.length) {
      return value;
    }
    return keywords.reduce((result, word) => {
      const reg = new RegExp(word, 'gi');
      result = result.replace(reg, '<mark>$&</mark>');
      return result;
    }, value);
  }

  public navigateToTarget(targetId: string) {
    this.router.navigate(['targets', targetId, 'overview']);
    this.profilerService.targetData.next(null);
  }

  public navigateToRadicalMonitoring(target: TargetItem, content) {
    this.router.navigate(['targets', target.id, 'radical-monitoring'], {
      fragment: `${content.payload.data.sourceEntityId}@${content.payload.data.platform}`,
    });
    this.profilerService.targetData.next(null);
  }

  ngOnInit() {
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }
  }

  get notification() {
    return this._notification.value;
  }

  getSubject() {
    const target = this.notification.content.target;
    if (target) {
      return target.alias;
    }

    if (this.notification.content.queryArgs) {
      return this.getSubjectFromQueryArgs(this.notification.content.queryArgs);
    }

    return this.getSubjectFromQueryArgs(this.notification.content.query.queryArgs);
  }

  getSubjectFromQueryArgs(queryArgs: QueryArgs) {
    if (queryArgs.telno) {
      return queryArgs.telno;
    } else if (queryArgs.imsi) {
      return queryArgs.imsi;
    }

    return 'Missing QueryArg';
  }

  getArea() {
    const coordinates = [];
    coordinates.push(
      this.roundPipe.transform(this.notification.content.area.location.coordinates[0]),
      this.roundPipe.transform(this.notification.content.area.location.coordinates[1])
    );
    return this.notification.content.area.name ?
      this.notification.content.area && this.notification.content.area.name :
      coordinates.join(', ');
  }

  ngOnChanges(changes) {
    const notificationUpdate = get(changes, 'notification.currentValue');
    if (notificationUpdate) {
      this.notification = notificationUpdate;
      this.notificationText = getNotificationText(this.notification);
      const notificationType = this.notification.notificationType;
      this.context.timestamp =
        this.notification.createdAt instanceof Date
          ? this.notification.createdAt.getTime()
          : this.notification.createdAt;
      if (notificationType === 'geoquery:schedule:expired') {
        this.template = 'geoqueryScheduleEnded';
        const payload = this.notification.content.schedule.payload;
        this.context.subject = payload.queryArgs ? this.getSubjectFromQueryArgs(payload.queryArgs) : payload.target.alias;
        this.context.id = this.notification.content.schedule.id;
        this.context.endedAt = this.notification.content.schedule.endsAt;
      } else if (notificationType.includes('quarantine')) {
        this.template = 'quarantine';
        this.context.id = this.notification.id;
        this.context.target = this.notification.content.target;
        this.context.inside = this.notification.content.inside;
      } else if (notificationType.includes('geoquery:area') || notificationType.includes('geoquery:geofence')) {
        this.template = 'geoqueryArea';
        this.context.inside = this.notification.content.inside;
        this.context.subject = this.getSubject();
        this.context.area = this.getArea();
      } else if (notificationType.includes('geoquery:logon:status:off')) {
        this.template = 'geoqueryLogonStatusOff';
        this.context.subject = this.getSubject();
      } else if (notificationType.includes('geoquery:log')) {
        this.template = 'geoqueryLogged';
        this.context.subject = this.getSubject();
        this.context.loggedOn = notificationType.includes('geoquery:logon');
      } else if (notificationType.includes('emergency')) {
        this.template = 'emergency';
        this.context.subject = this.getSubject();
      } else if (notificationType.includes('proximity')) {
        this.template = 'proximity';
        this.context.subject = this.getSubject();
        this.context.near = this.getSubjectFromQueryArgs(this.notification.content.near.queryArgs);
        this.context.accuracy = this.notification.content.near.accuracyMeters / 1000;
      } else if (notificationType.includes('country')) {
        this.template = 'geofenceCountry';
        this.context.inside = this.notification.content.inside;
        this.context.subject = this.getSubject();
        this.context.countryCode = this.notification.content.countryCode.toUpperCase();
      } else if (notificationType.includes('gamification')) {
        this.template = 'gamification';
        this.context.subject = this.getSubject();
        this.context.id = this.notification.id;
        this.context.query = this.notification.content.query;
      } else if (notificationType.includes('push')) {
        this.template = 'push';
        this.context.id = this.notification.id;
      } else if (notificationType.includes('releasenotes')) {
        this.template = 'releasenotes';
        this.context.id = this.notification.id;
        this.context.text = this.notification.content.text;
      } else if (notificationType === 'locationhistory:notfound') {
        this.template = 'locationHistoryNotFound';
        this.context.id = this.notification.id;
      } else if (notificationType === 'locationhistory:notfound:telcell') {
        this.template = 'locationHistoryNotFoundTellcell';
        this.context.id = this.notification.id;
        this.context.queryArgs = this.notification.content.queryArgs;
      } else if (notificationType === 'locationhistory:found') {
        this.template = 'locationHistoryFound';
        this.context.id = this.notification.id;
        this.context.queriesCount = this.notification.content.queriesCount;
      } else if (notificationType === 'tactical:notifyOnCapture') {
        this.template = 'tacticalNotifyOnCapture';
        this.context.id = this.notification.id;
        this.context.queryArgs = this.notification.content.queryArgs;
        this.context.satellite = this.notification.content.satellite;
      } else if (notificationType === 'radicalmonitoring:notifyOnDetect') {
        if (!this.notification.content?.radicalMonitor?.targetId) {
          return;
        }
        this.template = 'radicalMonitoringOnDetect';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content.radicalMonitor;
        this.profilerService
          .getTargetData(this.notification.content.radicalMonitor.targetId)
          .toPromise()
          .then((target: TargetItem) => {
            this.context.target = target;
          });
      } else if (notificationType === 'calllogrequests:notifyUserOnSupportUpload') {
        this.template = 'callLogUpload';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content.callLogUpload;
      }
      else if (notificationType === 'calllogrequests:notifyUserOnUploadFinished') {
        this.template = 'callLogUploadFinished';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content;
      } else if (notificationType === 'advancedgeoquery:notifyUserOnAdvancedQueryResponse') {
        this.template = 'advancedGeoQuery';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content.advancedGeoQuery;
      } else if (notificationType === 'querycallinfo:notifyOnDetect') {
        this.template = 'querycallinfo';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content.queryCallInfo;
      } else if (notificationType === 'live-session') {
        this.template = 'live-session';
        this.context.id = this.notification.id;
        this.context.liveSession = this.notification.content.liveSession;
      } else if (notificationType === 'marketing-banner') {
        if (!sessionStorage.getItem(`${this.localStorageService.getCurrentUser().identity}_marketingBannerClicked`)) {
          this.showMarketingBanner(this.notification);
        }
      } else if (notificationType === 'extractusd-request') {
        this.template = 'extractusd';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content;
        this.context.target = this.notification.content.target;
      } else if (notificationType === NotificationType.SYSTEM) {
        this.template = NotificationType.SYSTEM;
        this.context.content = this.notification?.content?.system;
      } else if (notificationType === NotificationType.TARGET_CASE_EXPIRATION) {
        this.template = 'targetOrCaseExpired';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content;
      } else if (notificationType === 'geoquery:deactivated:state') {
        if (this.notification.content?.query?.schedule?.scheduleType in this.userReadableGeofencingType) {
          this.notification.content.query.schedule.scheduleType = this.userReadableGeofencingType[this.notification.content.query.schedule.scheduleType];
        }
        this.template = 'geoqueryDeactivatedState';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content;
      } else if (notificationType === NotificationType.SOMEDUS_STATUS) {
        this.template = 'somedusStatus';
        this.context.id = this.notification.id;
        this.context.content = this.notification.content;
      } else if (notificationType === NotificationType.AVATAR_EXPIRED) {
        this.template = 'avatarExpired';
        const avatars = this.notification.content.payload?.avatars?.map((avatarDto) => this.avatarService.decode(avatarDto));
        this.context.expiredAvatars = avatars?.filter((avatar) => avatar.isExpired) || [];
        this.context.soonToExpireAvatars = avatars?.filter((avatar) => avatar.expiresLessThanADay) || [];
        this.context.id = this.notification.id;
      }
    }
  }

  resubmitQuery(query, notificationID) {
    if (this.userBillingService.userHasEnoughCredits([BillingActions.QUERY_LOCATION])) {
      const queries = [
        {
          'telno': query.queryArgs.telno ? query.queryArgs.telno : '',
          'imsi': query.queryArgs.imsi ? query.queryArgs.imsi : ''
        }
      ];

      const resubmitQuerySubscription = this.queryService.quickQuery(queries).subscribe(
        () => {
          this.showMessage(this.translationService.translate('Query successfully resubmitted!'));
          this.queryService.refreshLogQueries.next(true);
          this.markAsRead(notificationID);
          resubmitQuerySubscription.unsubscribe();
        },
        () => {
          this.showMessage(this.translationService.translate('Resubmit Cancelled'));
          resubmitQuerySubscription.unsubscribe();
        }
      );
    }
  }

  showPopup(title, content) {
    Swal.fire({
      title,
      html: `${content}`,
      showCloseButton: true,
    });
  }

  markAsRead(notificationID) {
    this.notificationService.markNotificationsAsRead([notificationID]);
  }

  requestLocationHistory(queryArgs, notificationID) {
    const data = {
      'target': queryArgs.telno || queryArgs.imsi,
    };
    this.dialog.open(RequestLocationHistoryDialogComponent, {
      width: '30vw',
      height: 'auto',
      data,
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : (this.isWhiteTheme ? 'white-theme' : ''),
    });
    this.markAsRead(notificationID);
  }

  private showMarketingBanner(notification: Notification) {
    const fromDate = new Date(notification.content.marketingBanner.fromDate).getTime();
    const toDate = new Date(notification.content.marketingBanner.toDate).getTime();
    const now = new Date().getTime();
    if (fromDate < now && toDate > now) {
      this.dialog.open(MarketingBannerComponent, {
        width: this.isMobileResolution ? '90vw' : 'auto',
        height: this.isMobileResolution ? '90vh' : 'auto',
        maxWidth: '70vw',
        maxHeight: '90vh',
        panelClass: 'no-padding-dialog',
        data: notification,
      });
    }
  }
}
