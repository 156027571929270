import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DayEnum } from '@trg-commons/data-models-ts';
import { first } from 'lodash-es';
import { debounceTime, skip } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { DurationFormatPipe } from 'src/app/pipes/duration-format.pipe';
import { enumToArray } from 'src/app/shared/util/helper';
import { PredictedLocationsTimeFilters } from '../../models/predicted-locations-time-filters.interface';
import { SelectedTimeType } from '../../models/selected-time-type.enum';
import { SelectedTimes } from '../../models/selected-times.interface';

@Component({
  selector: 'app-predicted-locations-form',
  templateUrl: './predicted-locations-form.component.html',
  styleUrls: ['./predicted-locations-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredictedLocationsFormComponent extends BaseComponent implements OnInit {
  @Input() loading: boolean = false;
  @Output() predictedLocationsFilters = new EventEmitter<PredictedLocationsTimeFilters>();
  days: string[] = enumToArray(DayEnum);
  timeFormat: DurationFormatPipe = new DurationFormatPipe();
  sliderConfig: unknown;
  selectedFormControl: FormControl = new FormControl();
  selected: SelectedTimes = {
    predefined: {
      allWeek: true,
      weekdays: false,
      weekends: false,
      working: false,
      sleeping: false
    },
    hours: {
      from: 0,
      to: 23
    },
    sleepingHours: {
      from: 0,
      to: 6
    },
    workingHours: {
      from: 10,
      to: 17
    },
    days: []
  };
  SelectedTimeType: typeof SelectedTimeType = SelectedTimeType;

  constructor() {
    super();
    this.setupTimeRangeSlider();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.selectedFormControl.valueChanges.pipe(debounceTime(1800), skip(1)).subscribe(values => {
        this.emitPredictedLocationsFilters();
      })
    );
  }

  daysSelection(days: string[]): void {
    this.toggleAllPredefineSelections(false);
    this.selectedFormControl.setValue(this.selected);
  }

  togglePredefinedSelection(key: SelectedTimeType): void {
    const previousValue = this.selected.predefined[key];
    this.toggleAllPredefineSelections(false);
    this.selected.predefined[key] = !previousValue;
    this.selected.days = [];
    this.selectedFormControl.setValue(this.selected);
  }

  onTimeSliderChange([from, to]: string[]): void {
    this.selected.hours = {
      from: this.toHour(from),
      to: this.toHour(to)
    };

    this.selectedFormControl.setValue(this.selected);
  }

  emitPredictedLocationsFilters(): void {
    const { working, sleeping } = this.selected.predefined;
    const hours = working ? this.selected.workingHours : sleeping ? this.selected.sleepingHours : this.selected.hours;
    this.predictedLocationsFilters.next({
      days: this.getSelectedDays(),
      hours
    });
  }

  private toHour(value: string): number {
    const hour: string | undefined = first(value.split(':'));

    if (!hour) {
      throw new Error(`Failed to get hour from value ${value}`);
    }

    return parseInt(hour, 10);
  }

  private getSelectedDays(): string[] {
    const predicted: { [key in SelectedTimeType]: DayEnum[] } = {
      allWeek: enumToArray(DayEnum),
      weekdays: [DayEnum.Monday, DayEnum.Tuesday, DayEnum.Wednesday, DayEnum.Thursday, DayEnum.Friday],
      weekends: [DayEnum.Saturday, DayEnum.Sunday],
      sleeping: enumToArray(DayEnum),
      working: [DayEnum.Monday, DayEnum.Tuesday, DayEnum.Wednesday, DayEnum.Thursday, DayEnum.Friday]
    };

    const selected: [SelectedTimeType, boolean] | undefined = Object.entries(this.selected.predefined).find(
      ([, value]) => value
    ) as [SelectedTimeType, boolean];

    if (selected) {
      const key: SelectedTimeType = selected[0];
      return predicted[key];
    }

    return this.selected.days;
  }

  private toggleAllPredefineSelections(checked: boolean): void {
    this.selected.predefined = {
      allWeek: checked,
      weekdays: checked,
      weekends: checked,
      sleeping: checked,
      working: checked
    };
  }

  private setupTimeRangeSlider(): void {
    const secondsInDay = 60 * 60 * 23;
    this.sliderConfig = {
      behaviour: 'tap-drag',
      connect: true,
      tooltips: [true, true],
      format: {
        to: (number: number) => {
          return this.timeFormat.transform(Math.ceil(number), false);
        },
        from: (number: number) => {
          return number;
        }
      },
      start: [0, secondsInDay],
      range: {
        min: 0,
        max: secondsInDay
      },
      step: 60 * 60
    };
  }
}
