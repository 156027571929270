import { Injectable } from '@angular/core';
import { ActiveToast, ComponentType, ToastrService } from 'ngx-toastr';
import { DrawerComponent } from './drawer.component';
import { DrawerOptions } from './drawer.model';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {
  constructor(private toastr: ToastrService){}
  public show(component: ComponentType<unknown>, options: DrawerOptions): ActiveToast<DrawerComponent> {
    const toast = this.toastr.success(undefined, undefined, {
      toastComponent: DrawerComponent, 
      positionClass: options?.positionClass || 'toast-bottom-right',
    });
    toast.toastRef.componentInstance.options = options;
    toast.toastRef.componentInstance.component = component;
    toast.toastRef.componentInstance.closed$.subscribe(()=>{
      options.onClose();
      toast.toastRef.close();
    })
    return toast;
  } 

}
