import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/classes/base-form.component';
import { FormControl } from '@angular/forms';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { TargetService } from 'src/app/services/target/target.service';
import { CaseService } from 'src/app/services/case/case.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Action } from 'src/app/shared/classes/action.class';
import { ImageService } from 'src/app/services/image/image.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { uniq } from 'lodash-es';
import { RedirectSnackBarComponent } from 'src/app/shared/components/redirect-snack-bar/redirect-snack-bar.component';

@Component({
  selector: 'app-target-modal',
  templateUrl: './target-modal.component.html',
  styleUrls: ['./target-modal.component.scss']
})
export class TargetModalComponent extends BaseFormComponent implements OnInit {
  notAssociatedTargets: TargetItem[] = [];
  searchText = new FormControl();

  paginationNextPage;
  paginationPreviousPage;
  paginator = {
    pageSize: 5,
    currentPage: 0,
    totalSize: 0
  };
  caseTargets: TargetItem[] = [];
  showCasetargets = false;
  caseId: string;

  checkedTargets: TargetItem[] = [];
  paginatedCaseTargets: TargetItem[];
  currentPage: number;

  filterTargets: Subject<string> = new Subject();
  submitButtonText: string;

  constructor(
    protected dialog: MatDialogRef<TargetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private msgSnackBar: MatSnackBar,
    protected router: Router,
    private targetService: TargetService,
    private caseService: CaseService,
    private zone: NgZone,
    private imageService: ImageService,
    private translationService: TranslationService
  ) {
    super(dialog, router);
  }

  ngOnInit() {
    this.submitButtonText = 'Update target';
    if (this.data.caseId) {
      this.showCasetargets = true;
      this.loadCaseTargets();
    } else {
      this.loadAllTargets();
    }
  }

  loadCaseTargets() {
    this.caseService.getCaseTargets(this.data.caseId).subscribe(cases => {
      this.caseTargets = cases;

      this.paginator.totalSize = this.caseTargets.length;
      this.paginatedCaseTargets = this.caseTargets.slice(0, 5);
    });
  }

  onCasePageChange(pageData) {
    const pagesize = this.paginator.pageSize;

    if (pageData.previousPageIndex < pageData.pageIndex) {
      this.currentPage = this.paginator.currentPage + 1;

      const nextStartValue = this.currentPage * pagesize;
      const nextEndValue = (this.currentPage + 1) * pagesize;

      this.paginatedCaseTargets = this.caseTargets.slice(nextStartValue, nextEndValue);
    } else {
      this.currentPage = this.currentPage - 1;

      const prevStartValue = this.currentPage * pagesize;
      const prevEndValue = (this.currentPage + 1) * pagesize;

      this.paginatedCaseTargets = this.caseTargets.slice(prevStartValue, prevEndValue);
    }
  }

  loadAllTargets() {
    this.targetService
      .fetchPaginatedTargets({ limit: this.paginator.pageSize, page: 1, filterArg: '' }, true)
      .subscribe();

    const paginatedTargetsSubscription = this.targetService.paginatedCaseTargets.subscribe(
      (data: {
        result: TargetItem[];
        meta: {
          totalPages: number;
          totalResults: number;
          pageSize: number;
          page: number;
        };
      }) => {
        let target;
        this.notAssociatedTargets = [];
        for (const d of data.result) {
          target = { ...d, checked: false };
          this.notAssociatedTargets.push(target);
        }
        this.paginator.totalSize = data.meta.totalResults;
        this.paginator.currentPage = data.meta.page - 1;
        this.paginationNextPage = data.meta.page + 1;
        this.paginationPreviousPage = data.meta.page - 1;
      }
    );

    this.filterTargets.pipe(debounceTime(500)).subscribe(text => {
      this.targetService
        .fetchPaginatedTargets({ limit: this.paginator.pageSize, page: 1, filterArg: text }, true)
        .subscribe();
    });

    this.subscriptions.push(paginatedTargetsSubscription);
  }

  onPaginatePageChange(event) {
    if (event.previousPageIndex < event.pageIndex) {
      this.paginator.currentPage = this.paginator.currentPage + 1;
      const page = this.paginator.currentPage + 1;
      this.targetService
        .fetchPaginatedTargets(
          {
            limit: this.paginator.pageSize,
            page,
            filterArg: this.searchText.value || ''
          },
          true
        )
        .subscribe();
    } else {
      this.paginator.currentPage = this.paginator.currentPage - 1;
      const page = this.paginator.currentPage + 1;
      this.targetService
        .fetchPaginatedTargets(
          {
            limit: this.paginator.pageSize,
            page,
            filterArg: this.searchText.value || ''
          },
          true
        )
        .subscribe();
    }
  }

  getPhoto(photo: string) {
    if (!photo) {
      return;
    }
    return this.imageService.getPhotoUrl(photo);
  }
  save() {}

  addTargets(event: MatCheckboxChange, target: TargetItem) {
    if (event.checked) {
      this.checkedTargets.push(target);
    } else {
      const index = this.checkedTargets.findIndex(item => item.id === target.id);
      this.checkedTargets.splice(index, 1);
    }
  }

  addDataToTarget() {
    const ifas = this.data.ifas || [];
    if (ifas.length) {
      this.checkedTargets.forEach(target => {
        const targetIfas = target.ifas || [];
        targetIfas.push(...ifas);
        target.ifas = uniq(targetIfas);

        this.targetService.updateTargetIfas(target).subscribe(newtarget => {
          this.openSnackBar(target.id);
        });
      });
      this.close(new Action({ key: 'success' }));
    } else {
      this.checkedTargets.forEach((target: TargetItem) => {
        this.editTarget(target);
      });
      this.close(new Action({ key: 'success' }));
    }
  }

  private editTarget(target: TargetItem) {
    const updatedTarget = Object.assign({}, target);
    const telnos = this.data.telnos || this.data.msisdns || [];
    const imeis = this.data.imeis || [];
    const imsis = this.data.imsis || [];

    updatedTarget.telnos = uniq([...target.telnos, ...telnos]);
    updatedTarget.imeis = uniq([...target.imeis, ...imeis]);
    updatedTarget.imsis = uniq([...target.imsis, ...imsis]);

    const editSub = this.targetService.editTargetProfiler(target, updatedTarget).subscribe(
      () => {
        this.showMessage(this.translationService.translate('Target edited successfully!'));
        editSub.unsubscribe();
      },
      (error: any) => {
        this.showMessage(
          this.translationService.translate(error.messages ? error.messages : 'Target has not been edited')
        );
        editSub.unsubscribe();
      }
    );
  }

  openSnackBar(id: string) {
    this.zone.run(() => {
      const snackBar = this.msgSnackBar.openFromComponent(RedirectSnackBarComponent, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        data: {
          id,
          html: {
            start: `${this.translationService.translate('The target changes have been saved. Click')}`,
            end: `${this.translationService.translate('to view the target profile')}`
          }
        }
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }

  onKeyUp() {
    this.filterTargets.next(this.searchText.value);
  }
}
