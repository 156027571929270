<div class="location-query-top">
  <div class="imsis">
    <input placeholder="{{'Insert IMSI' | translate}}..."
      autocomplete="off"
      [(ngModel)]="value"
      class="imsis-input"
      [class.hasError]="errorMsg"
      [class.mobile]="isMobileResolution"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </div>
  <mat-chip-list #chipList>
    <div class="entry" *ngFor="let target of targets; let i = index">
      <mat-chip [removable]="removable" (removed)="remove(target)">
        <span matPrefix [ngClass]=target.country></span>
        {{target.value}}
        <mat-icon matChipRemove *ngIf="removable" class="location-query-icon">cancel</mat-icon>
      </mat-chip>
    </div>
  </mat-chip-list>
  <mat-error *ngIf="errorMsgForAoI">{{'No area is defined on the map.' | translate}}</mat-error>
</div>

<div class="row center-xs location-query-bottom">
  <div class="col-12">
    <div>
      <button mat-raised-button class="reset location-query-btn" (click)="resetFields()">{{'Reset' | translate}}</button>
      <button
        mat-raised-button
        class="submit"
        (click)="submitQuery()">
        {{'Submit' | translate}}
      </button>
    </div>
  </div>
</div>
