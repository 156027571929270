import { RedirectionRoutes } from './types';

export const isLoginPage = (url: string) => url === RedirectionRoutes.LOGIN;
export const formatTime = (time: number) => {
  const mins = Math.floor((time % 3600) / 60);
  const secs = Math.floor(time % 60);

  return `${[mins, `${secs < 10 ? '0' : ''}${secs}`].filter(n => n).join(':')}${
    mins ? 'min' : 's'
  }`;
};
