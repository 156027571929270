<div class="user-dialog-title">{{ title }}</div>
<mat-checkbox
  *ngIf="(loggedinUserIsAdmin || loggedinUserIsSupport || loggedinUserIsPower) && !editMode"
  labelPosition="after"
  (change)="toggleTemporaryUser($event)"
  [disabled]="loggedinUserIsPower"
  [checked]="loggedinUserIsPower"
  >{{ 'This is a 24hrs temporary user' | translate }}
</mat-checkbox>
<div mat-dialog-content *ngIf="userForm">
  <form [formGroup]="userForm" class="add-user-form">
    <mat-form-field appearance="outline" class="user-fields username">
      <mat-label>{{ 'Username' | translate }}</mat-label>
      <input matInput formControlName="username" maxlength="20" required />
      <mat-error *ngIf="userForm.controls.username.value && !userForm.controls.username.valid">{{
        'Only lowercase letters, numbers, dot (.) and underscore (_).' | translate
      }}</mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="user-fields email"
      *ngIf="loggedinUserIsAdmin || loggedinUserIsSupport || loggedinUserIsPower"
    >
      <mat-label>{{ 'Email' | translate }}</mat-label>
      <input matInput formControlName="email" type="email" />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="user-fields phone"
      *ngIf="loggedinUserIsAdmin || loggedinUserIsSupport || loggedinUserIsPower"
    >
      <mat-label>{{ 'Phone' | translate }}</mat-label>
      <input matInput class="phone-field" formControlName="otpPhone" autocomplete="off" />
    </mat-form-field>
    <mat-error *ngIf="errorMsg">{{ 'Enter a valid phone' | translate }}</mat-error>

    <div class="quota-type-section">
      <p>{{ 'Define quota' | translate }}</p>
      <mat-radio-group formControlName="limitType">
        <mat-radio-button class="quota-type-option" value="Fixed">{{ 'Fixed' | translate }}</mat-radio-button>
        <mat-radio-button class="quota-type-option" value="Daily">{{ 'Daily quota' | translate }}</mat-radio-button>
        <mat-radio-button class="quota-type-option" value="Monthly">{{ 'Monthly quota' | translate }}</mat-radio-button>
        <mat-radio-button class="quota-type-option" value="Maximum">{{ 'Maximum' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-container *ngFor="let pool of availablePools">
      <mat-form-field *ngIf="!userDialogService.CREDITS_EXPIRED" appearance="outline" class="credits-fields">
        <mat-label>
          <span *ngIf="pool.label">{{ pool.label | translate }}</span>
          {{ userForm.controls['limitType'].value + ' credits assigned' | translate }}
        </mat-label>
        <input matInput type="number" step="0.1" formControlName="{{ pool.value }}" required autocomplete="off" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        [ngClass]="userDialogService.CREDITS_EXPIRED ? 'user-fields' : 'credits-fields'"
      >
        <mat-label>
          <span *ngIf="pool.label">{{ pool.label | translate }}</span>
          {{ userForm.controls['limitType'].value + ' credits available' | translate }}
        </mat-label>
        <input
          matInput
          type="number"
          step="0.1"
          formControlName="{{ unassignPoolPrefix }}{{ pool.value }}"
          required
          autocomplete="off"
        />
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="showRoles && !loggedinUserIsPower" class="roles">
      <mat-label>{{ 'Roles' | translate }}</mat-label>
      <mat-select formControlName="role" [disabled]="editMode && user.isTemporary" required>
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="enableFeatures" class="roles">
      <mat-label>{{ 'Feature' | translate }}</mat-label>
      <mat-select formControlName="feature" [disabled]="editMode && user.isTemporary">
        <mat-option [value]="''">
          {{ 'None' | translate }}
        </mat-option>
        <mat-option *ngFor="let featureValue of allFeatures" [value]="featureValue">
          {{ featureValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="user-dialog-actions btn-wrp" *ngIf="userForm">
  <button mat-button class="btn-cancel" (click)="dialogRef.close()" type="button">{{ 'Cancel' | translate }}</button>
  <button mat-button class="btn-green" type="submit" [disabled]="!userForm.valid" (click)="onSubmit()">
    {{ 'Save' | translate }}
  </button>
</div>
