export const fr: {[key: string]: string} = {
        Scheduled: "Programmation",
        Scheduler: "Programmateur",
        Emergency: "Urgence",
        "is on emergency": `est en situation d'urgence`,
        "There are no investigations added. Please create a ": `Aucune enquête n'a été ajoutée. Veuillez créer une `,
        "new investigation": "nouvelle enquête",
        "See a demo investigation ": `Voir une démonstration d'enquête `,
        Tasks: "Tâches",
        "Live tasks": "Tâches en cours",
        Active: "Actif",
        Past: "Passée",
        msisdn: "MSISDN",
        MSISDN: "MSISDN",
        imsi: "IMSI",
        imei: "IMEI",
        home_mcc: "MCC",
        home_mnc: "MNC",
        lac: "LAC",
        cell_id: "ID de cellule",
        status: "État",
        phone_model: "Modèle",
        "USER EQUIPMENT": `Équipement de l'utilisateur`,
        Cell: "Cellule",
        "Query Date": "Date de la requête",
        "Query date": "Date de la requête",
        "Create User": "Créer un utilisateur",
        Username: `Nom d'utilisateur`,
        Password: "Mot de passe",
        "New Password": "Nouveau mot de passe",
        "New password": "Nouveau mot de passe",
        "Confirm New Password": "Confirmer le nouveau mot de passe",
        "Confirm new password": "Confirmer le nouveau mot de passe",
        Phone: "Téléphone",
        Email: "E-mail",
        Credits: "Crédits",
        credits: "crédits",
        Cancel: "Annuler",
        Save: "Enregistrer",
        "User Management": "Gestion des utilisateurs",
        "Activity Log": "Journal des activités",
        "Location log": "Journal de localisation",
        Admin: "Administrateur",
        Actions: "Actions",
        "Download CSV": "Télécharger le CSV",
        "Create Place": "Créer un lieu",
        "Add new place": "Ajouter un nouveau lieu",
        "Name of the area": "Nom de la zone",
        "Save area of Interest": `Enregistrer la zone d'intérêt`,
        Draw: "Dessiner",
        Reset: "Réinitialiser",
        reset: "réinitialiser",
        "Enter a valid username": `Veuillez entrer un nom d'utilisateur valide`,
        "Sign In": "Se connecter",
        Manager: "Gérer",
        "Reset Password": "Réinitialiser le mot de passe",
        Logout: "Déconnexion",
        "Sign out": "Se déconnecter",
        "Mark all as read": "Tout marquer comme lu",
        Pending: "En attente",
        Locating: "Localisation en cours",
        History: "Historique",
        "No entries": "Aucune entrée",
        "My places": "Mes lieux",
        "No places": "Aucun lieu",
        Name: "Nom",
        "Full Name": "Nom complet",
        User: "Utilisateur",
        Users: "Utilisateurs",
        Photo: "Photo",
        Color: "Couleur",
        Delete: "Supprimer",
        "Pending request": "Demande en attente.",
        "Call log requested": "Journal des appels demandé",
        "Request call log": "Demander le journal des appels",
        "Pending request. Click here to cancel":
          "Demande en attente. Cliquez ici pour annuler.",
        "No targets": "Aucune cible",
        Search: "Rechercher",
        "Select user": "Sélectionner un utilisateur",
        Edit: "Modifier",
        "Add user": "Ajouter un utilisateur",
        "Family Members": "Membres de la famille",
        "Location found": "Localisation trouvée",
        "Query pending": "Requête en attente",
        "No location": "Aucune localisation",
        Close: "Fermer",
        Locate: "Localiser",
        "Edit family member": "Modifier un membre de la famille",
        "Add new Target": "Ajouter une nouvelle cible",
        Fleet: "Flotte",
        Follow: "Suivre",
        Unfollow: "Ne plus suivre",
        "Add target": "Ajouter une cible",
        "Edit Target": "Modifier la cible",
        "Place created successfully!": "Lieu créé avec succès !",
        "Place has not been created": `Le lieu n'a pas été créé.`,
        "Passwords don't match.": "Les mots de passe ne correspondent pas.",
        "Invalid values. Please try again.":
          "Valeurs invalides. Veuillez réessayer.",
        "Invalid values. Please fill in all fields.":
          "Valeurs invalides. Veuillez remplir tous les champs.",
        "User edited successfully!": "Utilisateur modifié avec succès !",
        "User has not been edited": "L'utilisateur n'a pas été modifié",
        "New user #{username} created successfully!":
          "Nouvel utilisateur #{username} créé avec succès !",
        "Temporary password sent to: #{email}":
          "Mot de passe temporaire envoyé à : #{email}",
        "The password will be sent to the email address #{email}. Is this address correct?": `Le mot de passe sera envoyé à l'adresse e-mail #{email}. Cette adresse est-elle correcte ?`,
        "User has not been created": "L'utilisateur n'a pas été créé",
        Heatmap: "Carte thermique",
        "Place has been successfully deleted": "Le lieu a été supprimé avec succès",
        "Place has not been deleted": `Le lieu n'a pas été supprimé`,
        "Target edited successfully!": "Cible modifiée avec succès !",
        "Target has not been edited": "La cible n'a pas été modifiée.",
        "Target created successfully!": "Cible créée avec succès !",
        "Target has not been created": "La cible n'a pas été créée.",
        "Case created successfully!": "Cas créé avec succès !",
        "Case has not been edited": `Le cas n'a pas été modifié`,
        "Please fill in all fields": "Veuillez remplir tous les champs",
        "Maximum image size is 2MB. Please choose another image.": `La taille maximale de l'image est de 2 Mo. Veuillez choisir une autre image.`,
        "Your query has been queued": `Votre requête a été mise en file d'attente`,
        "Follow started for": "Suivi commencé pour",
        "Follow stopped for": "Suivi arrêté pour",
        "Target has been successfully deleted":
          "La cible a été supprimée avec succès",
        "Target has not been deleted": `La cible n'a pas été supprimée`,
        Radius: "Rayon",
        "Total distance": "Distance totale",
        "Enter a valid phone": "Veuillez entrer un téléphone valide",
        "Something went wrong with the photo. Please try again.": `Quelque chose s'est mal passé avec la photo. Veuillez réessayer.`,
        "User has been successfully deleted": `L'utilisateur a été supprimé avec succès`,
        Map: "Carte",
        "Show query history": `Afficher l'historique des requêtes`,
        "Hide query history": `Masquer l'historique des requêtes`,
        Roles: "Rôles",
        "Place of interest": `Lieu d'intérêt`,
        "Drag the map to precise your location":
          "Faites glisser la carte pour préciser votre emplacement",
        Family: "Famille",
        Queries: "Requêtes",
        Notifications: "Notifications",
        "Follow is already enabled.": "Le suivi est déjà activé.",
        "Follow is already disabled.": "Le suivi est déjà désactivé.",
        "User not found": "Utilisateur introuvable",
        "Invalid credentials provided": "Identifiants invalides fournis",
        "Invalid OTP code provided": "Code OTP invalide fourni",
        Continue: "Continuer",
        Places: "Lieux",
        "Enter Password": "Entrer le mot de passe",
        "Enter OTP": "Entrer le code OTP",
        "Saved!": "Enregistré !",
        "Could not save!": `Impossible d'enregistrer !`,
        Log: "Journal",
        Target: "Cible",
        Targets: "Cibles",
        "Select dates": "Sélectionner des dates",
        Filter: "Filtrer",
        Geolocation: "Géolocalisation",
        Multiple: "Multiple",
        Date: "Date",
        Type: "Type",
        Export: "Exporter",
        Exporting: "Exportation",
        Refresh: "Actualiser",
        phone: "téléphone",
        "MOBILE NETWORK": "Réseau mobile",
        location: "localisation",
        LOCATION: "Localisation",
        "Target details": "Détails de la cible",
        Query: "Requête",
        "New Query": "Nouvelle requête",
        "AoI Query": "Requête AoI",
        "Advanced Options": "Options avancées",
        Schedule: "Programmation",
        "Log on": "Connexion",
        Proximity: "Proximité",
        "Insert phones": "Insérer des téléphones",
        Submit: "Soumettre",
        "Query number": "Numéro de requête",
        "Select frequency": "Sélectionner la fréquence",
        "Outside the circle": `À l'extérieur du cercle`,
        Country: "Pays",
        "Area of interest": `Zone d'intérêt`,
        "No area is defined on the map.": `Aucune zone n'est définie sur la carte.`,
        "Enter a valid number": "Veuillez entrer un numéro valide",
        "Enter a number": "Veuillez entrer un numéro",
        "Query created successfully!": "Requête créée avec succès !",
        "Query successfully resubmitted!": "Requête réenvoyée avec succès !",
        "Resubmit Cancelled": "Réenvoi annulé",
        "Query has not been created": `La requête n'a pas été créée.`,
        Status: "Statut",
        Subscription: "Abonnement",
        "Insert IMSI": "Insérer IMSI",
        "Insert MSISDN": "Insérer MSISDN",
        "Create target": "Créer une cible",
        Geofencing: "Géorepérage",
        "All caught up": "Tout est à jour",
        "Resubmit query": "Réenvoyer la requête",
        "Imsi query": "Requête IMSI",
        "Inside the circle": `À l'intérieur du cercle`,
        "Select country": "Sélectionner le pays",
        "Save area": "Enregistrer la zone",
        "Draw area": "Dessiner la zone",
        "Are you sure you wish to resubmit?":
          "Êtes-vous sûr de vouloir soumettre à nouveau ?",
        "Delete Target": "Supprimer la cible",
        Clear: "Effacer",
        "Export to pdf": "Exporter en PDF",
        "Download report": "Télécharger le rapport",
        located: "localisé",
        "not located": "non localisé",
        "Show history": `Afficher l'historique`,
        "Total credits": "Crédits totaux",
        "Total queries": "Total des requêtes",
        "Total successful queries": "Total des requêtes réussies",
        "Total credits deducted": "Total des crédits déduits",
        Reason: "Raison",
        Lock: "Verrouiller",
        Unlock: "Déverrouiller",
        Intelligence: "Intelligence",
        Photos: "Photos",
        Articles: "Articles",
        "See all photos": "Voir toutes les photos",
        Group: "Groupe",
        Groups: "Groupes",
        "Add Group": "Ajouter un groupe",
        "Edit Group": "Modifier le groupe",
        "Associated with": "Associé à",
        Gender: "Genre",
        Location: "Emplacement",
        "Delete photo": "Supprimer la photo",
        "Upload photo": "Télécharger une photo",
        Details: "Détails",
        "Call log map": "Carte du journal des appels",
        Analysis: "Analyse",
        "No credits": "Pas de crédits",
        "Date of query": "Date de la requête",
        "Last seen online": "Dernière connexion en ligne",
        About: "À propos de",
        Go: "Aller",
        "Date of birth": "Date de naissance",
        "Intel search": `Recherche d'intelligence`,
        "Full Post": "Publication complète",
        "Post Information": "Informations sur la publication",
        "PDF layout": "Disposition du PDF",
        Disclaimer: "Avertissement",
        "QR code": "Code QR",
        Alias: "Alias",
        Aliases: "Alias",
        Settings: "Paramètres",
        "User management": "Gestion des utilisateurs",
        Dashboard: "Tableau de bord",
        "Event viewer": `Visionneuse d'événements`,
        Ledger: "Grand livre",
        Title: "Titre",
        "Confidential content": "Contenu confidentiel",
        "Disclaimer content": `Contenu de l'avertissement`,
        "Title content": "Contenu du titre",
        Confidential: "Confidentiel",
        "Company Logo": `Logo de l'entreprise`,
        "Page numbering": "Numérotation des pages",
        "Company Logo Url": `URL du logo de l'entreprise`,
        "Map circle": "Cercle de la carte",
        "Simple details": "Détails simples",
        "PDF settings updated successfully!":
          "Paramètres PDF mis à jour avec succès !",
        "Something went wrong. Please try again.": `Quelque chose s'est mal passé. Veuillez réessayer.`,
        "Phone Model": "Modèle de téléphone",
        Provider: "Fournisseur",
        Lat: "Latitude",
        Lon: "Longitude",
        Latitude: "Latitude",
        Longitude: "Longitude",
        "Query Id": "ID de requête",
        "Date Of Query": "Date de la requête",
        Network: "Réseau",
        Radio: "Connexion",
        "Cell Id": "ID de cellule",
        "Cell ENBID": "ENBID de cellule",
        "Cell LCID": "LCID de cellule",
        "Cell ECI": "ECI de cellule",
        LAC: "LAC",
        "No date provided": "Aucune date fournie",
        Level: "Niveau",
        Command: "Commande",
        Message: "Message",
        "LOG IN": "CONNEXION",
        PASSWORD: "MOT DE PASSE",
        USERNAME: `NOM D'UTILISATEUR`,
        "Site properties": "Propriétés du site",
        "Session Timeout": "Expiration de la session",
        "Map default countries": "Pays par défaut sur la carte",
        Archive: "Archive",
        "Blacklist number": "Numéro de liste noire",
        All: "Tout",
        "Success!": "Succès !",
        "Failed!": "Échec !",
        "Query selected": "Requête sélectionnée",
        "Please select a query": "Veuillez sélectionner une requête",
        "Intelligence query": `Requête d'intelligence`,
        Blacklist: "Liste noire",
        "Show batch query": "Afficher la requête par lot",
        Options: "Options",
        Verified: "Vérifié",
        Following: "Suivant",
        "Do you wish to perform an intelligence query?": `Souhaitez-vous effectuer une requête d'intelligence ?`,
        Keywords: "Mots-clés",
        Keyword: "Mot-clé",
        "No groups": "Aucun groupe",
        "Enter a valid IMSI": "Veuillez entrer un IMSI valide",
        "Enter a valid TMSI": "Veuillez entrer un TMSI valide",
        "Enter a valid IMEI": "Veuillez entrer un IMEI valide",
        "Save result": "Enregistrer le résultat",
        "Are you sure you wish to save this result?":
          "Êtes-vous sûr de vouloir enregistrer ce résultat ?",
        Followings: "Suivis",
        Followers: "Abonnés",
        "Show more": "Afficher plus",
        "Results Found": "Résultats Trouvés",
        Value: "Valeur",
        "Enter a valid value": "Veuillez entrer une valeur valide",
        "Clear map": "Effacer la carte",
        "Phone Numbers": "Numéros de téléphone",
        "Group edited successfully!": "Groupe modifié avec succès !",
        "Group has not been edited": `Le groupe n'a pas été modifié.`,
        "Group created successfully!": "Groupe créé avec succès !",
        "Group has not been created": `Le groupe n'a pas été créé.`,
        "Delete Group": "Supprimer le groupe",
        "Group has been successfully deleted":
          "Le groupe a été supprimé avec succès",
        "Group has not been deleted": `Le groupe n'a pas été supprimé.`,
        "Image Analysis": `Analyse d'image`,
        "Dark Web": "Web sombre",
        Darkweb: "Darkweb",
        Posts: "Publications",
        Friends: "Amis",
        "Select File": "Sélectionner un fichier",
        FACES: "VISAGES",
        LABELS: "ÉTIQUETTES",
        "WEB ENTITIES": "ENTITÉS WEB",
        METADATA: "MÉTADONNÉES",
        "PARTIALLY MATCHING IMAGES": "IMAGES PARTIELLEMENT CORRESPONDANTES",
        "SIMILAR IMAGES": "IMAGES SIMILAIRES",
        "PAGES WITH MATCHING IMAGES": "PAGES AVEC DES IMAGES CORRESPONDANTES",
        "SAFE SEARCH": "RECHERCHE SÉCURISÉE",
        "Screen name": `Nom d'écran`,
        Statistics: "Statistiques",
        "Connected numbers by device for": "Numéros connectés par appareil pour",
        "Connected numbers by location for":
          "Numéros connectés par localisation pour",
        "Phone number": "Numéro de téléphone",
        Distance: "Distance",
        Time: "Temps",
        seconds: "secondes",
        Severity: "Gravité",
        Major: "Majeur",
        Minor: "Mineur",
        Critical: "Critique",
        "Wrong Credentials": "Identifiants incorrects",
        Address: "Adresse",
        "No queries": "Aucune requête",
        Back: "Retour",
        "MSISDN is not linked with a WhatsApp account":
          "Le MSISDN n'est pas lié à un compte WhatsApp",
        "Remove filters": "Supprimer les filtres",
        "Simple Query": "Requête simple",
        "Export to CSV": "Exporter au format CSV",
        Today: "Aujourd'hui",
        Yesterday: "Hier",
        "Login Now": "Connectez-vous maintenant",
        "End Date": "Date de fin",
        "End date": "Date de fin",
        Frequency: "Fréquence",
        Located: "Localisés",
        "Not Located": "Non localisés",
        "Distribution of keywords on platforms":
          "Répartition des mots-clés sur les plateformes",
        "Show more cases": "Afficher plus de cas",
        "Date & time": "Date et heure",
        Session: "Session",
        "Show less cases": "Afficher moins de cas",
        "Task ID": "ID de tâche",
        minutes: "minutes",
        "Task has been successfully canceled": "La tâche a été annulée avec succès",
        "Task has not been canceled": `La tâche n'a pas été annulée`,
        "Robot has not been canceled": `Le robot n'a pas été annulé`,
        "From Date": "À partir de la date",
        MCC: "MCC",
        MNC: "MNC",
        IMSI: "IMSI",
        "Query canceled": "La requête a été annulée",
        "Query has not been canceled": `La requête n'a pas été annulée`,
        "Add hashtag for this query": "Ajouter un hashtag pour cette requête",
        "Query hashtag": "Hashtag de la requête",
        "Hashtag added successfully!": "Hashtag ajouté avec succès !",
        "Hashtag has not been added": `Le hashtag n'a pas été ajouté`,
        "Data saved successfully!":
          "Les données ont été enregistrées avec succès !",
        "Something went wrong.": `Quelque chose s'est mal passé.`,
        "Save data to target": "Enregistrer les données pour la cible",
        "Select target": "Sélectionner une cible",
        "was turned on": "a été activé",
        "is Offline. Last Known Location found":
          "est hors ligne. Dernière localisation connue trouvée",
        "was turned off": "a été désactivé",
        "was found near": "a été trouvé près de",
        in: "dans",
        "was found": "a été trouvé",
        Resubmit: "Soumettre à nouveau",
        "Possible location for": "Emplacement possible pour",
        inside: `à l'intérieur`,
        outside: `à l'extérieur`,
        is: "est",
        "the geofenced area": "la zone de géolocalisation",
        entered: "entré",
        Disable: "Désactiver",
        "Are you sure you want to disable the geofencing?":
          "Êtes-vous sûr de vouloir désactiver la géolocalisation ?",
        "Geofencing canceled!": "Géolocalisation annulée !",
        left: "quitté",
        "Task for": "Tâche pour",
        "with ID:": "avec ID :",
        "has finished": "a terminé",
        "scheduled task has finished": "la tâche programmée est terminée",
        Hashtag: "Hashtag",
        Program: "Programme",
        "Task Id": "ID de la tâche",
        "New query": "Nouvelle requête",
        Analytics: "Analytique",
        "Outside of area": "Hors de la zone",
        "Location History Not Found": "Historique de localisation introuvable",
        "Request from Support": "Demande de support",
        "Location History Found": "Historique de localisation trouvé",
        "entries were imported": "entrées ont été importées",
        "Note: Please leave input box empty to remove current hashtag":
          "Note : Veuillez laisser la case de saisie vide pour supprimer le hashtag actuel",
        "Call log": `Journal d'appels`,
        "Call Log Analysis": `Analyse du journal d'appels`,
        "Past tasks": "Tâches passées",
        "Start Date": "Date de début",
        "Start date": "Date de début",
        Created: `Créé`,
        Language: "Langue",
        "User options": "Options utilisateur",
        "User manual": "Manuel utilisateur",
        Sort: "Trier",
        "Remove filters and restore queries":
          "Supprimer les filtres et restaurer les requêtes",
        group: "groupe",
        "Geo Country": "Pays géo",
        "Geo AoI": `Zone d'intérêt géo`,
        type: "type",
        GO: "ALLER",
        "Do you wish to perform an intelligence query or view the existing intel?": `Souhaitez-vous effectuer une requête d'intelligence ou consulter l'intelligence existante ?`,
        "New intel query": `Nouvelle requête d'intelligence`,
        "Show existing intel": `Afficher l'intelligence existante`,
        OTP: "OTP",
        "Please add phone or email to enable OTP":
          "Veuillez ajouter un téléphone ou un e-mail pour activer OTP",
        "Enable OTP": "Activer OTP",
        Roaming: "Roaming",
        "Last hour": "Dernière heure",
        Previous: "Précédent",
        "Send SMS": "Envoyer un SMS",
        Text: "Texte",
        Send: "Envoyer",
        "Profile URL": "URL du profil",
        "Open in website": "Ouvrir dans le site web",
        "Location History Request": `Demande d'historique de localisation`,
        "Call Log Request": `Demande de journal d'appels`,
        "Upload Call Log": `Télécharger le journal d'appels`,
        "Request has not been sent": `La demande n'a pas été envoyée`,
        "Request sent successfully!": "Demande envoyée avec succès !",
        "Show call log details": `Afficher les détails du journal d'appels`,
        "Show call log map": `Afficher la carte du journal d'appels`,
        "Origin position": `Position d'origine`,
        Direction: "Direction",
        Duration: "Durée",
        From: "De",
        To: "À",
        "Call detail records for": `Détails des enregistrements d'appels pour`,
        "Overall records": "Enregistrements globaux",
        "Number most contacted": "Numéro le plus contacté",
        "Number most received": "Numéro le plus reçu",
        "Longest call": "Appel le plus long",
        "Shortest call": "Appel le plus court",
        "Select period": "Sélectionner une période",
        "last 30 days": "30 derniers jours",
        "last 60 days": "60 derniers jours",
        "last 90 days": "90 derniers jours",
        "File uploaded successfully!": "Le fichier a été téléchargé avec succès !",
        "Error while uploading file": "Erreur lors du téléchargement du fichier",
        or: "ou",
        "Browse files": "Parcourir les fichiers",
        Downloading: "Téléchargement en cours",
        "Change password": "Changer le mot de passe",
        "Change Password": "Changer le mot de passe",
        English: "Anglais",
        Spanish: "Espagnol",
        "Export call log": `Exporter le journal d'appels`,
        "Wrong template.": "Modèle incorrect.",
        "Call log MSISDN is different from target MSISDN.": `Le MSISDN du journal d'appels est différent du MSISDN cible.`,
        "Invalid file format provided.": "Format de fichier non valide fourni.",
        "If you edit or delete the MSISDN the Call Log for this target will be removed":
          "Si vous modifiez ou supprimez le MSISDN, le journal d'appels pour cette cible sera supprimé.",
        all: "tous",
        monday: "lundi",
        tuesday: "mardi",
        wednesday: "mercredi",
        thursday: "jeudi",
        friday: "vendredi",
        saturday: "samedi",
        sunday: "dimanche",
        weekdays: "jours de la semaine",
        weekends: "week-ends",
        "Call Log Request Pending": `Demande de journal d'appels en attente`,
        "Session has expired!": "La session a expiré !",
        "You will be redirected to login screen.": `Vous serez redirigé vers l'écran de connexion.`,
        "ALL QUERIES FOUND": "TOUTES LES REQUÊTES TROUVÉES",
        "ALL UNREACHABLE QUERIES": "TOUTES LES REQUÊTES INACCESSIBLES",
        "Unique entries": "Entrées uniques",
        Found: "Trouvé",
        Unreachable: "Inaccessible",
        "Last Hour": "Dernière heure",
        "Last 24 hours": "Dernières 24 heures",
        "Last week": "Semaine dernière",
        "No statistics available for the selected period.":
          "Aucune statistique disponible pour la période sélectionnée.",
        "License Plate": `Plaque d'immatriculation`,
        "Not enough information": `Pas suffisamment d'informations`,
        "Not enough geolocation data or call logs data to predict locations. Would you like to create a schedule?": `Pas suffisamment de données de géolocalisation ou de journaux d'appels pour prédire les emplacements. Souhaitez-vous créer un planning ?`,
        "Would you like to import more data":
          "Souhaitez-vous importer plus de données",
        "Would you like to a create a schedule": "Souhaitez-vous créer un planning",
        Yes: "Oui",
        "Schedule has been created": "Le planning a été créé",
        "Request has been sent to support": "La demande a été envoyée au support",
        high: "élevé",
        medium: "moyen",
        low: "faible",
        "No. of calls": `Nombre d'appels`,
        Overall: "Global",
        Count: "Compte",
        Hour: "Heure",
        "Group ID": "ID de groupe",
        DEBUG: "Débogage",
        "Geo Query": "Requête géo",
        "Intel Query": "Requête Intel",
        authentication: "authentification",
        ERROR: "ERREUR",
        INFO: "INFO",
        "Preferred channel": "Canal préféré",
        "OTP Channel": "Canal OTP",
        None: "Aucun",
        MNOs: "Opérateurs mobiles",
        Whitelist: "Liste blanche",
        "Whitelist Number": "Numéro de liste blanche",
        "Remove from whitelist": "Supprimer de la liste blanche",
        Add: "Ajouter",
        "has been captured on": "a été capturé le",
        "Inform Security Personel": "Informer le personnel de sécurité",
        "Toggle map": "Basculer la carte",
        "Toggle photo": "Basculer la photo",
        "An error has occurred": `Une erreur s'est produite`,
        Imported: "Importé",
        Tactical: "Tactique",
        "Items per page": "Éléments par page",
        "Next page": "Page suivante",
        "Previous page": "Page précédente",
        "nearby towers found": "tour(s) à proximité trouvée(s)",
        "Communication Channel": "Canal de communication",
        "Please add phone to enable sms notifications":
          "Veuillez ajouter un téléphone pour activer les notifications par SMS",
        "Please add email to enable email notifications":
          "Veuillez ajouter une adresse e-mail pour activer les notifications par e-mail",
        "Disable sms notifications": "Désactiver les notifications par SMS",
        "Enable sms notifications": "Activer les notifications par SMS",
        "Enable email notifications": "Activer les notifications par e-mail",
        "Disable email notifications": "Désactiver les notifications par e-mail",
        "For authorized use only": "Réservé à un usage autorisé uniquement",
        "Notify me on Updates": "Me notifier lors des mises à jour",
        "Inside of area": `À l'intérieur de la zone`,
        "Pending... Click to cancel": "En attente... Cliquez pour annuler",
        "Whitelist Numbers": "Numéros de la liste blanche",
        "Create a Geofencing Task (1 week / 15 minutes)":
          "Créer une tâche de géorepérage (1 semaine / 15 minutes)",
        "Create a Geofencing Task (6 hours / 15 minutes)":
          "Créer une tâche de géorepérage (6 heures / 15 minutes)",
        "Stop Geofencing Task": "Arrêter la tâche de géorepérage",
        "Get the Neighbour Cells (1 credit)":
          "Obtenir les cellules voisines (1 crédit)",
        "Get the Neighbour Cells": "Obtenir les cellules voisines",
        "Create a Schedule Task (1 week / 15 minutes)":
          "Créer une tâche planifiée (1 semaine / 15 minutes)",
        "Create a Schedule Task (6 hours / 15 minutes)":
          "Créer une tâche planifiée (6 heures / 15 minutes)",
        "Click for more options": `Cliquez pour plus d'options`,
        "Hide options": "Masquer les options",
        Recipient: "Destinataire",
        "Safety Cameras": "Caméras de sécurité",
        "Intelligence Query": `Requête d'intelligence`,
        "Call Log Cancel Request": `Demande d'annulation du journal d'appels`,
        "Cancel Request sent successfully": `Demande d'annulation envoyée avec succès`,
        "Search by": "Rechercher par",
        "Contact Us": "Contactez-nous",
        "Maximum allowed size:": "Taille maximale autorisée :",
        "Support request": "Demande de support",
        "Allowed file types: images": "Types de fichiers autorisés : images",
        "Select file": "Sélectionner un fichier",
        "Roaming Provider": `Fournisseur d'itinérance`,
        LANGUAGE: "LANGUE",
        "Username must contain at least one letter": `Le nom d'utilisateur doit contenir au moins une lettre`,
        "View full profile": "Voir le profil complet",
        "View short profile": "Voir le profil abrégé",
        "Social Media": "Réseaux sociaux",
        Overview: `Vue d'ensemble`,
        Feed: "Flux",
        "Phone links": "Liens téléphoniques",
        "Link analysis": "Analyse des liens",
        "Link analysis v2": "Analyse des liens v2",
        "Geo Follow": "Suivi géographique",
        "Geolocation scheduler will start after radical speech detection!":
          "La planification de la géolocalisation démarrera après la détection de discours radical !",
        Monitoring: "Surveillance",
        "Radical speech monitoring": "Surveillance des discours radicaux",
        "Radical monitoring": "Surveillance radicale",
        "results found": "résultats trouvés",
        Start: "Démarrer",
        Stop: "Arrêter",
        Content: "Contenu",
        Platform: "Plateforme",
        "Target number": "Numéro cible",
        "Analyzed content": "Contenu analysé",
        "No social data for current targets!":
          "Aucune donnée sociale pour les cibles actuelles !",
        "Permanently delete target": "Supprimer définitivement la cible",
        "Permanently delete case": "Supprimer définitivement le cas",
        "The files once deleted cannot be restored.":
          "Les fichiers supprimés ne peuvent pas être récupérés.",
        "Once deleted, the cases cannot be restored.":
          "Une fois supprimés, les cas ne peuvent pas être récupérés.",
        "Are you sure you want to delete target permanently?":
          "Êtes-vous sûr de vouloir supprimer définitivement la cible ?",
        "Are you sure you want to delete case permanently?":
          "Êtes-vous sûr de vouloir supprimer définitivement le cas ?",
        "Create a target": "Créer une cible",
        "Request Call Log": `Demander le journal d'appels`,
        "Latest Location": "Dernière localisation",
        "Location Heatmap": "Carte thermique de localisation",
        "Location Timeline": "Chronologie de localisation",
        "Location Probalility": "Probabilité de localisation",
        "Loading call log data": `Chargement des données du journal d'appels`,
        "Create new target": "Créer une nouvelle cible",
        "Total number of sessions": "Nombre total de sessions",
        "Total monitor time": "Temps total de surveillance",
        "Create new keywords list": "Créer une nouvelle liste de mots-clés",
        "Configure keywords list": "Configurer la liste de mots-clés",
        "Create new list": "Créer une nouvelle liste",
        "Add new keywords list": "Ajouter une nouvelle liste de mots-clés",
        "Session started on the": "Session commencée le",
        "and ended on the": "et terminée le",
        "Keywords list": "Liste de mots-clés",
        "No selected list": "Aucune liste sélectionnée",
        "Maximum image size is 2MB.": `La taille maximale de l'image est de 2 Mo.`,
        "Supported file types": "Types de fichiers pris en charge",
        "No call log found for this target. Please use the menu to request or upload a call log.": `Aucun journal d'appels trouvé pour cette cible. Veuillez utiliser le menu pour demander ou télécharger un journal d'appels.`,
        "Export PDF": "Exporter en PDF",
        "Intelligence is not available yet": `L'intelligence n'est pas encore disponible`,
        'Add seeds and click "Enchance target" to get "Most common faces"': `Ajoutez des graines et cliquez sur "Améliorer l'objectif" pour obtenir "Visages les plus courants"`,
        "Social Media Profiles": "Profils sur les réseaux sociaux",
        Online: "En ligne",
        "Phone Info": "Informations sur le téléphone",
        "Location Info": "Informations de localisation",
        "Show query details": "Afficher les détails de la requête",
        more: "plus",
        "Select more targets": `Sélectionnez plus d'objectifs`,
        "Finish adding targets": `Terminer l'ajout d'objectifs`,
        "Add selected targets": "Ajouter les objectifs sélectionnés",
        "Date of creation": "Date de création",
        "Last modifications": "Dernières modifications",
        "Finish editing": "Terminer la modification",
        "Exporting call log": `Export du journal d'appels`,
        Inserted: "Insérés",
        records: "enregistrements",
        "Last Seen": "Dernière apparition",
        Timeline: "Chronologie",
        "Predicted locations": "Emplacements prévus",
        "Places of interest": `Lieux d'intérêt`,
        "Edit places": "Modifier les lieux",
        "BASE LAYERS": "COUCHES DE BASE",
        "ADDITIONAL LAYERS": "COUCHES SUPPLÉMENTAIRES",
        OPTIONS: "OPTIONS",
        "Remove layer": "Supprimer la couche",
        "Enable layer": "Activer la couche",
        "Query MSISDN": "Interroger le MSISDN",
        "Invalid MSISDN": "MSISDN invalide",
        "Loading target details": `Chargement des détails de l'objectif`,
        "Enable traffic layer": "Activer la couche de trafic",
        "Disable traffic layer": "Désactiver la couche de trafic",
        "Enable target heatmap": `Activer la carte de chaleur de l'objectif`,
        "Disable target heatmap": `Désactiver la carte de chaleur de l'objectif`,
        "Enable target history timeline": `Activer la chronologie de l'historique de l'objectif`,
        "Disable target history timeline": `Désactiver la chronologie de l'historique de l'objectif`,
        "Available Data": "Données disponibles",
        "No Data Available": "Pas de données disponibles",
        "No data": "Aucune donnée",
        "Resize map": "Redimensionner la carte",
        "Cases and targets": "Cas et objectifs",
        "Show less": "Afficher moins",
        "Create new case": "Créer un nouveau cas",
        Cases: "Cas",
        "Call duration": `Durée de l'appel`,
        "Always show descriptions": "Toujours afficher les descriptions",
        "Hide descriptions": "Masquer les descriptions",
        "Calls and SMS": "Appels et SMS",
        Calls: "Appels",
        "All week": "Toute la semaine",
        Weekdays: "Jours de semaine",
        Weekends: "Fins de semaine",
        "Incoming and Outgoing": "Entrants et sortants",
        Incoming: "Entrant",
        Outgoing: "Sortant",
        Cumulative: "Cumulatif",
        Individual: "Individuel",
        "No cases defined": "Aucun cas défini",
        "No targets defined": "Aucun objectif défini",
        "No more than 255 characters long": "Pas plus de 255 caractères",
        "No more than 1024 characters long": "Pas plus de 1024 caractères",
        "You have more than 255 characters": "Vous avez plus de 255 caractères",
        "You have more than 1024 characters": "Vous avez plus de 1024 caractères",
        "Title is required": "Le titre est requis",
        "Area of Interest Query": `Requête d'intérêt`,
        Select: "Sélectionner",
        "Redraw area": "Redessiner la zone",
        "Select area": "Sélectionner la zone",
        "Origin Position": `Position d'origine`,
        Data: "Données",
        Longest: "La plus longue",
        Shortest: "La plus courte",
        with: "avec",
        from: "de",
        to: "à",
        "data used at": "données utilisées à",
        Call: "Appel",
        at: "à",
        "Live data": "Données en direct",
        "Number of sessions": "Nombre de sessions",
        Busy: "Occupé",
        OFF: "Éteint",
        ON: "Allumé",
        "Last known location": "Dernière position connue",
        Unknown: "Inconnu",
        Wordcloud: "Nuage de mots",
        "Daily activity": "Activité quotidienne",
        "Weekly activity": "Activité hebdomadaire",
        MAPS: "CARTES",
        STATISTICS: "STATISTIQUES",
        "Related people": "Personnes associées",
        "Sentiment analysis": "Analyse de sentiment",
        Entity: "Entité",
        Positive: "Positif",
        Neqative: "Négatif",
        Neutral: "Neutre",
        Media: "Média",
        "Most common faces": "Visages les plus courants",
        "Social profiles": "Profils sociaux",
        "Instant messaging": "Messagerie instantanée",
        "Employment and education": "Emploi et éducation",
        "Add a new note": "Ajouter une nouvelle note",
        Publish: "Publier",
        "Advanced Information Request": `Demande d'informations avanc`,
        "AT&T post-paid only": "AT&T post-paiement uniquement",
        "This action will deduct 4 credits. This may take up to 30 min.": `Cette action déduira 4 crédits. Cela peut prendre jusqu'à 30 minutes.`,
        Proceed: "Procéder",
        "Upload image": "Télécharger une image",
        "Draw marker": "Dessiner un marqueur",
        "Note was added": "Note ajoutée",
        "Image attached": "Image jointe",
        "Add a note": "Ajouter une note",
        "Added on": "Ajouté le",
        "View post details": "Voir les détails de la publication",
        "View geolocation details": "Voir les détails de géolocalisation",
        "Post was added": "Publication ajoutée",
        Post: "Publication",
        Notes: "Notes",
        "Time of collection": "Heure de collecte",
        "Geolocation details": "Détails de géolocalisation",
        "Date created": "Date de création",
        "Last modified": "Dernière modification",
        "No phone links defined": "Aucun lien téléphonique défini",
        "Name(s)": "Nom(s)",
        "Phone(s)": "Téléphone(s)",
        "MSISDN(s)": "MSISDN(s)",
        Countries: "Pays",
        "Address(es)": "Adresse(s)",
        "Add new": "Ajouter un nouveau",
        "Time Zone": "Fuseau horaire",
        "Job(s)": "Emploi(s)",
        "Date Of Birth": "Date de naissance",
        "Place Of Birth": "Lieu de naissance",
        "Marital Status": "État civil",
        Nationalities: "Nationalités",
        Race: "Race",
        "Username(s)": `Nom(s) d'utilisateur`,
        "License Plate(s)": `Plaque(s) d'immatriculation`,
        "Work Place(s)": "Lieu(x) de travail",
        "Eyes Color": "Couleur des yeux",
        "Hair Color": "Couleur de cheveux",
        "Facial Hair": "Poils du visage",
        "Social Insurance": "Assurance sociale",
        Height: "Taille",
        Weight: "Poids",
        "Email(s)": "Adresse(s) e-mail",
        "IMSI(s)": "IMSI(s)",
        Remove: "Supprimer",
        "Add new value": "Ajouter une nouvelle valeur",
        "Enter phone numbers": "Entrez les numéros de téléphone",
        "Type value here": "Tapez la valeur ici",
        "Edit a target": "Modifier une cible",
        Create: "Créer",
        Update: "Mettre à jour",
        "Must be at least 3 characters long, special charactes and preceding or following spaces are not allowed":
          "Doit comporter au moins 3 caractères, les caractères spéciaux ainsi que les espaces avant ou après ne sont pas autorisés.",
        "Select timezone": "Sélectionnez le fuseau horaire",
        Items: "Articles",
        "Once deleted the data cannot be restored":
          "Une fois les données supprimées, elles ne peuvent pas être restaurées",
        "Alias exists. Please use another alias":
          "Cet alias existe déjà. Veuillez en utiliser un autre",
        "Title exists. Please use another title":
          "Ce titre existe déjà. Veuillez en utiliser un autre",
        "New note text": "Nouveau texte de note",
        Others: "Autres",
        "You can use the Apps button to navigate to the Cases & Targets page. By clicking done, you will permanently hide this message":
          'Vous pouvez utiliser le bouton Applications pour accéder à la page "Cas et cibles". En cliquant sur "Terminé", vous masquerez définitivement ce message.',
        'Back to "Cases and targets"': 'Retour à "Cas et cibles"',
        "Note created successfully!": "Note créée avec succès !",
        "Note has not been created": `La note n'a pas été créée`,
        "User equipment": "Équipement utilisateur",
        "Mobile network": "Réseau mobile",
        Image: "Image",
        Birthday: "Anniversaire",
        "Remove from case": "Supprimer du cas",
        "Remove target from case": "Supprimer la cible du cas",
        "Are you sure you want to remove target from this case?":
          "Êtes-vous sûr de vouloir supprimer la cible de ce cas ?",
        "was extracted successfully. Information was also added in Target Call Log Analysis": `a été extrait avec succès. Les informations ont également été ajoutées à l'analyse du journal d'appels de la cible`,
        "Geolocation query successfully submitted":
          "La requête de géolocalisation a été soumise avec succès",
        "number successfully located": "numéro localisé avec succès",
        "number could not be located": `numéro n'a pas pu être localisé`,
        "was located during a call.": `a été localisé lors d'un appel.`,
        "Do you want to extract the Peer Caller ID? This action will deduct 4 credits.": `Voulez-vous extraire l'identifiant de l'appelant ? Cette action déduira 4 crédits.`,
        "Do you want to extract the Peer Caller ID?": `Voulez-vous extraire l'identifiant de l'appelant ?`,
        Satellite: "Satellite",
        Catcher: "Récepteur",
        "Current Call": "Appel en cours",
        "Group By": "Regrouper par",
        "No conversations": "Pas de conversations",
        Hometown: "Ville natale",
        Education: "Éducation",
        Work: "Travail",
        No: "Non",
        items: "articles",
        item: "article",
        Medium: "Moyen",
        "Add note": "Ajouter une note",
        "Select targets": "Sélectionner des cibles",
        "Cancel request sent successfully!": "Demande annulée avec succès!",
        "with a duration of": "avec une durée de",
        "Related numbers": "Numéros liés",
        IMEI: "IMEI",
        "Lat/Lon": "Lat/Lon",
        "Duration (s)": "Durée (s)",
        TMSI: "TMSI",
        CALLEE: "Numéro appelé",
        CALLER: "Numéro appelant",
        ID: "ID",
        Redraw: "Redessiner",
        "Log on task": "Se connecter à une tâche",
        "Create a case": "Créer un cas",
        Description: "Description",
        "View note details": "Afficher les détails de la note",
        "Minimize map": "Réduire la carte",
        "Maximize map": "Agrandir la carte",
        Negative: "Négatif",
        "Target Edit": "Modifier la cible",
        Note: "Note",
        "items on": "articles sur",
        "Please insert more than one phones to perform a proximity query": `Veuillez insérer plus d'un numéro de téléphone pour effectuer une requête de proximité`,
        "Get the CallerID (4 credits)": "Obtenir le CallerID (4 crédits)",
        "Get the CallerID": "Obtenir le CallerID",
        Video: "Vidéo",
        Vendor: "Vendeur",
        Model: "Modèle",
        Device: "Appareil",
        Browser: "Navigateur",
        "No Data": "Pas de données",
        Caller: "Appelant",
        Callee: "Appelé",
        "Query and schedule": "Interroger et planifier",
        "Keywords monitored": "Mots clés surveillés",
        "Clear selection": "Effacer la sélection",
        "Export selected": "Exporter la sélection",
        "Monitoring is": "La surveillance est",
        "Select list": "Sélectionner la liste",
        "Update the graph": "Mettre à jour le graphique",
        "Conversation Type": "Type de conversation",
        "Copy all": "Tout copier",
        saved: "enregistré",
        "Save as a list from": "Enregistrer sous forme de liste à partir de",
        "Timebar type": "Type de barre de temps",
        histogram: "histogramme",
        area: "zone",
        "Filter panel": "Panneau de filtre",
        Person: "Personne",
        Organization: "Organisation",
        Place: "Lieu",
        Topic: "Sujet",
        "items found": "éléments trouvés",
        Nodes: "Nœuds",
        Relations: "Relations",
        Links: "Liens",
        Summary: "Résumé",
        Locations: "Emplacements",
        "Social relation": "Relation sociale",
        "Add seeds or Perform an OSINT query to retrieve more information": `Ajouter des graines ou effectuer une requête OSINT pour récupérer plus d'informations`,
        target: "cible",
        "Select a target to update": "Sélectionnez une cible à mettre à jour",
        "Clear all": "Effacer tout",
        "Target has not been update": `La cible n'a pas été mise à jour`,
        "Target updated successfully!": "Cible mise à jour avec succès !",
        Analyse: "Analyser",
        "Common relations": "Relations communes",
        "Update existing target": "Mettre à jour la cible existante",
        Labels: "Étiquettes",
        "Incoming call": "Appel entrant",
        "Outgoing call": "Appel sortant",
        "Incoming sms": "SMS entrant",
        "Outgoing sms": "SMS sortant",
        "Unknown type": "Type inconnu",
        "Live Session has been successfully obtained":
          "La session en direct a été obtenue avec succès",
        "Live session request created successfully!":
          "Demande de session en direct créée avec succès !",
        "Copy to Clipboard": "Copier dans le presse-papiers",
        "Following on Twitter": "Abonnement sur Twitter",
        "Follower on Twitter": "Abonné/Abonnée sur Twitter",
        "Following on Instagram": "Abonnement sur Instagram",
        "Follower on Instagram": "Abonné/Abonnée sur Instagram",
        "Facebook Friend": "Ami(e) sur Facebook",
        "Family member on Facebook": "Membre de la famille sur Facebook",
        "targets found": "cibles trouvées",
        "Link Analysis": "Analyse de liens",
        Names: "Noms",
        Usernames: `Noms d'utilisateur`,
        Emails: "Adresses e-mail",
        "Profile URLs": "URL de profil",
        Languages: "Langues",
        Addresses: "Adresses",
        "Copied!": "Copié !",
        Copy: "Copier",
        Profile: "Profil",
        queue: `file d'attente`,
        "locate all": "localiser tout",
        "No items added": "Aucun élément ajouté",
        "create a new target": "créer une nouvelle cible",
        "Add to an existing target": "Ajouter à une cible existante",
        cancel: "Annuler",
        "Add and analyse": "Ajouter et analyser",
        filters: "filtres",
        "filter by location": "filtrer par emplacement",
        "Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok":
          "URL de profil pour Facebook, Twitter, Instagram, Linkedin, Tiktok",
        "search history": "historique de recherche",
        "Show search": "Afficher la recherche",
        delete: "supprimer",
        "end of history": `fin de l'histoire`,
        "Basic OSINT Search": "Recherche OSINT de base",
        "geo-fencing": "géo-clôture",
        "cases and targets": "cas et cibles",
        "geo location": "emplacement géographique",
        "Search again": "Rechercher à nouveau",
        addresses: "adresses",
        countries: "pays",
        educations: "éducations",
        emails: "adresses e-mail",
        gender: "genre",
        imeis: "imeis",
        jobs: "emplois",
        names: "noms",
        photos: "photos",
        telnos: "numéros de téléphone",
        usernames: `noms d'utilisateur`,
        profileUrls: "URL de profil",
        MSISDNs: "Numéros de téléphone",
        "Target summary": "Résumé de la cible",
        "Live Session": "Session en direct",
        "search results found for": "résultats de recherche trouvés pour",
        on: "sur",
        "select all": "sélectionner tout",
        "items selected": "articles sélectionnés",
        "Fetching facebook friends": "Récupération des amis Facebook",
        "Show nodes that interacted with the target by":
          "Afficher les nœuds qui ont interagi avec la cible par",
        "times or more": "fois ou plus",
        "Rest of the week": "Le reste de la semaine",
        "Back to Dashboard": "Retour au tableau de bord",
        Case: "Cas",
    
        "Social Profile": "Profil Social",
        "Confirmed infection": "Infection Confirmée",
        "Potential infection": "Infection Potentielle",
        "Confirmed Infection": "Infection Confirmée",
        "Potential Infection": "Infection Potentielle",
        "Target Infected": "Cible Infectée",
        "Target received a call from": "La cible a reçu un appel de",
        "time(s)": "fois",
        "Target called": "La cible a appelé",
        "Target received a message from": "La cible a reçu un message de",
        "Target messaged": "La cible a envoyé un message à",
        "interacted with": "a interagi avec",
        "Is following": "Suit",
        "Is followed by": "Est suivi(e) par",
        "has family relation with": "a une relation familiale avec",
        "Connected with MSISDN": "Connecté(e) avec MSISDN",
        "Is/was working as": "Travaille/travaillait en tant que",
        "Possibly owns this social profile": "Possède peut-être ce profil social",
        "Target case": "Cas de la cible",
        "Facebook friends": "Ami(e) Facebook",
        "unknown type": "type inconnu",
        "Discover persons": "Découvrir des personnes",
        "Location history": "Historique de localisation",
        "Select/unselect location!": `Sélectionner/désélectionner l'emplacement !`,
        Discover: "Découvrir",
        "show less": "Montrer moins",
        " more": " de plus",
        "No targets selected!": "Aucune cible sélectionnée !",
        "The target changes have been saved. Click":
          "Les changements de la cible ont été enregistrés. Cliquez",
    
        here: "ici",
        "to view the target profile": "pour voir le profil de la cible",
        "The target changes have been saved and analysis is started. Click": `Les changements de la cible ont été enregistrés et l'analyse a commencé. Cliquez sur`,
        found: "trouvé",
        "Show nodes that came in contact with the target by distance of":
          "Afficher les nœuds qui ont été en contact avec la cible par une distance de",
        "meters or less": "mètres ou moins",
        "GEO query": "Requête GEO",
        "Discovered persons": "Personnes découvertes",
        "Create a case with the target's alias": `Créer un dossier avec l'alias de la cible`,
        "Company Employees": `Employés de l'entreprise`,
        "less than 50 meters": "moins de 50 mètres",
        "between 50-100 meters": "entre 50-100 mètres",
        "over 100 meters": "plus de 100 mètres",
        "Please add at least two fields to create a note.":
          "Veuillez ajouter au moins deux champs pour créer une note.",
        "Linkedin Employee": "Employé de LinkedIn",
        "Target is assigned to this case": "La cible est assignée à ce cas",
        "Company employee": `Employé de l'entreprise`,
        "Double click to expand/collapse node":
          "Double-cliquez pour agrandir/réduire le nœud",
        "IMEI(s)": "IMEI(s)",
        "View more": "Voir plus",
        "View less": "Voir moins",
        "Please complete all fields!": "Veuillez remplir tous les champs !",
        "Add new platform": "Ajouter une nouvelle plateforme",
        Workplaces: "Lieux de travail",
        "Landline(s)": "Ligne(s) fixe(s)",
        "Common relations on social networks":
          "Relations communes sur les réseaux sociaux",
        "Found for": "Trouvé pour",
        "Go to target page": "Aller à la page cible",
        "Common faces on photos": "Visages communs sur les photos",
        "Common working places": "Lieux de travail communs",
        "Common education places": `Lieux d'éducation communs`,
        "Paste here the url for the platform": `Collez ici l'url de la plateforme`,
        successful: "réussi",
        One: "Une",
        intel: "renseignement",
        query: "requête",
        call: "appel",
        info: "information",
        emergency: "urgence",
        advanced: "avancé",
        geolocation: "géolocalisation",
        "Enter a address": "Entrez une adresse",
        "Enter emails": "Entrez des emails",
        "Collected Profile URL": "URL de profil collectée",
        Apps: "Applications",
        "Targets are now here": "Les cibles sont maintenant ici",
        "Please Enter Username": `Veuillez entrer un nom d'utilisateur`,
        "Please Enter Password": "Veuillez entrer un mot de passe",
        "Case targets": "Cibles du cas",
        "This case has no targets": `Ce cas n'a pas de cibles`,
        " Create a new one ": "Créer un nouveau",
        "or ": "ou ",
        "Add an existing target": "Ajouter une cible existante",
        "Select case targets": "Sélectionner les cibles du cas",
        "Add to case": "Ajouter au cas",
        "Filter targets": "Filtrer les cibles",
        "Targets added successfully!": "Cibles ajoutées avec succès !",
        "This case needs at least 2 targets to find common information": `Ce cas a besoin d'au moins 2 cibles pour trouver des informations communes !`,
        "No data available": "Pas de données disponibles",
        "Add social profiles to the targets to view data":
          "Ajoutez des profils sociaux aux cibles pour voir les données",
        name: "nom",
        username: `nom d'utilisateur`,
        url: "url",
        details: "détails",
        "Please fill in both fields for the date":
          "Veuillez remplir les deux champs pour la date",
        "show more": "afficher plus",
        targets: "objectifs",
        "Switch to detail view": "Passer à la vue détaillée",
        "Switch to standard view": "Passer à la vue standard",
        "Search by name": "Rechercher par nom",
        Layout: "Disposition",
        of: "de",
        "This option will be available for": "Cette option sera disponible pour",
        "Advanced geolocation": "Géolocalisation avancée",
        "Would you like to obtain AGPS/GPS location for":
          "Souhaitez-vous obtenir la localisation AGPS/GPS pour",
        "This action will deduct 3 credits.": "Cette action déduira 3 crédits.",
        "This action will deduct 2 credits.": "Cette action déduira 2 crédits.",
        "Copy link": "Copier le lien",
        comments: "commentaires",
        likes: `j'aime`,
        "persons tagged": "personnes étiquetées",
        "Get Photo Info": "Obtenir les informations de la photo",
        "Persons tagged": "Personnes étiquetées",
        "Result type": "Type de résultat",
        "Open Web": "Ouvrir le Web",
        "See more results": "Voir plus de résultats",
        "See less results": "Voir moins de résultats",
        Maximize: "Agrandir",
        Minimize: "Réduire",
        "View in fullscreen": "Afficher en plein écran",
        "Your browser is not capable to view in fullscreen. Please switch to a latest browser.": `Votre navigateur ne prend pas en charge l'affichage en plein écran. Veuillez passer à un navigateur plus récent.`,
        "Exit full screen": "Quitter le mode plein écran",
        "Full screen": "Plein écran",
        "You can select multiple nodes by holding down Ctrl key and clicking on a node.":
          "Vous pouvez sélectionner plusieurs nœuds en maintenant la touche Ctrl enfoncée et en cliquant sur un nœud.",
        "Then you have the ability to:": "Ensuite, vous avez la capacité de :",
        "move the selected nodes by dragging using the mouse or with the keyboard arrows.":
          "déplacer les nœuds sélectionnés en les faisant glisser avec la souris ou avec les flèches du clavier.",
        "filter out the selected nodes by pressing the Delete key.":
          "filtrer les nœuds sélectionnés en appuyant sur la touche Supprimer.",
        "Monitor changes": "Surveiller les modifications",
        "Please select a Social Profile!":
          "Veuillez sélectionner un profil social !",
        "Please select a date!": "Veuillez sélectionner une date !",
        "Select Social Profile": "Sélectionnez un profil social",
        Feedback: "Commentaires",
        "Selected date must be in the past!":
          "La date sélectionnée doit être dans le passé !",
        "This post contains": "Cette publication contient",
        images: "images",
        videos: "vidéos",
        "click to expand.": "cliquez pour agrandir.",
        "Your browser does not support video":
          "Votre navigateur ne prend pas en charge la vidéo",
        "Show updates": "Afficher les mises à jour",
        "Facebook group member": "Membre du groupe Facebook",
        "photo details": "détails de la photo",
        "Open in a new tab": "Ouvrir dans un nouvel onglet",
        "Copy URL": `Copier l'URL`,
        "Would you like to obtain more accurate location for":
          "Souhaitez-vous obtenir une localisation plus précise pour",
        "Removes all filters, keeps new nodes from analyse": `Supprime tous les filtres, empêche l'analyse de nouveaux nœuds`,
        Reload: "Recharger",
        "Removes everything (including nodes from analyse) and reloads all target data": `Supprime tout (y compris les nœuds de l'analyse) et recharge toutes les données cibles`,
        "Reload chart with latest target data":
          "Recharger le graphique avec les dernières données cibles",
        "Do you wish to reload the chart? Any data created from analysing nodes will be removed": `Voulez-vous recharger le graphique? Toutes les données créées à partir de l'analyse des nœuds seront supprimées.`,
        "Group Member on Facebook": "Membre du groupe sur Facebook",
        Other: "Autre",
        "Group Name": "Nom du groupe",
        Members: "Membres",
        discovery: "Découverte",
        Discovery: "Découverte",
        "Check-ins": "Lieux",
        "License Plates": `Plaques d'immatriculation`,
        "Work Place": "Lieu de travail",
        "Voter Id": "ID de vote",
        Workplace: "Lieu de travail",
        Present: "Présent",
        "Case has not been created": `Le cas n'a pas été créé`,
        "Select date": "Sélectionner une date",
        "Select days": "Sélectionner des jours",
        "Facebook Group": "Groupe Facebook",
        "POI type": "Type de POI",
        SMS: "SMS",
        Sms: "Sms",
        "Show Release Notes": "Afficher les notes de version",
        "List name": "Nom de la liste",
        "Enter the keywords": "Entrez les mots clés",
        live: "En direct",
        "Live monitoring started at": "La surveillance en direct a commencé le",
        "View session details": "Voir les détails de la session",
        "User unlocked successfully!": "Utilisateur déverrouillé avec succès!",
        "User locked successfully!": "Utilisateur verrouillé avec succès!",
        "Create a temporary user": "Créer un utilisateur temporaire",
        "This is a 24hrs temporary user": `Il s'agit d'un utilisateur temporaire de 24 heures.`,
        "Lock Temporary User": `Verrouiller l'utilisateur temporaire`,
        "Lock user": `Verrouiller l'utilisateur`,
        "Time remaining": "Temps restant",
        Login: "Connexion",
        "Please provide your email address":
          "Veuillez fournir votre adresse e-mail",
        was: "était",
        disabled: "désactivée",
        for: "pour",
        "for:": "pour:",
        enabled: "activée",
        changed: "changée",
        quota: "crédits",
        empty: "vide",
        space: "espace",
        roles: "rôles",
        notifications: "notifications",
        unlocked: "débloquée",
        locked: "verrouillée",
        "update-user": `mettre à jour l'utilisateur`,
        "create-user": "créer un utilisateur",
        Likers: `J'aime`,
        "View all likers": `Voir tous les J'aime`,
        Commenters: "Commentateurs",
        "View all commenters": "Voir tous les commentateurs",
        "reset-password": "réinitialiser le mot de passe",
        "otp-email": "otp e-mail",
        "otp-phone": "otp téléphone",
        recipient: "destinataire",
        sender: "expéditeur",
        "Proximity Analysis": "Analyse de proximité",
        "Common locations": "Lieux communs",
        "Phone model": "Modèle de téléphone",
        "Assign to target or add to existing": `Attribuer à la cible ou ajouter à l'existante`,
        "Back to previous query": "Retour à la requête précédente",
        "Perform a location history query for the selected rows": `Effectuer une requête d'historique de localisation pour les lignes sélectionnées`,
        "Perform a follow query for the selected rows":
          "Effectuer une requête de suivi pour les lignes sélectionnées",
        "Perform a proximity query for the selected row":
          "Effectuer une requête de proximité pour la ligne sélectionnée",
        "Active proximity items": "Éléments de proximité actifs",
        "Existing target": "Cible existante",
        "New target": "Nouvelle cible",
        "Search Google Maps": "Rechercher sur Google Maps",
        "Your opinion is very important": "Votre opinion est très importante",
        "Please take this survey to rate your experience with":
          "Veuillez répondre à cette enquête pour évaluer votre expérience avec",
        "Not now": "Pas maintenant",
        new: "nouveau",
        user: "utilisateur",
        created: "créé",
        "created:": "créé :",
        set: "ensemble",
        "Invalid values. Please try again. Username must contain at least one letter.": `Valeurs invalides. Veuillez réessayer. Le nom d'utilisateur doit contenir au moins une lettre.`,
        "Twitter hashtag": "Hashtag Twitter",
        "Show statistics": "Afficher les statistiques",
        "Hide statistics": "Masquer les statistiques",
        Coordinates: "Coordonnées",
        "Favorite count": "Nombre de favoris",
        "Retweeted count": "Nombre de retweets",
        Account: "Compte",
        "This action will create the Locate query that is associated with":
          "Cette action va créer la requête de localisation associée à",
        "This action will fetch all the Basic OSINT that are associated with":
          "Cette action va récupérer tous les OSINT basiques associés à",
        "Basic OSINT will discover more seeds": `L'OSINT basique découvrira plus de sources`,
        "Discover more seeds": "Découvrir plus de sources",
        "Perform any of the OSINT Stage to retrieve important intelligence information": `Effectuez l'une des étapes OSINT pour récupérer des informations de renseignement importantes`,
        'Add seeds and click "Enchance target" to retrieve social profiles':
          'Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des profils sociaux',
        'Add seeds and click "Enchance target" to retrieve instant messaging apps':
          'Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des applications de messagerie instantanée',
        'Add seeds and click "Enchance target" to retrieve employment and education': `Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des informations sur l'emploi et l'éducation`,
        'Add seeds and click "Enchance target" to retrieve relationship information':
          'Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des informations sur les relations',
        'Add seeds and click "Enchance target" to retrieve media':
          'Ajouter des sources ou cliquer sur "Améliorer la cible" pour récupérer des médias',
        'Add a Facebook account and click "Enchance target" to retrieve more information': `Ajouter un compte Facebook ou cliquer sur "Améliorer la cible" pour récupérer plus d'informations`,
        'No locations found for the target <br> Click "Locate" or "#{Request call log}" to retrieve locations': `Aucun emplacement trouvé pour la cible <br> Cliquer sur "Localiser" ou "#{Demander le journal d'appels}" pour récupérer les emplacements`,
        "No locations found for the target <br> Add seeds to retrieve more information": `Aucun emplacement trouvé pour la cible <br> Ajouter des sources pour récupérer plus d'informations`,
        "Enter a value": "Entrez une valeur",
        "Basic OSINT": "OSINT Basique",
        "Simple OSINT": "OSINT Simple",
        "Advanced Webint": "WebInt avancé",
        "Ad IDs": "IDs publicitaires",
        "Something went wrong please try again later!": `Quelque chose s'est mal passé, veuillez réessayer plus tard !`,
        DATA: "Données",
        "Call in": `Entrée d'appel`,
        "Call out": `Sortie d'appel`,
        "SMS in": "SMS entrant",
        "SMS out": "SMS sortant",
        "Back to Case": "Retour au cas",
        "Search history": "Historique de recherche",
        "Analyze location": `Analyser l'emplacement`,
        "User Equipment": `Équipement de l'utilisateur`,
        "Mobile Network": "Réseau mobile",
        "Target Details": "Détails de la cible",
        darkweb: "Darkweb",
        profiles: "Profils",
        "This action will locate the": "Cette action localisera le",
        "AD ID": "ID publicitaire",
        "Last call log location": `Emplacement du dernier journal d'appel`,
        "Target Profile": "Profil de la cible",
        "Active in": "Actif dans",
        cases: "cas",
        "Open target profile": "Ouvrir le profil de la cible",
        "Select Dates": "Sélectionner les dates",
        "We were not able to bring mutual facebook friends at the moment.": `Nous n'avons pas pu apporter des amis Facebook mutuels pour le moment.`,
        "We were not able to bring company employees at the moment.": `Nous n'avons pas pu apporter des employés de la société pour le moment.`,
        "Current target has no MSISDN attached!": `La cible actuelle n'a aucun MSISDN attaché !`,
        "Add a valid MSISDN": "Ajouter un MSISDN valide",
        Industry: "Industrie",
        "Company location": "Emplacement de la société",
        "Current city": "Ville actuelle",
        "Radical monitoring service is down":
          "Le service de surveillance radicale est hors ligne",
        "Start monitoring": "Démarrer la surveillance",
        "Stop monitoring": "Arrêter la surveillance",
        "You don't have any monitoring created":
          "Vous n'avez créé aucune surveillance",
        "To start a monitor": "Pour commencer une surveillance",
        "enter keywords": "entrez des mots-clés",
        "to be monitored, or create a": "à surveiller, ou créez une",
        "keyword list": "liste de mots-clés",
        "Keyword lists": "Listes de mots-clés",
        "to reuse and select": "à réutiliser et sélectionnez",
        "social platforms": "les plateformes sociales",
        "you want to be monitored": "que vous souhaitez surveiller",
        "No keyword lists": "Aucune liste de mots-clés",
        "All social platforms": "Toutes les plateformes sociales",
        "Total number of matches": "Nombre total de correspondances",
        "Average matches per session": "Correspondances moyennes par session",
        "No keyword lists created": "Aucune liste de mots-clés créée",
        "Close session details": "Fermer les détails de session",
        "started on": "débuté le",
        "and ended on": "et s'est terminé le",
        "Scanned and added to feed": "Scanné et ajouté à la liste",
        "No lists selected": "Aucune liste sélectionnée",
        "This monitor did not collect any data":
          "Ce moniteur n'a pas collecté de données",
        "The monitor is collecting data, it will be presented as soon as it is discovered":
          "Le moniteur collecte des données, elles seront présentées dès qu'elles seront découvertes",
        "Live monitoring": "Surveillance en temps réel",
        "started at": "débutée à",
        "No keywords": "Aucun mot-clé",
        "Radical monitoring matched a keyword for":
          "La surveillance radicale a trouvé une correspondance pour",
        Geolocate: "Géolocaliser",
        "View item": "Voir l'élément",
        "platform selected": "plateforme sélectionnée",
        "platforms selected": "plateformes sélectionnées",
        "list selected": "liste sélectionnée",
        "lists selected": "listes sélectionnées",
        "Interested in": "Intéressé par",
        "Email to support": "Envoyer un email au support",
        "Multiple cases": "Cas multiples",
        "Last query": "Dernière requête",
        "Not active": "Non actif",
        "Please contact your administrator":
          "Veuillez contacter votre administrateur",
        "Analyze in progress": "Analyse en cours",
        "Advanced Webint query created successfully!":
          "Requête Webint avancée créée avec succès !",
        "Basic Osint query created successfully!":
          "Requête Osint de base créée avec succès !",
        "Collecting and analysing intelligence for":
          "Collecte et analyse de renseignements pour",
        "Searching social profiles": "Recherche de profils sociaux",
        "Searching instant message profiles":
          "Recherche de profils de messagerie instantanée",
        "Searching dark web": "Recherche sur le Dark Web",
        "Searching open web": "Recherche sur le web ouvert",
        "Searching closed databases": "Recherche de bases de données fermées",
        "In progress": "En cours",
        "The OSINT action for": `L'action OSINT pour`,
        "is done": "est terminée",
        "Would you like to see target profile": "Voulez-vous voir le profil cible",
        has: "a",
        "Please enter a valid TRG email!":
          "Veuillez entrer une adresse email TRG valide !",
        "User ID": "Identifiant utilisateur",
        "Last Activity": "Dernière activité",
        "Private Profile": "Profil privé",
        "Private profile": "Profil privé",
        "Joined At": "Inscrit le",
        "OSINT completed successfully": "OSINT terminé avec succès",
        View: "Voir",
        "Analysing data": "Analyse de données",
        "Create group": "Créer un groupe",
        "Add group name": "Ajouter un nom de groupe",
        Loading: "Chargement",
        Correlator: "Corrélateur",
        "Call log upload request for author": `Demande de téléchargement du journal d'appels pour l'auteur`,
        "with msisdn": "avec msisdn",
        login: "Connexion",
        logout: "Déconnexion",
        "User:": "Utilisateur :",
        "sucessfully logged in": "connecté avec succès",
        "sucessfully logged out": "déconnecté avec succès",
        Unlimited: "Illimité",
        "Request call log limit reached. Please contact support.": `Limite de demande de journal d'appels atteinte. Veuillez contacter le support.`,
        "Top associates": "Associés principaux",
        "Show only profiles with": "Afficher uniquement les profils avec",
        "All Types": "Tous les types",
        Filters: "Filtres",
        "All addresses": "Toutes les adresses",
        "All educations": "Toutes les éducations",
        Educations: "Éducation",
        Companies: "Entreprises",
        "All jobs": "Tous les emplois",
        "All names": "Tous les noms",
        "All urls": "Toutes les URL",
        "Social URLs": "URL des réseaux sociaux",
        "Phone numbers": "Numéros de téléphone",
        "All phone numbers": "Tous les numéros de téléphone",
        "All usernames": "Tous les utilisateurs",
        "All emails": "Tous les e-mails",
        Profiler: "Profilage",
        Age: "Âge",
        "More filters": "Plus de filtres",
        "Select location": "Sélectionner une localisation",
        "Enter a location": "Entrer une localisation",
        Apply: "Appliquer",
        "Choose age range": `Choisir une tranche d'âge`,
        Connections: "Connexions",
        "For example": "Par exemple",
        Jobs: "Emplois",
        "Free text": "Texte libre",
        "Show all": "Afficher tout",
        "Show only starred": "Afficher uniquement les éléments sélectionnés",
        "Search type": "Type de recherche",
        "Search query": "Terme de recherche",
        Results: "Résultats",
        "Created at": "Créé le",
        "Clear all history": `Effacer tout l'historique`,
        Anytime: `N'importe quand`,
        "Last 7 days": "Les 7 derniers jours",
        "Last 30 days": "Les 30 derniers jours",
        "Last 90 days": "Les 90 derniers jours",
        "Last page": "Dernière page",
        "First page": "Première page",
        "Recent searches": "Recherches récentes",
        "See search history": `Voir l'historique de recherche`,
        results: "résultats",
        "We found": "Nous avons trouvé",
        "Advanced Geo Query": "Recherche géographique avancée",
        image: "image",
        "Start typing phone, name, url, email or username or upload photo": `Commencez à taper le téléphone, le nom, l'URL, l'e-mail ou le nom d'utilisateur ou téléchargez une photo`,
        And: "et",
        "My target": "Ma cible",
        "All users": "Tous les utilisateurs",
        "See more": "Voir plus",
        "See less": "Voir moins",
        "seeds selected": "graines sélectionnées",
        "Choose profiles or seeds to build your target":
          "Choisissez des profils ou des graines pour construire votre cible",
        "Add to target": "Ajouter à la cible",
        Added: "Ajoutée",
        Source: "Source",
        selected: "sélectionnée",
        "Clear All": "Tout effacer",
        Telnos: "Numéros de téléphone",
        Profileurls: "URL des réseaux sociaux",
        "Create Target": "Créer une cible",
        "Create a new target": "Créez une nouvelle cible",
        seeds: "graines",
        "Create new target or search for existing one":
          "Créez une nouvelle cible ou recherchez-en une existante",
        "Start typing job description":
          "Commencez à taper la description du travail",
        "Type any term you would like to add":
          "Saisissez tout terme que vous souhaitez ajouter",
        "Profile photos": "Photos de profil",
        "Text Analysis": "Analyse de texte",
        Miscellaneous: "Divers",
        "Miscellaneous ": "Divers",
        Cardinal: "Cardinal",
        Numerical: "Numériques",
        Product: "Produit",
        Ordinal: "Ordinal",
        "Work of art": `Oeuvre d'art`,
        Percent: "Pourcentage",
        Event: "Événement",
        Quantity: "Quantité",
        Broadcaster: "Présentateur",
        Money: "Argent",
        Organizations: "Organisations",
        People: "Personnes",
        "Social groups": "Groupes sociaux",
        "Geo Political Entities": "Entités géopolitiques",
        "Infrastructure and buildings": "Infrastructure et bâtiments",
        "Non Geo Political Entities": "Entités non géopolitiques",
        "Distribution of topics": "Répartition des sujets",
        Org: "Org",
        Url: "Url",
        "Item matched": "Élément correspondant",
        "Open URL": `Ouvrir l'URL`,
        "Activity patterns": `Schémas d'activité`,
        "overall activity": "activité globale",
        "data activity": "activité de données",
        "voice activity": "activité vocale",
        "SMS activity": "activité SMS",
        "Events by type": "Événements par type",
        Voice: "Voix",
        "Call analysis": `Analyse d'appel`,
        Week: "Semaine",
        Weekend: "Week-end",
        "Top target locations": "Principales localisations cibles",
        "Count analysis": "Analyse de comptage",
        Amount: "Montant",
        "Total duration": "Durée totale",
        "duration & frequency": "durée et fréquence",
        "incoming & outgoing": "entrant et sortant",
        "Low activity": "Faible activité",
        "Average activity": "Activité moyenne",
        "High activity": "Haute activité",
        "No activity": "Aucune activité",
        "SMS & calls": "SMS et appels",
        "Total calls": "Total des appels",
        "Map View": "Vue de la carte",
        "Split View": "Vue divisée",
        "List View": "Vue de la liste",
        "Choose file to upload": "Choisissez le fichier à télécharger",
        "Call logs should be uploaded as a single .csv file": `Les journaux d'appels doivent être téléchargés en tant que fichier .csv unique`,
        "Drag file here": "Déplacez le fichier ici",
        "Checking Data Availability...":
          "Vérification de la disponibilité des données ...",
        "Search by phone number": "Rechercher par numéro de téléphone",
        "Results of upload": "Résultats du téléchargement",
        "Total Parsed": "Total analysé",
        "Successfully inserted": "Inséré avec succès",
        Duplicated: "Dupliqué",
        Errors: "Erreurs",
        "Processing the data": "Traitement des données",
        "Processing the data, please wait":
          "Traitement des données, veuillez patienter",
        Destination: "Destination",
        "Upload existing call log": `Téléchargez un journal d'appels existant`,
        "in order to see call log analysis":
          "afin de pouvoir consulter l'analyse du journal d'appels",
        "Analysis includes": "L'analyse comprend",
        "insights about your target's top associates":
          "statistiques sur les principaux associés de votre cible",
        "a playback of the target's timeline":
          "une lecture de la chronologie de la cible",
        "target's predicted locations": "prévisions de localisations de la cible",
        "target's common used routes":
          "les itinéraires les plus couramment utilisés par la cible",
        "And more": "et plus encore",
        "Most recent": "Le plus récent",
        "Most relevant": "Le plus pertinent",
        Tenant: "Locataire",
        "Total credits reserved": "Crédits totaux réservés",
        "Lines parsed": "Lignes analysées",
        "No available data": "Pas de données disponibles",
        "Please select a 3 day range maximum.":
          "Veuillez sélectionner une plage de 3 jours maximum.",
        "Select a range of max 3 days": "Sélectionnez une plage de 3 jours maximum",
        Balance: "Solde",
        "No transactions were made in the selected dates": `Aucune transaction n'a été effectuée aux dates sélectionnées`,
        "No transactions found": "Aucune transaction trouvée",
        "out of": "sur",
        queries: "requêtes",
        "Social Urls": "URL sociales",
        "Cross Intel Search": "Recherche croisée Intel",
        Contains: "Contient",
        "Social URL": "URL sociale",
        "View map": "Voir la carte",
        Certainty: "Certitude",
        "From - To": "De - À",
        "Add name and image url": `Ajouter nom et URL d'image`,
        "Search by face and name": "Recherche par visage et nom",
        and: "et",
        "We couldn’t find any results for": `Nous n'avons trouvé aucun résultat pour`,
        "Try another search": "Essayez une autre recherche",
        "Search & geolocate phone": "Rechercher et géolocaliser le téléphone",
        "Geolocate phone": "Géolocaliser le téléphone",
        "Search tips": "Conseils de recherche",
        "Try different or less filters": "Essayez des filtres différents ou moins",
        "Try fewer keywords": "Essayez avec moins de mots clés",
        "Check for spelling errors": `Vérifiez les fautes d'orthographe`,
        "Add Profile to My Target": "Ajouter un profil à Ma cible",
        "Parsing errors": `Erreurs d'analyse`,
        "Parsing error lines": `Lignes d'erreur d'analyse`,
        "Billing error": "Erreur de facturation",
        "File error": "Erreur de fichier",
        Confirmation: "Confirmé",
        "Are you sure want to clear the history ?": `Êtes-vous sûr de vouloir effacer l'historique ?`,
        "Are you sure want to clear all the history ?": `Êtes-vous sûr de vouloir effacer tout l'historique ?`,
        "No Results": "Pas de résultats",
        "Type a name": "Tapez un nom",
        "Look for a country, city or address":
          "Recherchez un pays, une ville ou une adresse",
        "View in Link Analysis": `Voir dans l'analyse de liens`,
        "Completed successfully": "Terminé avec succès",
        "Expiring date": `Date d'expiration`,
        "Could not verify that you are human, please contact your administrator":
          "Impossible de vérifier que vous êtes humain, veuillez contacter votre administrateur",
        "Name Surname": "Nom Prénom",
        "Name Middle Surname": "Nom Second Nom",
        "Saving data": "Enregistrement des données",
        "Save changes": "Enregistrer les changements",
        "Would you like to save the latest changes you made in link analysis?": `Voulez-vous enregistrer les derniers changements que vous avez apportés à l'analyse de liens ?`,
        Degrees: "Degrés",
        "Changes saved successfully!":
          "Les modifications ont été enregistrées avec succès !",
        "No seeds were found. The ExtractusD will fetch information related to Target MSISDN(s)": `Aucune graine n'a été trouvée. ExtractusD récupérera des informations relatives à la (aux) MSISDN cible(s)`,
        "Succesfull ExtractusD Operation": "Opération ExtractusD réussie",
        Features: "Fonctionnalités",
        "ExtractusD Response": "Réponse ExtractusD",
        "ExtractusD request": "Demande ExtractusD",
        "ExtractusD requested by user": `Demandé par l'utilisateur`,
        "ExtractusD request by user": `Demande d'ExtractusD par l'utilisateur`,
        "for the objective": "pour la cible",
        "was Succesfull": "a été réussie",
        Landlines: "Lignes fixes",
        "All landlines": "Toutes les lignes fixes",
        "All MSISDNs": "Tous les numéros de téléphone",
        "All types": "Tous les types",
        Ascending: "Ascendant",
        Descending: "Descendant",
        "Sort by": "Trier par",
        'Add to "My target"': 'Ajouter à "Ma cible"',
        URL: "URL",
        "Truecaller profile location": "Emplacement du profil Truecaller",
        "Clear filters": "Effacer les filtres",
        Topics: "Sujets",
        "Click to see details": "Cliquez pour voir les détails",
        Category: "Catégorie",
        "Seed copied": "Graine copiée",
        "This action will request Call Log for":
          "Cette action demandera le journal des appels pour",
        "Add at least 1 MSISDN or IMEI or IMSI":
          "Ajoutez au moins 1 MSISDN ou IMEI ou IMSI",
        "to the target to be able to use this feature":
          "à la cible pour pouvoir utiliser cette fonctionnalité",
        "Please enter 3 or more characters": "Veuillez entrer 3 caractères ou plus",
        "Special characters and trailing spaces are not allowed":
          "Les caractères spéciaux et les espaces de fin ne sont pas autorisés",
        Required: "Obligatoire",
        "Confirmation required": "Confirmation requise",
        "Insert a single or multiple MSISDNs or IMSIs separated by comma":
          "Insérez un ou plusieurs MSISDN ou IMSI séparés par des virgules",
        "Use comma to separate the MSISDNs.":
          "Utilisez une virgule pour séparer les MSISDN.",
        Block: "Bloquer",
        Manage: "Gérer",
        "Call Log Pending": `Journal d'appels en attente`,
        "Call log pending": `Journal d'appels en attente`,
        "Activate OTP": "Activer OTP",
        "Facebook Significant Other": "Autre significatif de Facebook",
        Facebook: "Facebook",
        Twitter: "Twitter",
        Instagram: "Instagram",
        Linkedin: "Linkedin",
        Skype: "Skype",
        Whatsapp: "Whatsapp",
        Tiktok: "Tiktok",
        Telegram: "Telegram",
        Friend: "Ami",
        Follower: "Abonné",
        Activate: "Activer",
        "Deactivate OTP": "Désactiver OTP",
        Deactivate: "Désactiver",
        "Activate 2-step verification": "Activer la vérification en deux étapes",
        "Deactivate 2-step verification":
          "Désactiver la vérification en deux étapes",
        "Enter password": "Entrez le mot de passe",
        "Scan QR with your device to generate OTP codes":
          "Scannez le QR avec votre appareil pour générer des codes OTP",
        "Enter the OTP code from your device":
          "Entrez le code OTP de votre appareil",
        "OTP configuration completed": "Configuration OTP terminée",
        "Are you sure you want to reset the user password for": `Êtes-vous sûr de vouloir réinitialiser le mot de passe de l'utilisateur pour`,
        "A second step after entering your password verifies it's you signing in.": `Une deuxième étape après avoir entré votre mot de passe vérifie que c'est bien vous qui vous connectez.`,
        "Change your password": "Changer votre mot de passe",
        "Current password": "Mot de passe actuel",
        "Password strength": "Force du mot de passe",
        "Use at least 8 characters. Don’t use a password from another site, or something too obvious": `Utilisez au moins 8 caractères. Ne choisissez pas un mot de passe d'un autre site, ou quelque chose de trop évident.`,
        "The password was changed!": "Le mot de passe a été changé!",
        "Password must be at least 8 characters.":
          "Le mot de passe doit comporter au moins 8 caractères.",
        "Password does not contain upper case letters.":
          "Le mot de passe ne contient pas de lettres majuscules.",
        "Password does not contain lower case letters.":
          "Le mot de passe ne contient pas de lettres minuscules.",
        "Password does not contain any digits.":
          "Le mot de passe ne contient pas de chiffres.",
        "Please use upper case letters, lower case letters and numbers":
          "Veuillez utiliser des lettres majuscules, des lettres minuscules et des chiffres.",
        "Please enter the same new password again":
          "Veuillez entrer à nouveau le même nouveau mot de passe",
        "2-Step Verification": "Vérification en deux étapes",
        Change: "Changement",
        "OTP code": "Code OTP",
        "Back to login": "Retour à la connexion",
        "An email with the QR was sent to": "Un email avec le QR a été envoyé à",
        "Email sent.": "Email envoyé.",
        "The password was reset!": "Le mot de passe a été réinitialisé!",
        "Could not reset password": "Impossible de réinitialiser le mot de passe",
        "Please add an email address to the user. A new password will be sent to that email": `Veuillez ajouter une adresse e-mail à l'utilisateur. Un nouveau mot de passe sera envoyé à cette adresse e-mail`,
        "If you lost your device please reset the 2-Step Verification by pressing the reset button. An email will be sent to guide you to add a new device.": `Si vous avez perdu votre appareil, réinitialisez la vérification en deux étapes en appuyant sur le bouton de réinitialisation. Un e-mail sera envoyé pour vous guider dans l'ajout d'un nouvel appareil.`,
        "Please fill out this field": "Veuillez remplir ce champ",
        "Wrong password": "Mot de passe incorrect",
        "Something went wrong. Please try OTP reset again.":
          "Quelque chose s'est mal passé. Veuillez réessayer la réinitialisation OTP.",
        "Analyzing and merging the results for":
          "Analyse et fusion des résultats pour",
        "Analyzing and unmerging the results for":
          "Analyse et annulation de la fusion des résultats pour",
        "Locate all": "Localiser tous",
        See: "Voir",
        "Quick filters": "Filtres rapides",
        "Tagged in": "Identifié dans",
        "photo(s) together": "photo(s) ensemble",
        "Attended same institution": "A fréquenté la même institution",
        "Same location": "Même emplacement",
        "Facebook family": "Famille Facebook",
        Liked: "Aimé",
        "target photo(s)": "photo(s) cible",
        "target post(s)": "publication(s) cible",
        "Commented on a photo of the target": "A commenté une photo de la cible.",
        "Commented on a post of the target":
          "A commenté une publication de la cible.",
        shares: "partages",
        views: "vues",
        Hashtags: "Hashtags",
        Muted: "Sourdine",
        Feature: "Fonctionnalité",
        Videos: "Vidéos",
        Hearts: "Cœurs",
        Shared: "Partagé",
        "No platforms were found. Please select the platform from the list bellow.":
          "Aucune plateforme n’a été trouvée. Veuillez sélectionner la plateforme dans la liste ci-dessous.",
        "Once the infrastructure is up, it will take our BOTs up to": `Une fois que l'infrastructure sera en place, il faudra à nos BOTs jusqu'à`,
        "hours to respond with success status.":
          "heures pour répondre avec un état de succès.",
        "Your request for SOMEDUS on": "Votre demande de SOMEDUS sur",
        "account has been submitted.": "a été soumise.",
        "If the status is N/A, yet you will have the option to enable the BOT to continue attempts.": `Si l'état est N/A, vous aurez toutefois la possibilité d'activer le BOT pour continuer les tentatives.`,
        "If you dont associated the correct MSISDN with platform account, the request could take up to 144 hours.": `Si vous n'avez pas associé le MSISDN correct à votre compte de plateforme, la demande pourrait prendre jusqu'à 144 heures.`,
        Download: "Télécharger",
        "Select platform": "Sélectionner la plateforme",
        "Select action": `Sélectionner l'action`,
        "Select MSISDN": `Sélectionner MSISDN`,
        "Somedus retrived information": "Somedus a récupéré des informations",
        "Action started by": "Action commencée par",
        "is here": "est ici",
        "Associated MSISDN with account": "MSISDN associé au compte",
        hours: "heures",
        "Searching open databases": "Recherche de bases de données ouvertes",
        "Add new item": "Ajouter un nouvel élément",
        "Select the start and end interval for tracking the Ad IDs": `Sélectionner l'intervalle de début et de fin pour suivre les ID publicitaires`,
        "Adint history": "Historique de Adint",
        "Enter Ad ID(s)": "Entrez les ID publicitaires",
        "Select targets from case": "Sélectionnez les cibles à partir du cas",
        "View results": "Voir les résultats",
        "Remove from history": `Supprimer de l'historique`,
        "Edit name": "Modifier le nom",
        Repeat: "Répéter",
        "Pin this search": "Épingler cette recherche",
        "Predicted Location": "Emplacement prévu",
        "CDR Statistics": "Statistiques de CDR",
        "Unpin this search": "Détacher cette recherche",
        "Show unique IDs": "Afficher des identifiants uniques",
        "Show common IDs": "Afficher des identifiants communs",
        "Column settings": "Paramètres de colonne",
        Expand: "Développer",
        Collapse: "Réduire",
        "Device ID": `ID de l'appareil`,
        "Phone brand": "Marque de téléphone",
        "IP/Carrier": "IP/Opérateur",
        APPs: "Applications",
        Area: "Zone",
        Confidence: "Confiance",
        "Follow with Geofence": "Suivre avec une géo clôture",
        "is required": "est requis",
        Trace: "Tracer",
        "De Anon": "Dé-anonymiser",
        "Cdr Statistics": "Statistiques de CDR",
        "Cdr Export": "Exporter CDR",
        "Target LastSeen": "Dernière vue de la cible",
        "Top Device Attributes": "Attributs de périphérique les plus importants",
        "Nearest Neighbors": "Voisins les plus proches",
        "Common Ips": "IP communes",
        "Case Cdr Statistics": "Statistiques de CDR de cas",
        "Common Associates": "Personnes communes",
        "Common Locations": "Lieux communs",
        "Case Top Associates": "Personnes les plus importantes du cas",
        "Case Top Locations": "Lieux les plus importants du cas",
        "Case Target Activity": "Activité de la cible de l'affaire",
        "Case Event By Type": "Événement par type de cas",
        "Case Call Analysis": `Analyse d'appels de cas`,
        "Case Count Analysis": "Analyse de comptage de cas",
        "Target Activity": "Activité de la cible",
        "Top connection": "Connexion importante",
        "Call log top relation": "Relation importante du journal des appels",
        "Select node to view details": "Sélectionnez le nœud pour voir les détails",
        "Every MSISDN should start with + .":
          "Chaque MSISDN doit commencer par + .",
        "is not a valid MSISDN .": `n'est pas un MSISDN valide .`,
        "Events Type": `Type d'événement`,
        "Top locations": "Top des lieux",
        "Export call log report": "Exporter le rapport du journal des appels",
        "Select report type": "Sélectionner le type de rapport",
        "Full report": "Rapport complet",
        "Custom report": "Rapport personnalisé",
        "Following MSISDN - Platform combination is already selected":
          "La combinaison suivante MSISDN - Plate-forme est déjà sélectionnée",
        "In order to strengthen account security, Two Factor Authentication (2FA) is mandatory for all users.": `Pour renforcer la sécurité du compte, l'authentification à deux facteurs (2FA) est obligatoire pour tous les utilisateurs.`,
        "Your password will expire in #{days} days.":
          "Votre mot de passe expirera dans #{days} jours.",
        "Your password has expired #{days} ago.":
          "Votre mot de passe a expiré il y a #{days} jours.",
        "Your password expired yesterday.": "Votre mot de passe a expiré hier.",
        "Your password expires today.": `Votre mot de passe expire aujourd'hui.`,
        "Your password expires tomorrow.": "Votre mot de passe expire demain.",
        "Session will expire in": "La session expirera dans",
        'Your session is about to expire click "Continue" to keep the session active.': `Votre session est sur le point d'expirer, cliquez sur "Continuer" pour maintenir la session active.`,
        Okay: `D'accord`,
        "Activate Now!": "Activer maintenant!",
        "Request expired for": "La demande a expiré pour",
        Abandon: "Abandonner",
        "is not a valid MSISDN.": `n'est pas un MSISDN valide.`,
        "Deep Webint query created successfully!":
          "Requête WebInt profonde créée avec succès!",
        "Failed to retrieve": "Impossible de récupérer",
        "Somedus Request Submitted": "Demande Somedus soumise",
        "Request Cancelled": "Demande annulée",
        "You have reached": "Vous avez atteint",
        "of your queries quota": "de votre quota de requêtes",
        "Only lowercase letters, numbers, dot (.) and underscore (_).":
          "Seulement des lettres minuscules, des chiffres, des points (.) et des tirets bas (_).",
        "Not enough credits for this request":
          "Crédits insuffisants pour cette demande",
        "Most Common Relations": "Relations les plus courantes",
        "Top Relations": "Relations principales",
        "Copy location": `Copier l'emplacement`,
        "Use the search filters": "Utiliser les filtres de recherche",
        "When using the search filters, the results will be more accurate": `Lors de l'utilisation des filtres de recherche, les résultats seront plus précis`,
        Quotes: "Citations",
        "Incoming SMS": "SMS entrants",
        "Outgoing SMS": "SMS sortants",
        "All locations": "Tous les emplacements",
        "Target locations": "Emplacements ciblés",
        "Peer(s) locations": "Emplacements des contacts",
        "In call": "Appel entrant",
        "Out call": "Appel sortant",
        "Out SMS": "SMS sortant",
        "In SMS": "SMS entrant",
        "Most common associates": `Associés les plus courants`,
        "Got it": "Compris",
        "Target peer interactions": "Interactions entre pairs cibles",
        "Location belongs to the source": `L'emplacement appartient à la source`,
        "Location belongs to the destination": `L'emplacement appartient à la destination`,
        "No, remove address from account": `Non, supprimer l'adresse du compte`,
        Confirm: "Confirmer",
        "Temporary password for #{username}":
          "Mot de passe temporaire pour #{username}",
        "Password provided was already used":
          "Le mot de passe fourni a déjà été utilisé",
        "No image available": "Aucune image disponible",
        sub: "sous",
        add: "ajouter",
        "The quota is changed by #{currentUser}":
          "La quota est modifiée par #{currentUser}",
        "The quota is changed by #{currentUser} - #{quotaType}":
          "La quota est modifiée par #{currentUser} - #{quotaType}",
        intel_search: `recherche d'intelligence`,
        query_location: "Emplacement de la requête",
        import_cdr: `Importation du journal d'appels`,
        "Maximum amount of robot queries is":
          "Le nombre maximal de requêtes robotiques est de",
        "Please choose another daterange.":
          "Veuillez choisir une autre plage de dates.",
        "Gathering call logs report for": `Rapport de journaux d'appels en cours pour`,
        "Gathering data for target details":
          "Rassemblement de données pour les détails de la cible",
        "Gathering images": `Rassemblement d'images`,
        "Gathering call log statistics": `Rassemblement de statistiques de journal d'appels`,
        "Preparing PDF preview": `Préparation de l'aperçu PDF`,
        "Add entity": "Ajouter une entité",
        "Select type": "Sélectionner le type",
        "name surname": "nom prénom",
        telno: "número de teléfono",
        "name middle surname": "nom deuxième nom",
        "full name": "nom complet",
        photo: "photo",
        email: "e-mail",
        "Add profile to target": "Ajouter un profil à la cible",
        "Enhance profile": "Améliorer le profil",
        "Remove Profile": "Supprimer le profil",
        "Alias is too short": `L'alias est trop court`,
        "Special characters are not allowed":
          "Les caractères spéciaux ne sont pas autorisés",
        "Deep search will add second degree relations, likers, commenters, reactors and more":
          "La recherche approfondie ajoutera des relations de deuxième degré, des personnes ayant aimé, commenté, réagi et plus encore.",
        Deep: "Approfondi",
        "Advanced search will add media, relations, insights and more from social profiles":
          "La recherche avancée ajoutera des médias, des relations, des informations et plus encore provenant de profils sociaux.",
        Advanced: "Avancé",
        "Gather more intel from MSISDN, email & social profiles": `Obtenez plus d'informations sur les MSISDN, les e-mails et les profils sociaux.`,
        Basic: "Basique",
        "Enhance profile in order to automatically create a target, discover more intelligence information and retrieve important insights": `Améliorez le profil pour créer automatiquement une cible, découvrir plus d'informations de renseignement et récupérer des informations importantes.`,
        "Enhance profile in order to discover more intelligence information and retrieve important insights": `Améliorez le profil pour découvrir plus d'informations de renseignement et récupérer des informations importantes.`,
        "People nearby": "Personnes à proximité",
        "Visit Target": "Visiter la cible",
        "The request call log is not currently supported for this country": `La demande de journal d'appels n'est pas actuellement prise en charge pour ce pays.`,
        "Analyze locations": "Analyser les emplacements",
        "Find commons": "Trouver des points communs",
        "You have entered some invalid ad ids.":
          "Vous avez entré des ID de publicité invalides.",
        IP: "IP",
        Operator: "Opérateur",
        "Date and time": "Date et heure:",
        "Are you sure you want to delete this search?":
          "Êtes-vous sûr de vouloir supprimer cette recherche?",
        Applications: "Applications",
        IPs: "IP",
        "Phone models": "Modèles de téléphone",
        Carrier: "Opérateur",
        "Select from Existing Targets": "Sélectionner parmi les cibles existantes",
        "Update target": "Mettre à jour la cible",
        OK: "OK",
        "Maximum number of targets has been reached":
          "Vous avez atteint le nombre maximum de cibles",
        Male: "Male",
        Female: "Femme",
        "Location Sources": "Sources de localisation",
        "All sources": "Toutes les sources",
        Geolocations: "Géolocalisations",
        "Call logs": `Journal d'appels`,
        "True Caller": "True Caller",
        "Social networks": "Réseaux sociaux",
        "Other sources": "Autres sources",
        Company: "Entreprise",
        "Disable drawing": "Désactiver le dessin",
        "Meeting Points": "Points de rencontre",
        nmr: "NMR",
        Impersonation: "Impersonation",
        Done: "Done",
        "Target is expired": "La cible a expiré",
        Expired: "Expiré",
        "Renew with the credits": "Renouveler avec les crédits",
        "Click on the button below to renew for #{days} days":
          "Cliquez sur le bouton ci-dessous pour renouveler pendant #{days} jours",
        "Meeting points": "Points de rencontre",
        "Paste image URL": `Coller l'URL de l'image`,
        "Drag image here": `Déplacez l'image ici`,
        "Choose image to upload": `Choisir l'image à télécharger`,
        Unauthorized: "Non autorisé",
        hour: "heure",
        "Upload existing call": `Télécharger un journal d'appels existant`,
        "for at least 2 targets from the case in order to see call log analysis": `pour au moins 2 cibles du cas et pouvoir voir l'analyse du journal d'appels.`,
        "Insights about most common and top associates":
          "Statistiques sur les contacts principaux et les plus courants",
        Analyze: "Analyser",
        "Tagged posts owners": "Personnes identifiées dans les publications",
        "Tagged photos owners": "Personnes identifiées dans les photos",
        "Must be at least 3 characters long and maximum characters allowed are 20, special charactes and preceding or following spaces are not allowed": `La longueur doit être d'au moins 3 caractères et au maximum de 20 caractères, les caractères spéciaux et les espaces précédents ou suivants ne sont pas autorisés`,
        "Call logs should be uploaded as a single .csv or .xlsx file": `Les journaux d'appels doivent être téléchargés sous forme d'un seul fichier .csv ou .xlsx`,
        "Close all": "Fermer tout",
        "Cdr Type": "Type de CDR",
        "Case renewed successfully!": "Affaire renouvelée avec succès!",
        "Case has not been renewed": `L'affaire n'a pas été renouvelée`,
        "Target renewed successfully!": "Cible renouvelée avec succès!",
        "Target has not been renewed": `La cible n'a pas été renouvelée`,
        "Create a new target and Enhance profile":
          "Créer une nouvelle cible et améliorer le profil",
        "First month is free": "Le premier mois est gratuit",
        "Target is Expired": "La cible a expiré",
        "You dont have permissions to use this feature": `Vous n'avez pas les autorisations nécessaires pour utiliser cette fonctionnalité`,
        "Call Log": `Journal d'appels`,
        "Access to the target will be free for #{days} days": `L'accès à la cible sera gratuit pendant #{days} jours`,
        "Could not verify that you are human":
          "Impossible de vérifier que vous êtes humain",
        Bio: "Biographie",
        "No valid MSISDN": "MSISDN invalide",
        "Password expired": "Le mot de passe a expiré",
        Discard: "Abandonner",
        "Target export call log report": `Exporter le rapport de journal d'appels de la cible`,
        "Enable geofencing": "Activer la géolocalisation",
        "Upload call log": `Télécharger le journal d'appels`,
        Dates: "Dates",
        "Select all": "Tout sélectionner",
        "Case call logs report": `Rapport de journal d'appels de l'affaire`,
        "Predicted location": "Emplacement prévu",
        "Last seen": "Vu pour la dernière fois",
        overall: "global",
        incoming: "entrant",
        outgoing: "sortant",
        frequency: "fréquence",
        duration: "durée",
        weekend: "week-end",
        week: "semaine",
        authored: "auteur",
        published: "publié",
        "Virtual ID creation wizard": `Assistant de création d'identifiant virtuel`,
        "Virtual ID information": `Informations sur l'identifiant virtuel`,
        "Select age": `Sélectionnez l'âge`,
        "Select gender": "Sélectionnez le sexe",
        Non: "Non",
        Next: "Suivant",
        "Select a virtual ID or proceed to the next step to create a new one": `Sélectionnez un identifiant virtuel ou passez à l'étape suivante pour en créer un nouveau`,
        "Virtual ID selection": `Sélection d'identifiant virtuel`,
        "Last name": "Nom de famille",
        "Middle name": "Deuxième nom",
        "City name": "Ville",
        "Autocomplete all": "Tout compléter automatiquement",
        "Select social network": "Sélectionner le réseau social",
        Step: "Étape",
        "Generating virtual ID": `Génération d'identifiant virtuel`,
        "Virtual ID  management": "Gestion des identifiants virtuels",
        "New virtual ID ": "Nouvel identifiant virtuel",
        "Virtual ID was created": `L'identifiant virtuel a été créé`,
        "Search virtual ID ": "Rechercher un identifiant virtuel",
        "Profile picture": "Photo de profil",
        "Birth date": "Date de naissance",
        "Remote view": "Vue à distance",
        "Open remote view": "Ouvrir la vue à distance",
        "Last activity": "Dernière activité",
        "No date available": "Aucune date disponible",
        "Status ": "Statut",
        active: "actif",
        idle: "inactif",
        errors: "erreurs",
        "View Log": "Voir le journal",
        "No virtual ID match your criteria":
          "Aucun identifiant virtuel ne correspond à vos critères",
        "There are no virtual IDs assigned to your user": `Aucun identifiant virtuel n'est attribué à votre utilisateur`,
        Reserving: "Réservation",
        Releasing: "Libération",
        "your browser": "votre navigateur",
        "remote browser": "navigateur à distance",
        Avatars: "Avatars",
        "United States": "États-Unis",
        "Time left to expiration:": "Temps restant avant expiration:",
        "This action will fetch call log information for": `Cette action récupérera les informations du journal d'appels pour`,
        "Case is expired": "Le cas est expiré",
        Sim: "Carte SIM",
        "Enter coordinates: lat, lon": "Entrez les coordonnées: lat, lon",
        "Enter phone": "Entrez le téléphone",
        "Paste profile URL": `Coller l'URL du profil`,
        "Enter IMEI": `Entrez l'IMEI`,
        "Invalid IMEI provided": "IMEI fourni invalide",
        "Enter valid coordinates": "Entrez des coordonnées valides",
        "Invalid URL. Social network not supported":
          "URL invalide. Réseau social non pris en charge",
        Creating: "Création en cours",
        "Fetching data": "Récupération de données",
        "Drag to link with another node":
          "Faites glisser pour lier à un autre nœud",
        "Fetch call logs": `Récupérer les journaux d'appels`,
        "Fetch social profiles": "Récupérer les profils sociaux",
        "Fetch instant messaging profiles":
          "Récupérer les profils de messagerie instantanée",
        "Get sim": "Obtenir une carte SIM",
        "Invalid relationship type": "Type de relation invalide",
        "Located at": "Localisé à",
        post: "publication",
        Called: "Appelé",
        "Reserve browser": "Reservar navegador",
        "Used by": "Utilisé par",
        "MSISDN is connected with this instant messaging profile":
          "Le MSISDN est connecté à ce profil de messagerie instantanée",
        "Please select at least 2 targets for call log analysis": `Veuillez sélectionner au moins 2 cibles pour l'analyse du journal d'appels`,
        Reserved: "Réservé",
        "Opening browser": "Ouverture du navigateur",
        "Pre-registering": "Pré-enregistrement",
        "Filling photos": "Remplissage des photos",
        "Filling personal info": "Remplissage des informations personnelles",
        "Registering FB acc.": "Inscription au compte Facebook",
        "Getting OTP": `Obtention de l'OTP`,
        "Filling OTP": `Remplissage de l'OTP`,
        "Logging in": "Connexion en cours",
        "Scrapping photos": "Suppression des photos",
        "Configuring acc.": "Configuration du compte",
        "Liking posts": "Appréciation des publications",
        "Accepting requests": "Acceptation des demandes",
        "Joining groups": "Rejoindre des groupes",
        "Following pages": "Suivre des pages",
        "Configuring Gmail": "Configuration de Gmail",
        Activity: "Activité",
        Social: "Social",
        "Started at": "Commencé à",
        "Completed at": "Terminé à",
        "is expired": "a expiré",
        "days remaining until expiration": `jours restants avant l'expiration`,
        Renew: "Renouveler",
        "Reserve browser failed. Please retry":
          "La réservation du navigateur a échoué. Veuillez réessayer",
        "No available browsers. Please retry":
          "Aucun navigateur disponible. Veuillez réessayer",
        "Management for a new target is free of charge for #{days} days": `La gestion d'une nouvelle cible est gratuite pendant #{days} jours.`,
        "Management for a new case is free of charge for #{days} days": `La gestion d'un nouveau cas est gratuite pendant #{days} jours.`,
        "Create a new target and Request call log": `Créer une nouvelle cible et demander le journal d'appels`,
        "This action will create a new target and request call log for": `Cette action créera une nouvelle cible et demandera le journal d'appels pour`,
        "Renew target and Request call log": `Renouveler la cible et demander le journal d'appels`,
        Copied: "Copié",
        away: "loin",
        "people nearby": "personnes à proximité",
        "Show nearby Telegram users":
          "Afficher les utilisateurs Telegram à proximité",
        "Hide nearby Telegram users":
          "Masquer les utilisateurs Telegram à proximité",
        "Intelligence search": "Recherche de renseignements",
        "Current call": "Appel en cours",
        "Other social profiles": "Autres profils sociaux",
        "Could not find a person with exact input":
          "Impossible de trouver une personne avec une entrée exacte",
        "Owner id": "ID du propriétaire",
        "Avatar remote view ended": `La vue distante de l'avatar est terminée`,
        Current: "Actuel",
        Previously: "Précédemment",
        "Activity log": `Journal d'activité`,
        "Call log analysis": `Analyse du journal d'appels`,
        "Text analysis": "Analyse de texte",
        "Advanced search will gather more intel from MSISDN, email & social profiles and analyse their content such as media, relations, posts and more": `La recherche avancée rassemblera plus d'informations du MSISDN, des emails et des profils sociaux et analysera leur contenu tels que les médias, les relations, les publications et plus encore`,
        "upload existing call log": `Télécharger un journal d'appels existant`,
        Position: "Poste",
        Link: "Lien",
        "Location sources": "Sources de localisation",
        "No results found": "Aucun résultat trouvé",
        Reactions: "Réactions",
        reactions: "Réactions",
        "Reactions count": "Nombre de réactions",
        'No data. Make sure you have seeds and click "Enhance profile"': `Aucune donnée. Assurez-vous d'avoir des données initiales et cliquez sur "Améliorer le profil"`,
        "nearby profiles found": "profils à proximité trouvés",
        "Deep Webint": "Webint approfondi",
        "Must be at least 1 character long and maximum characters allowed are 255, special charactes and preceding or following spaces are not allowed":
          "Doit contenir au moins 1 caractère et le maximum autorisé est de 255 caractères, les caractères spéciaux ainsi que les espaces précédents ou suivants ne sont pas autorisés",
        "Recovery data": "Récupération de données",
        "Authenticating to system": "Authentification au système",
        "Social profile details": "Détails du profil social",
        "Group details": "Détails du groupe",
        "Checkin details": `Détails de l'enregistrement`,
        "Time of the query": "Heure de la requête",
        "Gathering call log entry": `Collecte de l'entrée du journal d'appels`,
        "Generating map": "Génération de la carte",
        Unspecified: "Non spécifié",
        Truecaller: "Truecaller",
        "No matches found": "Aucune correspondance trouvée",
        Monday: "Lundi",
        Tuesday: "Mardi",
        Wednesday: "Mercredi",
        Thursday: "Jeudi",
        Friday: "Vendredi",
        Saturday: "Samedi",
        Sunday: "Dimanche",
        "Name must contain at least 3 characters":
          "Le nom doit contenir au moins 3 caractères",
        "Name is required": "Le nom est requis",
        "Name cannot be just whitespaces": `Le nom ne peut pas être composé uniquement d'espaces blancs`,
        "Receiver number": "Numéro de destinataire",
        "screenWidth must not be less than 640": `La largeur de l'écran ne doit pas être inférieure à 640`,
        "Please provide proper platform link!":
          "Veuillez fournir un lien de plateforme approprié !",
        "Place name": "Nom du lieu",
        Own: "Propre",
        "Tagged by others": `Tagué par d'autres`,
        "New entity": "Nouvelle entité",
        register: `S'inscrire`,
        "Add social network": "Ajouter un réseau social",
        "Not enough credits": "Pas assez de crédits",
        "Posted on": "Publié le",
        "View original post": "Voir la publication originale",
        Any: `N'importe quel`,
        Picture: "Photo",
        "Avatar id": `Identifiant d'avatar`,
        "Last Modified": "Dernière modification",
        "Created On": "Créé le",
        "You do not have access rights or the url is invalid": `Vous n'avez pas les droits d'accès nécessaires ou l'URL est invalide`,
        "Update link analysis": `Mettre à jour l'analyse de lien`,
        "Getting cookies": "Obtention des cookies",
        "Pre registration warmup for #{socialType} started":
          "Pré-enregistrement pour #{socialType} commencé",
        "The whole Facebook registration flow completed": `Le processus d'inscription complet à Facebook est terminé`,
        "Photos uploaded into facebook account. Filling account details":
          "Photos téléchargées sur le compte Facebook. Remplissage des détails du compte",
        "Facebook account confirmed. Uploading photos into account":
          "Compte Facebook confirmé. Téléchargement des photos sur le compte",
        "Otp code obtained. Confirming facebook account registration": `Code Otp obtenu. Confirmation de l'enregistrement du compte Facebook`,
        "#{socialType} registration succeed. Trying to get email otp code": `Enregistrement #{socialType} réussi. Tentative d'obtenir le code Otp par e-mail`,
        "#{socialType} registration started":
          "Enregistrement de #{socialType} démarré",
        "Imported successfully": "Importation réussie",
        "Importing historical data": "Importation de données historiques",
        Owner: "Propriétaire",
        "Case updated successfully!": "Cas mis à jour avec succès !",
        "Remove target": "Supprimer la cible",
        "Add targets": "Ajouter des cibles",
        "Unselect all": "Tout désélectionner",
        "Delete target": "Supprimer la cible",
        "Delete case": "Supprimer le cas",
        "Add case": "Ajouter un cas",
        "Next step": "Étape suivante",
        "screenHeight must not be less than 480": `La hauteur de l'écran ne doit pas être inférieure à 480`,
        "Both screenWidth and screenHeight must be specified": `Les deux valeurs de largeur et hauteur de l'écran doivent être spécifiées.`,
        "is not a valid": `n'est pas valide`,
        "Deleted!": "Supprimé !",
        "Permanently remove target from case":
          "Supprimer définitivement la cible du cas",
        "Are you sure you want to remove target from case permanently?":
          "Êtes-vous sûr de vouloir supprimer définitivement la cible du cas ?",
        "Once removed the data cannot be restored":
          "Une fois supprimées, les données ne peuvent pas être restaurées",
        "There are no avatars created. Please add a new avatar": `Aucun avatar n'a été créé. Veuillez ajouter un nouvel avatar.`,
        "Summary report": "Rapport sommaire",
        "Profiler report": "Rapport du profil",
        Import: "Importer",
        Selected: "Sélectionné",
        "No locations were found for": `Aucun lieu n'a été trouvé pour`,
        "We found available historical data":
          "Nous avons trouvé des données historiques disponibles",
        starting: "démarrage",
        "To import, please select one or multiple days":
          "Pour importer, veuillez sélectionner un ou plusieurs jours",
        "Select all available": "Sélectionner tous les disponibles",
        day: "jour",
        days: "jours",
        "Get historical data": "Obtenir les données historiques",
        "We found available historical data for":
          "Nous avons trouvé des données historiques disponibles pour",
        "Create or add to target": "Créer ou ajouter à la cible",
        "Recovery email": "Courriel de récupération",
        "Recovery phone": "Téléphone de récupération",
        "passwords found": "mots de passe trouvés",
        Choose: "Choisir",
        "Interacted with": "A interagi avec",
        "Show on map": "Afficher sur la carte",
        "Gathering target summary report for":
          "Rassemblement du rapport de résumé de la cible pour",
        "Gathering family relation": "Rassemblement de la relation familiale",
        "Gathering social word details":
          "Rassemblement des détails de mots sociaux",
        "Gathering social network top associates":
          "Rassemblement des principaux associés du réseau social",
        "Gathering call log top associates statistics": `Collecte des statistiques sur les principaux associés du journal d'appels`,
        "Gathering locations": "Collecte des emplacements",
        "Gathering activity feed details": `Collecte des détails de l'historique d'activité`,
        "There are no cases created. Please add a":
          "Aucun cas créé. Veuillez ajouter un",
        "new case": "nouveau cas",
        "New case": "Nouveau cas",
        "Invalid location": "Emplacement invalide",
        "Rows per page": "Lignes par page",
        "There are no targets created. Please add a":
          "Aucune cible créée. Veuillez ajouter une",
        "new target": "nouvelle cible",
        OSINT: "OSINT",
        "View Call Log analysis": `Voir l'analyse du journal d'appels`,
        "Copy password": "Copier le mot de passe",
        "Breached results for": "Résultats violés pour",
        Origin: "Origine",
        "Target summary report": "Rapport de synthèse de la cible",
        "Gathering social network details": "Collecte des détails du réseau social",
        "Events found": "Événements trouvés",
        "Telegram nearby": "Telegram à proximité",
        "This action will show the accurate position of the Telegram nearby people":
          "Cette action montrera la position précise des personnes proches de Telegram",
        source: "source",
        destination: "destination",
        "Could not update target profile, user id already exists!": `Impossible de mettre à jour le profil de la cible, l'identifiant utilisateur existe déjà !`,
        "Social word cloud": "Nuage de mots sociaux",
        "Social network top associates": "Principaux associés du réseau social",
        "Call log top associates": `Principaux associés du journal d'appels`,
        "There are no MSISDNs added. Please add a":
          "Aucun MSISDN ajouté. Veuillez ajouter un",
        "New MSISDN": "Nouveau MSISDN",
        "Clear all filters": "Effacer tous les filtres",
        "Target is already assigned to the case!":
          "La cible est déjà assignée au cas !",
        "Target Expired": "Cible expirée",
        "Call Logs uploaded successfully!": `Registres d'appels téléchargés avec succès !`,
        "Telegram users cannot be located now!":
          "Les utilisateurs Telegram ne peuvent pas être localisés maintenant !",
        "No location found for Telegram users!":
          "Aucun emplacement trouvé pour les utilisateurs Telegram !",
        "Telegram users located successfully!":
          "Utilisateurs Telegram localisés avec succès !",
        "Knowledge base": "Base de connaissances",
        "Call Log MSISDN": "MSISDN du journal des appels",
        "Active Locate": "Localisation active",
        "Active locate": "Localisation active",
        "This action might leave anonymous traces on target’s phone.":
          "Cette action pourrait laisser des traces anonymes sur le téléphone de la cible.",
        "The Cell ID cannot be extracted for": `L'ID de cellule ne peut pas être extrait pour`,
        "Do you want to perform an Active Locate query? This action might leave anonymous traces on target’s phone.":
          "Voulez-vous effectuer une requête de localisation active ? Cette action pourrait laisser des traces anonymes sur le téléphone de la cible.",
        "Active locate query submitted successfully!":
          "La requête de localisation active a été soumise avec succès !",
        "Avatar in error state, please contact administrators": `L'avatar est dans un état d'erreur, veuillez contacter les administrateurs.`,
        "Locate a single MSISDN or IMSI": "Localiser un seul MSISDN ou IMSI",
        "Added Seed": "Semence ajoutée",
        "Added Details": "Détails ajoutés",
        "Added Note": "Note ajoutée",
        "Added Photo": "Photo ajoutée",
        "Expired targets will be renewed and added to case": `Les cibles expirées seront renouvelées et ajoutées au dossier`,
        "Back to Call Logs": `Retour aux journaux d'appels`,
        "Assigned targets changed": "Cibles assignées modifiées",
        "Targets assigned": "Cibles assignées",
        "Targets unassigned": "Cibles non assignées",
        "Note changed": "Note modifiée",
        "Case name changed": "Nom du dossier modifié",
        "Case colour changed": "Couleur du dossier modifiée",
        "Call log successfully processed!": `Journal d'appels traité avec succès !`,
        "Could not gather data for this target":
          "Impossible de collecter des données pour cette cible",
        "Rows per page:": "Lignes par page :",
        "Enter alias": "Entrer un alias",
        "Create new case or search for existing one":
          "Créer un nouveau dossier ou rechercher un dossier existant",
        "Album name": `Nom de l'album`,
        "Album url": `URL de l'album`,
        "Create case": "Créer un dossier",
        "Distance from target": "Distance de la cible",
        "Social profile": "Profil social",
        "Search graph": "Rechercher dans le graphique",
        "Social accounts": "Comptes sociaux",
        "Calls number": `Nombre d'appels`,
        "Calls duration": "Durée des appels",
        "Data transferred": "Données transférées",
        "Geoloc Knowledge Base": "Base de connaissances de géolocalisation",
        "Knowledge Base": "Base de connaissances",
        "Intellectus Knowledge Base": "Base de connaissances Intellectus",
        "Expired cases will be renewed": "Les dossiers expirés seront renouvelés",
        "Target’s subscription is active": `L'abonnement de la cible est actif`,
        "Target’s subscription is not activated": `L'abonnement de la cible n'est pas activé`,
        "Target is connected to the network": "La cible est connectée au réseau",
        "Target’s subscription is active but currently not connected to the network": `L'abonnement de la cible est actif mais elle n'est actuellement pas connectée au réseau`,
        "Target is currently on a call": "La cible est actuellement en appel",
        "Target’s subscription is blocked": `L'abonnement de la cible est bloqué`,
        "Could not determine the target’s status":
          "Impossible de déterminer le statut de la cible",
        ACTIVE: "ACTIF",
        DEACTIVATED: "DÉSACTIVÉ",
        ONLINE: "EN LIGNE",
        OFFLINE: "HORS LIGNE",
        BUSY: "OCCUPÉ",
        UNKNOWN: "INCONNU",
        BLOCKED: "BLOQUÉ",
        "No targets added.": "Aucune cible ajoutée.",
        "Facebook groups": "Groupes Facebook",
        "new MSISDN": "nouveau MSISDN",
        Presets: "Préréglages",
        "Show predicted": "Afficher la prédiction",
        "Sleeping time": "Temps de sommeil",
        "Working time": "Temps de travail",
        "Group results": "Regrouper les résultats",
        "Unmerge results": "Dissocier les résultats",
        "Call Log has been requested": "Le journal d’appels a été demandé",
        "Pending Request": "Demande en attente",
        "Requesting call logs has been disabled":
          "La demande de journaux d’appels a été désactivée",
        "profile location": "localisation du profil",
        "View in link analysis": "Voir dans l’analyse de liens",
        "No Location found for trilateration query!":
          "Aucune localisation trouvée pour la requête de trilatération !",
        "Trilateration query completed!": "Requête de trilatération terminée!",
        "Please enable location access":
          "Veuillez activer l’accès à la localisation",
        Completed: "Terminé",
        "Could not gather data for this report":
          "Impossible de recueillir des données pour ce rapport",
        Job: "Emploi",
        male: "masculin",
        female: "féminin",
        "Enter value": "Entrez une valeur",
        "Export as CSV": "Exporter en CSV",
        "has terminated": "a été interrompu",
        "is deactivated": "est désactivé",
        "Always obtain accurate geolocation information when available":
          "Obtenez toujours des informations de géolocalisation précises lorsque disponibles",
        "Platform settings": "Paramètres de la plateforme",
        "You can turn off": "Vous pouvez désactiver",
        "from Platform settings.": "à partir des paramètres de la plateforme.",
        "Target has not been renewed, Not enough credits":
          "La cible n’a pas été renouvelée, pas assez de crédits",
        "Analyzing Results": "Analyse des résultats",
        "Enable support requests": "Activer les demandes de support",
        Powerusers: "Utilisateurs avancés",
        "Select a value": "Sélectionnez une valeur",
        "Results found": "Résultats trouvés",
        "Searched sources": "Sources recherchées",
        "Save and Locate": "Enregistrer et localiser",
        Adint: "Adint",
        "Virtual ID": "ID virtuelles",
        "Virtual ID management": "Gestion des ID virtuelles",
        Webint: "Webint",
        "Call Log for": "Journal des appels pour",
        "was successfully delivered": "a été livré avec succès",
        "Start scheduler": "Démarrer le planificateur",
        "Start Scheduler": "Démarrer le planificateur",
        "Choose duration and frequency": "Choisir la durée et la fréquence",
        "Choose Duration": "Choisir la durée",
        "Choose Frequency": "Choisir la fréquence",
        "1 day": "1 jour",
        "2 days": "2 jours",
        "1 week": "1 semaine",
        "15 minutes": "15 minutes",
        "30 minutes": "30 minutes",
        "1 hour": "1 heure",
        Logon: "Connexion",
        "Draw circle": "Dessiner un cercle",
        "Stop following": "Arrêter de suivre",
        "Fenced Area": "Zone clôturée",
        "Group members": "Membres du groupe",
        "Enable Call log analysis": "Activer l’analyse du journal des appels",
        "This action will enable Call log analysis":
          "Cette action permettra l’analyse du journal des appels",
        More: "Plus",
        "Cannot upload file": "Impossible de télécharger le fichier",
        Neuter: "Neutre",
        "Something went wrong": "Quelque chose s’est mal passé",
        "Gathering activity patterns": "Collecte des modèles d’activité",
        "Gathering call analysis": "Collecte de l’analyse des appels",
        "Gathering top locations": "Collecte des lieux les plus visités",
        "Gathering count analysis": "Collecte de l’analyse du décompte",
        Maps: "Cartes",
        "Full name": "Nom complet",
        "Mostly active": "Le plus actif",
        "Mostly inactive": "Le plus inactif",
        "Country and region": "Pays et région",
        Region: "Région",
        "Periods analyzed": "Périodes analysées",
        Period: "Période",
        File: "Fichier",
        Starting: "Démarrage du",
        "Gathering details for targets": "Collecte des détails pour les cibles",
        "Gathering call log common associates statistics":
          "Collecte des statistiques des contacts communs dans le journal des appels",
        "Activity Patterns": "Modèles d’activité",
        "Call Analysis": "Analyse des appels",
        "Call Logs": "Journal des appels",
        "Top Associates": "Associés principaux",
        "Top Locations": "Emplacements principaux",
        "Recovery accounts": "Comptes de récupération",
        "Target cannot be renewed. Not enough Credits":
          "La cible ne peut pas être renouvelée. Crédits insuffisants",
        "Intel Search Results": "Résultats de la recherche de renseignements",
        "View the target": "Voir la cible",
        "View the results": "Voir les résultats",
        "We have successfully merged all the collected data to a":
          "Nous avons fusionné avec succès toutes les données collectées en un",
        "single unique profile": "profil unique",
        "Missing CDRs for this target": "CDRs manquants pour cette cible",
        "Waiting for CSV to be downloaded":
          "En attente du téléchargement du fichier CSV",
        "Exporting CSV": "Exportation CSV",
        "We found that": "Nous avons trouvé que",
        "Intelligence suggestions": "Suggestions de renseignements",
        "has used by the same device": "a été utilisé par le même appareil",
        "Call log recommandation archived.":
          "Recommandation de journal des appels archivée.",
        "Call log recommandation unarchived.":
          "Recommandation de journal des appels désarchivée.",
        "Something went wrong, please try again.":
          "Quelque chose s’est mal passé, veuillez réessayer.",
        "No archived recommandations available":
          "Aucune recommandation archivée disponible",
        "No recommandations available": "Aucune recommandation disponible",
        "Exporting file": "Exportation du fichier",
        "Fathers name": "Nom du père",
        "Mothers name": "Nom de famille de la mère",
        "Social insurance number": "Numéro d’assurance sociale",
        "Voter ID": "ID de l’électeur",
        "Call log record": "Journal des appels",
        "Do you want to extract details about the Call Peer":
          "Voulez-vous extraire des détails sur le participant à l’appel ?",
        "Get information": "Obtenir des informations",
        "Get information & geolocate": "Obtenir des informations et géolocaliser",
        "No location data": "Aucune donnée de localisation",
        "was located during a call": "a été localisé pendant un appel",
        "Always obtain the peer details when available":
          "Obtenir les détails du participant à l’appel lorsqu’ils sont disponibles",
        "Always obtain the peer details and geolocate when available":
          "Obtenir les détails du participant à l’appel et géolocaliser lorsqu’ils sont disponibles",
        "Purchase call log": "Acheter le journal des appels",
        "Show archived": "Afficher les archives",
        "Hide archived": "Masquer les archives",
        "This action will purchase the call logs for":
          "Cette action achètera le journal des appels pour",
        "Frequently had consecutive communications with #{receiverMsisdn}":
          "A eu des communications consécutives fréquentes avec #{receiverMsisdn}",
        "Frequently had consecutive communications with #{receiverMsisdn} within 1-hour time intervals,":
          "A eu des communications consécutives fréquentes avec #{receiverMsisdn} dans des intervalles de temps d’1 heure",
        "Frequently communicated with #{receiverMsisdn}":
          "A communiqué fréquemment avec #{receiverMsisdn}",
        "located at #({latitude},{longitude})":
          "localisé à #({latitude},{longitude})",
        "between hours #{hour.start}:00 and #{hour.end}:00":
          "entre les heures #{hour.start}:00 et #{hour.end}:00",
        "between the #{day.start}th and #{day.end}th day of a month":
          "entre le #{day.start}ème et le #{day.end}ème jour d’un mois",
        "between the #{day.start}st and #{day.end}th day of a month":
          "entre le #{day.start}er et le #{day.end}ème jour d’un mois",
        "on #{dayName}s": "les #{dayName}s",
        "at year #{year}": "de l’année #{year}",
        "on #{monthName}": "le #{monthName}",
        January: "janvier",
        February: "février",
        March: "mars",
        April: "avril",
        May: "mai",
        June: "juin",
        July: "juillet",
        August: "août",
        September: "septembre",
        October: "octobre",
        November: "novembre",
        December: "décembre",
        "This associate frequently interacts with the target.":
          "Cet associé interagit fréquemment avec la cible.",
        "No new suggestions available": "Aucune nouvelle suggestion disponible",
        "No suggestions available": "Aucune suggestion disponible",
        "This action will purchase and import call log for":
          "Cette action achètera et importera le journal des appels pour",
        "Importing data": "Importation des données",
        "View call log analysis": "Voir l’analyse du journal des appels",
        "We found an anomaly! This person used the target's mobile device.":
          "Nous avons trouvé une anomalie ! Cette personne a utilisé le téléphone mobile de la cible.",
        "This associate possibly met with the target.":
          "Cet associé a peut-être rencontré la cible.",
        "Purchase call logs failed!": "L’achat des journaux d’appels a échoué !",
        "Purchase call logs initiated!":
          "L’achat des journaux d’appels a été initié !",
        "Select at least one period file":
          "Sélectionnez au moins un fichier de période",
        "Select one or multiple periods": "Sélectionnez une ou plusieurs périodes",
        "Select atleast one IMEI/Phone model":
          "Sélectionnez au moins un modèle de téléphone/IMEI",
        "Failed to find intel suggestions!":
          "Échec de la recherche de suggestions d’intelligence !",
        "What type of investigation are you working on?":
          "Sur quel type d’enquête travaillez-vous ?",
        "Opt-out": "Se désabonner",
        Homicide: "Homicide",
        Violence: "Violence",
        Robbery: "Vol",
        Kidnapping: "Enlèvement",
        "Financial crime": "Crime financier",
        Fraud: "Fraude",
        "Drugs and illegal substances": "Drogues et substances illégales",
        "Are you still in the same investigation?":
          "Êtes-vous toujours dans la même enquête ?",
        "Your feedback is valuable!": "Vos commentaires sont précieux !",
        Dear: "Cher",
        "We are always looking for the ways to improve our product for it to be even more useful in your daily work.":
          "Nous cherchons toujours des moyens d’améliorer notre produit pour qu’il soit encore plus utile dans votre travail quotidien.",
        "To achieve this we are asking you to participate in the user feedback generation program where every now-and-then we would ask you simple questions about how you use Intellectus.":
          "Pour cela, nous vous demandons de participer au programme de génération de commentaires des utilisateurs, où de temps en temps, nous vous poserons des questions simples sur la façon dont vous utilisez Intellectus.",
        "All the data collected will be completely anonymous.":
          "Toutes les données collectées seront complètement anonymes.",
        "Would you like to help us become even better?":
          "Souhaitez-vous nous aider à devenir encore meilleurs ?",
        "No, thank you": "Non, merci",
        "Yes, I want to participate": "Oui, je veux participer",
        "Send us your feedback!": "Envoyez-nous vos commentaires !",
        "Please provide a short description of your work.":
          "Veuillez fournir une brève description de votre travail.",
        "All the answers are anonymous.": "Toutes les réponses sont anonymes.",
        "E.g., 'I work with drug cases in Panama' or 'I work with kidnapping investigations in Mexico'.":
          "Par exemple, 'Je travaille sur des cas de drogue au Panama' ou 'Je travaille sur des enquêtes de kidnapping au Mexique'.",
        "Are you sure you want to leave the program?":
          "Êtes-vous sûr de vouloir quitter le programme ?",
        "Your feedback is very valuable and will help us to improve Intellectus. We would appreciate if you stayed!":
          "Vos commentaires sont très précieux et nous aideront à améliorer Intellectus. Nous apprécierions que vous restiez !",
        "Yes, I want to cancel": "Oui, je veux annuler",
        "No, I want to continue": "Non, je veux continuer",
        "Feedback is required": "Des commentaires sont requis",
        "Automatic interactions": "Interactions automatiques",
        "Random actions": "Actions aléatoires",
        "Specific actions": "Actions spécifiques",
        "Send friend request": "Envoyer une demande d’ami",
        "Like posts": "Aimer les publications",
        "Unlike posts": "Ne plus aimer les publications",
        "Comment on posts": "Commenter les publications",
        "Like group posts": "Aimer les publications de groupe",
        "Unlike group posts": "Ne plus aimer les publications de groupe",
        "Send friend requests to group members":
          "Envoyer des demandes d’ami aux membres du groupe",
        "Share post": "Partager la publication",
        "Join random or specific communities on the selected social network.":
          "Rejoindre des communautés aléatoires ou spécifiques sur le réseau social sélectionné.",
        "Please enter the URL for the specific community you want to be joined. &#10;One URL per line.":
          "Veuillez saisir l’URL de la communauté spécifique à laquelle vous souhaitez vous joindre. &#10;Une URL par ligne.",
        "Enter the text": "Entrez le texte",
        "Add media content": "Ajouter du contenu multimédia",
        "Create post": "Créer la publication",
        "Drag media here": "Faites glisser les médias ici",
        "Choose media for upload": "Choisir des médias à télécharger",
        "Delete file": "Supprimer le fichier",
        "Field is required": "Le champ est obligatoire",
        Run: "Lancer",
        "Stop method was successfully stopped":
          "La méthode d’arrêt a été arrêtée avec succès",
        "Run method was successfully started":
          "La méthode de lancement a été lancée avec succès",
        "Create post was successfully created":
          "La publication a été créée avec succès",
        "Choose an interval": "Choisir un intervalle",
        "Repeat interval - hours": "Répéter l’intervalle - heures",
        "Virtual IDs": "Identifiants virtuels",
        Activities: "Activités",
        "Virtual id": "Identifiant virtuel",
        Idle: "Inactif",
        Stopped: "Arrêté",
        Id: "Identifiant",
        "Time stamp": "Horodatage",
        "New virtual ID": "Nouvel identifiant virtuel",
        "Send friend requests": "Envoyer des demandes d’ami",
        "Social network": "Réseau social",
        Avatar: "Avatar",
        "No activity log for this avatar":
          "Aucun journal d’activité pour cet avatar",
        "User settings": "Paramètres utilisateur",
        "My details": "Mes informations",
        "Invalid type - allowed only png, jpg, jpeg":
          "Type invalide - seuls les formats png, jpg, jpeg sont autorisés",
        "Action has been successfully created": "L’action a été créée avec succès",
        "Action is stopped": "L’action est arrêtée",
        "Action is started": "L’action est lancée",
        "Must be at least 3 characters long and maximum characters allowed are 15, special charactes are not allowed":
          "Doit comporter au moins 3 caractères et au maximum 15 caractères, les caractères spéciaux ne sont pas autorisés",
        Running: "En cours d’exécution",
        "item selected": "éléments sélectionnés",
        "Post is created": "La publication est créée",
        "Post is shared": "La publication est partagée",
        "Post is liked": "La publication est aimée",
        "Post is disliked": "La publication est pas aimée",
        "An international #{type} to or from #{msisdn} coming from #{country} has happened at least #{frequency} time(s).":
          "Une #{type} internationale vers ou depuis #{msisdn} provenant de #{country} s’est produite au moins #{frequency} fois.",
        "Dislike posts": "Ne plus aimer les publications",
        "Join group": "Rejoindre le groupe",
        Specific: "Spécifique",
        Random: "Aléatoire",
        "Enter URL": "Entrer l’URL",
        "Joined group": "Groupe rejoint",
        "Please enter the URL for the specific community you want to be joined":
          "Veuillez saisir l’URL de la communauté spécifique à laquelle vous souhaitez vous joindre",
        "OTP - One Time Password": "OTP - Mot de passe à usage unique",
        "Please select the type of OTP you want to use.":
          "Veuillez sélectionner le type de OTP que vous souhaitez utiliser.",
        "Email address": "Adresse e-mail",
        Unavailable: "Non disponible",
        "Add phone number": "Ajouter un numéro de téléphone",
        "We sent a verification code to the email address:":
          "Nous avons envoyé un code de vérification à l’adresse e-mail:",
        "We sent a verification code to the phone number:":
          "Nous avons envoyé un code de vérification au numéro de téléphone:",
        "Enter the code received via email": "Entrez le code reçu par e-mail",
        "Enter the code received via SMS": "Entrez le code reçu par SMS",
        "Valid phone number entered.": "Numéro de téléphone valide entré.",
        "Disabled Feature": "Fonctionnalité désactivée",
        "Webint features are disabled. Please contact your customer success representative.":
          "Les fonctionnalités Webint sont désactivées. Veuillez contacter votre représentant de succès client.",
        "Contact Customer Success": "Contacter le succès client",
        "Thank you for your message. Your customer success representative will contact you shortly":
          "Merci pour votre message. Votre représentant de succès client vous contactera prochainement",
        "Must be at least 10 characters long and special characters are not allowed":
          "Doit contenir au moins 10 caractères et les caractères spéciaux ne sont pas autorisés",
        "GiO - GeoLocation Services - For exclusive usage by government law enforcement agency: Government of the State of Nuevo Leon - Backed up by EUC - 2017":
          "GiO - Services de géolocalisation - À usage exclusif des agences d'application de la loi du gouvernement : gouvernement de l'État de Nuevo León - Appuyé par EUC - 2017",
        "Passwords not provided": "Mots de passe non fournis",
        "Permission denied": "Autorisation refusée",
        "Phone Number": "Numéro de téléphone",
        "Current Password": "Mot de passe actuel",
        Activated: "Activé",
        Method: "Méthode",
        Application: "Application",
        "Change OTP method": "Changer de méthode OTP",
        "You selected to change OTP method from email to the following phone number":
          "Vous avez choisi de changer de méthode OTP de l'e-mail au numéro de téléphone suivant",
        "You selected to change OTP method from email to application":
          "Vous avez choisi de changer de méthode OTP de l'e-mail à l'application",
        "You selected to change OTP method from phone number to the following email address":
          "Vous avez sélectionné de changer la méthode OTP du numéro de téléphone à l'adresse email suivante",
        "You selected to change OTP method from phone number to application":
          "Vous avez sélectionné de changer la méthode OTP du numéro de téléphone à l'application",
        "You selected to change OTP method from application to the following phone number":
          "Vous avez sélectionné de changer la méthode OTP de l'application au numéro de téléphone suivant",
        "You selected to change OTP method from application to the following email address":
          "Vous avez sélectionné de changer la méthode OTP de l'application à l'adresse email suivante",
        "#{msisdn} has been used on target's phone #{imei}":
          "#{msisdn} a été utilisé sur le téléphone ciblé #{imei}",
        "Call log report in PDF": "Rapport de journal d'appels en PDF",
        "Export table": "Exporter la table",
        "Exporting table in CSV": "Exportation de la table au format CSV",
        "Call log request is already in pending":
          "La demande de journal d'appels est en attente",
        "Export detailed geolocation report":
          "Exporter le rapport de géolocalisation détaillé",
        "Unveil movement patterns and understand your target’s habits":
          "Découvrez les habitudes de mouvement et comprenez les habitudes de votre cible",
        "Unveil movement patterns and understand your target’s habits and associates":
          "Découvrez les habitudes de mouvement, comprenez les habitudes et les contacts de votre cible",
        "Locate now": "Localiser maintenant",
        "Get current location of this MSISDN":
          "Obtenez l’emplacement actuel de ce MSISDN",
        "Investigate lead": "Investiguer la piste",
        "Get historical locations": "Obtenez les emplacements historiques",
        "Uncover usernames, open web mentions, email and social media accounts":
          "Découvrez les noms d’utilisateur, les mentions sur le web, les comptes de messagerie et les réseaux sociaux",
        "Find possible associates, visited places, connections on social media, groups and more.":
          "Trouvez des associés possibles, des lieux visités, des connexions sur les réseaux sociaux, des groupes et plus encore.",
        "Credits will be deducted only if accurate information is found.":
          "Les crédits ne seront déduits que si des informations précises sont trouvées.",
        "Uncover usernames, open web mentions, email and social media accounts that are associated with":
          "Découvrez les noms d’utilisateur, les mentions sur le web, les comptes de messagerie et les réseaux sociaux associés à",
        "Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.":
          "Trouvez des associés possibles, des lieux visités, des connexions sur les réseaux sociaux, des groupes et plus encore. Les crédits ne seront déduits que si des informations précises sont trouvées.",
        "Create a new target and find possible associates, visited places, connections on social media, groups and more.":
          "Créer une nouvelle cible et trouver des associés potentiels, des lieux visités, des connexions sur les réseaux sociaux, des groupes et plus encore.",
        "For generating OTP codes, please download the Authenticator App":
          "Pour générer des codes OTP, veuillez télécharger l’application Authenticator",
        "Add email": "Ajouter un email",
        "Valid email entered": "Adresse email valide",
        "2-Step Verification is OFF":
          "La vérification en deux étapes est désactivée",
        "Turn On": "Activer",
        "Turn Off": "Désactiver",
        CURP: "CURP",
        IFE: "IFE",
        RFC: "RFC",
        "Start typing phone, name, CURP, url, email or username or upload photo":
          "Commencez à taper le téléphone, le nom, le CURP, l’URL, l’email ou le nom d’utilisateur ou téléchargez une photo",
        "data points": "points de données",
        "Perform Location History": "Effectuer un historique de localisation",
        "We now support search by CURP ID":
          "Nous prenons désormais en charge la recherche par ID CURP",
        "List of all targets": "Liste de toutes les cibles",
        "List of all cases": "Liste de tous les cas",
        "List of all imported call detail records":
          "Liste de tous les enregistrements de détails d’appels importés",
        Investigation: "Enquête",
        "Tool to analyze different types of intelligence":
          "Outil pour analyser différents types d’intelligence",
        "Invalid email address": "Adresse email invalide",
        Invalid: "Invalide",
        "Pending call log request!": "Demande de journal d’appels en attente !",
        "Pending call log": "Journal d’appels en attente",
        "Request or Upload Call Log by selecting one of the following options":
          "Demander ou télécharger un journal d’appels en sélectionnant l’une des options suivantes",
        "New call log": "Nouveau journal d’appels",
        Investigations: "Enquêtes",
        investigation: "enquête",
        nodes: "nœuds",
        links: "liens",
        "New investigation": "Nouvelle enquête",
        "Create case investigation": "Créer une enquête de cas",
        "Enter investigation name": "Entrez le nom de l’enquête",
        "Enter a short description of your investigation":
          "Entrez une brève description de votre enquête",
        "Please select at least 1 entity to create a new investigation":
          "Veuillez sélectionner au moins 1 entité pour créer une nouvelle enquête",
        "Selected queue": "File d’attente sélectionnée",
        Graph: "Graphique",
        "Loading data": "Chargement des données",
        "Would you like to save the changes you made in this investigation?":
          "Voulez-vous enregistrer les modifications apportées à cette enquête ?",
        "Go to call logs page": "Aller à la page des journaux d’appels",
        "Select entity": "Sélectionner une entité",
        "Details panel": "Panneau de détails",
        "View target": "Voir la cible",
        "View profile": "Voir le profil",
        "Enter MSISDN": "Entrez le MSISDN",
        "Add targets by searching for target’s Alias.":
          "Ajoutez des cibles en recherchant l’alias de la cible.",
        "closed databases": "Bases de données fermées",
        'Back to "Core"': 'Retour à "Core"',
        Core: "Core",
        "Webint search history": "Historique de recherche Webint",
        "Upload another file": "Télécharger un autre fichier",
        "Request Call Log by selecting one of the following options":
          "Demander un journal d’appels en sélectionnant l’une des options suivantes",
        "Upload Call Log by selecting one of the following options":
          "Télécharger un journal d’appels en sélectionnant l’une des options suivantes",
        Fixed: "Fixe",
        "Credit usage": "Utilisation de crédit",
        Quota: "Quota",
        WEBINT: "WEBINT",
        "Maximum quota": "Quota maximum",
        "Tenant balance": "Solde du locataire",
        "credits assigned": "crédits assignés",
        "credits available": "crédits disponibles",
        Somedus: "Somedus",
        credit: "Crédit",
        "Daily credits assigned": "Crédits quotidiens assignés",
        "Daily credits available": "Crédits quotidiens disponibles",
        "Monthly credits assigned": "Crédits mensuels assignés",
        "Monthly credits available": "Crédits mensuels disponibles",
        "Fixed credits assigned": "Crédits fixes assignés",
        "Fixed credits available": "Crédits fixes disponibles",
        "Maximum credits assigned": "Crédits maximaux assignés",
        "Maximum credits available": "Crédits maximaux disponibles",
        "Raw data": "Données brutes",
        "Fixed quota": "Quota fixe",
        "You have": "Vous avez",
        "available of your queries quota": "disponibles de votre quota de requêtes",
        "Start Investigation": "Commencer l’investigation",
        "Cases and Targets": "Cas et Cibles",
        "Load a target": "Charger une cible",
        "Add MSISDN": "Ajouter un MSISDN",
        "Load a case": "Charger un cas",
        "Graph settings": "Paramètres du graphique",
        "Select analysis": "Sélectionner l’analyse",
        "Select timeline analysis": "Sélectionner l’analyse de la chronologie",
        "Select layout": "Sélectionner la mise en page",
        Restore: "Restaurer",
        "Highlight nodes based on number and type of direct connections":
          "Surligner les nœuds en fonction du nombre et du type de connexions directes",
        'Highlight nodes that act as "bridges" between the connected network':
          'Surligner les nœuds qui agissent comme des "ponts" entre le réseau connecté',
        "Calculates the extended connections of a node. Can identify nodes with influence over the whole network, and not just those directly connected to it.":
          "Calcule les connexions étendues d’un nœud. Peut identifier les nœuds ayant une influence sur l’ensemble du réseau, et pas seulement ceux qui y sont directement connectés.",
        Betweenness: "Intermédiaire",
        "Eigen centrality": "Centralité de l’Eigen",
        "Hour of day": "Heure du jour",
        "Day of week": "Jour de la semaine",
        "Day of month": "Jour du mois",
        "Month of year": "Mois de l’année",
        Standard: "Standard",
        Organic: "Organique",
        Structural: "Structural",
        Lens: "Objectif",
        Tweak: "Ajustement",
        Sequential: "Séquentiel",
        Sources: "Sources",
        "Filter by source type": "Filtrer par type de source",
        "Are you sure you want to delete investigation permanently?":
          "Êtes-vous sûr de vouloir supprimer définitivement cette enquête ?",
        "Permanently delete investigation":
          "Supprimer l’enquête de façon permanente",
        "File name must match #{type} number":
          "Le nom du fichier doit correspondre au numéro #{type}",
        "Upload call log for: #{value}":
          "Télécharger le journal des appels pour : #{value}",
        "File name": "Nom du fichier",
        "File size": "Taille du fichier",
        "Vehicle year": "Année du véhicule",
        "Vehicle model": "Modèle du véhicule",
        "quota reset to initial balance": "Quota réinitialisé au solde initial",
        call_log_upload_imei: "Téléchargement réussi du CDR IMEI",
        "Credit change for request_cdr for IMEI":
          "Changement de crédit pour la demande de CDR pour IMEI",
        "Vehicle ID (VIN)": "ID du véhicule (VIN)",
        "Property type(s)": "Type(s) de propriété",
        "Property last sale date(s)":
          "Dernière(s) date(s) de vente de la propriété",
        Industries: "Industries",
        "Vehicle make": "Marque de véhicule",
        Volunteering: "Bénévolat",
        Skills: "Compétences",
        Courses: "Cours",
        "Associated domains": "Domaines associés",
        "Home last sale date": "Dernière date de vente de la maison",
        "Property type": "Type de propriété",
        "Fan of": "Fan de",
        "Other info": "Autre information",
        "All groups": "Tous les groupes",
        "All fans": "Tous les fans",
        "All skills": "Toutes les compétences",
        "All courses": "Tous les cours",
        "All industries": "Toutes les industries",
        "All vehicle models": "Tous les modèles de véhicules",
        "All vehicle make": "Toutes les marques de véhicules",
        "All vehicle ID (VIN)": "Tous les ID de véhicules",
        "All volunteering actions": "Toutes les actions de bénévolat",
        "All associated domains": "Tous les domaines associés",
        "All other info": "Toutes les autres informations",
        "Family relations": "Relations familiales",
        "Work relations": "Relations professionnelles",
        "Other relations": "Autres relations",
        Relation: "Relation",
        "Vehicle models": "Modèles de véhicules",
        "Volunteering actions": "Actions de bénévolat",
        "Only xlsx or csv files are supported":
          "Seuls les fichiers xlsx ou csv sont pris en charge",
        "Daily quota assigned": "Quota quotidien assigné",
        "Monthly quota": "Quota mensuel",
        Maximum: "Maximum",
        "Available Credits": "Crédits disponibles",
        "Define quota": "Définir le quota",
        "Monthly quota assigned": "Quota mensuel assigné",
        "Maximum quota assigned": "Quota maximum assigné",
        "Quota unused": "Quota inutilisé",
        "Quota assigned": "Quota assigné",
        "Available to assign": "Disponible pour assigner",
        "Daily quota": "Quota quotidien",
        "Other recovery accounts": "Autres comptes de récupération",
        "User created with": "Utilisateur créé avec",
        "credits by": "crédits par",
        inactive: "inactif",
        "Timeline pattern": "Motif de la chronologie",
        "Most active period": "Période la plus active",
        "Most inactive period": "Période la moins active",
        Law: "Loi",
        "No credits assigned": "Aucun crédit attribué",
        "List of periods": "Liste des périodes",
        "Targets renewed successfully!": "Cibles renouvelées avec succès !",
        "Recovery Accounts": "Comptes de récupération",
        "All friend relations": "Toutes les relations d’amitié",
        "All family relations": "Toutes les relations familiales",
        "All work relations": "Toutes les relations professionnelles",
        "All other relations": "Toutes les autres relations",
        "Friend relations": "Relations d’amitié",
        Fans: "Fans",
        "List of msisdns": "Liste des MSISDN",
        "List of imeis": "Liste des IMEI",
        "List of imsis": "Liste des IMSI",
        "Phone models / IMEIs": "Modèles de téléphone / IMEI",
        "Select one or multiple imsis": "Sélectionnez un ou plusieurs IMSI",
        "Select one of multiple msisdns": "Sélectionnez un ou plusieurs MSISDN",
        "Select one or multiple models": "Sélectionnez un ou plusieurs modèles",
        "Select one or multiple msisdns": "Sélectionnez un ou plusieurs MSISDN",
        IMSIs: "IMSI",
        target_management: "Gestion des cibles",
        advanced_osint: "OSINT avancé",
        "One successful Advanced OSINT": "Un OSINT avancé réussi",
        "One successful Intel Search": "Une recherche de renseignements réussie",
        "Advanced osint": "OSINT avancé",
        call_info: "Informations sur l’appel",
        case_management: "Gestion des cas",
        neighbour_cell_towers: "Tours de cellules voisines",
        "Neighbour cell towers with params":
          "Tours de cellules voisines avec des paramètres",
        trilateration_nearby_people: "Trilatération de personnes à proximité",
        query_foreign_location: "Recherche de localisation étrangère",
        "Place of birth": "Lieu de naissance",
        "Favorite results": "Résultats favoris",
        "Work place": "Lieu de travail",
        "Vehicle ID": "Identifiant de véhicule",
        "Dates of birth": "Dates de naissance",
        "Switch to dark theme": "Passer au thème sombre",
        Hierarchy: "Hiérarchie",
        Sequencial: "Séquentiel",
        "Current And Previous Workplaces (Target)":
          "Lieu de travail actuel et précédent (Cible)",
        "Education (Target)": "Éducation (Cible)",
        "Switch to light theme": "Passer au thème clair",
        "Export current investigation view": "Exporter la vue d’enquête actuelle",
        "Enter report title": "Entrer le titre du rapport",
        "Enter notes": "Entrer des notes",
        "Generate pdf": "Générer un PDF",
        "Export CSV": "Exporter en CSV",
        renewed: "renouvelé",
        "Top Facebook Connections": "Principales connexions Facebook",
        "Mutual Facebook Friends": "Amis Facebook en commun",
        "Top Commenteers In Facebook Photos":
          "Principaux commentateurs sur les photos Facebook",
        "Top Likers In Facebook Posts":
          'Principaux "j’aime" sur les publications Facebook',
        Studied: "A étudié",
        "Connected with this location": "Connecté à cet emplacement",
        "Profile is associated with this photo":
          "Le profil est associé à cette photo",
        "Profile is associated with this post":
          "Le profil est associé à cette publication",
        "View post": "Voir la publication",
        "View photo": "Voir la photo",
        "Tagged in posts of": "Tagué dans les publications de",
        "Tagged in photos of": "Tagué dans les photos de",
        Home: "Accueil",
        "current and previous workplaces (target)":
          "Lieu de travail actuel et précédent (Cible)",
        "education (target)": "Éducation (Cible)",
        "Location probability": "Probabilité de localisation",
        "Search name, MSISDNs or cases": "Rechercher un nom, un MSISDN ou des cas",
        "Search MSISDNs": "Rechercher des MSISDN",
        "Call logs interaction depth":
          "Profondeur d'interaction des journaux d'appels",
        "Export graph": "Exporter le graphique",
        "Drag and drop to load a target to the investigation":
          "Faites glisser et déposez pour charger une cible dans l’enquête",
        "Drag and drop to add an MSISDN to the investigation":
          "Faites glisser et déposez pour ajouter un MSISDN à l’enquête",
        "Drag and drop to load a case to the investigation":
          "Faites glisser et déposez pour charger un dossier dans l’enquête",
        "Search entities": "Rechercher des entités",
        "IP addresses": "Adresses IP",
        "All IP addresses": "Toutes les adresses IP",
        call_log_upload_msisdn: "Téléchargement de journaux d’appels MSISDN",
        case: "cas",
        time: "fois",
        times: "fois",
        "View call log": "Voir le journal d’appels",
        "Add at least 1 MSISDN or IMEI": "Ajoutez au moins 1 MSISDN ou IMEI",
        "The inputted #{clParameterType} is partially invalid. Please update your request with the following #{clParameterType}:":
          "Le #{clParameterType} saisi est partiellement invalide. Veuillez mettre à jour votre demande avec le #{clParameterType} suivant :",
        "#{clParameterType} entered is not valid. Please try another one":
          "Le #{clParameterType} saisi n’est pas valide. Veuillez en essayer un autre",
        call_log_purchase: "Achat de journaux d’appels",
        logon_query_location_offline:
          "Connexion à la requête de localisation hors ligne",
        import_historical_locations: "Importation d’emplacements historiques",
        "Import Historical Locations": "Importation d’emplacements historiques",
        "request cdr": "Demande de CDR",
        "Top Commenteers In Facebook Posts":
          "Top des commentateurs de publications sur Facebook",
        "Tagged In Same Photo": "Tagué(s) sur la même photo",
        "Top Facebook Post Shares": "Top des partages de publications sur Facebook",
        "Top Facebook Photo Shares": "Top des partages de photos sur Facebook",
        "Top Likers In Facebook Photos": "Top des Likers de photos sur Facebook",
        query_location_offline: "Requête de localisation hors ligne",
        "Purchase and import call log data for":
          "Acheter et importer des données de journal d’appels pour",
        "Gathering common locations": "Collecte des emplacements communs",
        Peer: "Appelé",
        call_log_request_msisdn: "Demande de journaux d’appels",
        "Advanced webint is already in progress.":
          "Webint avancé est déjà en cours.",
        months: "mois",
        "Please select the time period":
          "Veuillez sélectionner la période de temps",
        "Note: Time periods are not available for Upload call logs ":
          "Note : Les périodes de temps ne sont pas disponibles pour le téléchargement des journaux d’appels",
        "You have to save the investigation before uploading a file":
          "Vous devez enregistrer l’enquête avant de télécharger un fichier",
        "Credits are expired": "Les crédits sont expirés",
        "Call log request #{type} for #{duration} months":
          "Demande de journaux d’appels pour #{type} de #{duration} mois",
        "One successful call log request for #{type} #{value} for the period of #{duration} months":
          "Une demande de journaux d’appels réussie pour #{type} #{value} pour la période de #{duration} mois",
        "Call log upload for #{type}":
          "Téléchargement de journaux d’appels pour #{type}",
        "One successful import cdr for #{type} #{value}":
          "Une importation réussie de CDR pour #{type} #{value}",
        "Select case": "Sélectionner un dossier",
        "Change language": "Changer de langue",
        "Age range": "Tranche d’âge",
        "Get Facebook Posts": "Obtenir des publications Facebook",
        "Get Cell Level Location": "Obtenir la localisation de la tour",
        "Do you want to get cell level location?":
          "Voulez-vous obtenir la localisation de la tour ?",
        "Query successfully submitted": "Requête envoyée avec succès",
        "IP Location": "Localisation IP",
        "Get Facebook Relations": "Obtenir des relations Facebook",
        "Software Information": "Informations sur le logiciel",
        "Covid vaccine": "Vaccin Covid",
        Pregnant: "Enceinte",
        "House number": "Numéro de maison",
        "Wanted for": "Recherché pour",
        "Skin tone": "Tonalité de la peau",
        "Medical condition": "État de santé",
        "Card number": "Numéro de carte",
        Cv: "CV",
        "Birth location": "Lieu de naissance",
        Scars: "Cicatrices",
        "Covid vaccination status": "Statut de vaccination COVID",
        "Registration date": "Date d’inscription",
        "Affiliated person": "Personne affiliée",
        "Act number": "Numéro d’acte",
        "Is dead": "Est décédé",
        "Identification card number": "Numéro de carte d’identité",
        Institution: "Institution",
        "Instagram handle": "Identifiant Instagram",
        Cve: "CV",
        "Bank details": "Détails bancaires",
        Colony: "Colonie",
        Consec: "Consec",
        "Photo update time": "Heure de mise à jour de la photo",
        "To search for a phone number, enter the complete number, including country code. Example: #{example}":
          "Pour rechercher un numéro de téléphone, entrez le numéro complet, y compris le code pays. Exemple : #{example}",
        "Enter the complete phone number including country code. Example: #{example}":
          "Entrez le numéro de téléphone complet, y compris le code pays. Exemple : #{example}",
        "View place": "Voir lieu",
        "is in": "est dans",
        "View Dashboard": "Voir le tableau de bord",
        "Credits Usage Statistics": "Statistiques d’utilisation des crédits",
        "New Credits Usage Statistics Dashboard":
          "Nouveau tableau de bord des statistiques d’utilisation des crédits",
        "check in": "lieux à",
        "Username already exists. Please try again.":
          "Le nom d’utilisateur existe déjà. Veuillez réessayer.",
        "Peer interactions": "Interactions entre pairs",
        "Accept friends": "Accepter des amis",
        "Error occured while attempting to reserve":
          "Une erreur s’est produite lors de la tentative de réservation",
        "Postal code": "Code postal",
        "Facebook id": "Identifiant Facebook",
        Callerid: "Callerid",
        Foreign: "Étranger",
        successfully: "avec succès",
        "Cancelled request": "Demande annulée",
      };