interface Array<T> {
  /**
 * @type {T} Type of each element in array
 * @param predicate A function or string that defines the unique key of the Array to be filtered.
 * @returns Array of T
 */
  uniqueBy(predicate: ((value: T) => string | number) | keyof T): Array<T>;
}

function uniqueBy<T>(predicate: ((value: T) => string | number) | string): T[] {
  const cb = typeof predicate === 'function' ? predicate : (o: T) => o[predicate];
  return [
    ...this.reduce((map: Map<string | number, T>, item: T) => {
      const key = item === null || item === undefined ? item : cb(item);

      map.has(key) || map.set(key, item);

      return map;
    }, new Map()).values(),
  ];
};
Array.prototype.uniqueBy = uniqueBy;
