import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  Subject,
  take,
  zip,
} from 'rxjs';
import {
  CaseInvestigation,
  InvestigationsListResponse,
  LinkAnalysisGraphState,
  TimelineState,
} from 'src/app/modules/analysis/shared/models/case-investigations.model';
import { BaseService } from 'src/app/services/base.service';
import {
  transformSnakeToCamel,
  transformCamelToSnakeRecursive,
} from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root',
})
export class CaseInvestigationService extends BaseService {
  refreshInvestigationsList = new BehaviorSubject<boolean>(false);
  saveState = new Subject<boolean>();
  chartState = new Subject<LinkAnalysisGraphState>();
  timelineState = new Subject<TimelineState>();
  graphImage = new Subject<string>();
  currentInvestigation: CaseInvestigation;
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
  }

  readonly apiPrefix = 'case-investigations';

  getCaseInvestigationsList(
    limit: number,
    page: number
  ): Observable<InvestigationsListResponse> {
    return this.httpClient
      .get<InvestigationsListResponse>(
        `${this.fastAPIurl}/${this.apiPrefix}/?page=${page}&limit=${limit}`
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getCaseInvestigationById(
    investigationId: string
  ): Observable<CaseInvestigation> {
    return this.httpClient
      .get<{ result: CaseInvestigation }>(
        `${this.fastAPIurl}/${this.apiPrefix}/${investigationId}`
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data.result);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  createCaseInvestigation(
    requestBody: CaseInvestigation
  ): Observable<CaseInvestigation> {
    return this.httpClient
      .post<{ result: CaseInvestigation }>(
        `${this.fastAPIurl}/${this.apiPrefix}/`,
        transformCamelToSnakeRecursive(requestBody)
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data.result);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  deleteCaseInvestigation(investigationId: string): Observable<string[]> {
    return this.httpClient
      .delete<any>(`${this.fastAPIurl}/${this.apiPrefix}/${investigationId}`)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  updateCaseInvestigation(
    requestBody: Partial<CaseInvestigation>,
    investigationId: string
  ): Observable<CaseInvestigation> {
    return this.httpClient
      .put<{ result: CaseInvestigation }>(
        `${this.fastAPIurl}/${this.apiPrefix}/${investigationId}`,
        transformCamelToSnakeRecursive(requestBody)
      )
      .pipe(
        map((data) => {
          this.saveState.next(false);
          return transformSnakeToCamel(data?.result);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  filterCaseInvestigation(requestBody: any): Observable<string[]> {
    return this.httpClient
      .post<any>(`${this.fastAPIurl}/${this.apiPrefix}/filter`, requestBody)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => this.handleError(error))
      );
  }

  searchCaseInvestigation(
    limit: number,
    page: number,
    search_query: string
  ): Observable<InvestigationsListResponse> {
    return this.httpClient
      .get<InvestigationsListResponse>(
        `${this.fastAPIurl}/${this.apiPrefix}/?page=${page}&limit=${limit}&search_query=${search_query}`
      )
      .pipe(
        map((data) => {
          return transformSnakeToCamel(data);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  public gatherStateData(): Observable<
    (string | LinkAnalysisGraphState | TimelineState)[]
  > {
    return zip(this.chartState, this.timelineState, this.graphImage).pipe(
      take(1)
    );
  }

  public getIvestigationListBasedOnSearch(
    limit: number,
    page: number,
    search_query?: string
  ): Observable<InvestigationsListResponse> {
    search_query = search_query.trim();
    if (search_query) {
      return this.searchCaseInvestigation(limit, page, search_query);
    } else {
      return this.getCaseInvestigationsList(limit, page);
    }
  }

  public getFileIdFromFilename(filename: string) {
    return this.currentInvestigation.entities.files.find(
      (elem) => elem.filePath === filename
    );
  }
}
