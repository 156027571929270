<div class="la-top-filters">
  <div class="left-filters">
    <div class="la-counter-labels">{{ 'Nodes' | translate }}: {{ nodesCounter }}</div>
    <div class="la-counter-labels">{{ 'Links' | translate }}: {{ linksCounter }}</div>
    <button
      *ngIf="playgroundView || casePlaygroundView"
      mat-button
      class="add-entity-btn cp-btn-primary capitalize-none"
      (click)="onShowEntitiesPanel()"
    >
      {{ 'Add entity' | translate }}
    </button>
    <!-- Layout select -->
    <mat-select
      class="global-select option-dropdown"
      (selectionChange)="onChartLayout($event)"
      [placeholder]="'Layout' | translate"
    >
      <mat-select-trigger
        >{{ 'Layout' | translate }}: {{ selectedLayout | titlecase }}</mat-select-trigger
      >
      <mat-option *ngFor="let option of chartLayouts" [value]="option">{{
        option | titlecase | translate
      }}</mat-option>
    </mat-select>
    <!-- Quick filters -->
    <mat-select
      *ngIf="!(caseView || playgroundView)"
      class="global-select option-dropdown"
      (selectionChange)="onQuickFilterChange($event)"
      [placeholder]="'Quick filters' | translate"
      [value]="selectedQuickFilterOption"
    >
      <mat-select-trigger matTooltip="{{ selectedQuickFilterOption?.label | titlecase | translate}}"
        >{{ 'Filter' | translate }}: {{ selectedQuickFilterOption?.label | translate }}</mat-select-trigger
      >
      <mat-option (click)="onShowAllGraphData()" [value]="'None'">{{
        'None' | translate
      }}</mat-option>
      <mat-option
        *ngFor="let option of quickFilterOptions"
        [value]="option"
        [class.hidden]="option.value.includes('deepOsint') && !deepOsintFlagEnabled"
        [disabled]="option.value.includes('deepOsint') && target.deepOsintStatus !== 'DONE'"
      >
        {{ option.label | titlecase | translate}}
      </mat-option>
    </mat-select>

    <!-- Canvas search -->
    <div class="search-input-container entities-search">
      <input
        type="text"
        placeholder="{{ 'Search graph' | translate }}"
        matInput
        [formControl]="entitiesAutocompleteControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of entitiesFilteredList | async"
          [value]="option.d.label"
          class="entities-option"
          (click)="onSelectedEntity(option.id)"
        >
          <span
            *ngIf="playgroundView"
            class="entities-type"
            [ngStyle]="{ color: nodeTypeToColor[option.d.type] }"
            >{{ entitiesTypeToLabel[option.d.type] | translate }}</span
          >{{ option.d.label | translate }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <!-- Degrees label+slider -->
    <ng-container *ngIf="socialProfileNodes?.length > 20 || target?.deepOsintStatus === 'DONE'">
      <div class="degree-label" [matTooltip]="showExplanation('degrees')">
        {{ 'Degrees' | translate }}:
      </div>
      <ng-template *ngTemplateOutlet="degreesSliderTemplate"></ng-template>
    </ng-container>

    <!-- Mutual Friends Slider (Commented out for now)-->
    <!-- <div *ngIf="selectedQuickFilterOption?.value === 'deepOsint.mutualFriends.request'" class="mutual-friends-slider"> -->
    <!-- <nouislider [config]="mutualFriendsSliderConfig"  (end)="onMutualFriendsSliderUpdate($event)"></nouislider> -->
    <!-- </div> -->
  </div>
  <div class="right-filters inline-flex">
    <mat-checkbox
      checked="true"
      labelPosition="before"
      class="la-top-filter-checkbox filter-item-border"
      (change)="onToggleLabels($event)"
      >{{ 'Labels' | translate }}</mat-checkbox
    >
    <button
      mat-button
      *ngIf="enableShowPhotosButton"
      class="filter-item-border"
      (click)="onTogglePhotos()"
    >
      {{ 'Toggle photo' | translate }}
    </button>
    <div
      *ngIf="playgroundView || casePlaygroundView"
      class="toggle-la sync-with-table-toggle la-sidebar-toggle filter-item-border"
    >
      <mat-slide-toggle labelPosition="before" (change)="onToggleMapView($event)">
        {{ 'Map' | translate }}
      </mat-slide-toggle>
    </div>
    <div class="toggle-la sync-with-table-toggle la-sidebar-toggle filter-item-border">
      <mat-slide-toggle
        labelPosition="before"
        [checked]="showFilterSidebar"
        (change)="onToggleFilterSidebar($event)"
      >
        {{ 'Filter panel' | translate }}
      </mat-slide-toggle>
    </div>
  </div>
</div>

<ng-template #degreesSliderTemplate>
  <mat-slider
    #degreesSlider
    class="width-100 degrees-slider"
    [invert]="degreesSliderConfig.invert"
    [max]="degreesSliderConfig.max"
    [min]="degreesSliderConfig.min"
    [step]="degreesSliderConfig.step"
    [thumbLabel]="degreesSliderConfig.thumbLabel"
    [value]="
      !(caseView || playgroundView) && deepOsintFlagEnabled && target.deepOsintStatus === 'DONE'
        ? degreesSliderConfig.value
        : 1
    "
    (change)="degrees(degreesSlider.value)"
    [displayWith]="formatDegreesSliderLabel"
  >
  </mat-slider>
</ng-template>
