import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AuthState } from 'src/app/services/authentication/auth-state.enum';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GroupNotification } from 'src/app/shared/models/group-notifications.model';
import { IntelResultsNotificationComponent } from './intel-results-notification.component';
import { IntelResultResponse, IntelResultsNotificationsStore } from './intel-results-notification.store';

@Injectable({
  providedIn: 'root'
})
export class IntelResultsNotificationsService extends GroupNotification {
  toastMessage = new BehaviorSubject<any>(null);
  body: IntelResultResponse = {};
  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private toastStore: IntelResultsNotificationsStore
  ) {
    super();
    this.authService.authState$.subscribe(state => {
      if (state !== AuthState.authenticated) {
        this.cleanAllToasts();
      }
    });
  }

  processNotification(message: any) {
    const newId = message.correlationId;
    if (!this.toastStore.hasNotificationWithId(newId)) {
      this.addToast(message);
    }
    // clean an old notifications for group if OSINT was ran again
    if (this.toastStore.hasDoneNotification(newId)) {
      this.toastStore.cleanNotificationsById(newId);
    }

    this.toastStore.addNotification(message);
  }

  public cleanAllToasts() {
    this.toastr.toasts.forEach(t => {
      t.toastRef.close();
      this.toastStore.cleanNotificationsById(t.toastRef.componentInstance.id);
    });
  }

  protected addToast(message: any) {
    const toast = this.toastr.success(null, null, {
      toastComponent: IntelResultsNotificationComponent,
      positionClass: 'toast-bottom-right'
    });

    toast.toastRef.componentInstance.id = message.correlationId;
    toast.toastRef.componentInstance.toast = toast;
  }

  cleanNotificationsById(message: any) {
    this.toastStore.cleanNotificationsById(message.correlationId);
  }
}
