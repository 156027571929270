export enum Platform {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  SKYPE = 'skype',
  TELEGRAM = 'telegram',
  WHATSAPP = 'whatsapp',
  TRUECALLER = 'truecaller',
  VIBER = 'viber',
  WECHAT = 'wechat',
  GIO = 'gio',
  LINKEDIN = 'linkedin',
  PERSON = 'person', // social search
  DARKWEB = 'darkweb',
  OPENSEARCH = 'opensearch',
  DBSEARCH = 'dbsearch',
  CallerID = 'callerid',
}
