<div class="row center-xs support-form">
  <div class="col-12">
    <div class="support-title">{{'Support request' | translate}}</div>
    <form [formGroup]="contactForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'Title' | translate}}</mat-label>
        <input formControlName="title" matInput required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'Name' | translate}}</mat-label>
        <input formControlName="name" matInput required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'Email' | translate}}</mat-label>
        <input formControlName="email" matInput required>
      </mat-form-field>
      <mat-form-field class="txt" appearance="outline">
        <mat-label>{{'Message' | translate}}</mat-label>
        <textarea matInput formControlName="message" required></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-select [placeholder]="'Severity' | translate" formControlName="severity" required>
          <mat-option *ngFor="let severity of severities" [value]="severity.value">
            {{severity.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="row right-xs">
        <button mat-raised-button class="select-file-btn capitalize-none" (click)="file.click()">{{ 'Select file' | translate }}</button>
        <input type="file" #file class="hidden" (change)="fileUpload($event)" />
      </div>
      <div class="row right-xs file-name">
        {{uploadedFile ? uploadedFile.name : ''}}
      </div>
      <div class="row right-xs">
        <mat-error *ngIf="errorMsg">
          {{'Maximum allowed size:' | translate}} {{max_size_bytes / 1000 / 1000}} MB
          <br />{{'Allowed file types: images' | translate}}
        </mat-error>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="contact-actions-align">
    <button mat-raised-button class="cancel" (click)="onClose()" type="button">{{'Cancel' | translate}}</button>
    <button
      mat-raised-button
      class="ok"
      color="accent"
      type="submit"
      [disabled]="!contactForm.valid || errorMsg"
      (click)="onSubmit()">
      {{'Send' | translate}}
    </button>
  </div>
</div>
