<div *ngIf="!isProfilerView && !isAdminView" class="global-select-label">{{'Select dates' | translate}}</div>
<div class="global-input datetimepicker-input" [class.profiler-call-log-datepicker]="isProfilerView">
  <input matInput
    class="remove-input-styling clickable"
    [owlDateTimeTrigger]="dt10"
    [owlDateTime]="dt10"
    [selectMode]="'range'"
    [(ngModel)]="dateTimeRange"
    (ngModelChange)="getRange($event)"
    [min]="minDate"
    [max]="maxDate"
    autocomplete="off"
    readonly
    [placeholder]="isProfilerView ? placeholderText : 'Select dates' | translate"
  >
  <mat-icon *ngIf="!isProfilerView" class="owl-datepicker datepicker-basic-icon" [owlDateTimeTrigger]="dt10">calendar_today</mat-icon>
  <owl-date-time #dt10></owl-date-time>
</div>
