import { Injectable } from '@angular/core';
import { CdrTarget } from '@trg-commons/data-models-ts';
import { Polygon } from 'src/app/modules/mapV2/models/map.models';
import { QueryService } from 'src/app/services/query/query.service';
import { Azimuth, NMR, Query } from 'src/app/shared/models/query-item.model';
import { ClMapService } from 'src/app/shared/modules/call-logs-shared/services/cl-map.service';

@Injectable({
  providedIn: 'root'
})
export class MarkerSectorService {
  constructor(
    private queryService: QueryService,
    private clMapService: ClMapService,
  ) { }

  getSector(query): Polygon | undefined {
    const nmrSector = this.getNMRSector(query);
    const azimuthSector = this.getAzimuthSector(query);

    return nmrSector || azimuthSector;
  }

  getSectorsFromQuery(query): Polygon[] {
    const sectors: Polygon[] = [];

    const nmrSector = this.getNMRSector(query);
    const azimuthSector = this.getAzimuthSector(query);

    if (nmrSector) {
      sectors.push(nmrSector);
    }

    if (azimuthSector) {
      sectors.push(azimuthSector);
    }

    return sectors;
  }

  getSectorsFromCdr(cdrs: CdrTarget[], requestTelnos: string[]): Polygon[] {
    return [...this.clMapService.createAzimuthPolygons(cdrs, requestTelnos)]
      .map(polygon => ({ ...polygon, show: true, extendMapBounds: true }));
  }

  getNMRSector(query): Polygon | undefined {
    if (
      query?.nmr?.billingId &&
      query.nmr.sectorLocation &&
      query.nmr.combasBearing &&
      query.nmr.sectorSize &&
      query.nmr.bearingDirection
    ) {
      return this.getCircularPolygon(query, query.nmr);
    }
  }

  getAzimuthSector(query): Polygon | undefined {
    if (query?.azimuth) {
      return this.getCircularPolygon(query, query.azimuth);
    }
  }

  getCircularPolygon({ id, location, accuracyMeters }: Query, sectorData: NMR | Azimuth): Polygon {
    return new Polygon({
      id: id.toString(),
      strokeColor: 'white',
      strokeOpacity: 1,
      fillColor: '#0C97FF',
      points: this.queryService.calculateCircularSector(location, sectorData, accuracyMeters, true),
      extendMapBounds: true,
      fillOpacity: 0.3,
      isPopupWindowOpen: true
    });
  }
}
