import { Entity } from '../base';
import { Coordinates } from 'src/app/shared/models/coordinates-interface';
import { Place } from 'src/app/shared/models/place-interface';

export class Post extends Entity {
  public title?: string;
  public body?: string;
  public url?: string;
  public language?: string;
  public agent?: string;
  public coordinates?: Coordinates;
  public place?: Place;
  public profileId?: string;
  public mentionType?: string;
  public publishedAt?: Date;
  public validAt?: Date;
  public feedbackStats?: FeedbackStats;
  public images?: ImageUrl[];
}


export interface FeedbackStats {
  likesCount: number;
  commentsCount: number;
}

export interface ImageUrl {
  imageUrl: string;
}