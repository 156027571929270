import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Inject,
  Injector,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import MarkerClusterer, { MarkerClustererOptions } from '@googlemaps/markerclustererplus';
import { get, isEqual } from 'lodash-es';
import { isEmpty } from 'src/app/shared/util/isEmpty';
import { BaseMapImplComponent } from 'src/app/modules/mapV2/components/map/base-map-impl.component';
import { IMapService } from 'src/app/modules/mapV2/models/map-service.interface';
import {
  Button,
  Circle,
  DrawMode,
  DrawModeOptions,
  Feature,
  HeatMap,
  IconMarker,
  MapStyleWithOptions,
  MapTypeId,
  Point,
  Polygon,
  Polyline,
  Rectangle,
  StyledMapTypeOptions,
  TileSource,
} from 'src/app/modules/mapV2/models/map.models';
import { MapButtonComponent } from 'src/app/modules/mapV2/vanilla-google-map/map-button/map-button.component';
import { MarkerFactory } from 'src/app/modules/mapV2/vanilla-google-map/shared/marker.factory';
import { OverlayFactory } from 'src/app/modules/mapV2/vanilla-google-map/shared/overlay.factory';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { CallLogFeatureData } from 'src/app/shared/models/call-log-feature-data.model';
import { normalizeToArray } from 'src/app/shared/util/helper';
import { AppMarkerProvider } from './shared/app-marker-provider.interface';
import { GoogleCircle } from './shared/google-circle';
import { GoogleMarker } from './shared/google-marker';
import { GooglePolygon } from './shared/google-polygon';
import { GooglePolyline } from './shared/google-polyline';
import { ShapeFactory } from './shared/shape.factory';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { debounce } from 'src/app/shared/util/debounce.decorator';

@Component({
  selector: 'app-vanilla-google-map',
  templateUrl: './vanilla-google-map.component.html',
  styleUrls: ['./vanilla-google-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MarkerFactory, ShapeFactory]
})
export class VanillaGoogleMapComponent extends BaseMapImplComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('mapContainer', { static: true }) gmap: ElementRef;
  @ViewChild('search', { static: true }) search: ElementRef;
  @ViewChild('legend', { static: true }) legend: ElementRef;

  heatMap = new google.maps.visualization.HeatmapLayer();
  map: google.maps.Map;
  mapBounds = new google.maps.LatLngBounds();
  markerClusterer: MarkerClusterer;
  editableOverlays: google.maps.Marker[] | google.maps.OverlayView[] = [];
  trafficLayer = new google.maps.TrafficLayer();
  drawManager: google.maps.drawing.DrawingManager = new google.maps.drawing.DrawingManager();
  markersMap: Map<string, GoogleMarker> = new Map();
  polyLinesMap: Map<string, GooglePolyline> = new Map();
  circlesMap: Map<string, GoogleCircle> = new Map();
  polygonsMap: Map<string, GooglePolygon> = new Map();
  private isStreetViewMode?: boolean;
  previousBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
  private onVisibleMarkersSubject: BehaviorSubject<GoogleMarker[]> = new BehaviorSubject([]);
  private currentStyle: MapStyleWithOptions;
  private mapMarkerBoundsStack: BehaviorSubject<Point[]> = new BehaviorSubject(
    []
  );
  private mapCircleBoundsStack: BehaviorSubject<Point[]> = new BehaviorSubject(
    []
  );
  private mapPolygonBoundsStack: BehaviorSubject<Point[]> = new BehaviorSubject(
    []
  );


  constructor(
    private markerFactory: MarkerFactory,
    private shapeFactory: ShapeFactory,
    @Inject('mapService') private mapService: IMapService,
    private zone: NgZone,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private translationService: TranslationService,
    private appRef: ApplicationRef,
  ) {
    super();
    this.heatMap.set('radius', 20);
  }

  get calculatedHeight(): string {
    if (this.maximized) {
      if (window.innerWidth < 1440) {
        return this.height + 100 + 'px';
      } else {
        return this.height + 250 + 'px';
      }
    }

    return this.height + 'px' || '100%';
  }

  ngOnInit() {
    this.setupMap();
    this.legend.nativeElement.classList.add('hidden');
    this.subscription = this.onVisibleMarkersSubject
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return (
            curr.length === prev.length && curr.map((el) => el.id).every((id) => prev.map((el) => el.id).includes(id))
          );
        })
      )
      .subscribe((res) => {
        this.visibleMarkers.emit(res);
      });

    this.subscription = combineLatest([
      this.mapMarkerBoundsStack,
      this.mapCircleBoundsStack,
      this.mapPolygonBoundsStack,
    ])
      .pipe(
        debounceTime(500),
        filter(
          ([markers, circles, polygons]) =>
            !!markers.length || !!circles.length || !!polygons.length
        ),
        distinctUntilChanged(isEqual)
      )
      .subscribe(([markers, circles, polygons]) => {
        this.mapBounds = new google.maps.LatLngBounds();
        this.updateMapBounds([...markers, ...circles, ...polygons])
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.map) {
      this.OnChanges(changes);
    }
  }

  ngOnDestroy() {
    this.removeAllListeners();
  }

  createMapLegend(legendIcons: { [key: string]: string }) {
    this.legend.nativeElement.innerHTML = '';
    this.legend.nativeElement.classList.remove('hidden');
    if (this.map && this.legendIcons && Object.keys(legendIcons).length > 0) {
      Object.keys(legendIcons).forEach((key) => {
        const div = document.createElement('div');
        div.innerHTML = `<img class="legend-icon" src="${legendIcons[key]}"> <span class="icon-name">${key}</span>`;
        this.legend.nativeElement.appendChild(div);
      });
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.legend.nativeElement);
    }
  }

  getNativeMap(): google.maps.Map {
    return this.map;
  }

  maximize() {
    this.maximized = !this.maximized;
  }

  panTo(point: Point) {
    const center = new google.maps.LatLng(point.lat, point.lng);
    this.map.panTo(center);
  }

  setZoom(zoom: number) {
    const currentZoom = this.map.getZoom();
    if (zoom !== currentZoom) {
      this.map.setZoom(zoom);
    }
  }

  getZoom(): number {
    return this.map.getZoom();
  }

  setCenter(center: Point, zoom?: number) {
    this.map.panTo(new google.maps.LatLng(center.lat || 0, center.lng || 0));

    if (zoom) {
      this.setZoom(zoom);
    }
  }

  resetMapBounds() {
    this.mapBounds = new google.maps.LatLngBounds();
  }

  getMarkers(): google.maps.Marker[] {
    return this.map.get('markers') || [];
  }

  findPolylineById(id: string): Polyline {
    return this.polyLinesMap.get(id);
  }

  addPointToPolyline(polylineId: string, point: Point) {
    const polyline = this.polyLinesMap.get(polylineId);

    if (!polyline) {
      return;
    }

    const path = polyline.nativeShapeObject.getPath();
    path.push(new google.maps.LatLng(point.lat, point.lng));
    polyline.nativeShapeObject.setPath(path);
  }

  removePointFromPolyline(polylineId: string, point: Point) {
    const polyline = this.polyLinesMap.get(polylineId);

    if (!polyline) {
      return;
    }

    let path = polyline.nativeShapeObject.getPath().getArray();

    path = path.filter((latLng) => {
      return latLng.lat() !== point.lat && latLng.lng() !== point.lng;
    });

    polyline.nativeShapeObject.setPath(path);
  }

  addPolyline(line: GooglePolyline) {
    const existing = this.polyLinesMap.get(line.id);

    if (existing) {
      existing.nativeShapeObject.setMap(null);
    }

    const gPolyLine = new google.maps.Polyline({
      path: line.points,
      geodesic: true,
      strokeOpacity: line.strokeOpacity,
      strokeWeight: line.strokeWeight,
      strokeColor: line.strokeColor,
    });

    gPolyLine.setMap(this.map);

    line.nativeShapeObject = gPolyLine;
    this.polyLinesMap.set(line.id, line);
  }

  updatePolyline(id: string) {
    const line = this.polyLinesMap.get(id);
    this.updateOrCreatePolyline(line);
  }

  updateOrCreatePolyline(line: GooglePolyline) {
    const polyline = this.polyLinesMap.get(line.id);

    if (polyline) {
      const gPolyline = polyline.nativeShapeObject as google.maps.Polyline;
      gPolyline.setOptions({
        path: line.points.map((point) => {
          return new google.maps.LatLng(point.lat, point.lng);
        }),
      });
    } else {
      this.addPolyline(line);
    }
  }

  removePolyline(polylineId: string) {
    const line = this.polyLinesMap.get(polylineId);

    if (line) {
      line.nativeShapeObject.setMap(null);
    }

    this.polyLinesMap.delete(polylineId);
  }

  removeAllPolylines() {
    this.polyLinesMap.forEach((line) => {
      line.nativeShapeObject.setMap(null);
      this.polyLinesMap.delete(line.id);
    });
  }

  addMarker(marker: GoogleMarker, cluster: boolean = false) {
    // this.zone.runOutsideAngular(() => {
    if (!marker.id) {
      throw new Error('Marker must have an id');
    }

    const existingMarker = this.markersMap.get(marker.id);

    if (existingMarker) {
      existingMarker.nativeMarkerObject.setMap(null);
    }

    const gMarker = this.markerFactory.create(marker);
    marker.nativeMarkerObject = gMarker;
    this.markersMap.set(marker.id, marker);

    if (marker.extendMapBounds) {
      this.updateMapBounds({ lat: marker.lat, lng: marker.lng });
    }

    if (cluster) {
      this.markerClusterer.addMarker(gMarker);
    } else {
      gMarker.setMap(this.map);
    }
    // });
  }

  addMarkers(markers: GoogleMarker[], cluster: boolean = false) {
    const existingMarker = markers.reduce((filtered, m) => {
      const marker = this.markersMap.get(m.id);
      if (marker) {
        filtered.push(marker);
      }
      return filtered;
    }, []);

    if (existingMarker) {
      existingMarker.forEach((m) => m.nativeMarkerObject.setMap(null));
    }

    const gMarkers = markers.map((m) => {
      const gMarker = this.markerFactory.create(m);
      m.nativeMarkerObject = gMarker;
      this.markersMap.set(m.id, m);

      if (!cluster) {
        gMarker.setMap(this.map);
      }
      return gMarker;
    });

    if (cluster) {
      this.markerClusterer.addMarkers(gMarkers);
    }
    this.markersAdded.emit();
  }

  removeAllMarkers() {
    this.markersMap.forEach((marker) => {
      marker.nativeMarkerObject.setMap(null);
    });
    this.markerClusterer.clearMarkers();
  }

  removeMarker(markerId: string) {
    const marker = this.markersMap.get(markerId);

    if (marker) {
      marker.nativeMarkerObject.setMap(null);
      this.markersMap.delete(marker.id);
      this.markerClusterer.removeMarker(marker.nativeMarkerObject);
    }
  }

  renderMarkers(markers: GoogleMarker[]) {
    const toBeDeletedMarkers = new Map(this.markersMap);
    const newMarkers = new Map();

    markers.forEach((marker) => {
      if (toBeDeletedMarkers.has(marker.id)) {
        toBeDeletedMarkers.delete(marker.id);
        this.markerFactory.update(marker);
        return;
      }
      newMarkers.set(marker.id, marker);
    });

    const toBeDeletedNativeMarkers: google.maps.Marker[] = [];
    toBeDeletedMarkers.forEach((marker) => {
      marker.nativeMarkerObject.setMap(null);
      this.markersMap.delete(marker.id);
      toBeDeletedNativeMarkers.push(marker.nativeMarkerObject);
    });

    if (toBeDeletedNativeMarkers.length > 0) {
      this.markerClusterer.removeMarkers(toBeDeletedNativeMarkers);
    }
    this.zone.runOutsideAngular(() => {
      const gMarkers = [];
      newMarkers.forEach((marker) => {
        const gMarker = this.markerFactory.create(marker);
        marker.nativeMarkerObject = gMarker;
        this.markersMap.set(marker.id, marker);
        marker.click = () => this.markerClicked.next(marker.id);
        gMarkers.push(gMarker);
      });
      if (this.enableClustering) {
        this.markerClusterer.addMarkers(gMarkers);
      }
      this.renderBoundingBoxMarkers(this.map.getBounds());
      this.markersAdded.emit();

      const bounds = Array.from(this.markersMap.values())
        .filter((marker) => marker.extendMapBounds || this.mapOptions?.updateMapBounds)
        .map((marker) => {
          return { lat: marker.lat, lng: marker.lng };
        });
      this.mapMarkerBoundsStack.next(bounds || []);
    });
  }

  renderHeatMap(points: Point[]) {
    this.zone.runOutsideAngular(() => {
      this.heatMap.setData(points.map((point) => new google.maps.LatLng(point.lat, point.lng)));
      this.updateMapBounds(points);
    });
  }

  renderCircles(circles: Circle[]) {
    const toBeDeletedCircles = new Map(this.circlesMap);
    const newCircles = new Map();
    circles.forEach((circle) => {
      if (toBeDeletedCircles.has(circle.id)) {
        toBeDeletedCircles.delete(circle.id);
        return;
      }
      newCircles.set(circle.id, circle);
    });
    toBeDeletedCircles.forEach((circle) => {
      circle.nativeShapeObject.setMap(null);
      this.circlesMap.delete(circle.id);
    });

    this.zone.runOutsideAngular(() => {
      const gCircles = [];
      newCircles.forEach((circle) => {
        const gCircle = this.shapeFactory.createCircle(circle as GoogleCircle);
        circle.nativeShapeObject = gCircle;
        gCircle.setMap(this.map);
        gCircles.push(gCircle);
        this.circlesMap.set(circle.id, circle);
      });
      // Update map bounds
      const bounds = Array.from(this.circlesMap.values())
        .filter((circle) => circle.extendMapBounds)
        .reduce((accumulator, currentValue) => {
          const boundsLatLng = this.getCircleBounds(currentValue);
          if (boundsLatLng) {
            boundsLatLng
              .map((latLng) => {
                return { lat: latLng.lat(), lng: latLng.lng() };
              })
              .forEach((bound) => {
                accumulator.push(bound);
              });
          }
          return accumulator;
        }, []);
      this.mapCircleBoundsStack.next(bounds || []);
    });
  }

  renderPolygons(polygons: Polygon[]) {
    const toBeDeletedPolygons = new Map(this.polygonsMap);
    const newPolygons = new Map();
    polygons.forEach((polygon) => {
      if (toBeDeletedPolygons.has(polygon.id)) {
        toBeDeletedPolygons.delete(polygon.id);
        return;
      }
      newPolygons.set(polygon.id, polygon);
    });

    toBeDeletedPolygons.forEach((polygon) => {
      polygon.nativeShapeObject.setMap(null);
      this.polygonsMap.delete(polygon.id);
    });

    this.zone.runOutsideAngular(() => {
      const gPolygons = [];
      newPolygons.forEach((polygon) => {
        if (!polygon.show) {
          return;
        }

        const gPolygon = this.shapeFactory.createPolygon(polygon as GooglePolygon);
        polygon.nativeShapeObject = gPolygon;
        gPolygon.setMap(this.map);
        gPolygons.push(gPolygon);
        this.polygonsMap.set(polygon.id, polygon);
      });
      // Update map bounds
      const bounds = Array.from(this.polygonsMap.values())
        .filter((polygon) => polygon.extendMapBounds)
        .map((polygon) => {
          // Reset extendMapBounds so disabling the focus on circles after their first render.
          this.polygonsMap.set(polygon.id, { ...polygon, extendMapBounds: false })
          return polygon
        })
        .reduce((accumulator, polygon) => {
          polygon.points.forEach((point) => {
            if (typeof point.lat !== 'number' || typeof point.lng !== 'number') {
              // somehow point.lat and point.lng are functions.
              // It seems that queryService calculateCircularSector does this.
              // TODO investigate more
              return;
            }
            accumulator.push({ lat: point.lat, lng: point.lng });
          });
          return accumulator;
        }, []);
      this.mapPolygonBoundsStack.next(bounds || []);
    });
  }

  renderRectangles(rectangles: Rectangle[]) {
    this.zone.runOutsideAngular(() => {
      rectangles.forEach((rectangle) => {
        const gRectangle = new google.maps.Rectangle({
          strokeOpacity: rectangle.strokeOpacity,
          strokeWeight: rectangle.strokeWeight,
          strokeColor: rectangle.strokeColor,
          fillColor: rectangle.fillColor,
          fillOpacity: rectangle.fillOpacity,
          bounds: {
            north: rectangle.northEast.lat,
            south: rectangle.southWest.lat,
            east: rectangle.northEast.lng,
            west: rectangle.southWest.lng,
          },
        });
        gRectangle.setMap(this.map);
      });
    });
  }

  renderPolyLines(polyLines: Polyline[]) {
    this.zone.runOutsideAngular(() => {
      const existingPolyLines = this.map.get('polylines') || [];
      existingPolyLines.forEach((line) => line.setMap(null));

      const gPolyLines = [];

      polyLines.forEach((line) => {
        const gPolyLine = new google.maps.Polyline({
          path: line.points,
          geodesic: true,
          strokeOpacity: line.strokeOpacity,
          strokeWeight: line.strokeWeight,
          strokeColor: line.strokeColor,
        });
        gPolyLine.setMap(this.map);
        gPolyLines.push(gPolyLine);
      });

      this.map.set('polylines', gPolyLines);
    });
  }

  setTileSource(tileSource: TileSource) {
    console.error('Method-setTileSource not implemented.');
  }

  maybeEnableCorrectDrawMode(drawMode: DrawMode | DrawModeOptions) {
    let mode = drawMode;
    let options = null;

    if (drawMode instanceof DrawModeOptions) {
      mode = drawMode.mode;
      options = drawMode.options;
    }

    switch (mode) {
      case DrawMode.Marker:
        this.createEditableMarker(options);
        break;

      case DrawMode.None:
        this.clearEditableOverlays();
        break;

      case DrawMode.Rectangle:
        this.createEditableRectangle(options);
        break;

      case DrawMode.Circle:
        this.createEditableCircle(options);
        break;

      case DrawMode.DrawCircle:
        this.createDrawCircle(options);
        break;

      case DrawMode.DrawPolygon:
        this.createDrawPolygon(options);
        break;

      default:
        console.error('Method draw for this mode  not implemented.');
    }
  }

  toggleMarkerInfoWindow(show: boolean) {
    const markers = this.getMarkers();
    const mapBounds = this.map.getBounds();

    const markersInBounds = markers.filter((marker) => {
      return mapBounds.contains(marker.getPosition());
    });

    markersInBounds.forEach((marker) => {
      const isVisible = !!marker.getMap();
      if (isVisible) {
        (marker as any).openInfoWindow(show);
      }
    });
  }

  toggleClustering(enabled: boolean) {
    this.markerClusterer.setMap(enabled ? this.map : null);
  }

  toggleHeatMaps(enabled: boolean) {
    const existingHeatMaps = this.map.get('heatMaps') || [];
    existingHeatMaps.forEach((heatMap) => heatMap.setMap(enabled ? this.map : null));
  }

  toggleHistoryTimeline(enabled: boolean) {
    console.error('Method-toggleHistoryTimeline not implemented.');
  }

  toggleTraffic(enabled: boolean) {
    const map = enabled ? this.map : null;
    this.trafficLayer.setMap(map);
  }

  toggleNearbyLocations(enabled: boolean) {}

  renderButtons(buttons: Button[]) {
    this.addButtonControls(buttons);
  }

  renderFeature(feature: Feature) {
    // Remove all features
    this.map.data.forEach((item) => {
      this.map.data.remove(item);
    });

    if (isEmpty(feature)) {
      return;
    }

    this.map.data.addGeoJson(feature);

    if (feature.properties.boundingBox) {
      const mapBounds = new google.maps.LatLngBounds();
      Object.values(feature.properties.boundingBox).forEach((val) => {
        mapBounds.extend(new google.maps.LatLng(val['lat'], val['long']));
      });
      this.map.fitBounds(mapBounds);
    }
  }

  toggleSearching(enabled: boolean) {
    if (enabled) {
      this.addSearchPlaceBox();
      this.search.nativeElement.classList.remove('hidden');
    } else {
      this.search.nativeElement.classList.add('hidden');
    }
  }

  toggleFreeze(enabled: boolean) {
    this.map.setOptions({ draggable: !enabled });
  }

  renderCallLogClusteringData(data: CallLogFeatureData) {
    console.error('Method-renderCallLogClusteringData not implemented.');
  }

  addPointToHeatMap(point: Point) {
    if (!this.heatMap.getMap()) {
      this.heatMap.setMap(this.map);
    }
    const heatMapData = this.heatMap.getData();
    heatMapData.push(new google.maps.LatLng(point.lat, point.lng));
  }

  createHeatMap(heatMap: HeatMap): google.maps.visualization.HeatmapLayer {
    return new google.maps.visualization.HeatmapLayer({
      data: [],
      radius: heatMap.radius || 75,
      gradient: ['rgba(255, 0, 0, 0)', 'rgba(255, 255, 0, 0.8)', heatMap.color || 'rgba(255, 255, 0, 0.8)'],
    });
  }

  renderHeatMaps(heatMaps: HeatMap[]) {
    const existingHeaMaps = this.map.get('heatMaps') || [];
    existingHeaMaps.forEach((heatMap) => heatMap.setMap(null));
    const gHeatMaps = [];

    this.mapBounds = new google.maps.LatLngBounds();

    heatMaps.forEach((heatMap) => {
      if (!heatMap?.data) {
        return;
      }
      const data = heatMap.data.map((point) => new google.maps.LatLng(point.lat, point.lng));
      const gHeatMap = new google.maps.visualization.HeatmapLayer({
        data: data,
        radius: heatMap.radius || 20,
        map: this.heatmapEnabled ? this.map : null,
        gradient: ['rgba(255, 0, 0, 0)', 'rgba(255, 255, 0, 0.8)', heatMap.color],
      });

      gHeatMaps.push(gHeatMap);
      data.forEach((latLng) => this.mapBounds.extend(latLng));

      // this breaks current implementation (heat map visibility is based on zoom level)
      // this.map.fitBounds(this.mapBounds);
    });
    this.map.set('heatMaps', gHeatMaps);
  }

  renderBoundingBoxMarkers(mapBounds: google.maps.LatLngBounds): void {
    if (!mapBounds || this.enableClustering) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      const gMarkers = [];
      const markers = [];
      const mapZoom = this.map.getZoom();
      this.markersMap.forEach((marker) => {
        const isInBoundingBox = mapBounds.contains(marker.nativeMarkerObject.getPosition());
        const shouldBeRendered = mapZoom >= (marker.zoomLevelDisplay || 0) || marker.isSelected;
        if (isInBoundingBox && shouldBeRendered) {
          if (!marker.nativeMarkerObject.getMap()) {
            if (!this.enableClustering) {
              marker.nativeMarkerObject.setMap(this.map);
              this.delayedRenderMarker(marker.nativeMarkerObject, mapZoom);
            }
            gMarkers.push(marker.nativeMarkerObject);
          }
          markers.push(marker);
        } else {
          marker.nativeMarkerObject.setMap(null);
        }
      });
      this.map.set('markers', gMarkers);
      this.onVisibleMarkersSubject.next(markers);
    });
  }

  updateMapBounds(pointOrPoints: Point | Point[] | google.maps.LatLngBounds) {
    const points = normalizeToArray(pointOrPoints);
    if (!points.length) {
      return;
    }

    points.forEach((point) => this.mapBounds.extend(point as any));
    if(!this.mapOptions.preserveBounds) {
      this.map.fitBounds(this.mapBounds);
      this.preserveMinZoom();
    }
  }

  private clearEditableOverlays() {
    this.map.setOptions({ draggableCursor: null });
    this.editableOverlays.forEach((item) => item.setMap(null));
    this.drawManager.setDrawingMode(null);
  }

  private createEditableCircle(options: Partial<Circle> | any = {}) {
    const overlayFactory = new OverlayFactory(this.translationService, this.appRef);
    // this.drawManager.setDrawingMode(null);

    if (options) {
      options.radius = options.radiusMeters;
    }

    const defaultOptions = {
      strokeColor: '#292929',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#292929',
      fillOpacity: 0.3,
      map: this.map,
      draggable: true,
      editable: true,
      center: this.map.getCenter(),
      radius: 100,
    };

    Object.assign(defaultOptions, options);

    const existingEditableCircle = this.map.get('editableCircle');
    if (existingEditableCircle) {
      existingEditableCircle.setMap(null);
    }

    const editableCircle = overlayFactory.createEditableCircle({
      circleOptions: defaultOptions,
      onDrawFinished: () => {
        const center = editableCircle.getCenter();
        this.circleDrawn.emit(
          new Circle({
            radiusMeters: editableCircle.getRadius(),
            center: { lat: center.lat(), lng: center.lng() },
          })
        );
      },
    });

    this.map.fitBounds(editableCircle.getBounds());
    this.preserveMinZoom();

    this.map.set('editableCircle', editableCircle);
    editableCircle.setMap(this.map);
    this.editableOverlays.push(editableCircle as any);
  }

  private createEditableRectangle(options: any = null) {
    const rectangle = new google.maps.Rectangle({
      bounds: this.calculateBounds(this.map.getCenter(), new google.maps.Size(10000, 10000)),
      editable: true,
      draggable: true,
    });

    google.maps.event.addListener(rectangle, 'bounds_changed', (event) => {
      if (rectangle) {
        const bounds = rectangle.getBounds();
        this.rectangleDrawn.emit(
          new Rectangle({
            northEast: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
            southWest: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
          })
        );
      }
    });

    rectangle.setMap(this.map);
    this.editableOverlays.push(rectangle as any);
  }

  private setStreetViewListener(): void {
    const streetView = this.map.getStreetView();

    streetView.addListener('visible_changed', () => {
      const isStreetView = streetView.getVisible();

      if (isStreetView === this.isStreetViewMode) {
        return;
      }

      this.isStreetViewMode = isStreetView;
      this.streetViewMode.emit(isStreetView);
    });
  }

  private createEditableMarker(options: any = null) {
    const existingEditableMarker = this.map.get('editableMarker');
    const defaultLocation = this.map.getCenter();

    if (existingEditableMarker) {
      existingEditableMarker.setMap(null);
    }

    this.map.setOptions({ draggableCursor: 'crosshair' });
    const draggableMarker = new google.maps.Marker({
      draggable: true,
      map: this.map,
      icon: this.mapService.getDefaultMarkerIcon(),
      position: this.map.getCenter(),
    });

    this.markerDrawn.emit({ lat: defaultLocation.lat(), lng: defaultLocation.lng() });

    google.maps.event.addListener(this.map, 'click', (event) => {
      if (draggableMarker) {
        draggableMarker.setPosition(event.latLng);
        this.markerDrawn.emit({ lat: event.latLng.lat(), lng: event.latLng.lng() });
      }
    });

    this.map.set('editableMarker', draggableMarker);

    this.editableOverlays.push(draggableMarker as any);
  }

  private calculateBounds(center: google.maps.LatLng, size: google.maps.Size) {
    const n = google.maps.geometry.spherical.computeOffset(center, size.height / 2, 0).lat(),
      s = google.maps.geometry.spherical.computeOffset(center, size.height / 2, 180).lat(),
      e = google.maps.geometry.spherical.computeOffset(center, size.width / 2, 90).lng(),
      w = google.maps.geometry.spherical.computeOffset(center, size.width / 2, 270).lng();
    return new google.maps.LatLngBounds(new google.maps.LatLng(s, w), new google.maps.LatLng(n, e));
  }

  private setupMap() {
    if (this.showLoaderOnInit) {
      this.setLoading(true);
    }

    const defaultOptions: any = {
      gestureHandling: 'greedy',
    };

    if (this.isMobileDevice()) {
      this.mapOptions.disableDefaultUI = true;
    }

    this.zone.runOutsideAngular(() => {
      this.map = new google.maps.Map(this.gmap.nativeElement, {
        ...defaultOptions,
        ...(get(this.mapOptions, 'controls') || {}),
      });
    });
    this.heatMap.setMap(this.map);
    this.markerFactory.setMap(this.map);
    this.shapeFactory.setMap(this.map);

    this.setupMarkerClusterer();
    this.registerMapEvents();
    this.setStreetViewListener();

    if (!this.center) {
      this.setDefaultMapCenter();
    }
  }

  private addButtonControls(buttons: Button | Button[]) {
    buttons = normalizeToArray(buttons);
    // Clear all control positions
    const existingButtons = this.map.get('buttons') || [];
    existingButtons.forEach((button) => this.map.controls[button.position].clear());

    buttons.forEach((button) => {
      const factory = this.resolver.resolveComponentFactory(MapButtonComponent);
      const btnComponent: ComponentRef<MapButtonComponent> = factory.create(this.injector);
      btnComponent.instance.button = button;
      this.subscription = btnComponent.instance.onClick.subscribe((btnEvent) => {
        button.callback(btnEvent);
        btnComponent.changeDetectorRef.detectChanges();
      });
      this.map.controls[button.position].push(btnComponent.location.nativeElement);
      btnComponent.changeDetectorRef.detectChanges();
    });

    this.map.set('buttons', buttons);
  }

  setupMarkerClusterer(options?: MarkerClustererOptions) {
    this.zone.runOutsideAngular(() => {
      const clustererOptions: MarkerClustererOptions = {
        averageCenter: false,
        minimumClusterSize: 2,
        gridSize: 60,
        zoomOnClick: true,
        ignoreHidden: false,
        maxZoom: options ? options.maxZoom : 17,
        imagePath: '/assets/static/images/map/cluster/m',
        // !Remove for now to see if effects performance
        // calculator:this.clusterCalculationMethod.bind(this)
      };

      if (this.clustererStyles) {
        clustererOptions.styles = this.clustererStyles;
        delete clustererOptions.imagePath;
      }

      if (!this.markerClusterer) {
        if (!this.map) {
          console.warn("Configuring a clusterer before map is initialised. Please don't do this");
        }
        this.markerClusterer = new MarkerClusterer(this.map, [], clustererOptions);
        return;
      }

      this.markerClusterer.setMap(this.map);

      if (this.markerClusterer && options) {
        this.markerClusterer.setMaxZoom(options.maxZoom);
      }

      if (this.clustererStyles) {
        this.markerClusterer.setStyles(this.clustererStyles);
        this.markerClusterer.setImagePath(MarkerClusterer.IMAGE_PATH);
      }
    });
  }

  private preserveMinZoom() {
    const minZoom = this.mapOptions.minZoom;
    if (minZoom) {
      const zoom = this.map.getZoom();
      this.map.setZoom(zoom >= minZoom ? minZoom : zoom);
    }
  }

  @debounce(500)
  private onBoundsChanged(): void {
    const bounds = this.map.getBounds();
    if (!bounds || this.previousBounds.equals(bounds)) {
      return;
    }
    this.previousBounds = bounds;
    this.renderBoundingBoxMarkers(bounds);
    const mapTypeId = this.map.getMapTypeId();
    const zoom = this.map.getZoom();
    this.zoomChanged.emit(zoom);
    if (mapTypeId !== MapTypeId.SATELLITE && this.styledMapTypeCustomOptions) {
      const style: MapStyleWithOptions = Object.values(this.styledMapTypeCustomOptions).find((value) => {
        if (zoom >= value.minZoom && zoom <= value.maxZoom) {
          return value;
        }
      });
      if (this.currentStyle?.id === style?.id) {
        return;
      }
      this.currentStyle = style;
      this.map.setOptions({ styles: style.featureDensityStyles as google.maps.MapTypeStyle[] });
    }
  }
  private registerMapEvents() {
    this.zone.runOutsideAngular(() => {
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.MapReady();
        if (this.showLoaderOnInit) {
          this.setLoading(false, 400);
        }
        this.mapLoaded.emit(true);
      });

      google.maps.event.addListener(this.map, 'tilesloaded', () => {
        this.tilesLoadedChanges.emit();
      });
    });

    google.maps.event.addListener(this.markerClusterer, 'click', (cluster) => {
      this.clusterClicked.emit(cluster);
    });

    this.map.addListener('bounds_changed', () => {
      this.onBoundsChanged();
    });

    this.markerClusterer.addListener('clusteringend', () => {
      const mapZoom = this.map.getZoom();
      const maxZoomExceeded = mapZoom > this.markerClusterer.getMaxZoom();
      this.markerClusterer.getClusters().forEach((cluster) => {
        if (1 < cluster.getSize() && !maxZoomExceeded) {
          return;
        }

        cluster.getMarkers().forEach((marker)=>{
          this.delayedRenderMarker(marker, mapZoom)
        });
      });
      const notClusteredMarkers = Array.from(this.markersMap.values()).filter((marker) =>
        (marker.nativeMarkerObject as google.maps.Marker)?.getMap()
      );
      this.onVisibleMarkersSubject.next(notClusteredMarkers);
    });
  }

  private delayedRenderMarker(marker: google.maps.Marker, mapZoom: number) {
    const googleMarkerInstance = marker instanceof google.maps.Marker;

    if (!googleMarkerInstance) {
      return;
    }

    const appMarker = (marker as unknown as AppMarkerProvider).appMarker;

    if (appMarker instanceof IconMarker) {
      let iconSize = null;
      if (appMarker.iconWidth && appMarker.iconHeight) {
        iconSize = new google.maps.Size(appMarker.iconWidth, appMarker.iconHeight, 'px', 'px');
      }
      if (!marker.getVisible()) {
        marker.setIcon({
          url: appMarker.iconUrl,
          size: iconSize,
          scaledSize: iconSize,
          ...(appMarker.anchor ? { anchor: new google.maps.Point(appMarker.anchor.x, appMarker?.anchor.y) } : {}),
        });
      }
    }

    marker.setVisible(true);

    if (appMarker.isPopupWindowOpen && !(marker as any).infoWindow?.getMap()) {
      (marker as any).openInfoWindow(true);
      if(appMarker.isSelected) {
        (marker as any).infoWindow.setZIndex(appMarker.zIndex);
      }
    }
  }

  private setDefaultMapCenter() {
    this.mapService.getDefaultCenter().subscribe((point) => {
      if (point) {
        this.setCenter(point);
      }
    });
  }

  private addSearchPlaceBox() {
    const searchBox = new google.maps.places.SearchBox(this.search.nativeElement);
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].clear();
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.search.nativeElement);

    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry) {
          return;
        }

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      this.map.fitBounds(bounds);
    });
  }

  private getCircleBounds(circle: Circle, offset: number = 0): google.maps.LatLng[] {
    const center = new google.maps.LatLng(circle.center.lat, circle.center.lng);
    const extendedNe = google.maps.geometry.spherical.computeOffset(center, circle.radiusMeters + offset, 0);
    const extendedSw = google.maps.geometry.spherical.computeOffset(center, circle.radiusMeters + offset, 180);

    return [extendedNe, extendedSw];
  }

  private isMobileDevice(): boolean {
    return window.innerWidth < 768;
  }

  private createDrawPolygon(options: any = null) {
    this.drawManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      markerOptions: {
        icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
      },
    });
    this.drawManager.setMap(this.map);

    google.maps.event.addListener(this.drawManager, 'overlaycomplete', (event) => {
      event.overlay.setMap(null);
      const points = event.overlay
        .getPath()
        .getArray()
        .map((p) => {
          return { lng: p.lng(), lat: p.lat() };
        });
      this.stopDrawing.emit(new Polygon({ points: points }));
    });
  }

  private createDrawCircle(options: any = null) {
    this.drawManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.CIRCLE,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [google.maps.drawing.OverlayType.CIRCLE],
      },
      markerOptions: {
        icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
      },
      circleOptions: {
        fillColor: '#292929',
        fillOpacity: 0.3,
        strokeWeight: 3,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });
    this.drawManager.setMap(this.map);
    google.maps.event.addListener(this.drawManager, 'overlaycomplete', (event) => {
      event.overlay.setMap(null);
      this.stopDrawing.emit(
        new Circle({
          center: { lat: event.overlay.center.lat(), lng: event.overlay.center.lng() },
          radiusMeters: event.overlay.radius,
          extendMapBounds: false,
        })
      );
    });
  }

  private removeAllListeners() {
    google.maps.event.clearInstanceListeners(this.map);
  }

  setMaptTypeId(mapTypeId: MapTypeId) {
    this.map.setMapTypeId(mapTypeId);
  }

  setMaxZoomClusterDistance(distance: number) {
    this.markerClusterer.setMaxZoom(distance);
    this.resetMapBounds();
  }

  setStyledMapTypeCustomOptions(mapStyle: StyledMapTypeOptions) {
    const zoom = this.map.getZoom();
    const style: MapStyleWithOptions = Object.values(mapStyle).find((value) => {
      if (zoom >= value.minZoom && zoom <= value.maxZoom) {
        return value;
      }
    });
    this.currentStyle = style;
    this.map.setOptions({ styles: style.featureDensityStyles as google.maps.MapTypeStyle[] });
  }

  setMapFitBounds() {
    this.map.fitBounds(this.mapBounds);
  }
}
