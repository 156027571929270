import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RecommendationResponseBody } from '../models/socket-responses.interface';

@Component({
  selector: 'app-request-call-log-modal',
  templateUrl: './request-call-log-modal.component.html',
  styleUrls: ['./request-call-log-modal.component.scss']
})
export class RequestCallLogModalComponent {
  constructor(
    public modalRef: MatDialogRef<RequestCallLogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { telno: string; recommendations: RecommendationResponseBody }
  ) {}

  selectedDays: string[] = [];

  onImport(): void {
    this.modalRef.close({ selectedDays: this.selectedDays });
  }

  onDatePickerSelect(days: string[]) {
    this.selectedDays = days;
  }
}
