import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  NearbyLocationSearch,
  NearbyLocationsProfile,
  NearbyLocationsRequest,
  TrilaterationNearbyLocationRequest
} from '../shared/models/nearby-locations.model';

@Injectable({
  providedIn: 'root'
})
export class NearbyLocationsService {
  constructor(private http: HttpClient) {}
  private readonly fastAPIurl: string = environment.fastAPIUri;

  public TRILATERATION_DISTANCE_LIMIT = 3000;

  public getNearbyLocations(payloadRequest: NearbyLocationsRequest): Observable<any> {
    return this.http.post(`${this.fastAPIurl}/nearby-locations`, payloadRequest);
  }

  public applyTrilaterationOnNearbyLocation(payloadRequest: TrilaterationNearbyLocationRequest): Observable<any> {
    return this.http.post(`${this.fastAPIurl}/nearby-locations/trilateration`, payloadRequest);
  }

  public createEntityProfilesFromNearbyLocationProfile(profile: NearbyLocationsProfile, targetIds: string[]) {
    return this.http.post(`${this.fastAPIurl}/nearby-locations/create-entity-profiles`, {
      profile,
      target_ids: targetIds
    });
  }

  public nearbyLocationProfileWasTrilaterated(profile: NearbyLocationsProfile): boolean {
    return !!profile.latitude && !!profile.longitude;
  }

  public getNearbyLocationSearch(queryIds: string[]): Observable<NearbyLocationSearch[]> {
    let params = new HttpParams();
    queryIds.forEach(id => (params = params.append('geo_query_id', id)));
    return this.http
      .get(`${this.fastAPIurl}/nearby-locations/search`, { params })
      .pipe(map((data: { result: NearbyLocationSearch[] }) => data.result));
  }

  public setTargetIdsOnNearbyProfile(targetData: {
    targetIds: string[];
    queryArgTelnos: string[];
    profileUserId: string;
  }): void {
    this.http
      .post(`${this.fastAPIurl}/nearby-locations/add-target-ids`, {
        target_ids: targetData.targetIds,
        query_arg_telnos: targetData.queryArgTelnos,
        profile_user_id: targetData.profileUserId
      })
      .subscribe();
  }

  public getNearbyLocationProfileByTargetId(targetId: string): Observable<NearbyLocationsProfile[]> {
    let params = new HttpParams();
    params = params.append('target_id', targetId);
    return this.http
      .get(`${this.fastAPIurl}/nearby-locations/profiles`, { params })
      .pipe(map((data: { result: NearbyLocationsProfile[] }) => data.result));
  }
}
