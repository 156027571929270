import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [BaseModalComponent],
  exports: [MaterialModule, BaseModalComponent]
})
export class TrgCommonUiModule { }
