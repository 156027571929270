<button
  mat-button
  [ngClass]="stylingClasses"
  [class.active]="isActive"
  (click)="onAction($event)"
  [disabled]="isDisabled"
>
  <span class="btn-txt">{{ buttonText | translate }}</span>
  <ng-container
    *ngIf="isActive"
  >
    <span class="credits-separator">|</span>
    <span class="credits credit assistant-font">{{cost}}</span>
  </ng-container>
</button>
