import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseDialogComponent } from 'src/app/shared/classes/base-dialog.component';
import { EmailService } from './../../../services/email/email.service';

@Component({
  selector: 'app-webint-disabled-modal',
  templateUrl: './webint-disabled-modal.component.html',
  styleUrls: ['./webint-disabled-modal.component.scss'],
})
export class WebintDisabledModalComponent extends BaseDialogComponent {
  constructor(
    router: Router,
    private dialogRef: MatDialogRef<WebintDisabledModalComponent>,
    private emailService: EmailService,
    private translationService: TranslationService
  ) {
    super(dialogRef, router);
  }

  closeFeedback(): void {
    this.dialogRef.close(false);
  }

  contactCustomerSuccess(): void {
    this.emailService.customerSuccessRequest().subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: () => {
        this.showMessage(this.translationService.translate('Something went wrong. Please try again.'));
      },
    });
  }
}
