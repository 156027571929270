import { DataSource, EntityType } from 'datalayer/models//platform-models/enums';
import { SourceEntityProperties } from 'datalayer/models/platform-models/base/source.entity.properties';

export class Entity {
  public id?: any;
  public type?: EntityType;
  public source?: DataSource;
  public createdAt?: Date;
  public updatedAt?: Date;
  public sourceEntity?: SourceEntityProperties;
}
