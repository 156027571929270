import { TargetItem } from './../../../shared/models/target-item.model';
import { ProfilerService } from './profiler.service';
import { ProfilerOverviewHeaderMapOptions } from './../shared/models/profiler-dashboard-sections.model';
import { ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, QueryList, ViewContainerRef } from '@angular/core';
import { TargetSummaryInfo } from '@report-service-ts/data-models';
import { TargetSummarySectionIdentifier } from '@report-service-ts/data-models/dist/target-summary/enums/section-identifier.enum';
import { EntityType, QueryCommand } from '@trg-commons/data-models-ts';
import { CdrStatisticsDto, TopAssociatesExtended } from '@trg-commons/gio-data-models-ts';
import { DataSource, EntityRelationType } from 'datalayer/models/platform-models/enums';
import { Profile } from 'datalayer/models/social/profile';
import { RequestOptions } from 'datalayer/services/base/request-options.interface';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, delay, map, switchMap, take, distinctUntilChanged, catchError } from 'rxjs/operators';
import { AssociatesDataTransformerService } from 'src/app/modules/case-call-logs/components/case-cl-main/shared/services/associates-data-transformer.service';
import { ProfileService, ProfileService as SocialProfilesService } from 'src/app/modules/data-layer/services/social/profile/profile.service';
import { WordCloudComponent } from 'src/app/modules/text-analysis/components/text-analysis/components/word-cloud/word-cloud.component';
import { ReportService } from 'src/app/services/report/report.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { ReportAttributeDirective } from 'src/app/shared/directives/report-attribute.directive';
import { ReportSectionImage } from 'src/app/shared/models/report-section-image.model';
import { ClAssociate } from 'src/app/shared/modules/call-logs-shared/models/cl-associate';
import { CallLogsApiService } from 'src/app/shared/modules/call-logs-shared/services/call-logs-api.service';
import { LinkAnalysisComponent } from '../../link-analysis/components/link-analysis/link-analysis.component';
import { WordCloudItem } from '../../text-analysis/components/text-analysis/components/word-cloud/models/word-cloud.class';
import { TAWsCallResponse } from '../../text-analysis/models/socket-responses.interface';
import { MarkerCollection } from './../modules/profiler-map/models/marker-collection.model';
import { OverviewMapComponent } from '../modules/profiler-map/overview-map/overview-map.component';
import { Subject } from 'rxjs/internal/Subject';
import { of } from 'rxjs/internal/observable/of';
import { forkJoin } from 'rxjs';
import { PersonService } from 'src/app/modules/data-layer/services/person/person/person.service';
import { Person } from 'datalayer/models';
import moment from 'moment';
import { Platforms } from 'src/app/shared/models/radical-monitoring-options.model';
import { TargetActivityPatternComponent } from '../components/target-export/components/target-activity-pattern/target-activity-pattern.component';
import { TypeOfDob } from 'src/app/shared/models/date-of-birth.enum';
import { ServerPyWsService } from 'src/app/services/websocket/server-py-websocket.service';

const PlatformConvertor: {[key in Platforms]: DataSource} = {
  [Platforms.FACEBOOK]: DataSource.Facebook,
  [Platforms.INSTAGRAM]: DataSource.Instagram,
  [Platforms.LINKEDIN]: DataSource.LinkedIn,
  [Platforms.TIKTOK]: DataSource.Tiktok,
  [Platforms.TWITTER]: DataSource.Twitter,
  [Platforms.YOUTUBE]: DataSource.Youtube
};

@Injectable({
  providedIn: 'root'
})
export class TargetSummaryChildComponentService {
  tableActionBarfilter: {};
  locationSection: ReportAttributeDirective<TargetSummarySectionIdentifier>;
  correlationId: string;
  private locationsData$: Subject<MarkerCollection | undefined> = new Subject<MarkerCollection | undefined>();
  private locationsData = this.locationsData$.asObservable();
  private readonly defaultSources: DataSource[] = [
    DataSource.WhatsApp,
    DataSource.Telegram,
    DataSource.Skype,
    DataSource.Twitter,
    DataSource.Facebook,
    DataSource.Instagram,
    DataSource.LinkedIn,
    DataSource.Youtube,
    DataSource.Tiktok,
    DataSource.Truecaller
  ];

  platformDefaultOrder: {[key: string]: number} = {
    [DataSource.WhatsApp]: 1,
    [DataSource.Telegram]: 2,
    [DataSource.Truecaller]: 3,
    [DataSource.Skype]: 4,
    [DataSource.Facebook]: 5,
    [DataSource.Twitter]: 6,
    [DataSource.Instagram]: 7,
    [DataSource.LinkedIn]: 8,
    [DataSource.Tiktok]: 9,
  };

  target: TargetSummaryInfo;

  constructor(
    private serverPyWsService: ServerPyWsService,
    private reportService: ReportService,
    private apiService: CallLogsApiService,
    private socialProfilesService: SocialProfilesService,
    private associatesDataTransformerService: AssociatesDataTransformerService,
    private resolver: ComponentFactoryResolver,
    private profilerService: ProfilerService,
    private personService: PersonService,
    private profileService: ProfileService,
  ) {}

  generateCompleteTargetData(targetId: string): Observable<TargetSummaryInfo> {
    return new Observable<TargetSummaryInfo>((obs) => {
      forkJoin({
        targetData: this.profilerService.getTargetData(targetId),
        persons: this.getAllPersons(targetId),
        profiles: this.getAllSocialProfiles(targetId),
        family: this.generateFamilyRelations(targetId)
      }).subscribe((data) => {
        if (!data) {
          obs.next(undefined);
          obs.complete();
          return;
        }
         const platformsConvertorArray = data.targetData.socialProfiles.map((item) => {
           if (item.platform) {
            return item = PlatformConvertor[item.platform];
           }
         });
         const platforms = platformsConvertorArray.filter((value, index) => platformsConvertorArray.indexOf(value) === index).
         sort((a, b) => this.platformDefaultOrder[a] - this.platformDefaultOrder[b]);
         let targetDobStr: string | Date;
         let age: number;
         if (data?.targetData?.dateOfBirth) {
           targetDobStr = data.targetData.dateOfBirth.match(this.profileService.regexDobOnlyYear) ? data.targetData.dateOfBirth : new Date(data.targetData.dateOfBirth);
           age = moment().diff(targetDobStr, 'years', false);
         }
         this.target = new TargetSummaryInfo({
          alias: data.targetData.alias,
          names: data.targetData.names,
          image: data.targetData.photos[0] || '',
          age,
          platforms: platforms || [],
          nationalId: data.targetData.nationalId || '',
          msisdn: data.targetData.telnos || [],
          family: data.family ? data.family.map(item => item.name) : [],
        });
        if (data.persons && data.persons.length > 0) {
          this.target = this.addTargetDataFromPerson(data.persons);
        }
        if (data.profiles && data.profiles.length > 0) {
          this.target = this.addTargetNamesAndDateOfBirthFromProfiles(data.profiles);
        }
        obs.next(this.target);
        obs.complete();
      });
    });
  }

  getAllPersons(targetId: string): Observable<Person[]> {
    const personFilter: RequestOptions = {
      filters: {
        source: [DataSource.SocialSearch, DataSource.DBSearch],
        targetId: targetId,
        type: EntityType.Person,
        relationType: [EntityRelationType.Plain]
      }
    };
    return this.personService.getAll(personFilter).pipe(
      map((result: { [key: string]: Person }) => Object.values(result)),
    );
  }

  getAllSocialProfiles(targetId: string, extraDataSources?: DataSource[]): Observable<Profile[]> {
    const profileFilters: RequestOptions = {
      filters: {
        source: extraDataSources ? [...this.defaultSources, ...extraDataSources] : this.defaultSources,
        targetId: targetId,
        type: EntityType.Profile,
        relationType: [EntityRelationType.Plain]
      }
    };
    return this.socialProfilesService.getAll(profileFilters).pipe(
      map((result: { [key: string]: Profile }) => Object.values(result)),
    );
  }

  addTargetDataFromPerson(persons: Person[]): TargetSummaryInfo {
    persons.forEach(item => {
      if (item?.names?.length) {
        this.target.names.push(...item.names.filter(name => !this.target.names.includes(name)));
      }
      if (item?.telnos?.length) {
        this.target.msisdn.push(...item.telnos.filter(telno => !this.target.msisdn.includes(telno)));
      }
    });
    return this.target;
  }

  addTargetNamesAndDateOfBirthFromProfiles(profiles: Profile[]): TargetSummaryInfo {
    profiles.forEach((item: Profile) => {
      if (item.dob && !this.target.age) {
        const formatDate = this.profileService.formatDateOfBirth(item.dob);
        if (!formatDate.typeOfBirthday || formatDate.typeOfBirthday !== TypeOfDob.NO_YEAR) {
          this.target.age = moment().diff(new Date(item.dob), 'years', false);
        }
      }
      if (item.name && !this.target.names.includes(item.name)) {
        this.target.names.push(item.name);
      }
      if (item.source && !this.target.platforms.includes(item.source)) {
        this.target.platforms.push(item.source);
        this.target.platforms.sort((a, b) => this.platformDefaultOrder[a] - this.platformDefaultOrder[b]);
      }
    });
    return this.target;
  }

  generateCallTopAssociates(target: TargetItem): Observable<ClAssociate[]> {
    const request = new CdrStatisticsDto({
      msisdns: target.telnos,
      command: QueryCommand.TopAssociates
    });
    return this.apiService.createStatisticsRequest(request).pipe(
      map(reponse => reponse.body as TopAssociatesExtended),
      map(body =>
        body.associates
          .map(item => new ClAssociate(item))
          .map(item =>
            isEmpty(body.gioTargetMetadata)
              ? item
              : this.associatesDataTransformerService.metaDataPopulate(body.gioTargetMetadata, item)
          )
      )
    );
  }

  generateFamilyRelations(targetId: string): Observable<Profile[]> {
    const filters: {
      familyFilters: RequestOptions;
    } = {
      familyFilters: {
        filters: {
          source: [DataSource.Facebook],
          targetId: targetId,
          type: EntityType.Profile,
          relationType: [EntityRelationType.Family],
          limit: 50
        }
      }
    };
    return this.socialProfilesService
      .getAll(filters.familyFilters)
      .pipe(map((result: { [key: string]: Profile }) => Object.values(result)));
  }

  setMarkerCollection(markerCollection: MarkerCollection) {
    this.locationsData$.next(markerCollection);
  }

  generateLocationData(): Observable<MarkerCollection> {
    return this.locationsData;
  }

  generateLinkAnalysis(
    targetId: string,
    container: ViewContainerRef
  ): Observable<ReportSectionImage<TargetSummarySectionIdentifier>[]> {
    return new Observable<ReportSectionImage<TargetSummarySectionIdentifier>[]>(obs => {
      const factory = this.resolver.resolveComponentFactory(LinkAnalysisComponent);
      const componentRef = container.createComponent(factory);
      if (!componentRef) {
        obs.next(undefined);
        obs.complete();
        return;
      }
      componentRef.instance.targetId = targetId;
      componentRef.instance.caseId = targetId;
      componentRef.instance.chartHeight = 30;
      componentRef.instance.graphStateSaved = true;
      componentRef.instance.graphImageLoaded$
        .pipe(
          delay(1000),
          distinctUntilChanged(),
          switchMap(() => componentRef.instance.generateGraphImage().pipe(
            switchMap(blob => this.blobToBase64(blob))
          )
          )
        )
        .subscribe((imageBase64: string) => {
          if (!imageBase64) {
            obs.next(undefined);
            obs.complete();
            return;
          }
          const linkAnalysisSection: ReportSectionImage<TargetSummarySectionIdentifier>[] = [
            {
              title: 'Link Analysis',
              image: imageBase64,
              sectionIdentifier: TargetSummarySectionIdentifier.linkAnalysis
            }
          ];
          obs.next(linkAnalysisSection);
          obs.complete();
        });
    });
  }

  private blobToBase64(blob: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  generateLocations(targetId: string, locationContainer: ViewContainerRef):
    Observable<ReportSectionImage<TargetSummarySectionIdentifier>[]> {
    return this.generateMapOverviewComponent(targetId, locationContainer).pipe(
      switchMap(response => {
        if (!response) {
          return of(undefined);
        }
        const sectionImage = this.reportService.transformSectionsToImages([response]);
        return sectionImage;
      })
    );
  }

  generateMapOverviewComponent(targetId: string, locationContainer: ViewContainerRef):
    Observable<ReportAttributeDirective<TargetSummarySectionIdentifier>> {
    return new Observable<ReportAttributeDirective<TargetSummarySectionIdentifier>>(obs => {
      const factory = this.resolver.resolveComponentFactory(OverviewMapComponent);
      const componentRef = locationContainer.createComponent(factory);
      if (!componentRef) {
        obs.next(undefined);
        obs.complete();
        return;
      }
      componentRef.instance.targetId = targetId;
      componentRef.instance.placesOfInterestVisibility = false;
      componentRef.instance.selectedSection = ProfilerOverviewHeaderMapOptions.HEATMAPLASTSEEN;
      componentRef.instance.showTimeline = false;

      componentRef.instance.mapLoaded.pipe(
        switchMap(() => this.workAroundForMapIncompleteRenderIssue(componentRef))).subscribe(() => {
          const lastSeen = componentRef.instance.markerCollection.all.value;
          if (lastSeen && lastSeen.length === 0) {
            obs.next(undefined);
            obs.complete();
          }
          const locationImage = componentRef.instance.returnElementsQueryList();
          const locationImageSections: ReportAttributeDirective<TargetSummarySectionIdentifier> = locationImage[
            '_results'
          ].find(item => item.sectionIdentifier === TargetSummarySectionIdentifier.locations);
          if (!locationImageSections) {
            obs.next(undefined);
            obs.complete();
            return;
          }
          this.setMarkerCollection(componentRef.instance.markerCollection);
          obs.next(locationImageSections);
          obs.complete();
        });
    });
  }

  private workAroundForMapIncompleteRenderIssue(componentRef: ComponentRef<OverviewMapComponent>): Observable<void> {
    const parentWrapper = ((componentRef.hostView as EmbeddedViewRef<OverviewMapComponent>).rootNodes[0] as HTMLElement).parentElement;
    parentWrapper.style.width = '99%';
    parentWrapper.style.width = '100%';
    return new Observable<void>((obs) => {
      setTimeout(() => {
        obs.next();
        obs.complete();
      }, 500);
    });
  }

  generateTargetTotalActivityPattern(
    target: TargetItem,
    container: ViewContainerRef,
    sections: QueryList<ReportAttributeDirective<TargetSummarySectionIdentifier>>
  ): Observable<ReportSectionImage<TargetSummarySectionIdentifier>[] | undefined> {
    return this.generateTargetActivityPatternComponent(target, container, sections).pipe(
      switchMap(response => {
        if (!response) {
          return of(undefined);
        }
        const sectionImage = this.reportService.transformSectionsToImages([response]);
        return sectionImage;
      })
    );
  }

  generateTargetActivityPatternComponent(
    target: TargetItem,
    container: ViewContainerRef,
    sections: QueryList<ReportAttributeDirective<TargetSummarySectionIdentifier>>):
  Observable<ReportAttributeDirective<TargetSummarySectionIdentifier> | undefined>  {
    const activityPatternSections: ReportAttributeDirective<TargetSummarySectionIdentifier> = sections.toArray()
    .find(item => item.sectionIdentifier === TargetSummarySectionIdentifier.activityPatterns);
    if (!activityPatternSections) {
      console.error('Activity pattern was requested in the PDF but it is in DOM');
      return of(undefined);
    }
    return new Observable<ReportAttributeDirective<TargetSummarySectionIdentifier> | undefined>(obs => {
      const factory = this.resolver.resolveComponentFactory(TargetActivityPatternComponent);
      const componentRef = container.createComponent(factory);
      if (!componentRef) {
        obs.next(undefined);
        obs.complete();
        return;
      }
      componentRef.instance.target = target;
      componentRef.instance.activityPatternComplete.subscribe(() => {
        obs.next(activityPatternSections);
        obs.complete();
      }, () => {
        obs.next(undefined);
        obs.complete();
      });
    });
  }

  generateCloudWordImage(
    targetId: string,
    container: ViewContainerRef,
    sections: QueryList<ReportAttributeDirective<TargetSummarySectionIdentifier>>
  ): Observable<ReportSectionImage<TargetSummarySectionIdentifier>[]> {
    return this.generateCloudWordComponent(targetId, container, sections).pipe(
      switchMap(response => {
        if (!response) {
          return of(undefined);
        }
        const sectionImage = this.reportService.transformSectionsToImages([response]);
        return sectionImage;
      })
    );
  }

  private generateCloudWordComponent(
    targetId: string,
    container: ViewContainerRef,
    sections: QueryList<ReportAttributeDirective<TargetSummarySectionIdentifier>>
  ): Observable<ReportAttributeDirective<TargetSummarySectionIdentifier>> {
    return new Observable<ReportAttributeDirective<TargetSummarySectionIdentifier>>(obs => {
      this.generateTargetWords(targetId).pipe(take(1)).subscribe(
        (response: WordCloudItem[]) => {
          if (!response) {
            obs.next(undefined);
            obs.complete();
            return;
          }
          const factory = this.resolver.resolveComponentFactory(WordCloudComponent);
          const componentRef = container.createComponent(factory);
          if (!componentRef) {
            obs.next(undefined);
            obs.complete();
            return;
          }
          componentRef.instance.words = response;
          const cloudWordsSections: ReportAttributeDirective<TargetSummarySectionIdentifier> = sections[
            '_results'
          ].find(item => item.sectionIdentifier === TargetSummarySectionIdentifier.socialWordCloud);
          if (!cloudWordsSections) {
            obs.next(undefined);
            obs.complete();
            return;
          }
          componentRef.instance.wordRenderComplete$.pipe(debounceTime(2000)).subscribe(() => {
            obs.next(cloudWordsSections);
            obs.complete();
          });
        }, () => {
          obs.next(undefined);
          obs.complete();
        }
      );
    });
  }

  private generateTargetWords(targetId: string): Observable<WordCloudItem[]> {
    return this.serverPyWsService
      .call({
        subject: MessageSubject.TextAnalysisNamedEntity,
        body: {
          targetId: targetId
        }
      })
      .pipe(
        catchError(() => {
          return of(undefined);
        }),
        map((data: TAWsCallResponse<Partial<WordCloudItem>[]>) => {
          if (!data) {
            return undefined;
          }
          if (!data.body.payload) {
            return undefined;
          }
          return data.body.payload.map(p => {
            return new WordCloudItem({
              count: p.count,
              text: p.text,
              category: p.category,
              subCategory: p.subCategory
            });
          });
        })
      );
  }
}
