import { BaseDialogComponent } from 'src/app/shared/classes/base-dialog.component';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent extends BaseDialogComponent implements OnInit {

  @Input()
  showFooter: boolean = true;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close();
  }

  constructor(matDialogRef: MatDialogRef<any>,
    router: Router) {
    super(matDialogRef, router);
  }

  ngOnInit() {
  }

}
