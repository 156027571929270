<div class="row">
  <div class="col-7 left">
    <div class="row">
      <div class="selections col-12 flex-vert-center">
        <button
          (click)="togglePredefinedSelection(SelectedTimeType.allWeek)"
          [ngClass]="{ 'selected-btn': selected.predefined.allWeek }"
          class="btn mr-5 assistant-font-family"
          mat-flat-button
        >
          {{ 'All week' | translate }}
        </button>
        <button
          (click)="togglePredefinedSelection(SelectedTimeType.weekdays)"
          [ngClass]="{ 'selected-btn': selected.predefined.weekdays }"
          class="btn mr-5 assistant-font-family"
          mat-flat-button
        >
          {{ 'Weekdays' | translate }}
        </button>
        <button
          (click)="togglePredefinedSelection(SelectedTimeType.weekends)"
          [ngClass]="{ 'selected-btn': selected.predefined.weekends }"
          class="btn mr-5 assistant-font-family"
          mat-flat-button
        >
          {{ 'Weekends' | translate }}
        </button>

        <mat-select
          [(ngModel)]="selected.days"
          class="btn days-select"
          multiple
          placeholder="{{ 'Select days' | translate }}"
          (selectionChange)="daysSelection($event.value)"
        >
          <mat-option *ngFor="let day of days" [value]="day">{{
            day | lowercase | translate | titlecase
          }}</mat-option>
        </mat-select>

        <app-spinner-loader
          class="cl-main-loader"
          *ngIf="loading"
          [loading]="true"
          [diameter]="30"
        ></app-spinner-loader>
      </div>
    </div>

    <div class="time row">
      <div class="col-12">
        <nouislider [config]="sliderConfig" (update)="onTimeSliderChange($event)"></nouislider>
      </div>
    </div>
  </div>
  <div class="col-5 pl-15 assistant-font-family">
    <span class="font-semi-bold fs-16 d-block mb-5">{{ 'Presets' | translate }}</span>
    <span class="fs-14 d-block mb-5">{{ 'Show predicted' | translate }}:</span>
    <button
      class="btn mr-5 assistant-font-family"
      (click)="togglePredefinedSelection(SelectedTimeType.sleeping)"
      [ngClass]="{ 'selected-btn': selected.predefined.sleeping }"
      mat-flat-button
    >
      {{ 'Sleeping time' | translate }}
    </button>
    <button
      class="btn assistant-font-family"
      (click)="togglePredefinedSelection(SelectedTimeType.working)"
      [ngClass]="{ 'selected-btn': selected.predefined.working }"
      mat-flat-button
    >
      {{ 'Working time' | translate }}
    </button>
  </div>
</div>
