import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, share, distinctUntilChanged } from 'rxjs/operators';
import io from '@trg/socket.io-client';
import { AuthService } from '../authentication/auth.service';
import { environment } from 'src/environments/environment';
import { AuthState } from '../authentication/auth-state.enum';

@Injectable({
  providedIn: 'root',
})
export class WebsocketManagerService {
  public serverTsWs$: BehaviorSubject<SocketIOClient.Socket | undefined> = new BehaviorSubject<
    SocketIOClient.Socket | undefined
  >(undefined);

  constructor(private authSessionService: AuthService) {
    this.listenForLoggedInEvent();
  }

  public getServerTsConnection(): Observable<SocketIOClient.Socket> {
    return this.serverTsWs$.asObservable().pipe(
      filter((socket) => {
        return undefined !== socket;
      }),
      share()
    );
  }

  private listenForLoggedInEvent(): void {
    this.authSessionService.authState$.pipe(distinctUntilChanged()).subscribe((authState: AuthState) => {
      if (authState === AuthState.authenticated) {
        this.maybeDisconnectServerTs();
        this.connectToServerTs(this.authSessionService.token.toString());
      } else {
        this.maybeDisconnectServerTs();
      }
    });
  }

  private maybeDisconnectServerTs(): void {
    if (undefined === this.serverTsWs$.value) {
      return;
    }

    this.serverTsWs$.value.disconnect();
    this.serverTsWs$.next(undefined);
  }

  private connectToServerTs(token: string): void {
    const socket = io(environment.proxyAPIUWebSocketUri, {
      path: '/proxy/socket.io',
      query: { token },
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      this.serverTsWs$.next(socket);
    });
  }
}
