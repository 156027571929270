<button
        class="icon-text-button"
        [ngClass]="[buttonSize, isLoading ? 'loading' : '', !buttonText ? 'icon-only' : '']"
        [disabled]="isLoading || isDisabled"
        (click)="onClick()"
        [attr.aria-label]="buttonText || 'Icon Button'"
>
    <div class="content">
        <ng-container *ngIf="!isLoading; else loadingSpinner">
            <fa-icon [icon]="buttonIcon" [class.rotate]="rotate"></fa-icon>
        </ng-container>
        <ng-template #loadingSpinner>
            <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
        </ng-template>
        <span *ngIf="buttonText">{{ buttonText | translate }}</span>
    </div>
</button>
