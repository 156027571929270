import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from 'src/app/base/base.component';
import { TranslationService } from 'src/app/services/translation/translation.service';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})

export class EmailSettingsComponent extends BaseComponent {
  emailPattern = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$';

  inputs = [
    { name: 'host' },
    { name: 'port', value: '587' },
    { name: 'email' },
    { name: 'username' },
    { name: 'password', type: 'password' },
  ];

  labels = [
    { name: 'SMTPHost' },
    { name: 'SMTPPort' },
    { name: this.translationService.translate('From') + this.translationService.translate('Email') },
    { name: 'SMTPUsername'},
    { name: 'SMTPPassword' },
  ];

  emailSettings = new FormGroup({
    'host': new FormControl(),
    'port': new FormControl('587'),
    'email': new FormControl(),
    'username': new FormControl(),
    'password': new FormControl(),
    'startTls': new FormControl(true),
  });

  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private translationService: TranslationService,
    public snackBar: MatSnackBar
  ) {
    super();
  }

  submitForm() {
    if (this.emailSettings.valid) {
      // TODO: submit email Settings
    }
  }

  // TODO: - Remove this and extend from Base
  showMessage(msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition,
  ) {
    this.snackBar.open(
      msg,
      okText, {
        duration: preferredDuration || 3000,
        horizontalPosition: preferredHorizontalPosition,
        verticalPosition: preferredVerticalPosition,
        panelClass: ['custom-snackbar']
      }
    );
  }
}
