import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { format } from 'date-fns';
import { NouisliderComponent } from 'ng2-nouislider';
import { BarModel } from 'src/app/modules/profiler/shared/minimalist-chart/BarModel';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { Query } from 'src/app/shared/models/query-item.model';

export type PlayerState = 'stopped' | 'playing' | 'paused' | 'finished' | 'replay';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {
  @ViewChild('timelineSlider') public timelineSlider: NouisliderComponent;
  @Input() locations: Query[];
  @Input() showPlaybackControls: boolean = false;
  @Input() callLogFeatureCollection;
  @Input() updateDatepickerLabel: boolean;
  @Input() callLogSection: boolean;
  @Input() config: { min: number; max: number };
  @Input() showLatestCount: number; // set the timeline range to show the last x items

  @Output() initialCallLogDateRange = new EventEmitter<number[]>();
  @Output() filters = new EventEmitter<Number[]>();

  @Input() playerState: PlayerState = 'stopped';
  @Output() playerStateChange = new EventEmitter<PlayerState>();

  bars: BarModel[];

  show = false;
  sliderConfig = {
    behaviour: 'tap-drag',
    connect: true,
    tooltips: [
      {
        to: (index: number) => this.getFormat(index)
      },
      {
        to: (index: number) => this.getFormat(index)
      }
    ],
    start: [null, null],
    range: {
      min: null,
      max: null
    },
    step: 1
  };

  private barsLimit = 120;

  constructor(private userBehaviorService: UserBehaviorService) {}

  ngOnInit() {
    this.userBehaviorService.userBehavior('analytics_call_log_timeline').subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.callLogSection) {
      this.buildGraph();
    }

    if (changes.config) {
      if (!changes.config.currentValue) {
        this.show = false;
        return;
      }

      this.sliderConfig.range = {
        min: this.config.min,
        max: this.config.max
      };

      this.sliderConfig.start = [this.config.min, this.config.max];

      this.show = true;
    }

    if (changes?.locations?.currentValue) {
      const targetLocations = changes.locations.currentValue;
      if (targetLocations.length) {
        // convert to timestamps
        let startIndex = 0;
        const endIndex = targetLocations.length - 1;

        let startLocation = this.getDate(targetLocations[0]).getTime();
        let endLocation = this.getDate(targetLocations[targetLocations.length - 1]).getTime();

        // just to be safe in case the data are sorted the other way
        if (startLocation > endLocation) {
          const temp = startLocation;
          startLocation = endLocation;
          endLocation = temp;
        }

        if (startLocation === endLocation) {
          return;
        }

        this.sliderConfig.range = {
          min: startIndex,
          max: endIndex
        };

        if (this.showLatestCount) {
          startIndex = targetLocations.length - this.showLatestCount;

          if (startIndex < 0) {
            startIndex = 0;
          }

          startLocation = this.getDate(targetLocations[startIndex]).getTime();

          this.sliderConfig.start = [startIndex, endIndex];
          if (this.show) {
            this.timelineSlider.slider.updateOptions({
              start: [startIndex, endIndex]
            });
          }

          const [from, to] = this.getTimestamps([startIndex, endIndex]);

          this.filters.emit([from, to]);
        } else {
          this.sliderConfig.start = [startIndex, endIndex];
        }

        this.show = true;
      }
    }

    if (changes.callLogFeatureCollection && changes.callLogFeatureCollection.currentValue) {
      const featureCollection = changes.callLogFeatureCollection.currentValue;
      if (featureCollection.features || featureCollection.features.length) {
        featureCollection.features.sort((a, b) => {
          return new Date(b.properties.date).getTime() - new Date(a.properties.date).getTime();
        });
        const startLocationDate = new Date(
          featureCollection.features[featureCollection.features.length - 1].properties.date
        ).getTime();
        const endLocationDate = new Date(featureCollection.features[0].properties.date).getTime();
        this.sliderConfig.range = {
          min: startLocationDate,
          max: endLocationDate
        };
        this.sliderConfig.start = [startLocationDate, endLocationDate];
        this.show = true;
        if (this.timelineSlider) {
          this.timelineSlider.slider.updateOptions({
            start: [startLocationDate, endLocationDate]
          });
        }
        if (this.updateDatepickerLabel) {
          this.initialCallLogDateRange.emit([startLocationDate, endLocationDate]);
        }
      }
    }
  }

  playerAction(state: PlayerState) {
    this.playerState = state;
    this.playerStateChange.emit(state);
  }

  buildGraph() {
    const data = this.callLogFeatureCollection.features.sort((a, b) => {
      return new Date(a.properties.date).getTime() - new Date(b.properties.date).getTime();
    });
    const start = data[0].properties.date;
    const end = data[data.length - 1].properties.date;
    this.buildGraphWrapper(start, end);
  }

  getDatesBetween(startDate, endDate, type) {
    const dates = [];
    let currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      startDate.getHours(),
      startDate.getMinutes()
    );

    while (currentDate <= endDate) {
      dates.push(currentDate);
      if (type === 'HH') {
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours() + 1
        );
      } else {
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours(),
          currentDate.getMinutes() + 1
        );
      }
    }
    if (dates.length < this.barsLimit) {
      return this.getDatesBetween(startDate, endDate, 'MM');
    } else {
      return dates;
    }
  }

  buildGraphWrapper(start, end) {
    const datesArray = this.getDatesBetween(new Date(start), new Date(end), 'HH');
    const intervalChunkNum = Math.round(datesArray.length / this.barsLimit);
    const result: { start: any; end: any; howMuch: number }[] = [];

    for (let i = 0; i < datesArray.length; i += intervalChunkNum) {
      const intervalChunk = datesArray.slice(i, i + intervalChunkNum);
      const intelChunkObject = intervalChunk.reduce((resultObj, elem, index, array) => {
        resultObj = {
          start: array[0],
          end: array[array.length - 1],
          howMuch: 0
        };
        return resultObj;
      }, {});
      result.push(intelChunkObject);
    }

    if (result.length > this.barsLimit) {
      const howMany = result.length - this.barsLimit;
      const elements = result.splice(this.barsLimit, howMany);
      result[result.length - 1].end = elements[elements.length - 1].end;
    }

    this.callLogFeatureCollection.features.forEach(featuresElement => {
      result.forEach(resultElement => {
        const featuresElementTime = new Date(featuresElement.properties.date).getTime();
        const startResult = new Date(resultElement.start).getTime();
        const endResult = new Date(resultElement.end).getTime();
        if (featuresElementTime >= startResult && featuresElementTime <= endResult) {
          resultElement.howMuch += 1;
        }
      });
    });

    this.bars = result.map(elem => {
      return {
        value: elem.howMuch,
        start: elem.start,
        end: elem.end,
        cssClass: 'active'
      };
    });
  }

  onSliderChange(range: number[]) {
    const [from, to] = this.getTimestamps(range);

    this.filters.emit([from, to]);

    if (this.callLogSection) {
      this.bars.forEach(elem => {
        const startTime = new Date(elem.start).getTime();
        const endTime = new Date(elem.end).getTime();
        if (startTime >= from && endTime <= to) {
          elem.cssClass = 'active';
        } else {
          elem.cssClass = 'disable';
        }
      });
    }
  }

  private getTimestamps([startIndex, endIndex]: number[]): number[] {
    try {
      const fromLocation = this.locations[this.normalizeIndex(startIndex)];

      const from = this.getDate(fromLocation).getTime();
      const toLocation = this.locations[this.normalizeIndex(endIndex)];
      const to = this.getDate(toLocation).getTime();
      return [from, to];
    } catch(e){
      return [startIndex, endIndex]
    }
  }

  private normalizeIndex(index: number): number {
    return Math.round(index);
  }

  private getFormat(index: number): string {
    try {
      const normalizedIndex = this.normalizeIndex(index);
      const locationDate = this.getDate(this.locations[normalizedIndex]);
      return format(locationDate, 'dd MMM yyyy HH:mm:ss');
    } catch(e){
      return format(index, 'dd MMM yyyy HH:mm:ss');
    }
  }

  private getDate(item: Partial<Query & { date: Date }>): Date {
   if (item?.locationReceivedAt) {
      return new Date(item.locationReceivedAt);
    } else if (item?.date) {
      return item.date;
    }
  }
}
