import { BaseService, EmptyCacheService } from 'datalayer/services/base';
import { Place } from 'datalayer/models/social/place';
import { PlaceApiService } from 'src/app/modules/data-layer/services/social/place/place-api-service';
import { Injectable } from '@angular/core';
import { PlaceDTO } from 'src/app/modules/data-layer/services/social/place/place-dto';

@Injectable({
  providedIn: 'root',
})
export class PlaceService extends BaseService<Place, PlaceDTO, PlaceApiService, EmptyCacheService<Place>> {
  constructor(apiService: PlaceApiService, localCacheService: EmptyCacheService<Place>) {
    super(apiService, localCacheService);
  }
}
