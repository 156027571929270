import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { camelCase, groupBy, isBoolean } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { BillingService } from 'src/app/services/billing/billing.service';
import { User } from 'src/app/services/user/user.model';
import {
  BalanceType,
  BillingActions,
  BillingActionType,
  BillingPlan,
  TenantBillingDetails
} from 'src/app/shared/models/billing-action.model';
import {
  AvailablePool,
  CreditPools,
  PoolFeatures,
  PoolFeaturesLabelsMap
} from 'src/app/shared/models/credit-pools.model';
import { AuthService } from '../authentication/auth.service';
import { BaseService } from '../base.service';
import { LedgerService } from '../ledger/ledger.service';
import { TranslationService } from '../translation/translation.service';

enum UserBalanceStateMessages {
  CREDITS_EXPIRED = 'Credits are expired',
  NO_CREDITS = 'No credits',
}

enum UserBalanceState {
  CREDITS_EXPIRED = 'CREDITS_EXPIRED',
  NO_CREDITS = 'NO_CREDITS',
  EXISTENT_CREDITS = 'EXISTENT_CREDITS',
}

@Injectable({
  providedIn: 'root',
})
export class UserBillingService extends BaseService {

  private readonly DIGITS_AFTER_DECIMAL_POINT: number = 1;

  constructor(
    private billingService: BillingService,
    private ledgerService: LedgerService,
    private translationService: TranslationService,
    private authService: AuthService,
    protected override router: Router,
    protected override snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.authService.isAuthenticated.pipe(switchMap(() => this.getTenantDetails())).subscribe();
  }

  private tenantUnassignCredits$: BehaviorSubject<number | CreditPools> = new BehaviorSubject<number | CreditPools>(
    null
  );
  private tenantCurrentBalance$: BehaviorSubject<number | CreditPools> = new BehaviorSubject<number | CreditPools>(
    null
  );
  private tenantBalanceType$: BehaviorSubject<BalanceType> = new BehaviorSubject<BalanceType>(null);
  private isTenantLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private isTenantExpired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private getValidPools(creditPools: CreditPools): string[] {
    return Object.keys(creditPools)
      .filter((pool) => pool !== 'total');
  }

  private isTenantBalanceZero(tenantBalance: TenantBillingDetails): boolean {
    return this.isDistributedBalance()
      ? (tenantBalance.balance as CreditPools).total === 0
      : (tenantBalance.balance as number) === 0;
  }

  public getTenantDetails(): Observable<TenantBillingDetails> {
    return this.billingService.getTenantBillingDetails().pipe(
      tap((tenantBalance: TenantBillingDetails) => {
        this.tenantUnassignCredits$.next(tenantBalance.unassignUserCredits);
        this.tenantBalanceType$.next(tenantBalance.balanceType);
        this.tenantCurrentBalance$.next(tenantBalance.balance);
        this.isTenantLoaded$.next(true);
        this.isTenantExpired$.next(this.isTenantBalanceZero(tenantBalance));
      })
    );
  }

  public isTenantExpired(): Observable<boolean> {
    return this.isTenantExpired$
      .asObservable()
      .pipe(filter((isExpired) => isBoolean(isExpired)), distinctUntilChanged());
  }

  public isTenantExpiredValue(): boolean {
    return this.isTenantExpired$.getValue();
  }

  public getAvailablePools(): AvailablePool[] {
    const tenantUnassignCredits: number | CreditPools = this.tenantUnassignCredits$.getValue();
    const pools: AvailablePool[] = [];

    if (this.isDistributedBalance()) {
      this.getValidPools(tenantUnassignCredits as CreditPools).forEach((pool: string) => {
        pools.push({
          value: `${pool}`,
          label: PoolFeaturesLabelsMap[pool],
          unassignCredits: tenantUnassignCredits[pool],
        });
      });
    }

    if (!this.isDistributedBalance()) {
      pools.push({
        value: camelCase(PoolFeatures.DEFAULT),
        label: '' as PoolFeatures,
        unassignCredits: tenantUnassignCredits as number,
      });
    }

    return pools;
  }

  public getTenantUnassignCredits(): number {
    const tenantUnassignCredits: number | CreditPools = this.tenantUnassignCredits$.getValue();
    if (this.isDistributedBalance()) {
      return (tenantUnassignCredits as CreditPools).total;
    }
    return tenantUnassignCredits as number;
  }

  public getTenantCurrentBalance(): number {
    const tenantBalanceValue: number | CreditPools = this.tenantCurrentBalance$.getValue();
    if (this.isDistributedBalance()) {
      return (tenantBalanceValue as CreditPools).total;
    }
    return tenantBalanceValue as number;
  }

  public getUserCurrentBalance(user: User): number {
    if (this.isTenantExpired$.getValue()) {
      return 0;
    }

    const balance: number | CreditPools | undefined = user.currentBalance;
    return this.getBalanceValueByType(balance);
  }

  public getUserInitialBalance(user: User): number {
    const balance: number | CreditPools | undefined = user.initialBalance;
    return this.getBalanceValueByType(balance);
  }

  private getBalanceValueByType(balance: number | CreditPools | undefined): number {
    if (!balance) {
      return 0;
    }

    if (!this.isDistributedBalance() && typeof balance === 'number') {
      return this.toDecimal(balance);
    }

    if (this.isDistributedBalance() && typeof balance !== 'number') {
      return this.toDecimal(balance.total);
    }

    if (this.isDistributedBalance() && typeof balance === 'number') {
      return 0;
    }

    throw 'Invalid user balance configured';
  }

  public getTenantUnassignCreditPools(): CreditPools | number {
    return this.tenantUnassignCredits$.getValue();
  }

  public getTenantCurrentBalanceCreditPools(): CreditPools | number {
    return this.tenantCurrentBalance$.getValue();
  }

  public getTenantBalanceType(): BalanceType {
    return this.tenantBalanceType$.getValue();
  }

  public isDistributedBalance(): boolean {
    return BalanceType.DISTRIBUTED === this.getTenantBalanceType();
  }

  public userHasEnoughCredits(actions: BillingActions[]): boolean {
    const userBalanceState: UserBalanceState = this.getUserBalanceState(actions);
    if ([UserBalanceState.CREDITS_EXPIRED, UserBalanceState.NO_CREDITS].includes(userBalanceState)) {
      this.showMessage(this.translationService.translate(UserBalanceStateMessages[userBalanceState]));
      return false;
    }
    return true;
  }

  private getUserBalanceState(actions: BillingActions[]): UserBalanceState {
    const billingPlan: BillingPlan<BillingActions, BillingActionType> = this.billingService.getBillingPlan().getValue();
    const currentBalance: CreditPools | number = this.ledgerService.getUserLedgerItemValue()?.currentBalance;
    const creditsExpired: boolean = this.isTenantExpired$.getValue();

    if (creditsExpired) {
      return UserBalanceState.CREDITS_EXPIRED;
    }

    try {
      if (this.isDistributedBalance()) {
        const actionsTypeMap: { [key: string]: BillingActions[] } = groupBy(actions);
        const allSatisfied: boolean = actions.every(
          (action) =>
            currentBalance[billingPlan[action].type] >= billingPlan[action].cost * actionsTypeMap[action].length
        );
        return allSatisfied ? UserBalanceState.EXISTENT_CREDITS : UserBalanceState.NO_CREDITS;
      } else {
        const total: number = actions.reduce((acc, curr) => acc + billingPlan[curr].cost, 0);
        return typeof currentBalance === 'number' && (currentBalance as number) >= total
          ? UserBalanceState.EXISTENT_CREDITS
          : UserBalanceState.NO_CREDITS;
      }
    } catch (error) {
      return UserBalanceState.NO_CREDITS;
    }
  }

  public isTenantLoaded(): Observable<boolean> {
    return this.isTenantLoaded$.asObservable().pipe(
      filter((isLoaded: boolean) => isLoaded),
      distinctUntilChanged()
    );
  }

  public toDecimal(value: number): number {
    if (!value) {
      return 0;
    }
    return Number(value.toFixed(this.DIGITS_AFTER_DECIMAL_POINT));
  }
}
