import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfigService } from '@app/config';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { head } from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { TargetColumnsKeys } from 'src/app/modules/analysis/shared/models/targets-list-request.model';
import { AnalysisUtilService } from 'src/app/modules/analysis/shared/services/analysis-util.service';
import { AnalysisService } from 'src/app/modules/analysis/shared/services/analysis.service';
import { CountryPipe } from 'src/app/pipes/country.pipe';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { RedirectSnackBarComponent } from 'src/app/shared/components/redirect-snack-bar/redirect-snack-bar.component';
import { Country } from 'src/app/shared/models/country.model';
import { Msisdn } from 'src/app/shared/models/msisdn.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { Case } from '../../models/case.model';
@Component({
  selector: 'app-create-target-dialog',
  templateUrl: './create-target-dialog.component.html',
  styleUrls: ['./create-target-dialog.component.scss']
})
export class CreateTargetDialogComponent extends BaseComponent implements OnInit {
  nextStep: boolean;
  createTargetForm: FormGroup;
  countryChips: string[] = [];
  availableCountries: Country[] = [];
  filteredCountries: Observable<Country[]>;
  msisdnChips: Msisdn[] = [];
  filteredMsisdns: Observable<Msisdn[]>;
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  isMsisdnValid: boolean;
  aliasError: boolean;
  disableNext: boolean;
  isValidNumber: boolean;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;
  targetCreditsMessage: string;
  expireTargetDays:number = 0;
  targetCreditsChargesEnabled: boolean = false;
  isMsisdnFormatValid: boolean;
  columnsKeys: { [key: string]: string } = TargetColumnsKeys;

  constructor(
    public dialogRef: MatDialogRef<CreateTargetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { case?: Case, target?: TargetItem },
    private countryPipe: CountryPipe,
    private targetService: TargetService,
    private translateService: TranslationService,
    private localStorageService: LocalStorageService,
    private targetSnackBar: MatSnackBar,
    private appConfigService: AppConfigService,
    private analysisService: AnalysisService,
    private analysisUtilService: AnalysisUtilService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.availableCountries = this.countryPipe.getAllCountries();
    this.targetCreditsChargesEnabled = this.appConfigService.getConfigVariable('enableCreditChargesForTarget');
    this.expireTargetDays = this.appConfigService.getConfigVariable('expireTargetDays');
    this.targetCreditsMessage = this.translateService.interpolate(
      'Management for a new target is free of charge for #{days} days',
      { days: this.expireTargetDays.toString() }
    );
    this.filteredCountries = this.createTargetForm.controls['countries'].valueChanges.pipe(
      debounceTime(100),
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.availableCountries.slice()))
    );

    this.checkAllMsisdnIsValid();
    this.onKeyupCheckAlias();
  }

  initForm(): void {
    this.createTargetForm = new FormGroup({
      alias: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
        Validators.pattern("^[a-zA-Z0-9 ]+$")
      ]),
      msisdn: new FormControl([], Validators.pattern('^[0-9]*$')),
      countries: new FormControl([])
    });
  }

  onKeyupCheckAlias(): void {
    this.subscriptions.push(
      this.createTargetForm.controls['alias'].valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        map(value => {
          if(!this.createTargetForm.controls['alias'].valid || !this.createTargetForm.controls['alias'].value){
            return;
          }
          return value;
        }),
        switchMap((alias: string | undefined) => {
          if (!alias) {
            return of([]);
          }
          return this._aliasFilter(alias);
        }))
      .subscribe((targets: TargetItem[]) => {
        this.validateNewAlias(targets, this.createTargetForm.controls['alias'].value);
      })
     );
  }

  fetchTargetsDetails(alias: string): Observable<TargetItem[]> {
    const request = {
      activeSort: '',
      direction: '',
      searchText: alias,
      filters: {},
      pageSize: '5',
      pageNumber: '1',
      columnsKeys: this.columnsKeys,
      searchFields: ['alias']
    };
    const { params, requestbody } = this.analysisUtilService.buildRequestPayload(request);
    return this.analysisService.getTargetsList(params, requestbody).pipe(
      map((data) => {
        return data.result;
      })
    );
  }

  private _aliasFilter(value: string): Observable<TargetItem[]> {
    const filterValue = value.toLowerCase();
    return this.fetchTargetsDetails(filterValue);
  }

  private validateNewAlias(targetsList: TargetItem[], newAlias: string): void {
    if (targetsList.some((target) => target.alias.toLowerCase() === newAlias.toLowerCase())) {
      this.aliasError = true;
      this.disableNext = true;
      return;
    }

    if (1 !== targetsList?.length) {
      this.aliasError = false;
      this.disableNext = false;
      return;
    }
  }

  private _filter(name: string): Country[] {
    const filterValue = name.toLowerCase();
    return this.availableCountries.filter(option => option.countryName.toLowerCase().includes(filterValue));
  }

  addCountry(event: MatAutocompleteSelectedEvent): void {
    const country = event.option.viewValue;
    const index = this.countryChips.indexOf(country);

    if (index < 0) {
      this.countryChips.push(country.trim());
    }

    this.countryInput.nativeElement.value = '';
    this.createTargetForm.controls['countries'].setValue('');
  }

  removeCountry(country: string): void {
    const index = this.countryChips.indexOf(country);

    if (index >= 0) {
      this.countryChips.splice(index, 1);
    }
  }

  addMsisdn(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      const index = this.msisdnChips.map(telno => telno.telno).indexOf(value);
      this.isValidNumber = typeof this.editValidationPhone(value) === 'string' ? true : false;
      const countryFlag = this.isValidNumber ? this.getCountryFlag(value) : '';

      if (index < 0) {
        this.msisdnChips.push({
          telno: value.trim(),
          countryCode: this.isValidNumber && countryFlag ? `iti__flag iti__${countryFlag}` : undefined
        });
      }
    }

    this.createTargetForm.controls['msisdn'].setValue('');
    this.checkAllMsisdnIsValid();
    this.msisdnValidation();
  }

  removeMsisdn(msisdn: string): void {
    const index = this.msisdnChips.map(telno => telno.telno).indexOf(msisdn);

    if (index >= 0) {
      this.msisdnChips.splice(index, 1);
    }
    this.checkAllMsisdnIsValid();
  }

  checkAllMsisdnIsValid(): void {
    this.isMsisdnValid = this.msisdnChips.every(
      msisdn => typeof this.targetService.getValidPhone(this.phoneNumberUtil, msisdn.telno) === 'string'
    );
  }

  editValidationPhone(msisdn: string): string | boolean {
    return this.targetService.getValidPhone(this.phoneNumberUtil, msisdn);
  }

  private getCountryFlag(msisdn: string): string {
    const parsedNumber = this.phoneNumberUtil.parse(msisdn, '');
    const countryFlag = this.phoneNumberUtil.getRegionCodeForNumber(parsedNumber)?.toLocaleLowerCase();
    return countryFlag;
  }

  createTarget(): void {
    if (this.createTargetForm.valid) {
      const newTarget: TargetItem = {
        ...this.data?.target,
        alias: this.createTargetForm.controls['alias'].value.trim(),
        telnos: this.msisdnChips.map(x => x.telno),
        countries: this.countryChips,
        user: this.localStorageService.getCurrentUser().identity,
        assignedCases: [this.data?.case?.id]
      };

      this.targetService.createTargetProfiler(newTarget, { createCase: false, addImProfiles: true }).subscribe(
        (target: TargetItem) => {
          this.targetRedirectSnackBar(target.id);
          this.dialogRef.close(target);
        },
        (error: any) => {
          this.showMessage(
            this.translateService.translate(error.messages ? error.messages : 'Target has not been created')
          );
        }
      );
    }
  }

  targetRedirectSnackBar(id: string): void {
    this.targetSnackBar.openFromComponent(RedirectSnackBarComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        id,
        html: {
          start: `${this.translateService.translate('The target changes have been saved. Click')}`,
          end: `${this.translateService.translate('to view the target profile')}`
        }
      }
    });
  }

  private msisdnValidation(): void {
    const msisdnRegexCheck = /^[0-9]*$/;
    const telno = head(this.msisdnChips.map(msisdn => msisdn.telno.replace('+', '')));
    this.isMsisdnFormatValid = !msisdnRegexCheck.test(telno) ? false : true;
  }
}
