<div class="row geo-statistics-top-row">
  <div class="stats-header">{{'Statistics' | translate}}</div>
</div>
<section class="info-message" *ngIf="(isAdmin || isPower) && isGrafanaDashboardEnabled">
  <span class="title">
    <mat-icon class="icon">info</mat-icon><span>{{ 'New Credits Usage Statistics Dashboard' | translate }} (beta)</span>
  </span>
  <button class="view-dashboard-btn" (click)="openGrafanaDashboard()">
    <mat-icon>open_in_new</mat-icon> {{ 'View Dashboard' | translate }} 
  </button>
</section>
<!-- Filters -->
<mat-progress-bar *ngIf="showLoader" mode="indeterminate" color="accent"></mat-progress-bar>
<div class="row geo-statistics-filters">
  <div class="col-6">
    <div>
      <div *ngIf="isAdmin || isSupport || isPower" class="user-filter">
        <div class="global-select-label">{{'Select user' | translate}}</div>
        <mat-select class="global-select" [(value)]="selectedUser" (selectionChange)="filter()" [disabled]="showLoader">
          <mat-option [value]="null">---</mat-option>
          <mat-option *ngFor="let username of usernames" [value]="username">{{username}}</mat-option>
        </mat-select>
      </div>
        <div class="period-filter">
          <div class="global-select-label">{{'From' | translate}}</div>
          <mat-select class="global-select" [(value)]="selectedPeriod" (selectionChange)="filter()" (selectionChange)="setPeriodLabel($event)" [disabled]="showLoader">
            <mat-option *ngFor="let period of periods" [value]="period.value">{{period.label | translate}}</mat-option>
          </mat-select>
        </div>
      <mat-checkbox class="unique-filter" [disabled]="showLoader" [(ngModel)]="uniqueEntries" checked="uniqueEntries" (change)="filter()">{{'Unique entries' | translate }}</mat-checkbox>
    </div>
  </div>
  <div class="col-6">
    <mat-tab-group class="dashboard-tabs" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="{{ 'MNOs' | translate}}">
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<!-- All Queries Charts -->
<div class="row mnos-tab-content" *ngIf="!showUsers">
  <div *ngFor="let chart of allQueriescharts" class="col-6 col">
    <ng-container *ngIf="chart.data.length">
      <div class="headers">
        <div class="chart-header">{{chart.header | translate}}</div>
        <span class="period-header">{{periodLabel | translate}}</span>
      </div>
      <google-chart
        [type]="chart.type"
        [data]="chart.data"
        [options]="chart.options"
        [width]="chart.width"
        [height]="chart.height">
      </google-chart>
      <div class="row table-headers">
        <div class="col-4 header">{{'Name' | translate}}</div>
        <div class="col-3 header center-xs">{{'Total queries' | translate}}</div>
        <div class="col-2 header center-xs">{{'Found' | translate}}</div>
        <div class="col-3 header center-xs">{{'Unreachable' | translate}}</div>
      </div>
      <div
        *ngFor="let entry of statistics"
        class="row table-entry"
        [class.hidden]="(chart.name === 'found' && !entry.found) || (chart.name === 'unreachable' && !entry.unreachable)">
        <div class="col-4">{{entry.providerName}}</div>
        <div class="col-3 center-xs">{{entry.found + entry.unreachable}}</div>
        <div class="col-2 center-xs">{{entry.found}}</div>
        <div class="col-3 center-xs">{{entry.unreachable}}</div>
      </div>
    </ng-container>
  </div>
  <!-- Providers Charts -->
  <div *ngFor="let chart of providersCharts" class="col-6 col">
    <div class="headers">
      <div class="chart-header">{{chart.header}}</div>
      <span class="period-header">{{periodLabel | translate}}</span>
    </div>
    <google-chart
      [type]="chart.type"
      [data]="chart.data"
      [options]="chart.options"
      [width]="chart.width"
      [height]="chart.height">
    </google-chart>
    <div class="row table-headers">
      <div class="col-4 header">{{'Name' | translate}}</div>
      <div class="col-3 header center-xs">{{'Total queries' | translate}}</div>
      <div class="col-2 header center-xs">{{'Found' | translate}}</div>
      <div class="col-3 header center-xs">{{'Unreachable' | translate}}</div>
    </div>
    <div class="row table-entry">
      <div class="col-4">{{chart.header}}</div>
      <div class="col-3 center-xs">{{chart.data[0][1] + chart.data[1][1]}}</div>
      <div class="col-2 center-xs">{{chart.data[0][1]}}</div>
      <div class="col-3 center-xs">{{chart.data[1][1]}}</div>
    </div>
  </div>
  <div *ngIf="!this.statistics.length && !showLoader" class="no-results">
    {{'No statistics available for the selected period.' | translate}}
  </div>
</div>
<div *ngIf="showUsers">
  USERS
</div>
