import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ExtractPeerStoreAction, QueryExtractPeerService } from 'src/app/services/query/query-extract-peer.service';
import { Query } from '../../models/query-item.model';
import { ExportPeerAction } from '../extract-peer-dialog/extract-peer-dialog.component';

@Component({
  selector: 'app-extract-peer-custom-marker',
  templateUrl: './extract-peer-custom-marker.component.html',
  styleUrls: ['./extract-peer-custom-marker.component.scss']
})
export class ExtractPeerCustomMarkerComponent implements OnInit {
  @Input() query: Query;

  public markerPath: string = '/assets/static/images/location_off_black.svg';
  public availableActions = {
    getInfoAndLocate: ExportPeerAction.GET_INFO_AND_LOCATE,
    getInfo: ExportPeerAction.GET_INFO
  };

  public popUpState: { requestExtractPeerPopup: boolean; showPeerInfo: boolean } = {
    requestExtractPeerPopup: false,
    showPeerInfo: false
  };

  constructor(public elRef: ElementRef, private queryExtractPeerService: QueryExtractPeerService) {}

  ngOnInit() {
    if (!!this.query?.callInfo?.billingId) {
      this.markerPath = '/assets/static/images/location_off_orange.svg';
      this.popUpState.showPeerInfo = true;
    } else {
      this.popUpState.requestExtractPeerPopup = true;
    }
  }

  public emitAction(action: ExportPeerAction): void {
    this.queryExtractPeerService.emitAction({ action, query: this.query } as ExtractPeerStoreAction);
  }

  public togglePopUp() {
    if (!!this.query?.callInfo?.billingId) {
      this.popUpState.showPeerInfo = !this.popUpState.showPeerInfo;
    } else {
      this.popUpState.requestExtractPeerPopup = !this.popUpState.requestExtractPeerPopup;
    }
  }
}
