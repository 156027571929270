import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { AdIdLocationHistoryDto } from '@trg-commons/gio-data-models-ts';
import { Angulartics2 } from 'angulartics2';
import { PhoneNumberUtil } from 'google-libphonenumber';
import _ from 'lodash';
import { head } from 'lodash-es';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AdIdService } from 'src/app/modules/ad-ids/shared/ad-id.service';
import { isValidIfa } from 'src/app/modules/ad-ids/shared/helpers';
import { IntelSearchArgTypes } from 'src/app/modules/search-intel/models/intel-search-arg-types.model';
import { SearchIntelModel } from 'src/app/modules/search-intel/models/search-intel.model';
import { IntelResultsInvestigationService } from 'src/app/modules/search-intel/services/intel-results-investigation.service';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { DashboardService, DashboardView } from 'src/app/services/dashboard/dashboard.service';
import { InstantMessagesStore } from 'src/app/services/instant-messages.store';
import { QueryService } from 'src/app/services/query/query.service';
import { QuestionnaireService } from 'src/app/services/questionnaire/questionnaire.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Animations } from 'src/app/shared/animations/animations';
import { WebintDisabledService } from 'src/app/shared/components/webint-disabled-modal/webint-disabled.service';
import { ApplicationMainPageUrls } from 'src/app/shared/models/application-main-page-urls.enum';
import { BillingActions, BillingActionType, BillingPlan } from 'src/app/shared/models/billing-action.model';
import { NavbarIdentifier } from 'src/app/shared/models/navbar-identifier.enum';
import { Themes } from 'src/app/shared/models/skins.model';
import { checkUrlForSpecificSubsequence } from 'src/app/shared/util/helper';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';
import { SearchbyImageComponent, SearchByImageModalPayload } from '../searchby-image/searchby-image.component';
import {
  IntelSearchType,
  SearchFilters,
  SearchIntelResult,
  SearchLabels
} from './../../modules/search-intel/models/search-intel.model';

type NavbarSearchType = 'GE0' | 'OSINT' | 'ADINT';
@Component({
  selector: 'app-search-intel-navbar',
  templateUrl: './search-intel-navbar.component.html',
  styleUrls: ['./search-intel-navbar.component.scss'],
  animations: [Animations.openClose]
})
export class SearchIntelNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output()
  emitSearchLoader = new EventEmitter<boolean>();

  @Output()
  emitIntelNavbarClick = new EventEmitter<boolean>();

  @Output()
  emitQueryInfo = new EventEmitter<{
    btnActive: boolean;
    searchInputType?: IntelSearchArgTypes[];
    searchInputValue?: string;
  }>();

  @Output()
  emitSearchFields = new EventEmitter<
    {
      value: string;
      label: SearchLabels;
      countryCode?: string;
      fileName?: string;
      displayName?: string;
    }[]
  >();

  @Input()
  importFilters: SearchFilters;

  @Input()
  importQueryArgs: { argValue: string; argType: any }[];

  @Input()
  navBarIdentifier: NavbarIdentifier;

  @ViewChild('searchList')
  searchList: MatSelectionList;

  private searchHistoryChanges: Subscription;
  searchLabels = SearchLabels;
  showLoader = false;
  currentUrl = this.router.url;
  queryPlaceholder: string;
  searchDrop = false;
  searchHistory: SearchIntelModel[] = [];
  searchFocus: boolean = false;
  loaderValue: boolean = false;
  searchText = new FormControl();
  searchTextQuery = new Subject();
  searchInputValue: string;
  searchType: NavbarSearchType = 'GE0';
  phoneNumberUtil = PhoneNumberUtil.getInstance();
  theme: Themes;
  visible = true;
  selectedOptions: any;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  adintFlavourEnabled: boolean;
  searchFields: {
    value: string;
    label: SearchLabels;
    countryCode?: string;
    filename?: string;
    displayName?: string;
  }[] = [];
  defaultQueryPlaceholder: string;
  IntelSearchType = IntelSearchType;
  randomPhoneNumber = '';
  infoMessage = '';

  @ViewChild('searchFieldInput') searchFieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  btnActive: boolean;
  inputTypes: IntelSearchArgTypes[] = [];
  inputTypeValueLabel: { [key: string]: { value: string; label: string } } = {
    [IntelSearchArgTypes.TELNO]: { value: SearchLabels.PHONE, label: 'Phone number' },
    [IntelSearchArgTypes.POSSIBLE_TELNO]: { value: SearchLabels.POSSIBLE_TELNO, label: `Phone number`},
    [IntelSearchArgTypes.NAME_WITH_SURNAME]: { value: SearchLabels.NAME_WITH_SURNAME, label: 'Name Surname' },
    [IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME]: { value: SearchLabels.NAME_WITH_MIDDLE_AND_SURNAME, label: 'Name Middle Surname' },
    [IntelSearchArgTypes.NAME]: { value: SearchLabels.NAME, label: 'Full Name' },
    [IntelSearchArgTypes.EMAIL]: { value: SearchLabels.EMAIL, label: 'Email' },
    [IntelSearchArgTypes.URL]: {
      value: SearchLabels.URL,
      label: 'Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok'
    },
    [IntelSearchArgTypes.USERNAME]: { value: SearchLabels.USERNAME, label: 'Username' },
    [IntelSearchArgTypes.HASHTAG]: { value: SearchLabels.HASHTAG, label: 'Twitter hashtag' },
    [IntelSearchArgTypes.CURP]: { value: SearchLabels.CURP, label: 'CURP' },
  };
  queryFilters: SearchFilters;
  showFilter: boolean = true;
  subscriptions: Subscription[] = [];
  matomo = {
    actions: matomoActions,
    categories: matomoCategories
  };
  enableIntelResultsV2: boolean;
  enableCovid19MX: boolean;
  typingEffect = true;
  appLanguage: Observable<string>;
  isLocatingDisabled = false;
  @ViewChild('matFormField') matFormField: MatFormField;
  public availableNavBarIdentifier = NavbarIdentifier;

  public billingPlan$: Observable<BillingPlan<BillingActions, BillingActionType>>;

  constructor(
    private router: Router,
    private searchIntelService: SearchIntelService,
    private queryService: QueryService,
    private targetService: TargetService,
    private translationService: TranslationService,
    private dashboardService: DashboardService,
    private adIdService: AdIdService,
    public snackBar: MatSnackBar,
    private appConfigService: AppConfigService,
    public dialog: MatDialog,
    private instantMessagesFetcher: InstantMessagesStore,
    private angulartics2: Angulartics2,
    private billingService: BillingService,
    private questionnaireService: QuestionnaireService,
    private intelResultsService: IntelResultsInvestigationService,
    private webintDisabledService: WebintDisabledService,
    private changeDetectorRef: ChangeDetectorRef,
    private userBillingService: UserBillingService,
  ) {
    this.subscribeToRouterEvents();
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.adintFlavourEnabled = this.appConfigService.getConfigVariable('enableAdintFlavour');
    this.enableIntelResultsV2 = this.appConfigService.getConfigVariable('enableIntelResultsV2');
    this.enableCovid19MX = this.appConfigService.getConfigVariable('enableCovid19MX');
    this.defaultQueryPlaceholder = `Start typing phone, name,${this.enableCovid19MX ? ' CURP,' : ''} url, email or username or upload photo`;
    this.isLocatingDisabled = this.appConfigService.getConfigVariable('disableLocatingFunctionality');
    this.router.events.subscribe(url => {
      if (url instanceof NavigationEnd) {
        this.searchDrop = false;
        this.emitIntelNavbarClick.emit(false);
      }
    });
    this.appLanguage = this.translationService.languageChange;

    this.randomPhoneNumber = this.getRandomPhoneNumberForTenant(this.appConfigService.getConfigVariable('tenantCountryCodes'));
    this.infoMessage = this.translationService.interpolate('To search for a phone number, enter the complete number, including country code. Example: #{example}', {example: this.randomPhoneNumber})
  }

  ngAfterViewInit() {
    if (this.enableCovid19MX && this.searchType === IntelSearchType.OSINT) {
      setTimeout(() => {
        this.queryPlaceholder = this.defaultQueryPlaceholder;
        this.changeDetectorRef.markForCheck();
      }, 10000);
    }
  }

  ngOnInit() {

    this.getSearchTypeBaseUrl(this.router.url);
    this.setQueryInputPlaceHolder();
    this.searchHistoryChanges = this.searchIntelService.changeHistory.subscribe(() => this.getHistory());

    this.getHistory();
    this.subscriptions.push(
      this.searchTextQuery.subscribe((text: string) => {
        if (!text) {
          return;
        }
        this.searchInputValue = text.trim();
      })
    );

    this.subscriptions.push(
      this.searchIntelService.searchBtnListner
        .pipe(distinctUntilChanged())
        .subscribe(
          (action: {
            event: PointerEvent;
            filters: SearchFilters;
            navBarIdentifier: string;
            noResultSearch?: boolean;
            skipLocate?: boolean;
          }) => {
            const { event, noResultSearch, skipLocate, filters, navBarIdentifier } = action;
            if (navBarIdentifier === this.navBarIdentifier) {
              this.queryFilters = filters;
              this.handleIncomingInput({ event, noResultSearch, skipLocate: skipLocate || this.isLocatingDisabled });
            }
          }
        )
    );

    if (this.importQueryArgs) {
      this.createQueryLabels();
    }

    this.subscriptions.push(
      this.searchText.valueChanges.subscribe(() =>
        this.handleInputText())
    );

    this.billingPlan$ = this.billingService.getBillingPlan().asObservable();
  }

  createQueryLabels() {
    this.importQueryArgs.forEach(argData => {
      let label = this.searchIntelService.getQueryArgLabel(argData.argType);
      if (label === SearchLabels.PHONE && this.searchText.value) {
        this.searchText.setValue(argData.argValue);
        this.searchInputValue = argData.argValue;
        const parsedNumber = this.phoneNumberUtil.parse(this.searchText.value, '');
        const countryFlag = this.phoneNumberUtil.getRegionCodeForNumber(parsedNumber)?.toLocaleLowerCase();

        this.searchFields.push({
          value: this.searchText.value,
          label,
          countryCode: countryFlag ? `iti__flag iti__${countryFlag}` : undefined
        });
      } else if (label === SearchLabels.IMAGE) {
        this.searchInputValue = 'image-search';
        this.searchText.setValue('');
        Object.keys(argData.argValue).forEach(key => {
          if (key === 'photoUrl') {
            this.searchFields.push({
              value: argData.argValue[key],
              label: SearchLabels.IMAGE,
              filename: argData.argValue['filename']
            });
          } else if (key === 'name' && argData.argValue[key]) {
            this.searchFields.push({
              value: argData.argValue[key],
              label: SearchLabels.NAME
            });
          }
        });
      } else if( label === SearchLabels.POSSIBLE_TELNO && this.searchText.value) {
        this.searchInputValue = argData.argValue;
        this.searchText.setValue(argData.argValue);
        this.searchFields.push({
          value: this.searchText.value,
          label
        });
      } else {
        this.searchInputValue = argData.argValue;
        this.searchText.setValue(argData.argValue);
        this.searchFields.push({
          value: this.searchText.value,
          label
        });
      }

      this.searchIntelService.searchText.next(this.searchText.value);
      this.onKeyUp();
      this.searchText.reset();
    });
  }


  getHistory() {
    const queryArgs = {
      limit: 5,
      page: 1
    };

    this.searchIntelService
      .getAllSearchIntelWithPagination(queryArgs)
      .subscribe((res: { result: SearchIntelResult }) => {
        this.searchHistory = res.result.queries;
      });
  }

  onKeyUp(keyEvent?: KeyboardEvent) {
    if (keyEvent && keyEvent.keyCode === 13) {
      this.handleIncomingInput({ event: keyEvent, skipLocate: this.isLocatingDisabled });
    }
  }

  handleInputText(): void {
    this.searchTextQuery.next(this.searchText.value);
    this.btnActive = this.searchText.value || this.searchFields.length ? true : false;
    if (this.searchText.value) {
      this.inputTypes = Object.keys(
        this.searchIntelService.guessInputType(this.searchText.value)
      ) as IntelSearchArgTypes[];
    }
    this.emitQueryInfo.emit({
      btnActive: this.btnActive,
      searchInputType: this.inputTypes,
      searchInputValue: this.searchInputValue,
    });
  }

  onKeyDown(event) {
    if (event && [38, 40].includes(event.keyCode) && this.searchList) {
      this.searchList.focus();
    } else {
      this.searchFields.forEach(label => {
        if (label.label === SearchLabels.IMAGE) {
          if (this.searchFields.length === 2) {
            event.preventDefault();
          }
        } else if (this.searchFields.length === 1) {
          event.preventDefault();
        }
      });
      this.onSearchDrop();
    }
  }

  onSearchDrop() {
    this.searchDrop = true;
    if (this.searchType === IntelSearchType.OSINT) {
      this.emitIntelNavbarClick.emit(this.searchDrop);
      this.queryPlaceholder = this.defaultQueryPlaceholder;
    }
    event.stopPropagation();
  }

  clickedOutside() {
    this.searchDrop = false;
    this.emitIntelNavbarClick.emit(this.searchDrop);
  }

  searchListAutoSelect(inputTypes: IntelSearchArgTypes[], searchList: MatSelectionList): MatListOption[] {
    if (inputTypes.length === 0 || !searchList || this.searchType === IntelSearchType.GE0) {
      return null;
    }
    return searchList.options.toArray();
  }

  private getRandomPhoneNumberForTenant(countryCodeNums: number[]): string {
    const countryCodeNum = countryCodeNums?.length > 0 ? countryCodeNums[0] : null;
    if (!countryCodeNum) return '+5215513336419';

    const countryCode = this.phoneNumberUtil.getRegionCodeForCountryCode(countryCodeNum);
    const num = this.phoneNumberUtil.getExampleNumber(countryCode);

    return `+${num.getCountryCode()}${num.getNationalNumber()}`;
  }

  private checkForValidMsisdn(): string | boolean {
    const parsedMsisdn = this.queryService.deleteInvalidCharactersFromMsisdn(this.searchInputValue);
    const isValid = this.targetService.getValidPhone(this.phoneNumberUtil, parsedMsisdn);
    if (!!isValid) {
      this.searchInputValue = parsedMsisdn;
    }
    return isValid;
  }

  private handleIncomingInput(params: {
    event: KeyboardEvent | PointerEvent;
    noResultSearch?: boolean;
    skipLocate?: boolean;
  }): void {
    const { event, noResultSearch, skipLocate } = params;
    if (!this.loaderValue) {
      if ((event['keyCode'] === 13 && this.searchInputValue) || (event.type === 'click' && this.searchInputValue)) {
        const autoSelectOptions = this.searchListAutoSelect(this.inputTypes, this.searchList);
        if (autoSelectOptions) {
          this.onClickSearchItem(autoSelectOptions);
          return;
        }
      }
      const isTelno: boolean = this.searchInputValue.startsWith('+') && !skipLocate;

      if (!this.userHasEnoughCredits(isTelno)) {
        return;
      }

      if (isTelno && this.searchType === IntelSearchType.OSINT) {
        const validPhone = this.checkForValidMsisdn();
        if (!!validPhone) {
          this.guessSearchIntelType(noResultSearch);
          const shouldPreventGeoquery = event['keyCode'] === ENTER && (this.navBarIdentifier === NavbarIdentifier.MAINNAVBAR);
          if (!shouldPreventGeoquery) {
            this.createQuickQuery(<string>validPhone);
          } 
        } else {
          this.showMessage(this.translationService.translate('Enter a valid number'));
        }
      } else {
        this.guessSearchIntelType(noResultSearch);
      }
    }
  }

  private userHasEnoughCredits(isTelno: boolean): boolean {
    const billingActions: BillingActions[] = [];

    if (IntelSearchType.GE0 === this.searchType && isTelno) {
      billingActions.push(BillingActions.QUERY_LOCATION);
    }

    if (IntelSearchType.OSINT === this.searchType && isTelno) {
      billingActions.push(BillingActions.QUERY_LOCATION, BillingActions.INTEL_SEARCH);
    }

    if (!isTelno) {
      billingActions.push(BillingActions.INTEL_SEARCH);
    }

    return this.userBillingService.userHasEnoughCredits(billingActions);
  }

  private guessSearchIntelType(noResultSearch: boolean): void {
    this.angulartics2.eventTrack.next({
      action: matomoActions.search,
      properties: { category: matomoCategories.landingPage },
    });

    switch (this.searchType) {
      case IntelSearchType.GE0:
        if (this.isValidImsi(this.searchInputValue)) {
          return;
        }
        this.isValidPhone(this.searchInputValue);
        this.searchInputValue = '';
        break;
      case IntelSearchType.OSINT:
        this.searchDrop = false;
        if (this.webintDisabledService.webintActionsDisabled) {
          if (this.checkForValidMsisdn()) {
            this.locate();
          } else {
            this.webintDisabledService.openWebintDisabledModal();
          }
        } else {
          this.createQuery(noResultSearch);
        }
        break;
      case IntelSearchType.ADINT:
        if (!this.webintDisabledService.handleWebintAvailability()) {
          return;
        }

        const ifas = this.searchInputValue.trim().split(',');
        const validIfas = [];

        ifas.forEach((ifa) => {
          if (isValidIfa(ifa)) {
            validIfas.push(ifa);
          }
        });

        if (validIfas.length < ifas.length) {
          this.showMessage(this.translationService.translate('You have entered some invalid ad ids.'));
        } else {
          this.showLoader = true;
          this.adIdService
            .createLocationHistoryRequest(
              new AdIdLocationHistoryDto({
                ifas: validIfas,
                startTime: moment().subtract(1, 'months').toDate(),
                endTime: moment().toDate(),
              })
            )
            .subscribe(() => {
              this.searchText.setValue(null);
              this.showLoader = false;
            });
        }
        break;
    }
  }


  createQuery(noResultSearch: boolean = false) {
    noResultSearch = this.router.url === `/${ApplicationMainPageUrls.WEBINT}/results` ? true : false;
    this.searchDrop = false;
    if (!this.enableIntelResultsV2) {
      this.loaderValue = true;
      this.emitSearchLoader.emit(this.loaderValue);
    }
    let searchArgs = [];

    if (!this.searchFields.length) {
      searchArgs = searchArgs.concat(this.buildQueryArgs(this.searchInputValue));
      this.searchIntelService.searchText.next(this.searchInputValue);
    } else {
      const searchArgsImageObject = {
        arg_type: IntelSearchArgTypes.PHOTO,
        arg_value: {
          name: '',
          photoUrl: '',
          filename: ''
        }
      };
      const checkForImage = this.searchFields.every(entry => entry.label !== SearchLabels.IMAGE);
      if (noResultSearch) {
        if (checkForImage) {
          this.searchFields.forEach(field => {
            searchArgs = searchArgs.concat(this.searchIntelService.buildQueryArgsByLabel(field));
            this.searchIntelService.searchText.next(searchArgs[0].arg_value);
          });
        } else {
          const queryNames = this.searchFields.filter(entry => entry.label == SearchLabels.NAME);
          const queryImages = this.searchFields.filter(entry => entry.label == SearchLabels.IMAGE);
          queryNames.forEach(field => {
            searchArgsImageObject.arg_value[IntelSearchArgTypes.PHOTO_URL] = String(queryImages[0].value).trim();
            searchArgsImageObject.arg_value[IntelSearchArgTypes.NAME] = String(field.value).trim();
            searchArgsImageObject.arg_value['filename'] = String(queryImages[0].filename)
              ? String(queryImages[0].filename)
              : '';
            searchArgs.push(_.cloneDeep(searchArgsImageObject));
            this.searchIntelService.searchText.next(
              `${searchArgsImageObject.arg_value.name} and ${searchArgsImageObject.arg_value.filename
                ? searchArgsImageObject.arg_value.filename
                : searchArgsImageObject.arg_value.photoUrl
              }`
            );
          });
        }
      } else {
        this.searchFields.forEach(field => {
          if (checkForImage) {
            searchArgs = searchArgs.concat(this.searchIntelService.buildQueryArgsByLabel(field));
            this.searchIntelService.searchText.next(searchArgs[0].arg_value);
          } else {
            searchArgsImageObject.arg_value[
              field.label === SearchLabels.IMAGE ? IntelSearchArgTypes.PHOTO_URL : IntelSearchArgTypes.NAME
            ] = String(field.value).trim();
            if (field.label === SearchLabels.IMAGE) {
              searchArgsImageObject.arg_value['filename'] = String(field.filename);
            }
            this.searchIntelService.searchText.next(
              `${searchArgsImageObject.arg_value.name} and ${searchArgsImageObject.arg_value.filename
                ? searchArgsImageObject.arg_value.filename
                : searchArgsImageObject.arg_value.photoUrl
              }`
            );
          }
        });
        if (!checkForImage) {
          searchArgs.push(searchArgsImageObject);
        }
      }
    }
    if (this.enableIntelResultsV2) {
      this.intelResultsService
        .createSearchIntel(searchArgs, this.queryFilters)
        .subscribe((search: SearchIntelModel) => {
          this.intelResultsService.createListenerForSearchResult(search);
          this.navigateToResultsView();
        });
    } else {
      this.searchIntelService.createSearchIntel(searchArgs, this.queryFilters).subscribe(
        (search: SearchIntelModel) => {
          searchArgs = [];
          if (search) {
            this.searchIntelService.currentSearchIntel.next({ search, noResultSearch });
            this.navigateToResultsView();
            this.searchText.setValue('');
            this.searchFields = [];
            this.emitIntelNavbarClick.emit(false);
            this.searchFieldInput.nativeElement.blur();
            this.emitSearchLoader.emit(false);
            this.loaderValue = false;
          } else {
            this.emitSearchLoader.emit(false);
            this.loaderValue = false;
          }
          this.searchInputValue = '';
          this.getHistory();
        },
        (error: { errors: { message?: string; msg?: string }[] }) => {
          const searchField = head(this.searchFields);
          const err = head(error.errors);
          if (err?.message) {
            this.showMessage(this.translationService.translate(err.message));
          }
          if (err?.msg) {
            this.showMessage(
              `${searchField.value} ${this.translationService.translate('is not a valid')} ${searchField.label}`
            );
          }
          this.emitSearchLoader.emit(false);
          this.loaderValue = false;
        }
      );
    }
  }

  private buildQueryArgs(value: string): { arg_type: string; arg_value: string }[] {
    const searchArgs = [];
    const input = this.searchIntelService.guessInputType(value);
    Object.keys(input).forEach(key => {
      searchArgs.push({ arg_type: key, arg_value: String(input[key]).trim() });
    });

    if (input.hasOwnProperty(IntelSearchArgTypes.TELNO)) {
      this.searchIntelService.searchText.next(input.telno);
    } else {
      const searchText = Object.keys(input)
        .map(key => input[key])
        .join(', ');
      this.searchIntelService.searchText.next(searchText);
    }

    return searchArgs;
  }

  isValidImsi(number) {
    const queries = [];
    if (this.queryService.isValidImsi(number)) {
      queries.push({ imsi: number });
      this.submitQuickQuery(queries);
      this.searchText.setValue('');
      return true;
    }
  }

  isValidPhone(number: string) {
    try {
      const queries = [];
      const validPhone = this.targetService.getValidPhone(this.phoneNumberUtil, number);

      if (validPhone) {
        this.searchText.setValue('');

        queries.push({ telno: validPhone });
        this.submitQuickQuery(queries);
      } else {
        this.showMessage(this.translationService.interpolate(`Enter the complete phone number including country code. Example: #{example}`, {example: this.randomPhoneNumber}));
      }
    } catch (e) {
      this.showMessage(this.translationService.interpolate(`Enter the complete phone number including country code. Example: #{example}`, {example: this.randomPhoneNumber}));
    }
  }

  submitQuickQuery(queries) {
    this.searchDrop = false;
    if (this.userBillingService.userHasEnoughCredits([BillingActions.QUERY_LOCATION])) {
      this.questionnaireService.shouldShowQuestionnaire();
      const quickQuerySubscription = this.queryService.quickQuery(queries).subscribe(
        (response: { result: any }) => {
          const [query] = response.result;
          if (query.query_args.telno) {
            this.instantMessagesFetcher.fetchAllImPlatforms(query.query_args.telno, query.id, true);
          }
          this.showMessage(this.translationService.translate('Query created successfully!'));
          this.dashboardService.componentsView.next(DashboardView.LOG);
          this.dashboardService.showLogTab.next(true);
          quickQuerySubscription.unsubscribe();
        },
        () => {
          this.showMessage(this.translationService.translate('Query has not been created'));
          quickQuerySubscription.unsubscribe();
        }
      );
    }
  }

  showPreviousSearch(search: SearchIntelModel) {
    this.searchDrop = false;
    this.searchIntelService.currentSearchIntel.next({ search, noResultSearch: false });
    this.navigateToResultsView();
    this.clearSearchInput();
  }

  addImageDialog() {
    const dialogRef = this.dialog.open(SearchbyImageComponent, {
      width: '550px'
    });

    dialogRef
      .afterClosed()
      .subscribe((result: SearchByImageModalPayload) => {
        if (result?.imageUrl) {
          this.searchText.setValue('');
          this.searchIntelService.searchText.next(this.searchText.value);
          this.searchInputValue = 'image-search';
          this.searchFields = [];
          Object.keys(result).forEach(key => {
            if (key === 'imageUrl') {
              this.searchFields.push({
                value: result[key],
                label: key === 'imageUrl' ? SearchLabels.IMAGE : SearchLabels.NAME,
                filename: result['filename'],
                displayName: result['displayName']
              });
            } else if (key === 'name') {
              this.searchFields.push({
                value: result[key],
                label: SearchLabels.NAME
              });
            }
          });
          this.search({ keyCode: ENTER } as KeyboardEvent, 'image-search');
        }
      });
  }

  remove(index: any): void {
    if (index >= 0) {
      let imageSearch = this.searchFields.some(x => x.label == SearchLabels.IMAGE);
      if (imageSearch) {
        this.searchFields = [];
      } else {
        this.searchFields.splice(index, 1);
      }
    }
  }

  makeFavorite(flag: boolean, id: string) {
    this.searchIntelService.makeSearchHistoryAsFavorite(id, flag).subscribe(result => {
      this.getHistory();
    });
  }

  private checkExistingLabels(): boolean {
    return this.searchFields.some(elem => elem.label === SearchLabels.IMAGE);
  }

  onClickSearchItem(data: MatListOption[]) {
    this.clickedOutside();
    let label: SearchLabels = head(data.map(o => o.value));
    let countryFlag: string;
    if (label === SearchLabels.PHONE && this.searchText.value) {
      const parsedNumber = this.phoneNumberUtil.parse(this.searchText.value, '');
      countryFlag = this.phoneNumberUtil.getRegionCodeForNumber(parsedNumber)?.toLocaleLowerCase();
    }

    if (this.checkExistingLabels() && label === SearchLabels.NAME && this.searchFields.length <= 1) {
      this.searchFields.push({
        value: this.searchText.value,
        label: label,
        countryCode: ''
      });
    } else if (!this.checkExistingLabels() && !this.searchFields.length) {
      if (label === SearchLabels.URL && data.length > 1 && !checkUrlForSpecificSubsequence(this.searchText.value)) {
        label = data[1].value;
      }
      this.searchFields.push({
        value: this.searchText.value,
        label: label,
        countryCode: label === SearchLabels.PHONE && countryFlag ? `iti__flag iti__${countryFlag}` : undefined
      });
    }
    this.searchText.reset();
    this.emitSearchFields.next(this.searchFields);
    event.stopPropagation();
  }

  private subscribeToRouterEvents() {
    this.subscriptions.push(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((location: NavigationEnd) => {
        this.getSearchTypeBaseUrl(location.url);
        this.setQueryInputPlaceHolder();
        this.currentUrl = location.url;
        this.showFilter = this.currentUrl === `/${ApplicationMainPageUrls.WEBINT}` ? false : true;
      })
    );
  }

  private getSearchTypeBaseUrl(url: string) {
    if (this.adintFlavourEnabled) {
      this.searchType = IntelSearchType.ADINT;

      return;
    }

    if (url === '/discovery') {
      this.searchType = IntelSearchType.GE0;
    } else if (url.includes('adint')) {
      this.searchType = IntelSearchType.ADINT;
    } else {
      this.searchType = IntelSearchType.OSINT;
    }
  }

  private setQueryInputPlaceHolder() {
    switch (this.searchType) {
      case IntelSearchType.GE0:
        this.queryPlaceholder = 'Locate a single MSISDN or IMSI';
        break;
      case IntelSearchType.OSINT:
        this.queryPlaceholder = this.defaultQueryPlaceholder;
        break;
      case IntelSearchType.ADINT:
        this.queryPlaceholder = 'Location history';
        break;
    }
  }

  public search(event: KeyboardEvent | PointerEvent, action: string): void {
    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }

    this.searchDrop = false;
    if (this.importFilters && (!action || action !== 'image-search')) {
      this.searchIntelService.searchBtnListner.next({
        event: event as PointerEvent,
        filters: this.importFilters,
        navBarIdentifier: this.navBarIdentifier,
        skipLocate: true,
      });
    }
    this.handleIncomingInput({ event, skipLocate: this.isLocatingDisabled });
    this.clearSearchInput();
  }

  public searchAndLocate(event: PointerEvent): void {
    if (!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }
    this.searchDrop = false;
    this.handleIncomingInput({ event, skipLocate: false });
    this.clearSearchInput();
  }

  private clearSearchInput(): void {
    this.searchText.setValue('');
    this.emitIntelNavbarClick.emit(false);
  }

  private createQuickQuery(telno: string): void {
    this.queryService.quickQuery([{ telno }]).subscribe(
      () => { },
      () => {
        this.showMessage(this.translationService.translate('Query Cancelled'));
      }
    );
  }

  public locate(): void {
    const validPhone = this.checkForValidMsisdn();
    if (!validPhone) {
      this.showMessage(this.translationService.interpolate(`Enter the complete phone number including country code. Example: #{example}`, { example: this.randomPhoneNumber}));
      return;
    }

    if (this.userBillingService.userHasEnoughCredits([BillingActions.QUERY_LOCATION])) {
      this.createQuickQuery(<string>validPhone);
      this.clearSearchInput();
      this.router.navigate(['discovery']);
    }
  }

  /**
   * @param  {string} msg
   * @param  {} okText='OK'
   */
  showMessage(msg: string, okText = 'OK') {
    this.snackBar.open(msg, okText, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }
  routeToHistory() {
    this.router.navigateByUrl(`/${ApplicationMainPageUrls.WEBINT}/history`);
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.searchHistoryChanges) {
      this.searchHistoryChanges.unsubscribe();
    }
  }

  navigateToResultsView(): void {
    if (this.enableIntelResultsV2) {
      this.router.navigateByUrl(`/${ApplicationMainPageUrls.WEBINT}/results-view`);
    } else {
      this.router.navigateByUrl(`/${ApplicationMainPageUrls.WEBINT}/results`);
    }
  }

  public hideTypingEffect() {
    this.typingEffect = false;
  }
}
