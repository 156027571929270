<!-- actions, filter -->
<div class="row start-md">
  <div class="si-actions">
    <img src="assets/static/images/refresh_light.svg" height="18" class="clickable refresh" (click)="refreshConversations()"
      matTooltip="{{'Refresh' | translate}}">
    <div class="group-by">
      <div class="global-select-label">{{'Group By' | translate }}</div>
      <mat-select [(value)]="groupBy" class="global-select" (selectionChange)="filterView()">
        <mat-option *ngFor="let filter of groupByFields" [value]="filter.value">{{ filter.label | translate }}</mat-option>
      </mat-select>
    </div>
    <div>
      <div class="global-select-label">{{'Filter' | translate }}</div>
      <div class="expanding-search">
        <input #searchInput matInput type="search" (keyup)="onKeyUp($event)">
      </div>
    </div>
  </div>
</div>

<div class="conversations-list">
  <div class="row labels-row">
    <div class="column checkbox-column">
      <!-- comment out select all until we find a solution for the multiple map pins crush
      <mat-checkbox (change)="multiselect($event)"></mat-checkbox> -->
    </div>
    <div class="column">
      <span class="col-label">{{ 'Satellite' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('satellite')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'ID' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('conversationId')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Type' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('conversationType')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Direction' | translate}} </span>
      <span class="sort-icon clickable" (click)="sort('direction')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Date' | translate }} </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Duration (s)' | translate}} </span>
      <span class="sort-icon clickable" (click)="sort('durationSeconds')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'CALLER' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('caller')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'CALLEE' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('callee')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'IMSI' | translate}} </span>
      <span class="sort-icon clickable" (click)="sort('imsi')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'TMSI' | translate }} </span>
      <span class="sort-icon clickable" (click)="sort('tmsi')">
        <img src="assets/static/images/sort.svg" height="15" width="15" matTooltip="{{'Sort' | translate}}">
      </span>
    </div>
    <div class="column coords-column">
      <span class="col-label">{{ 'Lat/Lon' | translate}} </span>
    </div>
    <div class="column">
      <span class="col-label">{{ 'Target' | translate }} </span>
    </div>
  </div>
  <mat-progress-bar *ngIf="loader" [color]="'accent'" [mode]="'query'" [value]="50" [bufferValue]="75">
  </mat-progress-bar>
  <!-- Default View Data-->
  <ng-container *ngIf="!groupByView">
    <app-si-log-table-item
      *ngFor="let conversation of paginatedConversations, let i=index"
      (emittedConversation)="onSelectedConversation($event)"
      (emittedMultiselectConversation)="onMultiselectConversation($event)"
      [conversation]="conversation"
      [even]="i%2 === 0"
      [checked]="checked">
    </app-si-log-table-item>
  </ng-container>
  <!-- Group View Data-->
  <ng-container *ngIf="groupByView">
    <mat-accordion class="groups">
      <mat-expansion-panel *ngFor="let group of groupedData">
        <mat-expansion-panel-header>
          <mat-panel-title>{{group.groupName === 'null' ? 'Unknown' : group.groupName}} ({{group.items.length}})</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="group-panel" [style.max-height]="getGroupHeight()">
          <app-si-log-table-item
          *ngFor="let conversation of group.items, let i=index"
          (emittedConversation)="onSelectedConversation($event)"
          (emittedMultiselectConversation)="onMultiselectConversation($event)"
          [conversation]="conversation"
          [even]="i%2 === 0"
          [checked]="checked">
          </app-si-log-table-item>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <div *ngIf="!conversations || !conversations.length" class="col-12 center-xs">
    {{'No conversations' | translate}}.</div>
</div>
<mat-paginator *ngIf="!groupByView" [length]="paginator.totalSize" [pageIndex]="paginator.currentPage" [pageSize]="paginator.pageSize"
  (page)="onPaginatePageChange($event)" class="si-paginator">
</mat-paginator>
