import { Entity } from 'src/app/modules/data-layer/models/platform-models/base/entity';
import { Gender } from 'src/app/modules/data-layer/models/platform-models/enums/gender';
import { ProfileStatus } from 'src/app/modules/data-layer/models/platform-models/enums/social/profile.status';
import { Location } from 'src/app/modules/data-layer/models/platform-models/social/location';

export class Profile extends Entity {
  public about?: string;
  public username?: string;
  public telno?: string;
  public url?: string;
  public name?: string;
  public nicknames?: string[];
  public gender?: Gender;
  public dob?: string;
  public email?: string;
  public status?: ProfileStatus;
  public lastseen?: Date;
  public languages?: string[];
  public websites?: string[];
  public mood?: string;
  public interestedIn?: string;
  public verified?: boolean;
  public isPublic?: boolean;
  public hometown?: Location;
  public nameLocation?: Location;
  public currentCity?: Location;
  public location?: string;
  public isGeoEnabled?: boolean;
  public favouritesCount?: number;
  public profileNumericId?: string;
  public followingCount?: number;
  public followersCount?: number;
  public tweetsCount?: number;
  public joinedAt?: Date;
  public friendsCount?: number;
  public heartsCount?: number;
  public videosCount?: number;
  public imageUrl?: string;
  public publishedAt?: Date;
  public telecomProvider?: string;
  public lastSeen?: string;
  public relations?: Relation[];
  public totalSms?: number;
  public totalCalls?: number;
  public relationCategory?: string;
  public subRelationCategory?: string;
  public relationshipStatus?: string;

  public profileToTargetInfo?: {
    targetId?: string;
    alias?: string;
  }
}

export interface Relation {
  count_no?: string;
  type?: string
  user_id: string;
}
