import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CQRSBaseEvent, EventChannel } from '@trg-commons/gio-data-models-ts';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { catchError, map } from 'rxjs/operators';
import { LedgerDetail, LedgerDetailsResult } from 'src/app/components/ledger-details/ledger-details.model';
import {
  Ledger,
  LedgerItemsDetailsOptions,
  LedgerItemsResult,
  TenantDetails,
} from 'src/app/components/ledger/ledger.model';
import { ProxyWsService } from 'src/app/modules/ad-ids/shared/proxy-ws.service';
import { BaseService } from 'src/app/services/base.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { User as UserLedgerItem } from 'src/app/services/user/user.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { AuthService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LedgerService extends BaseService {
  public paginatedLedgerItems$: Subject<LedgerItemsResult> = new Subject<LedgerItemsResult>();
  private refreshCurrentUser$: Subject<void> = new Subject<void>();
  private currentUserLedgerDetail$: BehaviorSubject<UserLedgerItem> = new BehaviorSubject<UserLedgerItem>(null);

  constructor(
    protected router: Router,
    private httpClient: HttpClient,
    private proxyWsService: ProxyWsService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.authService.isAuthenticated.subscribe(() => {
      this.initializeLedgerUpdateSubscriptions();
      this.fetchCurrentUserLedgerItem();
    });
  }

  private initializeLedgerUpdateSubscriptions(): void {
    this.proxyWsService
      .getMessage()
      .pipe(
        filter(({ channel }: CQRSBaseEvent<LedgerDetail | UserLedgerItem>) => channel === EventChannel.Ledger),
        map((message) => message?.body),
        filter((ledgerItem) => ledgerItem?.username === this.localStorageService.getCurrentUser()?.identity),
        debounceTime(500)
      )
      .subscribe(() => this.fetchCurrentUserLedgerItem());
  }

  public fetchLedgerItemDetails(
    ledger: Ledger,
    {
      limit = 12,
      page = 1,
      sortKey = 'created_at',
      sortOrder = -1,
      filterArg = '',
      startsAt = 0,
      endsAt = 0,
    }: LedgerItemsDetailsOptions
  ): Observable<LedgerDetailsResult> {
    return this.httpClient
      .get<any>(`${this.fastAPIurl}/ledger/ledgers-items/${ledger.name}`, {
        params: {
          limit: limit.toString(),
          page: page.toString(),
          sortOrder: sortOrder.toString(),
          sortKey: sortKey
            .split(/(?=[A-Z])/)
            .join('_')
            .toLowerCase(),
          filterArg,
          startsAt: startsAt.toString(),
          endsAt: endsAt.toString(),
        },
      })
      .pipe(
        catchError((error) => this.handleError(error)),
        map((data) => transformSnakeToCamel(data))
      );
  }

  public getLedgerItems({ limit = 12, page = 1, sortKey = 'name', sortOrder = -1, filterArg = '' }): void {
    const params = {};
    params['limit'] = limit;
    params['page'] = page;
    params['sortOrder'] = sortOrder;
    params['sortKey'] = sortKey
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    params['filterArg'] = filterArg;

    this.httpClient
      .get<LedgerItemsResult>(`${this.fastAPIurl}/ledger/ledger-users`, { params })
      .pipe(
        catchError((error) => this.handleError(error)),
        map((ledgerResult) => transformSnakeToCamel(ledgerResult))
      )
      .subscribe((ledgerResult: LedgerItemsResult) => this.paginatedLedgerItems$.next(ledgerResult));
  }

  private fetchCurrentUserLedgerItem(): void {
    const username: string = this.localStorageService.getCurrentUser()?.identity;
    this.httpClient
      .get<{ result: UserLedgerItem }>(`${this.fastAPIurl}/ledger/ledger-item/${username}`)
      .pipe(
        catchError((error) => this.handleError(error)),
        map((data: { result: UserLedgerItem }) => transformSnakeToCamel(data.result))
      )
      .subscribe((user: UserLedgerItem) => {
        this.updateUserLedgerItem(user);
        this.refreshCurrentUser();
      });
  }

  public getTenantDetails(startDateTimestamp: number, endDateTimestamp: number): Observable<TenantDetails> {
    const params = {};
    params['start_date'] = startDateTimestamp;
    params['end_date'] = endDateTimestamp;
    return this.httpClient.get<{ data: TenantDetails }>(`${this.fastAPIurl}/ledger/tenant`, { params }).pipe(
      catchError((error) => this.handleError(error)),
      map((result) => {
        return transformSnakeToCamel(result.data);
      })
    );
  }

  public reloadUserLedgerItem(): void{
    this.fetchCurrentUserLedgerItem();
  }

  private refreshCurrentUser(): void {
    this.refreshCurrentUser$.next();
  }

  public onRefreshCurrentUser(): Observable<void> {
    return this.refreshCurrentUser$.asObservable();
  }

  public getCurrentUserLedgerItem(): Observable<UserLedgerItem> {
    return this.currentUserLedgerDetail$.asObservable().pipe(filter((user: UserLedgerItem) => !!user));
  }

  public getUserLedgerItemValue(): UserLedgerItem {
    return this.currentUserLedgerDetail$.getValue();
  }

  private updateUserLedgerItem(updatedLedgerItem: UserLedgerItem): void {
    this.currentUserLedgerDetail$.next(updatedLedgerItem);
  }
}
