export enum MarkerIcon {
  Home = `/assets/static/images/analytics/pin_home.svg`,
  Work = `/assets/static/images/analytics/pin_work.svg`,
  Grey = '/assets/static/images/profiler/pin_grey.svg',
  TimelineLast = '/assets/static/images/profiler/pin_red.svg',
  TimelineFirst = '/assets/static/images/profiler/pin_green.svg',
  Default = '/assets/static/images/geo_default_pin.svg',
  Live = '/assets/static/images/pin_live.svg',
  ProfilerDefault = '/assets/static/images/profiler_default_pin.svg',
  Gps = '/assets/static/images/gps_location_pin.svg',
  Nmr = '/assets/static/images/nmr_location_pin.svg',
  Facebook = '/assets/static/images/pin_facebook.svg',
  FeedAlbum = '/assets/static/images/profiler/marker_feed_album.svg',
  FeedPost = '/assets/static/images/profiler/marker_feed_post.svg',
  FeedComment = '/assets/static/images/profiler/marker_feed_comment.svg',
  FeedImageVideo = '/assets/static/images/profiler/marker_feed_image_video.svg',
  FeedNote = '/assets/static/images/profiler/marker_feed_note.svg',
  FeedLocation = '/assets/static/images/profiler/marker_feed_location.svg',
  Data = '/assets/static/images/profiler/marker_data.svg',
  CallIn = '/assets/static/images/profiler/marker_call_in.svg',
  CallOut = '/assets/static/images/profiler/marker_call_out.svg',
  SmsIn = '/assets/static/images/profiler/marker_sms_in.svg',
  SmsOut = '/assets/static/images/profiler/marker_sms_out.svg',
  Twitter = '/assets/static/images/pin_twitter.svg'
}
