import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityType, QueryCommand } from '@trg-commons/data-models-ts';
import {
  AdIdLocationHistoryDto,
  CaseCdrStatisticsDto,
  CdrExportDto,
  CdrStatisticsDto,
  CdrTargetEvent,
  CQRSBaseEvent,
  EventChannel,
  OperationRequestDto,
  PredictedLocationDto,
  PredictedLocationsEvent
} from '@trg-commons/gio-data-models-ts';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, mergeAll, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { StatisticModels } from 'src/app/modules/call-logs/services/cl-store-manager.service';
import { WebsocketManagerService } from 'src/app/services/websocket/websocket-manager.service';
import { ClParameterDataTypeBackendMapper } from 'src/app/shared/models/call-log-request.model';
import { environment } from 'src/environments/environment';
import { CallLogUploadRequest } from '../models/call-log-upload-request';
import { ClRecommandations, ClRecommandationsRequest, PurchaseClRecommandation } from './../../../../modules/call-logs/models/clr.model';

@Injectable({ providedIn: 'root' })
export class CallLogsApiService {
  baseUrl: string;
  public purchaseClPendingState = new BehaviorSubject<string[]>([]);
  public listeners: Map<string, Subject<any>> = new Map();

  public prevRequestHash: string | undefined;
  public prevCorrelationId: string | undefined;

  constructor(
    private wsManager: WebsocketManagerService,
    private http: HttpClient,
  ) {
    this.baseUrl = environment.proxyAPIUri;
    this.initializeListeners();
  }

  addToPurchasePendingState(msisdn: string) {
    this.purchaseClPendingState.next([...this.purchaseClPendingState.getValue(), msisdn]);
  }

  createStatisticsRequest(request: CdrStatisticsDto): Observable<CQRSBaseEvent<StatisticModels>> {
    const url = this.baseUrl + '/call-logs/cdr-statistics';
    if (!request.command) {
      return forkJoin(this.prepareMultipleStatisticRequests(request, url)).pipe(
        switchMap(result =>
          result.map(clStatistic => {
            return this.createListener(clStatistic.correlationId);
          })
        ),
        mergeAll()
      );
    } else {
      return this.postWithHeaders<CQRSBaseEvent<StatisticModels>>(url, request).pipe(
        switchMap(result => this.createListener(result.correlationId))
      );
    }
  }

  createStitisticsRequestTargetData(request: CdrStatisticsDto): Observable<CQRSBaseEvent<StatisticModels>> {
    const url = this.baseUrl + '/call-logs/cdr-statistics';
    return forkJoin(this.prepareMultipleRequestsForTargetData(request, url)).pipe(
      switchMap(result =>
        result.map(clStatistic => {
          return this.createListener(clStatistic.correlationId);
        })
      ),
      mergeAll()
    );
  }

  prepareMultipleRequestsForTargetData(
    request: CdrStatisticsDto,
    url: string,
  ): Observable<CQRSBaseEvent<StatisticModels>>[] {
    const commands = [
      QueryCommand.StaticInfo,
      QueryCommand.ImeiDistribution,
    ];

    return commands.map(commandType => {
      const requestBody = cloneDeep(request);
      requestBody.command = commandType;
      return this.postWithHeaders<CQRSBaseEvent<StatisticModels>>(url, requestBody)
        .pipe(
          catchError(error => {
            console.warn('Http Failure', error);
            return [];
          })
        );
    });
  }

  prepareMultipleStatisticRequests(
    request: CdrStatisticsDto,
    url: string
  ): Observable<CQRSBaseEvent<StatisticModels>>[] {
    const commands = [
      QueryCommand.CallAnalysis,
      QueryCommand.CountAnalysis,
      QueryCommand.EventByType,
      QueryCommand.TargetActivity,
      QueryCommand.TopAssociates,
      QueryCommand.TopLocations,
      QueryCommand.TargetPeerInteractions
    ];
    return commands.map(commandType => {
      const requestBody = cloneDeep(request);
      requestBody.command = commandType;
      return this.postWithHeaders<CQRSBaseEvent<StatisticModels>>(url, requestBody)
        .pipe(
          catchError(error => {
            console.warn('Http Failure', error);
            return [];
          })
        );
    });
  }

  public getTargetsCallLogPeerInteractionsStatistics(msisdns: string[]) {
    const url = `${environment.proxyAPIUri}/call-logs/cdr-statistics`;
    return this.postWithHeaders<OperationRequestDto>(url, { msisdns, command: EntityType.TargetPeerInteractions }).pipe(
      mergeMap(result => this.createListener(result.correlationId))
    );
  }

  // is used in target view link analysis
  public getTargetCallLogTopAssociatesStatistics(msisdns: string[]) {
    const url = `${environment.proxyAPIUri}/call-logs/cdr-statistics`;
    return this.postWithHeaders<OperationRequestDto>(url, { msisdns, command: EntityType.TopAssociates }).pipe(
      mergeMap(result => this.createListener(result.correlationId))
    );
  }

  // is used in case view link analysis
  public getCaseCallLogTopAssociatesStatistics(targetIds: string[]) {
    const url = this.baseUrl + `/call-logs/case/cdr-statistics`;
    return this.postWithHeaders<OperationRequestDto>(url, { targetIds, command: EntityType.CaseTopAssociates }).pipe(
      mergeMap(result => this.createListener(result.correlationId))
    );
  }

  protected getConnectionId(): Observable<string> {
    return this.wsManager.getServerTsConnection().pipe(map(socket => socket.id));
  }

  private buildHttpHeaders(connectionId: string): HttpHeaders {
    return new HttpHeaders().append('Ws-Connection-Id', connectionId);
  }

  postWithHeadersWithObserve<T>(url: string, request: unknown): Observable<any> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap(connectionId => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.http.post<T>(url, request, { headers: headers, observe: 'events', reportProgress: true });
      })
    );
  }

  postWithHeaders<T>(url: string, request: unknown): Observable<T> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap(connectionId => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.http.post<T>(url, request, { headers: headers });
      })
    );
  }

  putWithHeaders<T>(url: string, request: unknown): Observable<T> {
    return this.getConnectionId().pipe(
      take(1),
      switchMap(connectionId => {
        const headers = this.buildHttpHeaders(connectionId);
        return this.http.put<T>(url, request, { headers: headers });
      })
    );
  }

  hashRequest(request: AdIdLocationHistoryDto): string {
    return JSON.stringify(
      new AdIdLocationHistoryDto({
        ifas: request.ifas,
        msisdns: request.msisdns,
        startTime: request.startTime,
        endTime: request.endTime,
        limit: request.limit,
        order: request.order,
        imeis: request.imeis,
        batchIds: request.batchIds,
        countryCodes: request.countryCodes,
        regionCodes: request.regionCodes
      })
    );
  }

  locationHistoryRequest(
    request: AdIdLocationHistoryDto
  ): Observable<CQRSBaseEvent<any>> {
    const url = this.baseUrl + '/call-logs/location-history';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      switchMap(result => {
        this.prevCorrelationId = result.correlationId;
        return this.createListener(result.correlationId);
      })
    );
  }

  requestClRecommandation(request: ClRecommandationsRequest): Observable<ClRecommandations> {
    const url = this.baseUrl + '/call-log-recommendation/rpc/request-call-log-recommendations';

    return this.postWithHeaders<OperationRequestDto[]>(url, request).pipe(
      mergeMap(result => result.map(clRecommandation => this.createListener(clRecommandation.correlationId))),
      mergeAll(),
      tap(cqrsMessage => {
        if (cqrsMessage.channel === EventChannel.OperationRequestsStreamEnded) {
          const listener = this.listeners.get(cqrsMessage.correlationId);
          this.waitForOutOfOrderMessagesAndCleanup(cqrsMessage.correlationId, listener);
        }
      }),
      filter(cqrsMessage => cqrsMessage.channel === EventChannel.CallLogRecommendations),
      map(clRecommandation => clRecommandation.body)
    );
  }

  purchaseCallLogRequest(telno: string): Observable<PurchaseClRecommandation> {
    const url = this.baseUrl + '/call-log-request/rpc/request-call-log-purchase';

    return this.postWithHeaders<OperationRequestDto>(url, { msisdn: telno }).pipe(
      tap(result => this.addToPurchasePendingState(telno)),
      mergeMap(result => this.createListener(result.correlationId)),
      map(result => result.body)
    );
  }

  createCaseLocationHistoryRequest(request: AdIdLocationHistoryDto): Observable<CdrTargetEvent> {
    const url = this.baseUrl + '/call-logs/case/location-history';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      mergeMap(result => this.createListener(result.correlationId))
    );
  }

  createPredictedLocationsRequest(request: PredictedLocationDto): Observable<PredictedLocationsEvent> {
    const url = this.baseUrl + '/call-logs/predicted-location';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      mergeMap(result => this.createListener(result.correlationId))
    );
  }

  createCasePredictedLocationsRequest(request: PredictedLocationDto): Observable<any> {
    const url = this.baseUrl + '/call-logs/case/predicted-location';
    return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
      mergeMap(result => this.createListener(result.correlationId))
    );
  }

  createExportRequest(request: CdrExportDto): Observable<any> {
    const exportUrl = this.baseUrl + '/call-logs/cdr-export';
    const downloadUrl = this.baseUrl + '/call-logs/download/';

    return this.postWithHeaders<OperationRequestDto>(exportUrl, request).pipe(
      mergeMap(operationRequest => this.createListener(operationRequest.correlationId)),
      filter(event => event.channel === EventChannel.OperationRequestsStreamEnded),
      mergeMap(listener => {
        return this.http.get(downloadUrl + listener.correlationId, { responseType: 'blob' });
      }),
      mergeMap(fileResponse => {
        const fileName = request.msisdns.join().replace(/\+/g, '').replace(/,/g, '_') + '_call_log.csv';
        const blob = new Blob([fileResponse], { type: fileResponse.type });
        saveAs(blob, fileName);
        return of(request);
      })
    );
  }

  uploadCallLogsFile(file: File, request: CallLogUploadRequest): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const mappedRequest = {...request, uploadType: ClParameterDataTypeBackendMapper[request.uploadType]};
    Object.entries(mappedRequest).forEach(el => {
      formData.append(el[0], el[1])
    });
    return this.postWithHeadersWithObserve(this.baseUrl + '/call-logs/upload', formData)
      .pipe(
        mergeMap((resp: any) => {
          if (resp instanceof HttpResponse) {
            return this.createListener(resp.body.correlationId);
          }
          return of(resp);
        })
      );
  }


  /**
   *
   * @param targetIds string
   */
  public createCdrStatisticRequest(request: CaseCdrStatisticsDto) {
    const url = this.baseUrl + `/call-logs/case/cdr-statistics`;
    if (!request.command) {
      return forkJoin(this.prepareMultipleStatisticRequestsForCaseCdr(request, url)).pipe(
        switchMap(result =>
          result.map(clStatistic => {
            return this.createListener(clStatistic.correlationId);
          })
        ),
        mergeAll()
      );
    } else {
      return this.postWithHeaders<OperationRequestDto>(url, request).pipe(
        switchMap(result => this.createListener(result.correlationId))
      );
    }
  }

  prepareMultipleStatisticRequestsForCaseCdr(
    request: CaseCdrStatisticsDto,
    url: string
  ): Observable<CQRSBaseEvent<StatisticModels>>[] {
    const commands = [
      QueryCommand.CommonAssociates,
      QueryCommand.CommonLocations,
      QueryCommand.CaseTopAssociates,
      QueryCommand.CaseTopLocations,
      QueryCommand.CaseTargetActivity,
      QueryCommand.CaseEventByType,
      QueryCommand.CaseCallAnalysis,
      QueryCommand.CaseCountAnalysis,
      QueryCommand.CasePredictedLocations,
      QueryCommand.CaseTargetsInteractions
    ];
    return commands.map(commandType => {
      const requestBody = cloneDeep(request);
      requestBody.command = commandType;
      return this.postWithHeaders<OperationRequestDto>(url, requestBody)
        .pipe(
          catchError(error => {
            console.warn('Http Failure', error);
            return [];
          })
        );
    });
  }

  private createListener(correlationId: string | undefined): Observable<CQRSBaseEvent<any>> {
    if (!correlationId) throw 'Cannot create listener with no correlationId';
    const subject = new Subject<CQRSBaseEvent<any>>();
    this.listeners.set(correlationId, subject);
    return subject.asObservable();
  }

  private initializeListeners() {
    this.wsManager.getServerTsConnection().subscribe(ws => {
      ws.on('message', (data: CQRSBaseEvent<any>) => {
        const listener = this.listeners.get(data.correlationId);

        if (!listener) {
          return;
        }

        listener.next(data);
        if (data.streamEnded && data.channel === EventChannel.OperationRequestsStreamEnded) {
          this.waitForOutOfOrderMessagesAndCleanup(data.correlationId, listener);
        }
      });
    });
  }

  private waitForOutOfOrderMessagesAndCleanup(corId: string, listener: Subject<any>) {
    this.listeners.delete(corId);
    listener.complete();
  }
}
