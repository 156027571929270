import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComponentType } from 'ngx-toastr';
import { DrawerOptions, DrawerState } from './drawer.model';
import { Subject } from 'rxjs';
import {
  faUpRightAndDownLeftFromCenter,
  faWindowMinimize,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'intellectus-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements AfterViewInit {
  expanded$ = new Subject<boolean>();
  closed$ = new Subject<void>();
  component: ComponentType<unknown> | undefined;
  options: DrawerOptions | undefined;

  readonly closeIcon = faXmark;
  readonly expandIcon = faUpRightAndDownLeftFromCenter;
  readonly minimizeIcon = faWindowMinimize;

  @ViewChild('container', { read: ViewContainerRef }) container:
    | ViewContainerRef
    | undefined;
  state = DrawerState.DEFAULT;
  States = DrawerState;
  constructor(private changeDetector: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    if (this.component) {
      this.container?.createComponent(this.component);
    }
    this.changeDetector.detectChanges();
  }

  toggleState() {
    this.state =
      this.state === this.States.MINIMIZED
        ? this.States.DEFAULT
        : this.States.MINIMIZED;
  }

  expand() {
    this.expanded$.next(true);
  }

  close() {
    this.closed$.next();
  }
}
