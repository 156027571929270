import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { TacticalService } from 'src/app/services/tactical/tactical.service';
import Swal from 'sweetalert2';
import { TargetFormComponent } from 'src/app/modules/profiler/components/target-form/target-form.component';
import { MatDialog } from '@angular/material/dialog';
import { ConversationItem } from 'src/app/shared/models/conversation-item.model';
import { AppConfigService } from 'src/app/providers/app-config.service';

@Component({
  selector: 'app-si-log-table-item',
  templateUrl: './si-log-table-item.component.html',
  styleUrls: ['./si-log-table-item.component.scss']
})
export class SiLogTableItemComponent extends BaseComponent implements OnInit {
  selectedConversation;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;
  theme: string;
  @Input() conversation;
  @Input() even: boolean;
  @Input() checked: boolean;

  @Output() emittedConversation = new EventEmitter<ConversationItem>();
  @Output() emittedMultiselectConversation = new EventEmitter<{ conversation: ConversationItem, checked: boolean }>();

  constructor(
    private tacticalService: TacticalService,
    public dialog: MatDialog,
    private appConfigService: AppConfigService,
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    const conversationSubscription = this.tacticalService.selectedConversation.subscribe(
      (conversation) => {
        this.selectedConversation = conversation;
      }
    );

    this.subscriptions.push(conversationSubscription);
  }

  selectConversation(conversation) {
    if (conversation.location) {
      this.emittedConversation.emit(conversation);
    }
    this.tacticalService.selectedConversation.next(conversation);
  }

  fileReader(sms) {
    Swal.fire({
      title: 'SMS',
      text: `${sms}`,
      showCloseButton: true,
    });
  }

  audioPlayer(conversation) {
    Swal.fire({
      title: 'Audio',
      html: `<div>
              <audio controls>
                <source src="${conversation.file}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </div>
            <div>
              ${conversation.audioText}
            </div>`,
      showCloseButton: true,
    });
  }

  addTargetDialog(conversation): void {
    const msisdn = conversation.caller ? [conversation.caller] : conversation.callee ? [conversation.callee] : [];
    const imsi = conversation.imsi ? [conversation.imsi] : [];
    const tmsi = conversation.tmsi ? [conversation.tmsi] : [];
    const imei = conversation.imei ? [conversation.imei] : [];
    const target = {
      names: [],
      telnos: msisdn,
      imsis: imsi,
      tmsis: tmsi,
      imeis: imei,
      assignedUsers: [],
      assignedGroups: []
    };
    this.dialog.open(TargetFormComponent, {
      width: '30vw',
      height: 'auto',
      data: target,
      panelClass: 'create-target-dialog',
    });
  }

  multiselect(event, conversation) {
    this.tacticalService.selectedConversation.next(null);
    if (conversation.location) {
      this.emittedMultiselectConversation.emit({ conversation, checked: event.checked });
    }
  }

}
