import { ComponentRef, ElementRef, EmbeddedViewRef, TemplateRef } from '@angular/core';

/**
 * Elements drawn on map
 */
export class MapOptions {
  zoomControl?: boolean = true;
  mapTypeControl?: boolean = true;
  scaleControl?: boolean = true;
  streetViewControl?: boolean = true;
  rotateControl?: boolean = false;
  fullscreenControl?: boolean = true;
  disableDefaultUI?: boolean = false;
  updateMapBounds?: boolean;
  minZoom?: number;
  draggable?: boolean;
  mapTypeControlOptions: Object | undefined;
  zoomControlOptions: Object | undefined;
  streetViewControlOptions: Object | undefined;
  fullscreenControlOptions: Object | undefined;
  maxZoom?: number;
  mapTypeId?: MapTypeId;
  homeControl?: boolean = true;
  preserveBounds?: boolean = false;
  get controls(): Object {
    if (this.disableDefaultUI) {
      return {
        disableDefaultUI: true
      };
    }
    return this;
  }

  constructor(model: Partial<MapOptions> = {}) {
    Object.assign(this, model);
  }
}

export abstract class Feature {
  type?: string | 'FeatureCollection' | 'Feature';
  properties?: any;
  geometry?: any;
  features?: Feature[];
}

export interface Point {
  lat: number;
  lng: number;
}

export class HeatMap {
  data: Point[] = [];
  color?: string;
  radius?: number;

  constructor(model: Partial<HeatMap> | any = {}) {
    Object.assign(this, model);
  }
}

export class Marker implements Point {
  constructor(model: Partial<Marker>) {
    Object.assign(this, model);
    if (!model.hasOwnProperty('extendMapBounds')) {
      this.extendMapBounds = true;
    }
  }
  lat: number = 0;
  lng: number = 0;
  id!: string;
  isPopupWindowOpen?: boolean;
  popupHTML?: string;
  extendMapBounds?: boolean;
  date?: Date;
  zIndex?: number;
  zoomLevelDisplay?: number;
  isSelected?: boolean;
  getPopupEmbeddedView?: () => EmbeddedViewRef<unknown>;
  click?: (marker: Marker, nativeMarker: any) => void;
  highlight?: (marker: Marker) => Marker;
}

export class IconMarker extends Marker {
  constructor(model: Partial<IconMarker>) {
    super(model);
    Object.assign(this, model);
  }

  iconUrl: string | undefined;
  iconWidth?: number;
  iconHeight?: number;
  anchor?: AnchorPoint;
}

export class ColorMarker extends Marker {
  constructor(model: Partial<ColorMarker> = {}) {
    super(model);
    Object.assign(this, model);
  }

  color: string | undefined;
  iconWidth?: number;
  iconHeight?: number;
}

export class RadiusMarker extends IconMarker {
  constructor(model: Partial<RadiusMarker>) {
    super(model);
    Object.assign(this, model);
  }

  radius = 0;
  alwaysShowRadius = false;
  editable = false;
  radiusChanged?(marker: Marker, nativeMarker: unknown, distance: number): void;
}

export class TemplateMarker extends Marker {
  constructor(model?: any) {
    super(model);
  }
  templateRef: TemplateRef<unknown> | undefined;
  componentRef: ComponentRef<unknown> | undefined;
  elementRef: ElementRef | undefined;
  anchorPosition?: AnchorPosition;
  getEmbeddedView: (() => EmbeddedViewRef<unknown>) | undefined;
}

export class Shape {
  id!: string;
  strokeColor?: string;
  fillColor?: string;
  strokeWeight?: number;
  strokeOpacity?: number;
  fillOpacity?: number;
  extendMapBounds?: boolean;
  show?: boolean;

  isPopupWindowOpen?: boolean;
  popupHTML?: string;
  getPopupEmbeddedView?: () => EmbeddedViewRef<unknown>;
  popupPosition?: PopupPosition = PopupPosition.TOP;

  constructor(partial: Partial<Shape> = {}) {
    Object.assign(this, partial);

    if (!partial.hasOwnProperty('extendMapBounds')) {
      this.extendMapBounds = true;
    }

    if (!partial.hasOwnProperty('show')) {
      this.show = true;
    }
  }
}

class CircleCenterRadius extends Shape {
  constructor(model: Partial<CircleCenterRadius>) {
    super(model);
    Object.assign(this, model);
  }
  center!: Point;
  radiusMeters = 0;
}

export class Circle extends CircleCenterRadius {
  constructor(partial: Partial<Circle>) {
    super(partial);
    Object.assign(this, partial);
  }
}

export class Rectangle extends Shape {
  northEast!: Point;
  southWest!: Point;

  constructor(partial: Partial<Rectangle> = {}) {
    super();
    Object.assign(this, partial);
  }
}

export class Polygon extends Shape {
  points: Point[] = [];

  constructor(partial: Partial<Polygon> = {}) {
    super(partial);
    Object.assign(this, partial);
  }
}

export class Polyline extends Shape {
  points: Point[] = [];
  constructor(partial: Partial<Polyline> = {}) {
    super();
    Object.assign(this, partial);
  }
}

/**
 * HUD elements
 */
export enum ButtonState {
  Activated = 'Activated',
  Deactivated = 'Deactivated'
}

export enum ControlPosition {
  RIGHT_BOTTOM = 9,
  TOP_LEFT = 1,
  TOP_CENTER = 2,
  TOP_RIGHT = 3,
  LEFT_CENTER = 4,
  LEFT_TOP = 5,
  LEFT_BOTTOM = 6,
  RIGHT_TOP = 7,
  RIGHT_CENTER = 8,
  BOTTOM_RIGHT = 12,
  BOTTOM_LEFT = 10,
  BOTTOM_CENTER = 11
}

export enum ControlStyle {
  DEFAULT = 0,
  HORIZONTAL_BAR = 1,
  DROPDOWN_MENU = 2,
  INSET = 3,
  INSET_LARGE = 4,
}

export class Button {
  iconUrl: { [key in ButtonState]: string } | undefined;
  altText?: { [key in ButtonState]: string } | undefined;
  state = ButtonState.Activated;
  toggleable = false;
  id!: string;
  position!: ControlPosition;
  callback?: (button?: Button) => void;

  constructor(partial: Partial<Button> = {}) {
    Object.assign(this, partial);
  }
}

/**
 * Tiles
 */
export interface TileSource {
  tileUrl: string;
  attribution: '';
}

/**
 * Drawing
 */
export enum DrawMode {
  None,
  Circle,
  Rectangle,
  Polygon,
  Polyline,
  Marker,
  DrawCircle,
  DrawPolygon
}

export class DrawModeOptions {
  mode!: DrawMode;
  options: any;

  constructor(partial: Partial<DrawModeOptions> = {}) {
    Object.assign(this, partial);
  }
}
interface AnchorPoint {
  x: number;
  y: number;
}

export enum MapTypeId {
  HYBRID = 'hybrid',
  ROADMAP = 'roadmap',
  SATELLITE = 'satellite',
  TERRAIN = 'terrain',
}

export enum MapStyleType {
  DEFAULT = 'default',
  STANDARD = 'standard',
  SATELLITE = 'satellite'
}

type FeatureDensityStyle = {
  stylers: { color?: string, visibility?: string }[];
  elementType?: string;
  featureType?: string;
};

export type MapStyleWithOptions = {
  minZoom: number;
  maxZoom: number;
  id: string;
  featureDensityStyles: FeatureDensityStyle[]
}

export type StyledMapTypeOptions =  {[id: string]: MapStyleWithOptions};

export enum PopupPosition {
  TOP,
  BOTTOM
}

export enum AnchorPosition {
  MIDDLE,
  BOTTOM
}
