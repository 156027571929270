import { inRange } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { IconMarker } from 'src/app/modules/mapV2/models/map.models';
import { MarkerCollectionOptions } from './marker-collection-options.enum';

export class MarkerCollection {
  markers: { [id: string]: { [id: string]: IconMarker } } = {
    lastSeen: {},
    placesOfInterest: {},
    targetLocations: {},
    filteredTimeline: {},
    callLogs: {},
    adIds: {},
    geolocationProbabilities: {},
    cdrProbabilities: {},
    timelinecdrProbabilities: {},
    nearbyProfileLocations: {},
    feed: {},
    checkins: {},
  };
  all: BehaviorSubject<IconMarker[]> = new BehaviorSubject([]);

  addMarker(collectionName: MarkerCollectionOptions, newMarkers: IconMarker[]): void {
    newMarkers.forEach(newMarker => {
      if (!this.areCoordsValid(newMarker)) {
        return;
      }
      this.markers[collectionName][newMarker.id] = newMarker;
    });
    this.setAll();
  }

  updateCollection(collectionName: MarkerCollectionOptions, collection: IconMarker[]): void {
    if (!Array.isArray(collection)) {
      return;
    }
    this.markers[collectionName] = {};
    const markers = collection.filter(marker => this.areCoordsValid(marker));
    markers.forEach(marker => {
      this.markers[collectionName][marker.id] = marker;
    });
    this.setAll();
  }

  private setAll(): void {
    const all = [
      ...Object.values(this.markers.lastSeen),
      ...Object.values(this.markers.targetLocations),
      ...Object.values(this.markers.callLogs),
      ...Object.values(this.markers.adIds),
      ...Object.values(this.markers.nearbyProfileLocations),
      ...Object.values(this.markers.checkins),
    ]
      .sort((a, b) => +a.date - +b.date)
      .filter((marker, index, self) => index === self.findIndex(m => m.id === marker.id))
      .filter(marker => !!marker.date);

    this.all.next(all);
  }

  private areCoordsValid(marker: IconMarker): boolean {
    if (!marker) {
      return false;
    }

    const { lat, lng } = marker;

    if (lat === 0 && lng === 0) {
      return false;
    }

    if (lat === null || lat === undefined || lng === null || lng === undefined) {
      return false;
    }

    if (!inRange(lat, -90, 90)) {
      return false;
    }

    if (!inRange(lng, -180, 180)) {
      return false;
    }

    return true;
  }
}
