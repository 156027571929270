import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { DataLayerModule } from 'datalayer/data-layer.module';

@Injectable({
  providedIn: DataLayerModule
})

export class TargetRecycleBinService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar,
  ) {
    super(router, snackBar);
  }

  public getTargetRecycleBin(target_id: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/target-recycle-bin/${target_id}`)
      .pipe(
        catchError(error => this.handleError(error))
      );
  }

  public saveToTargetRecycleBin(
    target_id: string,
    seeds: {
      values: [string, string][],
      recycleData: { [key: string]: any[] } | {},
      reEnabledSeeds: { [key: string]: any[] } | {}
    }): Observable<{ result: string[] }> {
    return this.httpClient.post<any>(`${this.url}/target-recycle-bin/${target_id}`, seeds)
      .pipe(
        catchError(error => this.handleError(error))
      );
  }
}
