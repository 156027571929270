import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-stage-loader',
  templateUrl: './multi-stage-loader.component.html',
  styleUrls: ['./multi-stage-loader.component.scss']
})
export class MultiStageLoaderComponent implements OnInit {
  @Input() title: string;
  @Input() query: string;
  @Input() progressBarValue: number = 0;
  @Input() resultsCount: number = 0;
  @Input() progressBarElements: {name: string, value: boolean}[] = [];
  @Input() showResultsCount: boolean = false;

  constructor() {
   }

  ngOnInit(): void {
  }
}
