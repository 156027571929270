import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plain-progress-loader',
  templateUrl: './plain-progress-loader.component.html',
  styleUrls: ['./plain-progress-loader.component.scss']
})
export class PlainProgressLoaderComponent {

  @Input()
  loading: boolean;

  constructor() {
  }
}
