<div [ngClass]="view">
  <div class="target-popup" [style.left.px]="btnPosition" *ngIf="targetPopup">
    {{ 'Targets are now here' | translate }}
  </div>
  <ng-container *ngIf="!isMobileResolution && (skin === 'GEO4' || skin === 'CVTP')">
    <div
      class="flex-wrp"
      [class.zoom75]="isSmallDeviceResolution"
      [class.main-dashboard-content]="view === 'logView' || view === 'analyticsView'"
    >
      <div class="geo-tables" [class.standard-user]="this.currentUser">
        <!-- Geo Log Table -->
        <div class="log-table shadow" #geoTableLog *ngIf="view === 'logView'">
          <app-query-list
            [filesDownloadState]="filesDownloadState"
            (emittedSelectedQuery)="onSelectedQuery($event)"
            (refreshLogMap)="refreshMap(); closeSideNav()"
            (emittedMultiselectQuery)="onMultiselectQuery($event)"
          >
          </app-query-list>
        </div>

        <!-- Geo Side Content -->
        <div
          #geoTableSideContent
          class="side-content shadow"
          [class.active]="sideNav"
          *ngIf="query"
          [class.hidden]="hideCenterDetails()"
        >
          <div>
            <app-geolocation-details
              [recommendations]="recommendations"
              (toggleSideNav)="toggleSideNav()"
              (filesDownloadState)="getFilesDownloadState($event)"
            ></app-geolocation-details>
          </div>
        </div>
      </div>

      <!-- Geo Map -->
      <div
        [style.width]="'calc(100% - ' + geoMapWidth + 'px)'"
        [class.mapAnimate]="!sideNav && geoMapWidth > 510"
        [class.active]="sideNav"
        *ngIf="view === 'logView' || view === 'analyticsView'"
        class="geo-map map"
        [class.hidden]="intelligenceIsActiveTab"
      >
        <mat-spinner *ngIf="showMapLoader" class="dashboard-map-spinner" color="accent" diameter="40"></mat-spinner>

        <app-map
          #map
          requestedMap="vanilla-google"
          [zoom]="zoom"
          [center]="center"
          [markers]="markers$ | async"
          [circles]="circles"
          [heatmapEnabled]="heatmapEnabled"
          [trafficEnabled]="trafficEnabled"
          [clusteringEnabled]="clusteringEnabled"
          [nearbyLocationsEnabled]="nearbyLocationsEnabled"
          [buttons]="buttons"
          [showExpandBtn]="false"
          [polyLines]="polylines"
          [mapOptions]="mapOptions"
          [points]="heatmapPoints"
          [polygons]="polygons"
          [feature]="feature"
          [enableDrawing]="enableDrawing"
          (circleDrawn)="onShapeDrawn($event)"
          [enableSearching]="enableSearching"
        >
        </app-map>

        <app-day-time-selector (dataForProbabilityMarkers)="drawMarkersForProbability($event)"></app-day-time-selector>
        <div *ngIf="historyTimelineEnabled" class="dashboard-map-timeline">
          <app-timeline
            (filters)="filterTimeline($event)"
            [locations]="timelineHistoryLocations"
            [showLatestCount]="timelineInitialLimit"
            (playerStateChange)="onPlayerAction($event)"
            [showPlaybackControls]="showPlaybackControls"
            [playerState]="playerState"
          >
          </app-timeline>
        </div>
        <ng-container *ngIf="query && !query.location && recommendations?.numberOfRecords > 0 && !hideRecommendationPopup">
          <div
            class="recommendations-holder"
            *ngIf="query?.queryArgs?.telno || query?.provider?.telno as telno"
          >
            <app-recommendations-popup
              (import)="onRecommendationsImport($event)"
              [telno]="telno"
              [recommendations]="recommendations"
            ></app-recommendations-popup>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="view === 'queryView' && !isMobileResolution && (skin === 'GEO4' || skin === 'CVTP')">
    <div [class.zoom75]="isSmallDeviceResolution" class="row main-dashboard-content">
      <div class="dashboard-data">
        <app-queries
          (selectedCountry)="onSelectedCountry($event)"
          (showSearchTool)="toggleSearchTool($event)"
          (showDrawingTools)="toggleDrawingTools($event)"
          (clearQueryMap)="onClearQueryMap($event)"
          (drawAreaOfInterestFromComponent)="onDrawnAreaOfInterestFromComponent($event)"
          (emittedLocationQuery)="onEmittedLocationQuery($event)"
          [areaOfInterestDrawn]="areaOfInterestDrawn"
        >
        </app-queries>
      </div>
      <div class="dashboard-map map remove-padding-from-left-col remove-padding-from-right-col">
        <app-map
          requestedMap="vanilla-google"
          [zoom]="zoom"
          [center]="center"
          [markers]="markers$ | async"
          [circles]="circles"
          [heatmapEnabled]="heatmapEnabled"
          [trafficEnabled]="trafficEnabled"
          [clusteringEnabled]="clusteringEnabled"
          [trafficEnabled]="trafficEnabled"
          [buttons]="buttons"
          [showExpandBtn]="false"
          [polyLines]="polylines"
          [mapOptions]="mapOptions"
          [points]="heatmapPoints"
          [polygons]="polygons"
          [feature]="feature"
          [enableDrawing]="enableDrawing"
          (circleDrawn)="onShapeDrawn($event)"
          [enableSearching]="enableSearching"
        >
        </app-map>
      </div>
    </div>
  </ng-container>

  <!-- GEO4 MOBILE VIEW -->
  <mat-sidenav-container
    class="geo4-mobile-sidenav-container"
    *ngIf="isMobileResolution && (skin === 'GEO4' || skin === 'CVTP')"
  >
    <mat-sidenav #sidenav mode="over" [(opened)]="sidenavIsOpen">
      <app-mobile-sidenav (refreshMap)="refreshMap($event)"></app-mobile-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="mobile-mat-sidenav-content">
      <img class="burger-menu" (click)="sidenav.toggle()" src="assets/static/images/burger_menu.svg" height="30px" />
      <div *ngIf="!mobileShowIntel" class="map">
        <app-map
          requestedMap="vanilla-google"
          [zoom]="zoom"
          [center]="center"
          [markers]="markers$ | async"
          [circles]="circles"
          [heatmapEnabled]="heatmapEnabled"
          [trafficEnabled]="trafficEnabled"
          [clusteringEnabled]="clusteringEnabled"
          [trafficEnabled]="trafficEnabled"
          [buttons]="buttons"
          [showExpandBtn]="false"
          [polyLines]="polylines"
          [mapOptions]="mapOptions"
          [points]="heatmapPoints"
          [polygons]="polygons"
          [feature]="feature"
          [enableDrawing]="enableDrawing"
          (circleDrawn)="onShapeDrawn($event)"
          [enableSearching]="enableSearching"
        >
        </app-map>
      </div>
      <div class="query-detail">
        <div *ngIf="query && !mobileShowIntel" class="row">
          <div *ngIf="mobileShowGeo" class="geo-details">
            <app-geolocation-details></app-geolocation-details>
          </div>
          <div *ngIf="!mobileShowGeo" class="col-12 end-xs">
            <div class="telno">
              {{ query.queryArgs.telno || query.queryArgs.imsi }}
              <span
                ><button class="selected-query-mobile-close" (click)="refreshQueriesMobileView()" mat-icon-button>
                  <mat-icon>close</mat-icon>
                </button></span
              >
            </div>
          </div>
          <!-- hide buttons when geo details is showing -->
          <div *ngIf="!mobileShowGeo" class="col-12 end-xs actions">
            <button mat-raised-button class="geo" (click)="showGeoDetails()">
              <img src="assets/static/images/geolocation_details.svg" width="25" height="25" />
            </button>
            <button mat-raised-button class="resubmit" [swal]="resubmitSwal">
              <img src="assets/static/images/requery.svg" width="25" height="25" />
            </button>
            <button mat-raised-button (click)="mobileHistory(query)">
              <img src="assets/static/images/log_history.svg" width="25" height="25" />
            </button>
          </div>
          <swal
            #resubmitSwal
            title="{{ query.queryArgs.telno ? query.queryArgs.telno : query.queryArgs.imsi }}"
            [text]="'Are you sure you wish to resubmit?' | translate"
            icon="warning"
            [showCancelButton]="true"
            (confirm)="resubmitQuery(query)"
          >
          </swal>
        </div>
      </div>
      <div *ngIf="!mobileShowIntel" class="bottom-container">
        <img
          class="open"
          src="assets/static/images/open_sheet.svg"
          height="50"
          width="100"
          (click)="showLatestQueries()"
        />
        <div class="row">
          <div class="col-9">
            <app-quick-query></app-quick-query>
          </div>
          <div class="col-1">
            <app-notifications-list></app-notifications-list>
          </div>
          <div class="col-2">
            <app-credits-gauge></app-credits-gauge>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #cellTowerDetails let-tower="data">
  <div class="tower-details">
    <div class="item" *ngIf="tower.lac">
      <span>{{ 'LAC' | translate }}: {{ tower.lac }}</span>
    </div>
    <div class="item" *ngIf="tower.cellId">
      <span>{{ 'Cell Id' | translate }}: {{ tower.cellId }} </span>
    </div>
    <div class="item" *ngIf="tower.accuracy">
      <span>{{ 'Radius' | translate }}: {{ tower.accuracy }}m </span>
    </div>
    <div class="item" *ngIf="tower.distance">
      <span>{{ 'Distance from target' | translate }}: {{ tower.distance | number: '1.2-2' }}m </span>
    </div>
    <div class="item" *ngIf="tower.location">
      <span>
        {{ 'Lat/Lon' | translate }}: {{ tower.location.lat | number: '1.4-4' }} /
        {{ tower.location.lon | number: '1.4-4' }}
      </span>
      <div
        class="copy-icon"
        (mouseup)="valueCopied()"
        cdkCopyToClipboard="{{ tower.location.lat | number: '1.4-4' }} / {{ tower.location.lon | number: '1.4-4' }}"
        matTooltip="{{ 'Copy' | translate }}"
      ></div>
    </div>
  </div>
</ng-template>

<app-map-nearby-locations-window
  class="nearby-locations-component"
  *ngIf="nearbyLocationsWindow.enabled"
  [showOnMapWindowFlag]="nearbyLocationsWindow.showOnMap"
></app-map-nearby-locations-window>

<ng-template #ipLocationMarkerInfoWindow let-query="data">
  <div class="info-window ip-tooltip">
    <div class="title">
      <span class="accuracy">{{ query.accuracyMeters <= 10000 ? '4G' : '3G' }}</span>
      <span>{{'IP Location' | translate}}</span>
    </div>
    <div class="body">
      <span>{{query.ipAddress}}</span>
    </div>
  </div>
</ng-template>

<!-- CUSTOM TOOLBOX MARKER TEMPLATE -->
<ng-template 
  #customToolboxMarker
  let-label="label"
  let-showGeoMarkerToolbox="showGeoMarkerToolbox"
  let-geofencingIsEnabled="geofencingIsEnabled"
  let-cachedAntennas="cachedAntennas"
  let-followIsEnabled="followIsEnabled"
  let-pendingSchedulerAction="pendingSchedulerAction"
  >
  <app-custom-toolbox-marker
    [label]="toolboxPopup"
    [showGeoMarkerToolbox]="showGeoMarkerToolbox"
    [geofencingIsEnabled]="geofencingIsEnabled"
    [cachedAntennas]="cachedAntennas"
    [followIsEnabled]="followIsEnabled"
    [pendingSchedulerAction]="pendingSchedulerAction"
    (toggleGeoMarkerToolboxClick)="toggleGeoMarkerToolbox()"
    (toggleGeofencingTaskClick)="toggleGeofencingTask()"
    (showBTSClick)="showBTS()"
    (toggleScheduleTaskClick)="toggleScheduleTask()"
  >
  </app-custom-toolbox-marker>
</ng-template>
<ng-template 
  #customToolboxMarkerPopup
  let-label="label"
>
  <div class="info-window simple-text">
    {{ label }}
  </div>
</ng-template>

<!-- CUSTOM NEARBY LOCATION MARKER TEMPLATE -->
<ng-template
  #customNearbyMarkerContainer
  let-imageUrl="imageUrl"
  let-sourceBadge="sourceBadge"
  let-nearbyProfileId="nearbyProfileId"
>
  <app-nearby-location-custom-marker
    [imageUrl]="imageUrl"
    [sourceBadge]="sourceBadge"
    [nearbyProfileId]="nearbyProfileId"
  >
  </app-nearby-location-custom-marker>
</ng-template>

<ng-template
  #extractPeerCustomMarker
  let-query="query"
>
  <app-extract-peer-custom-marker
    [query]="query"
  >
  </app-extract-peer-custom-marker>
</ng-template>

<ng-template
  #extractPeerInfoCustomMarker
  let-query="query"
>
  <app-extract-peer-info-custom-marker
    [query]="query"
  >
  </app-extract-peer-info-custom-marker>
</ng-template>

<ng-template
  #customIPLocationMarkerContainer
  let-query="query"
>
  <app-ip-location-tooltip
    [query]="query"
  ></app-ip-location-tooltip>
</ng-template>