<div class="loader-position" *ngIf="showLoader">
  <mat-spinner class="spinner-loader" color="accent"></mat-spinner>
</div>

<div class="row justify-content-between">
  <h2 mat-dialog-title>{{ 'Search by face and name' | translate }}</h2>
  <mat-icon class="tasks-close-btn clickable" matTooltip="{{ 'Close' | translate }}" (click)="dialogRef.close()"
    >close</mat-icon
  >
</div>

<mat-dialog-content *ngIf="billingPlan$ | async as billingPlan" class="mat-typography">
  <mat-tab-group mat-align-tabs="start">
    <!-- TAB 1 -->
    <mat-tab label="{{ 'Upload image' | translate }}">
      <!-- IMAGE UPLOAD SECTION -->
      <div class="drop-section" [ngClass]="{ disabled: !!files.length }">
        <div class="drop-container" appDnd (fileDropped)="onFileDropped($event)">
          <input
            type="file"
            #fileDropRef
            id="fileDropRef"
            accept="image/x-png,image/jpg,image/jpeg"
            (change)="fileBrowseHandler($event.target.files)"
          />
          <img src="assets/img/dnd/ic-upload-file.svg" alt="" />
          <h3>{{ 'Drag image here' | translate }}</h3>
          <h3>{{ 'or' | translate }}</h3>
          <label class="upload-button" for="fileDropRef">{{ 'Choose image to upload' | translate }}</label>
        </div>
      </div>
      <div class="files-list">
        <div class="single-file" *ngFor="let file of files; let i = index">
          <img [src]="selectedImageBase64" class="icon-img" />
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
          </div>
          <img (click)="deleteFile(i)" class="delete" src="./assets/static/images/search-intel/delete-row.svg" />
        </div>
      </div>

      <!-- NAME INPUT -->
      <form class="form-field col-md-12">
        <label>{{ 'Name' | translate }}</label>
        <ng-container *ngTemplateOutlet="nameChipsInput"> </ng-container>
      </form>

      <!-- BUTTON -->
      <div class="d-flex justify-content-center">
        <button
          mat-button
          class="cp-btn-ghost cp-btn-primary cp-btn-delimiter active"
          (click)="addToImageSearch()"
          [disabled]="!selectedImageBase64?.length || !imageName"
        >
          <span class="btn-title">{{ 'Search' | translate }}</span>
          <span class="credits">{{ billingPlan.intelSearch.cost }}</span>
        </button>
      </div>
    </mat-tab>

    <!-- TAB 2 -->
    <mat-tab label="{{ 'Paste image URL' | translate }}">
      <form class="form-field col-md-12" [formGroup]="imageForm">
        <label>URL</label>
        <input type="text" formControlName="imageUrl" />
        <label>{{ 'Name' | translate }}</label>
        <ng-container *ngTemplateOutlet="nameChipsInput"> </ng-container>
      </form>

      <div class="d-flex justify-content-center">
        <button
          mat-button
          class="cp-btn-ghost cp-btn-primary cp-btn-delimiter active"
          (click)="addToSearch()"
          [disabled]="!imageForm.valid || !imageName"
        >
          <span class="btn-title">{{ 'Search' | translate }}</span>
          <span class="credits">{{ billingPlan.intelSearch.cost }}</span>
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<ng-template #nameChipsInput>
  <mat-form-field class="chips-input-wrapper">
    <mat-chip-list #name>
      <mat-chip *ngIf="imageName" (removed)="clearName()">
        {{ imageName }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        [matChipInputFor]="name"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addName($event)"
        (keydown)="onKeyDown($event)"
      />
    </mat-chip-list>
  </mat-form-field>
</ng-template>
