import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { User } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { Themes } from 'src/app/shared/models/skins.model';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';
import { TenantBillingDetails } from 'src/app/shared/models/billing-action.model';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { switchMap } from 'rxjs/operators';
import { LimitTypeLabelsMap, PoolFeaturesLabelsMap } from 'src/app/shared/models/credit-pools.model';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { combineLatest, EMPTY, Observable } from 'rxjs';

enum CreditGaugeRange {
  LOW = '0',
  MEDIUM = '21',
  HIGH = '41',
}

enum CreditGaugeColors {
  RED = 'red',
  GREY = 'grey',
  ORANGE = 'orange',
  GREEN = 'green',
  WHITE = '#ffffff',
  BLACK = '#000000',
}

@Component({
  selector: 'app-credits-gauge',
  templateUrl: './credits-gauge.component.html',
  styleUrls: ['./credits-gauge.component.scss'],
})
export class CreditsGaugeComponent extends BaseComponent implements OnInit {
  // gauge settings
  size = 30;
  thick = 2;
  gaugeType = 'full';
  gaugeValue = 0;
  gaugeAppendText = '%';
  foregroundColor = CreditGaugeColors.BLACK;
  backgroundColor = CreditGaugeColors.RED;
  thresholdConfig = {
    [CreditGaugeRange.LOW]: { color: CreditGaugeColors.RED },
    [CreditGaugeRange.MEDIUM]: { color: CreditGaugeColors.ORANGE },
    [CreditGaugeRange.HIGH]: { color: CreditGaugeColors.GREEN },
  };
  strokeWeight = 2;

  // variables
  isMobileResolution: boolean;
  isUnlimitedTheme = false;
  matomo = {
    actions: matomoActions,
    categories: matomoCategories,
  };
  creditPoolsGaugeSeries: number[] = [];
  creditPoolsGaugeLabels: string[] = [];
  gaugeLabel: string;
  creditPoolsGaugeMaxSeries: number[] = [];
  tenant: TenantBillingDetails;

  constructor(
    private ledgerService: LedgerService,
    protected userService: UserService,
    private applicationStateService: ApplicationStateService,
    private appConfigService: AppConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private translationService: TranslationService,
    public userBillingService: UserBillingService
  ) {
    super();
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    if (this.isMobileResolution) {
      this.foregroundColor = CreditGaugeColors.WHITE;
      this.backgroundColor = CreditGaugeColors.WHITE;
    }
    this.isUnlimitedTheme = this.appConfigService.getConfigVariable('theme') === Themes.UNLIMITED;
  }

  ngOnInit() {
    const isTenantLoaded$: Observable<boolean> = this.userBillingService.isTenantLoaded();
    const isTenantExpired$: Observable<boolean> = this.userBillingService.isTenantExpired();

    this.subscriptions.push(
      combineLatest([isTenantExpired$, isTenantLoaded$])
        .pipe(
          switchMap(([isTenantExpired, isTenantLoaded]) => {
            if (isTenantExpired) {
              this.gaugeValue = 0;
              return EMPTY;
            }
            return this.ledgerService.getCurrentUserLedgerItem();
          })
        )
        .subscribe((user: User) => this.getUserLedgerDetails(user))
    );
  }

  private getUserLedgerDetails(user: User) {
    this.gaugeLabel = LimitTypeLabelsMap[user.limitType]
      ? this.translationService.translate(`${LimitTypeLabelsMap[user.limitType]} quota`)
      : this.translationService.translate('Quota');

    if (this.userBillingService.isDistributedBalance()) {
      this.setCreditPoolsGage(user);
    }
    this.setGauge(user);
  }

  private setCreditPoolsGage(user: User): void {
    const availablePools: string[] = this.userBillingService
      .getAvailablePools()
      .filter((pool) => user?.initialBalance[pool.value] > 0)
      .map((pool) => pool.value);

    this.creditPoolsGaugeLabels = availablePools.map((pool) =>
      this.translationService.translate(PoolFeaturesLabelsMap[pool])
    );
    this.creditPoolsGaugeSeries = availablePools.map((pool) => user?.currentBalance[pool]);
    this.creditPoolsGaugeMaxSeries = availablePools.map((pool) => Math.max(user?.initialBalance[pool], user?.currentBalance[pool]) );
    this.changeDetectorRef.markForCheck();
  }

  setGauge(user: User) {
    const initialBalance: number = this.userBillingService.getUserInitialBalance(user);
    const currentCredits: number = this.userBillingService.getUserCurrentBalance(user);
    const from = Math.max(initialBalance, currentCredits);

    if (!currentCredits) {
      this.gaugeValue = 0;
    } else {
      this.gaugeValue = Math.floor((currentCredits / from) * 100);
    }

    this.setGaugeBackgroundColor();
  }

  private setGaugeBackgroundColor(): void {
    if (this.gaugeValue === 0) {
      this.backgroundColor = CreditGaugeColors.RED;
    } else {
      this.backgroundColor = CreditGaugeColors.GREY;
    }
    this.changeDetectorRef.markForCheck();
  }
}
