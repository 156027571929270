import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Query } from '../../models/query-item.model';

@Component({
  selector: 'app-extract-peer-info-custom-marker',
  templateUrl: './extract-peer-info-custom-marker.component.html',
  styleUrls: ['./extract-peer-info-custom-marker.component.scss']
})
export class ExtractPeerInfoCustomMarkerComponent implements OnInit {
  @Input() query: Query;
  public popupIsOpen: boolean = true;

  sectionMap: {
    userEquipment: { fields: any; hasData: boolean };
    mobileNetwork: { fields: any; hasData: boolean };
    location: { fields: any; hasData: boolean };
  } = {
    userEquipment: { fields: {}, hasData: false },
    mobileNetwork: { fields: {}, hasData: false },
    location: { fields: {}, hasData: false }
  };

  public markerPath: string = '/assets/static/images/location_on_orange.svg';

  constructor(public elRef: ElementRef) {}

  ngOnInit() {
    this.query.location.coordinates = this.query?.location?.coordinates.map(coord => Number(coord.toFixed(4)));
    this.sectionMap.userEquipment.fields.msisdn = !!this.query?.queryArgs?.telno;
    this.sectionMap.userEquipment.fields.phoneModel = !!this.query?.device?.phoneModel;
    this.sectionMap.userEquipment.fields.imei = !!this.query?.device?.imei;
    this.sectionMap.userEquipment.hasData = Object.values(this.sectionMap.userEquipment.fields).some(
      val => val === true
    );

    this.sectionMap.mobileNetwork.fields.provider = !!this.query?.provider?.name;
    this.sectionMap.mobileNetwork.fields.lac = !!this.query?.cell?.lac;
    this.sectionMap.mobileNetwork.fields.cellEnbid = !!this.query?.cell?.enbid;
    this.sectionMap.mobileNetwork.fields.cellLcid = !!this.query?.cell?.lcid;
    this.sectionMap.mobileNetwork.fields.cellId = !!this.query?.cell?.cellId;
    this.sectionMap.mobileNetwork.fields.radio = !!this.query?.cell?.cellType;
    this.sectionMap.mobileNetwork.hasData = Object.values(this.sectionMap.mobileNetwork.fields).some(
      val => val === true
    );

    this.sectionMap.location.fields.latLon = !!this.query?.location?.coordinates.length;
    this.sectionMap.location.fields.radius = !!this.query?.accuracyMeters;
    this.sectionMap.location.fields.date = !!this.query?.locationReceivedAt;
    this.sectionMap.location.fields.aol = !!this.query?.ageOfLocationInMinutes;
    this.sectionMap.location.hasData = Object.values(this.sectionMap.location.fields).some(val => val === true);
  }

  togglePopUp() {
    this.popupIsOpen = !this.popupIsOpen;
  }
}
