import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Profile } from 'datalayer/models';
import { ProfileDTO } from 'src/app/modules/data-layer/services/social/profile/profile-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: DataLayerModule
})
export class ProfileApiService extends BaseApiService<Profile, ProfileDTO> {

    constructor(httpClient: HttpClient) {
        super(httpClient, ProfileDTO);
    }

    public getModelName(): string {
        return Profile.name;
    }

}
