<ng-container *ngIf="skin === 'GEO4' || skin === 'CVTP'">
  <mat-icon
    angulartics2On="click"
    [angularticsAction]="matomo.actions.viewNotifications"
    [angularticsCategory]="matomo.categories.navBar"
    [matMenuTriggerFor]="notificationsMenu"
    class="clickable bell-icon"
    [class.mobile-geo-icon]="isMobileResolution"
    [matBadge]="notificationsCount || ''"
    matBadgeColor="warn"
    >notifications</mat-icon
  >
  <mat-menu
    #notificationsMenu="matMenu"
    class="notifications-menu container-width"
    [class.geoloc-theme]="isGeolocTheme"
    [class.white-theme]="isWhiteTheme"
  >
    <div *ngIf="hasRegularNotifications && notificationsCount > 0; else noNotifications">
      <div class="row container-width">
        <div class="action-read-all col-9">
          <button mat-menu-item (click)="markAllRead()">
            {{ 'Mark all as read' | translate }}
          </button>
        </div>
        <div class="col-3 end-xs">
          <img
            src="assets/static/images/delete.svg"
            angulartics2On="click"
            [angularticsAction]="matomo.actions.clearNotifications"
            [angularticsCategory]="matomo.categories.navBar"
            (click)="clearNotifications()"
            title="{{ 'Clear' | translate }}"
            class="clickable notifications-delete-btn"
            height="20"
          />
        </div>
      </div>
    </div>

    <div class="no-notifications dead" #noNotifications *ngIf="!notificationsCount">
      {{ 'All caught up' | translate }}
    </div>

    <div class="notifications-list">
      <app-notification-item
        *ngFor="let notification of notificationService.messages$ | async | all"
        [notification]="notification"
      >
      </app-notification-item>
    </div>
  </mat-menu>
</ng-container>

<!-- <div *ngIf="isMobileResolution && skin === 'FAMILY_FINDER'" class="mobile">
    <app-notification-item *ngFor="let notification of notificationService.messages$ | async | all" [notification]="notification">
    </app-notification-item>
  </div> -->

<!-- <div *ngIf="isMobileResolution && skin === 'FAMILY_FINDER'" class="mobile">
  <app-notification-item *ngFor="let notification of notificationService.messages$ | async | all" [notification]="notification">
  </app-notification-item>
</div> -->
