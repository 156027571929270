import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseService {

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    protected snackBar: MatSnackBar,
  ) {
    super(router, snackBar);
  }

  supportRequest(data) {
    return this.httpClient.post<any>(`${this.url}/email/support`, data)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => this.handleError(error))
      );
  }

  customerSuccessRequest(): Observable<string> {
    return this.httpClient.get<any>(`${this.url}/email/support/enable/webint`)
      .pipe(
        map((result: string) => {
          return result;
        }),
        catchError(error => this.handleError(error))
      );
  }
}
