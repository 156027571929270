import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QueryType } from 'src/app/shared/models/query-item.model';
import { QueryService } from 'src/app/services/query/query.service';
import { BaseComponent } from 'src/app/base/base.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { Angulartics2 } from 'angulartics2';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';

@Component({
  selector: 'app-proximity',
  templateUrl: './proximity.component.html',
  styleUrls: ['./proximity.component.scss']
})
export class ProximityComponent extends BaseComponent implements OnInit {
  date: string[];
  proximityFrequency: number;
  proximityForm: FormGroup;
  public todayDate = new Date();
  showError = false;
  numbersToBeQueried;
  showLengthError = false;

  constructor(
    private queryService: QueryService,
    private translationService: TranslationService,
    private dashboardService: DashboardService,
    private angulartics2: Angulartics2
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    const dateSubscription = this.queryService.dateRange.subscribe(dateRange => {
      this.date = dateRange;
      this.proximityForm.controls['startAt'].setValue(this.date[0]);
      this.proximityForm.controls['endAt'].setValue(this.date[1]);
    });
    const frequencySubscription = this.queryService.frequency.subscribe(frequency => {
      this.proximityFrequency = frequency;
      this.proximityForm.controls['frequency'].setValue(this.proximityFrequency);
    });
    const formSubmissionSubscription = this.queryService.advancedDataFlag.subscribe(flag => {
      if (flag && this.queryService.advancedQueryType.value === QueryType.PROXIMITY) {
        this.onSubmit();
      }
    });
    const clearFormsSubscription = this.queryService.clearAdvancedInputs.subscribe(flag => {
      if (flag) {
        this.proximityForm.reset();
      }
    });
    this.subscriptions.push(
      dateSubscription,
      frequencySubscription,
      formSubmissionSubscription,
      clearFormsSubscription
    );
  }

  initForm() {
    this.proximityForm = new FormGroup({
      queryType: new FormControl(QueryType.PROXIMITY),
      startAt: new FormControl('', Validators.required),
      endAt: new FormControl('', Validators.required),
      frequency: new FormControl('', Validators.required),
      radius: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    const queriesSubmission = this.queryService.numbersToBeQueried.subscribe(numbers => {
      if (numbers) {
        this.numbersToBeQueried = numbers;
      }
    });
    this.subscriptions.push(queriesSubmission);

    if (this.numbersToBeQueried.length > 1) {
      if (this.proximityForm.valid) {
        const proximityQuerySubscription = this.queryService.createProximityQuery(this.proximityForm.value).subscribe(
          () => {
            this.showMessage(this.translationService.translate('Query created successfully!'));
            this.changeView('logView');
          },
          () => {
            this.showMessage(this.translationService.translate('Query has not been created'));
          },
          () => {
            this.angulartics2.eventTrack.next({
              action: matomoActions.submitProximityQuery,
              properties: {
                category: matomoCategories.gioAdvancedQuery
              }
            });
          }
        );
        this.subscriptions.push(proximityQuerySubscription);
        // clear form
        this.queryService.advancedDataFlag.next(false);
      } else {
        this.showError = true;
      }
    } else {
      this.showLengthError = true;
    }
  }

  changeView(view) {
    this.dashboardService.componentsView.next(view);
  }
}
