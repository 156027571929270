<div class="drawer" [style.width]="options?.width">
    <div class="header">
        <div (click)="toggleState()">
            <fa-icon *ngIf="options?.headerIcon" [icon]="options?.headerIcon || minimizeIcon"></fa-icon>
            <p>{{options?.title}}</p>
        </div>
        <div>
            <button (click)="toggleState()">
                <fa-icon [icon]="minimizeIcon" [style]="{display: 'inline-block'}"></fa-icon>
            </button>
            <button (click)="expand()"> <fa-icon [icon]="expandIcon" [style]="{display: 'inline-block'}"></fa-icon>
            </button>
            <button (click)="close()"><fa-icon [icon]="closeIcon"
                    [style]="{display: 'inline-block'}"></fa-icon></button>
        </div>
    </div>
    <div [hidden]="state === States.MINIMIZED">
        <ng-template #container>

        </ng-template>
    </div>
</div>