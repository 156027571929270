<div class="recommendations-popup shadow pr-20 pl-20">
  <div class="header">
    <h3 class="rec-title fs-18 ubuntu-font text-center">
      {{ 'No locations were found for' | translate }} <br />
      {{ telno }}
    </h3>
  </div>
  <div class="body">
    <div class="icon">
      <mat-icon svgIcon="app-no-location"></mat-icon>
    </div>
    <p class="available-data-info fs-16 mb-15">
      {{ 'We found available historical data' | translate }}
      <span class="fs-16">
        {{ 'starting' | translate }}
        <strong class="fs-16">{{ recommendations.dates[0] | date }}</strong>
        {{ 'to' | translate }}
        <strong class="fs-16">{{
          recommendations.dates[recommendations.dates.length - 1] | date
        }}</strong>
      </span>
    </p>
    <p class="m-0 fs-16 mb-10">
      {{ 'To import, please select one or multiple days' | translate }}.
    </p>
    <div class="range-calendar">
      <app-recommendation-datepicker
        (selected$)="onDatePickerSelect($event)"
        [dates]="recommendations.dates"
      >
      </app-recommendation-datepicker>
      <p class="mt-15 fs-16 selected-label">
        {{ 'Selected' | translate }}:
        <strong class="font-medium fs-16" [ngPlural]="selectedDays.length">
          <ng-template ngPluralCase="=0">0 {{ 'days' | translate }}</ng-template>
          <ng-template ngPluralCase="=1"
            >{{ selectedDays.length }} {{ 'day' | translate }}</ng-template
          >
          <ng-template ngPluralCase="other"
            >{{ selectedDays.length }} {{ 'days' | translate }}</ng-template
          >
        </strong>
      </p>
    </div>
  </div>
  <hr class="mt-20" />
  <div *ngIf="billingPlan$ | async as billingPlan" class="footer p-20 flex-hor-center">
    <button
      mat-button
      class="cp-btn-primary cp-search-btn active"
      (click)="onImport()"
      [disabled]="selectedDays.length === 0 || !billingPlan?.importHistoricalLocations?.cost"
    >
      <span class="btn-txt">{{ 'Import' | translate }}</span>
      <ng-container *ngIf="billingPlan?.importHistoricalLocations?.cost">
        <span>|</span>
        <span class="credits assistant-font">{{
          selectedDays.length * billingPlan.importHistoricalLocations.cost
        }}</span>
      </ng-container>
    </button>
  </div>
</div>
