import { Album as PlatformAlbum } from 'datalayer/models/platform-models';
import { BaseTargetEntity } from '../platform-models/base/base-target-entity';

export class Album extends PlatformAlbum implements BaseTargetEntity {
  targetId: string;

  constructor(model?: Partial<Album>) {
    super();

    if (model) {
      Object.assign(this, model);
    }

  }
}
