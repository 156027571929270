import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityType } from '@trg-commons/data-models-ts';
import { Album, DataSource, EntityRelationType, FeedbackStats, Media, Profile } from 'datalayer/models/platform-models';
import { RequestOptions } from 'datalayer/services/base';
import { AlbumService } from 'datalayer/services/social/album/album.service';
import { ProfileService } from 'datalayer/services/social/profile/profile.service';
import { format } from 'date-fns';
import saveAs from 'file-saver';
import _, { head } from 'lodash';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { LinkAnalysisDataService } from 'src/app/modules/link-analysis/services/link-analysis-data.service';
import { Circle, IconMarker, MapOptions, Marker, Point } from 'src/app/modules/mapV2/models/map.models';
import { ReactorProfile } from 'src/app/modules/profiler/components/media-carousel-dialog/media-carousel-dialog.component';
import {
  RelatedPeopleActionModel,
  RelatedPeopleDialogComponent,
  RelatedPeopleDialogModel
} from 'src/app/modules/profiler/components/related-people-dialog/related-people-dialog.component';
import { MarkerIcon } from 'src/app/modules/profiler/modules/profiler-map/models/marker-icons.enum';
import { ProfilerActionsListModel } from 'src/app/modules/profiler/shared/models/profiler-action-list.model';
import { PlatformImages } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { ImageService } from 'src/app/services/image/image.service';
import { MapHelperService } from 'src/app/services/map-helper/map-helper.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { MessageSubject } from 'src/app/services/websocket/message-subject.model';
import { ServerPyWsService } from 'src/app/services/websocket/server-py-websocket.service';
import { Message } from 'src/app/services/websocket/websocket.class';
import { Action } from 'src/app/shared/classes/action.class';
import { BaseModalHeader } from 'src/app/shared/components/base-modal-component/base-modal-component.component';
import { FeedItem, FeedType, SourceEntity } from 'src/app/shared/models/feed.model';
import { Query } from 'src/app/shared/models/query-item.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { ActionService } from 'src/app/shared/services/action.service';
import { environment } from 'src/environments/environment';
import { SocialPlatformMap } from '../../models/social-platform-map';
import { Platform } from '../../schemas/common/platforms';

export class EntityDetailsDialogModel {
  feed?: FeedItem;
  type?: FeedType;
  imgWidth?: number;
  platform?: Platform;
  url?: string;
  image?: string;
  media?: string;
  targetId?: string;
  publishedAt?: Date;
  description?: string;
  content?: string;
  feedbackStats?: FeedbackStats;
  title?: string;
  articleTitle?: string;
  queryDate?: Date;
  sourceEntity?: SourceEntity;
}
@Component({
  selector: 'app-entity-details-dialog',
  templateUrl: './entity-details-dialog.component.html',
  styleUrls: ['./entity-details-dialog.component.scss'],
})
export class EntityDetailsDialogComponent extends BaseComponent implements OnInit, OnChanges {
  index: number = 0;
  speed: number = 1000;
  infinite: boolean = true;
  direction: string = 'right';
  autoplay: boolean = false;
  photos: Media[];
  headerDetails: BaseModalHeader;
  correlationId: string;
  likers: ReactorProfile[];
  commenters: ReactorProfile[];
  fileManagerUrl = environment.fileManagerUri;
  showAllLikers = false;
  showAllCommenters = false;
  platformImages = PlatformImages;
  profile: Profile;
  photo: string;
  feed: any;
  allPlatformDetails: Profile[] = [];
  profileImage: string;
  profileUrl: string;
  copyToClipboard: string;

  phoneInfo = [];
  callInfo = [];
  mobileInfo = [];
  locationInfo = [];
  isTelcel: Boolean = false;
  markers: Marker[] = [];
  circles: Circle[] = [];
  zoom = 5;
  center: Point;
  trafficEnabled = false;
  query: Query;
  target: TargetItem;
  protected legacyFilesUri: string = environment.serverAPIUri;
  type: FeedType;
  basicImageSrc = 'assets/static/images/';
  serviceIsRunning = false;
  mapOptions: MapOptions = new MapOptions({
    disableDefaultUI: true,
    minZoom: 15,
  });
  imgWidthVal: number;

  loading: boolean = false;
  likersIsActive: boolean = false;
  commentersIsActive: boolean = false;

  // for popup widget
  showPopup: boolean = false;
  socialImg: string;
  paginator = {
    pageSize: 4,
    currentPage: 0,
    totalSize: 0,
  };
  paginationNextPage;
  paginationPreviousPage;
  addToTarget = false;
  queueTargetsDictionary: { [value: string]: boolean } = {};
  targetsQueue: TargetItem[] = [];
  popupData;
  searchText = new FormControl();
  targets: TargetItem[];
  filterTargets = new Subject();
  targetId: string;
  imgLink: string;
  somedusDetails: any[] = [];
  album: Album;

  constructor(
    public dialogRef: MatDialogRef<EntityDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public entityDetails: EntityDetailsDialogModel,
    private imageService: ImageService,
    private translationService: TranslationService,
    private linkAnalysisDataService: LinkAnalysisDataService,
    private serverPyWsService: ServerPyWsService,
    private actionService: ActionService,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private queryService: QueryService,
    private mapHelperService: MapHelperService,
    private userBehaviorService: UserBehaviorService,
    private albumService: AlbumService
  ) {
    super();
  }

  ngOnInit() {
    if (!!this.entityDetails) {
      this.setPhotos();
      this.setHeaderDetails();
    }
    if (this.entityDetails.type === FeedType.LOCATION) {
      this.showQueryDetails();
    } else if (this.entityDetails?.feed?.feed?.location) {
      setTimeout(() => {
        this.showFeedLocation();
      }, 1000);
    }
    if (this.entityDetails?.feed?.feed?.albumId) {
      this.getAlbumData(this.entityDetails?.feed?.feed?.albumId);
    }

    if (
      this.entityDetails.platform === Platform.FACEBOOK &&
      (this.entityDetails.type === FeedType.POST  || this.entityDetails.type === FeedType.IMAGE )
    ) {
      this.subscribeToWS();
      this.getReactors();
    }
  }

  ngOnChanges() {
    if (
      this.entityDetails.platform === Platform.FACEBOOK &&
      (this.entityDetails.type === FeedType.POST  || this.entityDetails.type === FeedType.IMAGE )
    ) {
      this.subscribeToWS();
      this.getReactors();
    }
  }

  private setPhotos(): void {
    this.photo = this.entityDetails.image
      ? this.getPhoto(this.entityDetails.image)
      : this.entityDetails.media
      ? this.getPhoto(head(this.entityDetails.media))
      : this.getPhoto('');
    this.feed = this.entityDetails?.feed?.feed;
    this.getAllProfiles();
  }

  private setProfileUrl(sourceEntity: any) {
    let profileUrl = '';
    if (sourceEntity) {
      let id: Profile[] = this.allPlatformDetails.filter((i) => i.sourceEntity.id === sourceEntity.parentId);
      profileUrl = id.length && id[0]?.url ? id[0].url : '';
    }
    return profileUrl;
  }

  private setPlatfromDisplayPicutre(sourceEntity: any): string {
    let imageUrl = '';
    if (sourceEntity) {
      let id: Profile[] = this.allPlatformDetails.filter((i) => i.sourceEntity.id === sourceEntity.parentId || `${i?.username}@${SocialPlatformMap[i?.source]}` === sourceEntity.parentId);
      imageUrl = id.length && id[0]['image'] ? id[0]['image']['url'] : 'assets/static/images/user.svg';
    }
    return imageUrl;
  }

  private setHeaderDetails() {
    this.headerDetails = {
      label: this.entityDetails.type.toUpperCase(),
      platform: {
        id: DataSource[_.startCase(this.entityDetails.platform)],
        url: this.entityDetails.url,
      },
    };
  }

  private setProfileData(sourceEntity: any): Profile {
    let profile: Profile = null;
    if (sourceEntity) {
      let id: Profile[] = this.allPlatformDetails.filter((i) => i.sourceEntity.id === sourceEntity.parentId);
      profile = id.length && id[0] ? id[0] : null;
    }
    return profile;
  }

  getPhoto(media: string, isThumbnail: boolean = false): string {
    return <string>(media ? this.imageService.getPhotoUrl(media, isThumbnail) : 'assets/static/images/user.svg');
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  gotoUrl(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  showRelatedPeople(reactorProfile: ReactorProfile): void {
    let profile: Profile = {
      url: reactorProfile.url,
      username: reactorProfile.username,
      name: reactorProfile.name,
      imageUrl: reactorProfile.photo ? `${this.fileManagerUrl}/file/${reactorProfile.photo}` : '',
      source: DataSource.Facebook,
    };
    const dialogRef = this.dialog.open(RelatedPeopleDialogComponent, {
      data: <RelatedPeopleDialogModel>{
        profile: profile,
        hasParent: true,
      },
      panelClass: 'related-people-dialog',
    });

    dialogRef.componentInstance.performAction.subscribe((action) => {
      switch (action.key) {
        case RelatedPeopleActionModel.CREATE_TARGET:
          this.actionService.publishAction(
            new Action({
              key: ProfilerActionsListModel.CREATE_OR_UPDATE_TARGET,
              data: profile,
            })
          );
          break;
        case RelatedPeopleActionModel.ADVANCED_OSINT:
          this.actionService.publishAction(
            new Action({
              key: ProfilerActionsListModel.ADVANCED_OSINT,
              data: profile,
            })
          );
          break;
      }
      dialogRef.close();
    });

    dialogRef.componentInstance.closePressed.subscribe((isClose) => {
      if (isClose) this.dialogRef.close();
    });
  }

  private getReactors() {
    if (this.entityDetails.type === 'image') {
      const index = this.entityDetails.feed.referenceId.indexOf('/file/');
      const fileId = this.entityDetails.feed.referenceId.substr(index + 6, this.entityDetails.feed.referenceId.length);
      this.correlationId = this.linkAnalysisDataService.createFilterQuery(
        this.entityDetails.targetId,
        MessageSubject.DeepOsintPhotoDetailsRequest,
        undefined,
        fileId
      );
    } else if (this.entityDetails.type === 'post') {
      const postId = this.entityDetails?.feed?.feed?.id.substring(
        this.entityDetails?.feed?.feed?.id.indexOf(':') + 1,
        this.entityDetails?.feed?.feed?.id.lastIndexOf(':')
      );
      this.correlationId = this.linkAnalysisDataService.createFilterQuery(
        this.entityDetails.targetId,
        MessageSubject.DeepOsintPostDetailsRequest,
        undefined,
        undefined,
        postId
      );
    }
  }

  private subscribeToWS() {
    this.subscriptions.push(
      this.serverPyWsService.getMessage().subscribe((message: Message) => {
        this.handleWsMessage(message);
      })
    );
  }

  private handleWsMessage(message?: Message) {
    const validSubjects = [MessageSubject.DeepOsintPhotoDetailsResponse, MessageSubject.DeepOsintPostDetailsResponse];
    if (validSubjects?.includes(message.subject) && this.correlationId === message.body.correlationId) {
      message.body?.payload.forEach((entry) => {
        if (entry.relationType === EntityRelationType.Liked) {
          this.likers = entry.profiles;
        } else if (entry.relationType === EntityRelationType.Commented) {
          this.commenters = entry.profiles;
        }
      });
    }
  }

  private getAllProfiles() {
    const filters: RequestOptions = {
      filters: {
        source: [
          DataSource.WhatsApp,
          DataSource.Skype,
          DataSource.Telegram,
          DataSource.Tinder,
          DataSource.Truecaller,
          DataSource.CallerID,
          DataSource.Viber,
          DataSource.Wechat,
          DataSource.Twitter,
          DataSource.Facebook,
          DataSource.Instagram,
          DataSource.LinkedIn,
          DataSource.Youtube,
          DataSource.Tiktok,
        ],
        targetId: this.entityDetails.targetId,
        type: EntityType.Profile,
        relationType: [EntityRelationType.Plain],
      },
    };

    this.subscriptions.push(
      this.profileService.getAll(filters).subscribe((result: { [key: string]: Profile }) => {
        if (Object.values(result).length) {
          this.allPlatformDetails = Object.values(result);
          this.profileImage = this.getPhoto(this.setPlatfromDisplayPicutre(this.entityDetails.sourceEntity));
          this.profileUrl = this.setProfileUrl(this.entityDetails.sourceEntity);
          this.profile = this.setProfileData(this.entityDetails.sourceEntity);
        }
      })
    );
  }

  copyValue(event) {
    this.copyToClipboard = event;
    this.showMessage(this.translationService.translate('Copied'));
  }

  private showQueryDetails() {
    const geolocationData = this.queryService.getGeolocationDetails(this.entityDetails?.feed?.feed);
    this.query = this.entityDetails?.feed?.feed;
    this.phoneInfo = geolocationData.phoneInfo;
    this.mobileInfo = geolocationData.mobileInfo;
    this.locationInfo = geolocationData.locationInfo;
    this.callInfo = geolocationData.callInfo;
    this.isTelcel = this.query.provider && this.query.provider.name === 'Telcel' ? true : false;

    this.addLocationMarker();
  }

  private addLocationMarker() {
    const target = this.query.queryArgs ? this.query.queryArgs.telno : this.query.queryArgs.imsi;

    if (this.query.location) {
      const marker = new IconMarker({
        id: `${this.query.id}geolocation_dialog`,
        lat: this.query.location.coordinates[1],
        lng: this.query.location.coordinates[0],
        iconUrl: MarkerIcon.Default,
        isPopupWindowOpen: true,
        popupHTML: `${target}`,
      });
      this.markers.push(marker);
      // This is needed untill MapModuleV2 is present in the mapHelperService
      this.circles = this.mapHelperService.showQueryAccuracyCircles(this.query) as Circle[];
    }

    this.markers = [...this.markers];
    this.circles = [...this.circles];
  }

  showFeedLocation() {
    if (this.entityDetails?.feed?.feed?.location && this.entityDetails?.feed?.feed?.location?.coordinates) {
      const marker = new IconMarker({
        id: `${this.entityDetails.feed.referenceId}feed_detail_dialog`,
        lat: this.entityDetails?.feed?.feed?.location?.coordinates[1],
        lng: this.entityDetails?.feed?.feed?.location?.coordinates[0],
        iconUrl: MarkerIcon.Default,
        isPopupWindowOpen: false,
      });

      this.markers.push(marker);
      this.markers = this.markers.slice();
    }
  }

  geolocExportPdf() {
    this.userBehaviorService.userBehavior('geo_export_location_details_pdf').subscribe();
    this.queryService.createPdfReport(this.query).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const { queryArgs = {} } = this.query;
      const formattedDate = format(new Date(), 'DDMMYYYY_HHmmss');
      saveAs(blob, `${this.queryService.stripPlusSign(queryArgs.telno) || queryArgs.imsi}_${formattedDate}.pdf`);
    });
  }

  async chargeCallInfoGeoQuery() {
    this.serviceIsRunning = true;
    this.sleep(2000).then();
    this.queryService.chargeCallInfoGeoQuery(this.query.id).subscribe(
      (query) => {
        if (query.id === this.query.id && query.callInfo) {
          this.query.callInfo = query.callInfo;
          this.showQueryDetails();
        }
        this.serviceIsRunning = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  sleep(duration): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, duration);
    });
  }

  private getAlbumData(albumId: string) {
    const albumFilters: RequestOptions = {
      filters: {
        source: [DataSource.Facebook],
        targetId: this.entityDetails.targetId,
        type: EntityType.Album,
      },
    };

    this.subscriptions.push(
      this.albumService
        .getAll(albumFilters)
        .pipe(map((result: { [key: string]: Album }) => Object.values(result)))
        .subscribe((albums: Album[]) => {
          albums = albums.filter((album: Album) => album.albumId === albumId);
          this.album = head(albums);
        })
    );
  }

  openIncognito(url) {
    window.open(url, 'Target Note', 'resizable,scrollbars,status,incognito');
  }
}
