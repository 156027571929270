import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Education } from 'datalayer/models';
import { EducationDTO } from 'src/app/modules/data-layer/services/social/education/education-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule
})

export class EducationApiService extends BaseApiService<Education, EducationDTO> {

  constructor(httpClient: HttpClient) {
    super(httpClient, EducationDTO);
  }

  public getModelName(): string {
    return Education.name;
  }

}
