import { Entity } from '../base';
import { Gender } from '../enums';
import { Media } from 'datalayer/models/platform-models';

export class Person extends Entity {
  public names?: string[];
  public emails?: string[];
  public gender?: Gender;
  public addresses?: {}[];
  public ethnicities?: string[];
  public languages?: string[];
  public urls?: string[];
  public originCountries?: string[];
  public telnos?: string[];
  public usernames?: string[];
  public userIds?: string[];
  public landTelnos?: string[];
  public image?: Media;
  public cities?: string[];
  public postcode?: string;
  public voterId?: string;
  public jobs?: string[];
  public motherNames?: string[];
  public fatherNames?: string[];
  public ife?: string[];
  public curp?: string[];
  public rfc?: string[];
  public dateOfBirth?: string;
  public placeOfBirth?: string[];
  public groups?: string[];
  public skills?: string[];
  public volunteering?: string[];
  public courses?: string[];
  public propertyType?: string[];
  public homeLastSaleDate?: string[];
  public vehicleIdNumber?: string[];
  public vehicleModel?: string[];
  public vehicleMake?: string[];
  public vehicleYear?: string[];
  public associatedDomain?: string[];
  public fanOf?: string[];
  public otherTags?: string[];
  public industry?: string[];
  public about?: string[];
  public followersCount?: number;
  public followingCount?: number;
  public connectionsCount?: number;
  public relation?: string;
  public subRelation?: string;
  public ipAddresses?: string[];
  public hairColor?: string;
  public eyesColor?: string;
  public height?: string;
  public weight?: string;
}
