import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MarkerSectorService } from '@app/services';
import { Angulartics2 } from 'angulartics2';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { DataSource } from 'datalayer/models/platform-models';
import { format } from 'date-fns';
import { head, isEqual, uniqBy } from 'lodash-es';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, of, Subject, Subscription, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  catchError,
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  retryWhen,
  skip,
  switchMap,
  tap
} from 'rxjs/operators';
import { AdvancedGeolocationQueryConfirmationDialogComponent } from 'src/app/components/advanced-geolocation-query-confirmation-dialog/advanced-geolocation-query-confirmation-dialog.component';
import { ImportDataRequestDialogComponent } from 'src/app/components/analytics/import-data-request-dialog/import-data-request-dialog.component';
import { BottomSheetMobileComponent } from 'src/app/components/bottom-sheet-mobile/bottom-sheet-mobile.component';
import { MapComponent } from 'src/app/modules/mapV2/components/map/map.component';
import {
  AnchorPosition,
  Button,
  ButtonState,
  Circle,
  ControlPosition,
  DrawMode,
  DrawModeOptions,
  Feature,
  IconMarker,
  MapOptions,
  Marker,
  Point,
  Polygon,
  Polyline,
  TemplateMarker
} from 'src/app/modules/mapV2/models/map.models';
import { PLAY_BACK_MARKER_ID } from 'src/app/modules/profiler/modules/profiler-map/profiler-map/profiler-map.component';
import { SearchIntelService } from 'src/app/modules/search-intel/services/search-intel.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AnalyticsService, IMPORT_DATA_THRESHOLD_PER } from 'src/app/services/analytics/analytics.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { AuthDataService } from 'src/app/services/authentication/auth-data.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { DashboardService, DashboardView } from 'src/app/services/dashboard/dashboard.service';
import { ImageService } from 'src/app/services/image/image.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { MapHelperService } from 'src/app/services/map-helper/map-helper.service';
import { NearbyLocationsService } from 'src/app/services/nearby-locations.service';
import { NearbyLocationsStore } from 'src/app/services/nearby-locations.store';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { IpLocationQueryMarkerGenerator } from 'src/app/services/query/ip-location-query.service';
import { IpLocationService } from 'src/app/services/query/ip-location.service';
import { ExtractPeerMarkerGenerator } from 'src/app/services/query/query-extract-peer-marker-generator.service';
import { QueryService } from 'src/app/services/query/query.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { TacticalService } from 'src/app/services/tactical/tactical.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { User } from 'src/app/services/user/user.model';
import { WsService } from 'src/app/services/websocket/ws.service';
import { Action } from 'src/app/shared/classes/action.class';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { ScheduleActivationDialogComponent } from 'src/app/shared/components/schedule-activation-dialog/schedule-activation-dialog.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { NearbyLocationWindowState } from 'src/app/shared/models/nearby-locations-window-state.interface';
import {
  NearbyLocationsBodyMsg,
  NearbyLocationsProfile,
  NearbyLocationsRequest,
  TrilaterationNearbyLocationRequest
} from 'src/app/shared/models/nearby-locations.model';
import { IntervalMinutes, Query, QueryStatus, QueryType, SortMode } from 'src/app/shared/models/query-item.model';
import { DiscoveryTimelineIconMarker } from 'src/app/shared/modules/map-shared/models/discovery-timeline-marker';
import { RecommendationResponseBody } from 'src/app/shared/modules/recommendations/models/socket-responses.interface';
import { RecommendationImportService } from 'src/app/shared/modules/recommendations/recommendation-import.service';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';
import Swal from 'sweetalert2';
import { environment } from './../../../environments/environment.prod';
import { VanillaGoogleMapComponent } from './../../modules/mapV2/vanilla-google-map/vanilla-google-map.component';
import { PlayerState } from './../../modules/profiler/components/timeline/timeline.component';
import { HistoryPlayback } from './../../shared/classes/history-playback.class';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('customToolboxMarker', { static: true }) customToolboxMarkerTemplate: TemplateRef<any>;
  @ViewChild('customToolboxMarkerPopup', { static: true }) customToolboxMarkerPopupTemplate: TemplateRef<any>;
  @ViewChild('map') mapComponent: MapComponent;

  @ViewChild('geoTableLog', { read: ElementRef }) geoTableLog: ElementRef;
  @ViewChild('geoTableSideContent', { read: ElementRef }) geoTableSideContent: ElementRef;

  @ViewChild('customNearbyMarkerContainer', { static: true }) customNearbyMarkerTemplate: TemplateRef<any>;
  @ViewChild('customIPLocationMarkerContainer', { static: true }) customIPLocationMarkerTemplate: TemplateRef<any>;
  @ViewChild('cellTowerDetails', { static: true }) cellTowerDetails: TemplateRef<any>;
  @ViewChild('ipLocationMarkerInfoWindow', { static: true })
  ipLocationMarkerInfoWindow: TemplateRef<any>;
  @ViewChild('extractPeerInfoCustomMarker', {static: true}) extractPeerInfoCustomMarker: TemplateRef<{query: Query}>;
  @ViewChild('extractPeerCustomMarker', {static: true}) extractPeerCustomMarker: TemplateRef<{query: Query}>;

  private onSelectedQueryStream$: Subject<Query> = new Subject<Query>();
  view = 'logView';
  drawingToolsContainer;
  query: Query;
  skin;
  intelligenceIsActiveTab = false;
  selectedSecondaryTab = 0;
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';
  subscriptions: Subscription[] = [];
  // hide intel tab if there was not an intel query for this telno in the past
  showIntelTab = false;
  enableAdvancedGeolocationPopup: boolean;
  enabledAnalytics: boolean;
  conversationData;
  // geo custom toolbox marker variables
  showGeoMarkerToolbox = true;
  activeSchedules = { telno: {}, imsi: {} };
  activeGeofences = { telno: {}, imsi: {} };
  followIsEnabled = false;
  geofencingIsEnabled = false;
  cachedAntennas = false;
  toolboxPopup: string;

  // mobile view toggles
  isMobileResolution: boolean;
  mobileShowGeo = false;
  mobileShowIntel = false;
  sidenavIsOpen = false;

  currentCredits: number = 0;
  isGeolocTheme: Boolean = false;
  isWhiteTheme: Boolean = false;

  pendingSchedulerAction: boolean = false;
  private markersChange: BehaviorSubject<Marker[]> = new BehaviorSubject<Marker[]>([]);
  public markers$: Observable<Marker[]> = this.markersChange.asObservable();
  polylines: Polyline[] = [];
  zoom = 5;
  center: Point;
  circles: Circle[] = [];
  polygons: Polygon[] = [];
  historyMarkers: Marker[] = [];
  heatmapEnabled = false;
  trafficEnabled = false;
  historyTimelineEnabled = false;
  clusteringEnabled = false;
  enableDrawing: DrawMode | DrawModeOptions = DrawMode.None;
  enableSearching = false;
  buttons: Button[] = [];
  feature: Feature;
  callLogClusteringData: any;
  trafficButton: Button;
  areaOfInterestDrawn: Circle;
  previousEnabledCustomControl: Button;
  isSmallDeviceResolution = false;
  theme: string;
  timelineHistoryLocations: Query[] = [];
  showMapLoader = false;
  heatmapPoints: Point[] = [];
  targetPopup = false;
  wasInside: boolean;
  filesDownloadState: boolean = false;
  nearbyLocationsWindow: NearbyLocationWindowState = { enabled: true, showOnMap: false };
  selectedQueries: Query[] = [];
  timelineInitialLimit = 100;

  mapOptions: MapOptions = new MapOptions({
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      position: google.maps.ControlPosition.TOP_LEFT,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    scaleControl: false,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
  });
  btnPosition: number;
  sideNav: boolean = false;
  currentUser: boolean;
  recommendations: RecommendationResponseBody | null = null;
  playerState: PlayerState = 'stopped';
  showPlaybackControls: boolean = true;

  hideRecommendationPopup: boolean = false;

  private historyPlayback: HistoryPlayback = new HistoryPlayback();
  private lastHistoryPlaybackMarkerIndex = 0;
  private readonly baseImagePath: string = '/assets/static/images/';
  private customPolylinePath: google.maps.Polyline;
  private previousQuery: Query;

  get googleMapComponent(): VanillaGoogleMapComponent {
    return this.mapComponent ? this.mapComponent.getVanillaGoogleMap() : null;
  }
  geoMapWidth: number;
  private extractPeerMarkerGenerator: ExtractPeerMarkerGenerator;
  private ipLocationQueryMarkerGenerator: IpLocationQueryMarkerGenerator;
  constructor(
    private datePipe: DatePipe,
    private dashboardService: DashboardService,
    private authSessionService: AuthService,
    private authService: AuthDataService,
    public notificationService: NotificationService,
    private queryService: QueryService,
    private router: Router,
    private applicationStateService: ApplicationStateService,
    private bottomSheet: MatBottomSheet,
    public snackBar: MatSnackBar,
    private ledgerService: LedgerService,
    private tacticalService: TacticalService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private mapHelperService: MapHelperService,
    private userBehaviorService: UserBehaviorService,
    private analyticsService: AnalyticsService,
    public dialog: MatDialog,
    private webSocketService: WsService,
    private roleManagementService: RoleManagementService,
    private angulartics2: Angulartics2,
    private appConfigService: AppConfigService,
    private nearbyLocationsStore: NearbyLocationsStore,
    private nearbyLocationsService: NearbyLocationsService,
    private imageService: ImageService,
    private recommendationImportService: RecommendationImportService,
    private markerSectorService: MarkerSectorService,
    private searchIntelService: SearchIntelService,
    private userBillingService: UserBillingService,
    private ipLocationService: IpLocationService,
  ) {
    super();
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.enabledAnalytics = this.appConfigService.getConfigVariable('enabledAnalytics');
    this.enableAdvancedGeolocationPopup = this.appConfigService.getConfigVariable('enableAdvancedGeolocationPopup');
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    // Check current User
    this.currentUser = !(
      this.roleManagementService.userIsAdmin() ||
      this.roleManagementService.userIsSupportUser() ||
      this.roleManagementService.userIsPowerUser()
    );

    const isTenantLoaded$: Observable<boolean> = this.userBillingService.isTenantLoaded();

    this.dashboardService.btnPosition.subscribe((position) => {
      this.btnPosition = position - 65;
    });

    this.isSmallDeviceResolution = this.applicationStateService.getIsSmallDeviceResolution();
    if (this.theme === 'GEOLOC') {
      this.isGeolocTheme = true;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    if (!this.isMobileResolution) {
      this.addTrafficButton();
    }

    this.subscription = this.dashboardService.componentsView.subscribe((data: string) => {
      if (!this.queryService.multiquerySelection.getValue().length || data === 'queryView') {
        this.refreshMap();
      }
      this.view = data;

      if (this.view === DashboardView.LOG) {
        this.enableDrawing = DrawMode.None;
      }

      this.cdr.markForCheck();
    });

    this.subscription = this.applicationStateService.skin.subscribe((skin: string) => {
      this.skin = skin;
    });

    this.subscription = this.queryService.onQuerySelection.subscribe((query: Query) => {
      if (!query) {
        return;
      }
      this.query = query;
      this.previousQuery = null;
      this.updateActiveTasks(query);
      this.cachedAntennasCheck(query);
      this.calculateGeoMapWidth(true);
    });

    this.subscription = this.recommendationObs();

    this.subscription = this.dashboardService.showIntelTabContent.subscribe((flag) => {
      if (flag) {
        this.selectedSecondaryTab = 1;
      }
      this.intelligenceIsActiveTab = flag;
    });

    this.subscription = this.dashboardService.showLogGeoTab.subscribe((flag) => {
      if (flag) {
        this.selectedSecondaryTab = 0;
        this.intelligenceIsActiveTab = false;
      }
    });

    this.subscription = this.dashboardService.showLogTab.subscribe((flag) => {
      if (flag) {
        this.selectedSecondaryTab = 0;
        this.hideAllTabContent();
      }
    });

    this.subscription = this.dashboardService.showMobileGeoDetails.subscribe((flag: boolean) => {
      this.mobileShowGeo = flag;
      this.mobileShowIntel = false;
    });

    this.subscription = this.dashboardService.showIntelTab.subscribe((flag) => {
      this.showIntelTab = flag;
    });

    this.subscription = this.tacticalService.selectedConversation.subscribe((conversation) => {
      this.conversationData = conversation;
    });

    this.subscription = isTenantLoaded$
      .pipe(switchMap(() => this.ledgerService.getCurrentUserLedgerItem()))
      .subscribe((user: User) => (this.currentCredits = this.userBillingService.getUserCurrentBalance(user)));

    this.subscription = this.dashboardService.toggleSidenav.subscribe((flag: boolean) => {
      this.sidenavIsOpen = flag;
      this.mobileShowIntel = false;
    });

    this.subscription = this.nearbyLocationsStore.storeUpdated$.pipe(skip(1)).subscribe(() => {
      if (this.selectedQueries.length) {
        this.nearbyLocationsStore.filterNearbyLocations([...this.selectedQueries.map((query) => query.id)]);
      }
    });

    this.subscription = this.nearbyLocationsStore.getCloseNearbyLocationWindow().subscribe((val: NearbyLocationWindowState) => {
      this.closeNearbyLocationWindow(val);
    });

    this.subscription = this.nearbyLocationsStore
      .getNearbyPeopleToggle()
      .pipe(distinctUntilChanged(isEqual))
      .subscribe((state: { queryIds: string[]; show: boolean }) => {
        if (state.show) {
          this.collectDataForTelegramMarkers(state.queryIds);
        } else {
          this.removeNearbyPeopleMarkers(state.queryIds);
        }
      });

    this.subscription = this.nearbyLocationsStore
      .getProfilesForTrilateration()
      .pipe(
        map((selectedProfiles: NearbyLocationsProfile[]) => this.getNearbyProfilesQueryParent(selectedProfiles)),
        switchMap((data: { profiles: NearbyLocationsProfile[]; queryParent: Query }) =>
          this.nearbyLocationsService.applyTrilaterationOnNearbyLocation(this.generateTrilaterationParams(data)).pipe(
            catchError((error) => {
              this.nearbyLocationsStore.setStateTrilaterationRequests([data.queryParent.id], JobStatus.DONE);
              throw error;
            })
          )
        ),
        retryWhen(errors =>
          errors.pipe(
            tap(e =>  {
              const errorBody: { message?: string } = head(e?.error?.errors);
              const message = errorBody?.message || 'Telegram users cannot be located now!';
              this.showMessage(this.translationService.translate(message));
            }),
          )
        ),
      )
      .subscribe();

    this.subscription = this.nearbyLocationsStore
      .getTrilaterationNearbyPeopleResponse()
      .subscribe((result: NearbyLocationsBodyMsg) => this.handleTrilaterationResult(result));

    this.subscription = combineLatest([this.queryService.multiquerySelection, this.queryService.onQuerySelection])
      .pipe(
        skip(1),
        map(([queries, query]: [Query[], Query]) => [...queries, query]),
        map((queries: Query[]) => queries.filter(Boolean)),
        map((queries: Query[]) => uniqBy(queries, 'id')),
        tap((queries: Query[]) => (this.selectedQueries = queries))
      )
      .subscribe((queries: Query[]) => {
        if (queries.length) {
          this.emitNewValuesToNearbyWindow(queries);
        }
      });

    this.subscription = this.webSocketService.onEvent('state-update-advanced-geolocation').subscribe((data) => {
      if (this.isMobileResolution && this.enableAdvancedGeolocationPopup) {
        this.dialog.open(AdvancedGeolocationQueryConfirmationDialogComponent, {
          width: this.isMobileResolution ? '90vw' : '30vw',
          height: 'auto',
          disableClose: true,
          data,
          panelClass: [
            'advanced-query-geolocation-dialog',
            // geo4-theme is a mock class name. We need to pass a valid string because the empty string breaks the implementation
            this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : 'geo4-theme',
          ],
        });
      }
    });

    this.subscriptions.push(
      this.searchIntelService.searchText.pipe(skip(1)).subscribe(() => {
        this.hideRecommendationPopup = true;
      })
    );

    this.subscriptions.push(
      this.onSelectedQueryStream$
        .pipe(debounceTime(500))
        .subscribe((query: Query) => this.catchLastSelectedQuery(query))
    );

    this.subscriptions.push(this.createIpLocationQuery());
  }

  private handleTrilaterationResult(result: NearbyLocationsBodyMsg) {
    const profiles = transformSnakeToCamel(result.profiles);
    if (profiles.length) {
      this.setTrilaterationRequestState([result.geo_query_id]);
      const markers = [];
      profiles.forEach((profile) => {
        this.nearbyLocationsStore.updateNearbyLocationStore(profile);
        markers.push(this.buildNearbyLocationMarker(profile));
      });
      this.addNearbyLocationMarkers(markers);
      this.showMessage(this.translationService.translate('Telegram users located successfully!'));
    } else {
      this.setTrilaterationRequestState([result.geo_query_id]);
      this.showMessage(this.translationService.translate('No location found for Telegram users!'));
    }
  }

  private setTrilaterationRequestState(queryIds: string[]): void {
    this.nearbyLocationsStore.setStateTrilaterationRequests(queryIds, JobStatus.DONE);
  }

  private emitNewValuesToNearbyWindow(queries: Query[]): void {
    this.nearbyLocationsStore.filterNearbyLocations([...queries.map((query) => query.id)]);
  }

  private collectDataForTelegramMarkers(querieIds: string[]) {
    const savedProfiles: NearbyLocationsProfile[] = this.nearbyLocationsStore.fetchAlreadySavedProfiles(querieIds);
    const markers = [];
    savedProfiles.forEach((profile: NearbyLocationsProfile) => {
      if (this.nearbyLocationsService.nearbyLocationProfileWasTrilaterated(profile)) {
        markers.push(this.buildNearbyLocationMarker(profile));
      }
    });
    this.addNearbyLocationMarkers(markers);
  }

  private removeNearbyPeopleMarkers(querieIds: string[]) {
    const trilateratedProfilesIds: string[] = this.nearbyLocationsStore
      .fetchAlreadySavedProfiles(querieIds)
      .filter((profile) => this.nearbyLocationsService.nearbyLocationProfileWasTrilaterated(profile))
      .map((profile) => `nearby-location-${profile.userId}`);
    this.markersChange.next(this.markersChange.getValue().filter((marker) => !trilateratedProfilesIds.includes(marker.id)));
  }

  private buildNearbyLocationMarker(profile: NearbyLocationsProfile) {
    return new TemplateMarker({
      id: `nearby-location-${profile.userId}`,
      lat: profile.latitude,
      lng: profile.longitude,
      popupHTML: this.getNearbyLocationPopupText(profile),
      isPopupWindowOpen: true,
      extendMapBounds: false,
      anchorPosition: AnchorPosition.MIDDLE,
      getEmbeddedView: () => this.customNearbyMarkerTemplate.createEmbeddedView({
        imageUrl: this.getNearbyProfilePhoto(profile),
        sourceBadge: DataSource.Telegram,
        nearbyProfileId: profile.userId,
      })
    });
  }

  public getNearbyProfilePhoto(profile: NearbyLocationsProfile): string {
    const photo = head(profile.photos);
    if (photo && Object.keys(photo).length) {
      return <string>this.imageService.getPhotoUrl(photo.url, false);
    }
    return 'assets/static/images/search-intel/avatars-h-60-no-img.svg';
  }

  private addNearbyLocationMarkers(markers: Marker[]): void {
    this.markersChange.next(this.markersChange.getValue().concat(markers).slice());
  }

  ngAfterViewInit() {
    this.calculateGeoMapWidth(this.sideNav);
    this.setupHistoryPlayback();
    this.extractPeerMarkerGenerator = new ExtractPeerMarkerGenerator(this.queryService, this.mapHelperService, this.extractPeerInfoCustomMarker, this.extractPeerCustomMarker);
    this.ipLocationQueryMarkerGenerator = new IpLocationQueryMarkerGenerator(this.mapHelperService, this.customIPLocationMarkerTemplate);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.queryService.multiquerySelection.next([]);
  }

  changeView(view) {
    this.dashboardService.componentsView.next(view);
  }

  getFilesDownloadState(event: boolean): void {
    this.filesDownloadState = event;
  }

  onPlayerAction(playerState: PlayerState) {
    this.playerState = playerState;

    switch (playerState) {
      case 'playing':
        this.historyPlayback.start();
        break;

      case 'paused':
        this.historyPlayback.pause();
        break;

      case 'stopped':
        this.historyPlayback.stop();
        this.lastHistoryPlaybackMarkerIndex = 0;
        this.googleMapComponent.removeMarker(PLAY_BACK_MARKER_ID);
        break;

      case 'replay':
        this.historyPlayback.stop();
        this.lastHistoryPlaybackMarkerIndex = 0;
        break;
    }
  }

  private generateTrilaterationParams(data: {
    profiles: NearbyLocationsProfile[];
    queryParent: Query;
  }): TrilaterationNearbyLocationRequest {
    return {
      geo_query_id: data.queryParent.id,
      latitude: data.queryParent.location.coordinates[1],
      longitude: data.queryParent.location.coordinates[0],
      profiles_data: data.profiles.map((profile) => ({
        profile_user_id: profile.userId,
        distance: profile.distance || this.nearbyLocationsService.TRILATERATION_DISTANCE_LIMIT,
      })),
      telno: data.queryParent.queryArgs.telno,
      default_distance: this.nearbyLocationsService.TRILATERATION_DISTANCE_LIMIT,
    };
  }

  private getNearbyProfilesQueryParent(profiles: NearbyLocationsProfile[]): {
    profiles: NearbyLocationsProfile[];
    queryParent: Query;
  } {
    return {
      profiles,
      queryParent: head(
        uniqBy(
          [...this.queryService.multiquerySelection.getValue(), this.queryService.onQuerySelection.getValue()],
          (query: Query) => query.id
        ).filter((query: Query) => query.id === head(profiles).geoQueryId)
      ),
    };
  }

  private getNearbyLocationPopupText(profile: NearbyLocationsProfile): string {
    const fullName = `${profile?.firstName} ${profile?.lastName}`;
    return fullName ? fullName.trim() : profile.username;
  }

  private setupHistoryPlayback() {
    this.subscription = this.historyPlayback.onInterval().subscribe(() => {
      const existingMarker = this.markersChange.getValue()[this.lastHistoryPlaybackMarkerIndex];

      if (!existingMarker) {
        this.playerState = 'finished';
        return;
      }

      const dateString = existingMarker.date.toString();

      const marker = new IconMarker({
        id: PLAY_BACK_MARKER_ID + this.lastHistoryPlaybackMarkerIndex,
        lat: existingMarker.lat,
        lng: existingMarker.lng,
        iconUrl: `${this.baseImagePath}pin_live.svg`,
        extendMapBounds: false,
        popupHTML: dateString,
        isPopupWindowOpen: true,
        zIndex: 1500,
      });
      const newMarkers = this.markersChange.getValue().filter(
        marker => marker.id !== PLAY_BACK_MARKER_ID + (this.lastHistoryPlaybackMarkerIndex - 1)
      );
      newMarkers.push(marker);
      this.markersChange.next([...newMarkers]);
      this.lastHistoryPlaybackMarkerIndex++;
    });
  }

  private createToolboxMarker(query: Query, extendMapBounds = false): TemplateMarker {
    return new TemplateMarker({
      id: 'selected-query' + query.id.toString(),
      lat: query.location.coordinates[1],
      lng: query.location.coordinates[0],
      elementRef: this.customToolboxMarkerTemplate,
      popupHTML: this.toolboxPopup,
      isPopupWindowOpen: true,
      extendMapBounds,
      anchorPosition: AnchorPosition.MIDDLE,
      getEmbeddedView: () => this.customToolboxMarkerTemplate.createEmbeddedView({
        label: this.toolboxPopup,
        showGeoMarkerToolbox: this.showGeoMarkerToolbox,
        geofencingIsEnabled: this.geofencingIsEnabled,
        cachedAntennas: this.cachedAntennas,
        followIsEnabled: this.followIsEnabled,
        pendingSchedulerAction: this.pendingSchedulerAction,
      }),
      getPopupEmbeddedView: () => this.customToolboxMarkerPopupTemplate.createEmbeddedView({
        label: this.toolboxPopup
      })
    });
  }

  private loadTimelineAndHeatMapQueries(query: Query): Observable<boolean> {
    if (this.historyMarkers.length) {
      return of(true);
    }

    return this.queryService
      .getTimelineAndHeatmapQueries(
        query.queryArgs.telno,
        query.queryArgs.imsi,
        this.queryService.dateRange.getValue(),
        SortMode.DESC
      )
      .pipe(
        map((historyQueries: Query[]) => {
          this.timelineHistoryLocations = historyQueries
            .filter((query) => !!query?.location?.coordinates?.length)
            .sort((a, b) => {
              return new Date(a.locationReceivedAt).getTime() - new Date(b.locationReceivedAt).getTime();
            });

          this.timelineHistoryLocations.forEach((historyQuery, index) => {
            let iconMarker = `${this.baseImagePath}profiler/pin_grey.svg`;
            let zIndex = 100;

            if (index === 0) {
              iconMarker = `${this.baseImagePath}profiler/pin_green.svg`;
              zIndex = 1000;
            } else if (index === historyQueries.length - 1) {
              iconMarker = `${this.baseImagePath}profiler/pin_red.svg`;
              zIndex = 1000;
            }

            const readableDateString = this.datePipe.transform(historyQuery.locationReceivedAt, 'MMM dd yyyy HH:mm:ss');

            const marker = new IconMarker({
              id: historyQuery.id.toString(),
              lat: historyQuery.location.coordinates[1],
              lng: historyQuery.location.coordinates[0],
              iconUrl: iconMarker,
              popupHTML: `${index + 1}. ${readableDateString}`,
              date: historyQuery.locationReceivedAt,
              isPopupWindowOpen: false,
              zIndex,
            });

            this.historyMarkers.push(marker);
          });
          return true;
        })
      );
  }

  onSelectedQuery(query: Query): void {
    this.onSelectedQueryStream$.next(query);
  }

  private catchLastSelectedQuery(query: Query): void {
    if (this.previousQuery?.id === query.id) {
      return;
    }
    this.deleteExtractPeerPolyline();
    this.openSideNav();
    this.markersChange.next([]);
    this.previousQuery = query;
    this.addAnalyticsButtons(query);
    if (query.location) {
      this.addTelegramNearbyLocationsButton();
      this.zoom = this.getQueryZoomLevel(query.accuracyMeters);
      // remove previously selected query
      this.markersChange.next(this.markersChange.getValue().filter((q) => q.id.indexOf('selected-query')));
      this.toolboxPopup = query.alias
        ? query.alias
        : query.queryArgs.telno
          ? query.queryArgs.telno
          : query.queryArgs.imsi;

      const marker = this.createToolboxMarker(query, true);

      this.extractPeerMarkerGenerator
        .getExtractPeerMarkerAndQuery(query)
        .subscribe(
          ([peerMarker, peerQuery]) => {
            if (!!peerMarker) {
              this.markersChange.next(this.markersChange.getValue().concat(peerMarker));
              this.customPolylinePath = this.extractPeerMarkerGenerator.generateMarkersPolyline(query, peerQuery);
              this.customPolylinePath.setMap(this.mapComponent.googleMap.map);
            }
          },
          () => { },
          () => (this.markersChange.next(this.markersChange.getValue().slice()))
        );
      this.markersChange.next(this.markersChange.getValue().concat(marker));
      let gpsMarker: IconMarker;
      if (query.gps && query.gps.billingId) {
        gpsMarker = new IconMarker({
          id: query.id.toString(),
          lat: query.gps.deviceLocation.coordinates[1],
          lng: query.gps.deviceLocation.coordinates[0],
          iconUrl: `${this.baseImagePath}gps_location_pin.svg`,
          popupHTML: query.gps.locationType || '',
          isPopupWindowOpen: true,
        });
        this.markersChange.next(this.markersChange.getValue().concat(gpsMarker));
      }
      let trialterationMarker: IconMarker;
      if (query.nmr && query.nmr.billingId) {
        if (query.nmr.trialterationLocation) {
          trialterationMarker = new IconMarker({
            id: query.id.toString(),
            lat: query.nmr.trialterationLocation.coordinates[1],
            lng: query.nmr.trialterationLocation.coordinates[0],
            iconUrl: `${this.baseImagePath}nmr_location_pin.svg`,
            popupHTML: query.queryArgs.telno || '',
            isPopupWindowOpen: false,
          });
          this.markersChange.next(this.markersChange.getValue().concat(trialterationMarker));
        } else if (
          query.nmr.sectorLocation &&
          query.nmr.combasBearing &&
          query.nmr.sectorSize &&
          query.nmr.bearingDirection
        ) {
          const sector = this.markerSectorService.getNMRSector(query);
          this.polygons.push(sector);
        }
      } else if (query.azimuth) {
        const sector = this.markerSectorService.getAzimuthSector(query);

        this.polygons.push(sector);
      }
      if (gpsMarker || trialterationMarker) {
        this.zoom = 20;
      }

      this.showQueryAccuracyShapes(query);
    }

    this.polygons = [...this.polygons];

    if (query.location && this.appConfigService.getConfigVariable('enableAdInt')) {
      const discoveryButton: Button = this.mapHelperService.createButton(
        'draw_marker',
        'discovery-logo.svg',
        'discovery-logo.svg',
        'Discovery',
        'Discovery',
        ButtonState.Deactivated,
        true,
        ControlPosition.TOP_RIGHT
      );

      discoveryButton.callback = () => {
        this.angulartics2.eventTrack.next({
          action: matomoActions.jumpToDiscovery,
          properties: {
            category: matomoCategories.gioMap,
          },
        });
        this.router.navigate(['/', 'discovery'], {
          queryParams: {
            coordinates: query.location.coordinates,
            date: query.createdAt,
          },
        });
      };

      this.buttons.push(discoveryButton);
    }

    // if number has history add heatmap and timeline buttons
    if (query.historyLocationCount > 1) {
      const heatmapButton: Button = this.mapHelperService.createButton(
        'heatmap',
        'heatmap_disable.svg',
        'heatmap_enable.svg',
        'Disable target heatmap',
        'Enable target heatmap',
        ButtonState.Deactivated,
        true,
        ControlPosition.TOP_RIGHT
      );

      const historyTimelineButton: Button = this.mapHelperService.createButton(
        'timeline',
        'timeline_disable.svg',
        'timeline_enable.svg',
        'Disable target history timeline',
        'Enable target history timeline',
        ButtonState.Deactivated,
        true,
        ControlPosition.TOP_RIGHT
      );

      this.buttons.push(heatmapButton, historyTimelineButton);
      this.buttons = this.buttons.slice();

      heatmapButton.callback = () => {
        this.angulartics2.eventTrack.next({
          action: matomoActions.viewHeatmap,
          properties: {
            category: matomoCategories.gioMap,
          },
        });
        this.circles = [];
        this.polylines = [];
        this.markersChange.next([]);
        this.polygons = [];

        // count only if the user is enabling the heatmap
        if (heatmapButton.state === ButtonState.Activated) {
          historyTimelineButton.state = ButtonState.Deactivated;

          this.loadTimelineAndHeatMapQueries(query).subscribe(() => {
            this.switchToHeatMapView();
          });
        } else {
          const marker = this.createToolboxMarker(query);
          this.markersChange.next([marker])
          this.showQueryAccuracyShapes(query);

          this.userBehaviorService.userBehavior('geo_map_heatmap').subscribe();
          this.heatmapEnabled = false;
        }
      };

      historyTimelineButton.callback = () => {
        this.angulartics2.eventTrack.next({
          action: matomoActions.viewTimeline,
          properties: {
            category: matomoCategories.gioMap,
          },
        });
        this.circles = [];
        this.markersChange.next([]);
        this.polylines = [];
        this.heatmapPoints = [];

        // count only if the user is enabling the timeline
        if (historyTimelineButton.state === ButtonState.Activated) {
          heatmapButton.state = ButtonState.Deactivated;

          this.loadTimelineAndHeatMapQueries(query).subscribe(() => {
            this.switchToTimelineView();
          });
        } else {
          this.historyTimelineEnabled = false;
          this.userBehaviorService.userBehavior('geo_map_timeline').subscribe();
          const marker = this.createToolboxMarker(query);
          this.showQueryAccuracyShapes(query);
          this.markersChange.next([marker]);
        }
      };
    }
    // draw area on map if fenced_area or fenced_country type
    if (query.schedule && query.schedule.scheduleType === QueryType.FENCED_AREA.toLowerCase()) {
      const area: Circle = new Circle({
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#000000',
        fillOpacity: 0.2,
        extendMapBounds: false,
        center: {
          lat: query.schedule.payload.area.location.coordinates[1],
          lng: query.schedule.payload.area.location.coordinates[0],
        },
        radiusMeters: query.schedule.payload.area.radiusMeters,
      });
      this.circles.push(area);

      this.center = {
        lat: query.schedule.payload.area.location.coordinates[1],
        lng: query.schedule.payload.area.location.coordinates[0],
      };

      this.circles = this.circles.slice();
    }

    if (query.schedule && query.schedule.scheduleType === QueryType.FENCED_COUNTRY.toLowerCase()) {
      this.onSelectedCountry(query.schedule.payload.countryCode.toUpperCase());
    }

    this.cdr.markForCheck();
  }

  private deleteExtractPeerPolyline(): void {
    if (!!this.customPolylinePath) {
      this.customPolylinePath.setMap(null);
    }
  }

  private switchToTimelineView() {
    this.deleteExtractPeerPolyline();
    this.markersChange.next([...this.historyMarkers]);
    this.buttons = [...this.buttons];
    this.historyTimelineEnabled = true;

    const linePoints = this.historyMarkers.map((marker) => {
      return { lat: marker.lat, lng: marker.lng };
    });

    this.polylines = [
      new Polyline({
        points: linePoints,
        strokeColor: '#4fb9d9',
      }),
    ];
    this.cdr.markForCheck();
  }

  private switchToHeatMapView() {
    this.deleteExtractPeerPolyline();
    this.heatmapPoints = this.historyMarkers.map((marker) => {
      return { lat: marker.lat, lng: marker.lng };
    });

    this.buttons = [...this.buttons];
    this.heatmapEnabled = true;
    this.historyTimelineEnabled = false;
    this.cdr.markForCheck();
  }

  onMultiselectQuery(data: { query: Query; new: boolean }) {
    // remove previously selected row (not from checkbox)
    this.markersChange.next(this.markersChange.getValue().filter((query) => query.id.indexOf('selected-query')));
    if (data.new) {
      const label = data.query.alias
        ? data.query.alias
        : data.query.queryArgs.telno
          ? data.query.queryArgs.telno
          : data.query.queryArgs.imsi;

      const marker = new IconMarker({
        id: data.query.id.toString(),
        lat: data.query.location.coordinates[1],
        lng: data.query.location.coordinates[0],
        popupHTML: label,
        isPopupWindowOpen: true,
      });
      this.markersChange.next(this.markersChange.getValue().concat(marker));
      this.addSector(data.query);

      if (data.query?.location) {
        this.addTelegramNearbyLocationsButton();
      }
    } else {
      this.markersChange.next(this.markersChange.getValue().filter((marker) => marker.id !== data.query.id.toString()));
      this.removeSector(data.query);
      this.enableSectorForPreviousQuery();
    }
  }

  private enableSectorForPreviousQuery() {
    const currentMarkers = this.markersChange.getValue();
    const hasRemainingQueries = currentMarkers.length > 0;
    if (this.selectedQueries.length && hasRemainingQueries) {
      const markerId = currentMarkers.slice(-1).pop().id;
      this.addSector(this.selectedQueries.find((query) => query.id === markerId));
    }
  }

  private addSector(query: Query) {
    const sector = this.markerSectorService.getSector(query);

    if (!sector) {
      return this.showQueryAccuracyShapes(query);
    }

    this.polygons.push(sector);
    this.polygons = [...this.polygons];
  }

  private removeSector(query: Query): void {
    const polygons = this.polygons.filter((polygon) => !polygon.id.includes(query.id));
    const circles = this.circles.filter((circle) => !circle.id.includes(query.id));

    this.circles = [...circles];
    this.polygons = [...polygons];
  }

  getQueryZoomLevel(accuracy) {
    let zoomlevel = 8;
    if (accuracy < 2000) {
      zoomlevel = 13;
    } else if (accuracy < 10000) {
      zoomlevel = 10;
    }
    return zoomlevel;
  }

  private createIpLocationQuery() {
    return this.ipLocationService.getAction().subscribe((query: Query) => {
      this.queryService.numbersToBeQueried.next([query.queryArgs]);
      this.queryService.ipLocationQuery().subscribe({
        next: () => {
          this.showMessage(this.translationService.translate('Query successfully submitted'));
        },
        error: (e) => {
          this.showMessage(this.translationService.translate('Query has not been created'));
        },
      });
    })
  }

  showQueryAccuracyShapes(queryData: Query) {
    if (!queryData) return;

    if (!queryData.cell?.cellId && queryData.cell?.lac > 0) {
      this.polygons = this.polygons.concat(this.mapHelperService.showQueryHexagonForLAC(queryData));
    } else if (queryData.ipAddress) {
      this.polygons = this.polygons.concat(
        this.mapHelperService.showQueryHexagonForIP(queryData, this.ipLocationMarkerInfoWindow)
      );
      const marker = this.ipLocationQueryMarkerGenerator.generateIPLocationMarker(
        queryData,
      );
      this.markersChange.next(this.markersChange.getValue().concat(marker));
    } else {
      this.circles = this.circles.concat(this.mapHelperService.showQueryAccuracyCircles(queryData));
    }
  }

  logout() {
    this.queryService.clearRadicalMonitoring.next(false);
    this.authSessionService.logout();
  }

  onTabChange(event) {
    this.hideAllTabContent();
    this.selectedSecondaryTab = 0;
    this.refreshMap();
  }

  hideCenterDetails(): boolean {
    return this.view === DashboardView.QUERY || this.view === DashboardView.ANALYTICS;
  }

  hideAllTabContent() {
    this.intelligenceIsActiveTab = false;
  }

  queryItemOnTabChange(event) {
    if (event.index === 1) {
      this.dashboardService.showIntelTabContent.next(true);
    } else {
      this.dashboardService.showIntelTabContent.next(false);
    }
  }

  // mobile view functions
  openLogBottomSheet(): void {
    this.bottomSheet.open(BottomSheetMobileComponent, {
      panelClass: this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : '',
    });
    this.dashboardService.showQaugeOnMobile.next(false);
    this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      if (this.query) {
        this.onSelectedQuery(this.query);
      }
    });
  }

  showLatestQueries() {
    this.openLogBottomSheet();
    this.queryService.showTelnoHistory.next(false);
  }

  resubmitQuery(query) {
    if (this.userBillingService.userHasEnoughCredits([BillingActions.QUERY_LOCATION])) {
      const resubmitQuerySubscription = this.queryService.resubmitQuery(query.id).subscribe(
        () => {
          this.showMessage(this.translationService.translate('Query successfully resubmitted!'));
          resubmitQuerySubscription.unsubscribe();
        },
        () => {
          this.showMessage(this.translationService.translate('Resubmit Cancelled'));
          resubmitQuerySubscription.unsubscribe();
        }
      );
    }
  }

  showIntelView() {
    this.mobileShowGeo = false;
    this.mobileShowIntel = true;
  }

  showGeoDetails() {
    this.dashboardService.showMobileGeoDetails.next(true);
  }

  mobileHistory(query) {
    this.openLogBottomSheet();
    const telno = query.queryArgs.telno;
    const imsi = query.queryArgs.imsi;
    this.queryService.showTelnoHistory.next(true);
    this.queryService.historyItem.next({ telno, imsi });
  }

  refreshQueriesMobileView() {
    this.refreshMap();
    this.queryService.resetVariablesState();
  }

  refreshMap() {
    this.markersChange.next([]);
    this.historyMarkers = [];
    this.trafficEnabled = false;
    this.nearbyLocationsWindow = { enabled : false, showOnMap: false };
    this.zoom = this.mapHelperService.DEFAULT_ZOOM_LEVEL;

    if (this.googleMapComponent) {
      this.onPlayerAction('stopped');
    }

    this.buttons = [];

    if (this.dashboardService.componentsView.getValue() === DashboardView.LOG && !this.isMobileResolution) {
      this.addTrafficButton();
    }

    this.deleteExtractPeerPolyline();
    this.heatmapEnabled = false;
    this.historyTimelineEnabled = false;
    this.heatmapPoints = [];
    this.polylines = [];
    this.circles = [];
    this.clusteringEnabled = false;
    this.polygons = [];
    this.enableDrawing = DrawMode.None;
    this.feature = {};
    this.analyticsService.showDayTimeSelector.next(false);
    this.previousEnabledCustomControl = null;
    this.cdr.markForCheck();
  }

  // toolbox marker functions
  toggleGeoMarkerToolbox() {
    this.removeDrawingCircleButton();
    this.showGeoMarkerToolbox = !this.showGeoMarkerToolbox;
  }

  showBTS() {
    this.removeDrawingCircleButton();
    let markers = this.markersChange.getValue();
    this.queryService.getAllNeighbourAntennas(this.queryService.onQuerySelection.getValue()).subscribe(
      (res) => {
        if (!res) {
          this.showMessage(this.translationService.translate('An error has occurred'));
          return;
        }
        this.cachedAntennas = true;
        this.showMessage(`${res.result.length} ${this.translationService.translate('nearby towers found')}`);
        res.result.forEach((tower) => {
          const marker = new IconMarker({
            id: tower.cellId.toString(),
            lat: tower.location.lat,
            lng: tower.location.lon,
            iconUrl: `${this.baseImagePath}geo/tower.svg`,
            getPopupEmbeddedView: () => this.cellTowerDetails.createEmbeddedView({
              data: tower
            }),
            isPopupWindowOpen: false,
          });
          markers.push(marker);
        });
        this.markersChange.next(markers.slice());
      },
      (err) => {
        if (err.status === 402) {
          this.showMessage(this.translationService.translate('Billing error'));
        } else {
          this.showMessage(this.translationService.translate('An error has occurred'));
        }
      }
    );
  }

  toggleScheduleTask() {
    this.pendingSchedulerAction = true;
    const query = this.queryService.onQuerySelection.getValue();
    if (this.followIsEnabled) {
      const { telno, imsi } = query.queryArgs;
      const task = telno ? this.activeSchedules.telno[telno] : this.activeSchedules.imsi[imsi];
      this.queryService.cancelRobot(task).subscribe(
        () => {
          this.pendingSchedulerAction = false;
          this.showMessage(this.translationService.translate('Query canceled'));
          delete this.activeSchedules.telno[telno];
          delete this.activeSchedules.imsi[imsi];
          this.updateActiveTasks(query);
        },
        (error: string) => {
          this.pendingSchedulerAction = false;
          this.showMessage(this.translationService.translate('Query has not been canceled'));
        }
      );
    } else {
      this.dialog
        .open(ScheduleActivationDialogComponent, {
          data: {
            title: 'Scheduler',
            bodyDescription: 'Choose duration and frequency',
          },
          panelClass: 'schedule-activation',
        })
        .afterClosed()
        .subscribe((result: Action) => {
          if (this.currentCredits) {
            if (!result || result.key === 'cancel') {
              this.pendingSchedulerAction = false;
            }

            if (result && result.key === 'success') {
              const { durationHours, frequencyMinutes } = result.data;
              const queryArgs = {
                queryType: QueryType.SCHEDULED,
                frequency: frequencyMinutes,
                startAt: new Date(),
                endAt: new Date(new Date().setHours(new Date().getHours() + durationHours)),
              };
              this.userBehaviorService.userBehavior('geo_map_query_schedule').subscribe();
              this.quickScheduledQuery(queryArgs);
            }
          } else {
            this.showMessage(this.translationService.translate('No credits'));
            this.pendingSchedulerAction = false;
          }
        });
    }
  }

  quickScheduledQuery(queryArgs: { queryType: QueryType; frequency: number; startAt: Date; endAt: Date }) {
    this.removeDrawingCircleButton();
    const query = this.queryService.onQuerySelection.getValue();
    this.queryService.numbersToBeQueried.next([query.queryArgs]);

    const scheduledQuerySubscription = this.queryService.createScheduledQuery(queryArgs).subscribe(
      () => {
        this.showMessage(this.translationService.translate('Query created successfully!'));
        this.updateActiveTasks(query);
        this.pendingSchedulerAction = false;
        scheduledQuerySubscription.unsubscribe();
      },
      () => {
        this.showMessage(this.translationService.translate('No credits'));
        scheduledQuerySubscription.unsubscribe();
        this.pendingSchedulerAction = false;
      }
    );
  }

  updateActiveTasks(query): void {
    this.queryService.getTasks('false').subscribe((tasks) => {
      this.activeSchedules.telno = {};
      this.activeSchedules.imsi = {};
      this.activeGeofences.telno = {};
      this.activeGeofences.imsi = {};
      tasks.forEach((task) => {
        const { telno: taskTelno, imsi: taskImsi } = task.payload.queryArgs;
        const scheduleType = task.scheduleType;
        if (taskTelno) {
          if (scheduleType === QueryType.SCHEDULED.toLowerCase()) {
            this.activeSchedules.telno[taskTelno] = task;
          } else if (scheduleType === QueryType.FENCED_AREA.toLowerCase()) {
            this.activeGeofences.telno[taskTelno] = task;
          }
        }
        if (taskImsi) {
          if (scheduleType === QueryType.SCHEDULED.toLowerCase()) {
            this.activeSchedules.imsi[taskImsi] = task;
          } else if (scheduleType === QueryType.FENCED_AREA.toLowerCase()) {
            this.activeGeofences.imsi[taskImsi] = task;
          }
        }
      });
      if (!query) {
        return;
      }
      const { telno, imsi } = query.queryArgs;
      const activeSchedule = telno ? this.activeSchedules.telno[telno] : this.activeSchedules.imsi[imsi];
      this.followIsEnabled = activeSchedule ? true : false;
      const activeGeofence = telno ? this.activeGeofences.telno[telno] : this.activeGeofences.imsi[imsi];
      this.geofencingIsEnabled = activeGeofence ? true : false;
    });
  }

  toggleGeofencingTask() {
    const query = this.queryService.onQuerySelection.getValue();

    if (this.geofencingIsEnabled) {
      const { telno, imsi } = query.queryArgs;
      const task = telno ? this.activeGeofences.telno[telno] : this.activeGeofences.imsi[imsi];
      this.queryService.cancelRobot(task).subscribe(
        () => {
          this.showMessage(this.translationService.translate('Query canceled'));
          delete this.activeGeofences.telno[telno];
          delete this.activeGeofences.imsi[imsi];
          this.updateActiveTasks(query);
        },
        (error: string) => this.showMessage(this.translationService.translate('Query has not been canceled'))
      );
    } else {
      this.createDrawingCircleButton();
    }
  }

  createDrawingCircleButton() {
    const drawingCircleButton: Button = this.mapHelperService.createButton(
      'circle',
      'circle_disable.svg',
      'circle_enable.svg',
      'Disable drawing',
      'Draw circle',
      ButtonState.Deactivated,
      true,
      ControlPosition.TOP_RIGHT
    );

    drawingCircleButton.callback = () => {
      if (this.enableDrawing === DrawMode.None) {
        this.enableDrawing = new DrawModeOptions({
          mode: DrawMode.Circle,
          options: {
            radiusMeters: 1000,
          },
        });
      } else {
        this.enableDrawing = DrawMode.None;
      }
    };

    const buttonExist = this.buttons.find((button) => button.id === drawingCircleButton.id);

    if (!buttonExist) {
      this.buttons.push(drawingCircleButton);
      this.buttons = this.buttons.slice();
    }
  }

  removeDrawingCircleButton() {
    this.buttons = this.buttons.filter((button) => button.id !== 'circle');
  }

  cachedAntennasCheck(query) {
    this.cachedAntennas = false;
    if (query.location) {
      this.queryService.neighbourAntennasCheck(query).subscribe((hasAntennas) => {
        if (hasAntennas) {
          this.cachedAntennas = true;
        }
      });
    }
  }

  addAnalyticsButtons(query) {
    if (this.enabledAnalytics && !this.isMobileResolution) {
      this.analyticsService.getDataAvailability(query.queryArgs.telno).subscribe((result) => {
        if (
          this.queryService.onQuerySelection.getValue() &&
          this.queryService.onQuerySelection.getValue().queryArgs.telno !== query.queryArgs.telno
        ) {
          return;
        }

        if (result.tenantPercentage >= IMPORT_DATA_THRESHOLD_PER) {
          const analyticsAvailableButton: Button = this.mapHelperService.createButton(
            'analyticsAvailable',
            'analytics/analytics-green.svg',
            'analytics/analytics-green.svg',
            'Available Data',
            'Available Data',
            ButtonState.Deactivated,
            true,
            ControlPosition.TOP_RIGHT
          );

          this.buttons.push(analyticsAvailableButton);
          this.buttons = this.buttons.slice();

          analyticsAvailableButton.callback = () => {
            this.refreshMap();
            this.dashboardService.componentsView.next(DashboardView.ANALYTICS);
            this.analyticsService.showDayTimeSelector.next(true);
          };
        } else {
          const noAnalyticsAvailableButton: Button = this.mapHelperService.createButton(
            'analyticsNoDataAvailable',
            'analytics/analytics-red.svg',
            'analytics/analytics-red.svg',
            'No Data Available',
            'No Data Available',
            ButtonState.Deactivated,
            true,
            ControlPosition.TOP_RIGHT
          );
          this.buttons.push(noAnalyticsAvailableButton);
          this.buttons = this.buttons.slice();
          noAnalyticsAvailableButton.callback = () => {
            this.dialog.open(ImportDataRequestDialogComponent, {
              height: '250px',
              width: '500px',
              panelClass: this.isGeolocTheme ? 'geoloc-theme' : this.isWhiteTheme ? 'white-theme' : '',
            });
            this.dialog.afterAllClosed.subscribe((e) => {
              // this.refreshMap();
            });
          };
        }
      });
    }
  }

  onSelectedCountry(countryCode: string) {
    if (!countryCode) {
      this.feature = {};
      return;
    }

    this.queryService.getCountries().subscribe((countriesData) => {
      if (!countriesData) {
        return;
      }

      this.polygons = [];

      Object.keys(countriesData).forEach((key) => {
        const country = countriesData[key];
        if (countryCode === country.alpha2 && country.simplePolygon) {
          const boundingBox = country.boundingBox;
          if (country.simplePolygon.geometry) {
            this.feature = {
              type: 'Feature',
              properties: {
                boundingBox: boundingBox,
              },
              geometry: {
                type: 'Polygon',
                coordinates: country.simplePolygon.geometry.coordinates,
              },
            };
          } else if (country.simplePolygon.geometries) {
            const features = [];

            country.simplePolygon.geometries.forEach((polygon) => {
              const feature = {
                type: 'Feature',
                geometry: polygon,
              };

              features.push(feature);
            });

            this.feature = {
              type: 'FeatureCollection',
              properties: {
                boundingBox: boundingBox,
              },
              features: features,
            };
          } else {
            // some countries (ex: France) don't have geometries
            return;
          }
        }
      });
    });
  }

  toggleButtonState(button: Button) {
    if (this.previousEnabledCustomControl && this.previousEnabledCustomControl.id !== button.id) {
      this.previousEnabledCustomControl.callback();
    }
    button.state = button.state === ButtonState.Activated ? ButtonState.Deactivated : ButtonState.Activated;
    this.previousEnabledCustomControl = button;
    this.buttons = this.buttons.slice();
    this.showLocatedPinIfMapActionsAreDisabled();
  }

  toggleSearchTool(flag: boolean) {
    this.enableSearching = flag;
  }

  toggleDrawingTools(flag: boolean) {
    if (flag) {
      this.createDrawingCircleButton();
    } else {
      this.removeDrawingCircleButton();
    }
  }

  onShapeDrawn = (circle: Circle) => {
    this.areaOfInterestDrawn = circle;
    this.enableDrawing = new DrawModeOptions({
      mode: DrawMode.Circle,
      options: circle,
    });

    if (this.view === 'logView') {
      this.openAreaOfInterestQueryConfirmation(circle);
    }
  };

  onStopDrawingEvent = () => {
    this.enableDrawing = DrawMode.None;
  };

  onClearQueryMap() {
    this.circles = [];
    // this.removeDrawingCircleButton();
  }

  onDrawnAreaOfInterestFromComponent(circle: Circle) {
    this.enableDrawing = new DrawModeOptions({
      mode: DrawMode.Circle,
      options: circle,
    });
    // this.circles.push(circle);
    // this.circles = this.circles.slice();
  }

  onEmittedLocationQuery(locationQuery: any) {
    const locationMarker = new IconMarker({
      id: 'ic-id',
      lat: locationQuery.Location.Location.Latitude,
      lng: locationQuery.Location.Location.Longitude,
      popupHTML: locationQuery.IMSI,
      isPopupWindowOpen: true,
    });
    this.markersChange.next([locationMarker]);
  }

  drawMarkersForProbability = (probabilities) => {
    const probabilityMarkers: Marker[] = [];
    probabilities.reverse().forEach((item) => {
      const confidence = Math.round(item.confidence * 100);

      const marker = new IconMarker({
        id: `probability${item.latitude}+${item.longitude}+${confidence}`,
        lat: item.latitude,
        lng: item.longitude,
        popupHTML: `${confidence}%`,
        isPopupWindowOpen: true,
      });

      probabilityMarkers.push(marker);
    });
    this.markersChange.next(probabilityMarkers);
  };

  onUserLocation = (location: Point) => {
    this.authService.userLocation.next(location);
  };

  addTrafficButton() {
    this.buttons.forEach((button) => {
      if (button.id === 'traffic') {
        return;
      }
    });
    this.trafficButton = this.mapHelperService.getTrafficButton();
    this.trafficButton.position = ControlPosition.TOP_RIGHT;
    this.buttons.push(this.trafficButton);
    this.buttons = this.buttons.slice();
    this.trafficButton.callback = () => {
      this.trafficEnabled = !this.trafficEnabled;
    };
  }

  addTelegramNearbyLocationsButton() {
    if (this.buttons.findIndex((btn) => btn.id === 'telegram-nearby-locations') < 0) {
      const telegramButton: Button = this.mapHelperService.getTelegramNearByLocationsButton();
      this.buttons.push(telegramButton);
      this.buttons = this.buttons.slice();
      telegramButton.callback = ($event: any) => {
        const buttonState = $event as Button;
        this.angulartics2.eventTrack.next({
          action: matomoActions.telegramNearby,
          properties: {
            category: matomoCategories.gioMap,
          },
        });

        if (this.selectedQueries.length && buttonState.state === ButtonState.Activated) {
          this.selectedQueries.forEach((query: Query) => {
            const status: JobStatus = this.nearbyLocationsStore.getNearbyLocationRequestState(query.id);
            if (!this.nearbyLocationsStore.areAlreadyFetchedProfiles(query.id) && status === JobStatus.DONE) {
              this.requestNearbyLocations(query);
            }
          });
        }
        this.nearbyLocationsStore.setStateTrilaterationRequests(this.selectedQueries.map((query) => query.id));
        this.nearbyLocationsWindow.enabled = !this.nearbyLocationsWindow.enabled;
      };
    }
  }

  showLocatedPinIfMapActionsAreDisabled() {
    const activatedMapActions = this.buttons.filter((b) => b.state === ButtonState.Activated);
    if (!activatedMapActions.length) {
      this.onSelectedQuery(this.queryService.onQuerySelection.getValue());
    }
  }

  onRecommendationsImport(params: { telno: string; selectedDays: string[] }): void {
    this.recommendations = null;

    this.subscription = this.recommendationImportService
      .doImportAndShowToastr(params.telno, params.selectedDays)
      .subscribe(([apiResult, comp]) => {
        if (apiResult.body.status === 'completed') {
          comp.toastRef.componentInstance.isImported = true;
          this.queryService.refreshLogQueries.next(true);
        }
      });
  }

  private requestNearbyLocations(query: Query) {
    const request: NearbyLocationsRequest = {
      geo_query_id: query.id,
      telno: query.queryArgs.telno,
      latitude: query.location.coordinates[1],
      longitude: query.location.coordinates[0],
      platform: Platform.TELEGRAM,
      radius: this.nearbyLocationsService.TRILATERATION_DISTANCE_LIMIT,
      construct_photos: true,
    };
    this.nearbyLocationsStore.setNearbyLocationRequestState(query.id, JobStatus.PENDING);
    this.nearbyLocationsService.getNearbyLocations(request).subscribe();
  }

  private closeNearbyLocationWindow(nearbyLocationStateWindow: NearbyLocationWindowState): void {
    this.nearbyLocationsWindow = { enabled: nearbyLocationStateWindow.enabled, showOnMap: nearbyLocationStateWindow.showOnMap};
    const index = this.buttons.findIndex((btn) => btn.id === 'telegram-nearby-locations');
    this.buttons[index].state = nearbyLocationStateWindow.enabled ? ButtonState.Activated : ButtonState.Deactivated;
    this.buttons = this.buttons.slice();
  }

  filterTimeline(timestamps: Number[]) {
    this.showMapLoader = true;
    this.markersChange.next([]);
    this.circles = [];
    this.polylines = [];
    this.polygons = [];

    const from = timestamps[0];
    const to = timestamps[1];
    const filteredMarkers: Marker[] = [];

    const filteredLocations = this.timelineHistoryLocations
      .filter((query) => {
        if (!query?.location?.coordinates?.length) {
          return false;
        }
        const updatedAt = moment(query.locationReceivedAt).valueOf();
        return updatedAt >= from && updatedAt <= to;
      })
      .sort((a, b) => +new Date(a.locationReceivedAt) - +new Date(b.locationReceivedAt));

    if (filteredLocations.length) {
      filteredLocations.forEach((locationQuery: Query, index) => {
        const target = locationQuery.queryArgs ? locationQuery.queryArgs.telno || locationQuery.queryArgs.imsi : '';
        const date = format(new Date(locationQuery.locationReceivedAt), 'dd.MM.yyyy HH:mm');

        let iconMarker = `${this.baseImagePath}profiler/pin_grey.svg`;
        let zIndex = 100;

        if (index === 0) {
          iconMarker = `${this.baseImagePath}profiler/pin_green.svg`;
          zIndex = 1000;
        } else if (index === filteredLocations.length - 1) {
          iconMarker = `${this.baseImagePath}profiler/pin_red.svg`;
          zIndex = 1000;
        }

        const marker = new DiscoveryTimelineIconMarker({
          id: locationQuery.id,
          lat: locationQuery.location.coordinates[1],
          lng: locationQuery.location.coordinates[0],
          iconUrl: iconMarker,
          popupHTML: `${target} ${date}`,
          isPopupWindowOpen: false,
          zIndex,
          sector: this.markerSectorService.getSector(locationQuery),
          circles: this.mapHelperService.showQueryAccuracyCircles(locationQuery),
          date: locationQuery.locationReceivedAt,
        });

        if (marker.sector) {
          this.polygons.push(marker.sector);
        } else {
          this.circles.push(head(marker.circles));
        }

        filteredMarkers.push(marker);
      });

      const linePoints = filteredMarkers.map((marker) => {
        return { lat: marker.lat, lng: marker.lng };
      });

      this.polylines = [
        new Polyline({
          points: linePoints,
          strokeColor: '#4fb9d9',
        }),
      ];
    }
    this.markersChange.next(filteredMarkers);
    this.polygons = [...this.polygons];

    // Reset player
    this.showPlaybackControls = this.markersChange.getValue().length > 1;
    this.onPlayerAction('stopped');

    // setTimeout will go on the execution queue and in reality it will complete as soon as
    // the map finishes it's functions
    setTimeout(() => {
      this.showMapLoader = false;
      this.cdr.markForCheck();
    }, 1000);
  }

  private openAreaOfInterestQueryConfirmation(circle: Circle) {
    Swal.fire({
      title: this.translationService.translate('Area of Interest Query'),
      text: this.translationService.translate('Do you wish to redraw the area or submit the query?'),
      showCancelButton: true,
      html: `<div class="quick-aoi-radio-buttons">
        <input type="radio" name="aoi-position" value="inside">${this.translationService.translate('Inside of area')}
        <input type="radio" name="aoi-position" value="outside" checked>${this.translationService.translate(
        'Outside of area'
      )}
      </div>`,
      confirmButtonText: this.translationService.translate('Submit'),
      cancelButtonText: this.translationService.translate('Redraw'),
    }).then((result) => {
      if (result.value) {
        this.quickAreaOfInterestQuery(circle);
      }
    });
  }

  private quickAreaOfInterestQuery(circle: Circle) {
    const position = document.querySelectorAll('input[name="aoi-position"]:checked')[0].getAttribute('value');
    const outside = position === 'inside' ? false : true;

    const data = {
      queryType: QueryType.FENCED_AREA,
      frequency: environment.enabledOnlyForDemo ? IntervalMinutes.MAX : IntervalMinutes.HIGH,
      startAt: this.queryService.getQuickScheduleDates(false)[0],
      endAt: this.queryService.getQuickScheduleDates(false)[1],
      outside,
      radius: (circle.radiusMeters / 1000).toFixed(3),
      lng: circle.center.lng,
      lat: circle.center.lat,
    };

    if (this.appConfigService.getConfigVariable('forceLowFrequencySchedules')) {
      let endAt = new Date();
      data.startAt = new Date();
      endAt.setHours(data.startAt.getHours() + 6);
      data.endAt = new Date(endAt.setHours(data.startAt.getHours() + 6));
    }

    this.queryService.numbersToBeQueried.next([this.queryService.onQuerySelection.getValue().queryArgs]);

    const areOfInterestQuerySubscription = this.queryService.createAreaOfInterestQuery(data).subscribe(
      () => {
        this.showMessage(this.translationService.translate('Query created successfully!'));

        this.buttons = this.buttons.filter((button) => button.id !== 'circle');
        this.enableDrawing = DrawMode.None;

        this.circles = [circle];

        this.updateActiveTasks(this.queryService.onQuerySelection.getValue());
        areOfInterestQuerySubscription.unsubscribe();
      },
      () => {
        this.showMessage(this.translationService.translate('No credits'));
        areOfInterestQuerySubscription.unsubscribe();
      }
    );
  }

  // Target Tool Tip POPup
  clickInside() {
    this.targetPopup = true;
    this.wasInside = true;
  }

  valueCopied() {
    this.showMessage(this.translationService.translate('Copied'));
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.targetPopup = false;
    }
    this.wasInside = false;
  }

  private recommendationObs(): Subscription {
    return this.queryService.onQuerySelection
      .pipe(
        tap(() => {
          this.recommendations = null;
        }),
        debounce(() => timer(1200)),
        filter((v) => !!v),
        filter((v) => v.status !== QueryStatus.PENDING),
        filter((v) => !!v?.queryArgs?.telno || !!v?.provider?.telno),
        map((q) => q.queryArgs.telno || q.provider.telno),
        switchMap((qTelno) => this.recommendationImportService.checkOnRecommendations(qTelno))
      )
      .subscribe((resp) => {
        this.recommendations = resp.body;
        this.cdr.markForCheck();
      });
  }

  public toggleSideNav(): void {
    this.sideNav = !this.sideNav;
    this.calculateGeoMapWidth(this.sideNav);
  }

  public closeSideNav(): void {
    this.sideNav = false;
    this.calculateGeoMapWidth(false);
  }

  private openSideNav(): void {
    this.sideNav = true;
    this.calculateGeoMapWidth(true);
  }

  private calculateGeoMapWidth(isSidenavOpen: boolean): void {
    switch (isSidenavOpen) {
      case true: {
        this.geoMapWidth =
          (this.geoTableLog?.nativeElement?.getBoundingClientRect()?.width || 510) +
          (this.geoTableSideContent?.nativeElement?.getBoundingClientRect()?.width || 440);
        break;
      }
      case false: {
        this.geoMapWidth = this.geoTableLog?.nativeElement?.getBoundingClientRect()?.width || 510;
        break;
      }
    }
    this.cdr.markForCheck();
  }
}
