import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/base/base.component';
import { QueryTypeUiPipe } from 'src/app/components/query-list-item/query-type-pipe';
import { IntervalMinutes, QueryFrequency, Schedule } from 'src/app/shared/models/query-item.model';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { environment } from 'src/environments/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from 'src/app/components/query-list/custom-mat-paginator-int';
import { map, mergeMap } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { UserRoles } from 'src/app/services/user/user.model';
import { remove } from 'lodash-es';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl,
  }]
})

export class TasksComponent extends BaseComponent implements OnInit {
  displayedColumnsGeo: string[] = ['msisdn', 'user', 'start_date', 'end_date', 'createdAt', 'frequency', 'type', 'task_id', 'actions'];
  dataSource: any;
  skin = environment.flavor;
  tasks = [];
  isMobileResolution = false;
  sidenavIsOpen = false;
  geoColumns = [
    {
      columnDef: 'user',
      header: this.translationService.translate('User'),
      cell: (row) => row.createdBy
    },
    {
      columnDef: 'start_date',
      header: this.translationService.translate('Start date'),
      cell: (row) => moment(row.startsAt).format('DD MMM YYYY HH:mm:ss') || ''
    },
    {
      columnDef: 'end_date',
      header: this.translationService.translate('End date'),
      cell: (row) => {
        if (row.canceledAt) { return moment(row.canceledAt).format('DD MMM YYYY HH:mm:ss'); }
        if (row.endsAt) { return moment(row.endsAt).format('DD MMM YYYY HH:mm:ss'); }
        return '';
      }
    },
    {
      columnDef: 'createdAt',
      header: this.translationService.translate('Created'),
      cell: (row) => moment(row.createdAt).format('DD MMM YYYY HH:mm:ss') || ''
    },
    {
      columnDef: 'frequency',
      header: this.translationService.translate('Frequency'),
      cell: (row) => row.intervalMinutes > 59 ?
        `${row.intervalMinutes / 60} ${row.intervalMinutes === 60 ? this.translationService.translate('hour') : 'hrs'}` :
        row.intervalMinutes + ' min'
    },
    {
      columnDef: 'type',
      header: this.translationService.translate('Type'),
      cell: (row) => this.queryTypePipe.transform(row.scheduleType)
    },
    {
      columnDef: 'task_id',
      header: this.translationService.translate('Task ID'),
      cell: (row) => row.id
    }
  ];

  title = '';
  paginator = {
    pageSize: 10,
    currentPage: 0,
    totalSize: 0
  };

  paginationNextPage;
  paginationPreviousPage;

  constructor(
    public dialogRef: MatDialogRef<TasksComponent>,
    private queryService: QueryService,
    private translationService: TranslationService,
    private applicationStateService: ApplicationStateService,
    private queryTypePipe: QueryTypeUiPipe,
    @Inject(MAT_DIALOG_DATA) public flag,
    private dashboardService: DashboardService,
    private userService: UserService,
  ) {
    super();
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
  }

  ngOnInit() {
    const tasks = this.queryService.tasksChanged
    const resultObservable = tasks.pipe(
      mergeMap((tasksData: any) => this.userService.getCurrentUser().pipe(map(user => {
        if (user.roles.includes(UserRoles.USER)) {
          tasksData.result = tasksData.result.filter((task) => task.createdBy === user.username);
          this.displayedColumnsGeo = remove(this.displayedColumnsGeo, ((column: string) => column !== 'user'));
        }
        return tasksData;
      })))
    )

    this.loadTasks(this.flag);
    const resultObservableSub = resultObservable.subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<Schedule>(data.result);
      this.tasks = data.result;
      this.paginator.totalSize = data.meta.totalResults;
      this.paginator.currentPage = data.meta.page - 1;
      this.paginationNextPage = data.meta.page + 1;
      this.paginationPreviousPage = data.meta.page - 1;
    }); 

    const mobileSidenavSubscription = this.dashboardService.toggleSidenav.subscribe(
      (flag: boolean) => {
        this.sidenavIsOpen = flag;
      }
    );

    this.subscriptions.push(mobileSidenavSubscription, resultObservableSub);
  }

  cancelRobot(robot) {
    const cancelText = 'Task has been successfully canceled';
    const notCancelText = 'Task has not been canceled';
    if (robot.scheduleType === 'radical-monitoring' || robot.scheduleType === 'monitor-changes') {
      this.queryService.clearRadicalMonitoring.next(true);
    }
    const cancelRobotSubscription = this.queryService.cancelRobot(robot).subscribe(
      () => {
        this.showMessage(this.translationService.translate(cancelText));
        this.loadTasks(this.flag);
      },
      (error: string) =>
        this.showMessage(this.translationService.translate(notCancelText))
    );
    this.subscriptions.push(cancelRobotSubscription);
  }

  loadTasks(flag) {
    this.flag = flag;
    this.title = (this.flag === 'false') ? 'Live tasks' : 'Past tasks';
    const getTasksSubscription = this.queryService.getPaginatedTasks({ flag: this.flag }).subscribe();
    this.subscriptions.push(getTasksSubscription);

    this.geoColumns.forEach(column => {
      if (column.columnDef === 'end_date') {
        column.header = this.title === 'Live tasks' ? this.translationService.translate('Expiring date')
          : this.translationService.translate('End date');
      }
    });
  }

  onPaginatePageChange(event) {
    if (event.previousPageIndex < event.pageIndex) {
      this.paginator.currentPage = this.paginator.currentPage + 1;
      const page = this.paginator.currentPage + 1;
      this.queryService.getPaginatedTasks({
        flag: this.flag,
        page,
      }).subscribe();
    } else {
      this.paginator.currentPage = this.paginator.currentPage - 1;
      const page = this.paginator.currentPage + 1;
      this.queryService.getPaginatedTasks({
        flag: this.flag,
        page,
      }).subscribe();
    }
  }

  onTabChange(event) {
    const canceled = event.index === 0 ? 'false' : 'true';
    this.loadTasks(canceled);
  }

  getFrequency(frequency) {
    if (frequency === IntervalMinutes.HIGH) {
      return QueryFrequency.HIGH;
    }

    if (frequency === IntervalMinutes.MEDIUM) {
      return QueryFrequency.MEDIUM;
    }

    if (frequency === IntervalMinutes.LOW) {
      return QueryFrequency.LOW;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
