import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {
  AnimationBuilder,
  AnimationFactory,
  AnimationMetadata,
  AnimationPlayer,
  style,
  animate,
} from '@angular/animations';

@Directive({
  selector: '[sxpAnimate]',
})
export class AnimateDirective {
  @Input() animateInAnimation: AnimationMetadata | AnimationMetadata[];
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    this.animate();
  }

  private animating: boolean;
  private player: AnimationPlayer;
  private defaults: any = {
    offset: 0,
  };

  constructor(
    private el: ElementRef,
    private animationBuilder: AnimationBuilder
  ) { }

  ngOnInit() {
    this.initialize();
    this.animate();
  }

  private initialize(): void {
    let animation: AnimationFactory;

    if (
      this.animateInAnimation !== null &&
      this.animateInAnimation !== undefined
    ) {
      animation = this.animationBuilder.build(this.animateInAnimation);
    } else {
      animation = this.animationBuilder.build([
        style({ transform: 'translateX(-50px)', opacity: 0 }),
        animate('1000ms cubic-bezier(0.1, 0.7, 0.6, 0.9)',
          style({
            transform: 'translateX(0)', opacity: 1
          }))
      ]);
    }

    this.player = animation.create(this.el.nativeElement);
    this.player.init();
  }

  private animate(): void {
    const inView = this.isInViewport();

    if (!inView) {
      this.animating = false;
    }
    if (!inView || this.animating){
      return;
    } 
    if(inView){
      if(this.el.nativeElement.classList.contains('visited') == false){
        this.el.nativeElement.classList.add('visited');
        this.animating = true;
        this.player.play();
      }
    }
  }

  private isInViewport(): boolean {
    const bounding = this.el.nativeElement.getBoundingClientRect();

    let top =
      bounding.top -
      (window.innerHeight || document.documentElement.clientHeight);
    let bottom = bounding.top + bounding.height + this.defaults.offset;

    return top < 0 && bottom > 0;
  }
}