import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GroupNotification } from '../../models/group-notifications.model';
import { FeatureNotificationsStore } from './feature-notifications.store';
import { FeatureNotificationResponse } from 'datalayer/models/background-jobs/feature-notification';
import { FeatureGroupNotificationsComponent } from './feature-group-notifications.component';
import { AuthService } from 'src/app/services/authentication/auth.service';
@Injectable({
  providedIn: 'root'
})
export class FeatureGroupNotificationsService extends GroupNotification {
  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private toastStore: FeatureNotificationsStore) {
    super();
    this.authService.isAuthenticated.subscribe(isLogged => {
      if (!isLogged) {
        this.cleanAllToasts();
      }
    });
  }

  processNotification(message: FeatureNotificationResponse) {
    if (!this.toastStore.hasNotificationWithId(message.target_id)) {
      this.addToast(message);
    }

    // clean an old notifications for group if SOMEDUS was ran again
    if (this.toastStore.hasDoneNotification(message.target_id)) {
      this.toastStore.cleanNotificationsById(message.target_id);
    }

    this.toastStore.addNotification(message);
  }

  protected cleanAllToasts() {
    this.toastr.toasts.forEach(t => {
      t.toastRef.close();
      this.toastStore.cleanNotificationsById(t.toastRef.componentInstance.id);
    });
  }

  protected addToast(message: FeatureNotificationResponse) {
    const toast = this.toastr.success(null, null, {
      toastComponent: FeatureGroupNotificationsComponent,
      positionClass: 'toast-bottom-right'
    });

    toast.toastRef.componentInstance.id = message.target_id;
    toast.toastRef.componentInstance.toast = toast;
  }
}
