import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { Action } from '../../classes/action.class';
import { BaseDialogComponent } from '../../classes/base-dialog.component';
import { BillingActions } from 'src/app/shared/models/billing-action.model';
import { CostInfoActionDialogModel } from '../../models/cost-info-action-dialog.model';
import { Themes } from '../../models/skins.model';

@Component({
  selector: 'app-cost-info-action-dialog',
  templateUrl: './cost-info-action-dialog.component.html',
  styleUrls: ['./cost-info-action-dialog.component.scss']
})
export class CostInfoActionDialogComponent extends BaseDialogComponent implements OnInit {
  targetMsisdn: string;
  message: string;
  title: string;
  cost: number;
  theme: Themes;
  action: BillingActions;

  constructor(
    public dialogRef: MatDialogRef<CostInfoActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CostInfoActionDialogModel,
    router: Router,
    private appConfigService: AppConfigService
  ) {
    super(dialogRef, router);
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    this.title = this.data.title;
    this.cost = this.data.cost;
    this.message = this.data.message;
    this.action= this.data.action;
  }

  onSuccess(action: Action = new Action({ key: 'success' })) {
    this.dialog.close(action);
  }
}
