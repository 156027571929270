import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(array: string[], toCompare: string, mode: FilterPipeMode = 'equal', minCharacters = 1): string[] {
    if (toCompare?.length < minCharacters || !Array.isArray(array)) {
      return [];
    }

    if (mode === 'equal') {
      return array.filter(item => item === toCompare);
    } else if (mode === 'startsWith') {
      return array.filter(item => item.toLowerCase().startsWith(toCompare.toLowerCase()));
    } else if (mode === 'includes') {
      return array.filter(item => item.toLowerCase().includes(toCompare.toLowerCase()));
    }

    return array;
  }
}

type FilterPipeMode = 'equal' | 'startsWith' | 'includes';
