<mat-card class="aoi-query-card">
  <mat-card-content>
    <div class="row aoi-table">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="mac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MAC</th>
          <td mat-cell *matCellDef="let row"> {{ row.MAC }}</td>
        </ng-container>
        <ng-container matColumnDef="imsi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>IMSI</th>
          <td mat-cell *matCellDef="let row"> {{ row.IMSI }} </td>
        </ng-container>
        <ng-container matColumnDef="mno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MNO</th>
          <td mat-cell *matCellDef="let row"> </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Date' | translate}} </th>
          <td mat-cell *matCellDef="let row">
            {{ row.Location.SeenPeriodStart ? (row.Location.SeenPeriodStart | date: 'dd.MM.yyyy HH:mm') : (row.Timestamp ? (row.Timestamp | date: 'dd.MM.yyyy HH:mm') : '') }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showLocation(row)"
          [class.selected-aoi]="selectedRow && selectedRow.Id === row.Id"></tr>
      </table>
      <mat-progress-bar *ngIf="loader" [color]="'accent'" [mode]="'query'" [value]="50" [bufferValue]="75">
      </mat-progress-bar>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
