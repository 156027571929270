import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataSource } from 'datalayer/models/platform-models/enums';
import { PlatformImages, PlatformNames } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';

export interface BaseModalHeader {
  label: string;
  platform?: {
    id: DataSource;
    url: string;
  };
  hasParent?: boolean;
}

@Component({
  selector: 'app-base-modal-component',
  templateUrl: './base-modal-component.component.html',
  styleUrls: ['./base-modal-component.component.scss']
})
export class BaseModalComponentComponent implements OnInit {
  @Input() headerDetails: BaseModalHeader;
  @Input() hideCoverSection: boolean = false;
  @Output() onPushBack = new EventEmitter<boolean>();
  @Output() onClosePressed = new EventEmitter<boolean>();
  platformImages = PlatformImages;
  platformNames = PlatformNames;
  constructor(public dialogRef: MatDialogRef<BaseModalComponentComponent>) {}

  ngOnInit(): void {}

  close(): void {
    if (this.headerDetails.hasParent) {
      this.onClosePressed.emit(true);
    } else {
      this.dialogRef.close();
    }
  }

  pushBack() {
    this.onPushBack.emit(true);
  }

  navigateToUrl(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
