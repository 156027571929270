import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { LoaderService } from 'src/app/modules/profiler/services/loader.service';
import { tap, catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QueryInterceptor implements HttpInterceptor {

  private pendingIntelRequestsCount = new BehaviorSubject(0);

  constructor(
    private loaderService: LoaderService
  ) {

    this.pendingIntelRequestsCount.subscribe(count => {
      if (count > 0) {
        this.loaderService.isLoading.next(true);
      } else {
        this.loaderService.isLoading.next(false);
      }
      this.loaderService.counter.next(count);
    });
  }

  sleep(time): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), time);
    });
  }

  // teardown logic for cancelling requests
  markComplete() {
    this.pendingIntelRequestsCount.next(this.pendingIntelRequestsCount.value - 1);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;

    // assuming all intel-related requests contain 'intel' in their path
    if (req.url.includes('/osint')) {
      this.pendingIntelRequestsCount.next(this.pendingIntelRequestsCount.value + 1);

      // And subscribe to the original observable to ensure the HttpRequest is made
      const sub = next.handle(req)
        .pipe(
          tap(
            event => {
              lastResponse = event;
              if (event instanceof HttpResponse) {
                this.markComplete();
              }
            },
          ),
          catchError((err: any) => {
            error = err;
            // this.markComplete(); //maybe this is needed
            // TODO: error handling if required
            return throwError(err);
          }),
          finalize(() => {
            if (lastResponse.type === HttpEventType.Sent && !error) {
              // cancelled requests
              this.markComplete();
            }
          })
        );
      return sub;
    }

    return next.handle(req);
  }
}

