import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-statistics-grafana',
  templateUrl: './statistics-grafana.component.html',
  styleUrls: ['./statistics-grafana.component.scss']
})
export class StatisticsGrafanaComponent implements OnInit{
  grafanaDashboardUrl: SafeUrl = '';

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.grafanaDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.grafanaDashboardUrl);
  }
}
