export function debounce(timeout = 300) {
  let timeoutRef: ReturnType<typeof setTimeout>;

  return function (_target: unknown, _propertyKey: unknown, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function debounce(...args: unknown[]) {
      clearTimeout(timeoutRef);
      timeoutRef = setTimeout(() => {
        original.apply(this, args);
      }, timeout);
    };

    return descriptor;
  };
}
