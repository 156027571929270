import { Component, Inject } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { EmailService } from 'src/app/services/email/email.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-request-location-history-dialog',
  templateUrl: './request-location-history-dialog.component.html',
  styleUrls: ['./request-location-history-dialog.component.scss']
})
export class RequestLocationHistoryDialogComponent extends BaseComponent  {
  currentUser;
  periods = ['last 30 days', 'last 60 days', 'last 90 days'];
  selectedPeriod;

  constructor(
    public dialogRef: MatDialogRef<RequestLocationHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData,
    private translationService: TranslationService,
    private emailService: EmailService,
    private localStorageService: LocalStorageService,
  ) {
    super();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  selectPeriod(event) {
    this.selectedPeriod = event.value;
  }

  sendEmail() {
    this.currentUser = this.localStorageService.getCurrentUser();
    const emailData = new FormData();

    emailData.append('title', this.translationService.translate('Location History Request'));
    emailData.append('content',
      `Target:${this.requestData.target}<br>
      Username: ${this.currentUser.identity}<br>
      Tenant ID: {{tenantid}}<br>
      Time period: ${this.selectedPeriod}<br>`);
    emailData.append('username', `${this.currentUser.identity}<br>`);
    emailData.append('message', 'sent a location history request<br>');

    this.emailService.supportRequest(emailData).subscribe(
      () => {
        this.showMessage(this.translationService.translate('Request sent successfully!'));
        this.onClose();
      },
      () => {
        this.showMessage(this.translationService.translate('Request has not been sent'));
      }
    );
  }
}
