import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { User, UserRoles } from 'src/app/services/user/user.model';
import { LimitType } from 'src/app/shared/models/billing-action.model';
import { LimitTypeLabelsMap } from 'src/app/shared/models/credit-pools.model';
import { UserDialogFormFields, UserDialogFormValidationPatterns } from './user-dialog.model';

@Injectable()
export class UserDialogFormService {
  constructor(private userBillingService: UserBillingService) {}
  public readonly UNASSIGN_FORM_POOL_PREFIX: string = 'unassign_';

  public generateBaseFormControls(user: User, editMode: boolean): Observable<{ [key: string]: FormControl }> {
    const username = editMode ? user.username : '';
    const email = editMode ? user.email : '';
    const otpPhone = editMode ? user.otpPhone : '';
    const role = editMode && user.roles.length ? user.roles[0] : '';
    const features = editMode && user.features?.length ? user.features[0] : '';

    const userBaseFormControls = {
      [UserDialogFormFields.USERNAME]: new FormControl(username, [
        Validators.required,
        Validators.pattern(UserDialogFormValidationPatterns.username),
      ]),
      [UserDialogFormFields.EMAIL]: new FormControl(
        { value: email, disabled: !!user && user?.roles.includes(UserRoles.SUPPORT) },
        Validators.pattern(UserDialogFormValidationPatterns.email)
      ),
      [UserDialogFormFields.OTP_PHONE]: new FormControl(otpPhone),
      [UserDialogFormFields.ROLE]: new FormControl(role, Validators.required),
      [UserDialogFormFields.FEATURE]: new FormControl(features),
    };

    return of(userBaseFormControls);
  }

  public generateCreditPoolsFormControls(user: User): Observable<{ [key: string]: FormControl }> {
    const { limitType } = user;
    let balance: number = this.userBillingService.getUserCurrentBalance(user);
    let controls: { [key: string]: FormControl } = {
      [UserDialogFormFields.LIMIT_TYPE]: new FormControl(LimitTypeLabelsMap[limitType] || LimitType.FIXED),
    };

    if (!this.userBillingService.isDistributedBalance()) {
      this.userBillingService.getAvailablePools().forEach((pool) => {
        if (isNaN(balance)) {
          balance = 0;
        }
        const maxValue: number = pool.unassignCredits + balance;
        controls[`${this.UNASSIGN_FORM_POOL_PREFIX}${pool.value}`] = new FormControl({
          value: this.userBillingService.toDecimal(pool.unassignCredits),
          disabled: true,
        });
        controls[pool.value] = new FormControl(balance, [
          Validators.required,
          Validators.max(maxValue),
          Validators.pattern(new RegExp(UserDialogFormValidationPatterns.creditsOneDecimal)),
        ]);
      });
    }

    if (this.userBillingService.isDistributedBalance()) {
      this.userBillingService.getAvailablePools().forEach((pool) => {
        let poolValue = 0;
        if (!!user && Object.keys(user?.currentBalance).length) {
          poolValue = user?.currentBalance[pool.value] || 0;
        }
        const maxValue: number = pool.unassignCredits + poolValue;
        controls[`${this.UNASSIGN_FORM_POOL_PREFIX}${pool.value}`] = new FormControl({
          value: this.userBillingService.toDecimal(pool.unassignCredits),
          disabled: true,
        });
        controls[pool.value] = new FormControl(this.userBillingService.toDecimal(poolValue), [
          Validators.required,
          Validators.max(maxValue),
          Validators.pattern(new RegExp(UserDialogFormValidationPatterns.creditsOneDecimal)),
        ]);
      });
    }
    return of(controls);
  }
}
