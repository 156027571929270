import { Pipe, PipeTransform } from '@angular/core';
import { flatten } from 'lodash-es';

@Pipe({
  name: 'mapArrayToProp'
})
export class MapArrayToPropPipe implements PipeTransform {

  transform(value: Array<any>, prop: string): Array<any> {
    if (Array.isArray(value)) {
      return flatten(value.map(v => v[prop])).filter(v => v);
    }

    return value;
  }

}
