import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
    template: `
       <div @tooltip>
      <img #img [src]="text">
      </div>
`,
    styles: [
        `
        :host {
            display: block;
          }

          div {
            background-color: #ededed;
            box-shadow:0 0 8px 1px rgb(0 0 0 / 16%);
            padding: 0.5rem;
            border-radius: 4px;
          }

    `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('tooltip', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(0, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate(0, style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class ImageOverlayComponent implements AfterViewInit {
    @Input() text = '';
    @Input() width: string = '300px';
    @Input() height: string = '300px';

    @ViewChild('img') img: ElementRef<HTMLImageElement>;

    constructor() { }

    ngAfterViewInit() {
      this.img.nativeElement.style.width = this.width;
      this.img.nativeElement.style.height = this.height;
    }


}
