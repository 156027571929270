import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<unknown>) {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass('base-modal');
  }
}
