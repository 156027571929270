import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SomedusRequestObj } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { BaseService } from 'src/app/services/base.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService extends BaseService {
  private readonly DEFAULT_SOMEDUS_PENDING_STATUSES: JobStatus[] = [
    JobStatus.PENDING,
    JobStatus.REISSUED,
    JobStatus.EXPIRED
  ];

  constructor(private httpClient: HttpClient, protected router: Router, protected snackBar: MatSnackBar) {
    super(router, snackBar);
  }

  getSomedusStatus(targetId: string): Observable<SomedusRequestObj[]> {
    let params = new HttpParams();
    this.DEFAULT_SOMEDUS_PENDING_STATUSES.forEach(jobStatus => (params = params.append('status', jobStatus)));
    return this.httpClient.get<SomedusRequestObj[]>(`${this.fastAPIurl}/features/${targetId}/somedus`, {
      params
    });
  }

  getPendingSomedusFeatures(): Observable<SomedusRequestObj[]> {
    let params = new HttpParams();
    this.DEFAULT_SOMEDUS_PENDING_STATUSES.forEach(jobStatus => (params = params.append('status', jobStatus)));
    return this.httpClient
      .get<SomedusRequestObj[]>(`${this.fastAPIurl}/features`, { params })
      .pipe(map(result => transformSnakeToCamel(result)));
  }

  updateFeatureStatus(requestId: string, status: JobStatus) {
    return this.httpClient.post(`${this.fastAPIurl}/somedus/${requestId}?request_status=${status}`, {});
  }

  getSomedusData(targetId: string, requestId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.fastAPIurl}/features/${targetId}/somedus?request_id=${requestId}&status=${JobStatus.DONE}`
    );
  }

  requestForSomedus(requestBody: SomedusRequestObj[]): Observable<any> {
    requestBody.forEach(item => {
      if (item.targetId) {
        item['target_id'] = item.targetId;
        item['phone_numbers'] = item.phoneNumbers;
        item['email_addresses'] = item.emailAddresses;

        delete item['tenantId'];
        delete item['targetId'];
        delete item['requestId'];
        delete item['phoneNumbers'];
        delete item['emailAddresses'];
        delete item['imageUrl'];
      }
    });
    return this.httpClient.post<any>(`${this.fastAPIurl}/somedus/`, requestBody).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(error => {
        if (error.status === 402) {
          this.showMessage('Not enough credits');
          return throwError('Not enough credits');
        }
        return throwError(error.message);
      })
    );
  }
}
