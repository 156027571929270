<div *ngIf="!isMobileResolution" class="quick-query-input-container" [ngClass]="{'hide-flag': !showFlag}">
  <img class="search-icon" src="assets/static/images/search_icon_global_query.svg" height="20" width="20">
  <input #phoneInput placeholder="{{'New query' | translate}}..." [formControl]="number"
    class="quick-query-input-desktop" matInput (keydown)="keyDownFunction($event, number.value)" type="text" ng2TelInput
    [ng2TelInputOptions]="{initialCountry: 'auto', allowDropdown: false, autoPlaceholder: 'off'}">
  <mat-icon class="remove-btn clickable" (click)="removeValue()">close</mat-icon>
</div>
<div *ngIf="isMobileResolution" class="row quick-query-mobile">
  <div class="col-9">
    <input #phoneInput [formControl]="number" placeholder="{{'Query number' | translate}}..."
      [ngClass]="{'hide-flag': !showFlag}" class="quick-query-input-mobile" matInput
      (keydown)="keyDownFunction($event, number.value)" type="text" ng2TelInput
      [ng2TelInputOptions]="{initialCountry: 'auto', allowDropdown: false, autoPlaceholder: 'off'}">
  </div>
  <div class="col-3 submit-btn">
    <button mat-button (click)="clickEvent()">{{'GO' | translate}}</button>
  </div>
  <mat-error class="quick-query-error-msg" *ngIf="errorMsg">{{'Enter a valid number' | translate}}</mat-error>
</div>
