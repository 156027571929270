import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { TasksComponent } from 'src/app/components/tasks/tasks.component';
import { ExtraLocationInformationDialogComponent, extraLocationInformationTypes } from 'src/app/modules/trg-common/components/extra-location-information-dialog/extra-location-information-dialog.component';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { QueryService } from 'src/app/services/query/query.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { WsService } from 'src/app/services/websocket/ws.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { Query } from 'src/app/shared/models/query-item.model';
import { Themes } from 'src/app/shared/models/skins.model';

@Component({
  selector: 'app-mobile-sidenav',
  templateUrl: './mobile-sidenav.component.html',
  styleUrls: ['./mobile-sidenav.component.scss']
})
export class MobileSidenavComponent extends BaseComponent implements OnInit {
  basicImageSrc = 'assets/static/images/';
  isWhiteTheme: Boolean = false;
  @Output() refreshMap = new EventEmitter<boolean>();
  theme: string;
  user = this.localStorageService.getCurrentUser();

  constructor(
    private dashboardService: DashboardService,
    private authSessionService: AuthService,
    private router: Router,
    private queryService: QueryService,
    public dialog: MatDialog,
    private appConfigService: AppConfigService,
    private websocketService: WsService,
    private localStorageService: LocalStorageService,
  ) {
    super();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit() {
    if (this.theme === 'GEOLOC') {
      this.basicImageSrc = `${this.basicImageSrc}geoloc_`;
    }

    if (this.theme === 'WHITE') {
      this.isWhiteTheme = true;
    }

    this.subscription = this.websocketService.onEvent('state-update-geolocation').subscribe(
      (query: Query) => {
        if (query && query.gps && query.gps.locationType && query.createdBy === this.user.identity) {
          this.openExtraLocationInformationDialog(extraLocationInformationTypes.GPS, query);
        } else if (query && query.nmr && (query.nmr.trialterationLocation || query.nmr.sectorLocation) &&
          query.createdBy === this.user.identity  && (query['tag'] !== '' && !query['tag'])) {
          this.openExtraLocationInformationDialog(extraLocationInformationTypes.NMR, query);
        }
      }
    );
  }

  closeSidenav() {
    this.dashboardService.toggleSidenav.next(false);
  }

  logout() {
    this.authSessionService.logout();
  }

  navigateToOptions() {
    this.dialog.closeAll();
    this.router.navigate(['user-options']);
  }

  navigateToSettings() {
    this.dialog.closeAll();
    this.router.navigate(['user-settings']);
  }

  navigateToDashboard() {
    this.dialog.closeAll();
    this.router.navigate(['discovery']);
    this.dashboardService.toggleSidenav.next(false);
    this.refreshMap.emit(true);
    this.queryService.resetVariablesState();
  }

  navigateToTasks(flag) {
    this.dialog.open(TasksComponent, {
      width: '100vw',
      height: '100vh',
      data: flag, // canceled task = true OR false
    });
  }

  public openChangePasswordDialog() {
    this.dialog.open(ResetPasswordComponent, {
      width: '100vw',
      height: '100vh',
      panelClass: this.theme === 'GEOLOC' ? 'geoloc-theme' : (this.isWhiteTheme ? 'white-theme' : ''),
    });
  }

  private openExtraLocationInformationDialog(type: extraLocationInformationTypes, query: Query) {
    this.dialog.open(ExtraLocationInformationDialogComponent, {
      width: '90vw',
      height: 'auto',
      disableClose: true,
      data: { type, query },
      panelClass:'extra-location-information-dialog'
    });
  }

  getMobileViewLogo(): string {
    if (this.theme === Themes.FRESHVALE) {
      return 'assets/static/images/freshvale-favicon-logo.png';
    }
    return `${this.basicImageSrc}intellectus.png`;
  }

}
