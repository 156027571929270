export enum LoginState {
    'ENTERING_USERNAME' = 'ENTERING_USERNAME',
    'ENTERING_PASSWORD' = 'ENTERING_PASSWORD',
}

export enum FormState {
    'LOGIN' = 'LOGIN',
    'CHANGE_PASSWORD' = 'CHANGE_PASSWORD',
    'CONFIGURE_VERIFICATION' = 'CONFIGURE_VERIFICATION',
    'VERIFICATION' = 'VERIFICATION',
    'VERIFY_OTP' = 'VERIFY_OTP'
}

export interface PasswordValidationResult {
    validated_policies: PasswordValidationType,
    score: number,
    rank: string,
    valid: boolean
}

export interface PasswordValidationType {
    length: boolean,
    uppercase: boolean,
    lowercase: boolean,
    digits: boolean
}
export interface OTPConfiguration {
    enabled: boolean,
    password: string,
    otp_code?: string
}