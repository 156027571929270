import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Group } from 'datalayer/models';
import { GroupDTO } from 'src/app/modules/data-layer/services/social/group/group-dto';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule
})

export class GroupApiService extends BaseApiService<Group, GroupDTO> {

  constructor(httpClient: HttpClient) {
    super(httpClient, GroupDTO);
  }

  public getModelName(): string {
    return Group.name;
  }

}
