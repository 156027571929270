import { Injectable, TemplateRef, } from '@angular/core';
import { AnchorPosition, Marker, TemplateMarker } from 'src/app/modules/mapV2/models/map.models';
import { Query } from 'src/app/shared/models/query-item.model';
import { randomString } from 'src/app/shared/util/helper';
import { MapHelperService } from '../map-helper/map-helper.service';

@Injectable({
  providedIn: 'root',
})
export class IpLocationQueryMarkerGenerator {
  private readonly DISTANCE_M: number = 2000;
  private readonly BEARING: number = 90;

  constructor(private mapHelperService: MapHelperService, private container: TemplateRef<{query: Query}>) {}

  public generateIPLocationMarker(query: Query): Marker {
    const [fakeLat, fakeLng] = this.mapHelperService.generateFakeGeoPoint(
      query.location.coordinates[1],
      query.location.coordinates[0],
      this.DISTANCE_M,
      this.BEARING
    );

    return new TemplateMarker({
      id: `no-gps-data-request-marker-${randomString()}`,
      lat: fakeLat,
      lng: fakeLng,
      elementRef: this.container,
      popupHTML: '',
      isPopupWindowOpen: true,
      extendMapBounds: false,
      anchorPosition: AnchorPosition.MIDDLE,
      getEmbededView: () =>
        this.container.createEmbeddedView({
          query: query,
        }),
    });
  }
}
