import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum DashboardView {
  LOG = 'logView',
  QUERY = 'queryView',
  ANALYTICS = 'analyticsView'
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  componentsView = new BehaviorSubject<string>(DashboardView.LOG);
  showIntelTabContent = new BehaviorSubject<boolean>(false);
  showLogGeoTab = new BehaviorSubject<boolean>(false);
  showLogTab = new BehaviorSubject<boolean>(true);
  showRadicalMonitoringTab = new BehaviorSubject<object>({currentTab: false, notificationObj: {}});
  btnPosition = new Subject<number>();

  // mobile
  showMobileGeoDetails = new BehaviorSubject<boolean>(false);
  toggleSidenav = new Subject<boolean>();

  // hide tab if there was not an intel query for this telno in the past
  showIntelTab = new BehaviorSubject<boolean>(false);
  showQaugeOnMobile = new BehaviorSubject<boolean>(true);

  // intel result list page
  resetIntelSearchResults = new Subject<boolean>();

  constructor() { }
}
