import { UserRoles } from "src/app/services/user/user.model";
import { BalanceType, BillingPolicies, LimitType } from "src/app/shared/models/billing-action.model";
import { CreditPools } from "src/app/shared/models/credit-pools.model";

export enum UserDialogFormFields {
  QUOTA = 'quota',
  USERNAME = 'username',
  AVAILABLE_QUOTA = 'availableQuota',
  EMAIL = 'email',
  ROLE = 'role',
  OTP_ENABLED = 'otpEnabled',
  OTP_CHANNEL = 'otpChannel',
  OTP_PHONE = 'otpPhone',
  LIMIT_TYPE = 'limitType',
  FEATURE = 'feature',
}

export enum UserDialogFormValidationPatterns {
  email = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$',
  // username allow only lowercase letters,numbers, dot (.) and underscore (_). Username can't finish with special character
  username = '^[a-z0-9]+([_s.]?[a-z0-9])*$',
  creditsOneDecimal = '^-?\\d*[.]?\\d{0,1}$'
}

export interface UserForm {
  policies?: BillingPolicies[];
  balanceType?: BalanceType;
  currentBalance?: number | CreditPools;
  initialBalance?: number | CreditPools;
  limitType?: LimitType;
  defaultPool?: number;
  role: UserRoles;
  feature: string;
  otpPhone: string;
  email?: string;
}
