// Global modules
import { DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { GoogleChartsModule, ScriptLoaderService } from 'angular-google-charts';
import { Angulartics2Module } from 'angulartics2';
import { DataLayerModule } from 'datalayer/data-layer.module';
import 'hammerjs';
import { IntersectionObserverHooks, LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { ToastrModule } from 'ngx-toastr';
// Local modules
import { AppRoutingModule } from 'src/app/app-routing.module';
// Components
import { AppComponent } from 'src/app/app.component';
import { BaseComponent } from 'src/app/base/base.component';
import { AddUserDialogComponent } from 'src/app/components/admin/users/user-dialog/user-dialog.component';
import { AdvancedGeolocationQueryConfirmationDialogComponent } from 'src/app/components/advanced-geolocation-query-confirmation-dialog/advanced-geolocation-query-confirmation-dialog.component';
import { ImportDataRequestDialogComponent } from 'src/app/components/analytics/import-data-request-dialog/import-data-request-dialog.component';
import { BottomSheetMobileComponent } from 'src/app/components/bottom-sheet-mobile/bottom-sheet-mobile.component';
import { ContactFormComponent } from 'src/app/components/contact-form/contact-form.component';
import { CreditsGaugeComponent } from 'src/app/components/credits-gauge/credits-gauge.component';
import { DarkwebDashboardComponent } from 'src/app/components/darkweb-dashboard/darkweb-dashboard.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { FrequencyComponent } from 'src/app/components/frequency/frequency.component';
import { GeofencingComponent } from 'src/app/components/geofencing/geofencing.component';
import { GeolocationTargetDetailsComponent } from 'src/app/components/geolocation-details/components/geolocation-target-details/geolocation-target-details.component';
import { GeolocationDetailsComponent } from 'src/app/components/geolocation-details/geolocation-details.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { LogonLogoffComponent } from 'src/app/components/logon-logoff/logon-logoff.component';
import { MarketingBannerComponent } from 'src/app/components/marketing-banner/marketing-banner.component';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { NotificationItemComponent } from 'src/app/components/notification-item/notification-item.component';
import { NotificationsListComponent } from 'src/app/components/notifications-list/notifications-list.component';
import { PhotoCarouselModalComponent } from 'src/app/components/photo-carousel-modal/photo-carousel-modal.component';
import { ProximityComponent } from 'src/app/components/proximity/proximity.component';
import { AdvancedQueriesComponent } from 'src/app/components/queries/advanced-queries/advanced-queries.component';
import { AoiQueryTableComponent } from 'src/app/components/queries/aoi-query-table/aoi-query-table.component';
import { LocationQueryComponent } from 'src/app/components/queries/location-query/location-query.component';
import { QueriesComponent } from 'src/app/components/queries/queries.component';
import { SimpleQueryComponent } from 'src/app/components/queries/simple-query/simple-query.component';
import { QueryListItemComponent } from 'src/app/components/query-list-item/query-list-item.component';
import { QueryTypeUiPipe } from 'src/app/components/query-list-item/query-type-pipe';
import { GuesHeightDirective } from 'src/app/components/query-list/directives/gues-height.directive';
import { QueryListComponent } from 'src/app/components/query-list/query-list.component';
import { TagTypeUiPipe } from 'src/app/components/query-list/tag-type-pipe';
import { QuickQueryComponent } from 'src/app/components/quick-query/quick-query.component';
import { RequestLocationHistoryDialogComponent } from 'src/app/components/request-location-history-dialog/request-location-history-dialog.component';
import { RequestLocationHistoryImsiDialogComponent } from 'src/app/components/request-location-history-imsi-dialog/request-location-history-imsi-dialog.component';
import { ResetPasswordAdminComponent } from 'src/app/components/reset-password-admin/reset-password-admin.component';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { ScheduleComponent } from 'src/app/components/schedule/schedule.component';
import { EmailSettingsComponent } from 'src/app/components/settings/email-settings/email-settings.component';
import { StatisticsComponent } from 'src/app/components/statistics/statistics.component';
import { StatisticsGrafanaComponent } from 'src/app/components/statistics-grafana/statistics-grafana.component';
import { IcLogTableItemComponent } from 'src/app/components/tactical/ic-log-table-item/ic-log-table-item.component';
import { IcLogTableComponent } from 'src/app/components/tactical/ic-log-table/ic-log-table.component';
import { LawEnforcementDialogComponent } from 'src/app/components/tactical/law-enforcement-dialog/law-enforcement-dialog.component';
import { LiLogTableItemComponent } from 'src/app/components/tactical/li-log-table-item/li-log-table-item.component';
import { LiLogTableComponent } from 'src/app/components/tactical/li-log-table/li-log-table.component';
import { SiLogTableItemComponent } from 'src/app/components/tactical/si-log-table-item/si-log-table-item.component';
import { SiLogTableComponent } from 'src/app/components/tactical/si-log-table/si-log-table.component';
import { TacticalDashboardComponent } from 'src/app/components/tactical/tactical-dashboard/tactical-dashboard.component';
import { TasksComponent } from 'src/app/components/tasks/tasks.component';
import { VersionComponent } from 'src/app/components/version/version.component';
import { MobileSidenavModule } from 'src/app/modules/mobile-sidenav/mobile-sidenav.module';
import { TrgCommonUiModule } from 'src/app/modules/trg-common-ui/trg-common-ui.module';
import { TrgCommonModule } from 'src/app/modules/trg-common/trg-common.module';
import { AllNotificationPipe, UnreadNotificationPipe } from 'src/app/notification-filter.pipe';
// Pipes
// Providers
import { httpInterceptorProviders } from 'src/app/services/http-interceptors';
import { RecommendationsModule } from 'src/app/shared/modules/recommendations/recommendations.module';
import { GestureConfig } from '../gesture-config';
import { ConfigureTwoStepVerificationComponent } from './components/configure-two-step-verification/configure-two-step-verification.component';
import { TwoStepVerificationDialogComponent } from './components/configure-two-step-verification/two-step-verification-dialog/two-step-verification-dialog.component';
import { TwoStepVerificationComponent } from './components/configure-two-step-verification/two-step-verification/two-step-verification.component';
import { ConfirmEmailRecipientDialogComponent } from './components/confirm-email-recipient-dialog/confirm-email-recipient-dialog.component';
import { EmailResultsDialogComponent } from './components/email-results/email-results.component';
import { GeolocationTargetActionsComponent } from './components/geolocation-details/components/geolocation-target-actions/geolocation-target-actions.component';
import { ChangePasswordDialogComponent } from './components/login/change-password-dialog/change-password-dialog.component';
import { ChangePasswordComponent } from './components/login/change-password/change-password.component';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { TransferDataPipe } from './pipes/transfer-data.pipe';
import { ExtractPeerCustomMarkerComponent } from './shared/components/extract-peer-custom-marker/extract-peer-custom-marker.component';
import { ExtractPeerInfoCustomMarkerComponent } from './shared/components/extract-peer-info-custom-marker/extract-peer-info-custom-marker.component';
import { NearbyLocationCustomMarkerComponent } from './shared/components/nearby-location-custom-marker/nearby-location-custom-marker.component';
import { BaseMapModule } from './shared/modules/base-map.module';
import { NavMenuSubItemComponent } from './components/navbar/navbar-menu/nav-menu-sub-item/nav-menu-sub-item.component';
import { NavbarMenuComponent } from './components/navbar/navbar-menu/navbar-menu.component';
import { MapV2Module } from './modules/mapV2/mapV2.module';
import { SharedUiIntellectusModule } from '@fe-platform/shared-ui/intellectus';

registerLocaleData(localeEs, 'es', localeEsExtra);
registerLocaleData(localeEn, 'en');

function tokenGetter() {
  try {
    const currentUserJson: string | null = localStorage.getItem('currentUser');
    if (currentUserJson) {
      const currentUser = JSON.parse(currentUserJson);
      return currentUser.token;
    }
  } catch (e) {
    console.warn(e);
    return null;
  }
}
@NgModule({
  declarations: [
    NearbyLocationCustomMarkerComponent,
    ExtractPeerCustomMarkerComponent,
    ExtractPeerInfoCustomMarkerComponent,
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    QueryListComponent,
    QueryListItemComponent,
    LoginComponent,
    BaseComponent,
    NotificationItemComponent,
    AddUserDialogComponent,
    NotificationsListComponent,
    ResetPasswordComponent,
    UnreadNotificationPipe,
    AllNotificationPipe,
    GeolocationDetailsComponent,
    GeolocationTargetDetailsComponent,
    QueriesComponent,
    AdvancedQueriesComponent,
    ScheduleComponent,
    LogonLogoffComponent,
    GeofencingComponent,
    ProximityComponent,
    SimpleQueryComponent,
    FrequencyComponent,
    QuickQueryComponent,
    ResetPasswordAdminComponent,
    TwoStepVerificationDialogComponent,
    ConfigureTwoStepVerificationComponent,
    TwoStepVerificationComponent,
    ChangePasswordComponent,
    ChangePasswordDialogComponent,
    EmailResultsDialogComponent,

    // Intelligence
    // TODO: Create intelligence in its own module
    EmailSettingsComponent,
    ContactFormComponent,
    BottomSheetMobileComponent,
    PhotoCarouselModalComponent,
    // Custom pipes
    TagTypeUiPipe,
    QueryTypeUiPipe,
    TasksComponent,
    VersionComponent,
    CreditsGaugeComponent,
    RequestLocationHistoryDialogComponent,
    RequestLocationHistoryImsiDialogComponent,
    StatisticsComponent,
    StatisticsGrafanaComponent,
    LocationQueryComponent,
    AoiQueryTableComponent,
    SiLogTableComponent,
    SiLogTableItemComponent,
    ImportDataRequestDialogComponent,
    TacticalDashboardComponent,
    IcLogTableComponent,
    IcLogTableItemComponent,
    LiLogTableComponent,
    LiLogTableItemComponent,
    LawEnforcementDialogComponent,
    AdvancedGeolocationQueryConfirmationDialogComponent,
    DarkwebDashboardComponent,
    MarketingBannerComponent,
    GuesHeightDirective,
    ConfirmEmailRecipientDialogComponent,
    GeolocationTargetActionsComponent,
    NavbarMenuComponent,
    NavMenuSubItemComponent,
  ],
  imports: [
    DropzoneModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxFileDropModule,
    NgxHmCarouselModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    SweetAlert2Module.forRoot(),
    NgxGaugeModule,
    GoogleChartsModule,
    NgxCaptchaModule,
    LazyLoadImageModule,
    TrgCommonUiModule,
    TrgCommonModule,
    RecommendationsModule,
    MobileSidenavModule,
    BaseMapModule,
    MapV2Module,
    DataLayerModule,
    ToastrModule.forRoot(),
    HammerModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        idsRegExp: new RegExp('[a-z]\\d+[a-z]$')
      }
    }),
    SharedUiIntellectusModule,
  ],
  providers: [
    httpInterceptorProviders,
    DatePipe,
    TitleCasePipe,
    TransferDataPipe,
    DurationFormatPipe,
    QueryTypeUiPipe,
    ScriptLoaderService,
    { provide: MatBottomSheetRef, useValue: undefined },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: IntersectionObserverHooks }, // <-- Declare that you want to use ScrollHooks
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ],
  bootstrap: [AppComponent],
  exports: [TagTypeUiPipe, QueryTypeUiPipe, GuesHeightDirective]
})
export class AppModule { }
