<app-dialog>
  <div header>
    <span class="title">{{ 'Call log record' | translate }}</span>
  </div>

  <div body *ngIf="data">
    <div class="body-description">
      <p>{{ data.query.queryArgs.telno }} {{ 'was located during a call' | translate }}.</p>
      <p>{{ 'Do you want to extract details about the Call Peer' }}?</p>
    </div>

    <div class="countdown">
      <mat-progress-spinner
        class="countdown-spinner"
        mode="determinate"
        [value]="progress"
        strokeWidth="5"
        diameter="70"
      >
      </mat-progress-spinner>
      <div class="txt" *ngIf="counterValue > 0">
        <span *ngIf="counterValue < 10">0</span>{{ counterValue }} s
      </div>
    </div>

    <div class="body-options">
      <mat-checkbox
        class="body-option"
        [(ngModel)]="data.extractPeerInformation"
        (change)="data.extractPeerInformationAndLocate = !data.extractPeerInformation"
        >{{ 'Always obtain the peer details when available' | translate }}.</mat-checkbox
      >
      <mat-checkbox
        class="body-option"
        [(ngModel)]="data.extractPeerInformationAndLocate"
        (change)="data.extractPeerInformation = !data.extractPeerInformationAndLocate"
        >{{
          'Always obtain the peer details and geolocate when available' | translate
        }}.</mat-checkbox
      >
    </div>
  </div>

  <div footer>
    <app-credit-cost-button
      [action]="['callInfo', 'queryLocation']"
      [buttonText]="'Get information & geolocate'"
      [active]="true"
      [stylingClasses]="'cp-btn-primary cp-search-btn active'"
      (clicked)="onSuccessAction(availableActions.getInfoAndLocate)"
    ></app-credit-cost-button>

    <app-credit-cost-button
      [action]="'callInfo'"
      [buttonText]="'Get information'"
      [active]="true"
      [stylingClasses]="'cp-btn-ghost cp-search-btn locate-btn active'"
      (clicked)="onSuccessAction(availableActions.getInfo)"
    ></app-credit-cost-button>
  </div>
</app-dialog>
