<div>
  <span class="advanced-queries-title">{{'Advanced Options' | translate}}</span>
  <mat-slide-toggle [formControl]="advanced" (change)="toggleChange($event)"></mat-slide-toggle>
  <mat-tab-group class="advanced-options-tabs" *ngIf="advanced.value" mat-align-tabs="start" (selectedTabChange)="advancedType($event.index)">
    <mat-tab label="{{'Schedule' | translate}}">
      <ng-template matTabContent>
        <app-schedule></app-schedule>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'Log on' | translate}}">
      <ng-template matTabContent>
        <app-logon-logoff></app-logon-logoff>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'Geofencing' | translate}}">
      <ng-template matTabContent>
        <app-geofencing
          (selectedCountry)="onSelectedCountry($event)"
          (showSearchTool)="toggleSearchTool($event)"
          (showDrawingTools)="toggleDrawingTools($event)"
          (drawAreaOfInterestFromComponent)="onDrawnAreaOfInterestFromComponent($event)"
          [areaOfInterestDrawn]="areaOfInterest"
          (clearQueryMap)="onClearQueryMap($event)">
        </app-geofencing>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'Proximity' | translate}}">
      <ng-template matTabContent>
        <app-proximity></app-proximity>
        </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
