<app-dialog>
  <div header>{{ 'Select from Existing Targets' | translate }}</div>

  <div body class="target-body">
    <!-- All Targets -->
    <div class="targets-wrp" *ngIf="!showCasetargets">
      <div class="search-input-container flex-wrp">
        <img
          class="search-icon"
          src="assets/static/images/search_icon_global_query.svg"
          height="15"
        />
        <input
          [formControl]="searchText"
          class="search-input"
          matInput
          (keyup)="onKeyUp()"
          type="text"
        />
      </div>
      <div class="case-targets-wrapper selectable-case-targets-wrapper flex-wrp">
        <div class="selectable-wrp" *ngFor="let target of notAssociatedTargets; let i = index">
          <div class="selectable-target">
            <div class="top-row"></div>
            <div class="center-xs select-target-bottom-row">
              <img
                class="target-photo"
                [src]="
                  target.photos && target.photos[0]
                    ? getPhoto(target.photos[0])
                    : 'assets/static/images/no_photo.svg'
                "
                appFallbackImg="assets/static/images/no_photo.svg"
              />
              <div class="target-name global-text-overflow-wrap" [matTooltip]="target.alias">
                {{ target.alias }}
              </div>
              <div class="case-target-checkbox">
                <mat-checkbox
                  class="round-checkbox"
                  [(ngModel)]="target.checked"
                  (change)="addTargets($event, target)"
                ></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-paginator
        class="case-item-paginator"
        [length]="paginator.totalSize"
        [pageIndex]="paginator.currentPage"
        [pageSize]="paginator.pageSize"
        (page)="onPaginatePageChange($event)"
      >
      </mat-paginator>
    </div>

    <!-- Case Targets -->
    <div class="case-wrp" *ngIf="showCasetargets">
      <!-- <div class="selectable-search-input-container">
          <img class="selectable-search-icon" src="assets/static/images/search_icon_global_query.svg" height="15">
          <input [formControl]="searchText" class="selectable-search-input" matInput (keyup)="oncaseKeyUp()" type="text">
          <mat-icon class="selectable-remove-btn clickable" (click)="removeValue()">close</mat-icon>
        </div> -->
      <div class="targets-wrp case-wrp">
        <div class="case-targets-wrapper selectable-case-targets-wrapper flex-wrp">
          <div class="selectable-wrp" *ngFor="let target of paginatedCaseTargets; let i = index">
            <div class="selectable-target">
              <div class="top-row"></div>
              <div class="center-xs select-target-bottom-row">
                <img
                  class="target-photo"
                  [src]="
                    target.photos && target.photos[0]
                      ? getPhoto(target.photos[0])
                      : 'assets/static/images/no_photo.svg'
                  "
                  appFallbackImg="assets/static/images/no_photo.svg"
                />
                <div class="target-name global-text-overflow-wrap" [matTooltip]="target.alias">
                  {{ target.alias }}
                </div>
                <div class="case-target-checkbox">
                  <mat-checkbox
                    class="round-checkbox"
                    [(ngModel)]="target.checked"
                    (change)="addTargets($event, target)"
                  ></mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-paginator
          class="case-item-paginator"
          [length]="paginator.totalSize"
          [pageIndex]="paginator.currentPage"
          [pageSize]="paginator.pageSize"
          (page)="onCasePageChange($event)"
        ></mat-paginator>
      </div>
    </div>
  </div>

  <div footer>
    <div>
      <button mat-button class="btn-default mr-15" (click)="close()">
        {{ 'Cancel' | translate }}
      </button>
      <button
        mat-button
        class="btn-primary"
        (click)="addDataToTarget()"
        [disabled]="!this.checkedTargets.length"
      >
        {{ submitButtonText | translate }}
      </button>
    </div>
  </div>
</app-dialog>
