import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TransientUserWithJWTClaims } from '../authentication/types';
import { UserRoles } from '../user/user.model';

@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {

  currentUser: TransientUserWithJWTClaims;
  currentUserIsAdmin: boolean;

  constructor(private localStorageService: LocalStorageService) {
  }

  userIsAdmin() {
    this.currentUser = this.localStorageService.getCurrentUser();
    this.currentUserIsAdmin = this.currentUser.roles.indexOf(UserRoles.ADMIN) > -1;
    return this.currentUserIsAdmin;
  }

  userIsPowerUser() {
    this.currentUser = this.localStorageService.getCurrentUser();
    return this.currentUser.roles.indexOf(UserRoles.POWERUSER) > -1;
  }

  userIsSupportUser() {
    this.currentUser = this.localStorageService.getCurrentUser();
    return this.currentUser.roles.indexOf(UserRoles.SUPPORT) > -1;
  }

  userIsStandardUser() {
    this.currentUser = this.localStorageService.getCurrentUser();
    return this.currentUser.roles.indexOf(UserRoles.USER) > -1;
  }
}
