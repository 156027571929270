<mat-menu #childMenu="matMenu" [hasBackdrop]="true">
    <ul class="sub-menu-list" (mouseleave)="menuTrigger?.closeMenu()">
        <ng-container *ngFor="let item of items">
            <li (click)="handleClick(item)" routerLinkActive="router-link-active" [routerLink]="item.link" *ngIf="item.visible">
                <div class="item">
                    <div class="icon" *ngIf="item.svgIcon">
                        <mat-icon [svgIcon]="item.svgIcon"></mat-icon>
                    </div>
                    <div>
                        <h5 class="menu-name">{{item.name | translate }}</h5>
                        <p *ngIf="item.description" class="menu-description">{{item.description | translate }}</p>
                    </div>
                        <span *ngIf="item.betaSign" [ngClass]="item.betaSign && item.demoSign ? 'both-sign' : '' "  
                        class="beta-sign-label">BETA</span>
                       <span *ngIf="item.demoSign" class="demo-sign">DEMO</span>
                </div>
            </li>
        </ng-container>
    </ul>
</mat-menu>