import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/base/base.component';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import { normalizeToArray } from 'src/app/shared/util/helper';

@Component({
  selector: 'app-recommendation-datepicker',
  templateUrl: './recommendation-datepicker.component.html',
  styleUrls: ['./recommendation-datepicker.component.scss']
})
export class RecommendationDatepickerComponent extends BaseComponent implements OnInit {
  constructor(
    private ledgerService: LedgerService,
    private translationService: TranslationService,
    private userBillingService: UserBillingService
  ) { super(); }
  @ViewChild('calendar') calendar!: MatCalendar<any>;

  @Input() dates: string[] = [];
  @Output() selected$ = new EventEmitter<string[]>();

  selectedDays: string[] = [];
  minDate = new Date();
  maxDate = new Date();
  currentCredits: number = 0;

  ngOnInit(): void {
    this.minDate = new Date(this.dates[0]);
    this.maxDate = new Date(this.dates[this.dates.length - 1]);

    this.ledgerService.getCurrentUserLedgerItem().subscribe((user: User) =>
      this.currentCredits = this.userBillingService.getUserCurrentBalance(user)
    );
  }

  isSelected = (date: Date) => {
    return this.selectedDays.includes(moment(date).format('YYYY-MM-DD')) ? 'selected-date' : null;
  };

  myFilter = (d: Date | null): boolean => {
    return !d ? false : this.dates.includes(moment(d).format('YYYY-MM-DD'));
  };

  selectDate(date: Date | Date[], noinverse: boolean = false) {
    const dates = normalizeToArray(date).map(d => moment(d).format('YYYY-MM-DD'));

    for (const date of dates) {
      if (this.selectedDays.includes(date)) {
        if (!noinverse) {
          this.currentCredits += 1
          this.selectedDays.splice(this.selectedDays.indexOf(date), 1);
        }
      } else {
        if (this.currentCredits === 0) {
          this.showMessage(this.translationService.translate("Not enough credits"))
        } else {
          this.currentCredits -= 1
          this.selectedDays.push(date);
        }
      }
    }

    this.selected$.emit(this.selectedDays);
    this.calendar.updateTodaysDate();
  }

  selectAllAvailableDays() {
    this.selectDate(
      this.dates.map(d => new Date(d)),
      this.dates.length !== this.selectedDays.length
    );
  }
}
