import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkerSectorService } from '@app/services';
import { TargetSummarySectionIdentifier } from '@report-service-ts/data-models';
import { AdRtb, Cdr, Cell, EntityType, GeoJsonFeatureCollection, GeoLocation } from '@trg-commons/data-models-ts';
import { AdIdLocationHistoryDto, AdIntWsEvent, AdRtbExtended, EventChannel } from '@trg-commons/gio-data-models-ts';
import { Angulartics2 } from 'angulartics2';
import { DataSource } from 'datalayer/models/platform-models';
import { RefreshLocalCacheService } from 'datalayer/services/base/refresh-data.service';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';
import * as moment from 'moment';
import { combineLatest, merge, noop, Observable } from 'rxjs';
import { debounceTime, filter, skip, take, tap } from 'rxjs/operators';
import { AdIdService } from 'src/app/modules/ad-ids/shared/ad-id.service';
import { ProxyWsService } from 'src/app/modules/ad-ids/shared/proxy-ws.service';
import { CdrTarget } from 'src/app/modules/call-logs/components/cl-main-map-view/cl-table/models/cdr-target.model';
import {
  Button,
  ButtonState,
  Circle,
  ControlPosition,
  DrawMode, IconMarker, Polygon
} from 'src/app/modules/mapV2/models/map.models';
import { FeedService } from 'src/app/modules/profiler/services/feed.service';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';
import { InvestigationInfoWindowRef } from 'src/app/modules/visual-investigation/models/investigation.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { MapHelperService } from 'src/app/services/map-helper/map-helper.service';
import { NearbyLocationsService } from 'src/app/services/nearby-locations.service';
import { QueryService } from 'src/app/services/query/query.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import { WsService } from 'src/app/services/websocket/ws.service';
import { HistoryPlayback } from 'src/app/shared/classes/history-playback.class';
import { ReportAttributeDirective } from 'src/app/shared/directives/report-attribute.directive';
import { PredictedLocationsTimeFilters } from 'src/app/shared/models/predicted-locations-time-filters.interface';
import { LogFilters, Query } from 'src/app/shared/models/query-item.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { CallLogsService } from 'src/app/shared/modules/call-logs-shared/services/call-logs.service';
import { CallLogsMapStore } from 'src/app/shared/modules/call-logs-shared/services/calls-logs-map.store';
import { debounce } from 'src/app/shared/util/debounce.decorator';
import { matomoActions, matomoCategories } from 'src/app/shared/values/matomo-config';
import { PlayerState } from '../../../components/timeline/timeline.component';
import {
  ProfilerDashboardSectionsRoutes,
  ProfilerOverviewHeaderMapOptions
} from '../../../shared/models/profiler-dashboard-sections.model';
import { ProfilerIconMarker } from '../../../shared/models/profiler-icon-marker';
import { TargetLocationIconMarker } from '../../../shared/models/target-location-icon-marker';
import { MarkerCollectionOptions } from '../models/marker-collection-options.enum';
import { MarkerIcon } from '../models/marker-icons.enum';
import { PLAY_BACK_MARKER_ID, ProfilerMapComponent } from '../profiler-map/profiler-map.component';
import { ProfilerMapDataService } from '../services/profiler-map-data.service';
import { ProfilerMapService } from '../services/profiler-map.service';
import { ProfilerProbabilityService } from '../services/profiler-probability.service';

@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.scss']
})
export class OverviewMapComponent extends ProfilerMapComponent implements OnInit, OnChanges {
  @Input() locationQuery: Query;

  @Input() locationProbabilityData;
  @Input() currentTargetId: string;
  @ViewChildren(ReportAttributeDirective) reportSections: QueryList<
    ReportAttributeDirective<TargetSummarySectionIdentifier>
  >;
  private historyPlayback: HistoryPlayback = new HistoryPlayback();
  private lastHistoryPlaybackMarkerIndex = 0;
  targetSummarySectionIdentifier = TargetSummarySectionIdentifier;
  dataSourceCount: number = 0;
  selectedLocationSources: boolean = true;
  allAzimuthPolygons: Polygon[] = [];
  @ViewChild('postMarkerInfoWindow', { static: true })
  postMarkerInfoWindow: TemplateRef<InvestigationInfoWindowRef>;
  @ViewChild('checkinMarkerInfoWindow', { static: true })
  checkinMarkerInfoWindow: TemplateRef<any>;
  isLocatingDisabled = false;

  constructor(
    public analyticsService: AnalyticsService,
    public angulartics2: Angulartics2,
    public appConfigService: AppConfigService,
    public applicationStateService: ApplicationStateService,
    public changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public feedService: FeedService,
    public mapHelperService: MapHelperService,
    public profilerMapDataService: ProfilerMapDataService,
    public profilerMapService: ProfilerMapService,
    public nearbyLocationsService: NearbyLocationsService,
    public profilerService: ProfilerService,
    public queryService: QueryService,
    public route: ActivatedRoute,
    public router: Router,
    public targetService: TargetService,
    public translationService: TranslationService,
    public userBehaviorService: UserBehaviorService,
    public websocketService: WsService,
    public markerSectorService: MarkerSectorService,
    private adIdService: AdIdService,
    private wsService: ProxyWsService,
    private profilerProbablityService: ProfilerProbabilityService,
    public refreshLocalCacheService: RefreshLocalCacheService,
    private callLogsService: CallLogsService,
    private datePipe: DatePipe,
    private mapStore: CallLogsMapStore
  ) {
    super(
      dialog,
      mapHelperService,
      profilerService,
      route,
      queryService,
      analyticsService,
      translationService,
      router,
      appConfigService,
      userBehaviorService,
      targetService,
      profilerMapService,
      markerSectorService,
      nearbyLocationsService,
      refreshLocalCacheService,
      profilerMapDataService,
      changeDetectorRef
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isLocatingDisabled = this.appConfigService.getConfigVariable('disableLocatingFunctionality');

    this.setTargetData();
    this.setWebsocketsListener();
    this.setupHistoryPlayback();

    this.setProbablityLocationsListener();
    this.gatherLocationAndBuildCustomAlerts();

    this.subscription = this.profilerMapService.activeFilters.pipe(skip(1)).subscribe((activeFilters: string[]) => {
      this.selectedLocationSources = activeFilters.length > 0;
    });

    const refreshObs = this.refreshLocalCacheService.getRefreshObservables([DataSource.Twitter, DataSource.Facebook]);
    this.subscriptions.push(
      merge(...Object.values(refreshObs))
        .pipe(debounceTime(1000))
        .subscribe(() => {
          this.refreshAllLocations();
          this.setMarkers();
        })
    );
  }

  returnElementsQueryList(): QueryList<ReportAttributeDirective<TargetSummarySectionIdentifier>> {
    return this.reportSections;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes?.currentTargetId) {
      this.targetId = this.currentTargetId;
      this.resetMap();
    }

    if (changes.placesOfInterestVisibility) {
      this.setMarkers();
    }

    if (changes.selectedSection) {
      this.loadSectionData();
    }

    if (changes?.locationQuery?.currentValue) {
      this.populateLocation();
    }

    if (changes?.clearGeofenceArea?.currentValue) {
      this.circles = [];
    }
  }
  @debounce(100)
  private loadSectionData(): void {
    this.eventTrack();
    switch (this.selectedSection) {
      case ProfilerOverviewHeaderMapOptions.HEATMAP:
        this.setCallLogListener();
        this.setAdIdListener();
        this.setMarkers();

        break;
      case ProfilerOverviewHeaderMapOptions.TIMELINE:
        this.setCallLogListener();
        this.setAdIdListener();
        this.setMarkers();
        break;
      case ProfilerOverviewHeaderMapOptions.HEATMAPLASTSEEN:
        this.setCallLogListener();
        this.setAdIdListener();
        this.setMarkers();
        break;
      case ProfilerOverviewHeaderMapOptions.PREDICTEDLOCATIONS:
        this.loadPredictedLocations(this.target.telnos);

        break;

      default:
        this.setMarkers();
    }
  }

  @debounce()
  filterTimeline([from, to]: number[]): void {
    this.markerCollection.markers.filteredTimeline = {};

    const locations = this.markerCollection.all.value;
    const filteredMarkers = this.profilerMapService.filterMarkersByDate(from, to, locations);

    this.profilerMapService.assignTimelineIcons(filteredMarkers).forEach(marker => {
      this.markerCollection.markers.filteredTimeline[marker.id] = marker;
    });

    this.onPlayerAction('stopped');

    this.setMarkers();
  }

  delegateCustomMessageAction(e: MouseEvent) {
    const el = e.target as HTMLElement;

    if (el.classList.contains('dynamic-url')) {
      e.preventDefault();
      this.router.navigate([`../${ProfilerDashboardSectionsRoutes.NEW_CALL_LOG}`], {
        relativeTo: this.route
      });
    }
  }

  onPlayerAction(playerState: PlayerState) {
    this.playerState = playerState;

    switch (playerState) {
      case 'playing':
        this.historyPlayback.start();
        break;

      case 'paused':
        this.historyPlayback.pause();
        break;

      case 'stopped':
        this.historyPlayback.stop();
        this.markersChange.next([
          ...this.markersChange.getValue().filter(
            marker => marker.id !== PLAY_BACK_MARKER_ID + (this.lastHistoryPlaybackMarkerIndex - 1)
          )
        ]);
        this.lastHistoryPlaybackMarkerIndex = 0;
        break;

      case 'replay':
        this.historyPlayback.stop();
        this.lastHistoryPlaybackMarkerIndex = 0;
        break;
    }
  }

  private addGeofencingLayer() {
    if (!this.enableGeofenceLayer) {
      return;
    }

    this.maximizedMap = true;
    this.createDrawingButton();
    this.queryService.numbersToBeQueried.next([
      {
        telno: this.target.telnos[0],
        imsi: ''
      }
    ]);
    this.queryService.selectedTargetId.next(this.target.id);

    this.disableGeofenceLayer.emit(void 0);
  }

  createDrawingButton() {
    const drawingButton: Button = this.mapHelperService.createButton(
      'circle',
      'circle_disable.svg',
      'circle_enable.svg',
      'Disable drawing',
      'Draw circle',
      ButtonState.Deactivated,
      true,
      ControlPosition.TOP_LEFT
    );

    drawingButton.callback = () => {
      this.enableDrawing = this.enableDrawing === DrawMode.None ? DrawMode.Circle : DrawMode.None;
      drawingButton.state =
        drawingButton.state === ButtonState.Activated ? ButtonState.Deactivated : ButtonState.Activated;
    };

    this.buttons.push(drawingButton);

    this.buttons = [...this.buttons];
  }

  private setTargetData() {
    this.getTargetData()
      .pipe(filter((target: TargetItem) => !!target))
      .subscribe(() => this.refreshAllLocations());
  }

  private refreshAllLocations() {
    this.addGeofencingLayer();
    this.setPlacesOfInterest();
    this.setRecentPostLocations();
    this.setTelnoLocations();
    this.setImsiLocations();
    this.getQuarantineGeofence();
    this.showLatestLocation();
    this.calculateDataAvailability();
    this.setCallLogListener(3);
    this.setAdIdListener(1);
    this.addCustomAddresses();
    this.loadSectionData();
    this.setNearbyLocationProfile();
    this.setCheckinMarkers();
  }

  private setCheckinMarkers() {
    this.registerDataSource(this.profilerMapDataService.getCheckinMarkers(this.target, this.checkinMarkerInfoWindow)).pipe(
      tap((markers: IconMarker[]) => {
        this.markerCollection.addMarker(MarkerCollectionOptions.CHECKINS, markers);
        this.setMarkers();
      })
    ).subscribe();
  }

  private showLatestLocation() {
    if (!this.target?.telnos?.length) {
      return;
    }

    this.loading = true;

    this.target.telnos
      .filter(telno => !!telno)
      .forEach(telno => {
        const subscription = this.registerDataSource(
          this.queryService.getFilteredTelnoQueries(telno, null, LogFilters.LOCATED)
        ).subscribe(
          (queries: Query[]) => {
            if (!queries?.length) {
              return;
            }

            const query = queries[0];

            this.addGpsLocation(query);
            this.addTrialterationLocation(query, telno);
            this.addDefaultPin(query, telno);
            this.setMarkers();
          },
          noop,
          () => (this.loading = false)
        );

        this.subscriptions.push(subscription);
      });
  }

  private addDefaultPin(query: Query, telno) {
    if (!query.location) {
      return;
    }
    const sectors = this.markerSectorService.getSectorsFromQuery(query);
    // This is needed untill MapModuleV2 is present in the mapHelperService
    const circles = this.mapHelperService.showQueryAccuracyCircles(query) as Circle[];
    const marker = new TargetLocationIconMarker({
      id: query.id,
      lat: query.location.coordinates[1],
      lng: query.location.coordinates[0],
      popupHTML: `${telno}<br>${format(new Date(query?.locationReceivedAt || query.createdAt), 'dd.MM.yyyy HH:mm')}`,
      sectors: sectors,
      circles: circles,
      isPopupWindowOpen: true,
      date: new Date(query?.locationReceivedAt || query.createdAt),
      source: DataSource.GeoLocation,
      iconUrl: MarkerIcon.Default,
      extendMapBounds: !!sectors?.length && !!circles?.length
    });
    this.markerCollection.addMarker(MarkerCollectionOptions.LASTSEEN, [marker]);
  }

  private addTrialterationLocation(query: Query, telno) {
    if (!query?.nmr?.trialterationLocation || !query?.nmr?.billingId) {
      return;
    }
    const sectors = this.markerSectorService.getSectorsFromQuery(query);
    // This is needed untill MapModuleV2 is present in the mapHelperService
    const circles = this.mapHelperService.showQueryAccuracyCircles(query) as Circle[];
    const marker = new TargetLocationIconMarker({
      id: query.id,
      lat: query.nmr.trialterationLocation.coordinates[1],
      lng: query.nmr.trialterationLocation.coordinates[0],
      popupHTML: `${telno}<br>${format(new Date(query.createdAt), 'dd.MM.yyyy HH:mm')}`,
      isPopupWindowOpen: true,
      sectors: sectors,
      circles: circles,
      date: new Date(query.createdAt),
      source: DataSource.GeoLocation,
      iconUrl: MarkerIcon.Nmr,
      extendMapBounds: !!sectors?.length && !!circles?.length
    });

    this.markerCollection.addMarker(MarkerCollectionOptions.LASTSEEN, [marker]);
  }

  private addGpsLocation(query: Query) {
    if (!query?.gps?.deviceLocation) {
      return;
    }
    const sectors = this.markerSectorService.getSectorsFromQuery(query);
    // This is needed untill MapModuleV2 is present in the mapHelperService
    const circles = this.mapHelperService.showQueryAccuracyCircles(query) as Circle[];
    const marker = new TargetLocationIconMarker({
      id: query.id,
      lat: query.gps.deviceLocation.coordinates[1],
      lng: query.gps.deviceLocation.coordinates[0],
      popupHTML: query.gps.locationType,
      isPopupWindowOpen: true,
      sectors: sectors,
      circles: circles,
      date: new Date(query.createdAt),
      source: DataSource.GeoLocation,
      iconUrl: MarkerIcon.Gps,
      extendMapBounds: !!sectors?.length && !!circles?.length
    });

    this.markerCollection.addMarker(MarkerCollectionOptions.LASTSEEN, [marker]);
  }

  private populateLocation(): void {
    const query = this.locationQuery;
    const date = format(new Date(query.createdAt), 'dd.MM.yyyy HH:mm');
    const sectors = this.markerSectorService.getSectorsFromQuery(query);
    // This is needed untill MapModuleV2 is present in the mapHelperService
    const circles = this.mapHelperService.showQueryAccuracyCircles(query) as Circle[];
    const newMarker = new TargetLocationIconMarker({
      id: query.id,
      lat: query.location.coordinates[1],
      lng: query.location.coordinates[0],
      date: new Date(query.createdAt),
      sectors: sectors,
      circles: circles,
      popupHTML: `${query.queryArgs.telno}<br>${date}`,
      source: DataSource.GeoLocation,
      isPopupWindowOpen: true,
      iconUrl: MarkerIcon.Default,
      extendMapBounds: !!sectors?.length && !!circles?.length
    });
    this.markerCollection.addMarker(MarkerCollectionOptions.LASTSEEN, [newMarker]);
    this.setMarkers();
  }

  private setPlacesOfInterest(): void {
    this.registerDataSource(this.profilerMapDataService.getProfileLocations(this.targetId)).subscribe(
      profileMarkers => {
        this.markerCollection.addMarker(MarkerCollectionOptions.PLACESOFINTEREST, profileMarkers);

        this.setMarkers();
      }
    );
  }

  private setRecentPostLocations(): void {
    this.registerDataSource(this.profilerMapDataService.getPostLocations(this.targetId, this.postMarkerInfoWindow)).subscribe(postLocations => {
      if (!postLocations?.length) {
        return;
      }

      this.markerCollection.addMarker(MarkerCollectionOptions.LASTSEEN, postLocations);

      this.setMarkers();
    });
  }

  private setWebsocketsListener(): void {
    const subscription = this.websocketService.onEvent('state-update-geolocation').subscribe((query: Query) => {
      if (
        !query?.location ||
        !(this.target?.telnos.includes(query.queryArgs.telno) || this.target?.imsis.includes(query.queryArgs.imsi))
      ) {
        return;
      }

      let found = false;

      const updatedQueries = this.targetLocations.map(q => {
        if (q.id === query.id) {
          found = true;
          return query;
        }
        return q;
      });

      if (found) {
        this.targetLocations = updatedQueries;
      } else {
        this.targetLocations.unshift(query);
      }

      this.setTargetLocations();
      this.setMarkers();
    });

    this.subscriptions.push(subscription);
  }

  private setCallLogListener(limit?: number): void {
    const overviewMapEnableCallLogLoad = this.appConfigService.getConfigVariable('overviewMapEnableCallLogLoad');

    if (!overviewMapEnableCallLogLoad) {
      return;
    }

    const request = new AdIdLocationHistoryDto({
      msisdns: this.target.telnos,
      ifas: this.target.ifas,
      order: 'DESC',
      limit
    });

    const subscription = this.registerDataSource(this.callLogsService.createLocationHistoryRequest(request)).subscribe(
      cdrs => {
        if (!Array.isArray(cdrs)) {
          return;
        }
        this.addCallLogLocationMarker(cdrs);

        this.setTargetLocations();
        this.setMarkers();
      }
    );

    this.subscriptions.push(subscription);
  }

  private addCallLogLocationMarker(cdrs: CdrTarget[]): void {
    const markers = [];
    cdrs.forEach(locationHistory => {
      const fromSource = this.target.telnos.includes(locationHistory.msisdn);
      const fromDestination = this.target.telnos.includes(locationHistory.receiverMsisdn);
      const geo = this.mapStore.getRequestedLocation({msisdns: this.target.telnos}, locationHistory);
      if (!fromSource && !fromDestination) {
        return;
      }
      if (isEmpty(geo.geojson)) {
        return;
      }

      const [lng, lat] = (geo.geojson as GeoJsonFeatureCollection).features[0].geometry.coordinates;
      const date = this.datePipe.transform(geo.sourceEntity.createdAt, 'dd.MM.yyyy HH:mm', '+0000');
      const sectors = this.markerSectorService.getSectorsFromCdr([locationHistory], this.mapStore.requestClParameters?.msisdns);
      const marker = new TargetLocationIconMarker({
        id: locationHistory.id,
        lat,
        lng,
        sectors: sectors,
        date: new Date(geo.sourceEntity.createdAt),
        popupHTML: `${fromSource ? locationHistory.msisdn : locationHistory.receiverMsisdn}<br>${date}`,
        source: DataSource.CallLog,
        isPopupWindowOpen: true,
        iconUrl: MarkerIcon.Default,
        extendMapBounds: !sectors?.length
      });
      markers.push(marker);
    });
    this.markerCollection.addMarker(MarkerCollectionOptions.CALLLOGS, markers);
  }

  private setAdIdListener(limit?: number): void {
    const overviewMapEnableAdIntLoad = this.appConfigService.getConfigVariable('overviewMapEnableAdIntLoad');

    if (!overviewMapEnableAdIntLoad || !this.target?.ifas?.length) {
      return;
    }

    const request = new AdIdLocationHistoryDto({
      ifas: this.target.ifas,
      order: 'DESC',
      startTime: moment().subtract(30, 'd').toDate(),
      endTime: new Date(),
      limit
    });

    this.registerDataSource(this.getAdIdData(request)).subscribe((event: AdIntWsEvent) => {
      const entities = event.body;
      if (Array.isArray(entities)) {
        entities.forEach(entity => this.onEntityReceived(entity));
      } else {
        this.onEntityReceived(entities);
      }
      this.setTargetLocations();
      this.setMarkers();
    });
  }

  getAdIdData(request: AdIdLocationHistoryDto): Observable<AdIntWsEvent> {
    let correlationId: string;
    return new Observable<AdIntWsEvent>(obs => {
      this.adIdService
        .createLocationHistoryRequest(request)
        .subscribe(result => (correlationId = result.correlationId));

      this.wsService.getMessage().subscribe((event: AdIntWsEvent) => {
        if (event.channel === EventChannel.AdRtbsStream) {
          if (event.correlationId === correlationId) {
            obs.next(event);
          }
          if (event.streamEnded) {
            obs.complete();
          }
        }
      });
    });
  }

  private onEntityReceived(adId: AdRtbExtended | Cdr | Cell) {
    let geo: GeoLocation | undefined;
    if (adId.type === EntityType.Cdr) {
      geo = (<Cdr>adId).geoMsisdn ? (<Cdr>adId).geoMsisdn : (<Cdr>adId).geoReceiverMsisdn;
    } else if (adId.type === EntityType.AdRtb) {
      geo = (<AdRtb>adId).geo;
    } else if (adId.type === EntityType.Cell) {
      geo = (<Cell>adId).geo;
    }
    const geosjon = geo.geojson as GeoJsonFeatureCollection;
    const [lng, lat] = geosjon.features[0].geometry.coordinates;
    const date = format(new Date(adId.createdAt), 'dd.MM.yyyy HH:mm');
    const sectors = this.markerSectorService.getSectorsFromQuery(adId);
    const marker = new TargetLocationIconMarker({
      id: adId.id,
      lat,
      lng,
      date: new Date(adId.createdAt),
      sectors: sectors,
      popupHTML: `Advertisement ${date}`,
      source: DataSource.AdInt,
      isPopupWindowOpen: true,
      iconUrl: MarkerIcon.Default,
      extendMapBounds: !!sectors?.length
    });

    this.markerCollection.addMarker(MarkerCollectionOptions.ADIDS, [marker]);
  }

  private setupHistoryPlayback() {
    const subscription = this.historyPlayback.onInterval().subscribe(() => {
      const existingMarker = Object.values(this.markerCollection.markers.filteredTimeline)[
        this.lastHistoryPlaybackMarkerIndex
      ];

      if (!existingMarker) {
        this.onPlayerAction('stopped');

        return;
      }
      const marker = new ProfilerIconMarker({
        id: PLAY_BACK_MARKER_ID + this.lastHistoryPlaybackMarkerIndex,
        lat: existingMarker.lat,
        lng: existingMarker.lng,
        iconUrl: MarkerIcon.Live,
        extendMapBounds: true,
        popupHTML: existingMarker.popupHTML,
        getPopupEmbeddedView: existingMarker.getPopupEmbeddedView,
        source: DataSource.GeoLocation,
        isPopupWindowOpen: true,
        zIndex: 1500,
        isSelected: true,
      });
      const newMarkers = this.markersChange.getValue().filter(
        marker => marker.id !== PLAY_BACK_MARKER_ID + (this.lastHistoryPlaybackMarkerIndex - 1)
      );
      newMarkers.push(marker);
      this.markersChange.next([...newMarkers]);
      this.lastHistoryPlaybackMarkerIndex++;
    });

    this.subscriptions.push(subscription);
  }

  private gatherLocationAndBuildCustomAlerts() {
    const translationKey = this.isLocatingDisabled ?
      'No locations found for the target <br> Click "#{Request call log}" to retrieve locations':
      'No locations found for the target <br> Click "Locate" or "#{Request call log}" to retrieve locations';

      // object keys will be replaced with their value
    this.customAlert = this.translationService.interpolate(
      translationKey,
      {
        'Request call log': `<a class="dynamic-url">Request call log</a>`,
        'Solicitar el Registro de Llamadas': `<a class="dynamic-url">Solicitar el registro de llamadas</a>`
      }
    );
  }

  private eventTrack(): void {
    const action = matomoEventsMap[this.overviewHeaderSection];
    const category = matomoCategories.targetOverviewMap;

    if (!action || !category) {
      return;
    }

    this.angulartics2.eventTrack.next({
      action,
      properties: {
        category
      }
    });
  }

  private setProbablityLocationsListener(): void {
    const subscription = this.profilerProbablityService.probabilities$.subscribe(result => {
      this.markerCollection.updateCollection(result.type, result.markers);
    });

    this.subscriptions.push(subscription);
  }

  private loadPredictedLocations(telnos: string[]): void {
    const callLogSubscription = this.profilerProbablityService.loadCallLogPredictedLocations(
      telnos,
      this.predictedLocationTimeFilters
    );
    const geolocationSubscription = this.profilerProbablityService.loadGeolocationPredictedLocations(telnos);
    combineLatest([callLogSubscription, geolocationSubscription])
      .pipe(take(1))
      .subscribe(() => {
        this.setMarkers();
      });
  }

  onPredictedLocationsFiltersChanged(event: PredictedLocationsTimeFilters): void {
    this.predictedLocationTimeFilters = event;
    this.loadPredictedLocations(this.target.telnos);
  }
}

const matomoEventsMap = {
  [ProfilerOverviewHeaderMapOptions.HEATMAP]: matomoActions.viewHeatmap,
  [ProfilerOverviewHeaderMapOptions.TIMELINE]: matomoActions.viewTimeline,
  [ProfilerOverviewHeaderMapOptions.PREDICTEDLOCATIONS]: matomoActions.viewPredictedLocation
};
