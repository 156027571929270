import { Injectable } from '@angular/core';
import { debounce } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private audio;
  private debouncedPlay = debounce(this.playSound, 1000);

  constructor() {
    this.audio = new Audio();
  }

  private playSound() {
    this.audio.src = '/assets/static/audio/notify.mp3';
    this.audio.preload = 'none';
    this.audio.load();
    const playPromise = this.audio.play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          // Automatic playback started!
        })
        .catch(error => {
          // Auto-play was prevented
        });
    }
  }

  public playNotification() {
    this.debouncedPlay();
  }
}
