<app-dialog>
  <div header>
    {{ title || 'Confirmation required' | translate }}
  </div>

  <div body>
    <p>
      {{ message }} <b>{{ targetMsisdn }}</b>
    </p>
  </div>

  <div footer>
    <div class="btn-wrp">
      <app-credit-cost-button
        [action]="action"
        [buttonText]="dialogType"
        [active]="true"
        [stylingClasses]="'cp-btn-primary cp-search-btn active'"
        (clicked)="onSuccess()"
      ></app-credit-cost-button>
      <button mat-button class="btn-cancel" (click)="close()">{{ 'Cancel' | translate }}</button>
    </div>
  </div>
</app-dialog>
