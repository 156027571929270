import { Component, Input, OnInit } from '@angular/core';
import { DataSource } from 'datalayer/models/platform-models';
import { NearbyLocationsStore } from 'src/app/services/nearby-locations.store';

@Component({
  selector: 'app-nearby-location-custom-marker',
  templateUrl: './nearby-location-custom-marker.component.html',
  styleUrls: ['./nearby-location-custom-marker.component.scss']
})
export class NearbyLocationCustomMarkerComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() sourceBadge: string;
  @Input() nearbyProfileId: string;

  sourceBadgePath: string;

  constructor(
    private nearbyLocationsStore: NearbyLocationsStore
  ) {}

  private readonly sourceBadged: { [key: string]: string } = {
    [DataSource.Telegram]: '/assets/static/images/badge_telegram.svg'
  };

  ngOnInit() {
    this.sourceBadgePath = this.sourceBadged[this.sourceBadge];
  }

  selectedProfile() {
    this.nearbyLocationsStore.setSelectedProfileIdFromMap(this.nearbyProfileId);
  }
}
