import { User } from '../user/user.model';

export class AuthPayload {
  username: string;
  password: string;
  otp_code?: string;
  recaptchaToken?: string;
  support_email?: string;
}

export interface TransientUserWithJWTClaims extends  User {
  jti?: string;
  uid: string;
}

export enum RedirectionRoutes {
  DASHBOARD = '/discovery',
  TARGETS = '/targets',
  WEBINT = '/webint',
  LOGIN = '/login',
  USER_OPTIONS = '/user-options',
  USER_SETTINGS = '/user-settings'
}
