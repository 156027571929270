<div class="loading-wrp container">
  <svg role="img" aria-labelledby="loading-aria" viewBox="0 0 500 100" preserveAspectRatio="none">
    <title id="loading-aria">Loading...</title>
    <rect x="0" y="0" width="100%" height="100%" clip-path="url(#clip-path)" style='fill: url("#fill");'></rect>
    <defs>
      <clipPath id="clip-path">
        <rect x="8" y="19" rx="0" ry="0" width="136" height="63" />
        <rect x="338" y="19" rx="0" ry="0" width="152" height="28" />
        <rect x="296" y="27" rx="0" ry="0" width="24" height="23" />
        <rect x="160" y="27" rx="0" ry="0" width="130" height="3" />
        <rect x="160" y="41" rx="0" ry="0" width="130" height="3" />
        <rect x="160" y="57" rx="0" ry="0" width="130" height="3" />
        <rect x="433" y="66" rx="0" ry="0" width="42" height="14" />
        <rect x="355" y="65" rx="0" ry="0" width="42" height="14" />
      </clipPath>
      <linearGradient id="fill">
        <stop offset="0.599964" stop-color="#d6d2d2" stop-opacity="1">
          <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite">
          </animate>
        </stop>
        <stop offset="1.59996" stop-color="#f1ecec" stop-opacity="1">
          <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite">
          </animate>
        </stop>
        <stop offset="2.59996" stop-color="#d6d2d2" stop-opacity="1">
          <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite">
          </animate>
        </stop>
      </linearGradient>
    </defs>
  </svg>
  <svg role="img" aria-labelledby="loading-aria" viewBox="0 0 500 100" preserveAspectRatio="none">
    <title id="loading-aria">Loading...</title>
    <rect x="0" y="0" width="100%" height="100%" clip-path="url(#clip-path)" style='fill: url("#fill");'></rect>
    <defs>
      <clipPath id="clip-path">
        <rect x="8" y="19" rx="0" ry="0" width="136" height="63" />
        <rect x="338" y="19" rx="0" ry="0" width="152" height="28" />
        <rect x="296" y="27" rx="0" ry="0" width="24" height="23" />
        <rect x="160" y="27" rx="0" ry="0" width="130" height="3" />
        <rect x="160" y="41" rx="0" ry="0" width="130" height="3" />
        <rect x="160" y="57" rx="0" ry="0" width="130" height="3" />
        <rect x="433" y="66" rx="0" ry="0" width="42" height="14" />
        <rect x="355" y="65" rx="0" ry="0" width="42" height="14" />
      </clipPath>
      <linearGradient id="fill">
        <stop offset="0.599964" stop-color="#d6d2d2" stop-opacity="1">
          <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite">
          </animate>
        </stop>
        <stop offset="1.59996" stop-color="#f1ecec" stop-opacity="1">
          <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite">
          </animate>
        </stop>
        <stop offset="2.59996" stop-color="#d6d2d2" stop-opacity="1">
          <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite">
          </animate>
        </stop>
      </linearGradient>
    </defs>
  </svg>
</div>