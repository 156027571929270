<div
  class="details-card"
  [class.active-paginate-header]="multiSelectQueries.length > 1 && !isMobileResolution"
  [class.geolocation-details-mobile]="isMobileResolution"
>
  <div class="target-details-header">
    <span>{{ 'Details' | translate }}</span>
  </div>
  <app-geolocation-target-actions
    [target]="target"
    [query]="query"
    (emitExportPdf)="generatePdfReport()"
  ></app-geolocation-target-actions>
  <div
    class="row align-center space-between"
    [class.paginate-header-band]="multiSelectQueries.length > 1 && !isMobileResolution"
    *ngIf="multiSelectQueries.length > 1 && !isMobileResolution"
  >
    <div class="col-4 start-xs align-center">
      <ng-container *ngIf="multiselectData.index !== 0">
        <span class="icon-btn" (click)="previousMultiquery()">
          <mat-icon matPrefix>keyboard_arrow_left</mat-icon>
        </span>
        <span class="multiselect">{{ multiselectData.previousQuery }}</span>
      </ng-container>
    </div>
    <div class="col-3 center-xs">
      <span class="multiselect sub-txt"> {{ multiSelectQueries.length }} {{ 'items' | translate }} </span>
    </div>
    <div class="col-4 end-xs align-center">
      <ng-container *ngIf="multiselectData.index + 1 !== this.multiSelectQueries.length">
        <span class="multiselect">{{ multiselectData.nextQuery }}</span>
        <span class="icon-btn" (click)="nextMultiquery()">
          <mat-icon matSuffix>keyboard_arrow_right</mat-icon>
        </span>
      </ng-container>
    </div>
    <div class="col-1 p-0">
      <span class="icon-btn" (click)="exportSelectedQueries()">
        <img src="assets/static/images/export-pdf.svg" />
      </span>
    </div>
  </div>

  <!-- Side Arrow -->
  <button class="side-arrow" (click)="toggleSideNav.emit()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>

  <mat-card class="details-info-card">
    <mat-card-content>
      <img
        (click)="hideMobileViewGeoDetails()"
        class="close"
        *ngIf="isMobileResolution"
        src="assets/static/images/close_black.svg"
        height="13"
      />
      <div class="details-wrp">
        <div class="info-wrp" [class.relative]="theme === 'UNLIMITED'">
          <span *ngIf="theme === 'UNLIMITED'" class="demo-sign">DEMO</span>
          <p class="info-heading">{{ 'Profile' | translate }}</p>

          <app-geolocation-target-details
            *ngIf="!targetDetailsIsFetching"
            [recommendations]="recommendations"
            [target]="target"
            [avatars]="avatars"
            [query]="query"
          ></app-geolocation-target-details>
        </div>

        <!-- User Equipment -->
        <div class="info-wrp">
          <p class="info-heading">{{ 'User equipment' | translate }}</p>
          <ng-container *ngFor="let i of phoneInfo">
            <div *ngIf="i.value && i.value !== 'None'" class="info-block flex-wrp">
              <span class="label left-wrp">{{ i.label }}:</span>
              <p class="value flex-wrp right-wrp">
                <span *ngIf="i.label === 'Date' || i.label === 'Fecha'">{{
                  i.value | date: 'EEE dd MMM yyyy H:mm':''
                }}</span>
                <span
                  *ngIf="i.label === 'Network' || i.label === 'Red'"
                  [matTooltip]="(deviceNetworkStatus | translate) + '.'"
                  innerHTML="{{ i.value }}"
                ></span>
                <span
                  *ngIf="i.label === 'Subscription' || i.label === 'Suscripción'"
                  [matTooltip]="(providerSubscriptionStatus | translate) + '.'"
                  innerHTML="{{ i.value }}"
                ></span>
                <span
                  *ngIf="i.label !== 'Date' && i.label !== 'Fecha' && i.label !== 'Status' && i.label !== 'Estado' && i.label !== 'Network' && i.label !== 'Red' && i.label !== 'Subscription' && i.label !== 'Suscripción'"
                  innerHTML="{{ i.value }}"
                ></span>
              </p>
            </div>
          </ng-container>
        </div>

        <!-- Mobile Network -->
        <div class="info-wrp">
          <p class="info-heading">{{ 'Mobile network' | translate }}</p>
          <ng-container *ngFor="let i of mobileInfo">
            <div *ngIf="i.value" class="info-block flex-wrp">
              <span class="label left-wrp" [class.hidden]="i.type === 'mobileProviderInfo' && query.roaming"
                >{{ i.label }}:</span
              >
              <p class="value flex-wrp right-wrp">
                <span [class.hidden]="i.type === 'mobileProviderInfo' && query.roaming">{{ i.value }}</span>
              </p>
            </div>
          </ng-container>
        </div>

        <!-- Location -->
        <div class="info-wrp">
          <p class="info-heading">
            {{ query?.lastKnownLocation ? 'Last known location' : ('location' | translate | titlecase) }}
          </p>
          <ng-container *ngFor="let i of locationDetails">
            <div class="info-block flex-wrp">
              <span class="label left-wrp">{{ i.label }}:</span>
              <p class="value flex-wrp right-wrp" [ngSwitch]="i.label === 'Date' || i.label === 'Fecha'">
                <span *ngSwitchCase="true">{{ i.value | date: 'EEE dd MMM yyyy H:mm' }}</span>
                <span *ngSwitchCase="false">{{ i.value }}</span>
              </p>
            </div>
          </ng-container>
        </div>

        <!-- Interception -->
        <div class="info-wrp" *ngIf="query.type === 'tactical'">
          <p class="info-heading">interception</p>
          <ng-container *ngIf="query.interception.conversationType">
            <div class="info-block flex-wrp">
              <span class="label left-wrp">Type:</span>
              <p class="value flex-wrp right-wrp">
                <span *ngIf="query.interception.conversationType === 'Voice'">
                  <span
                    [class.clickable]="query.interception.file"
                    (click)="query.interception.file ? audioPlayer(query.interception) : ''"
                    >{{ query.interception.conversationType }}
                    <img
                      *ngIf="query.interception.file"
                      class="file"
                      src="assets/static/images/speaker.svg"
                      height="20"
                      width="20"
                  /></span>
                </span>
                <span *ngIf="query.interception.conversationType === 'SMS'">
                  <span
                    [class.clickable]="query.interception.smsText"
                    (click)="query.interception.smsText ? fileReader(query.interception.smsText) : ''"
                    >{{ query.interception.conversationType }}
                    <img
                      *ngIf="query.interception.smsText"
                      class="file"
                      src="assets/static/images/contact.svg"
                      height="20"
                      width="20"
                  /></span>
                </span>
              </p>
            </div>
          </ng-container>
          <ng-container *ngFor="let i of interceptionInfo">
            <div *ngIf="i.value" class="details-label">
              <div class="row">
                <div class="col-4">
                  <label>{{ i.label }}: </label>
                </div>
                <div class="col-8">
                  <span>{{ i.value }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="info-wrp">
          <ng-container>
            <div class="info-block flex-wrp">
              <span class="label left-wrp">{{ 'Query Id' | translate }}:</span>
              <div class="value flex-wrp right-wrp">
                <span>{{ queryId }}</span>
                <button
                  class="query-id"
                  [cdkCopyToClipboard]="queryId"
                  [matTooltip]="'Copy' | translate"
                  (click)="copyInputMessage()"
                >
                  <img src="assets/static/images/search-intel/copy-color.svg" />
                </button>
              </div>
            </div>
            <div class="info-block flex-wrp">
              <span class="label left-wrp">{{ 'Date' | translate }}:</span>
              <div class="value flex-wrp right-wrp">
                <span>{{ query.createdAt | date: 'EEE dd MMM yyyy H:mm' }}</span>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Mobile PDF Report -->
        <div *ngIf="isMobileResolution" class="section">
          <button
            class="export-single-geolocation"
            matTooltip="{{ 'Export to pdf' | translate }}"
            mat-icon-button
            (click)="generatePdfReport()"
          >
            <img src="assets/static/images/pdf_color.svg" width="35" height="35" />
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
