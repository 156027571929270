import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { RoleManagementService } from 'src/app/services/roles/role-management.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User, UserRoles } from 'src/app/services/user/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { isEqual } from 'lodash-es';
import { forkJoin } from 'rxjs';
import { UserDialogService } from './user-dialog.service';
import { UsersUtilService } from '../users.utils.service';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';
import { AvailablePool, CreditPools } from 'src/app/shared/models/credit-pools.model';
import { UserDialogFormFields, UserForm } from './user-dialog.model';
import { LimitType } from 'src/app/shared/models/billing-action.model';
import { UserDialogFormService } from './user-dialog-form-service';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
  providers: [UserDialogService, UserDialogFormService],
})
export class AddUserDialogComponent extends BaseComponent implements OnInit {
  roles: string[] = [UserRoles.USER, UserRoles.POWERUSER, UserRoles.ADMIN, UserRoles.SUPPORT];
  allFeatures: string[] = ['somedus'];
  loggedinUserIsAdmin: boolean = false;
  loggedinUserIsSupport: boolean = false;
  loggedinUserIsPower: boolean = false;
  userForm: FormGroup | null = null;
  errorMsg: string | boolean = false;
  checked = false;
  editMode = false;
  title: string = '';
  showRoles = true;
  temporaryUser = false;
  enableFeatures: boolean = false;
  onUpdateUser: EventEmitter<User> = new EventEmitter<User>();

  private availablePools: AvailablePool[] = [];
  public unassignPoolPrefix: string = this.userDialogService.UNASSIGN_FORM_POOL_PREFIX;

  constructor(
    private translationService: TranslationService,
    private userService: UserService,
    private targetService: TargetService,
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private roleManagementService: RoleManagementService,
    private appConfigService: AppConfigService,
    private usersUtilService: UsersUtilService,
    private userBillingService: UserBillingService,
    private userDialogService: UserDialogService,
    private userDialogFormService: UserDialogFormService
  ) {
    super();
  }

  ngOnInit() {
    this.loggedinUserIsAdmin = this.roleManagementService.userIsAdmin();
    this.loggedinUserIsSupport = this.roleManagementService.userIsSupportUser();
    this.loggedinUserIsPower = this.roleManagementService.userIsPowerUser();
    this.editMode = !!this.user;
    this.enableFeatures = this.appConfigService.getConfigVariable('enableFeatures');

    this.title = this.editMode
      ? this.translationService.translate('Edit') + ' ' + this.translationService.translate('user')
      : this.translationService.translate('Create User');

    if (!this.loggedinUserIsSupport) {
      this.roles = this.roles.slice(0, -1);
    }

    this.availablePools = this.userBillingService.getAvailablePools();
    this.initForm();
    if (this.loggedinUserIsPower && (!this.editMode || this.user.isTemporary)) {
      this.temporaryUser = true;
      this.handleTemporaryUserValues();
    }
  }

  private initForm(): void {
    this.subscription = forkJoin([
      this.userDialogFormService.generateBaseFormControls(this.user, this.editMode),
      this.userDialogFormService.generateCreditPoolsFormControls(this.user),
    ]).subscribe(([baseFormControls, creditPoolsFormControls]: { [key: string]: FormControl }[]) => {
      this.userForm = new FormGroup({ ...baseFormControls, ...creditPoolsFormControls });
      this.initBaseFormListeners();
      if (Object.keys(creditPoolsFormControls).length) {
        this.initAdditionalFormListeners();
      }
    });
  }

  private initBaseFormListeners(): void {
    this.hideRolesOnEdit();
    this.onTelnoChanges();
    this.onRolesChanges();
  }

  private initAdditionalFormListeners(): void {
    this.onLimitTypeChanges();
    this.onPoolsAssignedChanges();
  }

  private hideRolesOnEdit(): void {
    if (this.editMode) {
      if (
        this.loggedinUserIsPower &&
        (this.user.roles.includes(UserRoles.ADMIN) || this.user.roles.includes(UserRoles.SUPPORT))
      ) {
        this.userForm.controls[UserDialogFormFields.QUOTA].disable();
        this.showRoles = false;
      }
      if (!this.loggedinUserIsAdmin && !this.loggedinUserIsSupport) {
        this.showRoles = false;
      }
      this.userForm.controls[UserDialogFormFields.USERNAME].disable();
      if (this.user.username === 'admin') {
        this.showRoles = false;
      }
    }
  }

  private onPoolsAssignedChanges(): void {
    this.getUserForm()
      .valueChanges.pipe(
        map((formChanges: UserForm) => {
          const poolsChanges = {};
          Object.keys(formChanges)
            .filter((poolForm) => this.availablePools.map((pool) => pool.value).includes(poolForm))
            .forEach((poolForm) => (poolsChanges[poolForm] = this.userBillingService.toDecimal(formChanges[poolForm])));
          return poolsChanges;
        }),
        filter((poolsChanges) => !!poolsChanges),
        debounceTime(400),
        distinctUntilChanged(isEqual)
      )
      .subscribe((assignedPools: CreditPools) => {
        if (this.userBillingService.isDistributedBalance()) {
          this.calculateDistributedPoolCredits(assignedPools);
        } else {
          this.calculateSinglePoolCredits(assignedPools);
        }
      });
  }

  private calculateSinglePoolCredits(assignedPools: CreditPools): void {
    if (!!this.user) {
      Object.keys(assignedPools).forEach((pool) => {
        if (assignedPools[pool] !== this.user.currentBalance) {
          if (assignedPools[pool] > this.user.currentBalance) {
            const total = assignedPools[pool] - <number>this.user.currentBalance;
            this.userForm.controls[`${this.unassignPoolPrefix}${pool}`].setValue(
              this.userBillingService.toDecimal(this.userBillingService.getTenantUnassignCredits() - total)
            );
          } else {
            const total = <number>this.user.currentBalance - assignedPools[pool];
            this.userForm.controls[`${this.unassignPoolPrefix}${pool}`].setValue(
              this.userBillingService.toDecimal(this.userBillingService.getTenantUnassignCredits() + total)
            );
          }
        } else {
          this.userForm.controls[`${this.unassignPoolPrefix}${pool}`].setValue(
            this.userBillingService.toDecimal(this.userBillingService.getTenantUnassignCredits())
          );
        }
      });
    }
  }

  private calculateDistributedPoolCredits(assignedPools: CreditPools): void {
    Object.keys(assignedPools).forEach((pool) => {
      if (assignedPools[pool] === this.getCurrentUserPoolBalance(pool)) {
        this.userForm.controls[`${this.unassignPoolPrefix}${pool}`].setValue(
          this.userBillingService.toDecimal(this.userBillingService.getTenantUnassignCreditPools()[pool])
        );
      }
      if (assignedPools[pool] > this.getCurrentUserPoolBalance(pool)) {
        const total = assignedPools[pool] - this.getCurrentUserPoolBalance(pool);
        this.userForm.controls[`${this.unassignPoolPrefix}${pool}`].setValue(
          this.userBillingService.toDecimal(this.userBillingService.getTenantUnassignCreditPools()[pool] - total)
        );
      }
      if (assignedPools[pool] < this.getCurrentUserPoolBalance(pool)) {
        const total = this.getCurrentUserPoolBalance(pool) - assignedPools[pool];
        if (this.getCurrentUserPoolBalance(pool) === this.userBillingService.getTenantUnassignCreditPools()[pool]) {
          this.userForm.controls[`${this.unassignPoolPrefix}${pool}`].setValue(
            this.userBillingService.toDecimal(total)
          );
        } else {
          this.userForm.controls[`${this.unassignPoolPrefix}${pool}`].setValue(
            this.userBillingService.toDecimal(this.userBillingService.getTenantUnassignCreditPools()[pool] + total)
          );
        }
      }
    });
  }

  private getCurrentUserPoolBalance(pool: string): number {
    return !!this.user && !!this.user.currentBalance[pool] ? this.user?.currentBalance[pool] : 0;
  }

  private onRolesChanges(): void {
    this.getUserForm()
      .get(UserDialogFormFields.ROLE)
      .valueChanges.pipe(filter((_) => this.loggedinUserIsSupport))
      .subscribe((selectedRole) => {
        if (selectedRole === UserRoles.SUPPORT) {
          this.userForm.controls[UserDialogFormFields.EMAIL].disable();
        } else {
          this.userForm.controls[UserDialogFormFields.EMAIL].enable();
        }
      });
  }

  private onLimitTypeChanges(): void {
    this.getUserForm()
      .get(UserDialogFormFields.LIMIT_TYPE)
      .valueChanges.subscribe((limitType: LimitType) => {
        this.availablePools.forEach((pool) => {
          if (LimitType.MAXIMUM === limitType) {
            this.userForm.controls[pool.value].setValue(this.userBillingService.toDecimal(pool.unassignCredits));
            this.userForm.controls[pool.value].disable();
          } else {
            const currentBalance = this.userBillingService.isDistributedBalance()
              ? this.getCurrentUserPoolBalance(pool.value)
              : this.user.currentBalance;
            this.userForm.controls[pool.value].setValue(this.userBillingService.toDecimal(<number>currentBalance) || 0);
            this.userForm.controls[pool.value].enable();
          }
        });
      });
  }

  private onTelnoChanges() {
    const userForm: FormGroup = this.getUserForm();
    const otpPhoneControl = userForm.get(UserDialogFormFields.OTP_PHONE);

    if (null === otpPhoneControl) {
      throw new Error('otp-phone is not present in the form');
    }

    this.subscription = otpPhoneControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value: string) => {
        const userForm: FormGroup = this.getUserForm();
        if (value.length) {
          const phoneNumberUtil = PhoneNumberUtil.getInstance();
          this.errorMsg = !this.updateValidPhone(phoneNumberUtil, userForm.value);
        } else {
          this.errorMsg = false;
        }
      });
  }

  async onSubmit(): Promise<void> {
    const userForm: FormGroup = this.getUserForm();
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    if (userForm.valid && isNaN(userForm.value.username)) {
      if (this.updateValidPhone(phoneNumberUtil, userForm.value)) {
        this.errorMsg = false;
        if (this.editMode) {
          this.editUser();
        } else {
          const userBillingRequestPayload = this.userDialogService.getUserBillingRequestPayload({
            ...userForm.value,
          });
          const userRequestPayload = this.userDialogService.getCreateUserRequestPayload({ ...userForm.getRawValue() });
          const confirmedEmail = await this.usersUtilService.confirmEmailRecipientDialog(userRequestPayload);

          if (userRequestPayload && !confirmedEmail) {
            delete userRequestPayload.email;
          }

          this.subscriptions.push(
            this.userService.createUser({ ...userRequestPayload, ...userBillingRequestPayload }, true).subscribe(
              (user: User) => {
                this.onUserCreated(user);
                this.dialogRef.close();
              },
              (error: any) => {
                if (error?.message && error.message === 'Invalid Username Input') {
                  this.showMessage(
                    this.translationService.translate(
                      'Username already exists. Please try again.'
                    )
                  );
                } else {
                  this.showMessage(this.translationService.translate('Something went wrong. Please try again.'));
                  this.dialogRef.close();
                }
              }
            )
          );
        }
      } else {
        this.errorMsg = true;
      }
    } else {
      this.showMessage(
        this.translationService.translate(
          'Invalid values. Please try again. Username must contain at least one letter.'
        )
      );
    }
  }

  private async onUserCreated(newUser: User) {
    this.onUpdateUser.emit(newUser);
    const { username, email, temporaryPassword } = newUser;
    if (!email && temporaryPassword) {
      await this.usersUtilService.displayTemporaryPasswordDialog(temporaryPassword, newUser.username);
    } else {
      this.showMessage(
        `${this.translationService.interpolate('New user #{username} created successfully!', {
          username,
        })}${
          email ? ` ${this.translationService.interpolate('Temporary password sent to: #{email}', { email })}` : ''
        }`,
        undefined,
        6000
      );
    }
  }

  private editUser(): void {
    const userForm: FormGroup = this.getUserForm();
    const formValue: UserForm = userForm.value;
    this.subscriptions.push(
      this.userService
        .editUser(
          this.user,
          {
            ...this.userDialogService.getUserBillingRequestPayload(formValue),
            ...this.userDialogService.getEditUserRequestPayload(formValue, this.user),
            username: this.userForm.controls[UserDialogFormFields.USERNAME].value,
          },
          true
        )
        .subscribe(
          (user: User) => {
            this.onUpdateUser.emit(user);
            this.showMessage(this.translationService.translate('User edited successfully!'));
            this.dialogRef.close();
          },
          () => {
            this.showMessage(this.translationService.translate('User has not been edited'));
          }
        )
    );
  }

  updateValidPhone(phoneNumberUtil: PhoneNumberUtil, user: User) {
    if (!user.otpPhone) {
      return true;
    }

    try {
      const telno = this.targetService.getValidPhone(phoneNumberUtil, user.otpPhone);
      if (telno) {
        user.otpPhone = telno;
        return true;
      }
    } catch (e) {}
    return false;
  }

  public toggleTemporaryUser(event: MatCheckboxChange): void {
    this.temporaryUser = event.checked;
    if (event.checked) {
      this.handleTemporaryUserValues();
    } else {
      this.resetTemporaryUserValues();
    }
  }

  private handleTemporaryUserValues(): void {
    const userForm: FormGroup = this.getUserForm();
    userForm.controls[UserDialogFormFields.ROLE].setValue(this.roles[0]);
    userForm.controls[UserDialogFormFields.ROLE].disable();
    userForm.addControl(UserDialogFormFields.OTP_ENABLED, new FormControl(true));
  }

  private resetTemporaryUserValues(): void {
    const userForm: FormGroup = this.getUserForm();
    userForm.controls[UserDialogFormFields.ROLE].reset();
    userForm.controls[UserDialogFormFields.ROLE].enable();
    userForm.removeControl(UserDialogFormFields.OTP_ENABLED);
    userForm.removeControl(UserDialogFormFields.OTP_CHANNEL);
  }

  private getUserForm(): FormGroup {
    if (null === this.userForm) {
      throw new Error('User form is not yet initialised');
    }
    return this.userForm;
  }
}
