<div class="map-wrapper">
  <input
    #search
    class="search hidden"
    type="text"
    placeholder="{{ 'Search Google Maps' | translate }}"
  />

  <div #legend class="map-legend"></div>

  <div
    #mapContainer
    class="gmap"
    [ngClass]="{ loading: loading }"
    [ngStyle]="{ height: calculatedHeight }"
  ></div>

  <div *ngIf="loading" class="loading-overlay"></div>
  <mat-progress-spinner *ngIf="loading" diameter="40" color="accent" mode="indeterminate">
  </mat-progress-spinner>

</div>
