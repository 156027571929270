import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'environment/environment';

enum Routes {
  SIM_SWAP = 'sims',
  DEVICE_SWAP = 'devices',
  PROXIMITY = 'proximity',
  LOCATION_PROBABILITY_BY_HOUR = 'location-probability-by-hour',
  LOCATION_PROBABILITY = 'location-probability',
  DATA_AVAILABILITY = 'data-availability',
  HOME_WORK_LOCATION_PROBABILITY = 'home-work-location-probability',
  GET_LAT_LONG_BY_ADDRESS = 'lat-long-by-address'
}

export enum DayOfWeek {
  ALL = -1,
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  WEEKDAYS = 7,
  WEEKENDS = 8,
}

export const IMPORT_DATA_THRESHOLD_PER = 70;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  showDayTimeSelector = new BehaviorSubject<boolean>(false);
  probabilityDataAvailability: {
    tenantPercentage: number,
    overallPercentage: number,
  } = {
    tenantPercentage: 0,
    overallPercentage: 0
  };
  private readonly config: string | null = null;

  constructor(private http: HttpClient) {
    this.config = environment.intelTypes?.analytics;
  }

  proximity(param, filter) {
    return new Observable(observable => {
      const option: Object = {
        params: filter
      };
      const base_url = `${this.config}/sims/${param}/proximity`;
      this.http.get(base_url, option).subscribe(result => {
        observable.next(result['result']);
        observable.complete();
      });
    });
  }

  simSwap(param: string): Observable<string[]> {
    return new Observable<string[]>(observable => {
      const base_url = `${this.config}/${Routes.DEVICE_SWAP}/${param}/${Routes.SIM_SWAP}`;
      this.http.get<{result: string[]}>(base_url).subscribe(result => {
        observable.next(result.result);
        observable.complete();
      });
    });
  }

  deviceSwap(param: string): Observable<any[]> {
    return new Observable(observable => {
      const base_url = `${this.config}/${Routes.SIM_SWAP}/${param}/${Routes.DEVICE_SWAP}`;
      this.http.get(base_url).pipe(catchError(error => {
        return throwError(error);
      })).subscribe(result => {
        observable.next(result['result']);
        observable.complete();
      }, error => {
        observable.error(error);
        observable.complete();
      });
    });
  }

  hotSpots(param): Observable<any> {
    return new Observable((observable) => {
      const base_url = `${this.config}/${Routes.SIM_SWAP}/${param}/${Routes.LOCATION_PROBABILITY_BY_HOUR}`;
      this.http.get(base_url).subscribe(result => {
        observable.next(result['result']);
        observable.complete();
      });
    });
  }

  getLocationProbability(telno: string, day?: string, fromHour?: string, toHour?: string): Observable<any> {
    const base_url = `${this.config}/${Routes.SIM_SWAP}/${telno}/${Routes.LOCATION_PROBABILITY}`;
    let params = new HttpParams();
    if (day !== undefined) {
      params = params.set('dayOfWeek', day);
    }
    if (fromHour !== undefined) {
      params = params.set('fromHour', fromHour);
    }
    if (toHour !== undefined) {
      params = params.set('toHour', toHour);
    }

    return this.http.get<any>(base_url, { params }).pipe(
      map(data => data.result)
    );
  }

  getHomeAndWorkLocationProbability(telno: string, day?: string, fromHour?: string, toHour?: string): Observable<any> {
    const base_url = `${this.config}/${Routes.SIM_SWAP}/${telno}/${Routes.HOME_WORK_LOCATION_PROBABILITY}`;
    return this.http.get<any>(base_url).pipe(
      map(data => data.result)
    );
  }

  getLatLongByAddress(addresses: string[]) {
    return this.http.post(`${this.config}/${Routes.GET_LAT_LONG_BY_ADDRESS}`, { addresses }).pipe(
      map((data: { result: {}[] }) => data.result)
    );
  }

  resetProbabilityDataAvailability() {
    this.probabilityDataAvailability = {
      tenantPercentage: 0,
      overallPercentage: 0
    };
  }

  getDataAvailability(telno: string): Observable<any> {
    this.resetProbabilityDataAvailability();
    const base_url = `${this.config}/${Routes.SIM_SWAP}/${telno}/${Routes.DATA_AVAILABILITY}`;
    return this.http.get<any>(base_url).pipe(
      map(data => {
        const { result } = data;
        this.probabilityDataAvailability = result;
        return result;
      })
    );
  }

  importDataRequest(telno: string) {
    const base_url = `${this.config}/${Routes.SIM_SWAP}/${telno}/import`;
    return this.http.post<any>(base_url, {}).pipe(
      map(data => data.result)
    );
  }
}
