import { BaseService, EmptyCacheService } from 'datalayer/services/base';
import { Person } from 'datalayer/models/person/person';
import { PersonApiService } from 'src/app/modules/data-layer/services/person/person/person-api.service';
import { Injectable } from '@angular/core';
import { PersonDTO } from 'src/app/modules/data-layer/services/person/person/person-dto';

@Injectable({
  providedIn: 'root',
})
export class PersonService extends BaseService<Person, PersonDTO, PersonApiService, EmptyCacheService<Person>> {
  constructor(apiService: PersonApiService, localCacheService: EmptyCacheService<Person>) {
    super(apiService, localCacheService);
  }
}
