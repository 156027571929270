import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { camelToSnakeCase, transformSnakeToCamel } from 'src/app/shared/util/helper';
import { BaseService } from '../base.service';
import { UserFeatures } from './../../shared/models/questionnaire.model';

@Injectable({
  providedIn: 'root'
})
export class UserFeaturesService extends BaseService {
  constructor(private httpClient: HttpClient, protected router: Router, protected snackBar: MatSnackBar) {
    super(router, snackBar);
  }

  getUserFeatures(): Observable<UserFeatures[]> {
    return this.httpClient
      .get<any>(`${this.fastAPIurl}/user-features?feature_id=investigation&feature_attribute=state`)
      .pipe(
        map((features: { result: UserFeatures[] }) => {
          return transformSnakeToCamel(features.result);
        }),
        catchError(error => this.handleError(error))
      );
  }

  updateUserFeatures(requestbody: Partial<UserFeatures>): Observable<UserFeatures[]> {
    const userFeaturesRequestBody: object = {};
    Object.keys(requestbody).forEach(key => (userFeaturesRequestBody[camelToSnakeCase(key)] = requestbody[key]));

    return this.httpClient.put<any>(`${this.fastAPIurl}/user-features`, userFeaturesRequestBody).pipe(
      map((features: { result: UserFeatures[] }) => {
        return transformSnakeToCamel(features.result);
      }),
      catchError(error => this.handleError(error))
    );
  }
}
