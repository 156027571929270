import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { head } from 'lodash-es';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ImTooltip } from 'src/app/components/geolocation-details/components/geolocation-im-tooltip/geolocation-im-tooltip.component';
import { QueryTypeUiPipe } from 'src/app/components/query-list-item/query-type-pipe';
import { PlatformImages } from 'src/app/modules/profiler/shared/models/profiler-dashboard-sections.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { BaseService } from 'src/app/services/base.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UserBehaviorService } from 'src/app/services/user-behavior.service';
import {
  Azimuth,
  deviceStateDetails as DeviceStateDetails,
  GeoJSONPoint,
  LogFilters,
  NMR,
  Query,
  QueryStatus,
  QueryType,
  Schedule,
  SortMode,
} from 'src/app/shared/models/query-item.model';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { Point } from 'src/app/modules/mapV2/models/map.models';
import { FilterParams, SortBy } from './filter-params.model';

const HIGH_LIMIT = 9999;

@Injectable({
  providedIn: 'root'
})
export class QueryService extends BaseService {
  skin;
  allQueries = [];
  allTasks = [];
  isMobileResolution: boolean;
  theme: string;
  public queriesSubscription = new ReplaySubject<Query[]>();
  public selectedQueryId: string | string[];
  public onQuerySelection = new BehaviorSubject<Query | null>(null);
  targetQueries: any;
  selectedUser: any;
  limit: number | undefined;
  dateRange = new BehaviorSubject<string[]>([]);
  frequency = new Subject<number>();
  isAdvancedQuery = new BehaviorSubject<boolean>(false);
  isAutoFillQueries = new BehaviorSubject<boolean>(false);
  advancedQueryType = new BehaviorSubject<string>(QueryType.SCHEDULED);
  advancedDataFlag = new BehaviorSubject<boolean>(false);
  queriesFlag = new BehaviorSubject<boolean>(false);
  filterParams: FilterParams = {
    page: 1,
    sort_by: SortBy.DESC,
  };
  // MSISDNs and IMSIs list to be queried
  numbersToBeQueried = new BehaviorSubject<any>([]);
  queriesChanged = new BehaviorSubject<any>([]);
  tasksChanged = new Subject<Schedule[]>();
  showTelnoHistory = new BehaviorSubject<boolean>(false);
  historyItem = new BehaviorSubject<any>({});
  queriesPaginationData = new BehaviorSubject<any>([]);
  autoFillQueries = new BehaviorSubject<any>([]);
  batchQueries = new BehaviorSubject<any>([]);
  // multiselect of log queries to show geolocation details
  multiquerySelection = new BehaviorSubject<any>([]);
  showQueriesLoader = new BehaviorSubject<boolean>(true);
  targetProfilePhoto = new Subject<string>();
  gpsQuery = new BehaviorSubject<Query | null>(null);
  nmrQuery = new BehaviorSubject<Query | null>(null);
  selectNewQuery = new BehaviorSubject<Query | null>(null);
  // reset query fields flags
  refreshLogQueries = new BehaviorSubject<boolean>(false);
  clearDates = new BehaviorSubject<boolean>(false);
  clearFrequency = new BehaviorSubject<boolean>(false);
  clearQueryInput = new BehaviorSubject<boolean>(false);
  clearAdvancedInputs = new BehaviorSubject<boolean>(false);
  clearLocationInput = new BehaviorSubject<boolean>(false);
  clearRadicalMonitoring = new Subject<boolean>();
  queryToHighLight: Subject<Query> = new Subject<Query>();

  // to be used for query type FENCED_QUARANTINE
  selectedTargetId = new BehaviorSubject<string>('');

  // covid19
  allPois: any;
  poisChanged = new Subject<any>();

  // Geo-Dashobard
  hideGeoSearch = new Subject<boolean>();
  locateQuery = new Subject<Query>();
  intelQuery = new Subject<Query>();


  private imTooltip$: BehaviorSubject<ImTooltip> = new BehaviorSubject(null);
  constructor(
    private httpClient: HttpClient,
    private applicationStateService: ApplicationStateService,
    protected override router: Router,
    private translationService: TranslationService,
    private transformQueryTypePipe: QueryTypeUiPipe,
    private appConfigService: AppConfigService,
    private userBehaviorService: UserBehaviorService,
    protected override snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.selectedQueryId = [];
    this.skin = this.applicationStateService.getSkin();
    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  public getImTooltip(): Observable<ImTooltip> {
    return this.imTooltip$.asObservable();
  }

  public setImTooltip(imTooltip: ImTooltip): void {
    this.imTooltip$.next(imTooltip);
  }

  public createPdfReport(query: Query, isIntelType = false) {
    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endpoint = isIntelType ? 'intel-reports' : 'reports';
    const params = Array.isArray(query) ? { ids: query.map(q => q.id) } : { id: query.id };

    return this.httpClient.post(
      `${this.reportServiceUrl}/${endpoint}`,
      {
        ...params,
        reportType: 'pdf',
        language: this.appConfigService.getLanguage(),
        timezoneOffset,
        flavor: this.theme.toLocaleLowerCase()
      },
      { responseType: 'blob' }
    );
  }

  public createIntelPdfReport(queryArgs: Record<string, unknown>) {
    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endpoint = 'intel-reports';
    return this.httpClient.post(
      `${this.reportServiceUrl}/${endpoint}`,
      {
        queryArgs: queryArgs,
        reportType: 'pdf',
        language: this.appConfigService.getLanguage(),
        timezoneOffset,
        flavor: this.theme.toLocaleLowerCase().toString()
      },
      { responseType: 'blob' }
    );
  }

  public createProfilerPdfReport(queryArgs: Record<string, unknown>) {
    this.theme = this.appConfigService.getConfigVariable('theme');
    this.userBehaviorService.userBehavior('osint_export_intel_pdf').subscribe();
    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const flavor = this.theme.toLocaleLowerCase().toString();
    return this.httpClient
      .post(
        `${this.reportServiceUrl}/profiler-reports`,
        {
          queryArgs: queryArgs,
          reportType: 'pdf',
          language: this.appConfigService.getLanguage(),
          timezoneOffset,
          flavor
        },
        { responseType: 'blob' }
      )
      .pipe(catchError(e => throwError(e)));
  }

  public createQueryLogReport(reportType: string, selectedFilter: LogFilters, dates: string[], searchText: string, multiSelected: { id: number; }[] = []) {
    const tags = [];
    const filter: any = {};
    const params: any = {
      filter,
      reportType,
      timezoneOffset: Intl.DateTimeFormat().resolvedOptions().timeZone,
      flavor: this.theme.toLocaleLowerCase()
    };

    if (multiSelected.length) {
      params.ids = multiSelected.map(query => query['id']);
    } else {
      if (selectedFilter === LogFilters.LOCATED) {
        filter.status = QueryStatus.DONE;
      } else if (selectedFilter === LogFilters.NOT_LOCATED) {
        filter.status = [
          QueryStatus.PENDING,
          QueryStatus.FAILED,
          QueryStatus.LOCATION_PENDING,
          QueryStatus.EXPIRED,
          QueryStatus.NO_LOCATION
        ];
      }

      if (this.selectedUser) {
        filter.created_by = this.selectedUser;
      }

      if (dates && dates.length > 0) {
        if (dates[1]) {
          filter.updated_at = { $gt: dates[0], $lt: dates[1] };
        } else {
          filter.updated_at = { $gt: dates[0] };
        }
      }

      if (searchText) {
        params.searchFilter = searchText;
      }

      if (this.showTelnoHistory.getValue()) {
        const { telno, imsi } = this.historyItem.getValue();
        if (telno) {
          tags.push({ type: 'telno', value: telno });
        }

        if (imsi) {
          tags.push({ type: 'imsi', value: imsi });
        }
      } else {
        params.latestOnly = true;
      }

      const exceptionalSearchableFilters: { [key: string]: string; } = {
        telno: 'provider.telno',
        imsi: 'provider.imsi'
      };

      for (const tag of tags) {
        const type = tag.type in exceptionalSearchableFilters ? exceptionalSearchableFilters[tag.type] : tag.type;
        filter[type] = tag.value;
      }
    }

    return this.httpClient
      .post(
        `${this.reportServiceUrl}/reports`,
        {
          ...params,
          language: this.appConfigService.getLanguage()
        },
        { responseType: 'blob' }
      )
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => this.handleError(error))
      );
  }

  public stripPlusSign(phoneNumber: string) {
    if (phoneNumber && phoneNumber[0] === '+') {
      return phoneNumber.substr(1);
    }
    return phoneNumber;
  }

  getAllQueries() {
    let params = {};
    params = this.queriesLimitGeo4(params);
    return this.httpClient.get<any>(`${this.url}/latest-queries`, { params }).pipe(
      map(data => {
        this.queriesPaginationData.next(data);
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries.slice()));
        return this.allQueries;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getPaginatedQueries(page: number) {
    this.filterParams['page'] = page;
    const params = { ...this.filterParams } as Record<string, string | number>;

    let endpoint = 'latest-queries';
    if (params.telno || params['imsi']) {
      // telno history endpoint
      endpoint = 'queries';
    }

    return this.httpClient.get<any>(`${this.url}/${endpoint}`, { params }).pipe(
      map((data: any) => {
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesPaginationData.next(data);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries));
        return this.allQueries;
      }),
      catchError(error => this.handleError(error))
    );
  }

  sortQueries(sort: boolean) {
    this.showQueriesLoader.next(true);
    if (sort) {
      this.filterParams.sort_by = SortBy.ASC;
    } else {
      this.filterParams.sort_by = SortBy.DESC;
    }
    const params = { ...this.filterParams } as Record<string, string | number>;

    let endpoint = 'latest-queries';
    if (params['telno'] || params['imsi']) {
      endpoint = 'queries';
    }

    return this.httpClient.get<any>(`${this.url}/${endpoint}`, { params }).pipe(
      map((data: any) => {
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesPaginationData.next(data);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries));
        this.showQueriesLoader.next(false);
        return this.allQueries;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getTelnoQueries(telno, imsi) {
    this.showQueriesLoader.next(true);
    let params = {};
    if (telno) {
      params['telno'] = telno;
    }
    if (imsi) {
      params['imsi'] = imsi;
    }

    params = this.queriesLimitGeo4(params);

    return this.httpClient.get<any>(`${this.url}/queries`, { params }).pipe(
      map(data => {
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries.slice()));
        this.queriesPaginationData.next(data);
        this.showQueriesLoader.next(false);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getFilteredTelnoQueries(telno, imsi, selectedFilter, dateRange?, searchTag?, selectedUser?) {
    this.showQueriesLoader.next(true);
    this.selectedUser = selectedUser;
    let params = {};
    if (telno) {
      params['telno'] = telno;
    }
    if (imsi) {
      params['imsi'] = imsi;
    }

    if (dateRange && dateRange.length > 0) {
      if (dateRange[0]) {
        params['from_date'] = dateRange[0].toISOString();
      }
      if (dateRange[1]) {
        params['to_date'] = dateRange[1].toISOString();
      }
    }

    if (selectedFilter === LogFilters.LOCATED) {
      params['status'] = QueryStatus.DONE;
    } else if (selectedFilter === LogFilters.NOT_LOCATED) {
      params['status'] = [
        QueryStatus.PENDING,
        QueryStatus.FAILED,
        QueryStatus.LOCATION_PENDING,
        QueryStatus.EXPIRED,
        QueryStatus.NO_LOCATION
      ];
    }

    if (searchTag) {
      params['search_filter'] = searchTag;
    }

    if (selectedUser) {
      params['created_by'] = selectedUser;
    }

    if (this.filterParams['sort_by'] && this.filterParams['sort_by'] === 'ASC') {
      params['sort_by'] = 'ASC';
    } else {
      params['sort_by'] = 'DESC';
    }

    params = this.queriesLimitGeo4(params);

    return this.httpClient.get<any>(`${this.url}/queries`, { params }).pipe(
      map(data => {
        this.queriesPaginationData.next(data);
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries.slice()));
        this.showQueriesLoader.next(false);
        return this.allQueries;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getFilteredQueries(dateRange, selectedFilter, searchTag, selectedUser) {
    this.showQueriesLoader.next(true);
    this.selectedUser = selectedUser;
    let params = {};
    if (dateRange && dateRange.length > 0) {
      if (dateRange[0]) {
        params['from_date'] = dateRange[0].toISOString();
      }
      if (dateRange[1]) {
        params['to_date'] = dateRange[1].toISOString();
      }
    }

    if (selectedFilter === LogFilters.LOCATED) {
      params['status'] = QueryStatus.DONE;
    } else if (selectedFilter === LogFilters.NOT_LOCATED) {
      params['status'] = [
        QueryStatus.PENDING,
        QueryStatus.FAILED,
        QueryStatus.LOCATION_PENDING,
        QueryStatus.EXPIRED,
        QueryStatus.NO_LOCATION
      ];
    }

    if (searchTag) {
      params['search_filter'] = searchTag;
    }

    if (selectedUser) {
      params['created_by'] = selectedUser;
    }

    if (this.filterParams['sort_by'] && this.filterParams['sort_by'] === 'ASC') {
      params['sort_by'] = 'ASC';
    } else {
      params['sort_by'] = 'DESC';
    }

    params = this.queriesLimitGeo4(params);
    return this.httpClient.get<any>(`${this.url}/latest-queries`, { params }).pipe(
      map(data => {
        this.queriesPaginationData.next(data);
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries.slice()));
        this.showQueriesLoader.next(false);
        return this.allQueries;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getEmergencyQueries(type) {
    const params = {};

    if (type) {
      params['type'] = type;
    }

    return this.httpClient.get<any>(`${this.url}/latest-queries`, { params }).pipe(
      map(data => {
        this.queriesPaginationData.next(data);
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries.slice()));
      }),
      catchError(error => this.handleError(error))
    );
  }

  getTasks(canceled) {
    return this.httpClient.get<any>(`${this.url}/schedules`, { params: { canceled } }).pipe(
      map(data => {
        this.allTasks = transformSnakeToCamel(data);
        return this.allTasks;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getPaginatedTasks({ flag, page = 1, limit = 10 }) {
    const params = {};
    params['canceled'] = flag;
    params['page'] = page;
    params['limit'] = limit;
    return this.httpClient.get<any>(`${this.url}/paginated-schedules`, { params }).pipe(
      map(data => {
        const schedules = transformSnakeToCamel(data);
        this.tasksChanged.next(schedules);
        return schedules;
      }),
      catchError(error => this.handleError(error))
    );
  }

  cancelRobot(robot) {
    return this.httpClient.delete<any>(`${this.url}/schedules/${robot.id}`);
  }

  getBatchQueries(groupId) {
    this.showQueriesLoader.next(true);
    let params = {};
    params = this.queriesLimitGeo4(params);
    return this.httpClient.get<any>(`${this.url}/queries`, { params: { group: groupId } }).pipe(
      map(data => {
        // TODO: Check if we need to use slice everytime
        this.queriesPaginationData.next(data);
        this.allQueries = transformSnakeToCamel(data.result);
        this.queriesChanged.next(transformSnakeToCamel(this.allQueries.slice()));
        this.batchQueries.next(this.allQueries);
        this.showQueriesLoader.next(false);
      }),
      catchError(error => this.handleError(error))
    );
  }

  isValidImsi(value: string): boolean {
    const regex = /^\d{14,15}$/g;
    if (!value.length) {
      return false;
    }

    if (regex.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  isValidIfa(value: string): boolean {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return value.length && regex.test(value);
  }

  isValidImei(value): boolean {
    const regex = /^\d{14,16}.*$/g;
    if (!value.length) {
      return false;
    }

    if (regex.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  deleteInvalidCharactersFromMsisdn(msisdn: string): string {
    return msisdn.trim().replace(/[^a-zA-Z0-9 !?\+?\d+]+/g, '');
  }

  enableAdvancedQuery() {
    this.queriesFlag.next(true);
    this.advancedDataFlag.next(true);
  }

  dateToTimestamp(date) {
    return date.getTime() / 1000;
  }

  createSimpleQuery(): Observable<any> {
    this.queriesFlag.next(true);
    const queryType = QueryType.SIMPLE.toLowerCase();
    if (!this.numbersToBeQueried.value.length) {
      this.queriesFlag.next(false);
      return of(false);
    }
    return this.httpClient
      .post<any>(`${this.url}/queries`, { query_type: queryType, queries: this.numbersToBeQueried.value })
      .pipe(catchError(error => this.handleError(error)));
  }

  quickQuery(query, parentId: string = undefined) {
    const queryType = QueryType.SIMPLE.toLowerCase();
    const params: { query_type: string; queries: []; parent_id?: string } = {
      query_type: queryType,
      queries: query
    };

    if (parentId) {
      params.parent_id = parentId;
    }

    return this.httpClient.post<any>(`${this.url}/queries`, params).pipe(
      tap(() => {
        this.numbersToBeQueried.next([]);
      }),
      catchError(error => this.handleError(error))
    );
  }

  ipLocationQuery(): Observable<any> {
    const queryType = QueryType.SIMPLE.toLowerCase();
    if (!this.numbersToBeQueried.value.length) {
      this.queriesFlag.next(false);
      return of(false);
    }
    const params: { query_type: string; queries: Query[]; parent_id?: string; advanced_location_method: boolean } = {
      query_type: queryType,
      queries: this.numbersToBeQueried.value,
      advanced_location_method: true,
    };

    return this.httpClient
      .post<any>(`${this.url}/queries`, params)
      .pipe(catchError((error) => this.handleError(error)));
  }

  resubmitQuery(queryId: string) {
    return this.httpClient.post<any>(`${this.url}/query/${queryId}/resubmit`, '').pipe(
      tap(() => {
        this.numbersToBeQueried.next([]);
      }),
      catchError(error => this.handleError(error))
    );
  }

  createScheduledQuery(data) {
    const queryType = data.queryType.toLowerCase();
    const startAt = this.dateToTimestamp(data.startAt);
    const endAt = this.dateToTimestamp(data.endAt);
    const numbersToBeQueried =
      this.numbersToBeQueried.value.length > 0 ? this.numbersToBeQueried.value : [this.onQuerySelection.value.queryArgs];

    return this.httpClient
      .post<any>(`${this.url}/queries`, {
        query_type: queryType,
        queries: numbersToBeQueried,
        start_at: startAt,
        end_at: endAt,
        interval_minutes: data.frequency
      })
      .pipe(
        map(result => {
          this.numbersToBeQueried.next([]);
        }),
        catchError(error => this.handleError(error))
      );
  }

  createLogonQuery(data) {
    const queryType = data.queryType.toLowerCase();
    const startAt = this.dateToTimestamp(data.startAt);
    const endAt = this.dateToTimestamp(data.endAt);
    return this.httpClient
      .post<any>(`${this.url}/queries`, {
        query_type: queryType,
        queries: this.numbersToBeQueried.value,
        start_at: startAt,
        end_at: endAt,
        interval_minutes: data.frequency
      })
      .pipe(
        map(result => {
          this.numbersToBeQueried.next([]);
        }),
        catchError(error => this.handleError(error))
      );
  }

  createCountryQuery(data) {
    const queryType = data.queryType.toLowerCase();
    const startAt = this.dateToTimestamp(data.startAt);
    const endAt = this.dateToTimestamp(data.endAt);
    return this.httpClient
      .post<any>(`${this.url}/queries`, {
        query_type: queryType,
        queries: this.numbersToBeQueried.value,
        start_at: startAt,
        end_at: endAt,
        interval_minutes: data.frequency,
        inside: !data.outside,
        country_code: data.country
      })
      .pipe(
        map(result => {
          this.numbersToBeQueried.next([]);
        }),
        catchError(error => this.handleError(error))
      );
  }

  createAreaOfInterestQuery(data) {
    this.userBehaviorService.userBehavior('geo_map_query_fencing').subscribe();
    const queryType = data.queryType.toLowerCase();
    const startAt = this.dateToTimestamp(data.startAt);
    const endAt = this.dateToTimestamp(data.endAt);
    const areaData = {
      radius_meters: data.radius * 1000,
      location: {
        coordinates: [data.lng, data.lat]
      }
    };
    return this.httpClient
      .post<any>(`${this.url}/queries`, {
        query_type: queryType,
        queries: this.numbersToBeQueried.value,
        start_at: startAt,
        end_at: endAt,
        interval_minutes: data.frequency,
        inside: !data.outside,
        area: areaData
        // TODO: use this when BE is completed
        // target_id: this.selectedTargetId.getValue() || ''
      })
      .pipe(
        map(result => {
          this.numbersToBeQueried.next([]);
        }),
        catchError(error => this.handleError(error))
      );
  }

  createProximityQuery(data) {
    const queryType = data.queryType.toLowerCase();
    const startAt = this.dateToTimestamp(data.startAt);
    const endAt = this.dateToTimestamp(data.endAt);
    const target = this.numbersToBeQueried.value.shift();
    const telnos = [];
    for (const telno of this.numbersToBeQueried.value) {
      telnos.push(telno.telno);
    }
    return this.httpClient
      .post<any>(`${this.url}/queries`, {
        query_type: queryType,
        queries: [target],
        distance: data.radius,
        telnos: telnos,
        start_at: startAt,
        end_at: endAt,
        interval_minutes: data.frequency,
        imsis: ''
      })
      .pipe(
        map(result => {
          this.numbersToBeQueried.next([]);
        }),
        catchError(error => this.handleError(error))
      );
  }

  addHashtag(hashtag, query) {
    return this.httpClient
      .put<any>(`${this.url}/query/${query.id}`, {
        tag: hashtag
      })
      .pipe(catchError(error => this.handleError(error)));
  }

  getCountries() {
    return this.httpClient.get<any>(`${this.url}/countries`).pipe(
      map(data => {
        return transformSnakeToCamel(data);
      }),
      catchError(error => this.handleError(error))
    );
  }

  sendSms(text, telno) {
    return this.httpClient.post<any>(`${this.url}/sms/${telno}`, { content: text }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => this.handleError(error))
    );
  }

  queriesLimitGeo4(params) {
    this.filterParams = params;
    // fallback to the default limit from the BE in case of zero value
    if (this.limit > 0) {
      params['limit'] = this.limit;
    }
    return params;
  }

  requestLocationHistoryImsi(data) {
    return this.httpClient
      .post<any>(`${this.url}/historic_locations`, data)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => this.handleError(error))
      )
      .subscribe();
  }

  getTimelineAndHeatmapQueries(telno, imsi, dateRange, sorting: SortMode) {
    const params = {};
    if (telno) {
      params['telno'] = telno;
    }
    if (imsi) {
      params['imsi'] = imsi;
    }
    if (dateRange && dateRange.length > 0) {
      if (dateRange[0]) {
        params['from_date'] = dateRange[0].toISOString();
      }
      if (dateRange[1]) {
        params['to_date'] = dateRange[1].toISOString();
      }
    }
    params['status'] = QueryStatus.DONE;
    params['limit'] = HIGH_LIMIT;
    params['sort'] = 'updated_at';
    if (sorting) {
      params['sort_by'] = sorting;
    } else {
      params['sort_by'] = SortMode.DESC;
    }

    return this.httpClient.get<any>(`${this.url}/queries`, { params }).pipe(
      map(data => {
        const queries = transformSnakeToCamel(data.result);
        return queries;
      }),
      catchError(error => this.handleError(error))
    );
  }

  addToMultiqueryGeolocationDetails(query) {
    const selection = this.multiquerySelection.getValue();
    selection.push(query);
    this.multiquerySelection.next(selection);
  }

  removeFromMultiqueryGeolocationDetails(id) {
    const selection = this.multiquerySelection.getValue().filter(q => q.id !== id);
    this.multiquerySelection.next(selection);
  }

  resetVariablesState() {
    this.filterParams = {
      page: 1,
      sort_by: SortBy.DESC
    };
    this.showTelnoHistory.next(false);
    this.onQuerySelection.next(null);
    this.clearDates.next(true);
    this.historyItem.next({});
    this.multiquerySelection.next([]);
  }

  // returns startAt, endAt with duration of 1 week if no arguments
  getQuickScheduleDates(twoWeeks?: boolean) {
    const days: number = twoWeeks ? 14 : 7;
    const startAt = new Date();
    let endAt = new Date(startAt);
    endAt = new Date(endAt.setDate(startAt.getDate() + days));
    return [startAt, endAt];
  }

  getAllNeighbourAntennas(query) {
    return this.httpClient.get<any>(`${this.url}/bts/neighbour-towers/${query.id}`).pipe(
      map(res => {
        return res;
      }),
      catchError(error => this.handleError(error))
    );
  }

  neighbourAntennasCheck(query) {
    return this.httpClient.get<any>(`${this.url}/bts/neighbour-towers-check/${query.id}`).pipe(
      map(res => {
        return res;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getLatestQueryById(id: string) {
    const params = {};
    params['limit'] = 1;

    return this.httpClient.get<any>(`${this.url}/query/${id}`, { params }).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getChildQueryById(id: string): Observable<Query> {
    return this.httpClient.get<any>(`${this.url}/child-query/${id}`).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => of(null))
    );
  }

  getLatestQueryByMsisdn(msisdn: string) {
    const params = {};
    params['telno'] = msisdn;
    params['limit'] = 1;

    return this.httpClient.get<any>(`${this.url}/queries`, { params }).pipe(
      map(data => {
        return transformSnakeToCamel(data.result[0]);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getLatestQueryByMsisdns(msisdns: string[]): Observable<Query[]> {
    let params = new HttpParams().append('limit', msisdns.length.toString());
    msisdns.forEach(msisdn => {
      params = params.append('telno', msisdn);
      params['telno'] = msisdn;
    });
    return this.httpClient.get<any>(`${this.url}/queries`, { params }).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getLatestLocatedQueryByMsisdn(msisdn: string) {
    const params = {};
    params['telno'] = msisdn;
    params['status'] = QueryStatus.DONE;
    params['limit'] = 1;

    return this.httpClient.get<any>(`${this.url}/queries`, { params }).pipe(
      map(data => {
        return data.meta.totalResults;
      }),
      catchError(error => this.handleError(error))
    );
  }

  public chargeCallInfoGeoQuery(query_id: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/call-info/query/${query_id}`).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  public chargeExtractPeer(selectedExtraction: {
    extractPeerInformationAndLocate: boolean;
    query: Query;
  }): Observable<{ parentQuery: Query; childQuery?: Query }> {
    return this.chargeCallInfoGeoQuery(selectedExtraction.query.id).pipe(
      switchMap((query: Query) => {
        if (selectedExtraction.extractPeerInformationAndLocate) {
          return this.quickQuery([{ telno: `+${selectedExtraction.query.callInfo.oncallNumber}` }], query.id).pipe(
            map((response: { result: Query[] }) => ({
              parentQuery: query,
              childQuery: transformSnakeToCamel(head(response.result))
            }))
          );
        }
        return of({ parentQuery: query });
      })
    );
  }

  public chargeGPSGeoQuery(queryId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/gps/query/${queryId}`).pipe(
      map(data => {
        this.gpsQuery.next(transformSnakeToCamel(data.result));
      }),
      catchError(error => this.handleError(error))
    );
  }

  public chargeNMRGeoQuery(queryId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/nmr/query/${queryId}`).pipe(
      map(data => {
        this.nmrQuery.next(transformSnakeToCamel(data.result));
      }),
      catchError(error => this.handleError(error))
    );
  }

  public submitActiveLocateQuery(queryId: string): Observable<Query[]> {
    return this.httpClient.get<any>(`${this.url}/query-active/${queryId}`).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  private getStatusText(status: string = ''): string {
    return status ? `<b class="${DeviceStateDetails[status].color}">${this.translationService.translate(status)}</b>` : '';
  }

  private getProviderHtml(query): string {
    if (!query.provider) {
      return '';
    }
    const network = query.provider.mcc && query.provider.mnc ? `${query.provider.mcc}/${query.provider.mnc}` : '';

    return query.provider.name ? `${network} - ${query.provider.name}` : network;
  }

  private getCellHtml(query): string {
    if (!query.cell) {
      return '';
    }

    const network = query.cell.mcc && query.cell.mnc ? `${query.cell.mcc}/${query.cell.mnc}` : '';

    return query.provider.roamingName ? `${network} - ${query.provider.roamingName}` : network;
  }

  public getGeolocationDetails(query: Query): {
    phoneInfo: [];
    mobileInfo: [];
    locationInfo: [];
    interceptionInfo: [];
    callInfo: [];
  } {
    let phoneInfo;
    let mobileInfo;
    let locationInfo;
    let interceptionInfo;
    let callInfo;

    const msisdn = query.queryArgs.telno
      ? query.queryArgs.telno
      : query.provider.telno && query.provider.telno !== '+0'
      ? query.provider.telno
      : '';

    phoneInfo = [
      { label: 'MSISDN', value: this.stripPlusSign(msisdn) },
      {
        label: this.translationService.translate('Network'),
        value: this.getStatusText(query?.device?.networkStatus)
      },
      {
        label: this.translationService.translate('Subscription'),
        value: this.getStatusText(query?.provider?.subscriptionStatus)
      },
      { label: this.translationService.translate('Phone model'), value: query.device ? query.device.phoneModel : '' },
      { label: 'IMSI', value: query.provider ? query.provider.imsi : '' },
      { label: 'IMEI', value: query.device ? query.device.imei : '' },
      { label: this.translationService.translate('Software Information'), value: query?.device?.softwareInfo },
    ];

    mobileInfo = [{ label: this.translationService.translate('Provider'), value: this.getProviderHtml(query) }];

    if (query.roaming) {
      mobileInfo.push({ label: 'Roaming Provider', value: this.getCellHtml(query) });
    }

    if (query.ipAddress) {
      mobileInfo.push({ label: this.translationService.translate('IP'), value: this.getIPWithVersion(query.ipAddress) });
      mobileInfo.push({ label: this.translationService.translate('Radio'), value: query.accuracyMeters <= 10000 ? '4G' : '3G'});
    }
    if (query.cell) {
      mobileInfo.push({ label: this.translationService.translate('LAC'), value: query.cell.lac });
      if (query.cell.cellType === 4) {
        mobileInfo.push({ label: this.translationService.translate('Cell ENBID'), value: query.cell.enbid });
        mobileInfo.push({ label: this.translationService.translate('Cell LCID'), value: query.cell.lcid });
        mobileInfo.push({ label: this.translationService.translate('Cell ECI'), value: query.cell.cellId });
      } else {
        mobileInfo.push({ label: this.translationService.translate('Cell Id'), value: query.cell.cellId });
      }
      if (query.cell.cellType) {
        mobileInfo.push({ label: this.translationService.translate('Radio'), value: `${query.cell.cellType}G` });
      }
    }

    if (query.callInfo && query.callInfo.oncallNumber) {
      callInfo = [
        {
          label: 'MSISDN',
          value: query.callInfo.oncallNumber
        }
      ];
    }

    locationInfo = [
      {
        label: query.nmr?.trialterationLocation && query.nmr?.billingId ? 'NMR' : '',
        value:
          query.nmr?.trialterationLocation && query.nmr?.billingId
            ? query.nmr.trialterationLocation
              ? `${query.nmr.trialterationLocation.coordinates[1].toFixed(
                  4
                )},${query.nmr.trialterationLocation.coordinates[0].toFixed(4)}`
              : `${query.nmr.sectorLocation.coordinates[1].toFixed(
                  4
                )},${query.nmr.sectorLocation.coordinates[0].toFixed(4)}`
            : ''
      },
      {
        label:
          query.gps && query.gps.locationType && query.gps.billingId
            ? query.gps.locationType === 'GPS'
              ? 'GPS'
              : 'AGPS'
            : '',
        value:
          query.gps && query.gps.deviceLocation && query.gps.billingId
            ? `${query.gps.deviceLocation.coordinates[1].toFixed(4)},${query.gps.deviceLocation.coordinates[0].toFixed(
                4
              )}`
            : ''
      },
      {
        label: 'Lat / Lon',
        value: query.location
          ? `${query.location.coordinates[1].toFixed(4)},${query.location.coordinates[0].toFixed(4)}`
          : ''
      },
      {
        label: this.translationService.translate('Radius'),
        value: query.accuracyMeters ? query.accuracyMeters + 'm' : ''
      },
      { label: this.translationService.translate('Address'), value: query.address || '' },
      {
        label: this.translationService.translate('Program'),
        value: query.schedule ? this.transformQueryTypePipe.transform(query.schedule.scheduleType) : ''
      },
      { label: this.translationService.translate('Task Id'), value: query.schedule ? query.schedule.id : '' },
      { label: this.translationService.translate('Query Id'), value: query.id },
      {
        label: this.translationService.translate('Date'),
        value: query.locationReceivedAt ? new Date(query.locationReceivedAt) : ''
      },
      { label: 'AoL', value: query.ageOfLocationInMinutes !== null ? query.ageOfLocationInMinutes + ' min' : '' }
    ];

    if (query.interception) {
      interceptionInfo = [
        {
          label: this.translationService.translate('ID'),
          value: query.interception.conversationId
        },
        {
          label: this.translationService.translate('Direction'),
          value: query.interception.direction
        },
        {
          label: this.translationService.translate('Quality'),
          value: query.interception.receivedQuality
        }
      ];
      if (query.interception.startTime && query.interception.endTime) {
        const startTime = new Date(query.interception.startTime);
        const endTime = new Date(query.interception.endTime);
        const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());
        const durationSeconds = Math.ceil(timeDiff / 1000);
        interceptionInfo.push({ label: this.translationService.translate('Duration'), value: `${durationSeconds}(s)` });
      }
    }

    return {
      phoneInfo,
      mobileInfo,
      locationInfo,
      callInfo,
      interceptionInfo
    };
  }

  // covid19
  public createTargetPoi(queries: { id: string; radius: number }[], targetId: string) {
    return this.httpClient
      .post<any>(`${this.url}/target/${targetId}/poi`, {
        queries: queries
      })
      .pipe(
        map(data => {}),
        catchError(error => this.handleError(error))
      );
  }

  // NMR sector calculcations start
  // For info ask the telco guys :)
  public calculateCircularSector(
    location: GeoJSONPoint,
    sectorData: NMR | Azimuth,
    accuracyMeters: number,
    googleFormat: boolean
  ) {
    if (!location) {
      return;
    }
    if (!sectorData.combasBearing || !sectorData.sectorSize) {
      throw new Error('invalid inputs, calculateCircularSector');
    }
    const R = 6378.1; // radius of the earth in Km
    const pi = Math.PI;
    const accuracyKm = accuracyMeters / 1000;
    const latRadians = location.coordinates[1] * (pi / 180);
    const lonRadians = location.coordinates[0] * (pi / 180);
    const bearing1 = sectorData.combasBearing + sectorData.sectorSize / 2;
    const bearing1Radians = bearing1 * (pi / 180);
    const bearing2 = sectorData.combasBearing - sectorData.sectorSize / 2;
    const bearing2Radians = bearing2 * (pi / 180);
    const point1Lat = Math.asin(
      Math.sin(latRadians) * Math.cos(accuracyKm / R) +
        Math.cos(latRadians) * Math.sin(accuracyKm / R) * Math.cos(bearing1Radians)
    );
    const point1Lon =
      lonRadians +
      Math.atan2(
        Math.sin(bearing1Radians) * Math.sin(accuracyKm / R) * Math.cos(latRadians),
        Math.cos(accuracyKm / R) - Math.sin(latRadians) * Math.sin(point1Lat)
      );
    const sector1Lat = point1Lat * (180 / pi);
    const sector1Lon = point1Lon * (180 / pi);

    const point2_lat = Math.asin(
      Math.sin(latRadians) * Math.cos(accuracyKm / R) +
        Math.cos(latRadians) * Math.sin(accuracyKm / R) * Math.cos(bearing2Radians)
    );
    const point2_lon =
      lonRadians +
      Math.atan2(
        Math.sin(bearing2Radians) * Math.sin(accuracyKm / R) * Math.cos(latRadians),
        Math.cos(accuracyKm / R) - Math.sin(latRadians) * Math.sin(point2_lat)
      );

    const sector2Lat = point2_lat * (180 / pi);
    const sector2Lon = point2_lon * (180 / pi);
    const centerPoint = { lat: location.coordinates[1], lng: location.coordinates[0] };
    const arcPts = this.calculateArcPoints(
      centerPoint,
      this.calculateBearing(centerPoint, { lat: sector2Lat, lng: sector2Lon }),
      this.calculateBearing(centerPoint, { lat: sector1Lat, lng: sector1Lon }),
      accuracyMeters,
      googleFormat
    );
    const allPoints = arcPts.concat(centerPoint, { lat: sector1Lat, lng: sector1Lon }, centerPoint, {
      lat: sector2Lat,
      lng: sector2Lon
    });
    return allPoints;
  }

  private calculateBearing(center: Point, otherLatLng: Point) {
    const lat1 = center.lat * (Math.PI / 180);
    const lon1 = center.lng * (Math.PI / 180);
    const lat2 = otherLatLng.lat * (Math.PI / 180);
    const lon2 = otherLatLng.lng * (Math.PI / 180);
    let angle = -Math.atan2(
      Math.sin(lon1 - lon2) * Math.cos(lat2),
      Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
    );
    if (angle < 0.0) {
      angle += Math.PI * 2.0;
    }
    if (angle > Math.PI) {
      angle -= Math.PI * 2.0;
    }
    return angle * (180 / Math.PI);
  }

  private calculateArcPoints(
    center: Point,
    initialBearing: number,
    finalBearing: number,
    radius: number,
    googleFormat: boolean
  ): Point[] {
    const points = 32;
    const extp = [];
    if (initialBearing > finalBearing) {
      finalBearing += 360;
    }
    let deltaBearing = finalBearing - initialBearing;
    deltaBearing = deltaBearing / points;
    for (let i = 0; i < points + 1; i++) {
      extp.push(this.destinationPoint(center, initialBearing + i * deltaBearing, radius, googleFormat));
    }
    return extp;
  }

  private destinationPoint(center: Point, bearing: number, dist: number, googleFormat: boolean) {
    const R = 6378.1 * 1000; // earth's mean radius in meters
    const bearingRad = bearing * (Math.PI / 180);
    const lat1 = center.lat * (Math.PI / 180);
    const lon1 = center.lng * (Math.PI / 180);
    const lat2 = Math.asin(
      Math.sin(lat1) * Math.cos(dist / R) + Math.cos(lat1) * Math.sin(dist / R) * Math.cos(bearingRad)
    );
    const lon2 =
      lon1 +
      Math.atan2(
        Math.sin(bearingRad) * Math.sin(dist / R) * Math.cos(lat1),
        Math.cos(dist / R) - Math.sin(lat1) * Math.sin(lat2)
      );
    return googleFormat
      ? new google.maps.LatLng(lat2 * (180 / Math.PI), lon2 * (180 / Math.PI))
      : { lat: lat2 * (180 / Math.PI), lng: lon2 * (180 / Math.PI) };
  }
  // NMR sector calculations end


  getTelnosQueries(telnos: string[]) {
    let params = new HttpParams();
    telnos.forEach(telno => {
      params = params.append('telno', telno);
    });
    params = params.append('limit', HIGH_LIMIT)

    return this.httpClient.get<any>(`${this.url}/queries`, { params }).pipe(
      map(data => {
        return transformSnakeToCamel(data.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getPlatformImage(platform: Platform): string {
    switch (platform) {
      case Platform.TELEGRAM:
        return PlatformImages.TG;

      case Platform.WHATSAPP:
        return PlatformImages.WA;

      case Platform.SKYPE:
        return PlatformImages.SK;

      case Platform.WECHAT:
        return PlatformImages.WC;

      case Platform.TRUECALLER:
        return PlatformImages.TC;

      case Platform.CallerID:
        return PlatformImages.CI;
    }
  }
  private getIPWithVersion(ipAddress: string): string {
    if (ipAddress.includes('.') && ipAddress.split('.')?.length === 4) {  
      return `${ipAddress} (IPv4)`
    } else if (ipAddress.includes(':') && ipAddress.split(':')?.length > 4){
      return `${ipAddress} (IPv6)`
    }
  }
}
