import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BaseService } from '../base.service';
import { TranslationService } from '../translation/translation.service';

export enum ClRequestStatus {
  REQUESTED = 'CallLogsRequested',
  FAILED_TO_REQUEST = 'FailedToRequestCallLog',
  CANCELLED = 'OperationCancelled',
  NOT_ALLOWED_COUNTRY = 'NotAllowedCountryCode',
  NOT_ENABLED = 'CallLogsNotEnabled',
  FORBIDDEN = 'CallLogsForbidden',
  UPLOAD = 'Upload',
  UPLOAD_SUCCESSFUL = 'UploadSuccessful',
}
@Injectable({
  providedIn: 'root'
})
export class CallLogRequestsMessagesService extends BaseService {
  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {
    super(router, snackBar);
  }

  showClResponseMessage(clRequestStatus: ClRequestStatus): void {
    switch (clRequestStatus) {
      case ClRequestStatus.REQUESTED:
        this.showMessage(this.translationService.translate(`Call Log has been requested`));
        break;
      case ClRequestStatus.FAILED_TO_REQUEST:
        this.showMessage(this.translationService.translate('Request has not been sent'));
        break;
      case ClRequestStatus.NOT_ENABLED:
        this.showMessage(this.translationService.translate('Requesting call logs has been disabled'));
        break;
      case ClRequestStatus.NOT_ALLOWED_COUNTRY:
        this.showMessage(
          this.translationService.translate('The request call log is not currently supported for this country')
        );
        break;
      case ClRequestStatus.CANCELLED:
        break;
      case ClRequestStatus.FORBIDDEN:
        this.showMessage(this.translationService.translate('Not enough credits'));
        break;
      default:
        break;
    }
  }
}
