import { Button, ButtonState } from 'src/app/modules/mapV2/models/map.models';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-map-button',
  templateUrl: './map-button.component.html',
  styleUrls: ['./map-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MapButtonComponent {
  @Input()
  button: Button;

  @Output()
  onClick = new EventEmitter<Button>();

  get icon(): string {
    return this.button.iconUrl[this.button.state];
  }

  get title(): string {
    return this.button.altText[this.button.state];
  }

  click() {
    this.changeState();
    this.onClick.emit(this.button);
  }

  private changeState() {
    if (this.button.state === ButtonState.Activated) {
      this.button.state = ButtonState.Deactivated;
    } else {
      this.button.state = ButtonState.Activated;
    }

  }
}
