import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataSource, Relation } from 'datalayer/models/platform-models';
import { Profile } from 'datalayer/models/social';
import { switchMap, take } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { ImageService } from 'src/app/services/image/image.service';
import { TargetRenewalService } from 'src/app/services/target/target-renewal.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseModalHeader } from 'src/app/shared/components/base-modal-component/base-modal-component.component';
import { WebintDisabledService } from 'src/app/shared/components/webint-disabled-modal/webint-disabled.service';
import { Platform } from 'src/app/shared/schemas/common/platforms';
import { isEmpty } from 'src/app/shared/util/isEmpty';
import { ProfilerService } from '../../services/profiler.service';
import { PlatformImages } from '../../shared/models/profiler-dashboard-sections.model';

export enum RelatedPeopleActionModel {
  CREATE_TARGET = 'related-people-add-to-target',
  ADVANCED_OSINT = 'related-people-advanced-osint',
  INVESTIGATE_LEAD = 'investigare-lead',
}

export interface RelatedPeopleDialogModel {
  profile: Profile;
  hasParent?: boolean;
}

@Component({
  selector: 'app-related-people-dialog',
  templateUrl: './related-people-dialog.component.html',
  styleUrls: ['./related-people-dialog.component.scss']
})
export class RelatedPeopleDialogComponent implements OnInit {
  @Output() closePressed = new EventEmitter<boolean>();
  imageUrl: string;
  public platformImages = PlatformImages;
  headerDetails: BaseModalHeader;
  theme: string;
  performAction: EventEmitter<{ key: string; profile: Profile }> = new EventEmitter<{
    key: string;
    profile: Profile;
  }>();
  profile: Profile;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public relatedPeopleData: RelatedPeopleDialogModel,
    public dialogRef: MatDialogRef<RelatedPeopleDialogComponent>,
    private imageService: ImageService,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    private router: Router,
    private profilerService: ProfilerService,
    private targetService: TargetService,
    private targetRenewalService: TargetRenewalService,
    private webintDisabledService: WebintDisabledService
  ) {
    this.theme = this.appConfigService.getConfigVariable('theme');
  }

  ngOnInit(): void {
    if (this.relatedPeopleData?.profile?.relations?.length) {
      const { source, profileUrl } = this.createProfileUrl(this.relatedPeopleData.profile.relations);
      this.relatedPeopleData.profile.url = profileUrl;
      this.relatedPeopleData.profile.source = source;
    }

    this.profile = this.relatedPeopleData.profile;
    this.imageUrl = this.profile.image && typeof this.profile.image === 'string' && this.profile.relations ? this.profile.image : this.getPhoto(this.profile);

    if (this.profile?.telno) {
      this.headerDetails = {
        label: `${this.translationService.translate('Target')} - ${this.profile.profileToTargetInfo.alias}`,
      }
    } else {
      this.headerDetails = {
        label: this.profile.name,
        platform: {
          id: this.profile.source,
          url: this.profile.url
        },
        hasParent: this.relatedPeopleData.hasParent
      };
    }
  }

  createProfileUrl(profileRelations: Relation[]): { profileUrl: string, source: DataSource } {
    const relation: Relation = profileRelations[0];
    let profileUrl: string;
    let source: DataSource;

    if (relation && Object.keys(relation).length) {
      const userId = relation.user_id.split('@')[0];
      const platform = relation.user_id.split('@')[1];
      if (platform === Platform.FACEBOOK) {
        profileUrl = `https://www.facebook.com/${userId}`;
        source = DataSource.Facebook;
      }
    }

    return { profileUrl, source };
  }

  getPhoto(media, isThumbnail: boolean = false): string {
    return <string>(
      (!isEmpty(media.image)
        ? this.imageService.getPhotoUrl(media?.image?.url, isThumbnail)
        : media.imageUrl
          ? this.imageService.getPhotoUrl(media.imageUrl, isThumbnail)
          : 'assets/static/images/user.svg')
    );
  }

  close() {
    this.dialogRef.close();
  }

  addToTarget(): void {
    this.performAction.emit({
      key: RelatedPeopleActionModel.CREATE_TARGET,
      profile: this.profile
    });
  }

  advancedOsint(): void {
    if(!this.webintDisabledService.handleWebintAvailability()) {
      return;
    }

    this.performAction.emit({
      key: RelatedPeopleActionModel.ADVANCED_OSINT,
      profile: this.profile
    });
  }

  investigateLead(): void {
    if (!this.webintDisabledService.handleWebintAvailability()) { return; }

    this.performAction.emit({
      key: RelatedPeopleActionModel.INVESTIGATE_LEAD,
      profile: this.profile,
    });
  }

  closeParent(event) {
    if (event) {
      this.dialogRef.close();
      this.closePressed.emit(event);
    }
  }

  pushBack(event) {
    this.dialogRef.close();
  }

  navigateToUrl(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }

  navigateToTarget(profile: Profile): void {
    if (!profile?.profileToTargetInfo?.targetId) {
      return;
    }
    this.getTargetData(profile?.profileToTargetInfo?.targetId);
  }

  getTargetData(targetId: string): void {
    this.targetService.getTarget(targetId).pipe(
      take(1),
      switchMap((response) => this.targetRenewalService.isTargetExpiredShowMessageOrRenew([response], true))
    ).subscribe((isTargetActive) => {
      if (!isTargetActive) {
        return;
      }
      this.router.navigate(['targets', targetId, 'overview']);
      this.profilerService.targetData.next(null);
      this.dialogRef.close();
    });
  }
}
