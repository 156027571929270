import { BaseDTO } from 'datalayer/services/base';
import { Place } from 'datalayer/models/social/place';
import { EntityType, DataSource, Media } from 'datalayer/models/platform-models';
import { Point } from 'src/app/modules/mapV2/models/map.models';
import { SocialPlatformMap } from 'src/app/shared/models/social-platform-map';

export class PlaceDTO extends BaseDTO<Place, PlaceDTO> {

  private id: any;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private visited_at: string;
  private updated_at?: Date;
  private source_entity?: {
    id: string,
    created_at: Date,
    parent_id: string;
  };
  private target_id?: string;
  private image?: Partial<Media>;
  private name?: string;
  private hours?: string;
  private phone?: string;
  private email?: string;
  private address?: string;
  private coordinates?: { 
    type: string, 
    coordinates: number[] | string
  };
  private rating?: string;
  private place_id?: string;
  private url?: string;

  constructor(model?: Partial<PlaceDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Place): PlaceDTO {
    this.id = model.id;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.target_id = model.targetId;
    this.image = model.image;
    this.name = model.name;
    this.email = model.email;
    this.phone = model.phone;
    this.rating = model.rating;
    this.hours = model.hours;
    this.coordinates = { 
      coordinates: [model.coordinates.lat, model.coordinates.lng], 
      type: 'Point' 
    };
    this.place_id = model.placeId;
    this.visited_at = model.visitedAt.toISOString();
    this.address = model.address;
    return this;
  }

  toModel(): Place {
    const place: Place = new Place({
      id: this.id,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      image: this.image,
      name: this.name,
      email: this.email,
      phone: this.phone,
      rating: this.rating,
      hours: this.hours,
      coordinates: this.extractPointFromVariousTypes(this.coordinates?.coordinates),
      placeId : this.place_id,
      visitedAt : new Date(this.visited_at),
      address : this.address,
      platform: SocialPlatformMap[this.source],
      url: this.url,
    });

    return place;
  }

  private extractPointFromVariousTypes = (data: string | number[]): Point | undefined => {
    if(!data) {
      return undefined;
    }
    switch(typeof data) {
      case 'string':
        const split = data.split(',').map(n => parseFloat(n))
        return { lat: split[0], lng: split[1]};
      case 'object':
        return { lat: data[1], lng: data[0]};
      default:
        console.warn('extractPointFromVariousTypes type not handled: ', typeof data);
        return undefined;
    }
  }
}
