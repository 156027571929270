import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, race, Subject, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { IMapService } from 'src/app/modules/mapV2/models/map-service.interface';
import { Point } from 'src/app/modules/mapV2/models/map.models';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { DEFAULT_COUNTRIES } from '../../../../components/settings/shared/default-countries';

@Injectable({
  providedIn: 'root'
})
export class MapService implements IMapService {
  constructor(
    private configService: AppConfigService,
    private translationService: TranslationService,
    private http: HttpClient
  ) {}

  memo = {
    streetView: {},
    locations: {}
  };

  getDefaultCenter(): Observable<Point> {
    return new Observable(observer => {
      const defaultCountry = this.configService.getConfigVariable('country') || 'mexico';
      const settingsLocation = this.getUserCountryFromSettings();
      const defaultLocation = this.getUserCountryFromSettings(defaultCountry);

      // Priority to user settings
      if (settingsLocation) {
        observer.next(settingsLocation);
        observer.complete();

        // Fallback to browser Location
      } else if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            observer.next({ lat: position.coords.latitude, lng: position.coords.longitude });
            observer.complete();
          },
          _ => {
            observer.next(defaultLocation);
            observer.complete();
          }
        );

        // Fallback to config json settings
      } else {
        observer.next(defaultLocation);
        observer.complete();
      }
    });
  }

  getIconPath(): string {
    throw new Error('Method not implemented.');
  }

  getDefaultMarkerIcon(): string {
    return '/assets/static/images/geo_default_pin.svg';
  }

  getStreetViewStaticImageUrl(point: Point): Observable<string> {
    const id = `${point.lng}_${point.lat}`;
    if (this.memo.streetView[id]) {
      return of(this.memo.streetView[id]);
    }

    const sv = new google.maps.StreetViewService();
    const obs$ = new Subject<string>();
    // ! Todo: Add api key from environment when systems enable the api
    const url = `https://maps.googleapis.com/maps/api/streetview?size=300x160&location=${point.lat},${point.lng}
    &fov=80&heading=70&pitch=0
    &key=AIzaSyBKfBv0GVwEahX_F_zdZKqZFFg7ay50-fw`;
    const location = new google.maps.LatLng(point);
    sv.getPanorama({ location, radius: 50 }, (_, status) => {
      if (status === google.maps.StreetViewStatus.OK) {
        obs$.next(url);
        this.memo.streetView[id] = url;
      } else {
        obs$.next(google.maps.StreetViewStatus.ZERO_RESULTS);
        this.memo.streetView[id] = google.maps.StreetViewStatus.ZERO_RESULTS;
      }
    });

    return obs$.asObservable();
  }

  reverseGeocoding(point: Point): Observable<string> {
    const id = `${point.lng}_${point.lat}`;
    if (this.memo.locations[id]) {
      return of(this.memo.locations[id]);
    }

    // ! Todo: Add environment variable when system deploy the nominatim service
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${point.lat}&lon=${point.lng}&zoom=18&addressdetails=1`;
    const timeout$ = of(408).pipe(
      delay(7000),
      switchMap(v => throwError(v))
    );
    const request$ = this.http.get<any>(url).pipe(
      map(result => result.display_name),
      tap(address => {
        this.memo.locations[id] = address;
      })
    );

    return race(timeout$, request$).pipe(
      catchError(_ => {
        const lat = point.lat.toFixed(4);
        const lng = point.lng.toFixed(4);
        console.error('Nominatim server timeout');
        return of(
          `${this.translationService.translate('Latitude')}: ${lat}, ${this.translationService.translate(
            'Longitude'
          )}: ${lng}`
        );
      })
    );
  }

  reverseGeocodingGetPlace(point: Point): Observable<string> {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${point.lat}&lon=${point.lng}&zoom=18&addressdetails=1`;
    return this.http.get<any>(url);
  }

  private getUserCountryFromSettings(location?: string): Point {
    const userCountry = location || localStorage.getItem('mapCoordinates');
    const countryInfo = DEFAULT_COUNTRIES.find(country => country.value === userCountry);

    if (!countryInfo || countryInfo.value === 'none') {
      return null;
    }

    return { lat: countryInfo.lat, lng: countryInfo.lon };
  }
}
