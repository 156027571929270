<app-apx-radial-bar-chart
  [series]="series"
  [labels]="labels"
  [colors]="colors"
  [totalLabel]="gaugeLabel"
  [totalValue]="gaugeValue"
></app-apx-radial-bar-chart>

<div class="bullets-container">
  <div class="bullet-row" *ngFor="let bullet of bullets">
    <span class="bullet" [ngStyle]="{ 'background-color': bullet.color }"></span>
    <span class="bullet-text">{{ bullet.label }} - {{ bullet.value }}%</span>
  </div>
</div>
