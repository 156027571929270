export enum SortOrder {
  ASC,
  DESC,
}

export interface RequestOptions {
  filters?: { [propertyName: string]: string | boolean | number | string[] };
  resultsPerPage?: number;
  firstResult?: number;
  sort?: { property: string, direction: SortOrder }[];
}
