<div class="filter-wrp flex-wrp assistant-font">
  <!-- Location Filter -->
  <div class="filters">
    <button mat-button (click)="handleMenuBehaviour(filterType.LOCATION)"
    [matMenuTriggerFor]="location" #locationMenuTrigger="matMenuTrigger" class="cp-btn-dropdown">
      <span class="word-dots">{{selectedLocation.rawAddress ? selectedLocation.rawAddress : 'Location' | translate}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu class="filter-drop" #location="matMenu" [hasBackdrop]="filterBehaviour">
      <div class="filter-menu" (click)="$event.stopPropagation()">
        <div class="filter-header flex-wrp">
          <p class="assistant-font">{{'Select location' | translate}}</p>
          <mat-icon class="close-menu" (click)="locationMenu.closeMenu()">close</mat-icon>
        </div>
        <div class="input-style-round" [matMenuTriggerFor]="currentCity" #currentCityTrigger="matMenuTrigger">
          <input matInput placeholder="{{'Look for a country, city or address' | translate}}" ngx-google-places-autocomplete
            [formControl]='locationInput' [options]='options' #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)" />

            <mat-menu class="current-city-menu" #currentCity="matMenu" [hasBackdrop]="false">
              <button class="current-city ubuntu-font capitalize-none" (click)="getCurrentCity()" *ngIf="!locationInput.value">
                <mat-icon>near_me</mat-icon><span>{{ 'Current city' | translate }} </span>
              </button>
            </mat-menu>
        </div>

        <!-- Filter Actions -->
        <div class="apply-wrp flex-wrp">
          <button mat-button class="cp-btn-clear"
            (click)="clearFilters(filterType.LOCATION)">{{ 'Clear' | translate}}</button>
          <button mat-button class="cp-btn-primary ubuntu-font"
            (click)="onApplyFilters(filterType.LOCATION)">{{ 'Apply' | translate}}</button>
        </div>
      </div>
    </mat-menu>
  </div>

  <!-- Age Filter -->
  <div class="filters">
    <button mat-button (click)="handleMenuBehaviour(filterType.AGE)" [matMenuTriggerFor]="age"
      #ageMenuTrigger="matMenuTrigger" class="cp-btn-dropdown">{{'Age' | translate}}
      <span *ngIf="ageFilter.min">{{ageFilter.min + '-' + ageFilter.max}}</span><mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu class="filter-drop" #age="matMenu" [hasBackdrop]="filterBehaviour">
      <div class="filter-menu" (click)="$event.stopPropagation()">
        <div class="filter-header flex-wrp">
          <p class="assistant-font">{{'Choose age range' | translate}}</p>
          <mat-icon class="close-menu" (click)="ageMenu.closeMenu()">close</mat-icon>
        </div>
        <div class="age-slider">
          <nouislider [connect]="true" [min]="13" [max]="90" [step]="1" [(ngModel)]="sliderRange" [tooltips]="true">
          </nouislider>
        </div>

        <!-- Filter Actions -->
        <div class="apply-wrp flex-wrp">
          <button mat-button class="cp-btn-clear"
            (click)="clearFilters(filterType.AGE)">{{ 'Clear' | translate}}</button>
          <button mat-button class="cp-btn-primary ubuntu-font"
            (click)="onApplyFilters(filterType.AGE)">{{ 'Apply' | translate}}</button>
        </div>
      </div>
    </mat-menu>
  </div>

  <!-- More Filters -->
  <div class="filters">
    <button mat-button (click)="handleMenuBehaviour(filterType.MOREFILTERS)"
    [matMenuTriggerFor]="moreFilters" #filterMenuTrigger="matMenuTrigger" class="cp-btn-dropdown">
      {{'More filters' | translate}} <span *ngIf="moreFiltersCount">{{'('+moreFiltersCount+')'}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu class="filter-drop" #moreFilters="matMenu" [hasBackdrop]="filterBehaviour">
      <div class="filter-menu" (click)="$event.stopPropagation()">
        <div class="filter-header flex-wrp">
          <p class="assistant-font">{{'More filters' | translate}}</p>
          <mat-icon class="close-menu" (click)="moreFilterMenu.closeMenu()">close</mat-icon>
        </div>
        <form class="filter-form">
          <div class="field-group">
            <label>{{ 'Connections' | translate}}</label>
            <mat-form-field class="input-wrp">
              <mat-chip-list #connectionsList>
                <mat-chip *ngFor="let item of connections" [selectable]="selectable" [removable]="removable"
                  (removed)="removeChips(item, filterType.CONNECTIONS)">
                  {{item}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="{{'Type a name' | translate}}" [matChipInputFor]="connectionsList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addChips($event, filterType.CONNECTIONS)">
              </mat-chip-list>
            </mat-form-field>
            <span class="input-hint">{{'For example' | translate}}: Moses Rafi, Oscar Wilde</span>
          </div>

          <div class="field-group">
            <label>{{ 'Jobs' | translate}}</label>
            <mat-form-field class="input-wrp">
              <mat-chip-list #jobList>
                <mat-chip *ngFor="let job of selectedJobs" [selectable]="selectable" [removable]="removable"
                  (removed)="removeChips(job, filterType.JOBS)">
                  {{job}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="{{'Start typing job description' | translate}}" #jobInput [formControl]="jobCtrl"
                  [matAutocomplete]="autoJob" [matChipInputFor]="jobList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addChips($event, filterType.JOBS)">
              </mat-chip-list>
              <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="selectedChips($event)">
                <mat-option *ngFor="let job of filteredJobs | async" [value]="job" (click)="$event.stopPropagation()">
                  {{job}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="field-group">
            <label>{{ 'Free text' | translate}}</label>
            <mat-form-field class="input-wrp">
              <mat-chip-list #freeTextList>
                <mat-chip *ngFor="let text of freeTexts" [selectable]="selectable" [removable]="removable"
                  (removed)="removeChips(text, filterType.FREETEXTS)">
                  {{text}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="{{'Type any term you would like to add' | translate}}"
                  [matChipInputFor]="freeTextList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addChips($event, filterType.FREETEXTS)">
              </mat-chip-list>
            </mat-form-field>
          </div>
        </form>

        <!-- Filter Actions -->
        <div class="apply-wrp flex-wrp">
          <button mat-button class="cp-btn-clear"
            (click)="clearFilters(filterType.MOREFILTERS)">{{ 'Clear' | translate}}</button>
          <button mat-button class="cp-btn-primary ubuntu-font"
            (click)="onApplyFilters(filterType.MOREFILTERS)">{{ 'Apply' | translate}}</button>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
