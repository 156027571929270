import { Component, Input } from '@angular/core';
import { Link } from '@trg-ui/link-analysis';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-link-details',
  templateUrl: './link-details.component.html',
  styleUrls: ['./link-details.component.scss']
})
export class LinkDetailsComponent {
  @Input() link: Link;
  fileManagerUrl = environment.fileManagerUri;


}
