import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { AuthService } from './auth.service';
import { isLoginPage } from './utils';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private idleAfterMinutesInactive: number;
  private sessionRefreshThreshold: number = 5;
  private idleTimeoutStop: Subject<void>;
  private serviceInitialized = false;
  constructor(
    private router: Router,
    private authSessionService: AuthService,
    private appConfigService: AppConfigService
  ) {}

  initialize() {
    if (!this.serviceInitialized) {
      this.serviceInitialized = true;
      this.idleAfterMinutesInactive = this.appConfigService.getConfigVariable('sessionIdleAfterMinutesInactive');
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe({
        next: (event: NavigationStart) => this.onNavigation(event),
      });
      this.isValid();
      this.activitySubscriptionInit();
    }
  }

  public isValid() {
    const { count: errorCount } = this.authSessionService.errors;
    const valid = this.authSessionService.token.valid && !errorCount;
    if (!valid) {
      this.authSessionService.logout();
    }
    return valid;
  }

  public activitySubscriptionInit() {
    if (this.authSessionService.token.expiresInMinutes <= this.sessionRefreshThreshold) {
      return this.authSessionService.refresh().subscribe({
        next: () => this.resetActivityTimeout(),
        error: () => this.authSessionService.logout(),
      });
    }
    return this.resetActivityTimeout();
  }

  public Reload() {
    return this.isValid() ? this.authSessionService.reload().subscribe() : undefined;
  }

  private resetActivityTimeout() {
    if (!this.idleTimeoutStop) {
      this.idleTimeoutStop = new Subject();
    } else {
      this.idleTimeoutStop.next();
    }
    timer(this.idleAfterMinutesInactive * 60000)
      .pipe(takeUntil(this.idleTimeoutStop))
      .subscribe({
        next: () => this.onIdle(),
      });
  }

  private async onIdle() {
    if (isLoginPage(this.router.url) || !this.authSessionService.token.valid) {
      return this.authSessionService.logout();
    }
    const { value = false } = await this.authSessionService.showSessionWillExpireAlert(this.idleTimeoutStop, 30);
    if (!value) {
      return this.authSessionService.logout();
    }
    this.resetActivityTimeout();
  }
  private onNavigation(event: NavigationStart) {
    if (isLoginPage(event.url)) {
      return;
    }
    this.isValid();
    this.activitySubscriptionInit();
  }
}
