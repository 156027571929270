import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseMapComponent } from 'src/app/modules/mapV2/components/map/base-map.component';
import { Circle, Point, Rectangle } from 'src/app/modules/mapV2/models/map.models';
import { environment } from 'src/environments/environment';
import { MapType } from '../../models/map-type.enum';
import { VanillaGoogleMapComponent } from '../../vanilla-google-map/vanilla-google-map.component';


@Component({
  // TODO: change the selector
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent extends BaseMapComponent implements OnInit {
  @ViewChild('googleMap') googleMap: VanillaGoogleMapComponent;

  @Input()
  requestedMap: MapType;
  mapType = MapType;
  @Output() tilesLoadedChanges = new EventEmitter<void>();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  getVanillaGoogleMap(): VanillaGoogleMapComponent {
    return this.googleMap;
  }

  ngOnInit() {
    if (!this.requestedMap) {
      switch (environment.mapType) {
        case 'LEAFLET':
          this.requestedMap = MapType.Leaflet;
          break;
        case 'VANILLA_GOOGLE':
          this.requestedMap = MapType.VanillaGoogle;
          break;
      }

      this.cdRef.markForCheck();
    }
  }

  onAreaOfInterestDrawn(circle: Circle) {
    this.circleDrawn.emit(circle);
  }

  onUserLocation(location: Point) {
    this.userLocation.emit(location);
  }

  onStopDrawing(shapeData) {
    this.stopDrawing.emit(shapeData);
  }

  onRectangleDrawn(shape: Rectangle) {
    this.rectangleDrawn.emit(shape);
  }

  onRenderingCompleted() {
    this.renderingCompleted.emit(void 0);
  }

  onMarkerDrawn(position: Point) {
    this.markerDrawn.emit(position);
  }

  onMarkerClicked(markerId: string) {
    this.markerClicked.emit(markerId);
  }
  onClusterClicked(data: any) {
    this.clusterClicked.emit(data);
  }

  onCircleDrawn(circle: Circle) {
    this.circleDrawn.emit(circle);
  }
}
