export class PurchaseNotificationResponse {
  id?: string;
  content: {
    msisdn: string;
    uploadedAt?: string;
    callLogRequestId?: string;
  };
  notificationType: string;
  createdAt?: string;
  createdBy?: string
}

export enum PurchaseNotificationType {
  PurchasePending = "calllogpurchases:notifyUserOnPurchasePending",
  PurchaseCompleted = "calllogpurchases:notifyUserOnPurchaseCompleted",
  PurchaseFailed = "calllogpurchases:notifyUserOnPurchaseFailed"
}
