<app-lightbox-component>
  <div class="row base-modal-container">
    <div *ngIf="!hideCoverSection" class="col-md-6 col-sm-6 col-12 p-0 cover-section">
      <ng-content select="coverContent"></ng-content>
    </div>
    <div
      [class]="
        hideCoverSection
          ? 'col-md-12 col-sm-12 col-12 p-0 details-section'
          : 'col-md-6 col-sm-6 col-12 p-0 details-section'
      "
    >
      <div mat-dialog-title class="dialog-title">
        <div class="col-md-12 header">
          <div class="title">
            <p
              class="push-back assistant-font"
              (click)="pushBack()"
              *ngIf="headerDetails?.hasParent"
            >
              <mat-icon>arrow_back_ios</mat-icon>
              <span>{{ 'Back' | translate }}</span>
            </p>
            <h1 class="assistant-font">
              {{
                headerDetails.platform?.id
                  ? platformNames[headerDetails.platform.id] + ' - ' + headerDetails?.label
                  : headerDetails?.label
              }}
            </h1>
            <span
              class="align-center"
              *ngIf="headerDetails?.platform && headerDetails?.platform?.url"
              (click)="navigateToUrl(headerDetails?.platform.url)"
            >
              <img
                class="platform-image clickable"
                [src]="platformImages[headerDetails?.platform.id]"
              />
            </span>
          </div>
        </div>
        <button class="close-icon" mat-icon-button aria-label="close dialog" (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="details-container" [class.p-0]="hideCoverSection">
        <ng-content select="detailsContent"></ng-content>
      </div>
    </div>
  </div>
</app-lightbox-component>
