import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { RedirectionRoutes } from '../authentication/types';
import { Observable, map, skipWhile, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryGuard implements CanActivate {
  constructor(private router: Router, private appConfigService: AppConfigService) {}
  canActivate(): Observable<boolean> | boolean {
    this.appConfigService.fetchAuthenticatedConfig();
      return this.appConfigService.authenticatedConfigLoaded$.pipe(
        skipWhile((v) => !v),
        take(1),
        tap(() => {
          if (this.appConfigService.getConfigVariable('disableLocatingFunctionality')) {
            this.router.navigate(['/webint']);
            return false;
          }

          return true;
        })
      );

  }
}