export enum UsersTableHeaders {
  Username = 'username',
  Email = 'email',
  Phone = 'phone',
  Credits = 'credits',
  Otp = 'otp',
  OtpPhone = "otp_phone",
  Quota = "quota",
  IsLocked = "is_locked",
  Notifications = 'notifications',
  Features = 'features',
  Roles = 'roles',
  Status = 'status',
  Actions = 'actions'
}