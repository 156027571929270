import { Entity } from 'datalayer/models/platform-models';

export enum DataChangeType {
    Create,
    Update,
    Delete
}

export interface DataChangeNotification<T_MODEL extends Entity, T_CHANGE_TYPE = DataChangeType> {
    type: T_CHANGE_TYPE;
    models: T_MODEL[];
    message?: string;
}
