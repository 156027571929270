import { BaseApiService } from 'datalayer/services/base';
import { DataLayerModule } from 'datalayer/data-layer.module';
import { Album } from 'datalayer/models';
import { AlbumDTO } from 'src/app/modules/data-layer/services/social/album/album-dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: DataLayerModule
})

export class AlbumApiService extends BaseApiService<Album, AlbumDTO> {

  constructor(httpClient: HttpClient) {
    super(httpClient, AlbumDTO);
  }

  public getModelName(): string {
    return Album.name;
  }

}
