import { Component } from '@angular/core';


@Component({
  selector: 'app-search-intel-loader',
  templateUrl: './search-intel-loader.component.html',
  styleUrls: ['./search-intel-loader.component.scss']
})
export class SearchIntelLoader  {

  constructor() {
  }


}
