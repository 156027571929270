<div class="geloc-target-actions">
  <div class="action">
    <span
      appDebounceClick
      class="icon-btn"
      matTooltip="{{ 'Get current location of this MSISDN' | translate }}"
      (debounceClick)="onLocate(query)"
    >
      <div class="locate"></div>
      <span class="txt">{{ 'Locate now' | translate }}</span>
    </span>
  </div>
  <div class="action" [ngClass]="{ disabled: !telno }">
    <span
      class="icon-btn"
      matTooltip="{{ 'Uncover usernames, open web mentions, email and social media accounts' | translate }}"
      (click)="intelSearchQuery(query)"
    >
      <div class="search"></div>
      <span class="txt">{{ 'Investigate lead' | translate }}</span>
    </span>
  </div>
  <div class="action">
    <span class="icon-btn" matTooltip="{{ 'Export detailed geolocation report' | translate }}" (click)="generatePdf()">
      <div class="export"></div>
      <span class="txt">{{ 'Export' | translate }}</span>
    </span>
  </div>
  <div
    class="action"
    [ngClass]="{ disabled: clInProgress }"
    [matTooltipDisabled]="!clInProgress"
    matTooltip="{{ 'Pending Request' | translate }}"
    *ngIf="telno | callLogPermitted"
  >
    <span
      class="icon-btn"
      matTooltip="{{ 'Unveil movement patterns and understand your target’s habits and associates' | translate }}"
      [matTooltipDisabled]="clInProgress"
      (click)="requestCallLog()"
    >
      <div class="call-log"></div>
      <span class="txt">{{ (clInProgress ? 'Pending call log' : 'Request Call Log') | translate }}</span>
    </span>
  </div>
  <div [ngClass]="{ disabled: !telno }" class="action">
    <span
      class="icon-btn"
      matTooltip="{{
        target
          ? ('Find possible associates, visited places, connections on social media, groups and more.' | translate)
          : ('Create a new target and find possible associates, visited places, connections on social media, groups and more.'
            | translate)
      }}"
      (click)="openAdvancvedOSINTDialog()"
    >
      <div class="advanced-osint"></div>
      <span class="txt">{{ 'Advanced Webint' | translate }}</span>
    </span>
  </div>
</div>
