import { Profile as PlatformProfile, EntityRelationType } from 'datalayer/models/platform-models';
import { BaseTargetEntity } from 'datalayer/models/platform-models/base/base-target-entity';
import { Media } from 'datalayer/models/media/media';
import { RecoveryAccount } from 'src/app/shared/models/recovery-account.model';

export class Profile extends PlatformProfile implements BaseTargetEntity {
  targetId: string;
  profileId?: string;
  relationType?: EntityRelationType;
  image?: Partial<Media>;
  userFriendlyUrl?: string;
  recoveryAccount?: RecoveryAccount;
  userIds?: string[];
  fromUserId?: string;

  constructor(model?: Partial<Profile>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }
}
