import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isLoginPage } from './authentication/utils';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected url: string = environment.serverAPIUri;
  protected fastAPIurl: string = environment.fastAPIUri;
  protected reportServiceUrl: string = environment.reportAPIUri;
  protected reportServiceV2Url: string = environment.reportV2APIUri;
  protected proxyApiUrl: string = environment.proxyAPIUri;
  defaultSnackbarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  defaultSnackbarVerticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
  ) { }

  protected handleError(response: HttpErrorResponse) {
    const { error, status, message } = response;
    if ([401, 403].includes(status)) {
      if (!isLoginPage(this.router.url)) {
        if (error?.error?.message) {
          return throwError(() => error?.error?.message);
        }
        if (error?.message) {
          return throwError(() => error);
        }
      }
    } else if (status >= 500) {
      return throwError(() => 'Something bad happened; please try again later.');
    } else if (status === 400) {
      return throwError(() => error?.error ?? error);
    } else if (status === 402) { // payment error
      return throwError(() => response);
    }
    return throwError(() => message);
  }

  showMessage(msg: string,
    okText = 'OK',
    preferredDuration: number = 3000,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition,
  ) {
    this.snackBar.open(
      msg,
      okText, {
      duration: preferredDuration,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar']
    });
  }
}
