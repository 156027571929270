import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BaseDialogComponent } from './base-dialog.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class BaseFormComponent extends BaseDialogComponent {

  form: FormGroup = new FormGroup({});
  saving: boolean = false;

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.save();
  }

  abstract save(): void;

  setSaving(saving: boolean = true, delayMillis: number = 0) {

    if (!delayMillis) {
      this.saving = saving;
      return;
    }

    of(null).pipe(delay(delayMillis)).subscribe(() => {
      this.saving = saving;
    });
  }


  showError(controlName: string, forRule: string = null): boolean {
    const control = this.form.get(controlName);
    const isDirty = (control.dirty || control.touched);

    if (!forRule) {
      return control.invalid && isDirty;
    }

    return control.errors ? control.errors[forRule] && isDirty : false;

  }

}
