import { BaseDTO } from 'datalayer/services/base';
import { EntityType, DataSource } from 'datalayer/models/platform-models';
import { Album } from 'datalayer/models/social/album';

export class AlbumDTO extends BaseDTO<Album, AlbumDTO> {
  private id: any;
  private type: EntityType;
  private source: DataSource;
  private created_at: Date;
  private updated_at?: Date;
  private source_entity?: {
    id: string,
    created_at: Date,
    parent_id: string;
  };
  private target_id?: string;
  private url?: string;
  private name?: string;
  private description?: string;
  private album_id?: string;

  constructor(model?: Partial<AlbumDTO>) {
    super();

    if (model) {
      Object.assign(this, model);
    }
  }

  fromModel(model: Album): AlbumDTO {
    this.id = model.id;
    this.type = model.type;
    this.source = model.source;
    this.created_at = model.createdAt;
    this.updated_at = model.updatedAt;
    this.source_entity = {
      id: model.sourceEntity.id,
      created_at: model.sourceEntity.createdAt,
      parent_id: model.sourceEntity.parentId,
    };
    this.target_id = model.targetId;
    this.description = model.description;
    this.album_id = model.albumId;
    this.name = model.name;
    this.url = model.url;
    return this;
  }

  toModel(): Album {
    const album: Album = new Album({
      id: this.id,
      type: this.type,
      source: this.source,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      sourceEntity: {
        id: this.source_entity.id,
        createdAt: this.source_entity.created_at,
        parentId: this.source_entity.parent_id,
      },
      targetId: this.target_id,
      description: this.description,
      albumId: this.album_id,
      name: this.name,
      url: this.url,
    });

    return album;
  }

}
