import { EnvironmentConstants } from './models';
const FASTAPI_HOST = window.location.host;
const wsScheme = window.location.href.startsWith('https') ? 'wss' : 'ws';
const serverAPIUri = `/api/v1`;
export const environmentConstantsProd: EnvironmentConstants = {
  production: true,
  serverAPIUri: `/api/v1`,
  fastAPIUri: `/fastapi/api/v2`,
  reportAPIUri: '/reports',
  reportV2APIUri: '/reports-v2',
  avatarV1API: '/avatars/v1',
  avatarV2API: '/avatars/v2',
  proxyAPIUri: `/server-ts`,
  proxyAPIUWebSocketUri: `${wsScheme}://${FASTAPI_HOST}`,
  fileManagerUri: window.location.origin + '/file-manager',
  recaptchaV3SiteKey: '6Le3IWcaAAAAAJpRtWK2LaCrQvTg4DaWrrIRuPMw',
  knowledgeBaseUrl: 'intellectus.knewise.xyz/login',
  kbUsername: 'intellectus',
  kbPassword: 'YCPTJsoo8RPmFaApCdoNLy6NY3pdVvaNMinYCqF8p79ETF9vkGzzuHor6aCknvut',
  googleAPIKey: 'AIzaSyC0kUxs-BnyTuIS80ks-HCLOGJLtX8cqO0',
  intelTypes: {
    analytics: `${serverAPIUri}/analytics`,
  },
  supportEmailDomain: "trgint.com",
  grafanaDashboardUrl: window.location.origin + ':8488',
  hotjarKey: '',
};
