import { Injectable } from '@angular/core';
import { PurchaseNotificationResponse } from 'datalayer/models/background-jobs/purchase-notifications';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AuthState } from 'src/app/services/authentication/auth-state.enum';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GroupNotification } from 'src/app/shared/models/group-notifications.model';
import { PurchaseNotificationComponent } from './purchase-notification.component';
import { PurchaseNotificationsStore } from './purchase-notifications.store';

@Injectable({
  providedIn: 'root'
})
export class PurchaseNotificationsService extends GroupNotification {
  toastMessage = new BehaviorSubject<any>(null);

  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private toastStore: PurchaseNotificationsStore) {
    super();
    this.authService.authState$.subscribe(state => {
      if (state !== AuthState.authenticated) {
        this.cleanAllToasts();
      }
    });
  }

  processNotification(message: PurchaseNotificationResponse) {
    if (!this.toastStore.hasNotificationWithId(message.content.msisdn)) {
      this.addToast(message);
    }
    this.toastStore.addNotification(message);
  }

  public cleanAllToasts() {
    this.toastr.toasts.forEach(t => {
      t.toastRef.close();
      this.toastStore.cleanNotificationsById(t.toastRef.componentInstance.id);
    });
  }

  protected addToast(message: any) {
    const toast = this.toastr.success(null, null, {
      toastComponent: PurchaseNotificationComponent,
      positionClass: 'toast-bottom-right',
    });

    toast.toastRef.componentInstance.id = message.content.msisdn;
    toast.toastRef.componentInstance.toast = toast;
  }

  cleanNotificationsById(message: PurchaseNotificationResponse) {
    this.toastStore.cleanNotificationsById(message.content.msisdn);
  }
}
