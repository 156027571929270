import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
  }
}
