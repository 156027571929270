import { Action } from './action.class';
import { OnDestroy, Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseComponent } from './base.component';

@Directive()
export class BaseDialogComponent extends BaseComponent implements OnDestroy {

  constructor(protected dialog: MatDialogRef<any> = null, protected router: Router = null) {
    super();
    if(router){
      this._subscribeToNavigationChanges();
    }
  }

  close(action: Action = new Action({ key: 'cancel' })) {
    if (this.dialog) {
      this.dialog.close(action);
    }
  }

  private _subscribeToNavigationChanges() {
    this.subscription = this.router.events
      .subscribe(() => {
        this.close();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
