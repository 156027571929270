<app-base-modal-component [headerDetails]="headerDetails" class="height-100">
  <ng-container ngProjectAs="coverContent">
    <div *ngIf="entityDetails.type === 'location'">
      <div class="profiler-geolocation-details-dialog-map">
        <!-- MAP -->

        <app-map
          requestedMap="vanilla-google"
          [zoom]="zoom"
          [mapOptions]="mapOptions"
          height="750"
          [center]="center"
          [showExpandBtn]="false"
          [markers]="markers"
          [circles]="circles"
        >
        </app-map>
      </div>
    </div>
    <div *ngIf="photo && entityDetails.type !== 'location'" class="image-details-dialog">
      <img class="blur-img" [src]="photo" appFallbackImg="assets/static/images/no_photo.svg" />
      <img class="bg-img" [src]="photo" appFallbackImg="assets/static/images/no_photo.svg" />
    </div>
  </ng-container>

  <ng-container ngProjectAs="detailsContent">
    <div class="entity-item-modal">
      <div *ngIf="entityDetails?.url" class="post-details">
        <div class="img-wrp">
          <img class="platform-image clickable" [src]="platformImages[headerDetails?.platform.id]" />
          <img [src]="profileImage" loading="lazy" class="profile-image clickable post-ico" alt="" />
        </div>

        <p class="post-date assistant-font">
          <span *ngIf="entityDetails?.publishedAt && entityDetails?.publishedAt !== 'None'"
            >{{ 'Posted on' | translate }}
            {{ entityDetails?.publishedAt | date: 'dd/MM/yyyy HH:mm' }}
            |</span
          >

          <span (click)="gotoUrl(entityDetails?.url)" class="source-url">{{ 'View original post' | translate }}</span>
        </p>
      </div>

      <div *ngIf="entityDetails?.title" class="item-description">
        {{ entityDetails?.title }}
      </div>

      <!-- DESCRIPTION -->
      <div>
        <div *ngIf="profile" class="col-12 profile-details">
          <div *ngIf="profile.name.trim()" class="item-description">
            <span>
              <b>{{ 'Name' | translate }}:</b> {{ profile.name }}
            </span>
          </div>
          <div *ngIf="profile.about" class="item-description">
            <span>
              <b>{{ 'About' | translate }}:</b> {{ profile.about }}
            </span>
          </div>
          <div *ngIf="profile.username" class="item-description">
            <span>
              <b>{{ 'Username' | translate }}:</b> {{ profile.username }}
            </span>
          </div>
          <div *ngIf="profile.telno" class="item-description">
            <span>
              <b>{{ 'Phone' | translate }}:</b> {{ profile.telno }}
            </span>
          </div>
        </div>

        <ng-container *ngIf="feed?.albumId && album">
          <div *ngIf="album.name" class="item-description">
            {{ 'Album name' | translate }}:
            <span class="post-content album-details">{{ album.name }}</span>
          </div>

          <div *ngIf="album.url" class="item-description">
            {{ 'Album url' | translate }}:
            <span (click)="openIncognito(album.url)" class="post-content post-content-url album-details">{{
              album.url
            }}</span>
          </div>
        </ng-container>

        <div *ngIf="entityDetails?.description || entityDetails?.content" class="item-title">
          <b> {{ 'Description' | translate }} </b>
        </div>
        <ng-container>
          <div *ngIf="entityDetails?.description || entityDetails?.content" class="item-description">
            {{ entityDetails?.description || entityDetails?.content }}
          </div>
        </ng-container>
        <div class="reactions">
          <span *ngIf="entityDetails?.feedbackStats?.likesCount" class="item-description">
            {{ entityDetails?.feedbackStats.likesCount }} {{ 'likes' | translate | titlecase }}
          </span>
          <span *ngIf="entityDetails?.feedbackStats?.commentsCount" class="item-description">
            | {{ entityDetails?.feedbackStats.commentsCount }} {{ 'comments' | translate | titlecase }}
          </span>
          <span *ngIf="entityDetails?.feedbackStats?.resharesCount" class="item-description">
            | {{ entityDetails?.feedbackStats.resharesCount }} {{ 'shares' | translate | titlecase }}
          </span>
          <span *ngIf="entityDetails?.feedbackStats?.reactorsCount" class="item-description">
            | {{ entityDetails?.feedbackStats.reactorsCount }} {{ 'reactions' | translate | titlecase }}</span
          >
        </div>
      </div>

      <ng-container [ngSwitch]="entityDetails.type">
        <!-- NOTE TYPE -->
        <div *ngSwitchCase="'note'" class="feed-item-detail-view-dialog-note-container row">
          <ng-container *ngIf="entityDetails?.title !== 'Somedus'">
            <div class="col-6">
              <div
                class="profiler-feed-description"
                [class.link-description]="entityDetails?.url"
                *ngIf="entityDetails?.articleTitle"
                (click)="openIncognito(entityDetails?.url)"
              >
                {{ entityDetails?.feed?.feed?.articleTitle }}
              </div>
              <div
                class="profiler-feed-description"
                *ngIf="
                  !entityDetails?.description?.startsWith('http') && !entityDetails?.description
                    | includes: 'onion' && !entityDetails?.title
                    | includes: 'Twitter tweet' && !entityDetails?.description
                    | includes: 'twitter'
                "
              >
                {{ entityDetails?.description || '' }}
              </div>
              <div
                class="profiler-feed-description link-description"
                *ngIf="
                  entityDetails?.description &&
                    entityDetails?.description?.startsWith('http') &&
                    !entityDetails?.description | includes: 'onion'
                "
                (click)="openIncognito(entityDetails?.description)"
              >
                {{ entityDetails?.description }}
              </div>
              <!-- feed from open web -->
              <div
                class="profiler-feed-description link-description"
                *ngIf="entityDetails?.title === 'Open-Web Result'"
                (click)="openIncognito(entityDetails?.url)"
              >
                {{ entityDetails?.description }}
              </div>
              <!-- feed from dark web -->
              <div *ngIf="entityDetails?.description | includes: 'onion'">
                <span
                  class="profiler-feed-description link-description"
                  matTooltip="{{ 'Copy URL' | translate }}"
                  [copyClipboard]="entityDetails?.description"
                >
                  <span *ngIf="entityDetails?.description.length >= 100"
                    >{{ entityDetails?.description | slice: 0:100 }}...</span
                  >
                  <span *ngIf="entityDetails?.description.length < 100">{{ entityDetails?.description }}</span>
                  <mat-icon class="icon icon-space">content_copy</mat-icon>
                </span>
                <mat-icon
                  *ngIf="entityDetails.url"
                  class="icon icon-description"
                  (click)="openUrlNewTab('/api/v1' + entityDetails?.url)"
                  matTooltip="{{ 'Open in a new tab' | translate }}"
                >
                  remove_red_eye</mat-icon
                >
              </div>
              <!-- feed from Twitter tweet -->
              <div
                *ngIf="
                  entityDetails?.description && entityDetails?.title && entityDetails?.title?.includes('Twitter tweet')
                "
              >
                <span class="profiler-feed-description">
                  {{ entityDetails?.description }}
                </span>
              </div>
              <div
                class="link-description"
                *ngIf="entityDetails?.url && entityDetails?.title && entityDetails?.title?.includes('Twitter tweet')"
                (click)="openUrlNewTab(entityDetails?.url)"
              >
                <span class="profiler-feed-description link-description">
                  {{ entityDetails?.url }}
                </span>
                <mat-icon
                  *ngIf="entityDetails?.url"
                  class="icon icon-description"
                  matTooltip="{{ 'Open in a new tab' | translate }}"
                  >remove_red_eye</mat-icon
                >
              </div>

              <div *ngIf="entityDetails?.description | includes: 'twitter'">
                <span
                  class="profiler-feed-description link-description"
                  matTooltip="{{ 'Click to copy the URL' | translate }}"
                  [copyClipboard]="entityDetails?.description"
                >
                  <span *ngIf="entityDetails?.description?.length >= 100"
                    >{{ entityDetails?.description | slice: 0:100 }}...</span
                  >
                  <span *ngIf="entityDetails?.description?.length < 100">{{ entityDetails?.description }}</span>
                  <mat-icon
                    class="icon icon-space"
                    matTooltip="{{ 'Copy URL' | translate }}"
                    [copyClipboard]="entityDetails?.description"
                    >content_copy</mat-icon
                  >
                </span>
                <mat-icon
                  *ngIf="entityDetails?.url"
                  class="icon icon-description"
                  (click)="openUrlNewTab(entityDetails?.url)"
                  matTooltip="{{ 'Open in a new tab' | translate }}"
                >
                  remove_red_eye</mat-icon
                >
              </div>

              <div class="feed-item-details-view-dialog-title" *ngIf="entityDetails?.image">
                {{ 'Image' | translate }}
              </div>
              <img
                *ngIf="entityDetails?.image"
                class="profiler-note-image"
                src="{{ legacyFilesUri }}/files/{{ entityDetails?.image }}"
                height="250"
              />
              <div *ngIf="entityDetails?.media && entityDetails?.media.length">
                <div *ngFor="let photo of entityDetails?.media">
                  <img class="profiler-note-image" [src]="getPhoto(photo)" height="250" />
                </div>
              </div>
              <div class="feed-item-details-view-dialog-title" *ngIf="entityDetails?.location">
                {{ 'Location' | translate }}
              </div>
              <div *ngIf="entityDetails?.location" class="note-map-location">
                <app-map
                  requestedMap="vanilla-google"
                  [zoom]="zoom"
                  [mapOptions]="mapOptions"
                  [height]="500"
                  [center]="center"
                  [showExpandBtn]="false"
                  [markers]="markers"
                >
                </app-map>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="entityDetails?.title === 'Somedus' && somedusDetails?.length > 0">
            <div class="col-12">
              <div class="somedus-wrp" *ngFor="let somedus of somedusDetails; let i = index">
                <div class="content">
                  <span class="heading">
                    {{ i + 1 }}. SOMEDUS {{ 'for' | translate }} {{ somedus?.platform | titlecase }}
                    {{ 'is here' | translate }}
                  </span>
                  <div class="col-md-12 item">
                    <b> URL:</b>
                    <span class="profiler-feed-description m-0" (click)="openIncognito(somedus?.response?.url)">
                      {{ somedus?.response?.url }}
                    </span>
                    <div
                      class="copy-icon"
                      [cdkCopyToClipboard]="copyToClipboard"
                      (mouseup)="copyValue(somedus?.response?.url)"
                    >
                      <img src="/assets/static/images/search-intel/copy-color.svg" />
                    </div>
                  </div>
                  <div class="col-md-12 item">
                    <b> {{ 'Password' | translate }}: </b>{{ somedus?.response?.password }}
                    <div
                      class="copy-icon"
                      [cdkCopyToClipboard]="copyToClipboard"
                      (mouseup)="copyValue(somedus?.response?.password)"
                    >
                      <img src="/assets/static/images/search-intel/copy-color.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div
        *ngIf="entityDetails.type === 'location'"
        class="inline-flex feed-item-detail-view-dialog-location-container"
      >
        <div class="profiler-geolocation-details-dialog-map">
          <!-- MAP -->
          <app-map
            requestedMap="vanilla-google"
            [zoom]="zoom"
            [mapOptions]="mapOptions"
            height="750"
            [center]="center"
            [showExpandBtn]="false"
            [markers]="markers"
            [circles]="circles"
          >
          </app-map>
        </div>
        <div class="profiler-geolocation-details-dialog-content">
          <img
            *ngIf="query.location && query.location.coordinates; else noLocationPin"
            height="25"
            class="profiler-geolocation-details-dialog-pin-icon"
            src="{{ basicImageSrc }}geo_default_pin.svg"
          />
          <ng-template #noLocationPin>
            <img
              height="25"
              class="profiler-geolocation-details-dialog-pin-icon"
              src="{{ basicImageSrc }}geoloc_no_location.png"
            />
          </ng-template>
          <!-- DETAILS -->
          <div class="item-title">
            <b> {{ 'Geolocation details' | translate }} </b>
          </div>
          <!-- PHONE INFO -->
          <div>
            <div class="item-title">
              {{ 'User equipment' | translate }}
            </div>
            <ng-container *ngFor="let item of phoneInfo">
              <div *ngIf="item.value" class="d-flex">
                <div class="item-description">
                  <b> {{ item.label }}: </b>
                </div>
                <div class="item-description">
                  {{ item.value }}
                </div>
              </div>
            </ng-container>
          </div>
          <!-- CALL INFO -->
          <div *ngIf="query.callInfo && query.callInfo.oncallNumber">
            <div *ngIf="!query.callInfo.billingId" class="item-title">
              {{ 'Current Call' | translate }}
            </div>
            <div *ngIf="query.callInfo.billingId" class="item-description">
              {{ query.callInfo.callDirection === 'INCOMING' ? 'Caller' : ('Callee' | translate) }}
              ID
            </div>
            <ng-container *ngFor="let item of callInfo">
              <div *ngIf="item.value" class="d-flex">
                <div class="item-description">
                  <b> {{ item.label }}: </b>
                </div>
                <div *ngIf="query.callInfo.billingId" class="item-description">
                  {{ item.value }}
                </div>
                <button
                  *ngIf="!query.callInfo.billingId"
                  mat-button
                  (click)="chargeCallInfoGeoQuery()"
                  matTooltip="{{ 'Get the CallerID (4 credits)' | translate }}"
                >
                  <mat-spinner *ngIf="serviceIsRunning" class="spinner spinner-caller" [diameter]="12" [color]="'warn'">
                  </mat-spinner>
                  <img src="assets/static/images/calling_phone.svg" height="10" width="10" />
                  <span>{{ 'Query' | translate }}</span>
                </button>
              </div>
            </ng-container>
          </div>
          <!-- MOBILE INFO -->
          <div>
            <div class="item-title">
              {{ 'Mobile network' | translate }}
            </div>
            <ng-container *ngFor="let item of mobileInfo">
              <div
                *ngIf="item.value"
                class="d-flex"
                [class.hidden]="item.type === 'mobileProviderInfo' && query.roaming"
              >
                <div class="item-description">
                  <b> {{ item.label }}: </b>
                </div>
                <div class="item-description">
                  {{ item.value }}
                </div>
              </div>
            </ng-container>
          </div>
          <!-- LOCATION INFO -->
          <div>
            <div class="item-title">
              {{ 'Location' | translate }}
            </div>
            <ng-container *ngFor="let item of locationInfo">
              <div *ngIf="item.value" class="d-flex">
                <div class="item-description">
                  <b> {{ item.label }}: </b>
                </div>
                <div class="item-description">
                  {{ item.value }}
                </div>
              </div>
            </ng-container>
            <div class="item-title" (click)="geolocExportPdf()">
              <img src="assets/static/images/pdf_color.svg" width="35" height="35" />
            </div>
          </div>
        </div>
      </div>

      <div class="reactors-section" *ngIf="likers?.length || commenters?.length">
        <span class="title assistant-font">{{ 'Relations' | translate }}</span>
        <mat-tab-group mat-align-tabs="start">
          <mat-tab *ngIf="likers?.length" label="Likers ({{ likers?.length }})">
            <!-- LIKERS -->
            <div>
              <div class="reactors-list reactors-list-full">
                <div *ngFor="let liker of likers" class="reactor" (click)="showRelatedPeople(liker)">
                  <img
                    class="clickable"
                    src="{{ fileManagerUrl }}/file/{{ liker.photo }}?useThumbnail=yes"
                    onerror="this.onerror=null;this.src='assets/static/images/user.svg'"
                    height="40"
                    width="40"
                  />
                  <div class="reactor-name global-text-overflow-wrap assistant-font">
                    {{ liker.name }}
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="commenters?.length" label="Commenters ({{ commenters?.length }})">
            <!-- COMMENTERS -->
            <div>
              <div class="reactors-list reactors-list-full">
                <div *ngFor="let commenter of commenters" class="reactor" (click)="showRelatedPeople(commenter)">
                  <img
                    class="clickable"
                    src="{{ fileManagerUrl }}/file/{{ commenter.photo }}?useThumbnail=yes"
                    onerror="this.onerror=null;this.src='assets/static/images/user.svg'"
                    height="40"
                    width="40"
                  />
                  <div class="reactor-name global-text-overflow-wrap assistant-font">
                    {{ commenter.name }}
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div *ngIf="entityDetails.feed?.queryDate" class="query-date assistant-font">
        {{ 'Query date' | translate }}:
        {{ entityDetails.feed.queryDate | date: 'dd/MM/yyyy HH:mm' }}
      </div>
    </div>
  </ng-container>
</app-base-modal-component>
