import { Injectable } from '@angular/core';
import { Entity } from 'datalayer/models/platform-models';
import { Observable, of } from 'rxjs';
import { DataLayerModule } from 'src/app/modules/data-layer/data-layer.module';
import { CacheService } from 'src/app/modules/data-layer/services/base/cache.service';
import { ModelStore } from 'src/app/modules/data-layer/services/base/model-store';
import { RequestOptions } from 'src/app/modules/data-layer/services/base/request-options.interface';
@Injectable({
  providedIn: DataLayerModule
})
export class EmptyCacheService<TYPE_MODEL extends Entity> implements CacheService<TYPE_MODEL> {

  public get(guid: string): TYPE_MODEL | null {
    return null;
  }

  public getAll(requestOptions: RequestOptions): ModelStore<TYPE_MODEL> {
    return {};
  }

  public put(entries: TYPE_MODEL[]): Observable<TYPE_MODEL[]> {
    return of(entries);
   }

  public delete(entries: TYPE_MODEL[]): void { }

  public deleteAll(): void { }

  public getStorageComplete(): boolean {
    return false;
  }

  public setStorageComplete(state: boolean): void { }
}

