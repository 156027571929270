import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { debounce } from 'lodash-es';
import { Subscription } from 'rxjs';
import { AnalyticsService, DayOfWeek } from 'src/app/services/analytics/analytics.service';
import { DashboardService, DashboardView } from 'src/app/services/dashboard/dashboard.service';
import { QueryService } from 'src/app/services/query/query.service';
import { Query } from 'src/app/shared/models/query-item.model';
import { TimeFormatter } from 'src/app/components/analytics/day-time-selector/time-formatter';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationStateService } from 'src/app/services/application/application-state.service';
import { ProfilerService } from 'src/app/modules/profiler/services/profiler.service';

@Component({
  selector: 'app-day-time-selector',
  templateUrl: './day-time-selector.component.html',
  styleUrls: ['./day-time-selector.component.scss']
})
export class DayTimeSelectorComponent extends BaseComponent implements OnInit, OnDestroy {
  show: boolean  = false;
  isLoading: boolean = false;
  telno: string | undefined = undefined;
  sliderConfig = {
    behaviour: 'drag',
    connect: true,
    start: [0, 23],
    margin: 0,
    step: 1,
    range: {
      min: 0,
      max: 23
    },
    tooltips: [new TimeFormatter(), new TimeFormatter()]
  };
  hourRange: {
    from: string | undefined,
    to: string | undefined
  } = {
    from: undefined,
    to: undefined
  };
  day: number | undefined = undefined;
  subscriptions: Subscription[] = [];
  private debounceGetProbability = debounce(this.getProbabilityAndDrawMarkers, 1500);
  @Output() dataForProbabilityMarkers = new EventEmitter<any>();
  @Output() showDayValue = new EventEmitter<any>();

  constructor(
    private analyticsService: AnalyticsService,
    private dashboardService: DashboardService,
    private queryService: QueryService,
    private translationService: TranslationService,
    public snackBar: MatSnackBar,
    private applicationStateService: ApplicationStateService,
    private profilerService: ProfilerService,
  ) {
    super();
  }

  ngOnInit() {
    const showDayTimeSelectorSub = this.analyticsService.showDayTimeSelector
      .subscribe((show: boolean) => {
        this.show = show;
          if (this.show) {
            this.showDayValue.emit(this.show);
            this.reset();
            this.isLoading = true;
            this.getProbabilityAndDrawMarkers();
          } else {
            this.showDayValue.emit(this.show);
          }
      });
    const dashboardSub = this.dashboardService.componentsView
      .subscribe((view: string) => {
        if (view !== DashboardView.ANALYTICS) {
          this.show = false;
        }
      });
    const querySub = this.queryService.onQuerySelection
      .subscribe((query: Query) => {
        if (query && query.queryArgs) {
          this.telno = query.queryArgs.telno;
        }
      });

    this.subscriptions.push(showDayTimeSelectorSub, dashboardSub, querySub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  reset() {
    this.day = undefined;
    this.hourRange = {
      from: undefined,
      to: undefined
    };
  }

  is24HoursSelected(hourRange: number[]) {
    return hourRange[0] === 0 && hourRange[1] === 23;
  }

  async getProbabilityAndDrawMarkers() {
    if (this.applicationStateService.isProfilerTarget()) {
      const target = this.profilerService.targetData.getValue();
      this.telno = (target && target.telnos) ? target.telnos[0] : undefined;
    }
    if (!this.telno) { return; }
    const locationProbabilitySubscription = this.analyticsService.getLocationProbability(
      this.telno,
      String(this.day),
      this.hourRange.from,
      this.hourRange.to
    ).subscribe(
      (response) => {
        this.isLoading = false;
        if (response.length) {
          this.dataForProbabilityMarkers.emit(response);
        }
        locationProbabilitySubscription.unsubscribe();
      },
      (response) => {
        this.dashboardService.componentsView.next(DashboardView.LOG);
        this.analyticsService.showDayTimeSelector.next(false);
        this.showMessage(this.translationService.translate(response.error.error.message));
        locationProbabilitySubscription.unsubscribe();
      }
    );
  }

  onDayChange(day: DayOfWeek) {
    this.day = (DayOfWeek.ALL === day) ? undefined : day;
    this.isLoading = true;
    this.debounceGetProbability();
  }

  onHourChange(hourRange: number[]) {
    this.hourRange.from = undefined;
    this.hourRange.to = undefined;
    if (!this.is24HoursSelected(hourRange)) {
      const [from, to] = hourRange;
      this.hourRange.from = String(from);
      this.hourRange.to = String(to);
      if (from === to) {
        this.hourRange.to = undefined;
      }
    }
    this.isLoading = true;
    this.debounceGetProbability();
  }
}
