import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataIMExport, Profile, SearchIntelModel } from 'src/app/modules/search-intel/models/search-intel.model';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BaseService } from 'src/app/services/base.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';
import { IntelSearchArgTypes } from '../models/intel-search-arg-types.model';
import { SearchArticlesFilters } from '../models/search-articles-filters';
import { HistoryFilterType } from '../models/search-history-filters.model';
import {
  SearchArticleResult,
  SearchDarkWebResult,
  SearchFilters,
  SearchIntelResult,
  SearchLabels,
  SearchProfileResult,
  SearchResultTabs
} from './../models/search-intel.model';

@Injectable({
  providedIn: 'root'
})
export class SearchIntelService extends BaseService {
  usernameSpecialCharsRegex = new RegExp(/[\d\._]/);
  curpRegex = new RegExp(
    /^([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([a-zA-Z]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([a-zA-Z0-9]{2})$/
  );
  emailRegexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  userIdRegex = new RegExp(/^(([0-9]+)@([a-zA-Z0-9]+))$/);
  noWhitespaceRegex = new RegExp(/^\S*$/);
  telnoRegex = new RegExp(/^\+([0-9]+)$/);
  onlyDigits = new RegExp(/^[0-9]+$/);
  voterIdRegex = new RegExp(/^[A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3}$/);
  currentSearchIntel: BehaviorSubject<{ search: SearchIntelModel; noResultSearch: boolean }> = new BehaviorSubject<{
    search: SearchIntelModel;
    noResultSearch: boolean;
  }>(null);
  clearQueue: Subject<boolean> = new Subject<boolean>();
  changeHistory: Subject<boolean> = new Subject<boolean>();
  searchText = new BehaviorSubject<string>(null);
  searchBtnListner = new Subject<{
    event: PointerEvent;
    filters: SearchFilters;
    navBarIdentifier: string;
    noResultSearch?: boolean;
    skipLocate?: boolean;
  }>();
  onFiltersChange = new Subject<{
    intelSearchId: string;
    filters: { types?: string[]; values?: { [key: string]: string[] } };
  }>();
  onArticlesFiltersChange = new Subject<{
    intelSearchId: string;
    filters: {[key: string]: string[]};
  }>();
  onDarkWebArticlesFiltersChange = new Subject<{
    intelSearchId: string;
    filters: { siteName: string; total: number; selected?: boolean }[];
  }>();

  onSearchResultTabChange = new Subject<{ searchId: string; tab: SearchResultTabs }>();
  showSearchHistoryFilters = new Subject<boolean>();
  showSearchResultsFilters = new Subject<string>();
  onClearProfileFilter = new Subject<{ searchId: string; value: { key: string; value: string } }>();
  onClearAllProfilesFilter = new Subject<{ searchId: string; value: boolean }>();
  onClearAllSearchHistoryFilters = new Subject<{ value: boolean }>();
  onClearSearchHistoryFilter = new Subject<{ group: HistoryFilterType; value: string }>();
  onClearArticleFilter = new Subject<{ searchId: string; value: { group: string; value: string } }>();
  onClearDarkWebArticleFilter = new Subject<{
    searchId: string;
    value: { siteName: string; total: number; selected: boolean };
  }>();
  onClearAllArticlesFilter = new Subject<{ searchId: string; value: boolean }>();
  onClearAllDarkWebArticlesFilter = new Subject<{ searchId: string; value: boolean }>();
  openCreateTargetDialog = new Subject<void>();
  displayValuesForSearchHistoryKeyFilters: { [key: string]: string } = {
    nameWithSurname: 'name surname',
    telno: 'telno',
    nameWithMiddleAndSurname: 'name middle surname',
    name: 'full name',
    username: 'username',
    url: 'url',
    photo: 'photo',
    email: 'email',
    curp: 'CURP',
  };
  enableCovid19MX: boolean = false;

  onIntelProfileChanged: EventEmitter<Profile[]> = new EventEmitter<Profile[]>();

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private targetService: TargetService,
    private translationService: TranslationService,
    private appConfigService: AppConfigService,
    protected snackBar: MatSnackBar
  ) {
    super(router, snackBar);
    this.enableCovid19MX = this.appConfigService.getConfigVariable('enableCovid19MX');
  }

  private handleOsintError(e) {
    this.handleError(e);
    if (e.error.error && e.error.error.message) {
      this.showMessage(this.translationService.translate(`${e.error.error.message}`));
    }
  }

  getProfilesFilters(searchIds: string[]) {
    let queryArgs = new HttpParams();
    searchIds.forEach(id => {
      queryArgs = queryArgs.append('search_ids', id);
    });

    return this.httpClient.get(`${this.fastAPIurl}/intel-search/profiles-filters`, { params: queryArgs }).pipe(
      map((data: { result: { [key: string]: object[] } }) => {
        const dataToCamel = transformSnakeToCamel(data.result);
        return dataToCamel;
      })
    );
  }

  getArticleFilters(searchIds: string[]): Observable<SearchArticlesFilters> {
    let queryArgs = new HttpParams();
    searchIds.forEach(id => {
      queryArgs = queryArgs.append('search_ids', id);
    });

    return this.httpClient.get(`${this.fastAPIurl}/intel-search/articles-filters`, { params: queryArgs }).pipe(
      map((data: { result: SearchArticlesFilters }) => {
        const dataToCamel = transformSnakeToCamel(data.result);
        return dataToCamel;
      })
    );
  }

  getProfiles(queryArgs): Observable<SearchProfileResult> {
    return this.httpClient.get(`${this.fastAPIurl}/intel-search/profiles`, { params: queryArgs }).pipe(
      map((data: { result: { [key: string]: object[] } }) => {
        const dataToCamel = transformSnakeToCamel(data.result);
        return dataToCamel;
      })
    );
  }

  getArticles(queryArgs): Observable<SearchArticleResult> {
    return this.httpClient.get(`${this.fastAPIurl}/intel-search/articles`, { params: queryArgs }).pipe(
      map((data: { result: { [key: string]: object[] } }) => {
        const dataToCamel = transformSnakeToCamel(data.result);
        return dataToCamel;
      })
    );
  }

  getDarkWeb(queryArgs): Observable<SearchDarkWebResult> {
    return this.httpClient.get(`${this.fastAPIurl}/intel-search/dark-web`, { params: queryArgs }).pipe(
      map((data: { result: { [key: string]: object[] } }) => {
        const dataToCamel = transformSnakeToCamel(data.result);
        return dataToCamel;
      })
    );
  }

  getAllSearchIntelWithPagination(queryArgs): Observable<{ result: SearchIntelResult }> {
    const params = queryArgs;
    return this.httpClient.get(`${this.fastAPIurl}/intel-search`, { params: params }).pipe(
      map((data: any) => {
        const dataToCamel = transformSnakeToCamel(data);
        return dataToCamel;
      }),
      map((res: { result: SearchIntelResult }) => {
        res.result.queries.forEach((intelSearch: SearchIntelModel) => {
          intelSearch.queryArgsDisplay = [];
          intelSearch.queryArgs.forEach(queryArgs => {
            if (queryArgs && queryArgs['argValue'] && typeof queryArgs['argValue'] === 'object') {
              if (queryArgs['argType'] === 'photo') {
                intelSearch.queryArgsDisplay.push(`${queryArgs['argValue']['name']} <b>${this.translationService.translate('and')}</b> ${queryArgs['argValue']['filename']}`);
              } else {
                const values = Object.values(queryArgs['argValue']).filter(i => i);
                intelSearch.queryArgsDisplay.push(values.join(` <b>${this.translationService.translate('and')}</b> `));
              }
            } else {
              intelSearch.queryArgsDisplay.push(queryArgs.argValue);
            }
          });
        });
        return res;
      })
    );
  }

  getCompleteSearchIntel(searchId: string): Observable<SearchIntelModel> {
    return this.httpClient.get(`${this.url}/intel-search/${searchId}`).pipe(
      map((data: any) => {
        const dataToCamel = transformSnakeToCamel(data.result);
        return dataToCamel;
      })
    );
  }

  delteCompleteSearchIntel(searchId: string) {
    return this.httpClient.delete(`${this.fastAPIurl}/intel-search/${searchId}`).pipe(map((data: any) => data.result));
  }

  delteAllSearchIntel(params: HttpParams) {
    return this.httpClient
      .delete(`${this.fastAPIurl}/intel-search/all`, { params: params })
      .pipe(map((data: any) => data.result));
  }

  createSearchIntel(searchArgs, queryFilters?: SearchFilters): Observable<SearchIntelModel> {
    const bodyRequest = {
      query_args: searchArgs
    };

    if (queryFilters && Object.keys(queryFilters).length) {
      bodyRequest['query_filters'] = queryFilters;
    }

    return this.httpClient.post(`${this.fastAPIurl}/intel-search`, bodyRequest).pipe(
      catchError((e: HttpErrorResponse) => {
        this.handleOsintError(e);
        return throwError(() => e.error);
      }),
      map((data: any) => {
        const dataToCamel = transformSnakeToCamel(data.result);
        return dataToCamel;
      }),
      map((intelSearch: SearchIntelModel) => {
        intelSearch.queryArgsDisplay = [];
        intelSearch.queryArgs.forEach(queryArgs => {
          if (queryArgs && typeof queryArgs['argValue'] === 'object') {
            const values = Object.values(queryArgs['argValue']);
            intelSearch.queryArgsDisplay.push(values.join(` <b>${this.translationService.translate('and')}</b> `));
          } else {
            intelSearch.queryArgsDisplay.push(queryArgs.argValue);
          }
        });
        return intelSearch;
      })
    );
  }

  moveInstantMessagingProfiles(data: DataIMExport, targetIds: string[]): Observable<any> {
    return this.httpClient.post(`${this.url}/move-im-profiles`, { ...data, targetIds }).pipe(
      catchError((e: HttpErrorResponse) => {
        return throwError(e.error);
      })
    );
  }

  makeSearchHistoryAsFavorite(searchId: string, flag: boolean): Observable<any> {
    return this.httpClient
      .post(`${this.fastAPIurl}/intel-search/${searchId}/favorite`, { is_favorite: flag, search_id: searchId })
      .pipe(map((data: any) => data.result));
  }

  guessInputType(input: string): { [key: string]: any } {
    let possibleName = true;
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const response = {};

    if (input.startsWith('#')) {
      response[IntelSearchArgTypes.HASHTAG] = input.split(' ')[0];
      possibleName = false;
    }

    if (this.emailRegexp.test(input)) {
      response[IntelSearchArgTypes.EMAIL] = input;
      possibleName = false;
    }

    if (this.voterIdRegex.test(input)) {
      response[IntelSearchArgTypes.VOTER_ID] = input;
      possibleName = false;
    }

    if (this.isValidUrl(input.trim())) {
      response[IntelSearchArgTypes.URL] = input;
      possibleName = false;
    }

    if (this.telnoRegex.test(input) && this.targetService.getValidPhone(phoneNumberUtil, input)) {
      response[IntelSearchArgTypes.TELNO] = input;
      possibleName = false;
    }

    if (this.onlyDigits.test(input)) {
      response[IntelSearchArgTypes.USERNAME] = input;
      response[IntelSearchArgTypes.POSSIBLE_TELNO] = input;
      possibleName = false;
    }

    if (this.userIdRegex.test(input)) {
      response[IntelSearchArgTypes.USER_ID] = input;
      possibleName = false;
    }

    if (this.enableCovid19MX && this.isValidCurp(input)) {
      response[IntelSearchArgTypes.CURP] = input;
      possibleName = false;
    }

    if (possibleName) {
      if (this.noWhitespaceRegex.test(input)) {
        response[IntelSearchArgTypes.USERNAME] = input;
        if (input.substring(0, 1) === '@') {
          input = input.substring(1);
        } else if (!this.usernameSpecialCharsRegex.test(input)) {
          response[IntelSearchArgTypes.NAME] = input;
        }
      } else {
        const inputLength = input.split(' ').length;
        const stringsNotEmpty = input.split(' ').every((item) => item.length > 0);
        response[IntelSearchArgTypes.NAME] = input;

        if (inputLength === 2 && stringsNotEmpty) {
          response[IntelSearchArgTypes.NAME_WITH_SURNAME] = input;
        } else if (inputLength === 3 && stringsNotEmpty) {
          response[IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME] = input;
        } else {
          response[IntelSearchArgTypes.NAME] = input;
        }
      }
    }
    return response;
  }

  private isValidCurp(input: string): boolean {
    return this.curpRegex.test(input) ? true : false;
  }


  public buildQueryArgsByLabel(field: { value: string; label: SearchLabels }) {
    const queryArg = {
      arg_type: '',
      arg_value: String(field.value).trim()
    };
    switch (field.label) {
      case SearchLabels.PHONE:
        queryArg.arg_type = IntelSearchArgTypes.TELNO;
        break;
      case SearchLabels.EMAIL:
        queryArg.arg_type = IntelSearchArgTypes.EMAIL;
        break;
      case SearchLabels.HASHTAG:
        queryArg.arg_type = IntelSearchArgTypes.HASHTAG;
        break;
      case SearchLabels.CURP:
        queryArg.arg_type = IntelSearchArgTypes.CURP;
        break;
      case SearchLabels.NAME:
        queryArg.arg_type = IntelSearchArgTypes.NAME;
        break;
      case SearchLabels.NAME_WITH_SURNAME:
        queryArg.arg_type = IntelSearchArgTypes.NAME_WITH_SURNAME;
        break;
      case SearchLabels.NAME_WITH_MIDDLE_AND_SURNAME:
        queryArg.arg_type = IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME;
        break;
      case SearchLabels.IMAGE:
        queryArg.arg_type = IntelSearchArgTypes.PHOTO;
        break;
      case SearchLabels.URL:
        queryArg.arg_type = IntelSearchArgTypes.URL;
        break;
      case SearchLabels.USERNAME:
        queryArg.arg_type = IntelSearchArgTypes.USERNAME;
        break;
      case SearchLabels.USER_ID:
        queryArg.arg_type = IntelSearchArgTypes.USER_ID;
        break;
      case SearchLabels.VOTER_ID:
        queryArg.arg_type = IntelSearchArgTypes.VOTER_ID;
        break;
    }
    return queryArg;
  }

  public getQueryArgLabel(argType: string) {
    let label: SearchLabels;
    switch (argType) {
      case IntelSearchArgTypes.POSSIBLE_TELNO:
        label = SearchLabels.POSSIBLE_TELNO;
        break;
      case IntelSearchArgTypes.TELNO:
        label = SearchLabels.PHONE;
        break;
      case IntelSearchArgTypes.EMAIL:
        label = SearchLabels.EMAIL;
        break;
      case IntelSearchArgTypes.HASHTAG:
        label = SearchLabels.HASHTAG;
        break;
      case IntelSearchArgTypes.CURP:
        label = SearchLabels.CURP;
        break;
      case IntelSearchArgTypes.NAME:
        label = SearchLabels.NAME;
        break;
      case IntelSearchArgTypes.NAME_WITH_SURNAME:
        label = SearchLabels.NAME_WITH_SURNAME;
        break;
      case IntelSearchArgTypes.NAME_WITH_MIDDLE_AND_SURNAME:
        label = SearchLabels.NAME_WITH_MIDDLE_AND_SURNAME;
        break;
      case IntelSearchArgTypes.PHOTO:
        label = SearchLabels.IMAGE;
        break;
      case IntelSearchArgTypes.URL:
        label = SearchLabels.URL;
        break;
      case IntelSearchArgTypes.USERNAME:
        label = SearchLabels.USERNAME;
        break;
      case IntelSearchArgTypes.USER_ID:
        label = SearchLabels.USER_ID;
        break;
      case IntelSearchArgTypes.VOTER_ID:
        label = SearchLabels.VOTER_ID;
        break;
    }
    return label;
  }

  private isValidUrl(input: string): boolean {
    try {
      new URL(input);
      return true;
    } catch (e) {
      return false;
    }
  }

  translateTags(value: string, tagKeys: string[], translateFn: (value: string) => string): string {
    const values = value.split(': ');
    if (values?.length <= 1 ) return value;
    for (let [i, v] of values.entries()) {
      if (tagKeys.includes(v)) {
        values[i] = translateFn(v);
      }
    }
    return values.join(': ');
  }
}
