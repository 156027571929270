import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-target-img',
  templateUrl: './target-img.component.html',
  styleUrls: ['./target-img.component.scss']
})
export class TargetImgComponent implements OnInit, OnChanges {
  @Input() imageUrl: string;
  @Input() fullName: string;
  nameInitials: string;
  imageError: boolean;

  constructor() { }

  ngOnInit(): void {
    this.createNameInitials();
  }

  ngOnChanges(): void {
    this.createNameInitials();
  }

  public handleAvatarError(): void {
    this.imageError = true;
    const targetName = this.fullName;
    if (targetName && targetName !== '') {
      this.nameInitials = '';
      targetName.indexOf(' ') >= 0
        ? targetName.split(' ').forEach(initial => (this.nameInitials += initial.charAt(0)))
        : (this.nameInitials = targetName.charAt(0));
    }
  }

  private createNameInitials(): void {
    if (!this.imageUrl) {
      const targetName = this.fullName;
      if (targetName && targetName !== '') {
        this.nameInitials = '';
        targetName.indexOf(' ') >= 0
          ? targetName.split(' ').forEach(initial => (this.nameInitials += initial.charAt(0)))
          : (this.nameInitials = targetName.charAt(0));
      }
    }
  }
}
