export enum ClCdrTypes {
  'IncomingCall' = 'Incoming call',
  'OutgoingCall' = 'Outgoing call',
  'IncomingSMS' = 'Incoming SMS',
  'OutgoingSMS' = 'Outgoing SMS',
  'Data' = 'Data',
  'AllTypes' = 'All types'
}

export const shortCdrTypes = {
  [ClCdrTypes.IncomingCall]: 'In call',
  [ClCdrTypes.OutgoingCall]: 'Out call',
  [ClCdrTypes.IncomingSMS]: 'In SMS',
  [ClCdrTypes.OutgoingSMS]: 'Out SMS'
};

export const ClCdrTypesColors = new Map([
  [ClCdrTypes.IncomingCall, '#29704d'],
  [ClCdrTypes.OutgoingCall, '#2362cc'],
  [ClCdrTypes.IncomingSMS, '#4fc78c'],
  [ClCdrTypes.OutgoingSMS, '#4479fa'],
  [ClCdrTypes.Data, '#14a9fa']
]);

export const ClCdrTypesIconUrls = new Map([
  [ClCdrTypes.IncomingCall, 'assets/static/images/call-logs/green-pin.svg'],
  [ClCdrTypes.OutgoingCall, 'assets/static/images/call-logs/blue-pin.svg'],
  [ClCdrTypes.IncomingSMS, 'assets/static/images/call-logs/light-green-pin.svg'],
  [ClCdrTypes.OutgoingSMS, 'assets/static/images/call-logs/light-blue-pin.svg'],
  [ClCdrTypes.Data, 'assets/static/images/call-logs/lightened-blue-pin.svg'],
  ['peer' + ClCdrTypes.IncomingCall, 'assets/static/images/call-logs/green-circle.svg'],
  ['peer' + ClCdrTypes.OutgoingCall, 'assets/static/images/call-logs/blue-circle.svg'],
  ['peer' + ClCdrTypes.IncomingSMS, 'assets/static/images/call-logs/light-green-circle.svg'],
  ['peer' + ClCdrTypes.OutgoingSMS, 'assets/static/images/call-logs/light-blue-circle.svg'],
  ['peer' + ClCdrTypes.Data, 'assets/static/images/call-logs/lightened-blue-circle.svg]']
])

  ;

export enum ClCdrIconType {
  Pin,
  Circle
}

export const ClCdrTypesIconSizes = {
  [ClCdrIconType.Circle]: {
    width: 26,
    height: 26,
    type: ClCdrIconType.Circle
  },
  [ClCdrIconType.Pin]: {
    width: 23,
    height: 29,
    type: ClCdrIconType.Pin
  }
};
