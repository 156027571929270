import { ECallLogRequestPeriods } from "@trg-commons/gio-data-models-ts"
import { BillingActions } from "./billing-action.model"

export interface RequestClOptions {
  value: string,
  disabled?: boolean
}

export enum ClParameterType {
  MSISDN = 'msisdn',
  IMSI = 'imsi',
  IMEI = 'imei',
}

export const ClParameterDataTypeMapper = {
  [ClParameterType.MSISDN]: 'msisdns',
  [ClParameterType.IMSI]: 'imsis',
  [ClParameterType.IMEI]: 'imeis'
}

export const ClParameterDataTypeBackendMapper = {
  [ClParameterType.MSISDN]: 'MSISDN',
  [ClParameterType.IMSI]: 'IMSI',
  [ClParameterType.IMEI]: 'IMEI'
}

export const ClParameterDataToBillingActions = {
  [ClParameterType.MSISDN]: {
    requestActions: {
      [ECallLogRequestPeriods["3_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_MSISDN_3_MONTHS,
      [ECallLogRequestPeriods["6_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_MSISDN_6_MONTHS,
      [ECallLogRequestPeriods["12_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_MSISDN_12_MONTHS,
      [ECallLogRequestPeriods["24_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_MSISDN_24_MONTHS,
    },
    uploadAction: BillingActions.CALL_LOG_UPLOAD_MSISDN
  },
  [ClParameterType.IMSI]: {
    requestActions: {
      [ECallLogRequestPeriods["3_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMSI_3_MONTHS,
      [ECallLogRequestPeriods["6_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMSI_6_MONTHS,
      [ECallLogRequestPeriods["12_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMSI_12_MONTHS,
      [ECallLogRequestPeriods["24_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMSI_24_MONTHS,
    },
    uploadAction: BillingActions.CALL_LOG_UPLOAD_IMSI
  },
  [ClParameterType.IMEI]: {
    requestActions: {
      [ECallLogRequestPeriods["3_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMEI_3_MONTHS,
      [ECallLogRequestPeriods["6_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMEI_6_MONTHS,
      [ECallLogRequestPeriods["12_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMEI_12_MONTHS,
      [ECallLogRequestPeriods["24_MONTHS"]]: BillingActions.CALL_LOG_REQUEST_IMEI_24_MONTHS,
    },
    uploadAction: BillingActions.CALL_LOG_UPLOAD_IMEI,
  }
}

export const ClAvailablePeriodsMapper = {
  [ECallLogRequestPeriods["3_MONTHS"]]: '3',
  [ECallLogRequestPeriods["6_MONTHS"]]: '6',
  [ECallLogRequestPeriods["12_MONTHS"]]: '12',
  [ECallLogRequestPeriods["24_MONTHS"]]: '24',
}

export const ClAvailablePeriodsSelector = [
  {
    value: ECallLogRequestPeriods["3_MONTHS"],
    display: ClAvailablePeriodsMapper["3_MONTHS"]
  },
  {
    value: ECallLogRequestPeriods["6_MONTHS"],
    display: ClAvailablePeriodsMapper["6_MONTHS"]
  },
  {
    value: ECallLogRequestPeriods["12_MONTHS"],
    display: ClAvailablePeriodsMapper["12_MONTHS"]
  },
  {
    value: ECallLogRequestPeriods["24_MONTHS"],
    display: ClAvailablePeriodsMapper["24_MONTHS"]
  }
];

export interface RequestClFormValuesAndState {
  selectedParameter: { [key in ClParameterType]?: string };
  period: string,
  isFormValid: boolean,
}

export interface RequestClPayload {
  typeAndValue: { [key in ClParameterType]?: string };
  period: string;
}
