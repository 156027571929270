<div class="change-password-wrp" [class.outside-otp]="!isPartOfOtpForm">
  <form class="change-password-form assistant-font" [formGroup]="changePasswordForm">
    <div class="form-wrp">
      <p class="title">{{ 'Change your password' | translate }}</p>
      <div class="field-group" *ngIf="currentPassword === ''">
        <label>{{ 'Current password' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            formControlName="currentPassword"
            required
          />

          <mat-icon class="icon eye-icon" (click)="this.visibility = !this.visibility">
            {{ this.visibility === true ? 'visibility_off' : 'visibility' }}</mat-icon
          >
        </mat-form-field>
      </div>

      <div class="field-group">
        <label>{{ 'New password' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            name="new-password"
            formControlName="newPassword"
            autocomplete="new-password"
            required
          />

          <mat-icon class="icon eye-icon" (click)="this.visibility = !this.visibility">
            {{ this.visibility === true ? 'visibility_off' : 'visibility' }}</mat-icon
          >
        </mat-form-field>
        <span class="error" *ngIf="errorMsg"
          >{{ errorMsg | translate }}<br />
          {{ 'Please use upper case letters, lower case letters and numbers' | translate }}</span
        >
      </div>

      <div class="password-strength">
        <p class="strength flex-wrp">
          {{ 'Password strength' | translate }}:
          <mat-progress-bar mode="determinate" [value]="strengthBar"></mat-progress-bar>
        </p>
        <p class="input-desc">
          {{
            'Use at least 8 characters. Don’t use a password from another site, or something too obvious'
              | translate
          }}
        </p>
      </div>

      <div class="field-group">
        <label>{{ 'Confirm new password' | translate }}</label>
        <mat-form-field class="input-round">
          <input
            class="assistant-font"
            matInput
            [type]="visibility ? 'text' : 'password'"
            [class.disabled]="!isValid"
            formControlName="confirmPassword"
            (keyup)="(validate.match.start)"
            [readonly]="!isValid"
            required
            (keydown.enter)="onChangePassword()"
          />

          <mat-icon class="icon eye-icon" (click)="this.visibility = !this.visibility">
            {{ this.visibility === true ? 'visibility_off' : 'visibility' }}</mat-icon
          >
        </mat-form-field>
        <span class="error" *ngIf="!validate.match.start">{{
          'Please enter the same new password again' | translate
        }}</span>
      </div>
      <div class="login-btn">
        <button
          *ngIf="!isPartOfOtpForm"
          class="cp-btn-primary"
          mat-button
          [disabled]="!validate.match.exact"
          (click)="onChangePassword()"
        >
          {{ 'Change password' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
