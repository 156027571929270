import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryContext } from '@trg-commons/data-models-ts';
import {
  AdIdAnalyzeLocationDto,
  AdIdCommonIdsDto,
  AdIdLocationHistoryDto,
  AdIdProximityDto,
  AdIdTraceDto,
  DeAnonymizationDto,
  OperationRequestDto
} from '@trg-commons/gio-data-models-ts';
import { Observable } from 'rxjs/internal/Observable';
import {  map, tap } from 'rxjs/operators';
import { CallLogsApiService } from 'src/app/shared/modules/call-logs-shared/services/call-logs-api.service';
import { AdIdStateService } from './ad-id-state.service';
import { BaseProxyService } from './base-proxy-service.class';

@Injectable({
  providedIn: 'root'
})
export class AdIdService extends BaseProxyService<any> {
  resourcePath = '/ad-ids';
  constructor(private http: HttpClient, private state: AdIdStateService, private callLogsApiService: CallLogsApiService) {
    super();
  }

  createDeAnonymizationRequest(request: DeAnonymizationDto): Observable<OperationRequestDto> {
    const url = this.url + '/de-anonymization';
    return this.callLogsApiService.postWithHeaders<OperationRequestDto>(url, request)
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  createCommonIdsRequest(request: AdIdCommonIdsDto): Observable<OperationRequestDto> {
    const url = this.url + '/common-ids';
    return this.callLogsApiService.postWithHeaders<OperationRequestDto>(url, request)
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  createLocationHistoryRequest(request: AdIdLocationHistoryDto): Observable<OperationRequestDto> {
    const url = this.url + '/location-history';
    return this.callLogsApiService.postWithHeaders<OperationRequestDto>(url, request)
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  createAnalyzeLocationRequest(request: AdIdAnalyzeLocationDto): Observable<OperationRequestDto> {
    const url = this.url + '/analyze';
    return this.callLogsApiService.postWithHeaders<OperationRequestDto>(url, request)
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  createTraceRequest(request: AdIdTraceDto): Observable<OperationRequestDto> {
    const url = `${this.url}/trace`;
    return this.callLogsApiService.postWithHeaders<OperationRequestDto>(url, request)
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  createGeoFenceRequest(request: AdIdTraceDto): Observable<OperationRequestDto> {
    const url = `${this.url}/trace`;
    return this.callLogsApiService.postWithHeaders<OperationRequestDto>(url, request)
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  createProximityRequest(params: AdIdProximityDto): Observable<any> {
    const url = `${this.url}/proximity`;
    return this.callLogsApiService.postWithHeaders<OperationRequestDto>(url, params)
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  cancelTraceRequest(correlationId: string): Observable<any> {
    const url = `${this.url}/trace/${correlationId}`;
    return this.callLogsApiService.putWithHeaders<OperationRequestDto>(url, {
        streamStatus: 'end'
      })
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  getHistoricalResults(id: string): Observable<OperationRequestDto> {
    const url = `${this.url}/${id}/history`;
    return this.http
      .post<OperationRequestDto>(url, {})
      .pipe(tap(operationRequest => this.updateOperationRequest(operationRequest)));
  }

  getMatchedIfasTargets(ifas: string[]): Observable<any[]> {
    const url = `${this.baseUrl}/target?ifas=${ifas}&fields=alias,telnos`;
    return this.http.get<any[]>(url).pipe(
      map(result => {
        return result;
      })
    );
  }

  private updateOperationRequest(operationRequest: OperationRequestDto) {
    this.cancelActiveTraceRequest();
    this.state.updateOperationRequest(operationRequest);
  }

  private cancelActiveTraceRequest() {
    const activeRequest = this.state.getOperationRequest();
    if (activeRequest && activeRequest.body.context === QueryContext.Trace) {
      // console.log('cancelActiveTraceRequest');
      // this.cancelTraceRequest(activeRequest.correlationId).subscribe();
    }
  }

  public createCsvReport(correlationId) {
    const url = `${this.url}/export/${correlationId}`;

    return this.callLogsApiService.postWithHeaders(url, {});
  }

  public downloadCsvReport(correlationId) {
    const url = `${this.url}/download/${correlationId}`;

    return this.http.get(url, { responseType: 'blob' });
  }
}
