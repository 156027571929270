import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CallLogRequestPayload, CallLogRequestStatusPayload } from '../models/call-log-request-payload';
import { TargetsFilterRequestPayload } from '../models/targets-filter-request.model';
import {
  CallLogsColumnsKeys,
  CallLogsListRequestModel,
  TargetsListRequest
} from '../models/targets-list-request.model';

@Injectable({
  providedIn: 'root'
})
export class AnalysisUtilService {
  constructor(protected router: Router, protected snackBar: MatSnackBar) { }

  buildRequestPayload(
    request: TargetsFilterRequestPayload
  ) {
    const { activeSort, direction, searchText, filters, pageSize, pageNumber, columnsKeys, searchFields } = request;
    let params = this.buildRequestParams(pageSize, pageNumber);
    let requestbody: TargetsListRequest = new TargetsListRequest();

    requestbody.colsFilters.push({
      col: 'favourite',
      sortDir: -1
    });

    if (activeSort) {
      requestbody.colsFilters.push({
        col: columnsKeys[activeSort],
        sortDir: direction == 'asc' ? 1 : -1,
        search: '',
        value: ''
      });
    }

    // Adding search text to request body
    if (searchFields.length) {
      const requestBodyEntries = requestbody.colsFilters.map(i => i.col);
      searchFields.forEach(field => {
        const avaibaleFieldIndex = requestBodyEntries.indexOf(field);
        if (avaibaleFieldIndex != -1) {
          requestbody.colsFilters[avaibaleFieldIndex].search = searchText;
        } else if (searchText) {
          requestbody.colsFilters.push({
            col: columnsKeys[field],
            sortDir: direction == 'asc' ? 1 : -1,
            search: searchText,
            value: ''
          });
        }
      });
    }

    // Adding column filters to request body
    if (Object.keys(filters).length) {
      const requestBodyEntries = requestbody.colsFilters.map(i => i.col);
      const activeKeys = Object.keys(filters);
      activeKeys.forEach(key => {
        const indexOfKey = requestBodyEntries.indexOf(key);
        filters[key] =
          key === 'status'
            ? filters[key].map(i => (i === 'Active' ? JSON.parse('false') : JSON.parse('true')))
            : filters[key];
        if (indexOfKey !== -1) {
          requestbody.colsFilters[indexOfKey].value = filters[key];
        } else {
          requestbody.colsFilters.push({
            col: columnsKeys[key],
            sortDir: direction == 'asc' ? 1 : -1,
            search: searchText,
            value: filters[key]
          });
        }
      });
    }

    if (!requestbody.colsFilters.find(f => f.col === 'updated_at')) {
      requestbody.colsFilters.push({
        col: 'updated_at',
        sortDir: -1
      });
    }

    return {
      params: params,
      requestbody: requestbody
    };
  }

  buildRequestParams(pageSize: string, pageNumber: string): HttpParams {
    let params = new HttpParams().append('limit', pageSize).append('page', pageNumber);

    return params;
  }


  buildClRequestPayload(
    { createdBy, msisdns, imsis, imeis, pageNumber, pageSize, statuses, activeSort, cases, direction, searchText, targets, requestId, disableCache=false }: CallLogRequestPayload
  ) {
    let requestbody: CallLogsListRequestModel = new CallLogsListRequestModel();

    // Adding search text to request body
    if (searchText) {
      requestbody.search = searchText;
    }

    if (activeSort) {
      requestbody.sortBy = { [CallLogsColumnsKeys[activeSort]]: direction === 'asc' ? -1 : 1 };
    }

    requestbody.offset = Number(pageSize) * (Number(pageNumber) - 1);
    requestbody.limit = Number(pageSize);

    if (msisdns?.length) {
      requestbody.msisdns = msisdns;
    }

    if (imsis?.length) {
      requestbody.imsis = imsis;
    }

    if (imeis?.length) {
      requestbody.imeis = imeis;
    }

    if (createdBy?.length) {
      requestbody.createdBy = createdBy;
    }

    if (statuses?.length) {
      requestbody.statuses = statuses;
    }

    if (targets?.length) {
      requestbody.targetAlias = targets;
    }
    if (cases?.length) {
      requestbody.caseNames = cases;
    }

    if (requestId) {
      requestbody.id = requestId;
    }

    if (disableCache) {
      requestbody.disableCache = disableCache;
    }

    return requestbody;
  }

  buildPendingClRequestPayload(
    { msisdns, imsis, imeis, statuses, createdBy }: CallLogRequestStatusPayload
  ) {
    let requestbody: CallLogsListRequestModel = new CallLogsListRequestModel();

    if (msisdns?.length) {
      requestbody.msisdns = msisdns;
    }

    if (imsis?.length) {
      requestbody.imsis = imsis;
    }

    if (imeis?.length) {
      requestbody.imeis = imeis;
    }

    requestbody.createdBy = createdBy;

    if (statuses?.length) {
      requestbody.statuses = statuses;
    }

    return requestbody;

  }
}
