<div class="target-dialog">
  <p class="ubuntu-font flex-wrp" mat-dialog-title>
    {{ 'Create or add to target' | translate }}
    <mat-icon (click)="dialog.closeAll()">close</mat-icon>
  </p>

  <!-- Dialog Content -->
  <div mat-dialog-content>
    <p class="ubuntu-font target-name">
      <span class="query-label">{{ searchResultData.seedName }}</span
      ><span class="grayed-details">
        | {{ searchResultData.seedCount }} {{ 'seeds' | translate }}</span
      >
    </p>
    <div class="col-md-12 p-0">
      <mat-form-field class="example-chip-list input-box m-0">
        <!-- <mat-label>{{"Create new target or search for existing one" | translate}}</mat-label> -->
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let target of selectedTargets"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(target)"
            [class.expired]="targetCreditsChargesEnabled && target.expired"
            matTooltip="{{
              targetCreditsChargesEnabled && target.expired
                ? ('Target is expired' | translate)
                : return
            }}"
            [matTooltipDisabled]="!targetCreditsChargesEnabled && !target.expired"
          >
            {{ target.alias }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>

          <input
            class="assistant-font"
            #targetInput
            [formControl]="targetCtrl"
            placeholder="{{ 'Create new target or search for existing one' | translate }}"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        </mat-chip-list>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option
            class="target-options"
            *ngFor="let target of filteredTargets | async"
            [value]="target"
          >
            <span class="target-img"
              ><img
                [src]="viewImage(target)"
                loading="lazy"
                (error)="this.src = 'assets/static/images/no_photo.svg'"
            /></span>
            {{ target.alias }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
      <div *ngIf="targetCtrl.touched && targetCtrl.invalid" class="error-message">
        <p *ngIf="targetCtrl.errors.required && selectedTargets.length === 0">
          ** {{ 'Required' | translate }}
        </p>
        <p *ngIf="targetCtrl.errors.minlength">
          ** {{ 'Please enter 3 or more characters' | translate }}
        </p>
        <p *ngIf="targetCtrl.errors.pattern">
          ** {{ 'Special characters and trailing spaces are not allowed' | translate }}
        </p>
      </div>
    </div>
  </div>

  <!-- Dialog Actions -->
  <div mat-dialog-actions>
    <button class="cp-btn-secondary" mat-button (click)="dialog.closeAll()">
      {{ 'Back' | translate }}
    </button>

    <button
      *ngIf="!selectedTargets.length && disableCreateTarget()"
      class="cp-btn-primary"
      mat-button
      disabled
    >
      {{ 'Add to target' | translate }}
    </button>
    <ng-container *ngIf="selectedTargets.length || !disableCreateTarget()">
      <button
        *ngIf="!disableCreateTarget()"
        class="cp-btn-primary"
        mat-button
        [disabled]="disableCreateTarget()"
        (click)="createTarget()"
      >
        {{ 'Create new target' | translate }}
        <span *ngIf="isUnlimitedTheme" class="demo-sign">DEMO</span>
      </button>
      <button
        *ngIf="selectedTargets.length > 0"
        class="cp-btn-primary"
        mat-button
        (click)="addToExistingTarget()"
        [disabled]="!selectedTargets.length"
      >
        {{ 'Add to an existing target' | translate }}
        <span *ngIf="isUnlimitedTheme" class="demo-sign">DEMO</span>
        <ng-container *ngIf="targetCreditsChargesEnabled && creditsForExpired > 0">
          <span *ngIf="!isUnlimitedTheme">|</span>
          <span *ngIf="!isUnlimitedTheme" class="credits">{{ creditsForExpired }}</span>
        </ng-container>
      </button>
    </ng-container>

    <div class="col-md-12" *ngIf="targetCreditsChargesEnabled && !disableCreateTarget()">
      <p class="ubuntu-font note-txt">{{ targetCreditsMessage }}</p>
    </div>
  </div>
</div>
