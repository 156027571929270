<div
  class="profiler-map"
  [reportSectionAttribute]="'Locations' | translate"
  toImageType="png"
  [sectionIdentifier]="targetSummarySectionIdentifier.locations"
>
  <app-map
    #map
    requestedMap="vanilla-google"
    [loading]="loading"
    [zoom]="zoom"
    [mapOptions]="mapOptions"
    [points]="headMapPoints"
    [height]="250"
    [buttons]="buttons"
    [enableDrawing]="enableDrawing"
    [maximized]="maximizedMap"
    (circleDrawn)="openAreaOfInterestQueryConfirmation($event)"
    [polyLines]="polyLines"
    [polygons]="polygons"
    [center]="center"
    [heatmapEnabled]="heatmapEnabled"
    [markers]="markers$ | async"
    [circles]="circles"
    (tilesLoadedChanges)="maybeDataLoaded()"
  ></app-map>

  <div *ngIf="renderTimeline" [hidden]="!showTimeline" class="profiler-map-timeline">
    <app-timeline
      (filters)="filterTimeline($event)"
      [locations]="markerCollection.all | async"
      [showLatestCount]="initialMarkersNumber"
      (playerStateChange)="onPlayerAction($event)"
      [showPlaybackControls]="showPlaybackControls"
      [playerState]="playerState"
    >
    </app-timeline>
  </div>

  <button (click)="maximizeMap()" class="expand-btn">
    <img
      *ngIf="maximizedMap; else minimizedMap"
      [title]="'Minimize map' | translate"
      src="assets/static/images/profiler/fullscreen_disable.svg"
      width="15"
      height="15"
    />
    <ng-template #minimizedMap>
      <img
        src="assets/static/images/profiler/fullscreen_enable.svg"
        [title]="'Maximize map' | translate"
        width="15"
        height="15"
      />
    </ng-template>
  </button>

  <ng-container *ngIf="(markers$ | async).length === 0 && !headMapPoints?.length">
    <div
      class="no-locations-message"
      (click)="delegateCustomMessageAction($event)"
      *ngIf="!targetLocations.length && target?.telnos.length; else noLocations"
    >
      <div [innerHTML]="customAlert"></div>
    </div>

    <ng-template #noLocations>
      <div
        class="no-locations-message"
        *ngIf="!targetLocations.length"
        [innerHTML]="
          'No locations found for the target <br> Add seeds to retrieve more information'
            | translate
        "
      ></div>
    </ng-template>
  </ng-container>
</div>
<app-predicted-locations-form
  *ngIf="selectedSection === profilerOverviewHeaderMapOptions.PREDICTEDLOCATIONS"
  (predictedLocationsFilters)="onPredictedLocationsFiltersChanged($event)"
></app-predicted-locations-form>

<ng-template #postMarkerInfoWindow let-post="post">
  <div class="info-window">
    <app-investigation-social-post [post]="post"></app-investigation-social-post>
  </div>
</ng-template>

<ng-template #checkinMarkerInfoWindow let-target="target" let-checkin="checkin">
  <div class="info-window">
    <app-checkin-tooltip [checkin]="checkin" [target]="target"></app-checkin-tooltip>
  </div>
</ng-template>
