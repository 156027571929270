import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RecommendationsPopupComponent } from 'src/app/components/dashboard/call-log-recommendations/recommendations-popup.component';
import { TrgCommonUiModule } from 'src/app/modules/trg-common-ui/trg-common-ui.module';
import { TrgCommonModule } from 'src/app/modules/trg-common/trg-common.module';
import { CoreToastrModule } from 'src/app/shared/modules/core-toastr/core-toastr.module';
import { RecommendationDatepickerComponent } from 'src/app/shared/modules/recommendations/recommendation-datepicker/recommendation-datepicker.component';
import { RecommendationImportToastrComponent } from 'src/app/shared/modules/recommendations/recommendation-import-toastr/recommendation-import-toastr.component';
import { RequestCallLogModalComponent } from 'src/app/shared/modules/recommendations/request-call-log-modal/request-call-log-modal.component';

@NgModule({
  declarations: [
    RecommendationsPopupComponent,
    RequestCallLogModalComponent,
    RecommendationImportToastrComponent,
    RecommendationDatepickerComponent
  ],
  exports: [RecommendationsPopupComponent, RecommendationDatepickerComponent],
  imports: [CommonModule, MatCheckboxModule, TrgCommonModule, TrgCommonUiModule, ReactiveFormsModule, CoreToastrModule]
})
export class RecommendationsModule { }
