import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectSortItem } from 'src/app/shared/components/select-sorter/select-sort-item.class';

@Component({
  selector: 'app-select-sorter',
  templateUrl: './select-sorter.component.html',
  styleUrls: ['./select-sorter.component.scss']
})
export class SelectSorterComponent implements OnInit {
  constructor() { }

  @Input() defaultSortIndex;
  @Input() items: SelectSortItem[] = [];
  @Output() change = new EventEmitter<SelectSortItem>();

  public activeSort: SelectSortItem | undefined;

  ngOnInit(): void {
    if (typeof this.defaultSortIndex === 'number') {
      this.activeSort = this.items[this.defaultSortIndex];
    }
  }

  changeSort(sortItem: SelectSortItem): void {
    if (this.activeSort === sortItem) {
      const sort = sortItem.switchSortOrder();
      this.change.emit(this.activeSort);

      if (sort === '') {
        this.activeSort = undefined;
      }
      return;
    }

    // reset sort order for the newly picked items;
    sortItem.switchSortOrder('asc');
    this.activeSort = sortItem;
    this.change.emit(this.activeSort);
  }
}
