<div class="law-enforcement-item"
  [class.selected-tactical-item]="selectedLawEnforcement && lawEnforcement.id === selectedLawEnforcement.id"
  (click)="selectLawEnforcement(lawEnforcement)" [class.law-enforcement-item-even]="even" [class.law-enforcement-item-odd]="!even">
  <div class="column checkbox-column">
    <mat-checkbox [checked]="checked" class="checkbox-tactical" (change)="multiselect($event, lawEnforcement)" (click)="$event.stopPropagation()">
    </mat-checkbox>
  </div>
  <div class="column">
    {{ lawEnforcement.msisdn || '' }}
  </div>
  <div class="column">
    {{ lawEnforcement.imsi || '' }}
  </div>
  <div class="column">
    {{ lawEnforcement.imei || '' }}
  </div>
  <div class="column coords-column">
    {{ lawEnforcement.location ? lawEnforcement.location.coordinates[1].toFixed(4) : '' }} /
    {{ lawEnforcement.location ? lawEnforcement.location.coordinates[0].toFixed(4) : '' }}
  </div>
  <div class="column">
    {{ lawEnforcement.isIncoming ? 'Incoming' : 'Outgoing' }}
  </div>
  <div class="column">
    {{ lawEnforcement.durationSeconds || '' }}
  </div>
  <div class="column">
    {{ lawEnforcement.date ? (lawEnforcement.date | date: 'dd.MM.yyyy HH:mm') : '' }}
  </div>
  <div class="column">
    <span *ngIf="lawEnforcement.leType === 'voice'">
      <span [class.clickable]="lawEnforcement.file" (click)="lawEnforcement.file ? audioPlayer(lawEnforcement) : ''">
        {{ lawEnforcement.leType }}
        <img class="type-icon" *ngIf="lawEnforcement.file"
          [src]="selectedLawEnforcement && lawEnforcement.id === selectedLawEnforcement.id ? 'assets/static/images/speaker_inverted.svg' : 'assets/static/images/speaker.svg'"
          height="15" width="15">
      </span>
    </span>
    <span *ngIf="lawEnforcement.leType === 'sms'">
      <span [class.clickable]="lawEnforcement.smsText"
        (click)="lawEnforcement.smsText ? fileReader(lawEnforcement.smsText) : ''">
        {{ lawEnforcement.leType }}
        <img class="type-icon" *ngIf="lawEnforcement.smsText"
          [src]="selectedLawEnforcement && lawEnforcement.id === selectedLawEnforcement.id ? 'assets/static/images/contact_inverted.svg' : 'assets/static/images/contact.svg'"
          height="15" width="15">
      </span>
    </span>
  </div>
  <div class="column">
    {{ lawEnforcement.target || '' }}
    <img
      [src]="selectedLawEnforcement && lawEnforcement.id === selectedLawEnforcement.id ? 'assets/static/images/add_target_inverted.svg' : 'assets/static/images/add_target.svg'"
      height="20"
      *ngIf="!lawEnforcement.target"
      class="clickable"
      matTooltip="{{'Add new Target' | translate}}"
      (click)="addTargetDialog(lawEnforcement)">
  </div>
</div>
