<app-header-content-footer-dialog
  title="{{ 'Results for' | translate }} {{ context.email }}"
  [showClose]="true"
  [showFooter]="true"
>
  <ng-container ngProjectAs="detailsContent">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{ 'Type' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="{{element.iconClass}}"> warning </mat-icon>
          {{ element.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>{{ 'Value' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.value }}
          <mat-icon
            class="copy-icon"
            (click)="copyNotify()"
            [cdkCopyToClipboard]="element.value"
            matTooltip="{{ 'Copy value' | translate }}"
            >content_copy</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef>{{ 'Origin' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.origin || 'N/A' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </ng-container>
  <ng-container ngProjectAs="footerContent">
    <mat-paginator [pageSize]="10"></mat-paginator>
  </ng-container>
</app-header-content-footer-dialog>
