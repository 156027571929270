import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { EmailService } from 'src/app/services/email/email.service';
import { LedgerService } from 'src/app/services/ledger/ledger.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { User } from 'src/app/services/user/user.model';
import { UserBillingService } from 'src/app/services/billing/user-billing.service';

@Component({
  selector: 'app-advanced-geolocation-query-confirmation-dialog',
  templateUrl: './advanced-geolocation-query-confirmation-dialog.component.html',
  styleUrls: ['./advanced-geolocation-query-confirmation-dialog.component.scss'],
})
export class AdvancedGeolocationQueryConfirmationDialogComponent extends BaseComponent implements OnInit {
  currentUser;
  currentCredits: number;

  constructor(
    public dialogRef: MatDialogRef<AdvancedGeolocationQueryConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData,
    private translationService: TranslationService,
    private localStorageService: LocalStorageService,
    private emailService: EmailService,
    private ledgerService: LedgerService,
    private userBillingService: UserBillingService
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.ledgerService
        .getCurrentUserLedgerItem()
        .subscribe((user: User) => (this.currentCredits = this.userBillingService.getUserCurrentBalance(user)))
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  requestAdvancedGeolocationQuery() {
    if (this.currentCredits > 19) {
      this.currentUser = this.localStorageService.getCurrentUser();
      const emailData = new FormData();
      emailData.append('title', this.translationService.translate('Advanced Geolocation Request'));
      emailData.append('content',
        `Query-Id: ${this.requestData.id}<br>
        MSISDN: ${this.requestData.queryArgs.telno}<br>
        Username: ${this.currentUser.identity}<br>
        Tenant ID: {{tenantid}}<br>
        ${this.requestData.queryArgs.telno.replace('+', '')}:{{tenantid}}:${this.requestData.id}<br>`);
      emailData.append('username', `${this.currentUser.identity}<br>`);
      emailData.append('message', 'sent an advanced geolocation request');
      this.emailService.supportRequest(emailData).subscribe(
        () => {
          this.showMessage(this.translationService.translate('Request sent successfully!'));
          this.onClose();
        },
        () => {
          this.showMessage(this.translationService.translate('Request has not been sent'));
        }
      );
    } else {
      this.showMessage(this.translationService.translate('No credits'));
    }
  }
}
