import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { OsintService } from 'datalayer/services/osint/osint.service';
import * as KeyLines from '@trg-ui/link-analysis';
import { Link, Node } from '@trg-ui/link-analysis';
import { flatten } from 'lodash-es';
import { EMPTY, merge, Observable } from 'rxjs';
import { catchError, finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { LinkAnalysisDataService } from 'src/app/modules/link-analysis/services/link-analysis-data.service';
import { LinkAnalysisService } from 'src/app/modules/link-analysis/services/link-analysis.service';
import { DBGraph } from 'src/app/modules/link-analysis/shared/custom-entities.model';
import {
  contextMenuActions,
  entities,
  entityPanelTemplate,
  GlyphSize,
  instantMessagingPlatformsToFetch,
  LabelSize,
  LIGHTER_LINK_COLOR,
  linkTypes,
  linkTypeToText,
  nodeSize,
  nodeTypes,
  nodeTypesColors,
  relationTypes
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
import { ImageService } from 'src/app/services/image/image.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-la-custom-entities-panel',
  templateUrl: './la-custom-entities-panel.component.html',
  styleUrls: ['./la-custom-entities-panel.component.scss']
})
export class LaCustomEntitiesPanelComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() graphData: (Node | Link)[];
  @Input() showEntitiesPanel: boolean;
  @Input() node: Node;
  @Input() contextMenuAction: contextMenuActions;
  @Input() caseTargets: TargetItem[];
  @Input() removedItem: Node | Link;
  @Input() linkNodesData: {fromNode: Node, toNode: Node, position: KeyLines.LabelPosition};

  @Output() hideEntitiesPanel = new EventEmitter<boolean>();
  @Output() toggleLoader = new EventEmitter<boolean>();
  @Output() animateNode = new EventEmitter<string>();
  @Output() removeNodeAnimation = new EventEmitter<string>();
  @Output() removeItem = new EventEmitter<string>();
  @Output() setItem = new EventEmitter<Node | Link>();
  @Output() onCreateCustomEntity = new EventEmitter<Node>();
  @Output() onNewEntities = new EventEmitter<(Node | Link)[]>();
  @Output() setProperties = new EventEmitter<KeyLines.NodeProperties>();
  @Output() setTimebarData = new EventEmitter<any>();
  @Output() addToGraphData = new EventEmitter<Node | Link>();

  entities: entityPanelTemplate[] = entities;
  heighestLinkScore = 0;
  timebarData: any = [];
  newLinkTypeOptions: linkTypes[] = [];
  newLinkTypeProperties = { fromNode: null, toNode: null, position: { left: null, top: null } };
  linkTypeToText = linkTypeToText;

  constructor(
    private linkAnalysisService: LinkAnalysisService,
    private linkAnalysisDataService: LinkAnalysisDataService,
    private translationService: TranslationService,
    private targetService: TargetService,
    private osintService: OsintService,
    private imageService: ImageService
  ) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contextMenuAction?.currentValue) {
      this.onContextMenuAction(changes.contextMenuAction.currentValue);
    }
    if (changes.node?.currentValue?.id === 'temporaryId' && changes.node?.currentValue?.d.customEntity) {
      this.validateNewEntity(changes.node.currentValue.d.newId);
    }
    if (changes.removedItem?.currentValue) {
      this.onDelete(changes.removedItem.currentValue);
    }
    if (changes.linkNodesData?.currentValue) {
      this.linkNodes(changes.linkNodesData.currentValue.fromNode,changes.linkNodesData.currentValue.toNode, changes.linkNodesData.currentValue.position)
    }
  }

  public toggleEntitiesPanel() {
    this.hideEntitiesPanel.emit(true);
  }

  private validateNewEntity(input: string) {
    this.subscriptions.push(
      this.linkAnalysisService.isValidEntityId(input, this.node, this.graphData).subscribe(result => {
        if (result) {
          this.formatNewEntityDataBasedOnType(input, this.node, result as string);
        } else {
          this.toggleLoader.emit(false)
        }
      })
    );
  }

  private onContextMenuAction(action: contextMenuActions) {
    switch (action) {
      case contextMenuActions.INSTANT_MESSAGING:
        this.fetchImProfiles(this.node.d.label, this.node.id);
        break;
      case contextMenuActions.CALL_LOGS:
        this.getCallLogsFromSim(this.node.id, this.node.d.key);
        this.getLocationFromSim(this.node.id, this.node.d.key);
        break;
      case contextMenuActions.SOCIAL_PROFILES:
        this.getProfileFromSim(this.node.id, this.node.d.key);
        break;
      case contextMenuActions.SOCIAL_CONNECTIONS:
        this.getConnectionsFromProfile(this.node.id, this.node.d.key);
        break;
      case contextMenuActions.MSISDN:
        if (this.node.d.type === nodeTypes.SOCIAL_PROFILE) {
          this.getSimFromProfile(this.node.id, this.node.d.key);
        } else {
          this.getSimsFromDevice(this.node.id, this.node.d.key);
        }
        break;
      case contextMenuActions.DEVICE:
        this.getDevicesFromSim(this.node.id, this.node.d.key);
        break;
      default:
        break;
    }
  }

  private formatNewEntityDataBasedOnType(id: string, newItem: Node, profileId: string) {
    if (!newItem) {
      return;
    }
    newItem.id = id;
    newItem.t = id;
    newItem.d['label'] = id;
    if (newItem.d.type === nodeTypes.SOCIAL_PROFILE) {
      newItem.id = profileId;
      newItem.d['username'] = profileId;
      newItem.d['url'] = id;
      newItem.d['relation'] = this.linkAnalysisService.getRelationTypeFromUrl(id);
      return;
    }
    if (newItem.d.type === nodeTypes.PERSON) {
      newItem.d['names'] = id;
    }
    if (newItem.d.type === nodeTypes.LOCATION) {
      const coords = id.split(',');
      newItem.t = `${coords[0]}, ${coords[1]}`;
      newItem.d.label = `${coords[0]}, ${coords[1]}`;
      newItem.pos = {
        lat: +coords[0],
        lng: +coords[1]
      };
    }
    this.onCreateCustomEntity.emit(newItem);
  }

  private getCallLogsFromSim(nodeId: string, key: string) {
    this.animateNode.emit(nodeId);
    const itemsToMerge: (Node | Link)[] = [];
    this.subscriptions.push(
      this.linkAnalysisDataService.getSimsFromSim(key).subscribe(
        data => {
          data.nodes.forEach(entity => {
            if (entity.msisdn.includes('E')) {
              return;
            }
            const label = `+${entity.msisdn}`;
            // TODO: remove this when we change the format of incoming data
            // this.dBSimIdToMsisdnDict[entity.id] = label;
            const nodeData = {
              type: nodeTypes.MSISDN,
              label,
              key: entity.key,
              entityType: entity.entityType,
              source: entity.source
            };
            const node = this.linkAnalysisService.createNewNode(
              entity.id,
              nodeTypesColors.MSISDN,
              label,
              nodeData,
              nodeSize.SMALL,
              [],
              LabelSize.MEDIUM
            );
            itemsToMerge.push(node);
            this.getLocationFromSim(entity.id, node.d.key);
          });
          data.edges.forEach(edge => {
            const link: Link = this.linkAnalysisService.getLink(
              edge.fromEntity,
              edge.toEntity,
              edge.relationType,
              false,
              LIGHTER_LINK_COLOR
            );
            link.d['count'] = edge.metadata?.length;
            this.heighestLinkScore =
              this.heighestLinkScore > edge.metadata?.length ? this.heighestLinkScore : edge.metadata?.length;
            const dt = [];
            edge.metadata?.forEach(entry => {
              if (!entry?.datetime) {
                return;
              }
              dt.push(new Date(entry.datetime));
            });
            this.timebarData.push({ id: link.id, dt });
            this.setTimebarData.emit(this.timebarData);
            if (!this.linkExists(link, this.graphData)) {
              itemsToMerge.push(link);
            }
          });
          itemsToMerge.forEach(item => {
            if (item.type === 'link' && item.d.count) {
              item.w = this.linkAnalysisService.transformLinkWeight(item.d.count, this.heighestLinkScore, 0.5, 10, 1);
            }
          });
          this.onNewEntities.emit(itemsToMerge);
          this.removeNodeAnimation.emit(nodeId);
        },
        () => {
          this.removeNodeAnimation.emit(nodeId);
        }
      )
    );
  }

  private getLocationFromSim(nodeId: string, key: string) {
    const itemsToMerge: (Node | Link)[] = [];
    this.subscriptions.push(
      this.linkAnalysisDataService.getLocationFromSim(key).subscribe(data => {
        if (data.nodes.length) {
          this.setProperties.emit({
            id: nodeId,
            g: [this.linkAnalysisService.getGlyph(`link-analysis/location.svg`, 'e', false, GlyphSize.LARGE)]
          });
        }
        data.nodes.forEach(entity => {
          const label = `Lat: ${entity.lat} / Lng: ${entity.lon}`;
          const nodeData = {
            type: nodeTypes.LOCATION,
            label,
            source: entity.source
          };
          const node = this.linkAnalysisService.createNewNode(
            entity.id,
            nodeTypesColors.MSISDN,
            '',
            nodeData,
            nodeSize.SMALL,
            [],
            LabelSize.MEDIUM
          );
          node.pos = {
            lat: entity.lat,
            lng: entity.lon
          };
          node.hi = true;
          itemsToMerge.push(node);
        });
        data.edges.forEach(edge => {
          const index = itemsToMerge.findIndex(item => item.id === edge.toEntity);
          if (index > -1) {
            const timestamps = edge.metadata?.map(entry => entry?.datetime);
            itemsToMerge[index].d.timestamps = timestamps;
            // itemsToMerge[index].d.nodeLocationId = this.dBSimIdToMsisdnDict[edge.fromEntity];
          }
        });
        this.onNewEntities.emit(itemsToMerge);
      })
    );
  }

  private getProfileFromSim(id: string, key: string) {
    this.animateNode.emit(id);
    const itemsToMerge: (Node | Link)[] = [];
    this.subscriptions.push(
      this.linkAnalysisDataService.getProfileFromSim(key).subscribe(
        data => {
          data.nodes.forEach(profile => {
            const relation = profile.source.toLowerCase() as relationTypes;
            const label = profile.name;
            const nodeData = {
              type: nodeTypes.SOCIAL_PROFILE,
              relation,
              username: profile.username || `${profile.sourceId}@${relation}`,
              label,
              url: profile.url || `https://${relation}.com/${profile.sourceId}`,
              key: profile.key,
              entityType: profile.entityType,
              source: profile.source
            };
            const node = this.linkAnalysisService.createNewNode(
              `${profile.sourceId}@${relation}`,
              nodeTypesColors.SOCIAL_PROFILE,
              label,
              nodeData,
              nodeSize.SMALL,
              [this.linkAnalysisService.getGlyph(`link-analysis/${relation}.svg`, 'e')],
              LabelSize.MEDIUM
            );
            const link = this.linkAnalysisService.getLink(id, node.id, linkTypes.SOCIAL_PROFILE, false, LIGHTER_LINK_COLOR);
            if (this.linkExists(link, this.graphData)) {
              itemsToMerge.push(node);
            } else {
              itemsToMerge.push(node, link);
            }
          });
          this.onNewEntities.emit(itemsToMerge);
          this.removeNodeAnimation.emit(id);
        },
        () => {
          this.removeNodeAnimation.emit(id);
        }
      )
    );
  }

  private getConnectionsFromProfile(id: string, key: string) {
    this.animateNode.emit(id);
    const itemsToMerge: (Node | Link)[] = [];
    this.subscriptions.push(
      this.linkAnalysisDataService.getSocialConnectionsFromProfile(key).subscribe(
        data => {
          data.nodes.forEach(profile => {
            const node = this.linkAnalysisService.getProfileNode(profile.payload, profile.profileImageFileId);
            node.d['key'] = profile.key;
            node.d['entityType'] = profile.entityType;
            node.d['source'] = profile.source;
            const link = this.linkAnalysisService.getLink(id, node.id, linkTypes.FACEBOOK_FRIEND, true, LIGHTER_LINK_COLOR);
            if (this.linkExists(link, this.graphData)) {
              itemsToMerge.push(node);
            } else {
              itemsToMerge.push(node, link);
            }
            this.getSimFromProfile(node.id, node.d.key);
          });
          this.onNewEntities.emit(itemsToMerge);
          this.removeNodeAnimation.emit(id);
        },
        () => {
          this.removeNodeAnimation.emit(id);
        }
      )
    );
  }

  private getSimFromProfile(profileId: string, key: string) {
    this.animateNode.emit(profileId);
    this.subscriptions.push(
      this.linkAnalysisDataService.getSimFromProfile(key).subscribe(
        data => {
          this.createSimNodeFromDBentity(profileId, data);
        },
        () => {
          this.removeNodeAnimation.emit(profileId);
        }
      )
    );
  }

  private createSimNodeFromDBentity(profileId: string, entities: DBGraph) {
    const itemsToMerge: (Node | Link)[] = [];
    entities.nodes.forEach(entity => {
      if (entity.msisdn.includes('E')) {
        return;
      }
      const label = `+${entity.msisdn}`;
      const nodeData = {
        type: nodeTypes.MSISDN,
        label,
        key: entity.key,
        entityType: entity.entityType,
        source: entity.source
      };
      const node = this.linkAnalysisService.createNewNode(
        entity.id,
        nodeTypesColors.MSISDN,
        label,
        nodeData,
        nodeSize.SMALL,
        [],
        LabelSize.MEDIUM
      );
      const link: Link = this.linkAnalysisService.getLink(node.id, profileId, linkTypes.SOCIAL_PROFILE, false, LIGHTER_LINK_COLOR);
      if (this.linkExists(link, this.graphData)) {
        itemsToMerge.push(node);
      } else {
        itemsToMerge.push(node, link);
      }
    });
    this.onNewEntities.emit(itemsToMerge);
    this.removeNodeAnimation.emit(profileId);
  }

  private getSimsFromDevice(profileId: string, key: string) {
    this.animateNode.emit(profileId);
    this.subscriptions.push(
      this.linkAnalysisDataService.getSimsFromDevice(key).subscribe(
        data => {
          this.createSimNodeFromDBentity(profileId, data);
          this.removeNodeAnimation.emit(profileId);
        },
        () => {
          this.removeNodeAnimation.emit(profileId);
        }
      )
    );
  }

  private getDevicesFromSim(id: string, key: string) {
    this.animateNode.emit(id);
    const itemsToMerge: (Node | Link)[] = [];
    this.subscriptions.push(
      this.linkAnalysisDataService.getDevicesFromSim(key).subscribe(
        data => {
          data.nodes.forEach(device => {
            const nodeData = {
              type: nodeTypes.PHONE,
              label: device.imei,
              key: device.key,
              entityType: device.entityType,
              source: device.source
            };
            const node = this.linkAnalysisService.createNewNode(
              device.id,
              nodeTypesColors.PHONE,
              device.imei,
              nodeData,
              nodeSize.SMALL,
              [],
              LabelSize.MEDIUM
            );
            const link = this.linkAnalysisService.getLink(id, node.id, linkTypes.USED_BY, true, LIGHTER_LINK_COLOR);
            if (this.linkExists(link, this.graphData)) {
              itemsToMerge.push(node);
            } else {
              itemsToMerge.push(node, link);
            }
          });
          this.onNewEntities.emit(itemsToMerge);
          this.removeNodeAnimation.emit(id);
        },
        () => {
          this.removeNodeAnimation.emit(id);
        }
      )
    );
  }

  public linkNodes(fromNode: Node, toNode: Node, position: KeyLines.LabelPosition) {
    const linkTypes = this.linkAnalysisService.getLinkTypesFromNodes(fromNode?.d.type, toNode?.d.type);
    if (linkTypes.length > 1) {
      this.newLinkTypeProperties = {
        fromNode,
        toNode,
        position: { top: `${position.y1 - 50}px`, left: `${position.x1}px` }
      };
      this.newLinkTypeOptions = linkTypes;
    } else {
      const link: Link = this.linkAnalysisService.getLink(fromNode.id, toNode.id, linkTypes[0], false, LIGHTER_LINK_COLOR);
      this.setItem.emit(link);
      if (toNode?.d.isTargetNode) {
        if (toNode?.d.isTargetNode && fromNode?.d.type === nodeTypes.MSISDN) {
          this.associateTelnoWithExistingTarget(fromNode.id, toNode.id, true);
        }
      } else {
        this.subscriptions.push(
          this.linkAnalysisDataService.createEdge(fromNode as Node, toNode as Node, link).subscribe(
            data => {
              link.d['key'] = data.key;
              link.d['relationType'] = data.relationType;
              this.setItem.emit(link);
              this.addToGraphData.emit(link);
            },
            () => {
              this.removeItem.emit(link.id);
              this.showMessage(this.translationService.translate('Invalid relationship type'));
            }
          )
        );
      }
    }
  }

  public onNewLinkTypeChange(event: MatSelectChange) {
    const linkType = event.value as linkTypes;
    this.newLinkTypeOptions = [];
    const link: Link = this.linkAnalysisService.getLink(
      this.newLinkTypeProperties.fromNode.id,
      this.newLinkTypeProperties.toNode.id,
      linkType,
      false,
      LIGHTER_LINK_COLOR
    );
    this.subscriptions.push(
      this.linkAnalysisDataService
        .createEdge(this.newLinkTypeProperties.fromNode as Node, this.newLinkTypeProperties.toNode as Node, link)
        .subscribe(
          data => {
            link.d['key'] = data.key;
            link.d['relationType'] = data.relationType;
            this.setItem.emit(link);
            this.addToGraphData.emit(link);
          },
          () => {
            this.removeItem.emit(link.id);
            this.showMessage(this.translationService.translate('Invalid relationship type'));
          }
        )
    );
  }

  private associateTelnoWithExistingTarget(telno: string, targetId: string, add: boolean) {
    const text = add
      ? 'Would you like to add this MSISDN to the target?'
      : 'Would you like to remove this MSISDN from the target?';
    return Swal.fire({
      title: this.translationService.translate('Edit to target'),
      text: this.translationService.translate(text),
      showCancelButton: true,
      confirmButtonText: this.translationService.translate('Confirm'),
      cancelButtonText: this.translationService.translate('No')
    }).then(result => {
      if (result.value) {
        const currentTarget = this.caseTargets.find(target => target.id === targetId);
        const updatedTarget = { ...currentTarget };
        if (add) {
          updatedTarget.telnos.push(telno);
        } else {
          updatedTarget.telnos.splice(updatedTarget.telnos.indexOf(telno), 1);
        }
        this.targetService.editTargetProfiler(currentTarget, updatedTarget).subscribe(
          () => {
            const itemIndex = this.graphData.findIndex(chartItem => chartItem.id === telno);
            if (itemIndex > -1) {
              const data = this.graphData[itemIndex].d;
              data['isTargetNode'] = true;
              data['targetId'] = targetId;
              this.setProperties.emit({ id: telno, d: data });
            }
            this.showMessage(this.translationService.translate('Target edited successfully!'));
          },
          () => {
            this.showMessage(this.translationService.translate('Target has not been edited'));
          }
        );
      }
    });
  }

  private onDelete(removedItem: Node | Link) {
    this.checkIfDeletedItemIsPartOfTargetItems(removedItem);
    if (removedItem.type === 'node' && removedItem.d.key) {
      this.subscriptions.push(this.linkAnalysisDataService.deleteNode(removedItem).subscribe());
    } else if (removedItem.type === 'link' && removedItem.d.key) {
      const fromNodeIndex = this.graphData.findIndex(item => item.id === removedItem.id1);
      const toNodeIndex = this.graphData.findIndex(item => item.id === removedItem.id2);
      this.subscriptions.push(
        this.linkAnalysisDataService.deleteEdge(this.graphData[fromNodeIndex].d.entityType, this.graphData[toNodeIndex].d.entityType, removedItem.d.key).subscribe()
      );
    }
  }

  public fetchImProfiles(telno: string, connectToNodeId: string) {
    this.animateNode.emit(telno);
    const dataForReqs: Array<{ telno; platform }> = flatten(
      instantMessagingPlatformsToFetch.map(platform => {
        return { telno, platform };
      })
    );

    merge(dataForReqs)
      .pipe(
        mergeMap(req => {
          const obs = this.osintService.fetchInstantMessagesProfile([
            {
              arg_type: 'telno',
              arg_value: req.telno,
              platform: req.platform
            }
          ]) as Observable<ImProfile[]>;

          return obs.pipe(
            takeUntil(this.destroyed$),
            catchError(e => {
              return EMPTY;
            })
          );
        }),
        finalize(() => {
          this.removeNodeAnimation.emit(telno);
        })
      )
      .subscribe(profiles => {
        profiles.forEach(profile => {
          const image = profile.thumbnail ? (this.imageService.getPhotoUrl(profile.thumbnail, true) as string) : null;
          const nodeData = {
            type: nodeTypes.SOCIAL_PROFILE,
            platform: profile.platform,
            image,
            label: profile.names[0] || profile.telno,
            status: profile.status
          };
          const node = this.linkAnalysisService.createNewNode(
            `${profile.telno}_${profile.platform}`,
            nodeTypesColors.SOCIAL_PROFILE,
            profile.names[0] || profile.telno,
            nodeData,
            nodeSize.SMALL,
            [this.linkAnalysisService.getGlyph(`search-intel/${profile.platform}-colour.svg`, 'e')],
            LabelSize.MEDIUM,
            image
          );
          const link = this.linkAnalysisService.getLink(connectToNodeId, node.id, linkTypes.INSTANT_MESSAGING_PROFILE, true, LIGHTER_LINK_COLOR);
          if (this.linkExists(link, this.graphData)) {
            this.onNewEntities.emit([node]);
            this.graphData.push(node);
          } else {
            this.graphData.push(node, link);
            this.onNewEntities.emit([node, link]);
          }
        });
      });
  }

  private linkExists(link: Link, dataToSearchIn: (Node | Link)[]): boolean {
    return dataToSearchIn.some(
      item =>
        item.type === 'link' &&
        ((link.id1 === item.id1 && link.id2 === item.id2) || (link.id1 === item.id2 && link.id2 === item.id1)) &&
        item.d.type === link.d.type
    );
  }

  private checkIfDeletedItemIsPartOfTargetItems(item: Node | Link) {
    const caseTargetIds = this.caseTargets.map(target => target.id);
    if (item.type === 'link' && (caseTargetIds.includes(item.id1) || caseTargetIds.includes(item.id2))) {
      this.associateTelnoWithExistingTarget(
        item.id1.includes('+') ? item.id1 : item.id2,
        item.id1.includes('+') ? item.id2 : item.id1,
        false
      );
    } else if (item.type === 'node' && item.d.type === nodeTypes.MSISDN && item.d.isTargetNode && item.d.targetId) {
      this.associateTelnoWithExistingTarget(item.id, item.d.targetId, false);
    }
  }
}
