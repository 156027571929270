<div id="enhanceProfilePopup">
  <div class="ubuntu-font flex-wrp title" mat-dialog-title>
    <div>
      <p *ngIf="data.fromTarget">
        {{ 'Enhance profile' | translate }}
      </p>
      <p *ngIf="!data.fromTarget">
        {{ 'Create a new target and Enhance profile' | translate }}
      </p>
      <span *ngIf="!data.fromTarget" class="assistant-font">{{
        'Enhance profile in order to automatically create a target, discover more intelligence information and retrieve important insights'
          | translate
      }}</span>

      <span *ngIf="data.fromTarget" class="assistant-font">
        {{
          'Enhance profile in order to discover more intelligence information and retrieve important insights'
            | translate
        }}
      </span>
    </div>
    <mat-icon [mat-dialog-close]="false">close</mat-icon>
  </div>

  <mat-dialog-content>
    <div *ngIf="billingPlan$ | async as billingPlan" class="content">
      <div
        [class.no-action]="!data?.enableAdvancedOsint || !billingPlan?.advancedOsint?.cost"
        class="row-flex"
        (click)="onClickEnhance(enhanceProfileAction.ADVANCED, data?.enableAdvancedOsint)"
      >
        <div class="osint-icon">
          <img src="/assets/static/images/search-intel/enhance-advanced-osint.svg" />
        </div>
        <div class="osint-txt">
          <span class="ubuntu-font main-txt"> {{ 'Advanced Webint' | translate }}</span>
          <span
            [class.disabled-txt]="!data?.enableAdvancedOsint || !billingPlan?.advancedOsint?.cost"
            class="assistant-font sub-txt"
          >
            {{
              'Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.'
                | translate
            }}
          </span>
        </div>
        <div class="ubuntu-font credits-section">
          <span
            *ngIf="billingPlan?.advancedOsint?.cost"
            [class.disabled-txt]="!data?.enableAdvancedOsint"
            >{{ data.credits || billingPlan?.advancedOsint?.cost }}</span
          >
        </div>
      </div>
      <div
        *ngIf="deepOsintEnabled"
        [class.no-action]="!data?.enableDeepOsint || !billingPlan?.deepOsint?.cost"
        class="row-flex"
        (click)="onClickEnhance(enhanceProfileAction.DEEP, data?.enableDeepOsint)"
      >
        <div class="osint-icon">
          <img src="/assets/static/images/search-intel/enhance-deep-osint.svg" />
        </div>
        <div class="osint-txt">
          <span class="ubuntu-font main-txt"> {{ 'Deep Webint' | translate }}</span>
          <span
            [class.disabled-txt]="!data?.enableDeepOsint || !billingPlan?.deepOsint?.cost"
            class="assistant-font sub-txt"
            >{{
              'Deep search will add second degree relations, likers, commenters, reactors and more'
                | translate
            }}</span
          >
        </div>
        <div class="ubuntu-font credits-section">
          <span
            *ngIf="billingPlan?.deepOsint?.cost"
            [class.disabled-txt]="!data?.enableDeepOsint"
            >{{ billingPlan.deepOsint.cost }}</span
          >
        </div>
      </div>
      <div>
        <p class="ubuntu-font note-txt" *ngIf="targetCreditsChargesEnabled && !data.fromTarget">
          {{ targetCreditsMessage }}
        </p>
      </div>
    </div>
  </mat-dialog-content>
</div>
