import { Injectable } from '@angular/core';
import { DataSource } from '@trg-commons/data-models-ts';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { IconMarker } from 'src/app/modules/mapV2/models/map.models';
import { ProfilerIconMarker } from '../../../shared/models/profiler-icon-marker';
import { MarkerIcon } from '../models/marker-icons.enum';

@Injectable({
  providedIn: 'root'
})
export class ProfilerMapService {
  activeFilters: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  availableSources = new BehaviorSubject(null);
  private socialProfilesPlatformIconsMap = {
    [DataSource.Facebook]: MarkerIcon.Facebook,
    [DataSource.Twitter]: MarkerIcon.Twitter
  };

  setFilters(filters: string[]): void {
    this.activeFilters.next(filters);
  }

  setSources(sources: string[]): void {
    this.availableSources.next(sources);
  }

  filterMarkersByDate(from: number, to: number, collection: IconMarker[]): IconMarker[] {
    return collection
      .filter(marker => {
        if (!from || !to) {
          return true;
        }

        const updatedAt = moment(marker.date).valueOf();

        return updatedAt >= from && updatedAt <= to;
      })
      .sort((a, b) => +a.date - +b.date);
  }

  assignTimelineIcons(collection: IconMarker[]): IconMarker[] {
    return collection.map((marker, index) => {
      if (index === 0) {
        marker.zIndex = 1000;
        marker.iconUrl = this.skipSocialProfilesMarkers(marker as ProfilerIconMarker, MarkerIcon.TimelineFirst);
      } else if (index === collection.length - 1) {
        marker.iconUrl = this.skipSocialProfilesMarkers(marker as ProfilerIconMarker, MarkerIcon.TimelineLast);
        marker.zIndex = 1000;
      } else {
        marker.iconUrl = this.skipSocialProfilesMarkers(marker as ProfilerIconMarker, MarkerIcon.Grey);
        marker.zIndex = 100;
      }

      return marker;
    });
  }

  private skipSocialProfilesMarkers(marker: ProfilerIconMarker, selectedMarker: MarkerIcon): MarkerIcon {
    return this.socialProfilesPlatformIconsMap[marker.source] || selectedMarker;
  }
}
