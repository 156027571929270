import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TrgCommonModule } from 'src/app/modules/trg-common/trg-common.module';
import { MapButtonComponent } from './map-button/map-button.component';
import { MapLoaderService } from './shared/map-loader.service';
import { MapService } from './shared/map.service';
import { VanillaGoogleMapComponent } from './vanilla-google-map.component';

function loadGoogleMapsApi(mapLoader: MapLoaderService) {
  return (): Promise<any> => {
    return mapLoader.load();
  };
}

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule, MatButtonModule, TrgCommonModule],
  declarations: [VanillaGoogleMapComponent, MapButtonComponent],
  exports: [VanillaGoogleMapComponent],
  providers: [
    { provide: APP_INITIALIZER, useFactory: loadGoogleMapsApi, deps: [MapLoaderService], multi: true },
    { provide: 'mapService', useExisting: MapService }
  ]
})
export class VanillaGoogleMapModule { }
