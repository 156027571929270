import { Injectable } from '@angular/core';
import { GioTargetMetadata } from '@trg-commons/gio-data-models-ts';
import { Associate } from '@trg-commons/data-models-ts';
import { ImageService } from 'src/app/services/image/image.service';
import { ClAssociate } from 'src/app/shared/modules/call-logs-shared/models/cl-associate';
import { ImProfile } from 'datalayer/models/platform-models/im-profiles/im-profile';
import { first } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AssociatesDataTransformerService {
  constructor(private imageService: ImageService) {}

  public metaDataPopulate(metaData: { [key: string]: GioTargetMetadata[] }, associate: ClAssociate): ClAssociate {
    const msisdnMetaData = metaData[associate.msisdn] as GioTargetMetadata[];
    const associatedTarget = first(msisdnMetaData) as GioTargetMetadata;

    if (associatedTarget) {
      associate.photos.push(...(associatedTarget.photos || []));
      associate.hasAssociatedTarget = true;
      associate.associatedTargetAlias = associatedTarget.alias;
      associate.associatedTargetId = associatedTarget.targetId;
      associate.names.push(associatedTarget.alias);
      return associate;
    } else {
      return associate;
    }
  }

  /**
   *
   * @param associate Associate
   */
  public associateTransformer(associate: Associate): Associate {
    return {
      ...associate,
      targets: associate.targets.map(target => {
        return {
          ...target,
          photos: target.photos.map(p => this.imageService.getPhotoUrl(p) as string)
        };
      })
    };
  }

  /**
   * Populate data from instant-messages profiles
   * @param telno string
   * @param profile ImProfile
   * @param associate ClAssociate
   */
  public populateImProfile(telno: string, profile: ImProfile, associate: ClAssociate) {
    if (associate.msisdn === telno) {
      const thumbnails = associate.thumbnails || [];
      const isUniq = !thumbnails.find(t => t.platform === profile.platform);

      if (isUniq) {
        associate.thumbnails = [
          ...thumbnails,
          {
            src: this.imageService.getPhotoUrl(profile.thumbnail) as string,
            platform: profile.platform,
            sourceUrl: profile.sourceUrl
          }
        ];
      }

      const names = associate.names || [];
      associate.names = [...names, ...profile.names.filter(n => !names.includes(n))];
    }

    return {
      ...associate
    };
  }
}
