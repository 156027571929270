import { PaginationLinks, PaginationMeta } from "src/app/shared/models/pagination.model";

export interface CaseInvestigation {
  name: string;
  description?: string;
  uploadedBy?: string;
  updatedAt?: string;
  createdAt?: string;
  id?: string;
  image?: string;
  createdBy?: string;
  deletedAt?: Date;
  entities: CaseInvestigationEntities;
  sources?: InvestigationDataSourceType[];
  linkAnalysisGraphState?: LinkAnalysisGraphState;
  kronographState?: TimelineState;
  eventsMeta?: CaseInvestigationEventsMeta;
  colorsState?: string;
}

export interface InvestigationsListResponse {
  result?: CaseInvestigation[];
  meta?: PaginationMeta;
  links?: PaginationLinks;
}

export enum InvestigationDataSourceType {
  voice = 'voice',
  sms = 'sms',
  data = 'data',
  geo_query = 'geo_query',
  checkin = 'checkin',
  photo = 'photo',
  post = 'post',
  telegram = 'telegram',
  adid = 'adid',
  instant_message = 'instant_message'
}

export interface TextAnalysisFile {
  filePath: string;
  fileId: string;
}

export interface CaseInvestigationEntities {
  targets: string[];
  cases: string[];
  msisdns: string[];
  files?: TextAnalysisFile[];
}

export interface LinkAnalysisGraphState {
  chartState: string;
  filtersState: string;
}

export interface TimelineState {
  startAt: Date;
  endAt: Date;
  pinedEntities: string[];
  focusedEntities: string[];
}

interface CaseInvestigationEventsMeta {
  startOffset: number;
  lastOffset: number;
}
