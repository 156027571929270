import { Circle, IconMarker, Polygon } from 'src/app/modules/mapV2/models/map.models';

export class DiscoveryTimelineIconMarker extends IconMarker {
  constructor(model: Partial<DiscoveryTimelineIconMarker>) {
    super(model);
    Object.assign(this, model);
  }
  sector: Polygon;
  circles: Circle[];
}
