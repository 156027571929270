export enum EntityType {
  EntityRelation = 'EntityRelation',
  TelcoProvider = 'TelcoProvider',
  Sim = 'Sim',
  Cell = 'Cell',
  Phone = 'Phone',
  Profile = 'Profile',
  Post = 'Post',
  Article = 'Article',
  Page = 'Page',
  Place = 'Place',
  Address = 'Address',
  Group = 'Group',
  Photo = 'Photo',
  Video = 'Video',
  Audio = 'Audio',
  Album = 'Album',
  Hashtag = 'Hashtag',
  Symbol = 'Symbol',
  Poll = 'Poll',
  GeoLocation = 'Geolocation',
  Person = 'Person',
  Education = 'Education',
  Company = 'Company',
  TextAnalytics = 'TextAnalytics',
  PhotoMeta = 'PhotoMeta',
  PhotoContent = 'PhotoContent',
  WebDomain = 'WebDomain',
}
