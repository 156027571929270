import { Injectable } from '@angular/core';
import { Person } from 'datalayer/models';
import { DataSource } from 'datalayer/models/platform-models';
import { Profile } from 'datalayer/models/social/profile';
import { ProfileService } from 'datalayer/services/social/profile/profile.service';
import { head } from 'lodash-es';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { CountryPipe } from 'src/app/pipes/country.pipe';
import { TypeOfDob } from 'src/app/shared/models/date-of-birth.enum';
import { ProfilerService } from './profiler.service';
import { TargetSummaryChildComponentService } from './target-summary-child-component.service';

export class TargetItemInfo {
  gender?: string;
  country?: string;
  age?: number;
}

interface TargetGender {
  gender: string;
  source: DataSource,
}

interface PersonAddress {
  city?: string;
  country?: string;
}

@Injectable({
  providedIn: 'root'
})

export class TargetCardInfoService {
  target: TargetItemInfo;
  private targetGenders: TargetGender[] = [];
  private targetDobs: string[] = [];
  private targetCountries: string[] = [];
  private readonly extraDataSources: DataSource[] = [
    DataSource.Truecaller,
    DataSource.CallerID,
  ];

  constructor(
    private profilerService: ProfilerService,
    private profileService: ProfileService,
    private targetSummaryChildService: TargetSummaryChildComponentService,
    private countryPipe: CountryPipe,
  ) { }

  generateCompleteTargetData(targetId: string): Observable<TargetItemInfo | undefined> {
    return new Observable<TargetItemInfo | undefined>((obs) => {
      forkJoin({
        targetData: this.profilerService.getTargetData(targetId),
        persons: this.targetSummaryChildService.getAllPersons(targetId),
        profiles: this.targetSummaryChildService.getAllSocialProfiles(targetId, this.extraDataSources),
      }).subscribe((data) => {
        if (!data) {
          obs.next(undefined);
          obs.complete();
          return;
        }
        this.target = {
          gender: data.targetData.gender,
          age: data.targetData.dateOfBirth ? moment().diff(new Date(data.targetData.dateOfBirth), 'years', false) : undefined,
          country: data.targetData.countryCode || undefined,
        };

        if (!this.target.country && data.targetData?.countries?.length > 0) {
          this.target = this.setTargetCountriesFromTargetData(data.targetData.countries);
        }

        this.initTargetInfo();

        if (data.persons && data.persons.length > 0) {
          this.setTargetGenders(data.persons);
          this.setPersonTargetDobs(data.persons);
          this.setPersonTargetCountries(data.persons);
        }
        if (data.profiles && data.profiles.length > 0) {
          this.setTargetGenders(data.profiles);
          this.setTargetDobs(data.profiles);
          this.setProfileTargetCountries(data.profiles);
        }
        if (this.targetGenders?.length > 0) {
          this.target = this.getTargetGender();
        }
        if (this.targetDobs?.length > 0) {
          this.target = this.getTargetAge();
        }
        if (this.targetCountries?.length > 0) {
          this.target = this.getTargetCountry();
        }
        obs.next(this.target);
        obs.complete();
      });
    });
  }

  private initTargetInfo(): void {
    this.targetGenders = [];
    this.targetDobs = [];
    this.targetCountries = [];
  }

  setTargetCountriesFromTargetData(countries: string[]): TargetItemInfo {
    const targetCountries: string[] = [];
    countries.forEach(country => {
      country = this.countryPipe.getCountryCode(country);
      if (country) {
        targetCountries.push(country);
      }
    });

    this.target.country = head(targetCountries);
    return this.target;
  }

  setProfileTargetCountries(profiles: Profile[]): void {
    if (this.target.country) {
      return;
    }

    profiles.forEach((profile: Profile) => {
      if (profile.hometown?.title) {
        this.setTargetCountryCodesFromProfile(profile.hometown.title);
      }

      if (profile.nameLocation?.title) {
        this.setTargetCountryCodesFromProfile(profile.nameLocation.title);
      }

      if (profile.currentCity?.title) {
        this.setTargetCountryCodesFromProfile(profile.currentCity.title);
      }
    })
  }

  setTargetCountryCodesFromProfile(location: string): void {
    const hometownTitles: string[] = location.split(',');
    hometownTitles.forEach(title => {
      title = title.trim();
      const countryCode = this.countryPipe.getCountryCode(title);
      if (countryCode) {
        this.targetCountries.push(countryCode);
      }
    });
  }

  setPersonTargetCountries(persons: Person[]): void {
    if (this.target.country) {
      return;
    }

    const personAddresses: {}[] = [];
    persons.forEach((person: Person) => {
      if (person.originCountries?.length > 0) {
        this.setPersonOriginCountries(person.originCountries);
      }

      if (person.addresses?.length > 0) {
        personAddresses.push(person.addresses);
      }
    });

    personAddresses.forEach((addresses: Partial<PersonAddress>[]) => {
      for (const address of addresses) {
        if (address.country) {
          this.targetCountries.push(address.country);
        }
      }
    });
  }

  setPersonOriginCountries(countries: string[]): void {
    countries.forEach(country => {
      this.targetCountries.push(country);
    });
  }

  setTargetDobs(profiles: Profile[]): void {
    if (this.target.age) {
      return;
    }

    profiles.forEach((item: Profile) => {
      if (item.dob && !this.target.age) {
        const formatDate = this.profileService.formatDateOfBirth(item.dob);
        if (!formatDate.typeOfBirthday || formatDate.typeOfBirthday !== TypeOfDob.NO_YEAR) {
          this.targetDobs.push(item.dob);
        }
      }
    });
  }

  setPersonTargetDobs(persons: Person[]): void {
    if (this.target.age) {
      return;
    }

    persons.forEach((person: Person) => {
      if (person.dateOfBirth && !this.target.age) {
        const formatDate = this.profileService.formatDateOfBirth(person.dateOfBirth);
        if (!formatDate.typeOfBirthday || formatDate.typeOfBirthday !== TypeOfDob.NO_YEAR) {
          this.targetDobs.push(person.dateOfBirth);
        }
      }
    });
  }

  setTargetGenders(profiles: Profile[] | Person[]): void {
    if (this.target.gender) {
      return;
    }

    profiles.forEach((item: Profile | Person) => {
      if (item.gender) {
        this.targetGenders.push({ 'gender': item.gender, 'source': item.source });
      }
    });
  }

  getTargetCountry(): TargetItemInfo {
    let counts = {};
    this.targetCountries.forEach((country: string) => {
      counts[country] = (counts[country] || 0) + 1;
    });

    let country = Object.keys(counts).reduce((a, b) => counts[a] > counts[b] ? a : b);

    this.target.country = country || undefined;
    return this.target;
  }

  getTargetAge(): TargetItemInfo {
    let counts = {};
    this.targetDobs.forEach((targetDob: string) => {
      const targetDobStr = targetDob.match(this.profileService.regexDobOnlyYear) ? targetDob : new Date(targetDob)?.toString();
      counts[targetDobStr] = (counts[targetDobStr] || 0) + 1;
    });

    let age = Object.keys(counts).reduce((a, b) => {
      return counts[a] > counts[b] ? a : b;
    });

    this.target.age = age ? moment().diff(age, 'years', false) : undefined;
    return this.target;
  }

  getTargetGender(): TargetItemInfo {
    let counts = {};
    this.targetGenders.forEach((targetGenderObj: TargetGender) => {
      targetGenderObj.gender = targetGenderObj.gender.toLowerCase();
      counts[targetGenderObj.gender] = (counts[targetGenderObj.gender] || 0) + 1;
    });

    let gender = Object.keys(counts).reduce((a, b) => {
      if (counts[a] == counts[b]) {
        return;
      }

      return counts[a] > counts[b] ? a : b;
    });

    if (!gender) {
      gender = this.fallBackGetGender();
    }

    this.target.gender = gender;
    return this.target;
  }

  fallBackGetGender(): string {
    let gender: string;
    gender = (this.targetGenders.find(obj => obj.source === DataSource.Facebook))?.gender;

    if (!gender) {
      gender = (this.targetGenders.find(obj => obj.source === DataSource.Skype))?.gender;
    }

    return gender;
  }
}
