<app-base-modal-component [headerDetails]="headerDetails" class="height-100">
  <ng-container ngProjectAs="coverContent">
    <div *ngIf="imageUrl" class="image-details-dialog">
      <img class="blur-img" [src]="imageUrl" appFallbackImg="assets/static/images/no_photo.svg" />
      <img class="bg-img" [src]="imageUrl" appFallbackImg="assets/static/images/no_photo.svg" />
    </div>
  </ng-container>
  <ng-container ngProjectAs="detailsContent">
    <div class="col-12">
      <div *ngIf="trimmedProfileName" class="item-description">
        <span>
          <b>{{ 'Name' | translate }}:</b> {{ profile.name }}
        </span>
      </div>
      <div *ngIf="profile.source === 'WA' && profile?.image?.published_at" class="item-description">
        <span>
          <b>{{ 'Photo update time' | translate }}:</b> {{ profile.image.published_at | date: 'dd MMM yyyy, HH:mm' }}
        </span>
      </div>
      <div *ngIf="profile.about" class="item-description">
        <span>
          <b>{{ 'About' | translate }}:</b> {{ profile.about }}
        </span>
      </div>
      <div *ngIf="profile.username" class="item-description">
        <span>
          <b>{{ 'Username' | translate }}:</b> {{ profile.username }}
        </span>
      </div>
      <div *ngIf="profile.telno" class="item-description">
        <span>
          <b>{{ 'Phone' | translate }}:</b> {{ profile.telno }}
        </span>
      </div>
    </div>
    <div *ngIf="profile.sourceEntity?.createdAt" class="query-date assistant-font">
      {{'Query date' | translate}}:
      {{ profile.sourceEntity?.createdAt | date:'dd/MM/yyyy HH:mm' }}
    </div>
  </ng-container>
</app-base-modal-component>
