import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export class IntelResultResponse {
  queryArgs?: any;
  persons?: any;
  filters?: any;
}
@Injectable({
  providedIn: 'root'
})
export class IntelResultsNotificationsStore {
  private obs$ = new BehaviorSubject<{ [id: string]: any[] }>({});
  public notifications$ = this.obs$.asObservable();

  private get value() {
    return this.obs$.getValue();
  }

  /**
   *
   * @param value Notifications
   */
  private set value(value: { [id: string]: any[] }) {
    this.obs$.next(value);
  }

  /**
   *
   * @param newNotification PurchaseNotificationResponse
   */
  public addNotification(newNotification: any) {
    const newId = newNotification.correlationId;
    const body = newNotification.body.body;
    const curNotificationsById = this.value[newId];

    this.value = {
      ...this.value,
      [newId]: curNotificationsById
        ? [...curNotificationsById, Array.isArray(body) ? body[0] : body]
        : [Array.isArray(body) ? body[0] : body]
    };
  }

  /**
   *
   * @param id string
   */
  cleanNotificationsById(id: string) {
    this.value = {};
  }

  /**
   *
   * @param id string
   */
  hasDoneNotification(id: string) {
    const curNotificationsById = this.value[id];
    return curNotificationsById ? curNotificationsById.some(n => n.status === 'complete') : false;
  }

  hasNotificationWithId(id: string) {
    return this.value.hasOwnProperty(id);
  }
}
