import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Profile } from 'datalayer/models/social';
import { forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataSource, EntityRelationType, EntityType } from 'src/app/modules/data-layer/models/platform-models';
import { RequestOptions } from 'src/app/modules/data-layer/services/base';
import { ProfileService as SocialProfilesService } from 'src/app/modules/data-layer/services/social/profile/profile.service';
import { AppConfigService } from 'src/app/providers/app-config.service';
import { BaseService } from 'src/app/services/base.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { Case } from 'src/app/shared/models/case.model';
import { Query } from 'src/app/shared/models/query-item.model';
import { TargetItem } from 'src/app/shared/models/target-item.model';
import { transformSnakeToCamel } from 'src/app/shared/util/helper';

@Injectable({
  providedIn: 'root'
})
export class CaseService extends BaseService {
  paginatedCases = new Subject<any>();
  selectedCase = new Subject<any>();
  caseUpdated = new Subject<Case>();
  removeTargetFromCase = new Subject<{ data: TargetItem; deleted: boolean }>();
  caseCreditsChargesEnabled: boolean = false;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private localStorageService: LocalStorageService,
    protected snackBar: MatSnackBar,
    private appConfigService: AppConfigService,
    private socialProfilesService: SocialProfilesService
  ) {
    super(router, snackBar);
    this.caseCreditsChargesEnabled = this.appConfigService.getConfigVariable('enableCreditChargesForCase');
  }

  static getNextAvailableColor(data: Case): string {
    for (const key in data.targetsColors) {
      if (!data.targetsColors[key]) {
        return key;
      }
    }
  }

  static getTargetColor(data: Case, targetId: string): string {
    const defaultColor = 'red';
    for (const color in data.targetsColors) {
      if (data.targetsColors[color] === targetId) {
        return color ? '#' + color : defaultColor;
      }
    }

    return defaultColor;
  }

  static getTargetColorKey(data: Case, color: string): string {
    const defaultColor = 'red';
    for (const key in data.targetsColors) {
      if (key.replace('#', '').toLowerCase() === color.replace('#', '').toLowerCase()) {
        return key;
      }
    }

    return defaultColor;
  }

  removeCase(case_id: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/case/${case_id}`).pipe(
      map(data => data.result),
      catchError(error => this.handleError(error))
    );
  }

  updateCase(data: Case): Observable<any> {
    this.localStorageService.removeCaseAnalyticsData(data.id);
    return this.httpClient
      .put<any>(`${this.url}/case/${data.id}`, {
        case_name: data.caseName,
        case_color: data.caseColor,
        case_description: data.caseDescription,
        assigned_users: data.assignedUsers,
        assigned_targets: data.assignedTargets.map((e: TargetItem) => e.id),
        targets_colors: data.targetsColors
      })
      .pipe(
        map(data => {
          const targetColors = { ...data.result.targets_colors };
          const result = transformSnakeToCamel(data.result);
          result.targetsColors = targetColors;
          this.setCaseData(result);
          return result;
        }),
        catchError(error => this.handleError(error))
      );
  }

  createCase(data: Case): Observable<any> {
    return this.httpClient
      .post<any>(`${this.url}/cases`, {
        case_name: data.caseName,
        case_color: data.caseColor,
        case_description: data.caseDescription,
        assigned_users: data.assignedUsers,
        assigned_targets: data.assignedTargets,
        targets_colors: data.targetsColors
      })
      .pipe(
        map(data => data.result),
        catchError(error => this.handleError(error))
      );
  }

  addTargetsToCase(data: Case): Observable<any> {
    this.localStorageService.removeCaseAnalyticsData(data.id);
    return this.httpClient
      .put<any>(`${this.url}/case/${data.id}`, {
        case_name: data.caseName,
        case_color: data.caseColor,
        case_description: data.caseDescription,
        assigned_users: data.assignedUsers,
        assigned_targets: data.assignedTargets,
        targets_colors: data.targetsColors
      })
      .pipe(
        map(data => {
          const targetColors = { ...data.result.targets_colors };
          const result = transformSnakeToCamel(data.result);
          result.targetsColors = targetColors;
          this.setCaseData(result);
          return result;
        }),
        catchError(error => this.handleError(error))
      );
  }

  getPaginatedCases({ limit = 6, page = 1, filterArg = '' }): Observable<any> {
    const params = {};
    params['limit'] = limit;
    params['page'] = page;
    params['filterArg'] = filterArg;
    return this.httpClient.get<any>(`${this.url}/cases`, { params }).pipe(
      map(data => {
        const dataInCamelCase = transformSnakeToCamel(data);
        this.paginatedCases.next(dataInCamelCase);
        return transformSnakeToCamel(dataInCamelCase);
      }),
      catchError(error => this.handleError(error))
    );
  }

  getCase(caseId: string) {
    return this.httpClient.get<any>(`${this.url}/case/${caseId}`).pipe(
      map(data => {
        const targetColors = { ...data.result.targets_colors };
        const result = transformSnakeToCamel(data.result);
        result.targetsColors = targetColors;
        this.setCaseData(result);
        return result;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getCaseTargets(caseId: string, filterIfa = false): Observable<TargetItem[]> {
    const params = {};
    if (filterIfa) {
      params['hasIfas'] = filterIfa;
    }
    return this.httpClient.get<any>(`${this.url}/case/${caseId}/targets`, { params }).pipe(
      map(response => {
        return transformSnakeToCamel(response.result);
      }),
      catchError(error => this.handleError(error))
    );
  }

  mapSocialProfilesToTargets(userIds: string[], caseMembers: TargetItem[]) {
    const targets: TargetItem[] = [];
    const reqs$ = caseMembers.map(target => {
      const filters: RequestOptions = {
        filters: {
          source: [DataSource.Twitter, DataSource.Facebook, DataSource.Instagram, DataSource.LinkedIn],
          targetId: target.id,
          type: EntityType.Profile,
          relationType: [EntityRelationType.Plain]
        }
      };
      return this.socialProfilesService.getAll(filters).pipe(map(result => Object.values(result)));
    });

    return forkJoin(reqs$).pipe(
      map(allProfiles => {
        allProfiles.forEach((targetProfiles: Profile[], index: number) => {
          const profileIndex = targetProfiles.findIndex(profile => {
            const userIdIndex = userIds.findIndex(id => profile?.profileId?.toLowerCase().includes(id));
            return userIdIndex >= 0;
          });
          if (profileIndex >= 0) {
            targets.push(caseMembers[index]);
          }
        });
        return targets;
      })
    );
  }

  getTargetsLocations(
    caseId: string,
    limit: number = 500
  ): Observable<{ data: { [targetId: string]: Query[] }; meta: any }> {
    return this.httpClient.get<any>(`${this.url}/queries/case/${caseId}?limit=${limit}`).pipe(
      map(response => {
        const locations: { [targetId: string]: Query[] } = {};

        Object.keys(response.data).forEach(targetId => {
          locations[targetId] = transformSnakeToCamel(response.data[targetId]);
        });

        response.data = locations;
        response.meta = transformSnakeToCamel(response.meta);
        return response;
      }),
      catchError(error => this.handleError(error))
    );
  }

  renewCase(caseId: string): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/case/${caseId}/renew`, { caseId })
      .pipe(catchError(error => this.handleError(error)));
  }

  makeFavourite(data: Case,favourite: boolean): Observable<any> {
    this.localStorageService.removeCaseAnalyticsData(data.id);
    return this.httpClient
      .put<any>(`${this.url}/case/${data.id}`, {
        case_name: data.caseName,
        case_color: data.caseColor,
        case_description: data.caseDescription,
        assigned_users: data.assignedUsers,
        assigned_targets: data.assignedTargets.map((e: TargetItem) => e.id),
        targets_colors: data.targetsColors,
       favourite: favourite
      })
      .pipe(
        map(data => {
          const targetColors = { ...data.result.targets_colors };
          const result = transformSnakeToCamel(data.result);
          result.targetsColors = targetColors;
          this.setCaseData(result);
          return result;
        }),
        catchError(error => this.handleError(error))
      );
  }

  setCaseData(result) {
    this.selectedCase.next(result);
  }

  isCaseExpired(caseItem: Case) {
    return (
      caseItem && caseItem.expired && window.location.pathname.includes('/case/') && this.caseCreditsChargesEnabled
    );
  }
}
