import { Injectable } from '@angular/core';
import { CdrType, GeoLocation } from '@trg-commons/data-models-ts';
import { flatten } from 'lodash-es';
import { isEmpty } from 'src/app/shared/util/isEmpty';
import { BehaviorSubject, Subject } from 'rxjs';
import { bufferTime, filter, map, switchMap } from 'rxjs/operators';
import { CdrTarget } from 'src/app/modules/call-logs/components/cl-main-map-view/cl-table/models/cdr-target.model';
import { BaseStore } from 'src/app/shared/classes/base-store.class';
import { Filter } from 'src/app/shared/classes/filter.class';
import { ClCdrTypes } from 'src/app/shared/modules/call-logs-shared/models/cl-cdr-types.value';
import * as cdrHelpers from 'src/app/shared/modules/call-logs-shared/services/cdr-helpers';
import { ClLocationFilterProp } from 'src/app/shared/modules/call-logs-shared/values/cl-location-props.value';
import { RequestClParameters } from 'src/app/modules/analysis/shared/models/request-cl-parameters.model';

export enum CdrStreamControl {
  RESET = 'RESET'
}
@Injectable({ providedIn: 'root' })
export class CallLogsMapStore extends BaseStore<CdrTarget[]> {
  requestClParameters: RequestClParameters = {msisdns: [], imeis: [], imsis: []};
  correlationId: string | undefined;
  constructor() {
    super([]);
  }

  private filters = new BehaviorSubject<Filter[]>([]);
  private filteredEntityStream: Subject<CdrTarget[] | CdrStreamControl> = new Subject();
  private entityStream: Subject<CdrTarget[]> = new Subject();

  eventsDateRange: { from?: Date; to?: Date; } = {};
  queryDates: { from?: Date; to?: Date; } = {};

  filteredStream$ = this.filteredEntityStream.asObservable().pipe(
    bufferTime(1000),
    switchMap(x => x),
    filter(v => (Array.isArray(v) ? v.length > 0 : true)),
    map(batch => {
      if (Array.isArray(batch)) {
        return flatten(batch);
      }
      return batch;
    })
  );

  stream$ = this.entityStream.asObservable().pipe(map(batch => flatten(batch)));
  filters$ = this.filters.asObservable();

  private streamEndReceived = new BehaviorSubject<boolean>(false);
  public streamEndReceived$ = this.streamEndReceived.asObservable().pipe(filter(v => v));

  public setStreamEndEvent(value: boolean) {
    this.streamEndReceived.next(value);
  }

  public override update(value: CdrTarget[]) {
    this.value = value;
  }

  public reset() {
    this.value = [];
  }

  public isEmpty(): boolean {
    return this.value?.length === 0;
  }

  public getFiltersValue() {
    return this.filters.getValue();
  }

  public append(targets: CdrTarget[]) {
    const validTargets = targets.filter((cdr) => {
      const geo = !isEmpty(cdr.geoMsisdn) ? cdr.geoMsisdn : cdr.geoReceiverMsisdn;
      try {
        this.getCdrType(cdr);

        return !!geo?.geojson;
      } catch (e) {
        console.warn(e);

        return false;
      }
    });

    this.value = [...this.value, ...validTargets];
    this.addCdrsToDates(validTargets);
    const filteredTargets = validTargets.filter(cdr => this.satisfiesFilters(cdr));

    this.filteredEntityStream.next(filteredTargets);
    this.entityStream.next(validTargets);
  }

  public filter(filters: Filter[]) {
    if (!this.streamEndReceived.value) {
      return;
    }

    this.filters.next(filters);
    const entities = this.value;

    this.filteredEntityStream.next(CdrStreamControl.RESET);
    const filteredCdrs = entities.filter(cdr => this.satisfiesFilters(cdr));
    this.filteredEntityStream.next(filteredCdrs);
  }

  /**
   *
   * @param filter Filter
   * @param emitEvent boolean
   */
  public updateFilter(filter: Filter, emitEvent: boolean = true): Filter[] {
    const filters = this.filters.getValue();
    const existingFilter = filters.find(item => item.name === filter.name);

    if (existingFilter) {
      existingFilter.value = filter.value;
    } else {
      filters.push(filter);
    }

    if (emitEvent) {
      this.filters.next(filters);
    }

    return filters;
  }

  public setRequestParameters(type: string, value: string[]) : void {
    this.requestClParameters[type] = value;
  }

  public getCdrType(cdr: CdrTarget): ClCdrTypes | undefined {
    return cdrHelpers.getCdrType(cdr, this.requestClParameters);
  }

  public ownedCdr(cdr: CdrTarget, requestClParameters = this.requestClParameters): boolean {
    return Object.keys(requestClParameters).some(key => cdrHelpers.ownedCdr(cdr, requestClParameters[key]));
  }

  public isPeer(cdr: CdrTarget, requestClParameters = this.requestClParameters): boolean {
    return Object.keys(requestClParameters).some(key => cdrHelpers.isPeer(cdr, requestClParameters[key]));
  }

  public msisdnBelongsToTarget(msisdn: string, telnos: string[] = this.requestClParameters.msisdns): boolean {
    return cdrHelpers.msisdnBelongsToTarget(msisdn, telnos);
  }

  public hasImei(cdr: CdrTarget): boolean {
    return cdrHelpers.hasImei(cdr);
  }

  public isLocationBelongToMsisdn(cdr: CdrTarget): boolean {
    switch (cdr.cdrType) {
      case CdrType.Voice:
      case CdrType.Sms:
        return cdrHelpers.ownedCdr(cdr, [cdr.msisdn]);
      case CdrType.Data:
        return this.ownedCdr(cdr, {msisdns: [cdr.msisdn]});
    }
  }

  public getLocationForMsisdnFromCdr(cdr: CdrTarget): { geo: GeoLocation; msisdn: string; } | undefined {
    return cdrHelpers.getLocationForMsisdnFromCdr(cdr);
  }

  public getRequestedLocation(requestClParameters = this.requestClParameters, cdr: CdrTarget): GeoLocation | null {
    const fromSource = requestClParameters.msisdns?.includes(cdr.msisdn) || requestClParameters.imeis?.includes(cdr.imei);
    const fromDestination = requestClParameters.msisdns?.includes(cdr.receiverMsisdn) || requestClParameters.imeis?.includes(cdr.imei);
    return fromSource ? cdr?.geoMsisdn : fromDestination ? cdr?.geoReceiverMsisdn : null;
  }

  private addCdrsToDates(cdrs: CdrTarget[]) {
    cdrs.forEach(cdr => {
      const timestamp = new Date(cdr.createdAt as Date);

      if (!this.eventsDateRange.from) {
        this.eventsDateRange.from = timestamp;
        this.eventsDateRange.to = timestamp;
        return;
      }
      if (timestamp < this.eventsDateRange.from) {
        this.eventsDateRange.from = timestamp;
      } else if (this.eventsDateRange.to && timestamp > this.eventsDateRange.to) {
        this.eventsDateRange.to = timestamp;
      }
    });
  }

  private filterPredicate(cdr: CdrTarget): (filter: Filter) => boolean {
    const geo = this.getLocationForMsisdnFromCdr(cdr)?.geo;
    return (filter: Filter): boolean => {
      switch (filter.name) {
        case 'type':
          return this.filterByType(cdr, filter.value);
        case 'location':
          return this.filterByLocation(cdr, filter.value);
        case 'dates':
          const createdAt = new Date(geo?.sourceEntity?.createdAt).getTime();
          const [from, to]: [number, number] = filter.value.map(val => val.getTime());
          return !filter.isValuesEmpty() ? createdAt >= from  && createdAt <= to : true;
        case 'filter':
          return (cdr.msisdn || '').includes(filter.value) || (cdr.receiverMsisdn || '').includes(filter.value);
        default:
          return false;
      }
    };
  }

  private satisfiesFilters(cdr: CdrTarget): boolean {
    const filters = this.filters.getValue();
    let expression: boolean = true;

    for (const filter of filters) {
      expression = expression && (this.filterPredicate(cdr)(filter) || filter.isValuesEmpty());
    }

    return expression;
  }

  private filterByType(cdr: CdrTarget, filterValues: Array<{ type: ClCdrTypes; }>): boolean {
    if (isEmpty(filterValues)) {
      return true;
    }

    const cdrType = this.getCdrType(cdr);

    return filterValues.some(({ type }) => cdrType === type);
  }

  private filterByLocation(cdr: CdrTarget, value: ClLocationFilterProp): boolean {
    if (isEmpty(value)) {
      return true;
    }
    let location: {
      geo: GeoLocation;
      msisdn: string;
    };
    switch (value) {
      case ClLocationFilterProp.AllLocations:
        return true;

      case ClLocationFilterProp.TargetLocations:
        location = this.getLocationForMsisdnFromCdr(cdr);
        return this.requestClParameters.msisdns?.includes(location.msisdn);
      case ClLocationFilterProp.PeerLocations:
        location = this.getLocationForMsisdnFromCdr(cdr);
        return !this.requestClParameters.msisdns?.includes(location.msisdn);
    }
  }

  public getFilteredValues(): CdrTarget[] {
    return this.value?.filter(cdr => this.satisfiesFilters(cdr)) || [];
  }
}
