import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { JobStatus } from 'datalayer/models/background-jobs/background-job-status';
import { FeatureNotificationResponse } from 'datalayer/models/background-jobs/feature-notification';
import { uniqBy } from 'lodash-es';
import { ActiveToast } from 'ngx-toastr';
import { Observable,take } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { FeaturesService } from 'src/app/services/features/features.service';
import { TargetRenewalService } from 'src/app/services/target/target-renewal.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { TargetItem } from '../../models/target-item.model';
import { FeatureNotificationsStore } from './feature-notifications.store';

@Component({
  selector: 'app-group-toastr',
  templateUrl: './feature-group-notifications.component.html',
  styleUrls: ['./feature-group-notifications.component.scss']
})
export class FeatureGroupNotificationsComponent extends BaseComponent implements OnInit {
  constructor(
    private featureNotificationsStore: FeatureNotificationsStore,
    private router: Router,
    private targetService: TargetService,
    private featuresService: FeaturesService,
    private translationService: TranslationService,
    private targetRenewalService: TargetRenewalService
  ) {
    super();
  }

  public toast: ActiveToast<any>;
  public id: string;
  public target$: Observable<TargetItem>;
  public toasts$: Observable<FeatureNotificationResponse[]>;
  public isHidden = false;
  public inProgress: boolean;
  public showTargetLink: boolean;
  public notificationsList: FeatureNotificationResponse[] = [];

  ngOnInit() {
    this.showTargetLink = this.notOnTargetPage(window.location.pathname);

    this.toasts$ = this.featureNotificationsStore.notifications$.pipe(
      map((ids) => {
        return uniqBy(ids[this.id], 'platform');
      }),
      tap((ids: FeatureNotificationResponse[]) => {
        this.inProgress = ids.some(
          (n) =>
            n.status === JobStatus.PENDING ||
            n.status === JobStatus.EXPIRED ||
            n.status === JobStatus.REISSUED ||
            n.status === JobStatus.ABANDONED
        );
      }),
      shareReplay()
    );

    this.toasts$.subscribe((data) => {
      if (data.length) {
        data.forEach((item) => {
          if (this.checkIfRequestIsAvailable(item)) {
            this.notificationsList.push(item);
          } else {
            const updatedNotification = this.notificationsList.filter(
              (x) => x.request_id === item.request_id && x.target_id === item.target_id
            );
            updatedNotification[0].status = item.status;
          }
        });
      }
    });

    this.target$ = this.targetService.getTarget(this.id);

    this.subscriptions.push(this.routerSub());
  }

  public abandonRequest(requestId: string) {
    this.featuresService.updateFeatureStatus(requestId, JobStatus.ABANDONED).subscribe(
      (data) => {
        this.showMessage(this.translationService.translate(data['message']));
        this.featureNotificationsStore.deleteNotification(this.id, requestId);
        if (!this.inProgress) {
          this.removeGroup();
        }
      },
      (error) => console.error(error)
    );
  }

  public reissueRequest(requestId: string) {
    this.featuresService.updateFeatureStatus(requestId, JobStatus.REISSUED).subscribe(
      () => { },
      (error) => console.error(error)
    );
  }

  public removeGroup() {
    this.featureNotificationsStore.cleanNotificationsById(this.id);
    this.toast.toastRef.close();
  }

  private notOnTargetPage(url: string) {
    return !url.includes(this.id);
  }

  private routerSub() {
    return this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ url }: RouterEvent) => {
        this.showTargetLink = this.notOnTargetPage(url);
      });
  }

  checkIfRequestIsAvailable(notification) {
    const notify = this.notificationsList.filter(
      (x) => x.request_id === notification.request_id && x.target_id === notification.target_id
    );
    return notify.length == 0;
  }

  goToTargetPage() {
    this.router.navigateByUrl(`targets/${this.id}/overview`);
    this.removeGroup();
  }

  navigateToTarget(target: TargetItem): void {
    if (!target) {
      return;
    }
    this.subscriptions.push(
      this.targetRenewalService
        .isTargetExpiredShowMessageOrRenew([target], true)
        .pipe(take(1))
        .subscribe((isTargetActive: boolean) => {
          if (!isTargetActive) {
            return;
          }
          this.router.navigate(['targets', target.id, 'overview']);
        })
    );
  }

  showMessage(
    msg: string,
    okText = 'OK',
    preferredDuration?: number,
    preferredHorizontalPosition = this.defaultSnackbarHorizontalPosition,
    preferredVerticalPosition = this.defaultSnackbarVerticalPosition
  ) {
    this.snackBar.open(msg, okText, {
      duration: preferredDuration || 3000,
      horizontalPosition: preferredHorizontalPosition,
      verticalPosition: preferredVerticalPosition,
      panelClass: ['custom-snackbar']
    });
  }
}
