import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ApexAnnotations, ApexAxisChartSeries,
  ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ChartComponent
} from 'ng-apexcharts';


type ChartOptions = {
  series: ApexAxisChartSeries;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-feed-line-chart',
  templateUrl: './feed-line-chart.component.html',
  styleUrls: ['./feed-line-chart.component.scss']
})
export class FeedLineChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @Input() buildChart: BuildLineChart;
  constructor() {}

  public initChartData(): void {
    this.chartOptions = {
      series: this.buildChart.dataSet,
      tooltip: {
        x: {
          format: 'dd/MM/yyyy'
        }
      },
      chart: {
        height: 380,
        type: 'line'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        width: 2,
        dashArray: 0
      },
      grid: {
        padding: {
          right: 30,
          left: 20
        }
      },
      title: {
        text: '',
        align: 'left'
      },
      labels: this.buildChart.labels,
      xaxis: {
        type: 'datetime'
      }
    };
  }

  ngOnInit(): void {}

  ngOnChanges() {
    this.initChartData();
  }
}

export interface BuildLineChart {
  dataSet: { data: number[]; name: string }[];
  labels?: string[];
}
