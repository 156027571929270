import { Component, EventEmitter, Input, Output, OnChanges, ChangeDetectorRef } from '@angular/core';
import { BillingService } from 'src/app/services/billing/billing.service';
import { BaseComponent } from 'src/app/shared/classes/base.component';
import { BillingActions, BillingActionType, BillingPlan } from 'src/app/shared/models/billing-action.model';

@Component({
  selector: 'app-credit-cost-button',
  templateUrl: './credit-cost-button.component.html',
  styleUrls: ['./credit-cost-button.component.scss'],
})
export class CreditCostButtonComponent extends BaseComponent implements OnChanges {
  @Input() action: string | string[];
  @Input() buttonText: string;
  @Input() active: boolean;
  @Output() clicked = new EventEmitter<void>();
  @Input() stylingClasses: string;
  @Input() countOfActionsCharged?: number = 1;
  isActive: boolean = false;
  isDisabled: boolean = false;
  cost: number = 0;

  constructor(private billingService: BillingService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  private get billingPlan(): BillingPlan<BillingActions, BillingActionType> {
    return this.billingService.getBillingPlan().getValue()
  }

  ngOnChanges(): void {
    this.updateStatus();
  }

  private updateStatus(): void {
    this.isActive = this.active && this.hasBillingPlanActions;
    this.isDisabled = !this.active || !this.hasBillingPlanActionsWithCost;

    if (this.isActive) {
      this.cost = this.totalOfActions * this.countOfActionsCharged;
    }

    this.changeDetectorRef.markForCheck();
  }

  private get hasBillingPlanActions(): boolean {
    if (typeof this.action === 'string') {
      return this.action in this.billingPlan;
    }
    if (Array.isArray(this.action)) {
      return this.action.every((action) => action in this.billingPlan);
    }

    return false;
  }

  private get hasBillingPlanActionsWithCost(): boolean {
    if (typeof this.action === 'string') {
      return this.billingPlan[this.action]?.cost >= 0
    }
    if (Array.isArray(this.action)) {
      return this.action.every((action) => this.billingPlan[action]?.cost >= 0)
    }

    return false;
  }

  private get totalOfActions(): number {
    if (typeof this.action === 'string') {
      return this.billingPlan[this.action]?.cost ?? 0;
    }

    if (Array.isArray(this.action)) {
      return this.action.reduce((acc, curr) => acc + this.billingPlan[curr]?.cost ?? 0, 0);
    }

    return 0;
  }

  onAction(eventValue: any) {
    this.clicked.emit(eventValue);
  }
}
