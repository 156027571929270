export enum Skins {
  'FAMILY_FINDER' = 'FAMILY_FINDER',
  'FLEET_MANAGEMENT' = 'FLEET_MANAGEMENT',
  'GEO4' = 'GEO4',
  'CVTP' = 'CVTP',
}
export type Skin = keyof typeof Skins;
export enum Themes {
  GEO = 'GEO',
  GEOLOC = 'GEOLOC',
  WHITE = 'WHITE',
  FRESHVALE = 'FRESHVALE',
  UNLIMITED = 'UNLIMITED',
  INTELSHIELD = 'INTELSHIELD',
}
export type Theme = keyof typeof Themes;
