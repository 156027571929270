<div
  class="relative"
  [class.disabled-actions]="showLoader"
  [class.expanded-view]="expandedView"
  [class.fullscreen-view]="fullscreenView"
  #fullscreenContainer
>
  <div class="loader-background" *ngIf="showLoader">
    <app-link-analysis-loader></app-link-analysis-loader>
  </div>
  <div
    class="link-analysis-container"
    [class.low-opacity]="showLoader"
    [class.full-height]="playgroundView"
    [class.dark-theme]="darkMode"
  >
    <!-- Top filters -->
    <div *ngIf="showTopBarFilters" class="la-top-filters" [class.full-opacity]="showLoader">
      <app-la-top-filters
        [nodesCounter]="visibleNodesCounter"
        [linksCounter]="visibleLinksCounter"
        [playgroundView]="playgroundView"
        [casePlaygroundView]="casePlaygroundView"
        [selectedLayout]="selectedLayout"
        [caseView]="caseView"
        [target]="target"
        [selectedQuickFilterOption]="selectedQuickFilterOption"
        [graphData]="graphData"
        [playgroundEntities]="playgroundEntities"
        [socialProfileNodes]="socialProfileNodes"
        [enableShowPhotosButton]="enableShowPhotosButton"
        [showFilterSidebar]="showFilterSidebar"
        (showEntitiesPanel)="showEntitiesPanel = true"
        (chartLayoutChange)="chartLayout($event)"
        (quickFilterChange)="onQuickFilterChange($event)"
        (showAllGraphData)="showAllGraphData()"
        (selectedEntity)="onSelectedEntity($event)"
        (onDegreesSlider)="degrees($event, true)"
        (mutualFriendsSliderChange)="onMutualFriendsSliderUpdate($event)"
        (toggleLabels)="toggleLabels($event)"
        (togglePhotos)="togglePhotos()"
        (toggleMapView)="toggleMapView($event)"
        (toggleFilterSidebar)="toggleFilterSidebar($event)"
      ></app-la-top-filters>
    </div>

    <div class="inline-flex width-100 graph-filters-content">
      <!-- Display Buttons -->
      <button
        (click)="toggleTheme()"
        class="theme-btn"
        [matTooltip]="(darkMode ? 'Switch to light theme' : 'Switch to dark theme') | translate"
      >
        <mat-icon class="icon">settings_brightness</mat-icon>
      </button>
      <button
        *ngIf="!fullscreenView"
        (click)="expandedView = !expandedView"
        class="maximize-btn"
        [matTooltip]="(expandedView ? 'Minimize' : 'Maximize') | translate"
      >
        <mat-icon class="icon">{{ expandedView ? 'crop_7_5' : 'crop_landscape' }}</mat-icon>
      </button>
      <button
        (click)="toggleFullscreen()"
        class="fullscreen-btn"
        [matTooltip]="(fullscreenView ? 'Exit full screen' : 'Full screen') | translate"
      >
        <mat-icon class="icon">{{ fullscreenView ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
      </button>
      <button
        *ngIf="timebarData.length"
        (click)="minimizedTimebar = !minimizedTimebar"
        class="minimize-timebar-btn"
        [matTooltip]="(minimizedTimebar ? 'Open timebar' : 'Minimize timebar') | translate"
      >
        <mat-icon class="icon">{{ minimizedTimebar ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
      <!-- DISABLE UNTIL WE FIX THE ISSUE WITH Keylines 6.13.1 dependencies -->
      <!-- <button
        (click)="exportPdf()"
        class="export-btn"
        [matTooltip]="'Export graph' | translate"
      >
        <mat-icon class="icon">save_alt</mat-icon>
      </button> -->

      <div class="kl-graph" cdkDropList (cdkDropListDropped)="onEntityDrop($event)">
        <app-kl-components *ngIf="showGraph" (klReady)="klChartReady($event)">
          <app-kl-component
            klContainerClass="klchart klchart-timebar"
            [klOptions]="chartOptions"
            [ngStyle]="{ height: chartHeight + 'vh' }"
            klType="chart"
            (klEvents)="klChartEvents($event)"
            #chart
          >
          </app-kl-component>
          <app-kl-component
            [hidden]="!timebarData.length || minimizedTimebar"
            [ngStyle]="chartStyle"
            klType="timebar"
            klContainerClass="timebar-kl kltimebar"
            [klOptions]="timebarOptions"
            #timebar
          >
          </app-kl-component>
          <div class="kl-chart-matTooltip hidden" #chartTooltip></div>
          <div class="select-info-window hidden" #selectMultipleInfo>
            <img
              class="info-icon"
              src="{{ imagesPath }}link-analysis/information.svg"
              height="20"
            />
            <div class="text">
              {{
                'You can select multiple nodes by holding down Ctrl key and clicking on a node.'
                  | translate
              }}
            </div>
            <div class="text">{{ 'Then you have the ability to:' | translate }}</div>
            <li class="text">
              {{
                'move the selected nodes by dragging using the mouse or with the keyboard arrows.'
                  | translate
              }}
            </li>
            <li class="text">
              {{ 'filter out the selected nodes by pressing the Delete key.' | translate }}
            </li>
            <button mat-button (click)="closeInfoWindow()">{{ 'Close' | translate }}</button>
          </div>
          <div #contextMenu class="context-menu hidden">
            <div
              class="option"
              [class.disabled]="!option.enabled"
              *ngFor="let option of contextMenuOptions"
              (click)="onContextMenuAction(option.action)"
            >
              {{ option.name | translate }}
            </div>
          </div>
          <input
            class="input-label hidden"
            [placeholder]="inputLabelProperties.placeholderText"
            (keydown.enter)="onEnterNewItemLabel($event.target.value)"
            (keydown.esc)="hideNewItemLabel()"
            [ngStyle]="{ left: inputLabelProperties.left, top: inputLabelProperties.top }"
            #inputLabel
          />
        </app-kl-components>
        <div class="graph-legend center-element">
          <div
            *ngFor="let item of legendNodeTypes"
            class="inline-flex graph-legend-type"
            [class.hidden]="!item.show"
          >
            <img class="legend-icon" [src]="item.icon" height="24" />
            <div class="legend-text">{{ item.type | translate }}</div>
          </div>
        </div>

        <!-- Entities panel -->
        <app-la-custom-entities-panel
          *ngIf="playgroundView || casePlaygroundView"
          [graphData]="graphData"
          [showEntitiesPanel]="showEntitiesPanel"
          [node]="selectedNode"
          [contextMenuAction]="contextMenuAction"
          [caseTargets]="caseTargets"
          [linkNodesData]="linkNodesData"
          (hideEntitiesPanel)="showEntitiesPanel = false"
          (toggleLoader)="showLoader = $event"
          (onCreateCustomEntity)="finilizeNewEntityCreation($event)"
          (removeNodeAnimation)="removeNodeAnimation($event)"
          (onNewEntities)="onNewGraphItems($event)"
          (animateNode)="animateNode($event)"
          (setProperties)="setNodeProperties($event)"
          (setTimebarData)="setTimebarData($event)"
          (setItem)="setItem($event)"
          (removeItem)="removeItem($event)"
          (addToGraphData)="addToGraphData($event)"
        >
        </app-la-custom-entities-panel>
      </div>
      <div [hidden]="!showFilterSidebar" class="link-analysis-sidebar">
        <mat-tab-group class="la-tabs" (selectedTabChange)="onFilterTabChange($event)">
          <!-- Node filters -->
          <mat-tab label="{{ 'Nodes' | translate }}">
            <app-la-node-filters
              [allItems]="itemsForNodeFilters"
              [checkedItemsIds]="filteredNodeIds"
              (addToFilterData)="addToFilteredData($event)"
            ></app-la-node-filters>
          </mat-tab>
          <!-- Relation filters -->
          <mat-tab label="{{ 'Relations' | translate }}">
            <app-la-relation-filters
              [data]="graphDataDictionary"
              [(filteredTypes)]="filteredTypes"
              [(filteredSliderSections)]="filteredSliderSections"
            ></app-la-relation-filters>
          </mat-tab>
        </mat-tab-group>
        <div
          class="clickable la-remove-all-filters"
          (click)="showAllGraphData()"
          matTooltip="{{ 'Removes all filters, keeps new nodes from analyse' | translate }}"
        >
          {{ 'Remove filters' | translate }}
        </div>
        <div
          *ngIf="!(caseView || playgroundView)"
          class="clickable la-remove-all-filters"
          matTooltip="{{
            'Removes everything (including nodes from analyse) and reloads all target data'
              | translate
          }}"
          [swal]="reloadSwal"
        >
          {{ 'Reload' | translate }}
        </div>
        <button
          mat-button
          class="center-element la-update-graph-btn cp-btn-primary capitalize-none"
          (click)="filterGraph()"
        >
          {{ 'Update the graph' | translate }}
        </button>
      </div>
      <div *ngIf="showDetailsSidebar" class="link-analysis-sidebar">
        <app-node-details
          *ngIf="selectedNode"
          [target]="target"
          [node]="selectedNode"
          (newGraphItems)="onNewGraphItems($event)"
          (updateNodeData)="updateSelectedNodeData($event)"
          (updateNodeParent)="updateSelectedNodeParent($event)"
          (showAnimation)="handleNodeAnimation($event)"
          (queryPerformed)="addToPendingJobs($event)"
          [caseView]="caseView"
          [playgroundView]="playgroundView || casePlaygroundView"
          [selectedNodeOsintCompleted]="selectedNodeOsintCompleted"
        >
        </app-node-details>
        <app-link-details *ngIf="selectedLink" [link]="selectedLink"></app-link-details>
      </div>
    </div>
  </div>
</div>

<swal
  #reloadSwal
  [title]="'Reload chart with latest target data' | translate"
  [text]="
    'Do you wish to reload the chart? Any data created from analysing nodes will be removed'
      | translate
  "
  icon="warning"
  [showCancelButton]="true"
  [cancelButtonText]="'Cancel' | translate"
  [showCancelButton]="true"
  [confirmButtonText]="'Reload' | translate"
  (confirm)="reloadData()"
>
</swal>
