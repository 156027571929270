<div>
  <div class="header">
    <mat-icon class="close cursor-pointer" (click)="dialogRef.close()">close</mat-icon>
    <div class="title">
      <ng-content select="[header]"></ng-content>
    </div>
  </div>
  <div class="body">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
