import { CdkDrag } from '@angular/cdk/drag-drop';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import KeyLines, { Chart, Glyph, Link, Node } from '@trg-ui/link-analysis';
import { Profile } from 'datalayer/models/social';
import { OsintService } from 'datalayer/services/osint/osint.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataSource, EntityRelationType } from 'src/app/modules/data-layer/models/platform-models';
import { DeepOsintLink } from 'src/app/modules/link-analysis/shared/deep-osint-event.model';
import {
  DEFAULT_LINK_WIDTH,
  GlyphSize,
  LabelSize,
  linkTypes,
  linkTypeWeights,
  NodeData,
  nodeSize,
  nodeSubtypes,
  nodeTypes,
  nodeTypesColors,
  nodeTypeToColor,
  nodeTypeToIcons,
  relationTypes,
} from 'src/app/modules/link-analysis/shared/link-analysis.model';
import { BaseService } from 'src/app/services/base.service';
import { ImageService } from 'src/app/services/image/image.service';
import { TargetService } from 'src/app/services/target/target.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import {v4 as uuid} from 'uuid';
import { FileEntry } from '../../visual-investigation/models/text-analysis-uploader.model';
@Injectable({
  providedIn: 'root',
})
export class LinkAnalysisService extends BaseService {
  private imagesPath = 'assets/static/images/';
  private placeTypeDictionary = {
    LocatedAt: 'Current city',
    HomeLocation: 'Hometown',
    'Company location': 'Company location',
  };
  private facebookConnectionTypesDictionary = {
    [EntityRelationType.Friend]: linkTypes.FACEBOOK_FRIEND,
    [EntityRelationType.Tagged]: linkTypes.FACEBOK_TAGGED_IN_PHOTO,
    [EntityRelationType.Family]: linkTypes.FACEBOK_FAMILY,
    [EntityRelationType.Author]: linkTypes.FACEBOOK_TAGGED_IN_AUTHOR_POST,
    [EntityRelationType.Following]: linkTypes.FACEBOOK_FOLLOWING,
    [EntityRelationType.Follower]: linkTypes.FACEBOOK_FOLLOWER,
    SameLocation: linkTypes.FACEBOOK_SAME_PLACE,
    AttendSameInstitution: linkTypes.FACEBOOK_SAME_INSTITUTION,
    PhotoLiked: linkTypes.FACEBOOK_LIKED_PHOTO,
    PhotoCommented: linkTypes.FACEBOOK_COMMENTED_PHOTO,
    PostLiked: linkTypes.FACEBOOK_LIKED_POST,
    PostCommented: linkTypes.FACEBOOK_COMMENTED_POST,
    SignificantOther: linkTypes.FACEBOOK_SIGNIFICANT_OTHER,
    PostShared: linkTypes.FACEBOOK_SHARED_POST,
    PhotoShared: linkTypes.FACEBOOK_SHARED_PHOTO,
    MutualFriends: linkTypes.FACEBOOK_MUTUAL_FRIENDS,
  };
  private linkTypeToReadableText = {
    [linkTypes.FACEBOOK_FRIEND]: 'Facebook friend',
    [linkTypes.FACEBOK_TAGGED_IN_PHOTO]: 'Tagged in common Facebook photo(s)',
    [linkTypes.FACEBOK_FAMILY]: 'Facebook family',
    [linkTypes.FACEBOOK_SAME_PLACE]: 'Same hometown or current location',
    [linkTypes.FACEBOOK_SAME_INSTITUTION]: 'Attended same institution',
    [linkTypes.FACEBOOK_LIKED_PHOTO]: 'Liked photo(s)',
    [linkTypes.FACEBOOK_COMMENTED_PHOTO]: 'Commented photo(s)',
    [linkTypes.FACEBOOK_LIKED_POST]: 'Liked post(s)',
    [linkTypes.FACEBOOK_COMMENTED_POST]: 'Commented post(s)',
    [linkTypes.FACEBOOK_SIGNIFICANT_OTHER]: 'Significant Other',
    [linkTypes.FACEBOOK_SHARED_PHOTO]: 'Shared photo(s)',
    [linkTypes.FACEBOOK_TAGGED_IN_AUTHOR_POST]: 'Tagged in post',
    [linkTypes.FACEBOOK_FOLLOWER]: 'Facebook follower',
    [linkTypes.FACEBOOK_FOLLOWING]: 'Following on Facebook',
    [linkTypes.FACEBOOK_MUTUAL_FRIENDS]: 'Mutual Facebook friends',
  };

  phoneNumberUtil = PhoneNumberUtil.getInstance();

  constructor(
    protected router: Router,
    private translationService: TranslationService,
    protected snackBar: MatSnackBar,
    private imageService: ImageService,
    private targetService: TargetService,
    private osintService: OsintService,
    public titleCasePipe: TitleCasePipe,
    public datepipe: DatePipe
  ) {
    super(router, snackBar);
  }

  getChartTooltipText(item: Link): string {
    const tooltipDictionary = {
      [linkTypes.INCOMING_CALL]: `${item.d.id2} ${this.translationService.translate('received a call from')} ${
        item.d.id1
      } ${item.d.count} ${this.translationService.translate('time(s)')}`,
      [linkTypes.OUTGOING_CALL]: `${item.d.id1} ${this.translationService.translate('called')} ${item.d.id2} ${
        item.d.count
      } ${this.translationService.translate('time(s)')}`,
      [linkTypes.INCOMING_SMS]: `${item.d.id2} ${this.translationService.translate('received a message from')} ${
        item.d.id1
      } ${item.d.count} ${this.translationService.translate('time(s)')}`,
      [linkTypes.OUTGOING_SMS]: `${item.d.id1} ${this.translationService.translate('messaged')} ${item.d.id2} ${
        item.d.count
      } ${this.translationService.translate('time(s)')}`,
      [linkTypes.UNKNOWN]: this.translationService.translate('Interacted with'),
      [linkTypes.FACEBOOK_FRIEND]: this.translationService.translate('Facebook friends'),
      [linkTypes.INSTAGRAM_FOLLOWING]: item.a1
        ? this.translationService.translate('Following each other')
        : `${this.translationService.translate('Is following')} ${item.d.tooltipText}`,
      [linkTypes.TWITTER_FOLLOWING]: item.a1
        ? this.translationService.translate('Following each other')
        : `${this.translationService.translate('Is following')} ${item.d.tooltipText}`,
      [linkTypes.INSTAGRAM_FOLLOWER]: `${this.translationService.translate('Is followed by')} ${item.d.tooltipText}`,
      [linkTypes.TWITTER_FOLLOWER]: `${this.translationService.translate('Is followed by')} ${item.d.tooltipText}`,
      [linkTypes.FACEBOK_FAMILY]: this.translationService.translate('Facebook family'),
      [linkTypes.DEVICE]: `IMEI: ${item.id2} ${item.d.brand || ''} ${item.d.model || ''}`,
      [linkTypes.SIM]: this.translationService.translate('Connected with MSISDN'),
      [linkTypes.ORGANIZATION]: `${
        item.d.positionInOrganization
          ? item.d.positionInOrganization
          : item.d.degree || this.translationService.translate('Studied')
      } ${this.translationService.translate('at')} ${item.d.organizationName}
           ${item.d.startDate ? this.translationService.translate('from') : ''} ${item.d.startDate} ${
        item.d.endDate ? this.translationService.translate('to') : ''
      } ${item.d.endDate}`,
      [linkTypes.SOCIAL_PROFILE]: this.translationService.translate('Possibly owns this social profile'),
      [linkTypes.CASE_TARGET]: this.translationService.translate('Target is assigned to this case'),
      [linkTypes.LINKEDIN_EMPLOYEE]: this.translationService.translate('Company employee'),
      [linkTypes.FACEBOOK_GROUP]: this.translationService.translate('Facebook group member'),
      [linkTypes.FACEBOOK_GROUP_MEMBER]: `${this.translationService.translate('Facebook group member')}: ${
        item.d.facebookGroupName || ''
      }`,
      [linkTypes.PLACE]: this.translationService.translate(this.placeTypeDictionary[item.d.tooltipText]),
      [linkTypes.FACEBOK_TAGGED_IN_PHOTO]: `${this.translationService.translate('Tagged in')} ${
        item.d.count
      } ${this.translationService.translate('photo(s) together')}`,
      [linkTypes.FACEBOOK_SAME_PLACE]: `${this.translationService.translate('Same location')}: ${item.d.tooltipText}`,
      [linkTypes.FACEBOOK_SAME_INSTITUTION]: `${this.translationService.translate('Attended same institution')}: ${
        item.d.tooltipText
      }`,
      [linkTypes.FACEBOOK_LIKED_PHOTO]: `${this.translationService.translate('Liked')} ${
        item.d.count
      } ${this.translationService.translate('target photo(s)')}`,
      [linkTypes.FACEBOOK_COMMENTED_PHOTO]: `${this.translationService.translate(
        'Commented on a photo of the target'
      )} ${item.d.count} ${this.translationService.translate('time(s)')}`,
      [linkTypes.FACEBOOK_SIGNIFICANT_OTHER]: this.translationService.translate('Facebook Significant Other'),
      [linkTypes.FACEBOOK_LIKED_POST]: `${this.translationService.translate('Liked')} ${
        item.d.count
      } ${this.translationService.translate('target post(s)')}`,
      [linkTypes.FACEBOOK_COMMENTED_POST]: `${this.translationService.translate('Commented on a post of the target')} ${
        item.d.count
      } ${this.translationService.translate('time(s)')}`,
      [linkTypes.FACEBOOK_SHARED_POST]: `${this.translationService.translate('Shared')} ${
        item.d.count
      } ${this.translationService.translate('target post(s)')}`,
      [linkTypes.FACEBOOK_SHARED_PHOTO]: `${this.translationService.translate('Shared')} ${
        item.d.count
      } ${this.translationService.translate('target photo(s)')}`,
      [linkTypes.INSTANT_MESSAGING_PROFILE]: this.translationService.translate(
        'MSISDN is connected with this instant messaging profile'
      ),
      [linkTypes.LOCATED_AT]: this.translationService.translate('Located at'),
      [linkTypes.FACEBOOK_TAGGED_IN_AUTHOR_POST]: `${this.translationService.translate('Tagged in')} ${
        item.d.tooltipText
      } ${this.translationService.translate('post')}`,
      [linkTypes.CALLED]: this.translationService.translate('Called'),
      [linkTypes.SMSED]: this.translationService.translate('SMS'),
      [linkTypes.USED_BY]: this.translationService.translate('Used by'),
      [linkTypes.DATA]: this.translationService.translate('Data'),
      [linkTypes.FACEBOOK_FOLLOWER]: `${this.translationService.translate('Is followed by')} ${item.d.tooltipText}`,
      [linkTypes.FACEBOOK_FOLLOWING]: `${this.translationService.translate('Is following')} ${item.d.tooltipText}`,
      [linkTypes.FACEBOOK_MUTUAL_FRIENDS]: `${item.d.count} ${this.translationService.translate(
        'Mutual Facebook friends'
      )}`,
      [linkTypes.CALL_LOG_TOP_ASSOCIATE]: this.translationService.translate('Call log top associate'),
      [linkTypes.EMAIL]: this.translationService.translate('Connected with this email'),
      [linkTypes.PLACE]: this.translationService.translate('Connected with this location'),
      [linkTypes.GROUP_BELONGS_TO_PROFILE]: `${this.translationService.translate(
        'Profile'
      )} ${item.d?.tooltipText?.toLowerCase()}`,
      [linkTypes.POSTED_PHOTO]: `${this.translationService.translate('Profile is associated with this photo')}`,
      [linkTypes.POST_AUTHOR]: `${this.translationService.translate('Profile is associated with this post')}`,
      [linkTypes.LOCATION_PROBABILITY]: `${this.translationService.translate('Location probability')}`,
      [linkTypes.IMSI]: this.translationService.translate('Associated with'),
      [linkTypes.EXTRACTED_ENTITY]: this.translationService.translate('Extracted entity from file'),
      [linkTypes.EXTRACTED_EVENT]: this.translationService.translate('Extracted event from file.'),
      [linkTypes.INSTANT_MESSAGE]: 'View chat',
      [linkTypes.EMAIL_MESSAGE]: 'View chat'
    };
    return tooltipDictionary[item.d.type] || item.d.tooltipText || this.translationService.translate('Associated with');
  }

  public createNewNode(
    id: string,
    color: string,
    text: string,
    nodeData: any,
    size: nodeSize,
    glyphs: Glyph[],
    fs: number,
    image?: string
  ): Node {
    const node: Node = {
      type: 'node',
      id: id,
      u: image,
      ci: true,
      c: color,
      b: color,
      bw: 2,
      t: text,
      e: size,
      d: nodeData, // TODO: create a model for node data
      tc: false,
      g: glyphs.length ? glyphs : [],
      fc: '#3c4144',
      fs: fs,
      fi: {
        c: '#fff',
        t: KeyLines.getFontIcon(this.getNodeIcon(nodeData.type, nodeData.relation, nodeData.subtype)),
      },
    };
    return node;
  }

  public getGlyph(
    url: string,
    position: 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw' | number,
    animate = false,
    size = GlyphSize.MEDIUM,
    fillColor?: string,
    borderColor?: string,
    text?: string
  ): Glyph {
    return {
      u: `${this.imagesPath}${url}`,
      p: position,
      e: size,
      a: animate,
      c: fillColor,
      b: borderColor,
    };
  }

  public getIconGlyph(
    icon: string,
    position: 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw' | number,
    color?: string
  ): Glyph {
    return {
      p: position,
      e: 1.3,
      fi: {
        t: KeyLines.getFontIcon(icon),
        ff: 'Material Icons',
        c: color || '#666',
      },
    };
  }

  public parsePlatform(platform: string): relationTypes {
    const platforms = {
      FB: relationTypes.FACEBOOK,
      TW: relationTypes.TWITTER,
      IG: relationTypes.INSTAGRAM,
      IN: relationTypes.LINKEDIN,
      TT: relationTypes.TIKTOK,
      SK: relationTypes.SKYPE,
      TG: relationTypes.TELEGRAM,
      WA: relationTypes.WHATSAPP,
      TC: relationTypes.TRUECALLER,
      VB: relationTypes.VIBER,
      TIN: relationTypes.TINDER,
      WC: relationTypes.WECHAT,
      CI: relationTypes.CALLERID,
    };
    return platforms[platform];
  }

  public parseRelation(relation: EntityRelationType | string): linkTypes {
    const relations = {
      [EntityRelationType.Author]: linkTypes.POST_AUTHOR,
      [EntityRelationType.LocatedAt]: linkTypes.LOCATED_AT,
      [EntityRelationType.Friend]: linkTypes.FACEBOOK_FRIEND,
      'SignificantOther': linkTypes.FACEBOOK_SIGNIFICANT_OTHER,
      [EntityRelationType.Family]: linkTypes.FACEBOK_FAMILY,
      'Called': linkTypes.CALLED,
      'Smsed': linkTypes.SMSED,
      'ExtractedNamedEntity': linkTypes.EXTRACTED_ENTITY,
      'ExtractedEventEntity': linkTypes.EXTRACTED_EVENT,
    }
    return relations[relation];
  }

  public parseTextToNodeType(type: string): nodeTypes {
    const types = {
      company: nodeTypes.ORGANIZATION,
      education: nodeTypes.ORGANIZATION,
      email: nodeTypes.EMAIL,
      file: nodeTypes.FILE,
      group: nodeTypes.FACEBOOK_GROUP,
      imei: nodeTypes.PHONE,
      imsi: nodeTypes.IMSI,
      location: nodeTypes.LOCATION,
      msisdn: nodeTypes.MSISDN,
      namedentity: nodeTypes.NAMED_ENTITY,
      person: nodeTypes.PERSON,
      per: nodeTypes.PERSON,
      photo: nodeTypes.IMAGE,
      post: nodeTypes.POST,
      profile: nodeTypes.SOCIAL_PROFILE,
      hashtag: nodeTypes.HASHTAG,
      org: nodeTypes.ORGANIZATION,
      gpe: nodeTypes.PLACE,
      loc: nodeTypes.PLACE,
      url: nodeTypes.URL,
      'social profile': nodeTypes.SOCIAL_PROFILE,
      'evententity': nodeTypes.EVENT_ENTITY
    }
    return types[type.toLocaleLowerCase()] || nodeTypes.OTHER;
  }

  public getConnectionType(platform: string, relationType: string): linkTypes {
    if (relationType === EntityRelationType.Plain) {
      return linkTypes.SOCIAL_PROFILE;
    }
    switch (platform) {
      case DataSource.Facebook:
        return this.facebookConnectionTypesDictionary[relationType] || null;
      case DataSource.Twitter:
        return relationType === EntityRelationType.Follower ? linkTypes.TWITTER_FOLLOWER : linkTypes.TWITTER_FOLLOWING;
      case DataSource.Instagram:
        return relationType === EntityRelationType.Follower
          ? linkTypes.INSTAGRAM_FOLLOWER
          : linkTypes.INSTAGRAM_FOLLOWING;
      case DataSource.LinkedIn:
        return linkTypes.LINKEDIN_EMPLOYEE;
      default:
        break;
    }
  }

  // copyrights for this cool function --> Kyriakos ;)
  public transformLinkWeight(
    occurences: number,
    previousMaxWeight: number,
    previousMinWeight: number,
    maximunWeight: number,
    minWeight: number
  ): number {
    const prevRange = previousMaxWeight - previousMinWeight;
    let value;
    let range;
    if (prevRange === 0) {
      value = minWeight;
    } else {
      range = maximunWeight - minWeight;
      value = ((occurences - previousMinWeight) * range) / prevRange + minWeight;
    }
    return value;
  }

  public getImportanceNodeTooltipText(reasons: string[]): string {
    let text: string;
    reasons.forEach((reason) => {
      text = text ? `${text}, ${this.linkTypeToReadableText[reason]}` : this.linkTypeToReadableText[reason];
    });
    return text;
  }

  public getNodeLabelGlyph(text: string): Glyph {
    return {
      p: 'ne',
      c: '#292826',
      w: true,
      t: this.translationService.translate(text),
      b: '#292826',
      fb: false,
    };
  }

  // profile from mongo
  public getSocialConnectionNode(profile: Profile, parentId?: string, color?: string): Node {
    const platform: relationTypes = this.parsePlatform(profile.source);
    let name = profile.name === 'None None' ? profile.profileId : profile.name;
    const data = this.getSocialProfileNodeData(null, parentId, profile);
    return this.createNewNode(
      profile.profileId.toLowerCase(),
      color || nodeTypesColors.SOCIAL_PROFILE,
      name,
      data,
      nodeSize.XSMALL,
      [this.getGlyph(`link-analysis/${platform}.svg`, 120, false, GlyphSize.SMALL)],
      LabelSize.MEDIUM
    );
  }

  public getSocialConnectionLink(
    linkType: linkTypes,
    tooltipText: string,
    mainProfileId: string,
    profileId: string,
    color: string
  ): Link {
    const followerTypes = [linkTypes.INSTAGRAM_FOLLOWER, linkTypes.TWITTER_FOLLOWER, linkTypes.FACEBOOK_FOLLOWER];
    const id1 = followerTypes.includes(linkType) ? profileId : mainProfileId;
    const id2 = followerTypes.includes(linkType) ? mainProfileId : profileId;
    return {
      type: 'link',
      id: `${id1}_${id2}_${linkType}`,
      id1,
      id2,
      c: color,
      a2: true,
      a1: linkType === linkTypes.FACEBOOK_FRIEND || linkType === linkTypes.FACEBOK_FAMILY ? true : false,
      d: {
        type: linkType,
        tooltipText,
        weight: linkTypeWeights[linkType],
      },
      w: DEFAULT_LINK_WIDTH,
    };
  }

  public getLink(
    id1: string,
    id2: string,
    linkType: linkTypes,
    bi: boolean,
    color: string,
    tooltipText?: string
  ): Link {
    const link: Link = {
      type: 'link',
      id: `${id1}_${id2}_${linkType}`,
      id1,
      id2,
      c: color,
      a2: true,
      a1: bi,
      d: {
        type: linkType,
        weight: linkTypeWeights[linkType],
      },
      w: DEFAULT_LINK_WIDTH,
      fc: '#3c4144'
    };
    if (tooltipText) {
      link.d['tooltipText'] = tooltipText;
    }
    return link;
  }

  public removeEntityTypeFromId(id: string) {
    return id.substr(id.indexOf('/') + 1, id.length);
  }

  public getNodeIdsPerType(chart: Chart, type: nodeTypes): string[] {
    const nodeIds: string[] = [];
    chart.each({ type: 'node' }, (item) => {
      if (item.d.type === type) {
        nodeIds.push(item.id);
      }
    });
    return nodeIds;
  }

  // profile from arango
  public getProfileNode(profile: Profile, fileId: string) {
    const relation = this.parsePlatform(profile.source);
    const nodeData = this.getSocialProfileNodeData(null, null, profile);
    return this.createNewNode(
      `${profile.sourceEntity.id}@${relation}`,
      nodeTypesColors.SOCIAL_PROFILE,
      profile.name,
      nodeData,
      nodeSize.XSMALL,
      [this.getGlyph(`link-analysis/${relation}.svg`, 'e')],
      LabelSize.MEDIUM,
      this.imageService.getPhotoUrl(fileId, true) as string
    );
  }

  public getNodeTooltipText(
    chart: Chart,
    node: Node,
    subItem: { index: number; subId: string | number; type: string }
  ): string {
    if (node.g && node.g.findIndex((glyph) => glyph.u?.includes('load')) > -1) {
      return `${this.translationService.translate('Loading')}...`;
    } else if (chart.combo().isCombo(node.id)) {
      return this.translationService.translate('Double click to expand/collapse node');
    } else if (node.d.importanceDetails) {
      return this.getImportanceNodeTooltipText(node.d.importanceDetails);
    } else if (node.d.type === nodeTypes.LOCATION && node.d.timestamps) {
      //   formattedTimestamps = node.d.timestamps.map(date => format(new Date(date), 'dd/MM/yyyy HH:mm'))
      return `${this.translationService.translate('Device:')} ${node.d.nodeLocationId}
      ${this.translationService.translate('was located at')} ${node.pos.lat.toFixed(3)}/${node.pos.lng.toFixed(3)}
      ${this.translationService.translate('on')} ${node.d.timestamps.join('\n')}`;
    } else if (subItem.type === 'glyph' && node.g && node.g.findIndex((glyph) => glyph.t === '+') > -1) {
      return this.translationService.translate('Drag to link with another node');
    } else {
      // return this.translationService.translate('Select node to view details');
      return '';
    }
  }

  public getLinkTypesFromNodes(fromNodeType: nodeTypes, toNodeType: nodeTypes): linkTypes[] {
    if (fromNodeType === nodeTypes.MSISDN && toNodeType === nodeTypes.PERSON) {
      return [linkTypes.SIM];
    } else if (fromNodeType === nodeTypes.SOCIAL_PROFILE && toNodeType === nodeTypes.PERSON) {
      return [linkTypes.SOCIAL_PROFILE];
    } else if (
      (fromNodeType === nodeTypes.SOCIAL_PROFILE && toNodeType === nodeTypes.MSISDN) ||
      (fromNodeType === nodeTypes.MSISDN && toNodeType === nodeTypes.SOCIAL_PROFILE)
    ) {
      return [linkTypes.SOCIAL_PROFILE];
    } else if (
      (fromNodeType === nodeTypes.LOCATION && toNodeType === nodeTypes.MSISDN) ||
      (fromNodeType === nodeTypes.MSISDN && toNodeType === nodeTypes.LOCATION)
    ) {
      return [linkTypes.LOCATED_AT];
    } else if (fromNodeType === nodeTypes.MSISDN && toNodeType === nodeTypes.MSISDN) {
      return [linkTypes.CALLED, linkTypes.SMSED, linkTypes.DATA];
    } else if (
      (fromNodeType === nodeTypes.MSISDN && toNodeType === nodeTypes.PHONE) ||
      (fromNodeType === nodeTypes.PHONE && toNodeType === nodeTypes.MSISDN)
    ) {
      return [linkTypes.USED_BY];
    } else {
      return [linkTypes.UNKNOWN];
    }
  }

  public isValidEntityId(id: string, newEntity: Node, existingData: (Node | Link)[]): Observable<boolean | string> {
    const existingItemIndex = existingData.findIndex((item) => item.id === id);
    if (existingItemIndex > -1) {
      this.showMessage(this.translationService.translate('Entity already exists. Please enter a different value.'));
      return of(false);
    }
    switch (newEntity.d.type) {
      case nodeTypes.SOCIAL_PROFILE:
        return this.getSocialProfileIdFromUrl(id);
      case nodeTypes.MSISDN:
        return of(this.validTelno(id));
      case nodeTypes.LOCATION:
        return of(this.validCoordinates(id));
      case nodeTypes.PHONE:
        return of(this.validImei(id));
      default:
        break;
    }
  }

  private validTelno(telno: string): boolean {
    const validPhone = this.targetService.getValidPhone(this.phoneNumberUtil, telno);
    if (validPhone) {
      return true;
    } else {
      this.showMessage(this.translationService.translate('Enter a valid number'));
      return false;
    }
  }

  private validCoordinates(coordinatesString: string): boolean {
    const coords = coordinatesString.split(',');
    const lat = +coords[0];
    const lon = +coords[1];
    if (lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180) {
      return true;
    } else {
      this.showMessage(this.translationService.translate('Enter valid coordinates'));
      return false;
    }
  }

  private validImei(imei: string): boolean {
    if (imei.length === 15 && !isNaN(+imei)) {
      return true;
    } else {
      this.showMessage(this.translationService.translate('Invalid IMEI provided'));
      return false;
    }
  }

  public getSocialProfileIdFromUrl(url: string): Observable<string> {
    const platform = this.getRelationTypeFromUrl(url);
    if (!platform) {
      this.showMessage(this.translationService.translate('Invalid URL. Social network not supported'));
      return of('');
    }
    return this.getProfileIdCallPerPlatform(platform, url);
  }

  public getRelationTypeFromUrl(url: string): relationTypes {
    const platforms: relationTypes[] = [
      relationTypes.FACEBOOK,
      relationTypes.TWITTER,
      relationTypes.LINKEDIN,
      relationTypes.INSTAGRAM,
    ];
    return platforms.find((item) => url.includes(item.toLowerCase()));
  }

  private getProfileIdCallPerPlatform(platform: relationTypes, url: string): Observable<string> {
    switch (platform) {
      case relationTypes.FACEBOOK:
        return this.osintService.queryFacebookPlatform(url).pipe(
          switchMap((fbId) => {
            if (!fbId) {
              this.showMessage(this.translationService.translate('Something went wrong. Please try again.'));
              return throwError(`Seems like no facebook id was provided. Received id is "${fbId}"`);
            }
            return of(fbId);
          })
        );
      case relationTypes.TWITTER:
        return this.osintService.queryTwitterPlatform(url);
      case relationTypes.INSTAGRAM:
        return this.osintService.queryInstagramPlatform(url);
      case relationTypes.LINKEDIN:
        return this.osintService.queryLinkedInPlatform(url);
    }
  }

  public createCustomNode(x: number, y: number, item: CdkDrag): Node {
    const nodeId = item.data.type === nodeTypes.FILE_UPLOADER ? uuid() : 'temporaryId';
    const nodeData = {
      type: item.data.type,
      customEntity: true,
    };
    const node = this.createNewNode(
      nodeId,
      item.data.color,
      '',
      nodeData,
      nodeSize.XSMALL,
      [],
      LabelSize.MEDIUM
    );
    node.b = '#c8c8c8';
    node.x = x;
    node.y = y;
    return node;
  }

  public getDeepOsintEventLinkData(connectionType: linkTypes, rawData: DeepOsintLink): any {
    const linkData: any = {
      type: connectionType,
      importanceScore: rawData.importance,
    };
    switch (connectionType) {
      case linkTypes.FACEBOK_TAGGED_IN_PHOTO:
        linkData.count = rawData.taggedCount || rawData.count;
        linkData.media = rawData.taggedPhotos; // array of file ids
        break;
      case linkTypes.FACEBOOK_LIKED_PHOTO:
        linkData.count = rawData.likedPhotosCount || rawData.count;
        linkData.media = rawData.likedPhotos; // array of file ids
        break;
      case linkTypes.FACEBOOK_COMMENTED_PHOTO:
        linkData.count = rawData.commentedPhotosCount || rawData.count;
        linkData.media = rawData.commentedPhotos; // array of file ids
        break;
      case linkTypes.FACEBOOK_SAME_PLACE:
        linkData.tooltipText = rawData.location[0];
        break;
      case linkTypes.FACEBOOK_SAME_INSTITUTION:
        linkData.tooltipText = rawData.education[0];
        break;
      case linkTypes.FACEBOOK_LIKED_POST:
        linkData.count = rawData.likedPostsCount || rawData.count;
        linkData.media = rawData.likedPosts; // array of file ids
        break;
      case linkTypes.FACEBOOK_COMMENTED_POST:
        linkData.count = rawData.commentedPostsCount || rawData.count;
        linkData.media = rawData.commentedPosts; // array of file ids
        break;
      case linkTypes.FACEBOOK_SHARED_PHOTO:
        linkData.count = rawData.sharedPhotosCount || rawData.count;
        linkData.media = rawData.sharedPhotos; // array of file ids
        break;
      case linkTypes.FACEBOOK_SHARED_POST:
        linkData.count = rawData.sharedPostsCount || rawData.count;
        linkData.media = rawData.sharedPosts; // array of file ids
        break;
      case linkTypes.FACEBOOK_MUTUAL_FRIENDS:
        linkData.count = rawData.mutualFriendsCount;
        linkData.mutualFriends = rawData.mutualFriends; // array of facebook friends profiles
        break;
    }
    return linkData;
  }

  public getSocialProfileNodeData(
    targetId: string | null,
    parent: string | null,
    profile: Profile | Partial<Profile>,
    image?: string
  ): NodeData {
    return {
      type: nodeTypes.SOCIAL_PROFILE,
      parent: parent || profile.sourceEntity?.parentId?.toLowerCase(),
      targetId,
      label: profile.name === 'None None' ? profile.profileId : profile.name,
      userId: profile.profileId?.toLowerCase(),
      username: profile.username || profile.profileId?.toLowerCase(),
      url: profile.url,
      image: image || (this.imageService.getPhotoUrl(profile.image?.url, true) as string),
      relation: this.parsePlatform(profile.source),
      isPublic: profile.isPublic,
      verified: profile.verified,
    };
  }

  public getNodeIcon(nodeType: nodeTypes, relation: relationTypes, subtype: nodeSubtypes): string {
    if (nodeTypeToIcons[nodeType]) {
      return nodeTypeToIcons[nodeType];
    } else {
      switch (nodeType) {
        case nodeTypes.SOCIAL_PROFILE:
          return `gio-${relation}`;
        case nodeTypes.ORGANIZATION:
          return subtype === nodeSubtypes.EDUCATION ? 'gio-university' : 'gio-workplace';
        case nodeTypes.INSTANT_MESSAGING:
          return `gio-${relation}`;
        case nodeTypes.PROBABILITY:
          return subtype === nodeSubtypes.HOME_LOCATION ? 'gio-home' : 'gio-workplace';
        default:
          return 'gio-inv-loading';
      }
    }
  }

  public getNormalizedNodeSize(size: number): number {
    if (size > 25) {
      size = 11;
    } else if (size > 18) {
      size = 9;
    } else if (size > 12) {
      size = 8;
    }
    return size;
  }

  public formatNewEntityDataBasedOnType(id: string, newItem: Node, profileId: string): Node {
    if (!newItem) {
      return;
    }
    newItem.d = { ...newItem.d, label: id };
    if (newItem.d.type === nodeTypes.SOCIAL_PROFILE) {
      id = profileId;
      newItem.d = { ...newItem.d, username: profileId, url: id, relation: this.getRelationTypeFromUrl(id) };
    }
    return this.createNewNode(
      id,
      nodeTypeToColor[newItem.d.type],
      id,
      newItem.d,
      nodeSize.XSMALL,
      [],
      LabelSize.MEDIUM
    );
  }

  public getPropabilityLocationNodes(
    identity: string,
    subtype: nodeSubtypes,
    probability: number,
    lat: number,
    lng: number,
    color: string
  ): Node {
    const label = subtype === nodeSubtypes.HOME_LOCATION ? 'Home' : 'Work';
    const nodeData = {
      type: nodeTypes.PROBABILITY,
      subtype,
      label: this.translationService.translate(label)
    };
    const node = this.createNewNode(
      identity,
      color,
      this.translationService.translate(label),
      nodeData,
      nodeSize.XSMALL,
      [{ w: true, p: 'ne', c: '#fff', fc: color, b: color, t: `${Math.round(probability * 100)}%`, e: 1.5}],
      LabelSize.MEDIUM
    );
    node.pos = { lat, lng };
    return node;
  }

  public getCallLogsLinkLabel(link: Link): string {
    const typeText = link.d.type === linkTypes.CALLED ? this.translationService.translate('Call') : 'SMS';
    const times = `${link.d.dates.length} ${this.translationService.translate(link.d.dates.length === 1 ? 'time' : 'times')}`
    return `${typeText}\n${times}`
  }

  public createFileNode(file?: FileEntry, fileId?: string, fileName?: string, uploadedOn?: Date): Node {
    let nodeFileName: string;
    if(file) {
      nodeFileName = file.file ? file.file.name : file.filePath;
    }else {
      nodeFileName = fileName;
    }
    let nodeFileId = file?.fileId || fileId;
    let uploadedAt = uploadedOn || Date.now();
    const node = this.createNewNode(
      nodeFileId,
      nodeTypesColors.FILE,
      nodeFileName,
      {
        type: nodeTypes.FILE,
        label: nodeFileName,
        uploadedAt,
      }, nodeSize.XSMALL,
      [],
      LabelSize.MEDIUM
    );
    return node;
  }
}
