import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {faCheckCircle, faCircleNotch} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'intellectus-state-indicator',
    templateUrl: './state-indicator.component.html',
    styleUrls: ['./state-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateIndicatorComponent {
    @Input() isLoading = false;
    @Input() stateText = '';
    protected readonly faCircleNotch = faCircleNotch;
    protected readonly faCheckCircle = faCheckCircle;
}