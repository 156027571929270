<p *ngIf="showMessageWithOldChannel; else simpleMessages" class="body-desc">
  {{
    userSelectionMessage | translate
  }}:
  &ensp;
  <strong>
    {{ otpRecipient ? otpRecipient : otpChannel === channels.Email ? currentUser?.email : currentUser?.otpPhone }}
  </strong>
</p>

<ng-template #simpleMessages>
  <p class="body-desc">
    {{ 'We sent a verification code to the ' + otpChannelLabel[otpChannel] + ':' | translate }}
    &ensp;
    <strong>
      {{ otpRecipient ? otpRecipient : otpChannel === channels.Email ? currentUser?.email : currentUser?.otpPhone }}
    </strong>
  </p>
</ng-template>

<div class="form-wrp">
  <div class="field-group">
    <label>{{ 'Enter the code received via ' + (otpChannel === channels.Email ? 'email' : 'SMS') | translate }}</label>
    <div class="input-style-round relative">
      <input
        class="assistant-font"
        matInput
        [type]="visibility ? 'text' : 'password'"
        [formControl]="otpControl"
        required
      />

      <mat-icon class="icon eye-icon" (click)="this.visibility = !this.visibility">
        {{ this.visibility ? 'visibility_off' : 'visibility' }}</mat-icon
      >
    </div>
  </div>
  <mat-error class="activate-otp-modal-error" *ngIf="errorMsg">{{ errorMsg | translate }}</mat-error>
</div>
