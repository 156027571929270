<app-base-modal-component
  [headerDetails]="headerDetails"
  (onPushBack)="pushBack($event)"
  (onClosePressed)="closeParent($event)"
  class="height-100"
>
<ng-container ngProjectAs="coverContent">
  <div *ngIf="imageUrl" class="image-details-dialog">
    <img class="blur-img" [src]="imageUrl" appFallbackImg="assets/static/images/no_photo.svg" />
    <img class="bg-img" [src]="imageUrl" appFallbackImg="assets/static/images/no_photo.svg" />
  </div>
</ng-container>

  <ng-container ngProjectAs="detailsContent">
    <div class="related-people-details">
      <div class="col-12 col-md-12 base-modal-description">
        <div *ngIf="profile.name" class="item">
          <span class="col-md-3 item-title"> {{ 'Name' | translate }}: </span>
          <span class="col-md-9 item-description">
            {{ profile.name }}
          </span>
        </div>
        <div *ngIf="profile.username" class="item">
          <span class="col-md-3 item-title"> {{ 'Username' | translate }}: </span>
          <span class="col-md-9 item-description">
            {{ profile.username }}
          </span>
        </div>
        <div *ngIf="profile.url" class="item">
          <span class="col-md-3 item-title"> {{ 'Url' | translate }}: </span>
          <div class="col-md-9 item-description icon" (click)="navigateToUrl(this.profile.url)">
            <img class="platform-image clickable" [src]="platformImages[this.profile.source]" />
            {{ profile.url }}
          </div>
        </div>
        <div *ngIf="profile.telno" class="item">
          <span class="col-md-3 item-title"> {{ 'MSISDN' | translate }}: </span>
          <span class="col-md-9 item-description">
            {{ profile.telno }}
          </span>
        </div>
        <ng-container *ngIf="profile.source === 'IG'">
          <div class="item">
            <span class="col-md-3 item-title"> {{ 'Verified' | translate }}: </span>
            <span class="col-md-9 item-description">
                {{ !profile.verified ? 'No' : 'Yes' | translate }}
            </span>
          </div>
          <div class="item">
            <span class="col-md-3 item-title"> {{ 'Private profile' | translate }}: </span>
            <span class="col-md-9 item-description">
              <ng-container *ngIf="profile.isPublic; else privateProfile">
                {{ 'No' | translate }}
                <img class="value-icon" [src]="'assets/static/images/lock-open.svg'" />
              </ng-container>
              <ng-template #privateProfile>
                {{ 'Yes' | translate }}
                <img class="value-icon" [src]="'assets/static/images/lock.svg'" />
              </ng-template>
            </span>
          </div>
        </ng-container>
        <div *ngIf="profile.relationCategory" class="item">
          <span class="col-md-3 item-title"> {{ 'Relation' | translate }}: </span>
          <span class="col-md-9 item-description"> {{ profile.relationCategory | titlecase}}</span>
        </div>
        <div *ngIf="profile.subRelationCategory" class="item">
          <span class="col-md-3 item-title"> {{ 'Sub relation' | translate }}: </span>
          <span class="col-md-9 item-description"> {{ profile.subRelationCategory | titlecase}}</span>
        </div>
        <div *ngIf="profile.totalCalls" class="item">
          <span class="col-md-3 item-title"> {{ 'Calls' | translate }}</span>
          <span class="col-md-9 item-description"> {{ profile.totalCalls }}</span>
        </div>
        <div *ngIf="profile.totalSms" class="item">
          <span class="col-md-3 item-title"> {{ 'SMS' | translate }}</span>
          <span class="col-md-9 item-description"> {{ profile.totalSms }}</span>
        </div>
      </div>
      <div class="actions col-12 col-md-12" mat-dialog-actions>
        <button class="cp-btn-primary" *ngIf="!profile?.profileToTargetInfo?.targetId && (profile.username || profile.url || profile.telno)" mat-button (click)="addToTarget()">
          {{ 'Add to target' | translate }}
        </button>
        <button class="cp-btn-primary" *ngIf="profile?.profileToTargetInfo?.targetId" mat-button (click)="navigateToTarget(profile)">
          {{ 'Open target profile' | translate }}
        </button>
        <app-credit-cost-button *ngIf="profile.username || profile.url || profile.telno"
          [action]="'advancedOsint'"
          [buttonText]="'Advanced Webint'"
          [active]="true"
          [stylingClasses]="'cp-btn-ghost cp-search-btn advanced-btn'"
          (clicked)="advancedOsint()"
        ></app-credit-cost-button>
        <app-credit-cost-button *ngIf="profile.name && profile.relationCategory"
          [action]="'intelSearch'"
          [buttonText]="'Investigate lead'"
          [active]="true"
          [stylingClasses]="'cp-btn-primary cp-search-btn advanced-btn'"
          (clicked)="investigateLead()"
        ></app-credit-cost-button>
      </div>
    </div>
    <div *ngIf="profile.sourceEntity?.createdAt" class="query-date assistant-font">
      {{ 'Query date' | translate }}:
      {{ profile.sourceEntity?.createdAt | date: 'dd/MM/yyyy HH:mm' }}
    </div>
  </ng-container>
</app-base-modal-component>
