import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/services/user/user.model';
@Component({
  selector: 'app-reset-password-admin',
  templateUrl: './reset-password-admin.component.html',
  styleUrls: ['./reset-password-admin.component.scss']
})
export class ResetPasswordAdminComponent extends BaseComponent implements OnInit {
  onReset: EventEmitter<User> = new EventEmitter<User>();

  constructor(public dialogRef: MatDialogRef<ResetPasswordAdminComponent>, @Inject(MAT_DIALOG_DATA) public user: User) {
    super();
  }

  ngOnInit() {}

  onResetPassword() {
    this.onReset.emit(this.user);
  }
}
