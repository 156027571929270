<app-dialog class="request-cl-dialog">
  <div class="header-inner" header>
    <button mat-icon-button matTooltip="{{ 'Back' | translate }}" (click)="toggleScreens()" *ngIf="!isFormScreen">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <span class="title">
      {{ clDialogTitle }}
    </span>
  </div>

  <div body class="flex-wrp col-md-12">
    <div class="request col-md-12" [@slideInOut]="isFormScreen" [hidden]="!isFormScreen">
      <p>
        {{ clDialogSubtitle }}:
      </p>
      <app-request-cl-form
        [hidePeriodsSelection]="hasOnlyUploadCapabilities"
        [hidePeriodsNote]="hasUploadCapabilities"
        [disablePeriodsSelection]="disablePeriodsSelection$"
        [enableImsiUploads]="enableIMSICallLogs"
        [availableClOptions]="data.clParameters"
        [editable]="editable"
        (formValueAndStateChange)="onFormValueAndStateChange($event)"
      >
      </app-request-cl-form>
    </div>
    <div class="upload col-md-12" [@slideInOut]="isFormScreen" [hidden]="isFormScreen">
      <app-cl-upload
       [targetId]="data.uploadClArgs?.targetId"
       [requestId]="data.uploadClArgs?.requestCallLogId"
       [selectedParameter]="formValueAndState?.selectedParameter"
       (uploadFinished)="onUploadFinished($event)"
      ></app-cl-upload>
    </div>
  </div>

  <div footer>
    <div class="btn-wrp flex-hor-center">
      <app-credit-cost-button
        *ngIf="isFormScreen && !hasOnlyUploadCapabilities && enabledNewCallLogsRequest"
        [action]="requestAction"
        [buttonText]="(clInProgress | async) ? 'Call log pending' : 'Request call log'"
        [stylingClasses]="'cp-btn-primary cp-search-btn active'"
        (clicked)="requestCallLog()"
        [active]="allowRequest | async"
      ></app-credit-cost-button>

      <app-credit-cost-button *ngIf="hasUploadCapabilities && isFormScreen" [action]="uploadAction" [buttonText]="'Upload call log'"
        [stylingClasses]="'cp-btn-ghost cp-search-btn'" (clicked)="toggleScreens()" [active]="formValueAndState.isFormValid">
      </app-credit-cost-button>
    </div>
  </div>
</app-dialog>
