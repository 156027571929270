<ng-template
  #proximity
  let-near="near"
  let-subject="subject"
  let-accuracy="accuracy"
  let-timestamp="timestamp"
>
  <img src="assets/static/images/gps.svg" />
  <span>
    <b>{{ subject }}</b>
    {{ 'was found near' | translate }}&nbsp;{{ near }}&nbsp;{{ 'in' | translate }}&nbsp;{{
      accuracy
    }}km .
  </span>
</ng-template>

<ng-template
  #geofenceCountry
  let-inside="inside"
  let-countryCode="countryCode"
  let-subject="subject"
  let-timestamp="timestamp"
>
  <img src="assets/static/images/gps.svg" />
  <span>
    <b>{{ subject }}</b>
    {{ (inside ? 'entered' : 'left') | translate }}&nbsp;{{ countryCode | country }}.
  </span>
</ng-template>

<ng-template
  #gamification
  let-subject="subject"
  let-query="query"
  let-timestamp="timestamp"
  let-id="id"
>
  <img src="assets/static/images/gps.svg" />
  <span
    >{{ 'Possible location for' | translate }}&nbsp;<b>{{ subject }}</b
    >.&nbsp;{{ 'Resubmit' | translate }}?</span
  >
  <mat-icon
    class="clickable notification-new-query"
    matTooltip="{{ 'New Query' | translate }}"
    (click)="resubmitQuery(query, id)"
    >done</mat-icon
  >
  <mat-icon
    class="clickable notification-dismiss"
    matTooltip="{{ 'Clear' | translate }}"
    (click)="markAsRead(id)"
    >clear
  </mat-icon>
</ng-template>

<ng-template #emergency let-subject="subject" let-timestamp="timestamp">
  <img src="assets/static/images/emergency-notification.svg" />
  <span class="emergency-text">{{ 'Emergency' | translate }}!</span>&nbsp;<b>{{ subject }}</b>
  {{ 'is on emergency' | translate }}!
</ng-template>

<ng-template
  #geoqueryArea
  let-inside="inside"
  let-area="area"
  let-subject="subject"
  let-timestamp="timestamp"
>
  <img src="assets/static/images/gps.svg" />
  <span>
    <b>{{ subject }}</b>
    {{ 'was found' | translate }}&nbsp; {{ (inside ? 'inside' : 'outside') | translate }}&nbsp;{{
      area
    }}
    .
  </span>
</ng-template>

<ng-template
  #geoqueryScheduleEnded
  let-endedAt="endedAt"
  let-id="id"
  let-subject="subject"
  let-timestamp="timestamp"
>
  <img src="assets/static/images/target-monitoring.svg" hue="black" />
  <span>
    <b>{{ subject }}</b
    >&nbsp;
    {{ 'scheduled task has finished' | translate }}
    !
  </span>
</ng-template>

<ng-template
  #geoqueryLogged
  let-loggedOn="loggedOn"
  let-subject="subject"
  let-timestamp="timestamp"
>
  <img src="assets/static/images/gps.svg" />
  <span>
    <b>{{ subject }}</b
    >&nbsp;
    <b>{{ (loggedOn ? 'was turned on' : 'was turned off') | translate }}.</b>
  </span>
</ng-template>

<ng-template #geoqueryLogonStatusOff let-subject="subject" let-timestamp="timestamp">
  <img src="assets/static/images/gps.svg" />
  <span>
    <b>{{ subject }}</b
    >&nbsp;
    <b>{{ 'is Offline. Last Known Location found' | translate }}.</b>
  </span>
</ng-template>

<ng-template #push let-subject="notificationText" let-timestamp="timestamp">
  <img src="assets/static/images/information.svg" />
  <span>
    <b>{{ notificationText }}.</b>
  </span>
</ng-template>

<ng-template #releasenotes let-subject="notificationText" let-text="text" let-timestamp="timestamp">
  <img src="assets/static/images/information.svg" />
  <span>
    <b>{{ notificationText }} </b>
  </span>
  <mat-icon
    class="clickable release-notes"
    matTooltip="{{ 'Show Release Notes' | translate }}"
    (click)="showPopup(notificationText, text)"
    >new_releases</mat-icon
  >
</ng-template>

<ng-template #locationHistoryNotFound let-timestamp="timestamp">
  <img src="assets/static/images/information.svg" />
  <span>
    <b>{{ 'Location History Not Found' | translate }}.</b>
  </span>
</ng-template>

<ng-template #locationHistoryNotFoundTellcell let-queryArgs="queryArgs" let-timestamp="timestamp">
  <img src="assets/static/images/information.svg" />
  <span>
    <b
      >{{ 'Location History Not Found' | translate }}.&nbsp;{{
        'Request from Support' | translate
      }}?</b
    >
  </span>
  <mat-icon
    class="clickable notification-new-query"
    matTooltip="{{ 'Location History Request' | translate }}"
    (click)="requestLocationHistory(queryArgs, id)"
    >done</mat-icon
  >
  <mat-icon
    class="clickable notification-dismiss"
    matTooltip="{{ 'Clear' | translate }}"
    (click)="markAsRead(id)"
    >clear
  </mat-icon>
</ng-template>

<ng-template #locationHistoryFound let-queriesCount="queriesCount" let-timestamp="timestamp">
  <img src="assets/static/images/information.svg" />
  <span>
    <b
      >{{ 'Location History Found' | translate }}.{{ queriesCount }}&nbsp;{{
        'entries were imported' | translate
      }}.</b
    >
  </span>
</ng-template>

<ng-template
  #tacticalNotifyOnCapture
  let-queryArgs="queryArgs"
  let-satellite="satellite"
  let-timestamp="timestamp"
>
  <img src="assets/static/images/information.svg" />
  <span>
    <b>{{ queryArgs.imsi }}&nbsp;{{ 'has been captured on' | translate }}&nbsp;{{ satellite }}.</b>
  </span>
</ng-template>

<ng-template #radicalMonitoringOnDetect let-content="content" let-target="target">
  <p class="notification-label">
    {{ 'Radical monitoring matched a keyword for' | translate }}
    <span (click)="navigateToTarget(target.id)">{{ target ? target.alias : '' }}</span>
  </p>
  <p
    class="notification-content"
    innerHTML="{{ highlightText(content.payload?.data?.content, content.keywords) }}"
  ></p>

  <div class="notification-actions">
    <button
      class="notification-action"
      mat-flat-button
      (click)="navigateToRadicalMonitoring(target, content)"
    >
      <mat-icon>filter_none</mat-icon> {{ 'View item' | translate }}
    </button>
    <!-- WIP -->
    <!-- <button class="notification-action" mat-flat-button>
        <mat-icon>gps_fixed</mat-icon> {{ "Geolocate" | translate}}
      </button> -->
  </div>
</ng-template>

<ng-template
  #callLogUpload
  let-author="content.author"
  let-targetTelno="content.targetTelno"
  let-date="content.date"
  let-id="id"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />
    <span>
      {{ 'Call log upload request for author' | translate }}&nbsp; <b>{{ author }},</b>&nbsp;
      {{ 'with msisdn' | translate }}&nbsp; <b>{{ targetTelno }}</b
      >&nbsp;
      {{ 'has finished' | translate }}
      .
    </span>
  </div>
</ng-template>

<ng-template
  #callLogUploadFinished
  let-id="id"
  let-callLogUrl="content.callLogUrl"
  let-alias="content.alias"
  let-value="content.value"
  let-uploadedAt="content.uploadedAt"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />
    <span>
      {{ 'Call Log for' | translate }} <b>{{ alias || value }},</b>
      {{ 'was successfully delivered' | translate }}
      <a *ngIf="callLogUrl" href="{{ callLogUrl }}">{{ 'View Call Log analysis' | translate }}</a>
    </span>
  </div>
</ng-template>

<ng-template #advancedGeoQuery let-targetTelno="content.targetTelno" let-id="id" let-queryStatus="content.queryStatus">
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />
    <span>
      <b>{{ targetTelno }}</b
      >&nbsp;
      {{ queryStatus === 'NO LOCATION' ? 'finished' : 'was located successfully' | translate }}
      .
    </span>
  </div>
</ng-template>

<ng-template #querycallinfo let-text="content.text" let-id="id">
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />
    <span>
      <b>{{ text }}</b
      >&nbsp;
      {{
        'was extracted successfully. Information was also added in Target Call Log Analysis'
          | translate
      }}
      .
    </span>
  </div>
</ng-template>

<ng-template
  #liveSession
  let-subject="notificationText"
  let-liveSession="liveSession"
  let-timestamp="timestamp"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />&nbsp;
    <span>
      {{ 'Live Session has been successfully obtained' | translate }}
    </span>
    <div>
      <a href="{{ liveSession.url }}" target="_blank">{{ liveSession.url }}</a>
    </div>
    <div>
      {{ liveSession.password }}
      <img
        class="copyToClipboard clickable"
        [copyClipboard]="liveSession.password"
        src="assets/static/images/copy_all.svg"
        matTooltip="{{ 'Copy to Clipboard' | translate }}"
      />
    </div>
  </div>
</ng-template>

<ng-template
  #quarantine
  let-subject="subject"
  let-target="target"
  let-inside="inside"
  let-timestamp="timestamp"
  let-id="id"
>
  <img src="assets/static/images/gps.svg" />
  <span
    ><a href="/targets/{{ target.id }}/overview">{{ target.alias }}</a
    >&nbsp; {{ 'is' | translate }}&nbsp; {{ (inside ? 'inside' : 'outside') | translate }}&nbsp;
    {{ 'the geofenced area' | translate }}.</span
  >
</ng-template>

<ng-template #extractusd let-text="content.text" let-id="id" let-target="target">
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />
    <span>
      <b>{{ text }}</b>
    </span>
  </div>
</ng-template>

<ng-template #system let-text="content.text" let-actions="content.actions">
  <div class="system-notification">
    <p *ngIf="text">
      {{ text }}
    </p>
    <button
      *ngFor="let action of actions"
      mat-button
      class="notification-action"
      (click)="action.onClick()"
    >
      {{ action.label }}
    </button>
  </div>
</ng-template>

<ng-template
  #targetOrCaseExpired
  let-entityAlias="content.entityAlias"
  let-id="id"
  let-entityType="content.entityType"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />
    <span *ngIf="entityType">
      <b>{{ entityType | translate }} - {{ entityAlias }} {{ 'is expired' | translate }}</b>
    </span>
  </div>
</ng-template>

<ng-template #somedusStatus let-entityAlias="content.targetAlias" let-id="id" let-targetId="content.targetId" let-platform="content.platform"
  let-status="content.status">
  <div class="click-notification" (click)="markAsRead(id)">
    <span *ngIf="status === 'DONE'">
      <b>SOMEDUS {{platform | titlecase}} completed successfully. Information also added in <span class="cursor"
          (click)="navigateToTarget(targetId)"><i>{{entityAlias}}</i></span>
        profile</b>
    </span>
    <span *ngIf="status === 'ABANDONED'">
      <b>SOMEDUS Failed to retrieve {{platform | titlecase}} details. Information not added in
        <span class="cursor" (click)="navigateToTarget(targetId)"><i>{{entityAlias}}</i></span>
        profile</b>
    </span>
    <span *ngIf="status === 'EXPIRED'">
      <b>SOMEDUS Expired to retrieve {{platform | titlecase}} details. Information not added in
        <span class="cursor" (click)="navigateToTarget(targetId)"><i>{{entityAlias}}</i></span>
        profile</b>
    </span>
  </div>
</ng-template>

<ng-template
  #geoqueryDeactivatedState
  let-id="id"
  let-robot="content.query.schedule.scheduleType"
  let-telno="content.query.queryArgs.telno"
>
  <div class="click-notification" (click)="markAsRead(id)">
    <img src="assets/static/images/information.svg" />
    <span>
      <b
        >{{ robot | titlecase | translate }} {{ 'has terminated' | translate }}. {{ telno }}
        {{ 'is deactivated' | translate }}.</b
      >
    </span>
  </div>
</ng-template>

<ng-template 
  #avatarExpired 
  let-expiredAvatars="expiredAvatars"
  let-soonToExpireAvatars="soonToExpireAvatars"
  let-id="id"
>
  <div
    *ngIf="soonToExpireAvatars?.length"
    class="click-notification"
    (click)="markAsRead(id)"
  >
    <div *ngIf="soonToExpireAvatars.length > 1; else single_avatar">
      {{ 'Virtual IDs will expire in 24 hours' }}
      <a routerLink="'virtual-id'">{{ 'View the list' | translate }}</a>
    </div>
    <ng-template #single_avatar>
      {{ 'Virtual ID' | translate }} - {{ soonToExpireAvatars[0].person.fullName  }} {{'will expire soon' | translate }}
    </ng-template>
  </div>
  <div
    *ngIf="expiredAvatars?.length"
    class="click-notification"
    (click)="markAsRead(id)"
  >
    <div *ngIf="expiredAvatars.length > 1; else single_avatar">
      {{ 'Virtual IDs have expired' }}
      <a routerLink="'virtual-id'">{{ 'View the list' | translate }}</a>
    </div>
    <ng-template #single_avatar let-avatar="expiredAvatars[0]">
      {{ 'Virtual ID' | translate }} - {{ expiredAvatars[0].person.fullName  }} {{'is expired' | translate }}
    </ng-template>
  </div>
</ng-template>

<div class="notification row" *ngIf="template">
  <div class="createdAt col-12">{{ notification.createdAt | date: 'MMM d, y, H:mm:ss' }}</div>
  <div [ngClass]="notification.isRead ? 'read' : 'unread'">
    <!--div [ngClass]="notification.isRead ? 'read' : 'unread'">
      <div *ngTemplateOutlet="template"></div>
    </div-->
    <ng-container *ngIf="template === 'gamification'">
      <div *ngTemplateOutlet="gamification; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'emergency'">
      <div *ngTemplateOutlet="emergency; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'querycallinfo'">
      <div *ngTemplateOutlet="querycallinfo; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'callLogUploadFinished'">
      <div *ngTemplateOutlet="callLogUploadFinished; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'geoqueryArea'">
      <div *ngTemplateOutlet="geoqueryArea; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'geoqueryLogged'">
      <div *ngTemplateOutlet="geoqueryLogged; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'geoqueryLogonStatusOff'">
      <div *ngTemplateOutlet="geoqueryLogonStatusOff; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'proximity'">
      <div *ngTemplateOutlet="proximity; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'geofenceCountry'">
      <div *ngTemplateOutlet="geofenceCountry; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'geoqueryScheduleEnded'">
      <div *ngTemplateOutlet="geoqueryScheduleEnded; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'push'">
      <div *ngTemplateOutlet="push; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'releasenotes'">
      <div *ngTemplateOutlet="releasenotes; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'locationHistoryNotFound'">
      <div *ngTemplateOutlet="locationHistoryNotFound; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'locationHistoryNotFoundTellcell'">
      <div *ngTemplateOutlet="locationHistoryNotFoundTellcell; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'locationHistoryFound'">
      <div *ngTemplateOutlet="locationHistoryFound; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'tacticalNotifyOnCapture'">
      <div *ngTemplateOutlet="tacticalNotifyOnCapture; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'radicalMonitoringOnDetect'">
      <div *ngTemplateOutlet="radicalMonitoringOnDetect; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'callLogUpload'">
      <div *ngTemplateOutlet="callLogUpload; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'advancedGeoQuery'">
      <div *ngTemplateOutlet="advancedGeoQuery; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'live-session'">
      <div *ngTemplateOutlet="liveSession; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'quarantine'">
      <div *ngTemplateOutlet="quarantine; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'extractusd'">
      <div *ngTemplateOutlet="extractusd; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'system'">
      <div *ngTemplateOutlet="system; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'targetOrCaseExpired'">
      <div *ngTemplateOutlet="targetOrCaseExpired; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'geoqueryDeactivatedState'">
      <div *ngTemplateOutlet="geoqueryDeactivatedState; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'somedusStatus'">
      <div *ngTemplateOutlet="somedusStatus; context: context"></div>
    </ng-container>

    <ng-container *ngIf="template === 'avatarExpired'">
      <div *ngTemplateOutlet="avatarExpired; context: context"></div>
    </ng-container>
  </div>
</div>

<div *ngIf="isMobileResolution && skin === 'FAMILY_FINDER'" class="notification-mobile">
  <div class="row middle-xs notification-item-mob">
    <div class="col-3 center-xs">
      <div>
        <mat-icon class="pin">place</mat-icon>
      </div>
    </div>
    <div class="col-9">
      <div class="notification-text">
        {{ notificationText }}
      </div>
    </div>
  </div>
</div>
