import { EnvironmentFeatures } from 'environment/models';
// @ts-ignore
import { Skins, Themes } from 'src/app/shared/models/skins.model';

export const environmentFeatures: EnvironmentFeatures = {
  flavor: Skins.GEO4,
  country: 'mexico',
  defaultLanguage: 'en',
  homeCountryCode: '+357',
  tenantId: 'your-tenant-id',
  callLogsEnabledCountries: ['52'],
  useMockData: false,
  enableUserSettingsV2: true,
  enabledLandingScreen: false,
  enabledAnalytics: true,
  enableTactical: false,
  enabledLinkAnalysis: true,
  enableAoIQuery: false,
  enabledApps: true,
  enabledRecaptcha: false,
  enableRadicalMonitoring: true,
  mapType: 'GOOGLE',
  theme: Themes.GEO,
  enableTrueCaller: false,
  enabledMxnl: false,
  enableTourGuide: false,
  enableAdvancedGeolocationPopup: true,
  enableDarkwebLive: false,
  enableLinkAnalysis: true,
  enableLiveSession: false,
  enableGeolocationQuery: true,
  enabledDiscoverPerson: true,
  enabledOnlyForDemo: false,
  enableTargetFeed: true,
  enableDeleteTarget: true,
  enableUserManual: true,
  enablePhoneLinks: false,
  enableCaseOverview: true,
  adIntFrame: false,
  enableAdInt: true,
  adminCanConfigureUsersOTP: true,
  enabledNewCallLogs: true,
  enabledNewCallLogsUpload: true,
  enabledNewCallLogsRequest: true,
  enabledNewCallLogsExport: true,
  overviewMapInitialMarkerNumber: 1000,
  overviewMapEnableCallLogLoad: true,
  overviewMapEnableAdIntLoad: true,
  enableLinkAnalysisV2: false,
  enableCaseCallLogs: false,
  enableTestingFrequencies: false,
  enableKnowledgeBase: false,
  enableIntelResultsV2: false,
  kronographCaseStudy: false,
  enableInvestigationModule: true,
  enableInvestigationIntelSearchResults: false,
  httpResponseCacheLifetimeSeconds: 1,
  sessionIdleAfterMinutesInactive: 15,
  enableCreditChargesForTarget: false,
  themeQueriesLimit: 0,
  enabledTextAnalysis: false,
  enableDeepOsint: false,
  enableAdintFlavour: false,
  enabledUA: true,
  enableAvatars: false,
  enableCreditChargesForCase: false,
  expireTargetDays: 0,
  expireCaseDays: 0,
  enableCreditChargesForCallLogs: false,
  enabledSomedus: false,
  enableQuestionnaires: true,
  enableFeatures: false,
  disableWebintActions: false,
  enableCovid19MX: false,
  enableProfileUrlsMasking: false,
  enableNewRequestClDialog: false,
  forceLowFrequencySchedules: false,
  enableAvatarsCreation: true,
  enableInvestigationMapAoI: false,
  enableIMSICallLogs: false,
  fileTextAnalysisEnabled: false,
  fileTextAnalysisUrlUploadEnabled: false,
  enableTermsOfUse: false,
  termsOfUseAccountId: 'terms-of-use-account-id',
  termsOfUseEnvironmentUrl: 'https://demo.docusign.net',
  termsOfUseClickWrapId: 'terms-of-use-clickwrap-id',
  enableOsintSplitQueries: false,
  tenantCountryCodes: [52],
  enableGrafanaDashboard: false,
  disableLocatingFunctionality: false
};
