import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
// d3-cloud is not actually depending on d3 itself, it only give us the word coordinates algorithm
import * as cloudLayout from 'd3-cloud';
import { WordCloudItem } from 'src/app/modules/text-analysis/components/text-analysis/components/word-cloud/models/word-cloud.class';
import { textAnalysisColors } from 'src/app/modules/text-analysis/values/colors';

// TODO replace d3 with https://www.npmjs.com/package/angular-tag-cloud-module 
@Component({
  selector: 'app-text-analysis-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WordCloudComponent implements OnInit, OnChanges, OnDestroy {
  @Output() wordPick = new EventEmitter<WordCloudItem>();
  @Input() words: WordCloudItem[];

  constructor(private cd: ChangeDetectorRef) {}

  public WIDTH = 800;
  public HEIGHT = 500;
  public activeWord: WordCloudItem;
  public FONT_SIZE_MIN = 20;
  public FONT_SIZE_MAX = 60;
  public processedWordCloudItems: WordCloudItem[] = [];
  public colors = textAnalysisColors;
  private maxVal;
  private paddingReducer = 0.4;
  private fontSizeReducer = 1;
  private attempts = 0;
  private wordRenderComplete = new EventEmitter<void>();
  public wordRenderComplete$ = this.wordRenderComplete.asObservable();
  ngOnInit() {
    this.generateLayout(this.words);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.words.firstChange) {
      this.generateLayout(changes.words.currentValue);
    }
  }

  /**
   *
   * @param words WordCloudItem[]
   */
  generateLayout(words: WordCloudItem[]) {
    if (!words || !words.length) {
      this.wordRenderComplete.emit();
      return;
    }

    this.maxVal = Math.max(...words.map(w => w.count));

    const layout = cloudLayout()
      .size([this.WIDTH, this.HEIGHT])
      .words(words)
      .rotate(0)
      .fontSize((d: WordCloudItem) => {
        const percentageFromMaxVal = (d.count * 100) / this.maxVal;
        const valueInRange =
          (percentageFromMaxVal * (this.FONT_SIZE_MAX - this.FONT_SIZE_MIN)) / 100 + this.FONT_SIZE_MIN;
        return Math.ceil(valueInRange * this.fontSizeReducer);
      })
      .font('Impact')
      .padding((d: WordCloudItem) => {
        return d.size * this.paddingReducer;
      })
      .spiral('rectangular')
      .on('end', (list: WordCloudItem[]) => {
        if (list.length !== words.length) {
          // Some words could be missed after processing by d3-cloud layout algorithm.
          this.onFailedLayout(layout, words, list);
          return;
        }
        console.log('Word cloud was successfully generated');
        this.processedWordCloudItems = list;
        this.fontSizeReducer = 1;
        this.cd.markForCheck();
      });

    layout.start();
    this.wordRenderComplete.emit();
  }

  /**
   *
   * @param e any
   * @param word WordCloudItem
   */
  public onWordCloudClick(e: any, word: WordCloudItem) {
    this.wordPick.emit(word);
  }

  /**
   *
   * @param word WordCloudItem
   */
  public greyOutWords(word: WordCloudItem) {
    this.words
      .filter(w => w !== word)
      .forEach(w => {
        w.setActiveStatus(false);
        // click on already clicked word will reset greyed-out state for all words
        if (word.active) {
          w.enable();
          return;
        }

        w.disable();
      });
    this.cd.markForCheck();
  }

  /**
   * Some words could be missed after processing by d3-cloud layout algorithm.
   * This is a known bug https://github.com/jasondavies/d3-cloud/issues/159.
   * So in such case we will try (1) to re-render with decreased size. (2) Filtering words and rerender after 5 attempts;
   * @param layout any
   * @param givenWords WordCloudItem[]
   * @param processedWords WordCloudItem[]
   */
  private onFailedLayout(layout: any, givenWords: WordCloudItem[], processedWords: WordCloudItem[]) {
    this.attempts++;
    if (this.attempts >= 1) {
      console.warn(`Attempt ${this.attempts}. Decreasing the font-size. Given words: ${givenWords.length}. Words in the final layout: ${processedWords.length}. Rerunning...`);
      this.fontSizeReducer -= 0.1;
    }

    layout.stop();

    if (this.attempts > 5 ) {
      const words = givenWords.filter(given => !!processedWords.find(pw => pw.text === given.text));
      this.generateLayout(words);
      return;
    };

    layout.start();
  }

  ngOnDestroy(): void {
    this.words = [];
  }
}
