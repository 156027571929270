import { BaseService, EmptyCacheService } from 'datalayer/services/base';
import { Post } from 'datalayer/models/social/post';
import { PostApiService } from 'src/app/modules/data-layer/services/social/post/post-api-service';
import { Injectable } from '@angular/core';
import { PostDTO } from 'src/app/modules/data-layer/services/social/post/post-dto';

@Injectable({
  providedIn: 'root',
})
export class PostService extends BaseService<Post, PostDTO, PostApiService, EmptyCacheService<Post>> {
  constructor(apiService: PostApiService, localCacheService: EmptyCacheService<Post>) {
    super(apiService, localCacheService);
  }
}
