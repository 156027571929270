<p class="body-desc" *ngIf="isPartOfUserSettings">
  {{ "For generating OTP codes, please download the Authenticator App" | translate }}:
</p>

<div *ngIf="showOtpImage" class="authenticator-apps-wrp">
  <a
    class="authenticator-link"
    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
    target="_blank"
  >
    <img src="assets/static/images/google-play-img.png" />
  </a>
  <a class="authenticator-link" href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
    <img src="assets/static/images/app-store-img.svg"
  /></a>
</div>

<p class="body-desc" *ngIf="showOtpImage">
  {{
    (showMessageWithOldChannel ? userSelectionMessage : 'Scan QR with your device to generate OTP codes') | translate
  }}.
</p>

<div class="QR-img" *ngIf="showOtpImage">
  <img [src]="QRImage | async" alt="QR Code" />
</div>

<div class="form-wrp">
  <div class="field-group">
    <label>{{ 'Enter the OTP code from your device' | translate }}</label>
    <div class="input-style-round relative">
      <input
        class="assistant-font"
        matInput
        [type]="visibility ? 'text' : 'password'"
        [formControl]="otpControl"
        required
      />

      <mat-icon class="icon eye-icon" (click)="this.visibility = !this.visibility">
        {{ this.visibility === true ? 'visibility_off' : 'visibility' }}</mat-icon
      >
    </div>
  </div>
  <mat-error class="activate-otp-modal-error" *ngIf="errorMsg">{{ errorMsg | translate }}</mat-error>
</div>
