import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  version: string;

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  ngOnInit() {
    this.httpClient.get<any>(`${environment.serverAPIUri}/version`)
      .subscribe(version => {
        this.version = version;
      });
  }
}
