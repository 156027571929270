import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeafletMapComponent } from 'src/app/modules/mapV2/components/leaflet-map/leaflet-map.component';
import { MapComponent } from 'src/app/modules/mapV2/components/map/map.component';
import { TrgCommonUiModule } from 'src/app/modules/trg-common-ui/trg-common-ui.module';
import { TrgCommonModule } from 'src/app/modules/trg-common/trg-common.module';
import { VanillaGoogleMapModule } from './vanilla-google-map/vanilla-google-map.module';
@NgModule({
  imports: [CommonModule, TrgCommonModule, TrgCommonUiModule, VanillaGoogleMapModule],
  declarations: [MapComponent, LeafletMapComponent],
  exports: [MapComponent],
  providers: []
})
export class MapV2Module { }
