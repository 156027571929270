import { DataSource } from '@trg-commons/data-models-ts';
import { Platforms } from './radical-monitoring-options.model';

export const SocialPlatformMap = {
  [DataSource.Twitter]: Platforms.TWITTER,
  [DataSource.Facebook]: Platforms.FACEBOOK,
  [DataSource.Instagram]: Platforms.INSTAGRAM,
  [DataSource.LinkedIn]: Platforms.LINKEDIN,
  [DataSource.YouTube]: Platforms.YOUTUBE,
  [DataSource.Tiktok]: Platforms.TIKTOK,
};
