import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Directive({
  selector: '[appGuesHeight]'
})
export class GuesHeightDirective implements AfterViewInit, OnDestroy {
  constructor(private elementRef: ElementRef<HTMLElement>) {
  }

  private sub: Subscription;

  @Output() public afterGues = new EventEmitter<number>();


  ngAfterViewInit() {
    this.sub = of(true)
      .pipe(
        // shitty code there
        delay(100),
        map(() => {
          return this.elementRef.nativeElement.clientHeight;
        }),
      ).subscribe((height) => {
        this.afterGues.emit(height);
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}